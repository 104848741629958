import { QueryParamsForList } from '@/api/base';
import store from '@/store';
import CountryService, { CountryListResponseType } from '@/api/admin/dictionary/country';

export function useListApi() {
  return {
    async loadItems(params: QueryParamsForList) {
      const countryApi: CountryService = store.getters.$api.admin.dictionary.country;

      const response: CountryListResponseType = await countryApi.list(
        params,
      );

      return response.data?.items ?? [];
    },
  };
}

export default {};
