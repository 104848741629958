import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isSolid)
    ? (_openBlock(), _createElementBlock("svg", {
        key: 0,
        width: _ctx.width,
        height: _ctx.height,
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }, [
        _createElementVNode("rect", {
          width: "24",
          height: "24",
          rx: "12",
          fill: _ctx.color
        }, null, 8, _hoisted_2),
        _createElementVNode("path", {
          d: "M7.3608 7.27273H9.29545L11.8864 13.5966H11.9886L14.5795\n 7.27273H16.5142V16H14.9972V10.0043H14.9162L12.5043\n 15.9744H11.3707L8.95881 9.99148H8.87784V16H7.3608V7.27273Z",
          fill: _ctx.childColor
        }, null, 8, _hoisted_3)
      ], 8, _hoisted_1))
    : _createCommentVNode("", true)
}