import { BoardingSteps } from 'boarding.js';
import HighlightElement from 'boarding.js/dist/core/highlight-element';

export const getSteps = (): BoardingSteps => ([
  {
    element: '.blocks',
    popover: {
      className: 'first-step-popover-class',
      title: 'Реестр объемов',
      description: 'Список блоков в реесте объемов.',
      prefferedSide: 'bottom',
    },
    onBeforeHighlighted: () => {
      const blocks = document.querySelectorAll('.blocks .block:not(.block-hidden)');

      blocks.forEach((item) => {
        const element = item.querySelector('.block-header') as HTMLElement;

        element?.click();
      });
    },
  },
  {
    element: '.blocks .block',
    popover: {
      className: 'first-step-popover-class',
      title: 'Блок',
      description: 'Рассмотрим один блок.',
      prefferedSide: 'bottom',
    },
    onNext: (element: HighlightElement) => {
      const el = element.getElement() as HTMLElement;

      el.click();
    },
  },
  {
    element: '.blocks .block .filter-block',
    popover: {
      className: 'first-step-popover-class',
      title: 'Фильтр',
      description: 'Для просмотра данных, сначала необходимо выбрать группу и год. В режиме просмотра в списке отображаются только заполненые данные.',
      prefferedSide: 'bottom',
    },
  },
  {
    element: '.blocks .block .filter-block .switch-button',
    popover: {
      className: 'first-step-popover-class',
      title: 'Смена разреза',
      description: 'По умолчанию используется фильтр "группа товаров в разрезе года". При клике на эту кнопку можно сменить фильтр на "год в разрезе группы товара"',
      prefferedSide: 'bottom',
    },
  },
  {
    element: '.blocks .block .icon-edit-button',
    popover: {
      className: 'first-step-popover-class',
      title: 'Редактировани и добавление',
      description: 'Для редактирования или добавления новых данных нажмите на эту кнопку.',
      prefferedSide: 'bottom',
    },
  },
]);

export default {
  getSteps,
};
