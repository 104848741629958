import { toDisplayString as _toDisplayString, vModelDynamic as _vModelDynamic, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-38039a15"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "input-label block",
  for: "input"
}
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = ["type", "disabled", "placeholder", "autofocus"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search_icon = _resolveComponent("search-icon")!
  const _component_close_icon = _resolveComponent("close-icon")!
  const _component_icon_button = _resolveComponent("icon-button")!

  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["w-full relative", { 'mr-[16px]': _ctx.clearButton }])
      }, [
        _withDirectives(_createElementVNode("input", {
          ref: "input",
          class: "input",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.query) = $event)),
          type: _ctx.type,
          disabled: _ctx.disabled,
          placeholder: _ctx.placeholder,
          autofocus: _ctx.autofocus,
          onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.debounceFunction && _ctx.debounceFunction(...args)))
        }, null, 40, _hoisted_3), [
          [
            _vModelDynamic,
            _ctx.query,
            void 0,
            { trim: true }
          ]
        ]),
        _createVNode(_component_search_icon, { class: "absolute absolute top-[8px] right-[8px]" })
      ], 2),
      (_ctx.clearButton)
        ? (_openBlock(), _createBlock(_component_icon_button, {
            key: 0,
            class: "circle-button",
            border: "default",
            description: _ctx.$t('counterparty.list.filter.clear'),
            onClick: _ctx.clearSearch
          }, {
            default: _withCtx(() => [
              _createVNode(_component_close_icon)
            ]),
            _: 1
          }, 8, ["description", "onClick"]))
        : _createCommentVNode("", true)
    ])
  ]))
}