import JsonData from '@/components/jsonforms/interfaces/JsonData';
import i18n from '@/i18n';

export interface DataType {
  [key: string]: string,
}

export interface AjaxElementType {
  id: string | number,
  name: string,
}

export interface AjaxFilterType {
  [key: string]: string,
}

export interface AjaxConfigurationType {
  categoryName: string,
  mapper?: {
    id?: string,
    name?: string,
  },
  filter: AjaxFilterType,
}

export default function useAjaxConfiguration(params: AjaxConfigurationType) {
  const prepareName = (data: DataType) => {
    const name = params.mapper?.name ? data[params.mapper.name] : data.name;

    return data.isDeleted
      ? `(${i18n.global.t('common.isDeleted')}) ${name}`
      : name;
  };

  return {
    async loadDataByAjax(query: string | null = null, filter: AjaxFilterType = {}) {
      const { useListApi } = await import(`@/composables/api/${params.categoryName}/list.ts`);
      const { loadItems } = useListApi();

      const data = await loadItems({
        query,
        ...params.filter,
        ...filter,
      });

      return data.map((item: JsonData) => ({
        id: params.mapper?.id ? item[params.mapper.id] : item.id,
        name: params.mapper?.name ? item[params.mapper.name] : item.name,
      }));
    },
    async getNameById(id: string): Promise<string> {
      const { useOneApi } = await import(`@/composables/api/${params.categoryName}/one.ts`);
      const { loadData } = useOneApi();

      const data = await loadData(id);

      if (data === null) {
        return id;
      }

      return prepareName(data);
    },
    async getElementById(id: string): Promise<AjaxElementType> {
      const { useOneApi } = await import(`@/composables/api/${params.categoryName}/one.ts`);
      const { loadData } = useOneApi();

      const data = await loadData(id);

      if (data === null) {
        return { id, name: id };
      }

      const name = prepareName(data);

      return { id, name };
    },
  };
}
