
import { defineComponent } from 'vue';
import AddIcon from '@/components/ui/icons/AddIcon.vue';
import IconButton from '@/components/ui/buttons/IconButton/IconButton.vue';
import DefaultButton from '@/components/ui/buttons/DefaultButton/DefaultButton.vue';

export default defineComponent({
  name: 'TheTitle',
  inject: ['$sizeWindow'],
  components: {
    DefaultButton,
    AddIcon,
    IconButton,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    isCanEdit: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    buttonClickHandler() {
      this.$emit('button-click');
    },
  },
});
