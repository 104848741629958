import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e081fb58"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "contact-block" }
const _hoisted_2 = {
  key: 0,
  class: "contact-block-item"
}
const _hoisted_3 = { class: "contact-block-item__icon" }
const _hoisted_4 = {
  key: 1,
  class: "contact-block-item break-all"
}
const _hoisted_5 = { class: "contact-block-item__icon" }
const _hoisted_6 = ["href"]
const _hoisted_7 = {
  key: 2,
  class: "contact-block-item"
}
const _hoisted_8 = { class: "contact-block-item__icon" }
const _hoisted_9 = ["href"]
const _hoisted_10 = { class: "pt-[24px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_briefcase_icon = _resolveComponent("briefcase-icon")!
  const _component_email_icon = _resolveComponent("email-icon")!
  const _component_phone_icon = _resolveComponent("phone-icon")!
  const _component_form_generator = _resolveComponent("form-generator")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (!!_ctx.position)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_briefcase_icon, {
                width: "24",
                height: "24",
                type: "outline"
              })
            ]),
            _createElementVNode("span", null, _toDisplayString(_ctx.position), 1)
          ]))
        : _createCommentVNode("", true),
      (!!_ctx.email)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_email_icon, {
                width: "18",
                height: "18",
                type: "regular",
                color: "#64748B"
              })
            ]),
            _createElementVNode("span", null, [
              _createElementVNode("a", {
                href: `mailto:${_ctx.email}`
              }, _toDisplayString(_ctx.email ?? ''), 9, _hoisted_6)
            ])
          ]))
        : _createCommentVNode("", true),
      (!!_ctx.phone)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_phone_icon, {
                width: "18",
                height: "18",
                type: "regular",
                color: "#64748B"
              })
            ]),
            _createElementVNode("span", null, [
              _createElementVNode("a", {
                href: `tel:${_ctx.phone}`
              }, _toDisplayString(_ctx.phone ?? ''), 9, _hoisted_9)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createVNode(_component_form_generator, {
        uischema: _ctx.uischema,
        schema: _ctx.schema,
        information: _ctx.data,
        config: { onlyText: true },
        "validation-mode": "ValidateAndHide"
      }, null, 8, ["uischema", "schema", "information"])
    ])
  ]))
}