import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = ["innerHTML", "for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_toggle_tooltip = _resolveComponent("the-toggle-tooltip")!

  return (_ctx.visible)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(_ctx.styles.control.root),
        id: _ctx.id
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("label", {
            innerHTML: _ctx.computedLabel,
            for: _ctx.id + '-input',
            class: _normalizeClass(_ctx.appliedOptions.onlyText ? _ctx.styles.control.labelText : _ctx.styles.control.label)
          }, null, 10, _hoisted_3),
          (!!_ctx.appliedOptions.tooltip)
            ? (_openBlock(), _createBlock(_component_the_toggle_tooltip, {
                key: 0,
                class: "ml-[4px]",
                text: _ctx.appliedOptions.tooltip
              }, null, 8, ["text"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(`${_ctx.styles.control.wrapper} ${_ctx.errors ? _ctx.styles.control.wrapperError : null}`)
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 2),
        (_ctx.showError)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(_ctx.styles.control.error)
            }, _toDisplayString(_ctx.errors ? _ctx.errors : null), 3))
          : _createCommentVNode("", true),
        (_ctx.showDescription)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(_ctx.styles.control.description)
            }, _toDisplayString(_ctx.description ? _ctx.description : null), 3))
          : _createCommentVNode("", true)
      ], 10, _hoisted_1))
    : _createCommentVNode("", true)
}