
import { defineComponent, PropType } from 'vue';
import { JsonSchema } from '@jsonforms/core';
import config from '@/config';
import BriefcaseIcon from '@/components/ui/icons/BriefcaseIcon.vue';
import EmailIcon from '@/components/ui/icons/EmailIcon.vue';
import PhoneIcon from '@/components/ui/icons/PhoneIcon.vue';
import EditIcon from '@/components/ui/icons/EditIcon.vue';
import HistoryIcon from '@/components/ui/icons/HistoryIcon.vue';
import ImageAvatar from '@/components/ui/avatars/ImageAvatar.vue';
import ContextMenu from '@/components/ui/menu/ContextMenu/ContextMenu.vue';
import BlockedIcon from '@/components/counterparty/card/blocks/agents/modal/detail/icons/BlockedIcon.vue';
import { AgentSchema } from '@/api/agent';
import { ContextMenuType } from '@/components/ui/menu/ContextMenu/ContextMenu';
import CustomUISchemaElement from '@/components/jsonforms/interfaces/CustomUISchemaElement';
import useAjaxConfiguration from '@/composables/form/useAjaxConfiguration';
import useStringHelper from '@/composables/helpers/text/useStringHelper';

export const TYPE_EDIT = 'edit';
export const TYPE_VIEW = 'view';
export const TYPE_HISTORY = 'history';

export default defineComponent({
  name: 'TheCard',
  emits: ['open'],
  components: {
    ImageAvatar,
    BriefcaseIcon,
    EmailIcon,
    PhoneIcon,
    ContextMenu,
    BlockedIcon,
  },
  props: {
    data: {
      type: Object as PropType<AgentSchema>,
      default: () => ({}),
    },
    isCanEdit: {
      type: Boolean,
      default: false,
    },
    schema: {
      type: Object as PropType<JsonSchema>,
      default: () => ({}),
    },
    uischema: {
      type: Object as PropType<CustomUISchemaElement>,
      default: () => ({}),
    },
  },
  data() {
    return {
      position: null,
    };
  },
  computed: {
    contextMenuParams(): ContextMenuType[] {
      return [
        {
          text: this.$t('buttons.edit'),
          icon: EditIcon,
          events: {
            click: () => this.openEdit(),
          },
          isVisible: this.isCanEdit,
        },
        {
          text: this.$t('buttons.history'),
          icon: HistoryIcon,
          events: {
            click: () => this.openHistory(),
          },
        },
      ];
    },
    isBlockedStatus() {
      return this.data.status === config.STATUS_AGENT_IS_BLOCKED;
    },
    fullName(): string {
      return this.data[config.COUNTERPARTY_AGENT_FULL_NAME_FIELD] ?? '';
    },
    phone(): string {
      const phoneItem = this.data[config.COUNTERPARTY_AGENT_PHONE_ITEM] ?? null;
      const firstPhone = phoneItem ? phoneItem[0] : null;

      return firstPhone ? firstPhone[config.COUNTERPARTY_AGENT_PHONE_FIELD] : '';
    },
    email(): string {
      const emailItem = this.data[config.COUNTERPARTY_AGENT_EMAIL_ITEM] ?? null;
      const firstEmail = emailItem ? emailItem[0] : null;

      return firstEmail ? firstEmail[config.COUNTERPARTY_AGENT_EMAIL_FIELD] : '';
    },
    nameInitial(): string {
      return useStringHelper().getLetter(this.fullName);
    },
  },
  methods: {
    openEdit() {
      this.$emit('open', this.data, TYPE_EDIT);
    },
    openView() {
      this.$emit('open', this.data, TYPE_VIEW);
    },
    openHistory() {
      this.$emit('open', this.data, TYPE_HISTORY);
    },
    async setPositionName() {
      const positionUischema = this.uischema.elements.find(
        (item: CustomUISchemaElement) => item.scope?.split('/').pop() === config.COUNTERPARTY_AGENT_POSITION_FIELD,
      );
      const ajaxConfigurator = useAjaxConfiguration(positionUischema.options.ajaxConfiguration);

      if (this.data[config.COUNTERPARTY_AGENT_POSITION_FIELD]) {
        this.position = await ajaxConfigurator.getNameById(
          this.data[config.COUNTERPARTY_AGENT_POSITION_FIELD],
        );
      }
    },
  },
  watch: {
    data() {
      this.setPositionName();
    },
  },
  async created() {
    await this.setPositionName();
  },
});
