
import { defineComponent, ref } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import DictionaryGenerator from '@/components/admin/dictionary/generator/DictionaryGenerator.vue';
import { BankData } from '@/api/admin/dictionary/bank';
import useCheckPermissions from '@/composables/useCheckPermissions';
import { FilterParams, PaginationParams, SortParams } from '@/api/base';

export default defineComponent({
  name: 'TheList',
  components: {
    DictionaryGenerator,
  },
  data() {
    return {
      isLoading: false,
      fields: [
        { key: 'name', label: this.$t('dictionary.bank.fields.name') },
        { key: 'bic', label: this.$t('dictionary.bank.fields.bic') },
        { key: 'city', label: this.$t('dictionary.bank.fields.city') },
        { key: 'action', notSortable: true, width: 52 },
      ],
      schema: {
        type: 'object',
        properties: {
          name: {
            type: 'string',
          },
          bic: {
            type: 'string',
          },
          corrAccount: {
            type: ['string', 'null'],
          },
          city: {
            type: ['string', 'null'],
          },
          address: {
            type: ['string', 'null'],
          },
          phone: {
            type: ['string', 'null'],
            pattern: '^[+]{1}[\\0-9\\s]{3,25}$',
            default: null,
            errorMessage: {
              pattern: 'введите корректный номер телефона',
            },
          },
        },
        required: [
          'name',
          'bic',
        ],
      },
      uischema: {
        type: 'VerticalLayout',
        elements: [
          {
            type: 'Control',
            label: this.$t('dictionary.bank.fields.name'),
            scope: '#/properties/name',
          },
          {
            type: 'Control',
            label: this.$t('dictionary.bank.fields.bic'),
            scope: '#/properties/bic',
          },
          {
            type: 'Control',
            label: this.$t('dictionary.bank.fields.corrAccount'),
            scope: '#/properties/corrAccount',
          },
          {
            type: 'Control',
            label: this.$t('dictionary.bank.fields.city'),
            scope: '#/properties/city',
          },
          {
            type: 'Control',
            label: this.$t('dictionary.bank.fields.address'),
            scope: '#/properties/address',
          },
          {
            type: 'PhoneControl',
            label: this.$t('dictionary.bank.fields.phone'),
            scope: '#/properties/phone',
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters({
      items: 'admin/dictionary/bank/list/items',
      filter: 'admin/dictionary/bank/list/filter',
      pagination: 'admin/dictionary/bank/list/pagination',
      sort: 'admin/dictionary/bank/list/sort',
    }),
  },
  methods: {
    ...mapActions({
      loadItems: 'admin/dictionary/bank/list/loadItems',
      resetState: 'admin/dictionary/bank/list/resetState',
      changeFilter: 'admin/dictionary/bank/list/changeFilter',
      changePagination: 'admin/dictionary/bank/list/changePagination',
      changeSort: 'admin/dictionary/bank/list/changeSort',
      create: 'admin/dictionary/bank/one/create',
      update: 'admin/dictionary/bank/one/update',
      delete: 'admin/dictionary/bank/one/delete',
      restore: 'admin/dictionary/bank/one/restore',
    }),
    async createHandler(data: BankData, next: () => void) {
      await this.create(data);
      await this.loadItems();

      next();
    },
    async updateHandler(data: { id: string, data: BankData}, next: () => void) {
      await this.update(data);
      await this.loadItems();

      next();
    },
    async deleteHandler(id: string) {
      await this.delete(id);
      await this.loadItems();
    },
    async restoreHandler(id: string) {
      await this.restore(id);
      await this.loadItems();
    },
    async changePaginationHandler(pagination: PaginationParams) {
      await this.changePagination(pagination);

      await this.loadItems();
    },
    async changeSortHandler(sort: SortParams) {
      await this.changeSort(sort);

      await this.loadItems();
    },
    async changeFilterHandler(filter: FilterParams) {
      await this.changeFilter(filter);
      await this.loadItems();
    },
  },
  async created() {
    this.isLoading = true;

    await this.loadItems();

    this.isLoading = false;
  },
  unmounted() {
    this.resetState();
  },
  setup() {
    const checkPermissions = useCheckPermissions();
    const isCanEdit = ref(checkPermissions.dictionary.canEditBank);

    return { isCanEdit };
  },
});
