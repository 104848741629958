
import { defineComponent, PropType } from 'vue';
import { DateTime } from 'luxon';
import MonthLayout from '@/components/ui/datepickers/datepicker-default/layouts/month/MonthLayout.vue';
import DayLayout from '@/components/ui/datepickers/datepicker-default/layouts/day/DayLayout.vue';
import YearLayout from '@/components/ui/datepickers/datepicker-default/layouts/year/YearLayout.vue';

export const TYPE_LAYOUT_DAY = 'day';
export const TYPE_LAYOUT_MONTH = 'month';
export const TYPE_LAYOUT_YEAR = 'year';

export default defineComponent({
  emits: ['change'],
  name: 'DatepickerLayout',
  components: { YearLayout, DayLayout, MonthLayout },
  props: {
    date: {
      type: Object as PropType<DateTime | null>,
      default: null,
    },
  },
  data() {
    return {
      currentDate: null,
      nowDate: DateTime.now(),
      currentLayout: TYPE_LAYOUT_DAY,
    };
  },
  computed: {
    isDayLayout() {
      return this.currentLayout === TYPE_LAYOUT_DAY;
    },
    isMonthLayout() {
      return this.currentLayout === TYPE_LAYOUT_MONTH;
    },
    isYearLayout() {
      return this.currentLayout === TYPE_LAYOUT_YEAR;
    },
  },
  methods: {
    changeDateHandler(data: DateTime) {
      this.$emit('change', data);
    },
    changeMonthHandler(month: number) {
      this.currentDate = this.currentDate.set({ month });
      this.currentLayout = TYPE_LAYOUT_DAY;
    },
    changeYearHandler(year: number) {
      this.currentDate = this.currentDate.set({ year });
      this.currentLayout = TYPE_LAYOUT_DAY;
    },
    changeLayoutHandler(layout: string) {
      this.currentLayout = layout;
    },
  },
  watch: {
    date(value) {
      this.currentDate = value;
    },
  },
  created() {
    this.currentDate = this.date ?? this.nowDate;
  },
});
