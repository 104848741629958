import { Module } from 'vuex';
import user, { UserStateType } from '@/store/modules/admin/user';
import role, { RoleStateType } from '@/store/modules/admin/role';
import dictionary, { DictionaryStateType } from '@/store/modules/admin/dictionary';
import { StateType } from '@/store';

export interface AdminStateType {
  user: UserStateType,
  dictionary: DictionaryStateType,
  role: RoleStateType,
}

const adminModule: Module<AdminStateType, StateType> = {
  namespaced: true,
  modules: {
    user,
    role,
    dictionary,
  },
};

export default adminModule;
