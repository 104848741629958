
import { defineComponent, PropType } from 'vue';
import { JsonSchema } from '@jsonforms/core';
import { ChangedDataType, HistoryItemType } from '@/api/block/history';
import DeleteAction from '@/components/counterparty/card/blocks/history/action/DeleteAction.vue';
import config from '@/config';

export default defineComponent({
  name: 'ItemContent',
  inject: ['$sizeWindow'],
  components: {
    DeleteAction,
  },
  props: {
    data: {
      type: Object as PropType<HistoryItemType>,
      default: () => ({}),
    },
    schema: {
      type: Object as PropType<JsonSchema>,
      default: () => ({}),
    },
  },
  computed: {
    isProcessing() {
      return config.STATUS_REQUEST_PROCESSING === this.data.status;
    },
  },
  methods: {
    clickHandler() {
      this.$emit('clickHandler', this.data.id);
    },
    getPreparedValue(id: string, value: string | number | boolean) {
      const schemaItem = this.schema?.properties?.[id] ?? null;

      if (schemaItem && schemaItem.format === 'date') {
        if (!value) {
          return null;
        }

        const dateString = String(value).split('-');
        const day: number = +dateString[2] ?? 0;
        const month: number = +dateString[1] ?? 0;
        const year: number = +dateString[0] ?? 0;

        return new Date(year, month - 1, day).toLocaleDateString();
      }

      return value !== '' ? value : null;
    },
    getNewValue(changedItem: ChangedDataType) {
      return this.getPreparedValue(changedItem.id, changedItem.new);
    },
    getOldValue(changedItem: ChangedDataType) {
      return this.getPreparedValue(changedItem.id, changedItem.old);
    },
  },
});
