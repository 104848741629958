import { QueryParamsForList } from '@/api/base';
import store from '@/store';
import BankService, { BankResponseType } from '@/api/admin/dictionary/bank';

export function useListApi() {
  return {
    async loadItems(params: QueryParamsForList) {
      const bankApi: BankService = store.getters.$api.admin.dictionary.bank;

      const response: BankResponseType = await bankApi.list(
        params,
      );

      return response.data?.items ?? [];
    },
  };
}

export default {};
