
import { defineComponent, PropType } from 'vue';
import { JsonSchema } from '@jsonforms/core';
import CustomUISchemaElement from '@/components/jsonforms/interfaces/CustomUISchemaElement';
import DefaultButton from '@/components/ui/buttons/DefaultButton/DefaultButton.vue';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import TheForm, { PaymentForm } from '@/components/counterparty/card/blocks/general/payment/form/TheForm.vue';

export default defineComponent({
  name: 'TheCreate',
  emits: [
    'cancel',
    'create',
    'changed-form-data',
  ],
  components: {
    DefaultButton,
    TheForm,
  },
  props: {
    schema: Object as PropType<JsonSchema>,
    uischema: Object as PropType<CustomUISchemaElement>,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    cancelHandler() {
      this.$emit('cancel');
    },
    createHandler() {
      this.isLoading = true;

      const formRef: PaymentForm = this.$refs.form as PaymentForm;
      const valid: boolean = formRef?.validateForm(true) ?? false;
      const data: JsonData | null = formRef?.currentData ?? null;

      if (!valid || !data) {
        this.isLoading = false;

        return false;
      }

      this.$emit('create', data, () => {
        this.isLoading = false;
      });

      return true;
    },
    changedFormDataHandler(data: JsonData) {
      this.$emit('changed-form-data', data);
    },
  },
});
