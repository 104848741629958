import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-aff3cae2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = { class: "table" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_header = _resolveComponent("the-header")!
  const _component_the_body = _resolveComponent("the-body")!
  const _component_the_pagination = _resolveComponent("the-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      _createVNode(_component_the_header, {
        class: "!hidden sm:!table",
        fields: _ctx.fields,
        sort: _ctx.sort,
        variant: _ctx.variant,
        onSortChanged: _ctx.changeSortByHandler
      }, null, 8, ["fields", "sort", "variant", "onSortChanged"]),
      _createVNode(_component_the_body, {
        items: _ctx.items,
        fields: _ctx.fields,
        variant: _ctx.variant,
        onItemClick: _ctx.bodyItemClickHandler
      }, null, 8, ["items", "fields", "variant", "onItemClick"]),
      _createElementVNode("tfoot", null, [
        _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
      ])
    ]),
    (!!_ctx.pagination)
      ? (_openBlock(), _createBlock(_component_the_pagination, {
          key: 0,
          pagination: _ctx.pagination,
          variant: _ctx.variant,
          onPaginationChanged: _ctx.changePaginationHandler
        }, null, 8, ["pagination", "variant", "onPaginationChanged"]))
      : _createCommentVNode("", true)
  ]))
}