import { Module } from 'vuex';
import { StateType } from '@/store';
import one, {
  ProductionIndicatorsOneStateType,
} from '@/store/modules/counterparty/block/characteristic/productionIndicators/one';
import list, {
  ProductionIndicatorsListStateType,
} from '@/store/modules/counterparty/block/characteristic/productionIndicators/list';

export interface ProductionIndicatorsStateType {
  list: ProductionIndicatorsListStateType,
  one: ProductionIndicatorsOneStateType,
}

const productionIndicatorsModule: Module<ProductionIndicatorsStateType, StateType> = {
  namespaced: true,
  modules: {
    list,
    one,
  },
};

export default productionIndicatorsModule;
