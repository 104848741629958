
import { defineComponent, PropType } from 'vue';
import { JsonSchema, UISchemaElement } from '@jsonforms/core';
import DefaultButton from '@/components/ui/buttons/DefaultButton/DefaultButton.vue';
import TheForm, {
  GeneratorForm,
} from '@/components/admin/dictionary/generator/form/TheForm.vue';
import JsonData from '@/components/jsonforms/interfaces/JsonData';

export default defineComponent({
  name: 'TheCreate',
  inject: ['$sizeWindow'],
  emits: ['cancel', 'create', 'updateData'],
  components: {
    DefaultButton,
    TheForm,
  },
  props: {
    schema: {
      required: true,
      type: Object as PropType<JsonSchema>,
      default: () => ({}),
    },
    uischema: {
      required: true,
      type: Object as PropType<UISchemaElement>,
      default: () => ({}),
    },
    formData: {
      type: Object as PropType<JsonData>,
      default: () => ({}),
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    cancelHandler() {
      this.$emit('cancel');
    },
    updateDataHandler(data: JsonData) {
      this.$emit('updateData', data);
    },
    createHandler() {
      this.isLoading = true;

      const formRef: GeneratorForm = this.$refs.form as GeneratorForm;
      const valid: boolean = formRef?.validateForm() ?? false;
      const data: JsonData | null = formRef?.currentData ?? null;

      if (!valid || !data) {
        this.isLoading = false;

        return false;
      }

      this.$emit('create', data, () => {
        this.isLoading = false;
      });

      return true;
    },
  },
});
