import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_manager = _resolveComponent("the-manager")!

  return (_openBlock(), _createBlock(_component_the_manager, {
    notifications: _ctx.notifications,
    limit: this.$sizeWindow.isMd ? 3 : 5,
    onDeleteHandler: _ctx.deleteNotification
  }, null, 8, ["notifications", "limit", "onDeleteHandler"]))
}