
import { defineComponent, PropType } from 'vue';
import { CounterpartyCardItem } from '@/api/counterparty';
import ArrowDownIcon from '@/components/ui/icons/ArrowDownIcon.vue';
import IconButton from '@/components/ui/buttons/IconButton/IconButton.vue';
import OutsideClick from '@/utils/directives/OutsideClick';
import UserSwitchIcon from '@/components/ui/icons/UserSwitchIcon.vue';
import config from '@/config';

export default defineComponent({
  name: 'CardSwitcher',
  computed: {
    config() {
      return config;
    },
  },
  inject: ['$sizeWindow'],
  emits: ['change-card'],
  components: {
    UserSwitchIcon,
    IconButton,
    ArrowDownIcon,
  },
  props: {
    items: {
      type: Array as PropType<CounterpartyCardItem[]>,
      default: () => [],
    },
    currentCard: {
      type: Object as PropType<CounterpartyCardItem>,
      default: undefined,
    },
  },
  data() {
    return {
      isOpen: false,
      currentItem: this.currentCard ?? this.items[0],
    };
  },
  methods: {
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
    toggle() {
      this.isOpen = !this.isOpen;
    },
    formattedInfo(item: CounterpartyCardItem) {
      return `${item.division.name}::${item.counterpartyType.name}`;
    },
    changeCardHandler(id: string) {
      this.close();
      this.$emit('change-card', id);
    },
  },
  directives: {
    OutsideClick,
  },
  watch: {
    currentCard(value: CounterpartyCardItem) {
      this.currentItem = value;
    },
  },
});
