<template>
  <svg viewBox="0 0 10 6"
       fill="none"
       xmlns="http://www.w3.org/2000/svg"
       :class="{
         'rotate-[-90deg]': isOpen
       }"
       :width="width"
       :height="height"
  >
    <path d="M10 0.5H0L5 5.5L10 0.5Z" fill="#64748B"/>
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SelectArrow',
  props: {
    width: {
      type: String,
      default: '10',
    },
    height: {
      type: String,
      default: '6',
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style scoped lang="scss">
@import "src/components/ui/consts.scss";

svg {
  stroke: $light-label-color;
}
</style>
