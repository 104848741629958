
import { defineComponent } from 'vue';
import { REGULAR_TYPE, SOLID_TYPE } from '@/components/ui/icons/types';

export default defineComponent({
  name: 'EmailIcon',
  props: {
    type: {
      type: String,
      default: SOLID_TYPE,
    },
    width: {
      type: String,
      default: '20',
    },
    height: {
      type: String,
      default: '20',
    },
    color: {
      type: String,
      default: '#94A3B8',
    },
  },
  computed: {
    isSolid() {
      return this.type === SOLID_TYPE;
    },
    isRegular() {
      return this.type === REGULAR_TYPE;
    },
  },
});
