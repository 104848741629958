
import { defineComponent } from 'vue';
import { HistoryItemType } from '@/api/block/history';
import TheItem from '@/components/ui/tables/hierarchy/body/item/TheItem.vue';

export default defineComponent({
  name: 'TheBody',
  inject: ['$sizeWindow'],
  emits: ['itemClick'],
  components: {
    TheItem,
  },
  props: {
    variant: {
      type: String,
      default: 'expand',
    },
    items: {
      type: Object,
      default: () => ({}),
    },
    fields: {
      type: Object,
      default: () => ({}),
    },
    isAllOpen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    bodySize() {
      if (this.isSimple) {
        return '';
      }
      return this.$sizeWindow.isSm ? 'height-without-header' : 'height-with-header';
    },
    itemData() {
      return this.items.find((item: HistoryItemType) => item.id === this.currentContentId);
    },
    isSimple() {
      return this.variant === 'simple';
    },
    isExpand() {
      return this.variant === 'expand';
    },
  },
  methods: {
    itemClickHandler(id: string, event: Event) {
      this.currentContentId = id;
      const el: HTMLInputElement = event.target as HTMLInputElement;

      if (el.closest('.dropdown') === null && id) {
        this.$emit('itemClick', id);
      }
    },
  },
});
