
import { defineComponent, PropType, ref } from 'vue';
import { JsonSchema, UISchemaElement } from '@jsonforms/core';
import DefaultLoader from '@/components/ui/loaders/DefaultLoader.vue';
import TheTitle from '@/components/admin/dictionary/generator/title/TheTitle.vue';
import { FilterParams, PaginationParams, SortParams } from '@/api/base';
import { TableFieldObject, TableItem } from '@/components/ui/tables/simple/TheTable';
import TheTable from '@/components/ui/tables/simple/TheTable.vue';
import TheSideModal from '@/components/ui/modal/TheSideModal/TheSideModal.vue';
import TheDetail from '@/components/admin/dictionary/generator/detail/TheDetail.vue';
import TheCreate from '@/components/admin/dictionary/generator/create/TheCreate.vue';
import TheDangerModal from '@/components/ui/modal/TheDangerModal/TheDangerModal.vue';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import EditIcon from '@/components/ui/icons/EditIcon.vue';
import TrashIcon from '@/components/ui/icons/TrashIcon.vue';
import ContextMenu from '@/components/ui/menu/ContextMenu/ContextMenu.vue';
import TheFilter from '@/components/admin/dictionary/generator/filter/TheFilter.vue';
import statuses from '@/shared/consts/statuses';
import RefreshIcon from '@/components/ui/icons/RefreshIcon.vue';

export default defineComponent({
  name: 'DictionaryGenerator',
  emits: [
    'changeFilter',
    'changeSort',
    'changePagination',
    'deleteHandler',
    'updateHandler',
    'createHandler',
    'updateData',
    'closeSidebar',
    'onRestore',
  ],
  components: {
    DefaultLoader,
    TheTitle,
    TheTable,
    TheSideModal,
    TheDetail,
    TheCreate,
    TheDangerModal,
    TheFilter,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    isCanEdit: {
      type: Boolean,
      default: false,
    },
    isShowEdit: {
      type: Boolean,
      default: true,
    },
    isShowCreate: {
      type: Boolean,
      default: true,
    },
    isShowRemove: {
      type: Boolean,
      default: true,
    },
    filter: {
      type: Object as PropType<FilterParams>,
      default: () => ({}),
    },
    pagination: {
      type: Object as PropType<PaginationParams>,
      default: () => ({}),
    },
    sort: {
      type: Object as PropType<SortParams>,
      default: () => ({}),
    },
    items: {
      type: Array as PropType<TableItem[]>,
      default: () => ([]),
    },
    fields: {
      type: Array as PropType<TableFieldObject[]>,
      default: () => ([]),
    },
    schema: {
      required: true,
      type: Object as PropType<JsonSchema>,
      default: () => ({}),
    },
    uischema: {
      required: true,
      type: Object as PropType<UISchemaElement>,
      default: () => ({}),
    },
    formData: {
      type: Object as PropType<JsonData>,
      default: () => ({}),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isCreate: true,
      isUpdate: false,
      isOpenForm: false,
      isOpenConfirm: false,
    };
  },
  computed: {
    isDeletedStatus() {
      return Number(this.filter.status) === statuses.STATUS_TYPE_DELETE;
    },
    selectedItemData(): JsonData | undefined {
      return this.items.find((item: JsonData) => item.id === this.selectedItemId) ?? {};
    },
    hasSelectedData() {
      return !!Object.keys(this.selectedItemData).length;
    },
    isShowEditForm(): boolean {
      return this.isCanEdit && this.isShowEdit;
    },
    isShowCreateForm(): boolean {
      return this.isCanEdit && this.isShowCreate;
    },
    isShowRemoveForm(): boolean {
      return this.isCanEdit && this.isShowRemove;
    },
    preparedItems() {
      const items: TableItem[] = this.items ?? [];

      return items.map((item: TableItem) => ({
        ...item,
        action: {
          type: 'component',
          component: ContextMenu,
          props: this.contextMenuProps(item),
          isVisible: this.isCanEdit,
        },
      }));
    },
  },
  methods: {
    openForm() {
      this.isOpenForm = true;
    },
    closeForm() {
      this.isOpenForm = false;
      this.isCreate = false;
      this.isUpdate = false;
      this.selectedItemId = null;
      this.$emit('closeSidebar');
    },
    openInfo(id: string) {
      this.isOpenForm = true;
      this.isCreate = false;
      this.isUpdate = false;
      this.selectedItemId = id;
    },
    openCreateForm() {
      this.isCreate = true;
      this.openForm();
    },
    openConfirmModal() {
      this.isOpenConfirm = true;
    },
    confirmCancel() {
      this.isOpenConfirm = false;
    },
    changeModeHandler(value: boolean) {
      this.isUpdate = value;
    },
    changeFilter(filter: FilterParams) {
      this.$emit('changeFilter', filter);
    },
    changeSort(sort: SortParams) {
      this.$emit('changeSort', sort);
    },
    changePagination(pagination: PaginationParams) {
      this.$emit('changePagination', pagination);
    },
    updateDataHandler(data: JsonData) {
      this.$emit('updateData', data);
    },
    async createHandler(data: JsonData, next: () => void) {
      await this.$emit('createHandler', data, () => {
        next();

        this.closeForm();
      });
    },
    async updateHandler(data: JsonData, next: () => void) {
      if (this.selectedItemId) {
        const newData = {
          id: this.selectedItemId,
          data,
        };

        this.$emit('updateHandler', newData, () => {
          next();

          this.closeForm();
        });
      }
    },
    async restoreHandler() {
      await this.$emit('onRestore', this.selectedItemId);

      this.closeForm();
    },
    dangerApprovalHandler(next: () => void) {
      this.$emit('deleteHandler', this.selectedItemId);

      this.confirmCancel();
      this.closeForm();

      next();
    },
    contextMenuProps(item: TableItem) {
      return this.isDeletedStatus
        ? this.contextMenuForDeleted(item)
        : this.contextMenuForActive(item);
    },

    contextMenuForActive(item: TableItem) {
      return {
        params: [
          {
            text: this.$t('buttons.edit'),
            icon: EditIcon,
            events: {
              click: () => {
                this.isOpenForm = true;
                this.isUpdate = true;
                this.isCreate = false;
                this.selectedItemId = item.id;
              },
            },
            isVisible: this.isShowEditForm,
          },
          {
            text: this.$t('buttons.delete'),
            icon: TrashIcon,
            events: {
              click: () => {
                this.isOpenConfirm = true;
                this.selectedItemId = item.id;
              },
            },
            iconProps: {
              type: 'outline',
            },
            isVisible: this.isShowRemoveForm,
          },
        ],
      };
    },
    contextMenuForDeleted(item: TableItem) {
      return {
        params: [
          {
            text: this.$t('buttons.restore'),
            icon: RefreshIcon,
            iconProps: {
              type: 'outline',
            },
            events: {
              click: () => {
                this.$emit('onRestore', item.id);
              },
            },
            isVisible: this.isShowEditForm,
          },
        ],
      };
    },
  },
  setup() {
    const selectedItemId = ref<string | number | null>(null);

    return { selectedItemId };
  },
});
