<template>
  <svg
    class="inline w-[12px] h-[12px] mb-[3px] ml-[4px]"
    :class="direction === 'desc' ? 'rotate-180' : ''"
    width="64.631" height="73.153" viewBox="0 0 64.631 73.153" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M 36.967 73.153 L 27.734 73.153 L 27.734 17.33 L 6.463 38.672 L 0 32.28 L 32.351 0
 L 64.631 32.28 L 58.31 38.672 L 36.967 17.33 L 36.967 73.153 Z"
      vector-effect="non-scaling-stroke"
      :fill="fill"
      :fill-opacity="fillOpacity"
    />
  </svg>

</template>

<script>
export default {
  name: 'SortArrow',
  props: {
    direction: {
      type: String,
      default: 'asc',
    },
    fill: {
      type: String,
      default: '#64748B',
    },
    fillOpacity: {
      type: Number,
      default: 0.5,
    },
  },
};
</script>
