import { Module } from 'vuex';
import { StateType } from '@/store';
import CounterpartyService, { CounterpartyCardItem, CounterpartyCardsResponseType } from '@/api/counterparty';
import { AxiosCallResponse } from '@/api/base';

export interface CounterpartyOneStateType {
  cards: CounterpartyCardItem[],
}

const getInitialState = ():CounterpartyOneStateType => ({
  cards: [],
});

const initialState = getInitialState();

const counterpartyOneModule: Module<CounterpartyOneStateType, StateType> = {
  namespaced: true,
  state: initialState,
  getters: {
    cards(state): CounterpartyCardItem[] {
      return state.cards;
    },
  },
  mutations: {
    setCards(state, cards) {
      state.cards = cards;
    },
  },
  actions: {
    async loadCards({ rootGetters, commit }, id: string) {
      const api: CounterpartyService = rootGetters.$api.counterparty;

      const response: AxiosCallResponse<CounterpartyCardsResponseType> = await api
        .getCounterpartyCards(id);

      commit('setCards', response.data?.items ?? initialState.cards);
    },
  },
};

export default counterpartyOneModule;
