
import { defineComponent, PropType } from 'vue';
import { ErrorObject } from 'ajv';
import { JsonFormsChangeEvent } from '@jsonforms/vue';
import { JsonSchema } from '@jsonforms/core';
import config from '@/config';
import FormGenerator from '@/components/jsonforms/generators/FormGenerator.vue';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import CustomUISchemaElement from '@/components/jsonforms/interfaces/CustomUISchemaElement';
import { validateData } from '@/utils/helpers/AjvValidator';
import useShowErrors from '@/composables/form/useShowErrors';

export interface AgentForm {
  data: JsonData,
  currentData: JsonData,
  schema: JsonSchema,
  uischema: CustomUISchemaElement,
  errors: JsonData,
  validateForm: (showErrors?: boolean) => boolean,
  showErrors: () => void,
  onChange: () => void,
  clearData: () => void,
}

export default defineComponent({
  name: 'TheForm',
  components: {
    FormGenerator,
  },
  props: {
    schema: {
      required: true,
      type: Object as PropType<JsonSchema>,
    },
  },
  data() {
    return {
      uischema: {
        type: 'VerticalLayout',
        elements: [
          {
            type: 'Control',
            label: 'ФИО',
            scope: `#/properties/${config.COUNTERPARTY_AGENT_FULL_NAME_FIELD}`,
          },
          {
            type: 'AjaxControl',
            label: 'Должность',
            scope: `#/properties/${config.COUNTERPARTY_AGENT_POSITION_FIELD}`,
            options: {
              search: true,
              ajaxConfiguration: {
                categoryName: 'position',
              },
            },
          },
          {
            type: 'Control',
            label: 'Телефон',
            scope: `#/properties/${config.COUNTERPARTY_AGENT_PHONE_ITEM}`,
            options: {
              showSortButtons: false,
              detail: {
                type: 'HorizontalLayout',
                elements: [
                  {
                    type: 'PhoneControl',
                    label: 'Телефон',
                    scope: `#/properties/${config.COUNTERPARTY_AGENT_PHONE_FIELD}`,
                  },
                  {
                    type: 'Control',
                    label: 'Тип',
                    scope: `#/properties/${config.COUNTERPARTY_AGENT_PHONE_TYPE}`,
                  },
                ],
              },
            },
          },
          {
            type: 'Control',
            label: 'Почта',
            scope: `#/properties/${config.COUNTERPARTY_AGENT_EMAIL_ITEM}`,
            options: {
              showSortButtons: false,
              detail: {
                type: 'HorizontalLayout',
                elements: [
                  {
                    type: 'Control',
                    label: 'Почта',
                    scope: `#/properties/${config.COUNTERPARTY_AGENT_EMAIL_FIELD}`,
                  },
                  {
                    type: 'Control',
                    label: 'Тип',
                    scope: `#/properties/${config.COUNTERPARTY_AGENT_EMAIL_TYPE}`,
                  },
                ],
              },
            },
          },
        ],
      },
      data: {},
      errors: [] as ErrorObject[],
    };
  },
  methods: {
    clearData() {
      this.data = {};
      this.errors = [];
    },
    onChange(event: JsonFormsChangeEvent) {
      this.data = event.data;
    },
    validateForm(showErrors = false): boolean {
      this.errors = validateData(this.data, this.schema, this.uischema);

      if (showErrors) {
        this.showErrors(this.errors);
      }

      return !this.errors || !this.errors.length;
    },
  },
  setup() {
    const { showErrors } = useShowErrors();

    return { showErrors };
  },
});
