import { defineComponent, ref } from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { NewsAddType } from '@/api/news';
import TableDefault
  from '@/components/counterparty/card/blocks/news/table/TableDefault.vue';
import TablePagination
  from '@/components/counterparty/card/blocks/news/table/pagination/TablePagination.vue';
import NewsAddModal
  from '@/components/counterparty/card/blocks/news/AddModal/TheAddModal.vue';
import TheDangerModal
  from '@/components/ui/modal/TheDangerModal/TheDangerModal.vue';
import NewsItem
  from '@/components/counterparty/card/blocks/news/NewsItem/NewsItem.vue';
import DatepickerPeriod from '@/components/ui/datepickers/datepicker-period/DatepickerPeriod.vue';

export default defineComponent({
  name: 'TheNews',
  inject: ['$sizeWindow'],
  components: {
    DatepickerPeriod,
    TableDefault,
    TablePagination,
    NewsAddModal,
    TheDangerModal,
    NewsItem,
  },
  props: {
    isCanEdit: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const newsDeleteId = ref<null | number>(null);
    const newsEditId = ref<null | number>(null);
    return { newsDeleteId, newsEditId };
  },
  computed: {
    ...mapGetters({
      news: 'news/news',
      pagination: 'news/pagination',
      filter: 'news/filter',
      sort: 'news/sort',
    }),
    datepickerVisible() {
      return this.news.length > 0 || !!this.filter.afterDate || !!this.filter.beforeDate;
    },
  },
  methods: {
    ...mapActions({
      loadNews: 'news/loadNews',
      changePagination: 'news/changePagination',
      changeFilter: 'news/changeFilter',
      changeSort: 'news/changeSort',
      addNews: 'news/addNews',
      deleteNews: 'news/deleteNews',
      updateNews: 'news/updateNews',
    }),
    async newsHandler(data: NewsAddType, next: () => void) {
      const updateData = { id: this.newsEditId, ...data };

      if (this.newsEditId) {
        await this.updateNews(updateData);
      } else {
        await this.addNews(data);
      }

      next();
    },
    actionEdit(id: number) {
      this.newsEditId = id;
    },
    async actionDelete(next: () => void) {
      if (this.newsDeleteId) {
        await this.deleteNews(this.newsDeleteId);

        this.newsDeleteId = null;

        next();
      }
    },
    openRemoveModal(id: number) {
      this.newsDeleteId = id;
    },
    closeDeleteModal() {
      this.newsDeleteId = null;
    },
    closeUpdateModal() {
      this.newsEditId = null;
    },
    getNewsLength() {
      return this.news.length;
    },
  },
  async created() {
    await this.loadNews();
  },
});
