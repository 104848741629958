
import { defineComponent } from 'vue';
import store from '@/store';
import DefaultLoader from '@/components/ui/loaders/DefaultLoader.vue';
import { isArray } from 'lodash';

export default defineComponent({
  name: 'TheReport',
  methods: {
    isArray,
    replaceBoolean(value: string) {
      if (typeof value === 'boolean') {
        return String(value)
          .replace('false', 'Нет')
          .replace('true', 'Да');
      }

      return value;
    },
  },
  components: { DefaultLoader },
  data() {
    return {
      isLoading: false,
      data: null,
    };
  },
  async mounted() {
    const api = store.getters.$api.card;

    this.isLoading = true;

    const response = await api.getReportData(this.$route.params.cardId);

    this.data = response.data;

    this.isLoading = false;
  },
});
