import { Module } from 'vuex';
import { StateType } from '@/store';
import ConsumptionRegistryService from '@/api/block/registry/consumptionRegistry';
import { AxiosCallResponse } from '@/api/base';
import { RegistryInfo } from '@/api/block/registry/base';

export interface ConsumptionRegistryInfoStateType {
  filterState: RegistryInfo,
}

const getInitialState = () => ({
  filterState: {
    existingYears: null,
    existingGroups: null,
  },
});

const consumptionInfoModule: Module<ConsumptionRegistryInfoStateType, StateType> = {
  namespaced: true,
  state: getInitialState(),
  getters: {
    filterState: (state): RegistryInfo => state.filterState,
  },
  mutations: {
    setFilter(state, filter) {
      state.filterState = filter;
    },
  },
  actions: {
    async loadFilterState({ rootGetters, commit }, cardId: string) {
      const api: ConsumptionRegistryService = rootGetters.$api.blocks.consumptionRegistry;

      const response: AxiosCallResponse<RegistryInfo> = await api.info(cardId);

      commit('setFilter', response.data ?? getInitialState().filterState);
    },
  },
};

export default consumptionInfoModule;
