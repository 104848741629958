import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e59919c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "block" }
const _hoisted_3 = { class: "block__image" }
const _hoisted_4 = ["alt", "src"]
const _hoisted_5 = { class: "block__text" }
const _hoisted_6 = { class: "status" }
const _hoisted_7 = { class: "text" }
const _hoisted_8 = { class: "subtext" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          alt: _ctx.text,
          src: _ctx.url
        }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.status), 1),
        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.text), 1),
        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.subtext), 1)
      ])
    ])
  ]))
}