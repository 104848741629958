import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5b2da4ef"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "relative" }
const _hoisted_2 = { class: "p-[16px] align-baseline" }
const _hoisted_3 = { class: "w-[120px]" }
const _hoisted_4 = { class: "p-[16px] align-baseline" }
const _hoisted_5 = { class: "p-[16px] align-baseline" }
const _hoisted_6 = { class: "w-[250px]" }
const _hoisted_7 = ["href"]
const _hoisted_8 = { class: "w-full h-[70px] flex flex-col items-center justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_context_menu = _resolveComponent("context-menu")!

  return (_openBlock(), _createElementBlock("tr", _hoisted_1, [
    _createElementVNode("td", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.newItem.publishedAt), 1)
    ]),
    _createElementVNode("td", _hoisted_4, [
      _createElementVNode("div", {
        class: _normalizeClass(["w-[300px] flex flex-col justify-center items-start text-carousel", { open: _ctx.isOpen }])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["w-[300px]", {'truncate-text': _ctx.contentLength > 100 && !_ctx.isOpen}])
        }, _toDisplayString(_ctx.newItem.content), 3),
        (_ctx.contentLength > 100)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleShow && _ctx.toggleShow(...args))),
              class: "inline-button"
            }, _toDisplayString(_ctx.isOpen ? _ctx.$t('buttons.hide') + ' ↑' : _ctx.$t('buttons.show')), 1))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    _createElementVNode("td", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("a", {
          href: _ctx.newItem.link,
          target: "_blank",
          class: "text-[#]"
        }, _toDisplayString(_ctx.shortLink), 9, _hoisted_7)
      ])
    ]),
    _createElementVNode("td", null, [
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_context_menu, {
          params: _ctx.contextMenuParams,
          id: _ctx.newItem.id,
          onActionEmit: _ctx.actionHandler
        }, null, 8, ["params", "id", "onActionEmit"])
      ])
    ])
  ]))
}