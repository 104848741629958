import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-67636916"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "agent-card-avatar" }
const _hoisted_2 = {
  key: 0,
  class: "agent-card-avatar--status"
}
const _hoisted_3 = { class: "agent-card-info-block" }
const _hoisted_4 = { class: "agent-card-info" }
const _hoisted_5 = { class: "agent-card-title" }
const _hoisted_6 = { class: "agent-card-text" }
const _hoisted_7 = { class: "agent-card-text" }
const _hoisted_8 = { class: "agent-card-text" }
const _hoisted_9 = { class: "break-all" }
const _hoisted_10 = { class: "agent-card-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_image_avatar = _resolveComponent("image-avatar")!
  const _component_blocked_icon = _resolveComponent("blocked-icon")!
  const _component_briefcase_icon = _resolveComponent("briefcase-icon")!
  const _component_phone_icon = _resolveComponent("phone-icon")!
  const _component_email_icon = _resolveComponent("email-icon")!
  const _component_context_menu = _resolveComponent("context-menu")!

  return (_openBlock(), _createElementBlock("div", {
    class: "agent-card cursor-pointer",
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openView && _ctx.openView(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_image_avatar, { letter: _ctx.nameInitial }, null, 8, ["letter"]),
      (_ctx.isBlockedStatus)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_blocked_icon)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.fullName), 1),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", null, [
            _createVNode(_component_briefcase_icon, {
              type: "solid",
              color: "#94A3B8",
              width: "20",
              height: "20"
            })
          ]),
          _createElementVNode("span", null, _toDisplayString(_ctx.position), 1)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", null, [
            _createVNode(_component_phone_icon)
          ]),
          _createElementVNode("span", null, _toDisplayString(_ctx.phone), 1)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", null, [
            _createVNode(_component_email_icon)
          ]),
          _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.email), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createVNode(_component_context_menu, {
        "close-on-select": true,
        params: _ctx.contextMenuParams,
        onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
      }, null, 8, ["params"])
    ])
  ]))
}