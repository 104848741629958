import { JsonSchema, UISchemaElement } from '@jsonforms/core';
import { defineComponent, PropType } from 'vue';
import config from '@/config';
import TheAvatar from '@/components/counterparty/card/blocks/agents/modal/detail/avatar/TheAvatar.vue';
import DefaultButton from '@/components/ui/buttons/DefaultButton/DefaultButton.vue';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import EditForm, { DetailAgentForm } from '@/components/counterparty/card/blocks/agents/form/update/TheForm.vue';
import ViewForm from '@/components/counterparty/card/blocks/agents/form/view/TheForm.vue';
import EditIcon from '@/components/ui/icons/EditIcon.vue';
import IconButton from '@/components/ui/buttons/IconButton/IconButton.vue';
import TheSwitch from '@/components/ui/switch/TheSwitch.vue';
import BriefcaseIcon from '@/components/ui/icons/BriefcaseIcon.vue';
import EmailIcon from '@/components/ui/icons/EmailIcon.vue';
import PhoneIcon from '@/components/ui/icons/PhoneIcon.vue';
import UserIcon from '@/components/ui/icons/UserIcon.vue';
import RightArrowIcon from '@/components/ui/icons/RightArrowIcon.vue';
import BuildingIcon from '@/components/ui/icons/BuildingIcon.vue';
import { AgentSchema } from '@/api/agent';
import useStringHelper from '@/composables/helpers/text/useStringHelper';
import UnlockIcon from '@/components/ui/icons/UnlockIcon.vue';
import LockIcon from '@/components/ui/icons/LockIcon.vue';

export default defineComponent({
  name: 'TheDetail',
  emits: ['changedEditMode', 'close', 'submit', 'changeUserStatus'],
  components: {
    LockIcon,
    UnlockIcon,
    DefaultButton,
    EditForm,
    ViewForm,
    TheAvatar,
    EditIcon,
    IconButton,
    TheSwitch,
    BriefcaseIcon,
    EmailIcon,
    PhoneIcon,
    UserIcon,
    RightArrowIcon,
    BuildingIcon,
  },
  props: {
    data: {
      type: Object as PropType<AgentSchema>,
      default: () => ({}),
    },
    schema: {
      required: true,
      type: Object as PropType<JsonSchema>,
      default: undefined,
    },
    uischema: {
      required: true,
      type: Object as PropType<UISchemaElement>,
      default: undefined,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    isCanEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      isEditMode: this.isEdit,
      isShowUserInfo: true,
    };
  },
  watch: {
    isEdit() {
      this.isEditMode = this.isEdit;
    },
    isEditMode(value: boolean) {
      this.$emit('changedEditMode', value);
    },
  },
  computed: {
    fullName(): string {
      return this.data[config.COUNTERPARTY_AGENT_FULL_NAME_FIELD] ?? '';
    },
    nameInitial(): string {
      return useStringHelper().getLetter(this.fullName);
    },
    isActive() {
      return this.data.status === config.STATUS_AGENT_IS_ACTIVE;
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');

      this.clearData();
    },
    toggleEditMode() {
      this.isEditMode = !this.isEditMode;
    },
    changeUserStatus() {
      this.$emit('changeUserStatus', this.isActive);
    },
    clearData() {
      const formRef: DetailAgentForm = this.$refs.form as DetailAgentForm;

      formRef?.clearData();
    },
    approvalHandler() {
      this.isLoading = true;

      const formRef: DetailAgentForm = this.$refs.form as DetailAgentForm;
      const valid: boolean = formRef?.validateForm(true) ?? false;
      const data: JsonData | null = formRef?.dataBySchema ?? null;

      if (!valid || !data) {
        this.isLoading = false;

        return false;
      }

      this.$emit('submit', this.data.id, data, () => {
        this.isLoading = false;

        this.closeModal();
      });

      return true;
    },
  },
});
