import store from '@/store';
import StructureService, { DivisionResponseType, FilterDivisionParams } from '@/api/admin/dictionary/structure';

export function useListApi() {
  return {
    async loadItems(params: FilterDivisionParams) {
      const api: StructureService = store.getters.$api.admin.dictionary.structure;

      const response: DivisionResponseType = await api.divisionList(
        params,
      );

      return response.data?.items ?? [];
    },
  };
}

export default {};
