import { DirectiveBinding, ComponentPublicInstance } from 'vue';

export type CalculatePositionInstance = ComponentPublicInstance<{
  calculatePosition: {
    extraHeight?: number,
  },
  $refs: {
    mainBlock: HTMLElement,
    calculatedBlock: HTMLElement,
  },
}>

export default (el: HTMLDivElement, bindings: DirectiveBinding) => {
  const bindingInstance = bindings.instance as CalculatePositionInstance;
  if (bindingInstance?.calculatePosition) {
    const mainBlock: HTMLElement = bindingInstance.$refs?.mainBlock;
    const calculatedBlock: HTMLElement = bindingInstance.$refs?.calculatedBlock;

    const {
      height: mainBlockHeight,
    } = mainBlock.getBoundingClientRect();

    const {
      bottom: calculatedBlockBottom,
      right: calculatedBlockRight,
      left: calculatedBlockLeft,
      width: calculatedBlockWidth,
    } = calculatedBlock.getBoundingClientRect();

    const extraHeight: number = bindingInstance.calculatePosition?.extraHeight ?? 0;

    if ((calculatedBlockBottom + extraHeight) > window.innerHeight) {
      // eslint-disable-next-line no-param-reassign
      el.style.bottom = `${mainBlockHeight}px`;
      // eslint-disable-next-line no-param-reassign
      el.style.top = 'auto';
      el.classList.add('top');
    } else {
      // eslint-disable-next-line no-param-reassign
      el.style.top = `${mainBlockHeight}px`;
      // eslint-disable-next-line no-param-reassign
      el.style.bottom = 'auto';
      el.classList.remove('top');
    }

    if ((calculatedBlockRight) > window.innerWidth) {
      if ((calculatedBlockLeft - calculatedBlockWidth) < 0) {
        // eslint-disable-next-line no-param-reassign
        el.style.right = `-${calculatedBlockWidth / 2}px`;
      } else {
        // eslint-disable-next-line no-param-reassign
        el.style.right = '0px';
      }
    }
  }
};
