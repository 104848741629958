import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lock_keyhole_icon = _resolveComponent("lock-keyhole-icon")!
  const _component_the_badge = _resolveComponent("the-badge")!

  return (_openBlock(), _createBlock(_component_the_badge, {
    size: "xl",
    variant: "default"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_lock_keyhole_icon),
      _createTextVNode(" " + _toDisplayString(_ctx.$t('common.blocked')), 1)
    ]),
    _: 1
  }))
}