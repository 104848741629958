
import { defineComponent } from 'vue';
import TheTooltip from '@/components/ui/modal/TheTooltip/TheTooltip.vue';
import ShieldCheckIcon from '@/components/ui/icons/ShieldCheckIcon.vue';

export default defineComponent({
  name: 'ShieldTooltip',
  components: {
    TheTooltip,
    ShieldCheckIcon,
  },
});
