
import { defineComponent } from 'vue';
import { IMessage } from '@novu/notification-center';
import { NotificationCenterComponent } from '@novu/notification-center-vue';
import { mapGetters } from 'vuex';
import IconButton from '@/components/ui/buttons/IconButton/IconButton.vue';
import BellIcon from '@/components/ui/icons/BellIcon.vue';

export default defineComponent({
  name: 'TheCustomNotification',
  components: {
    BellIcon,
    IconButton,
    NotificationCenterComponent,
  },
  computed: {
    ...mapGetters({
      userData: 'user/localUserData',
    }),
    userId() {
      return this.userData?.id;
    },
  },
  methods: {
    onNotificationClick(message: IMessage): void {
      if (message?.cta?.data?.url) {
        this.$router.push({ path: message.cta.data.url });
      }
    },
  },
  setup() {
    const appNovuId: string = process.env.VUE_APP_NOVU_APPLICATION_ID;
    const apiNovuUrl: string = process.env.VUE_APP_NOVU_API_URL;
    const wsNovuUrl: string = process.env.VUE_APP_NOVU_WEBSOCKET_URL;

    return {
      appNovuId,
      apiNovuUrl,
      wsNovuUrl,
    };
  },
});
