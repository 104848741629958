import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { PinningItem } from '@/api/admin/pinning';
import config from '@/config';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import TheModal from '@/components/ui/modal/TheModal/TheModal.vue';
import AddIcon from '@/components/ui/icons/AddIcon.vue';
import { ErrorInterface } from '@/store/exception/ErrorInterface';
import ModalContent
  from '@/components/counterparty/list/modal/create/content/TheContent.vue';
import TheSideModal from '@/components/ui/modal/TheSideModal/TheSideModal.vue';
import TheAssign from '@/components/counterparty/list/modal/assign/TheAssign.vue';
import { CounterpartyItemType } from '@/api/mdm';
import DefaultLoader from '@/components/ui/loaders/DefaultLoader.vue';
import TheDangerModal from '@/components/ui/modal/TheDangerModal/TheDangerModal.vue';
import fields from '@/shared/consts/counterparty/fields';
import { UserListFilterParams } from '@/api/admin/user';
import groups from '@/shared/consts/groups';
import DefaultButton from '@/components/ui/buttons/DefaultButton/DefaultButton.vue';

export default defineComponent({
  name: 'AddCounterpartyModal',
  components: {
    DefaultButton,
    TheModal,
    AddIcon,
    ModalContent,
    TheSideModal,
    TheAssign,
    DefaultLoader,
    TheDangerModal,
  },
  data() {
    return {
      isOpen: false,
      isLoading: false,
      isOpenDangerModal: false,
      assignData: {},
      counterpartyData: null as JsonData | null,
      createFromSearch: false,
    };
  },
  computed: {
    ...mapGetters({
      schema: 'schema/schema',
      divisions: 'admin/dictionary/structure/division/items',
      departments: 'admin/dictionary/structure/list/items',
      users: 'admin/user/list/items',
      user: 'user/localUserData',
    }),
    generalBlockSchema() {
      return this.schema.properties[config.COUNTERPARTY_GENERAL_INFORMATION_BLOCK] ?? {};
    },
  },
  watch: {
    async isOpen(value: boolean) {
      if (value) {
        await this.loadDefaultData();
      }
    },
    async assignData(data: PinningItem, lastData: PinningItem) {
      if (data.division && data.division !== lastData.division) {
        await this.filterStructure({
          parentId: data.division,
        });

        if (lastData.division) {
          this.assignData.department = undefined;
          this.assignData.user = undefined;
        }

        await this.loadStructure();
      }

      if (data.department && data.department !== lastData.department) {
        await this.changeFilterUsers({
          division: data.division,
          department: data.department,
        });

        if (lastData.department) {
          this.assignData.user = undefined;
        }
      }

      if (!data.division && data.division !== lastData.division) {
        this.assignData.department = undefined;
        this.assignData.user = undefined;
      }

      if (!data.department && data.department !== lastData.department) {
        this.assignData.user = undefined;
      }
    },
  },
  methods: {
    ...mapActions({
      loadSchema: 'schema/loadSchema',
      loadDivisions: 'admin/dictionary/structure/division/loadItems',
      filterStructure: 'admin/dictionary/structure/list/changeFilter',
      loadStructure: 'admin/dictionary/structure/list/loadItems',
      changeFilterUsers: 'admin/user/list/changeFilter',
      loadUsers: 'admin/user/list/loadItems',
      addCounterparty: 'counterparty/list/addCounterparty',
      assignCounterparty: 'counterparty/list/assignCounterparty',
      searchCounterparties: 'search/counterpartyList/loadItems',
    }),
    openModal() {
      this.isOpen = true;
    },
    closeModal() {
      if (this.counterpartyData !== null) {
        this.isOpenDangerModal = true;

        return;
      }

      this.approveCloseHandler();
    },
    approveCloseHandler(next?: () => void) {
      this.isOpen = false;
      this.counterpartyData = null;

      if (next) {
        next();
      }

      this.setDefaultAssignData();
      this.closeDangerModal();
    },
    closeDangerModal() {
      this.isOpenDangerModal = false;
    },
    async submitHandler(
      data: JsonData,
      next: (error?: boolean) => void,
      createFromSearch: boolean,
    ) {
      this.counterpartyData = data;
      this.createFromSearch = createFromSearch;

      next();
    },
    async searchHandler(
      query: string,
      successCallback: (items: JsonData[]) => void,
      errorCallback: (error: ErrorInterface) => void,
    ) {
      try {
        const items: CounterpartyItemType[] = await this.searchCounterparties(query);

        successCallback(items);
      } catch (err: unknown) {
        const error = err as ErrorInterface;

        errorCallback(error);
      }
    },
    updateDataHandler(data: PinningItem) {
      this.assignData = data;
    },
    async createCounterparty(data: JsonData, createFromSearch: boolean) {
      const counterpartyData = await this.addCounterparty({
        block: config.COUNTERPARTY_GENERAL_INFORMATION_BLOCK,
        data,
        createFromSearch,
      });

      return counterpartyData?.data?.id ?? null;
    },
    async assignHandler(data: PinningItem) {
      const counterpartyId = await this.createCounterparty(
        this.counterpartyData,
        this.createFromSearch,
      );

      if (counterpartyId) {
        const counterpartyType = this.counterpartyData[fields.VIEW_FIELD] ?? null;
        const preparedData = { ...data, counterpartyType };

        await this.assignCounterparty({ counterpartyId, data: preparedData });
      }

      this.approveCloseHandler();
    },
    setDefaultAssignData() {
      this.assignData = {
        division: this.user?.division ?? null,
        department: this.user?.department ?? null,
      };
    },
    async ajaxUsersHandler(filter: UserListFilterParams) {
      await this.changeFilterUsers(filter);
    },
    async loadDefaultData() {
      this.isLoading = true;

      this.setDefaultAssignData();
      await this.loadDivisions();
      await this.loadSchema({ group: groups.GENERAL_INFORMATION });

      this.isLoading = false;
    },
  },
});
