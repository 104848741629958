import { Module } from 'vuex';
import seminarHistory, { SeminarHistoryStateType } from '@/store/modules/counterparty/block/relationship/seminarHistory';
import salesSupportHistory, { SalesSupportHistoryStateType } from '@/store/modules/counterparty/block/relationship/salesSupportHistory';
import conflictHistory, { ConflictHistoryStateType } from '@/store/modules/counterparty/block/relationship/conflictHistory';
import implementation, { ImplementationStateType } from '@/store/modules/counterparty/block/characteristic/implementation';
import keyProductionAssets, { KeyProductionAssetsStateType } from '@/store/modules/counterparty/block/general/keyProductionAssets';
import communication, { CommunicationStateType } from '@/store/modules/counterparty/block/relationship/communication';
import { StateType } from '@/store';
import productionIndicators, { ProductionIndicatorsStateType } from '@/store/modules/counterparty/block/characteristic/productionIndicators';
import presenceOfBrands, { PresenceOfBrandsStateType } from '@/store/modules/counterparty/block/strategy/presenceOfBrands';
import consumptionRegistry, { ConsumptionRegistryStateType } from '@/store/modules/counterparty/block/registry/consumptionRegistry';
import deliveryRegistry, { DeliveryRegistryStateType } from '@/store/modules/counterparty/block/registry/deliveryRegistry';
import relationshipBreak, {
  RelationshipBreakStateType,
} from '@/store/modules/counterparty/block/relationship/relationshipBreak';
import assignHistory, { AssignHistoryStateType } from '@/store/modules/counterparty/block/general/assignHistory';

export interface BlockStateType {
  keyProductionAssets: KeyProductionAssetsStateType,
  implementation: ImplementationStateType,
  communication: CommunicationStateType,
  conflictHistory: ConflictHistoryStateType,
  salesSupportHistory: SalesSupportHistoryStateType,
  seminarHistory: SeminarHistoryStateType,
  productionIndicators: ProductionIndicatorsStateType,
  presenceOfBrands: PresenceOfBrandsStateType,
  consumptionRegistry: ConsumptionRegistryStateType,
  deliveryRegistry: DeliveryRegistryStateType,
  relationshipBreak: RelationshipBreakStateType,
  assignHistory: AssignHistoryStateType,
}

const blockModule: Module<BlockStateType, StateType> = {
  namespaced: true,
  modules: {
    communication,
    keyProductionAssets,
    implementation,
    conflictHistory,
    salesSupportHistory,
    seminarHistory,
    productionIndicators,
    presenceOfBrands,
    consumptionRegistry,
    deliveryRegistry,
    relationshipBreak,
    assignHistory,
  },
};

export default blockModule;
