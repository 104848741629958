import DictionaryBase, { DictionaryListResponseType } from '@/api/admin/dictionary/base';
import { LoggerInterface } from '@/api/base';

export interface PositionSchema {
  id: string,
  name: string,
}

export interface PositionData {
  name: string,
}

export type PositionListResponseType
  = DictionaryListResponseType<PositionSchema>;

export default class PositionService extends DictionaryBase {
  constructor(token: string, logger: LoggerInterface = console) {
    super('position', token, logger);
  }
}
