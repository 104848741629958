export const role = Object.freeze({
  ADMIN: 'admin',
});

const config = Object.freeze({
  DATE_FORMAT: 'dd.MM.yyyy',
  DATE_FORMAT_API: 'yyyy-MM-dd',

  COUNTERPARTY_GENERAL_INFORMATION_BLOCK: '253b635d-2dce-4c29-b6b3-4bf205ca7a8b',
  COUNTERPARTY_MDM_DATA_BLOCK: '6511b48b-4822-4f32-9834-bfb286a09b66',
  COUNTERPARTY_AGENT_BLOCK: '053d3a63-9594-436e-8074-b179e610d967',
  COUNTERPARTY_PAYMENT_BLOCK: 'bac759c8-551a-4946-8590-925a3b2a7958',
  COUNTERPARTY_COMMUNICATION_BLOCK: '815967cc-45fb-4c1f-a959-e1601fb6fa07',
  COUNTERPARTY_RELATIONSHIP_BREAKS_BLOCK: '7901aab4-d945-430a-9133-9b37ba8d31bf',
  COUNTERPARTY_CONFLICT_HISTORY_BLOCK: 'aea26f0c-203e-496c-bb94-451edc795e73',
  COUNTERPARTY_SALES_SUPPORT_HISTORY_BLOCK: '9b48e812-a84a-4bff-be34-e2263bcd0225',
  COUNTERPARTY_SEMINAR_HISTORY_BLOCK: '9ff0dbdc-ad95-4a4a-b8da-037005df2e82',
  COUNTERPARTY_KEY_PRODUCTION_ASSETS_BLOCK: '83e72998-ff17-4912-9090-b20c61169409',
  COUNTERPARTY_IMPLEMENTATION_BLOCK: '3ec5f749-0dce-4733-86cf-4ccd33d3614c',
  COUNTERPARTY_PRODUCTION_INDICATORS: '47ac291c-e034-402a-bb2e-e9c5f72db7cf',
  COUNTERPARTY_PRESENCE_OF_BRANDS: '3be7a1b7-fbef-47b8-817e-6e47383eb72b',
  COUNTERPARTY_CONSUMPTION_REGISTRY: '2a0018cc-0edf-422b-b533-8478c3c6a027',
  COUNTERPARTY_DELIVERY_REGISTRY: '4ab30b3b-724b-4283-a6d9-9c0ab40a9068',

  COUNTERPARTY_SITE_FIELD: '9737b181-56eb-443f-bd02-20a4383518c5',
  COUNTERPARTY_DEPARTMENT_FIELD: '04bc1937-e02b-4280-b0cb-e3b1a0662e20',
  COUNTERPARTY_MANAGER_FIELD: '460328d1-502c-4c7c-9919-4cb72b6d4646',
  COUNTERPARTY_FINANCIAL_YEAR_FIELD: 'f67f8eab-0735-4b2d-814d-3213035e6773',
  COUNTERPARTY_AGENT_FULL_NAME_FIELD: '7f080812-b802-4bf4-8121-3c7c6f222c75',
  COUNTERPARTY_AGENT_POSITION_FIELD: '2591a5df-9d8a-4669-8041-56635b92fa10',
  COUNTERPARTY_AGENT_EMAIL_ITEM: '03437a27-6312-47b8-bd42-ef57947a9be8',
  COUNTERPARTY_AGENT_EMAIL_FIELD: '40a02e48-0fb5-4d37-9630-4c6c971fea0e',
  COUNTERPARTY_AGENT_EMAIL_TYPE: '56ecedd2-ea2d-45e4-9bea-2fe6699e5f4e',
  COUNTERPARTY_AGENT_PHONE_ITEM: '81bde42f-33b1-4350-a101-c47d1e95cf6a',
  COUNTERPARTY_AGENT_PHONE_FIELD: '1a42305b-4424-4cf2-bbf9-0a59680496bf',
  COUNTERPARTY_AGENT_PHONE_TYPE: '03437a27-6312-47b8-bd42-ef57947a9be8',
  COUNTERPARTY_KEY_PRODUCTION_NAME: 'c7911cfe-c457-4994-8a87-cfc3219f3651',
  COUNTERPARTY_KEY_PRODUCTION_TYPE: 'a3417d1d-4574-452e-b6c8-73d3ab8afdd9',
  COUNTERPARTY_IMPLEMENTATION_NOMENCLATURE_GROUP: 'f1cf3a6c-cd72-4c96-8335-80369d9a6329',
  COUNTERPARTY_LEGAL_ADDRESS_FIELD: 'a408da76-6990-417c-b935-367a4d4d6442',

  BLOCK_TYPE_SCHEMA: 'schema',
  BLOCK_TYPE_PAYMENT_ACCOUNT: 'paymentAccount',
  BLOCK_TYPE_AGENTS_ACCOUNT: 'agents',
  BLOCK_TYPE_KEY_PRODUCTION_ASSETS: 'keyProductionAssets',
  BLOCK_TYPE_IMPLEMENTATION_BLOCK: 'implementationBlock',
  BLOCK_TYPE_NEWS_BLOCK: 'newsBlock',
  BLOCK_TYPE_COMMUNICATION_BLOCK: 'communicationTable',
  BLOCK_TYPE_RELATIONSHIP_BREAKS_BLOCK: 'relationshipBreaks',
  BLOCK_TYPE_CONFLICT_HISTORY_BLOCK: 'conflictHistoryTable',
  BLOCK_TYPE_SALES_SUPPORT_HISTORY_BLOCK: 'salesSupportHistoryTable',
  BLOCK_TYPE_PRESENCE_OF_BRANDS_BLOCK: 'presenceOfBrands',
  BLOCK_TYPE_SEMINAR_HISTORY_BLOCK: 'seminarHistoryTable',
  BLOCK_TYPE_PRODUCTION_INDICATORS: 'productionIndicators',
  BLOCK_TYPE_ASSIGN_BLOCK: 'assignHistory',
  BLOCK_TYPE_CONSUMPTION_REGISTRY: 'consumptionVolumesRegister',
  BLOCK_TYPE_DELIVERY_REGISTRY: 'deliveryVolumesRegister',

  PAYMENT_BLOCK_NAME: '02a4732f-9d00-4912-b80f-6e754a391bf7',
  PAYMENT_BLOCK_BILLING_NUMBER: '6f1c9dc4-c3db-4317-88a9-b4c9d2267db2',
  PAYMENT_BLOCK_CURRENCY: '1fe10c9d-8164-424a-aa6f-628e200efe83',
  PAYMENT_BLOCK_BIC: '9d9f7e0d-2041-4532-8290-8199b3c18f7a',
  PAYMENT_BLOCK_BANK: '347fac3a-0257-4e66-864d-1852582e4c29',
  PAYMENT_BLOCK_SELLER_BANK: 'a970e3bd-51af-4641-9b0b-36f26240a2f0',
  PAYMENT_BLOCK_INN: '31d45bc8-5180-47df-9a06-d5491fdd2faa',
  PAYMENT_BLOCK_ACTIVE: 'e7a433e7-a4e9-4af3-bae2-ab6d2aa5eff4',
  PAYMENT_BLOCK_CORRESPONDENT: '0eeabbc4-0d93-49d9-a5b3-377ced0145b6',

  SIGN_OF_RESIDENT: '643',

  NEWS_BLOCK_DATE: '6f9146fb-7a60-4318-8ac5-928a58bf455a',
  NEWS_BLOCK_LINK: 'f634dc42-83a7-4436-b9cd-84f29f40d451',
  NEWS_BLOCK_DESCRIPTION: 'bcae25c5-9917-4752-b8c9-8f61cbabe956',

  STATUS_USER_IS_BLOCKED: 2,
  STATUS_USER_IS_ACTIVE: 1,

  STATUS_AGENT_IS_BLOCKED: 2,
  STATUS_AGENT_IS_ACTIVE: 1,

  STATUS_REQUEST_PROCESSING: 1,
  STATUS_REQUEST_AGREEMENT: 2,
  STATUS_REQUEST_APPROVED: 3,
  STATUS_REQUEST_REJECTED: 4,

  ADDRESS_MAPPER_FIELD_TEXT: 'ca4d1abb-355b-4d14-9dc0-c562c037604d',
  ADDRESS_MAPPER_FIELD_POSTAL_CODE: 'd1e155e6-fc80-433b-9c44-4246b9dbe4d4',
  ADDRESS_MAPPER_FIELD_COUNTRY: '85602630-1458-4c01-baa4-4fda45e3fecb',
  ADDRESS_MAPPER_FIELD_PROVINCE: 'd6915c34-7e39-43a0-bc9a-2629a53980ca',
  ADDRESS_MAPPER_FIELD_LOCALITY: 'b02d2609-e4e3-470b-9bad-c67f8e1ea723',
  ADDRESS_MAPPER_FIELD_AREA: 'eec24b38-6ef1-4859-9437-774a266d044c',
  ADDRESS_MAPPER_FIELD_STREET: '4a217058-5f9e-41c1-80d3-9f18406b17bb',
  ADDRESS_MAPPER_FIELD_HOUSE: 'd65e9640-1860-4e03-8977-78e29761984b',
  ADDRESS_MAPPER_FIELD_HOUSING: 'ac2cfd81-e1b9-4d5d-a492-76d31f9cd6c9',
  ADDRESS_MAPPER_FIELD_FLAT: '92d0ff59-fa98-42f4-b96d-996aa776d8bd',

  COUNTERPARTY_FILTER_STATUS_RESIDENT: 1,
  COUNTERPARTY_FILTER_STATUS_NOT_RESIDENT: 0,
  COUNTERPARTY_FILTER_BLOCK_FREE: 1,
  COUNTERPARTY_FILTER_BLOCK_WORKING: 2,

  ROLE_TYPE_EXTRA_DEPARTMENT: 2,

  CONTROL_DEBOUNCE: 500,
});

export default config;
