import {
  AxiosCallResponse,
  LoggerInterface,
  ResponseOfRecords,
} from '@/api/base';
import DictionaryBase from '@/api/admin/dictionary/base';

export interface IndustryData {
  id?: string,
  name: string,
}

export type IndustryResponseType = AxiosCallResponse<ResponseOfRecords<IndustryData[]>>;

export default class IndustryService extends DictionaryBase {
  constructor(token: string, logger: LoggerInterface = console) {
    super('industry', token, logger);
  }
}
