
import {
  ControlElement,
  JsonFormsRendererRegistryEntry,
  rankWith,
} from '@jsonforms/core';
import { RendererProps, rendererProps, useJsonFormsOneOfEnumControl } from '@jsonforms/vue';
import { useVanillaControl } from '@jsonforms/vue-vanilla';
import {
  computed, defineComponent, ref, Ref,
} from 'vue';
import SelectDefault, { DataType } from '@/components/ui/selects/SelectDefault/SelectDefault.vue';
import { controlWrapper, validationMethods } from '@/components/jsonforms/util/renderer';
import useAjaxConfiguration, { AjaxElementType } from '@/composables/form/useAjaxConfiguration';
import { isAjaxControl } from '@/components/jsonforms/util/testers';
import ControlWrapper from './ControlWrapper.vue';

const controlRenderer = defineComponent({
  name: 'ajax-control-renderer',
  components: {
    ControlWrapper,
    SelectDefault,
  },
  props: {
    ...rendererProps<ControlElement>(),
  },
  data() {
    return {
      elements: [],
      localData: null,
    };
  },
  computed: {
    isMultipleSelect() {
      return this.appliedOptions.multiple && Array.isArray(this.control.data);
    },
    defaultValues(): DataType[] {
      return this.elements.map((element: AjaxElementType) => ({
        id: element.id,
        name: element.name,
      }));
    },
    data(): DataType[] {
      if (this.localData) {
        const defaultValues = this.defaultValues.filter((
          value: DataType,
        ) => !this.localData.includes(value));

        return [
          ...this.localData,
          ...defaultValues,
        ];
      }

      return this.elements;
    },
    preparedControlWrapper() {
      return controlWrapper(this);
    },
    previewName(): string {
      const names: string[] = this.elements.map((element: DataType) => element.name);

      return names.join(', ');
    },
  },
  watch: {
    controlWrapper(value, oldValue) {
      if (value.errors !== oldValue.errors) {
        this.errors = value.errors;
      }
    },
    'control.config.onlyText': async function text() {
      this.elements = await this.getElements();
    },
    'control.data': async function data() {
      this.elements = await this.getElements();
    },
  },
  methods: {
    async changeHandler(value: string | string[]) {
      const localValue: string | string[] | undefined = !value && this.isRequired()
        ? undefined
        : value;

      this.handleChange(this.control.path, localValue);
    },
    blurHandler(value: string) {
      const localValue: string | undefined = !value && this.isRequired()
        ? undefined
        : value;

      this.isFocused = false;

      this.errors = this.getError(this.prepareData(localValue), this.prepareSchema());
    },
    async getElementById(id: string): Promise<AjaxElementType> {
      return this.ajaxConfigurator.getElementById(id);
    },
    async getElementsByIds(ids: string[]): Promise<AjaxElementType[]> {
      const promises = [];

      for (const id of ids) {
        promises.push(this.getElementById(id));
      }

      return Promise.all(promises);
    },
    async getElements(): Promise<AjaxElementType[]> {
      if (this.isMultipleSelect) {
        return this.getElementsByIds(this.control.data);
      }

      const element = this.control.data
        ? await this.getElementById(this.control.data)
        : null;

      return element !== null ? [element] : [];
    },
    async loadDataHandler(query: string) {
      this.localData = await this.ajaxConfigurator.loadDataByAjax(query, this.configuration.filter);

      this.elements = await this.getElements();
    },
  },
  async created() {
    this.elements = await this.getElements();
  },
  setup(props: RendererProps<ControlElement>) {
    const vanillaControl = useVanillaControl(useJsonFormsOneOfEnumControl(props));
    const control = vanillaControl.control as unknown as Ref;
    const errors = ref(vanillaControl.controlWrapper?.value?.errors ?? '');
    const configuration = computed(() => vanillaControl.appliedOptions.value.ajaxConfiguration);

    const ajaxConfigurator = useAjaxConfiguration(configuration.value);

    return {
      errors,
      ...vanillaControl,
      ...validationMethods(control),
      ajaxConfigurator,
      configuration,
    };
  },
});

export default controlRenderer;

export const entry: JsonFormsRendererRegistryEntry = {
  renderer: controlRenderer,
  tester: rankWith(5, isAjaxControl),
};
