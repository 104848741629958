
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'YearItem',
  props: {
    data: {
      type: Number,
      default: null,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    isCurrent: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    yearClass() {
      return {
        selected: this.isSelected,
        current: this.isCurrent,
      };
    },
  },
});
