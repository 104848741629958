import {
  computed, defineComponent, ref,
} from 'vue';
import { mapGetters, Store, useStore } from 'vuex';
import useUserAccess from '@/composables/useUserAccess';
import { StateType } from '@/store';
import TheManagerNotification
  from '@/components/notification/TheNotifications.vue';
import NotificationLogger from '@/utils/helpers/NotificationLogger';
import SidebarDefault from '@/layouts/sidebar/SidebarDefault.vue';

export interface WindowSize {
  width: number,
  height: number,
  isSm: boolean,
  isMd: boolean,
  isLg: boolean,
  isXl: boolean,
  is2xl: boolean
}

export default defineComponent({
  name: 'App',
  components: {
    TheManagerNotification,
    SidebarDefault,
  },
  computed: mapGetters({
    userEmail: 'user/email',
    userRoles: 'user/roles',
  }),
  methods: {
    resizeHandler() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
      this.window.isSm = window.innerWidth < 640;
      this.window.isMd = window.innerWidth < 768;
      this.window.isLg = window.innerWidth < 1024;
      this.window.isXl = window.innerWidth < 1280;
      this.window.is2xl = window.innerWidth < 1536;

      document.documentElement.style
        .setProperty('--vh', `${window.innerHeight * 0.01}px`);
    },
  },
  created() {
    window.addEventListener('resize', this.resizeHandler);
    this.resizeHandler();
  },
  provide() {
    const store: Store<StateType> = useStore();

    return {
      $notificationLogger: new NotificationLogger(store),
      $sizeWindow: this.window,
      $userAccess: this.userAccess,
    };
  },
  setup() {
    const window = ref<WindowSize>({
      width: 0,
      height: 0,
      isSm: false,
      isMd: false,
      isLg: false,
      isXl: false,
      is2xl: false,
    });

    const styleClass = computed<object>(() => ({
      'flex-col': window.value.isLg,
    }));
    const userAccess = useUserAccess();

    return {
      window, userAccess, styleClass,
    };
  },
});
