
import IconButton from '@/components/ui/buttons/IconButton/IconButton.vue';
import ButtonGroup from '@/components/ui/groups/ButtonGroup/ButtonGroup.vue';
import CloseIcon from '@/components/ui/icons/CloseIcon.vue';
import DefaultInput from '@/components/ui/inputs/DefaultInput/DefaultInput.vue';
import ActiveStatusIcon from '@/components/ui/statuses/ActiveStatusIcon.vue';
import TrashStatusIcon from '@/components/ui/statuses/TrashStatusIcon.vue';
import statuses from '@/shared/consts/statuses';
import { defineComponent, markRaw } from 'vue';

const getInitialStateFilter = () => ({
  query: null,
  status: statuses.STATUS_TYPE_ACTIVE,
});

export default defineComponent({
  name: 'TheFilter',
  emits: ['changeFilter'],
  components: {
    IconButton,
    CloseIcon,
    ButtonGroup,
    DefaultInput,
  },
  data() {
    return {
      filter: getInitialStateFilter(),
      statusList: [
        {
          name: statuses.STATUS_TYPE_ACTIVE,
          label: '',
          content: markRaw(ActiveStatusIcon),
        },
        {
          name: statuses.STATUS_TYPE_DELETE,
          label: '',
          content: markRaw(TrashStatusIcon),
        },
      ],
    };
  },
  methods: {
    clearFilter() {
      this.filter = getInitialStateFilter();

      this.changeFilter();
    },
    changeQueryHandler(query: string) {
      this.filter.query = query;

      this.changeFilter();
    },
    changeStatusHandler(value: number) {
      this.filter.status = value;

      this.changeFilter();
    },
    changeFilter() {
      this.$emit('changeFilter', this.filter);
    },
  },
});
