import { AxiosCallResponse, BaseService, LoggerInterface } from '@/api/base';

export interface OneOfType {
  const: string,
  title: string,
  _meta?: {
    [key: string]: string,
  }
}

export interface SchemaType {
  type?: string,
  additionalProperties?: boolean,
  properties?: {
    [key: string]: {
      type?: string | string[],
      properties?: object,
      additionalProperties?: boolean,
      uniqueItems?: boolean,
      items?: {
        type: string,
        properties?: object,
        oneOf?: OneOfType[],
      },
      format?: string,
      enum?: string[],
      allOf?: object[],
      if?: object,
      then?: object,
      else?: object,
      required?: string[],
    }
  },
  required?: string[],
}

export interface GetSchemaParams {
  group: string,
  counterparty?: string,
}

export default class SchemaService extends BaseService {
  constructor(token: string, logger: LoggerInterface = console) {
    super('schema', token, logger);
  }

  async getSchema(params: GetSchemaParams): Promise<AxiosCallResponse<SchemaType>> {
    return this.axiosCall({ method: 'get', params });
  }
}
