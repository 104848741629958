import { ErrorObject } from 'ajv';

const maxItemsException = (error: ErrorObject): string => {
  if (error.params.limit >= 2 && error.params.limit <= 4) {
    return `должно иметь не более, чем ${error.params.limit} элемента`;
  }

  if ((error.params.limit !== 1)) {
    return `должно иметь не более, чем ${error.params.limit} элементов`;
  }

  return `должно иметь не более, чем ${error.params.limit} элемент`;
};

const unevaluatedItemsException = (error: ErrorObject): string => {
  if (error.params.len >= 2 && error.params.len <= 4) {
    return `должно иметь не более, чем ${error.params.len} элемента`;
  }

  if ((error.params.len !== 1)) {
    return `должно иметь не более, чем ${error.params.len} элементов`;
  }

  return `должно иметь не более, чем ${error.params.len} элемент`;
};

const minItemsException = (error: ErrorObject): string => {
  if (error.params.limit >= 2 && error.params.limit <= 4) {
    return `должно иметь не менее, чем ${error.params.limit} элемента`;
  }

  if ((error.params.limit !== 1)) {
    return `должно иметь не менее, чем ${error.params.limit} элементов`;
  }

  return `должно иметь не менее, чем ${error.params.limit} элемент`;
};

const maxLengthException = (error: ErrorObject): string => {
  if (error.params.limit >= 2 && error.params.limit <= 4) {
    return `должно содержать максимум ${error.params.limit} символа`;
  }

  if ((error.params.limit !== 1)) {
    return `должно содержать максимум ${error.params.limit} символов`;
  }

  return `должно содержать максимум ${error.params.limit} символ`;
};

const minLengthException = (error: ErrorObject): string => {
  if (error.params.limit >= 2 && error.params.limit <= 4) {
    return `должно содержать минимум ${error.params.limit} символа`;
  }

  if ((error.params.limit !== 1)) {
    return `должно содержать минимум ${error.params.limit} символов`;
  }

  return `должно содержать минимум ${error.params.limit} символ`;
};

const maxPropertiesException = (error: ErrorObject): string => {
  if (error.params.limit >= 2 && error.params.limit <= 4) {
    return `должно иметь не более, чем ${error.params.limit} поля`;
  }

  if ((error.params.limit !== 1)) {
    return `должно иметь не более, чем ${error.params.limit} полей`;
  }

  return `должно иметь не более, чем ${error.params.limit} поле`;
};

const minPropertiesException = (error: ErrorObject): string => {
  if (error.params.limit >= 2 && error.params.limit <= 4) {
    return `должно иметь не менее, чем ${error.params.limit} поля`;
  }

  if ((error.params.limit !== 1)) {
    return `должно иметь не менее, чем ${error.params.limit} полей`;
  }

  return `должно иметь не менее, чем ${error.params.limit} поле`;
};

const additionalPropertiesException = (): string => 'не должно иметь дополнительных полей';

const anyOfException = (): string => 'должно соответствовать одной их схем в "anyOf"';

const constException = (): string => 'должно быть равно заданному значению';

const containsException = (): string => 'должно содержать значение соответствующее схеме';

const enumException = (): string => 'должно быть равно одному из разрешенных значений';

const dependentException = (error: ErrorObject): string => {
  const beginningOfLine = error.params.depsCount === 1
    ? 'должно иметь поле'
    : 'должно иметь поля';

  return `${beginningOfLine} ${error.params.deps}, когда присутствует поле ${error.params.property}`;
};

const discriminatorException = (error: ErrorObject): string => {
  if (error.params.error === 'tag') {
    return `поле "${error.params.tag}" должно быть строкой`;
  }

  if (error.params.error === 'mapping') {
    return `значение поля "${error.params.tag}" должно быть в одной из oneOf схем`;
  }

  return `должно соответствовать правилу "${error.keyword}"`;
};

const falseSchemaException = (): string => 'схема равна false';

const formatException = (error: ErrorObject): string => `должно соответствовать формату "${error.params.format}"`;

const formatMaximumException = (error: ErrorObject): string => `должно быть ${error.params.comparison} ${error.params.limit}`;

const formatMinimumException = (error: ErrorObject): string => `должно быть ${error.params.comparison} ${error.params.limit}`;

const maximumException = (error: ErrorObject): string => `должно быть ${error.params.comparison} ${error.params.limit}`;

const minimumException = (error: ErrorObject): string => `должно быть ${error.params.comparison} ${error.params.limit}`;

const ifException = (error: ErrorObject): string => `должно соответствовать схеме "${error.params.failingKeyword}"`;

const multipleOfException = (error: ErrorObject): string => `должно быть кратным ${error.params.multipleOf}`;

const patternException = (error: ErrorObject): string => `должно соответствовать образцу "${error.params.pattern}"`;

const patternRequiredException = (error: ErrorObject): string => `должно иметь поле, соответствующее образцу "${error.params.missingPattern}"`;

const typeException = (error: ErrorObject): string => `должно соответствовать типу "${error.params.type}"`;

const notException = (): string => 'не должно соответствовать схеме в "not"';

const oneOfException = (): string => 'должно соответствовать в точности одной схеме в "oneOf"';

const propertyNamesException = (): string => 'имя поля не соответствует схеме';

const requiredException = (): string => 'это обязательное поле';

const unevaluatedPropertiesException = (): string => 'не должно иметь непроверенных полей';

const uniqueItemsException = (): string => 'не должно быть повторяющихся полей';

const getTextByError = (error: ErrorObject): string => {
  const exceptions: { [key: string]: (err: ErrorObject) => string } = {
    additionalItems: maxItemsException,
    items: maxItemsException,
    maxItems: maxItemsException,
    minItems: minItemsException,
    maxLength: maxLengthException,
    minLength: minLengthException,
    maxProperties: maxPropertiesException,
    minProperties: minPropertiesException,
    additionalProperties: additionalPropertiesException,
    anyOf: anyOfException,
    const: constException,
    contains: containsException,
    dependencies: dependentException,
    dependentRequired: dependentException,
    discriminator: discriminatorException,
    enum: enumException,
    'false schema': falseSchemaException,
    format: formatException,
    formatMaximum: formatMaximumException,
    formatExclusiveMaximum: formatMaximumException,
    formatMinimum: formatMinimumException,
    formatExclusiveMinimum: formatMinimumException,
    if: ifException,
    maximum: maximumException,
    exclusiveMaximum: maximumException,
    minimum: minimumException,
    exclusiveMinimum: minimumException,
    multipleOf: multipleOfException,
    not: notException,
    oneOf: oneOfException,
    pattern: patternException,
    patternRequired: patternRequiredException,
    propertyNames: propertyNamesException,
    required: requiredException,
    type: typeException,
    unevaluatedItems: unevaluatedItemsException,
    unevaluatedProperties: unevaluatedPropertiesException,
    uniqueItems: uniqueItemsException,
  };

  return exceptions[error.keyword]?.(error) ?? error.message ?? `должно соответствовать правилу "${error.keyword}"`;
};

export default function ru(errors?: null | ErrorObject[]) {
  if (!(errors && errors.length)) return;

  for (const error of errors) {
    error.message = getTextByError(error);
  }
}
