
import { defineComponent } from 'vue';
import TheBadge from '@/components/ui/badges/TheBadge.vue';
import CircleCheckIcon from '@/components/ui/icons/CircleCheckIcon.vue';

export default defineComponent({
  name: 'ActiveBadge',
  components: {
    TheBadge,
    CircleCheckIcon,
  },
});
