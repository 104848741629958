import { Component, defineComponent, PropType } from 'vue';
import { RouteLocationRaw } from 'vue-router';
import CalculatePosition
  from '@/utils/directives/CalculatePosition';
import ContextMenuIcon from '@/components/ui/icons/ContextMenuIcon.vue';
import OutsideClick from '@/utils/directives/OutsideClick';

export interface ContextMenuType {
  text: string,
  to?: RouteLocationRaw,
  icon?: Component,
  iconProps?: object,
  emit?: string,
  events?: object,
  isVisible?: boolean,
}

export interface EmitDataType {
  emit: string,
  id: number
}

export default defineComponent({
  name: 'ContextMenu',
  components: {
    ContextMenuIcon,
  },
  emits: ['actionEmit'],
  props: {
    params: {
      type: Array as PropType<ContextMenuType[]>,
      default: () => ([]),
    },
    closeOnSelect: {
      type: Boolean,
      default: false,
    },
    id: Number,
    calculatePosition: {
      type: [Boolean, Object],
      default: () => ({
        extraHeight: 90,
      }),
    },
  },
  data: () => ({
    isShowDropdown: false,
    isPress: false,
  }),
  computed: {
    filteredParams() {
      return this.params.filter((item: ContextMenuType) => item.isVisible !== false);
    },
  },
  directives: {
    OutsideClick,
    CalculatePosition,
  },
  methods: {
    toggleDropdown() {
      this.isShowDropdown = !this.isShowDropdown;
    },
    hide() {
      this.isShowDropdown = false;
    },
    actionHandler(emit: string, id: number) {
      this.$emit('actionEmit', { emit, id });

      if (this.closeOnSelect) {
        this.hide();
      }
    },
  },
});
