
import { defineComponent, PropType } from 'vue';
import { JsonSchema } from '@jsonforms/core';
import CustomUISchemaElement from '@/components/jsonforms/interfaces/CustomUISchemaElement';
import useAjaxConfiguration, { AjaxConfigurationType } from '@/composables/form/useAjaxConfiguration';
import { OneOfType } from '@/api/schema';
import { DataType } from '@/components/ui/selects/SelectDefault/SelectDefault.vue';
import { isArray } from 'lodash';

export interface PreparedFieldSchema {
  name: string,
  key: string,
  ajaxConfiguration?: AjaxConfigurationType,
}

export default defineComponent({
  name: 'TheItem',
  emits: ['clickItemHandler'],
  props: {
    item: Object,
    fieldList: Array as PropType<string[]>,
    uischema: Object as PropType<CustomUISchemaElement>,
    schema: Object as PropType<JsonSchema>,
  },
  data() {
    return {
      valueList: [],
    };
  },
  computed: {
    fieldsSchema() {
      return this.uischema.elements.map((item: CustomUISchemaElement) => ({
        name: item.label,
        key: item.scope?.split('/').pop(),
        ajaxConfiguration: item.options?.ajaxConfiguration,
      }));
    },
  },
  methods: {
    getLabel(fieldId: string) {
      return this.fieldsSchema.find(
        (item: PreparedFieldSchema) => item.key === fieldId,
      )?.name;
    },
    getValue(fieldId: string) {
      const uiElement = this.fieldsSchema.find((item: PreparedFieldSchema) => item.key === fieldId);

      if (uiElement.ajaxConfiguration) {
        return this.valueList.find((item: DataType) => item.id === fieldId)?.name ?? this.$t('common.noData');
      } if (this.schema.properties[fieldId]?.oneOf) {
        const items: OneOfType[] = this.schema.properties[fieldId]?.oneOf ?? [];

        return items.find(
          (item: OneOfType) => item.const === this.item[fieldId],
        )?.title ?? this.$t('common.noData');
      }

      if (isArray(this.item[fieldId])) {
        return this.item[fieldId].join(', ');
      }

      return this.item[fieldId] ?? this.$t('common.noData');
    },
    clickHandler() {
      this.$emit('clickItemHandler', this.item.id);
    },
    async loadAjaxValue() {
      for (const key of this.fieldList) {
        const uiElement = this.fieldsSchema.find((item: PreparedFieldSchema) => item.key === key);
        if (uiElement.ajaxConfiguration && this.item[key]) {
          const ajaxConfigurator = useAjaxConfiguration(uiElement.ajaxConfiguration);

          // eslint-disable-next-line no-await-in-loop
          const name = await ajaxConfigurator.getNameById(this.item[key]);

          this.valueList.push({
            id: key,
            name,
          });
        }
      }
    },
  },
  watch: {
    async item() {
      await this.loadAjaxValue();
    },
  },
  async mounted() {
    await this.loadAjaxValue();
  },
});
