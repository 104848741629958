import { defineComponent } from 'vue';
import SortArrow from '@/components/ui/icons/SortArrow.vue';

export interface TableHeaderInterface {
  sortBy: string | null,
}

export default defineComponent({
  name: 'TableHeader',
  data: (): TableHeaderInterface => ({
    sortBy: null,
  }),
  components: {
    SortArrow,
  },
  emits: ['sortChanged'],
  props: {
    variant: {
      type: String,
      default: 'expand',
    },
    fields: {
      type: Object,
      default: () => ({}),
    },
    sort: {
      type: Object,
      default: () => ({
        sortBy: '',
      }),
    },
  },
  computed: {
    newSort() {
      return {
        sortBy: this.sortBy,
      };
    },
    filteredFields() {
      return this.fields.filter((item: { isVisible: boolean }) => item.isVisible !== false);
    },
    sortDirection() {
      return String(this.sortBy).startsWith('-')
        ? 'desc'
        : 'asc';
    },
    countCommonColumns(): number {
      return Object.keys(this.fields)
        .filter((key: string) => key !== 'Id')
        .length;
    },
    isSimple() {
      return this.variant === 'simple';
    },
    isExpand() {
      return this.variant === 'expand';
    },
  },
  methods: {
    changeSort(event: { currentTarget: HTMLSpanElement }) {
      const { key, notSortable } = event.currentTarget.dataset;

      if (key && !Number(notSortable)) {
        this.sortBy = key === this.sortBy ? `-${key}` : key;
        this.$emit('sortChanged', this.newSort);
      }
    },
    isActiveSort(name: string) {
      return String(this.sortBy).includes(name);
    },
    getSortDirection(name: string) {
      return this.isActiveSort(name)
        ? this.sortDirection
        : null;
    },
    getFillOpacity(name: string) {
      return this.isActiveSort(name)
        ? 1
        : 0.5;
    },
  },
});
