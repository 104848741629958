import _ from 'lodash';
import { JsonSchema } from '@jsonforms/core';
import Ajv, { ErrorObject } from 'ajv';
import ajvFormats from 'ajv-formats';
import ajvErrors from 'ajv-errors';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import CustomUISchemaElement
  from '@/components/jsonforms/interfaces/CustomUISchemaElement';
import localize from '@/components/jsonforms/localize';
import ajvCustomSetting from '@/utils/middlewares/ajvCustomSetting';

export const ajv: Ajv = new Ajv({
  allErrors: true,
  useDefaults: 'empty',
});

ajvFormats(ajv);
ajvErrors(ajv);
ajvCustomSetting(ajv);

const getAllLabels = (schema: CustomUISchemaElement): object[] => {
  if (schema.elements) {
    return _.flattenDeep(schema.elements.map((item: CustomUISchemaElement) => getAllLabels(item)));
  }

  if (schema?.options?.detail?.elements) {
    return _.flattenDeep(schema.options.detail.elements.map((
      item: CustomUISchemaElement,
    ) => getAllLabels(item)));
  }

  return schema.scope
    ? [{ [schema.scope]: schema?.label ?? '' }]
    : [];
};

const getLabel = (key: string, uischema: CustomUISchemaElement): string => {
  const labels: object[] = getAllLabels(uischema);
  const labelObject: object = labels.find((item: object) => _.has(item, `#/properties/${key}`)) ?? {};

  return String(_.values(labelObject)[0] ?? key);
};

const addLabelForErrors = (
  errors: ErrorObject[],
  uischema: CustomUISchemaElement,
) => errors.map((error: ErrorObject) => {
  const pathEnding: string = error.instancePath.split('/').pop() ?? '';
  const instancePath: string = pathEnding.length === 36
    ? pathEnding
    : error.instancePath;
  const key: string = error.params?.missingProperty ?? instancePath;

  return {
    ...error,
    params: {
      ...error.params,
      label: getLabel(key, uischema),
    },
  };
});

export const validateData = (
  data: JsonData,
  schema: JsonSchema,
  uischema?: CustomUISchemaElement,
): ErrorObject[] => {
  const validate = ajv.compile(schema);
  const valid = validate(data);
  validate.errors = _.uniqBy(validate.errors, 'instancePath');

  if (!valid && validate.errors) {
    localize.ru(validate.errors);

    return uischema
      ? addLabelForErrors(validate.errors, uischema)
      : validate.errors;
  }

  return [];
};

export default ajv;
