
import {
  ControlElement,
  JsonFormsRendererRegistryEntry,
  rankWith,
} from '@jsonforms/core';
import { RendererProps, rendererProps, useJsonFormsControl } from '@jsonforms/vue';
import { useVanillaControl } from '@jsonforms/vue-vanilla';
import { defineComponent, ref, Ref } from 'vue';
import PhoneInput from '@/components/ui/inputs/PhoneInput/PhoneInput.vue';
import { isPhoneControl } from '@/components/jsonforms/util/testers';
import { controlWrapper, validationMethods } from '@/components/jsonforms/util/renderer';
import config from '@/config';
import _ from 'lodash';
import ControlWrapper from './ControlWrapper.vue';

const controlRenderer = defineComponent({
  name: 'string-control-renderer',
  components: {
    PhoneInput,
    ControlWrapper,
  },
  props: {
    ...rendererProps<ControlElement>(),
  },
  watch: {
    controlWrapper(value, oldValue) {
      if (value.errors !== oldValue.errors) {
        this.errors = value.errors;
      }
    },
  },
  computed: {
    preparedControlWrapper() {
      return controlWrapper(this);
    },
  },
  methods: {
    blurHandler() {
      const value: string | null | undefined = this.getPreparedValue(this.control.data);

      this.isFocused = false;

      this.errors = this.getError(this.prepareData(value), this.prepareSchema());

      this.handleChange(this.control.path, value);
    },
  },
  setup(props: RendererProps<ControlElement>) {
    const vanillaControl = useVanillaControl(useJsonFormsControl(props));
    const control = vanillaControl.control as unknown as Ref;
    const errors = ref(vanillaControl.controlWrapper?.value?.errors ?? '');
    const debounceTime = config.CONTROL_DEBOUNCE ?? 500;
    const validMethods = validationMethods(control);

    const getPreparedValue = (value: string): string | null | undefined => {
      const trimmedValue = value !== '' ? value : null;

      return !trimmedValue && validMethods.isRequired()
        ? undefined
        : trimmedValue;
    };

    const debounceChangeHandler = _.debounce((
      value: string,
      object: { formatted?: string, valid?: boolean },
    ) => {
      const number = object?.formatted ?? value;
      const localValue: string | null | undefined = getPreparedValue(number);

      vanillaControl.handleChange(control.value.path, localValue);
    }, debounceTime);

    return {
      errors,
      ...vanillaControl,
      ...validationMethods(control),
      debounceChangeHandler,
      getPreparedValue,
    };
  },
});

export default controlRenderer;

export const entry: JsonFormsRendererRegistryEntry = {
  renderer: controlRenderer,
  tester: rankWith(1, isPhoneControl),
};
