import { BoardingSteps } from 'boarding.js';
import HighlightElement from 'boarding.js/dist/core/highlight-element';

export const getSteps = (): BoardingSteps => ([
  {
    element: '.blocks',
    popover: {
      className: 'first-step-popover-class',
      title: 'Список блоков с данными',
      description: '',
      prefferedSide: 'bottom',
    },
    onBeforeHighlighted: () => {
      const blocks = document.querySelectorAll('.blocks .block:not(.block-hidden)');

      blocks.forEach((item) => {
        const element = item.querySelector('.block-header') as HTMLElement;

        element?.click();
      });
    },
  },
  {
    element: '.blocks .block-item:nth-child(2) .block',
    popover: {
      className: 'first-step-popover-class',
      title: 'Раскрытие блока',
      description: 'При клике на область закрытого блока, он раскроется.',
      prefferedSide: 'bottom',
    },
    onNext: (element: HighlightElement) => {
      const el = element.getElement() as HTMLElement;

      el.click();
    },
  },
  {
    element: '.blocks .block .add-button',
    popover: {
      className: 'first-step-popover-class',
      title: 'Добавление записи',
      description: 'Для добавления новой записи нажмите на эту кнопку.',
      prefferedSide: 'bottom',
    },
  },
  {
    element: '.blocks .block .filter-block',
    popover: {
      className: 'first-step-popover-class',
      title: 'Фильтр',
      description: 'Здесь расположен фильтр записей, для настройки фильтра выберите соответствующий параметр.',
      prefferedSide: 'bottom',
    },
  },
  {
    element: '.blocks .block-item:nth-child(2) .block .template-block-table',
    popover: {
      className: 'first-step-popover-class',
      title: 'Список записей',
      description: 'Здесь будут отображаться все доступные вам записи. Для просмотра, редактирования или удаления записи нажмите на строку.',
      prefferedSide: 'bottom',
    },
  },
]);

export default {
  getSteps,
};
