
import {
  ControlElement,
  JsonFormsRendererRegistryEntry,
  rankWith,
  isDateControl,
} from '@jsonforms/core';
import { RendererProps, rendererProps, useJsonFormsControl } from '@jsonforms/vue';
import { useVanillaControl } from '@jsonforms/vue-vanilla';
import { defineComponent, ref, Ref } from 'vue';
import { controlWrapper, validationMethods } from '@/components/jsonforms/util/renderer';
import useDateHelper from '@/composables/helpers/date/useDateHelper';
import DatepickerDefault from '@/components/ui/datepickers/datepicker-default/DatepickerDefault.vue';
import { DateTime } from 'luxon';
import config from '@/config';
import ControlWrapper from './ControlWrapper.vue';

const controlRenderer = defineComponent({
  name: 'date-control-renderer',
  components: {
    ControlWrapper,
    DatepickerDefault,
  },
  props: {
    ...rendererProps<ControlElement>(),
  },
  computed: {
    preparedControlWrapper() {
      return controlWrapper(this);
    },
    formattedDate() {
      const date = DateTime.fromISO(this.control.data);

      return date.toFormat(config.DATE_FORMAT);
    },
  },
  watch: {
    controlWrapper(value, oldValue) {
      if (value.errors !== oldValue.errors) {
        this.errors = value.errors;
      }
    },
  },
  methods: {
    changeHandler(value: string): void {
      this.handleChange(this.control.path, value);
    },
    blurHandler(value: string) {
      this.isFocused = false;

      this.errors = this.getError(this.prepareData(value), this.prepareSchema());
    },
  },
  setup(props: RendererProps<ControlElement>) {
    const vanillaControl = useVanillaControl(useJsonFormsControl(props));
    const control = vanillaControl.control as unknown as Ref;
    const errors = ref(vanillaControl.controlWrapper?.value?.errors ?? '');
    const dateFormatter = useDateHelper().getDateFormatter();

    return {
      errors,
      dateFormatter,
      ...vanillaControl,
      ...validationMethods(control),
    };
  },
});

export default controlRenderer;

export const entry: JsonFormsRendererRegistryEntry = {
  renderer: controlRenderer,
  tester: rankWith(2, isDateControl),
};
