
import { defineComponent, PropType } from 'vue';
import { JsonSchema } from '@jsonforms/core';
import SelectDefault from '@/components/ui/selects/SelectDefault/SelectDefault.vue';
import { OneOfType } from '@/api/schema';
import salesSupportHistory from '@/shared/consts/blocks/salesSupportHistory';
import DatepickerPeriod, {
  DatepickerPeriodType,
} from '@/components/ui/datepickers/datepicker-period/DatepickerPeriod.vue';
import { TechnologicalSupportTypeSchema } from '@/api/admin/dictionary/typesTechnologicalSupport';

export default defineComponent({
  name: 'TheFilter',
  inject: ['$sizeWindow'],
  emits: ['filterChanged', 'ajaxSupportView'],
  components: {
    DatepickerPeriod,
    SelectDefault,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    isCanEdit: {
      type: Boolean,
      default: false,
    },
    schema: {
      type: Object as PropType<JsonSchema>,
      default: () => ({}),
    },
    supportViewList: {
      type: Array as PropType<TechnologicalSupportTypeSchema[]>,
      default: () => ([]),
    },
  },
  data() {
    return {
      filter: {
        status: null,
        date: null,
        type: null,
        user: null,
      },
    };
  },
  computed: {
    userDataList() {
      return this.schema.properties[salesSupportHistory.BLOCK_FIELD_SALES_SUPPORT_MANAGER]
        ?.oneOf.map((item: OneOfType) => ({
          id: item.const,
          name: item.title,
        }));
    },
    newFilter() {
      return {
        equals: {
          [salesSupportHistory.BLOCK_FIELD_TYPE]: this.filter.type,
          [salesSupportHistory.BLOCK_FIELD_SALES_SUPPORT_MANAGER]: this.filter.user,
        },
        between: {
          [salesSupportHistory.BLOCK_FIELD_DATE]: this.filter.date,
        },
      };
    },
  },
  methods: {
    changeDateHandler(date: DatepickerPeriodType) {
      this.filter.date = [date.beforeDate, date.afterDate];

      this.$emit('filterChanged', this.newFilter);
    },
    changeTypeHandler(id: string) {
      this.filter.type = id;

      this.$emit('filterChanged', this.newFilter);
    },
    changeUserHandler(id: string) {
      this.filter.user = id;

      this.$emit('filterChanged', this.newFilter);
    },
    async ajaxSupportView(query: string) {
      this.$emit('ajaxSupportView', query);
    },
  },
});
