
import { defineComponent, markRaw, PropType } from 'vue';
import { JsonSchema } from '@jsonforms/core';
import ButtonGroup from '@/components/ui/groups/ButtonGroup/ButtonGroup.vue';
import CanceledIcon
  from '@/components/counterparty/card/blocks/relationship/communication/status/icons/CanceledIcon.vue';
import PlannedIcon
  from '@/components/counterparty/card/blocks/relationship/communication/status/icons/PlannedIcon.vue';
import CompletedIcon
  from '@/components/counterparty/card/blocks/relationship/communication/status/icons/CompletedIcon.vue';
import DatepickerPeriod, {
  DatepickerPeriodType,
} from '@/components/ui/datepickers/datepicker-period/DatepickerPeriod.vue';
import SelectDefault from '@/components/ui/selects/SelectDefault/SelectDefault.vue';
import communication from '@/shared/consts/blocks/communication';
import { AgentSchema } from '@/api/agent';
import config from '@/config';

export default defineComponent({
  name: 'TheFilter',
  inject: ['$sizeWindow'],
  emits: ['filterChanged'],
  components: {
    ButtonGroup,
    DatepickerPeriod,
    SelectDefault,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    isCanEdit: {
      type: Boolean,
      default: false,
    },
    schema: {
      type: Object as PropType<JsonSchema>,
      default: () => ({}),
    },
    agents: {
      type: Array as PropType<AgentSchema[]>,
      default: () => ([]),
    },
  },
  data() {
    return {
      filter: {
        status: '',
        date: null,
        type: null,
        user: null,
      },
    };
  },
  computed: {
    typeDataList() {
      return this.schema.properties[communication.BLOCK_FIELD_TYPE]?.enum?.map(
        (item: string) => ({
          id: item,
          name: item,
        }),
      );
    },
    agentData() {
      return this.agents.map((item: AgentSchema) => ({
        id: item.id,
        name: item[config.COUNTERPARTY_AGENT_FULL_NAME_FIELD],
      }));
    },
    statusList() {
      return [
        { name: '', label: 'Все', active: true },
        {
          name: communication.STATUS_COMPLETED,
          label: '',
          content: markRaw(CompletedIcon),
          props: {
            isActive: this.filter.status === communication.STATUS_COMPLETED,
          },
        },
        {
          name: communication.STATUS_PLANNED,
          label: '',
          content: markRaw(PlannedIcon),
          props: {
            isActive: this.filter.status === communication.STATUS_PLANNED,
          },
        },
        {
          name: communication.STATUS_CANCELED,
          label: '',
          content: markRaw(CanceledIcon),
          props: {
            isActive: this.filter.status === communication.STATUS_CANCELED,
          },
        },
      ];
    },
    newFilter() {
      return {
        equals: {
          [communication.BLOCK_FIELD_STATUS]: this.filter.status,
          [communication.BLOCK_FIELD_TYPE]: this.filter.type,
          [communication.BLOCK_FIELD_USER]: this.filter.user,
        },
        between: {
          [communication.BLOCK_FIELD_DATE_CONTACT]: this.filter.date,
        },
      };
    },
  },
  methods: {
    changeDateHandler(date: DatepickerPeriodType) {
      this.filter.date = [date.beforeDate, date.afterDate];

      this.$emit('filterChanged', this.newFilter);
    },
    changeTypeHandler(id: string) {
      this.filter.type = id;

      this.$emit('filterChanged', this.newFilter);
    },
    changeUserHandler(id: string) {
      this.filter.user = id;

      this.$emit('filterChanged', this.newFilter);
    },
    changeStatusHandler(id: string) {
      this.filter.status = id;

      this.$emit('filterChanged', this.newFilter);
    },
  },
});
