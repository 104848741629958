import { AxiosCallResponse } from '@/api/base';
import store from '@/store';
import BankService, { BankData } from '@/api/admin/dictionary/bank';

export function useOneApi() {
  return {
    async loadData(id: string) {
      const bankApi: BankService = store.getters.$api.admin.dictionary.bank;

      const response: AxiosCallResponse<BankData> = await bankApi.one(id);

      return response.data ?? null;
    },
  };
}

export default {};
