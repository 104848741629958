
import { defineComponent, PropType } from 'vue';
import SelectDefault, { DataType } from '@/components/ui/selects/SelectDefault/SelectDefault.vue';
import IconButton from '@/components/ui/buttons/IconButton/IconButton.vue';
import SwitchHorizontalIcon from '@/components/ui/icons/SwitchHorizontalIcon.vue';
import { ConsumptionFilter } from '@/store/modules/counterparty/block/registry/consumptionRegistry/one';

export const TYPE_SLICE_GROUP = 'group';
export const TYPE_SLICE_YEAR = 'year';

export default defineComponent({
  name: 'TheFilter',
  emits: ['changeFilter', 'switchSlice'],
  components: {
    SwitchHorizontalIcon,
    IconButton,
    SelectDefault,
  },
  props: {
    nomenclatureGroup: Array as PropType<DataType[]>,
    yearsList: Array as PropType<DataType[]>,
    filter: Object as PropType<ConsumptionFilter>,
  },
  data() {
    return {
      typeSlice: TYPE_SLICE_GROUP,
      year: null,
      group: null,
    };
  },
  computed: {
    isYearSlice() {
      return this.typeSlice === TYPE_SLICE_YEAR;
    },
    isGroupSlice() {
      return this.typeSlice === TYPE_SLICE_GROUP;
    },
    reverseClass() {
      return {
        '!flex-row-reverse': this.isYearSlice,
      };
    },
    newFilter() {
      return {
        group: this.group,
        year: this.year,
      };
    },
  },
  watch: {
    newFilter() {
      if (this.typeSlice === TYPE_SLICE_GROUP) {
        this.year = null;
      }

      if (this.typeSlice === TYPE_SLICE_YEAR) {
        this.group = null;
      }
    },
  },
  methods: {
    switchDataSlice() {
      this.typeSlice = this.typeSlice === TYPE_SLICE_GROUP
        ? TYPE_SLICE_YEAR
        : TYPE_SLICE_GROUP;

      this.$emit('switchSlice', this.typeSlice);
    },
    changeGroup(value: string) {
      this.group = value;
      this.changeFilter();
    },
    changeYear(value: string) {
      this.year = value;
      this.changeFilter();
    },
    changeFilter() {
      this.$emit('changeFilter', this.newFilter);
    },
  },
});
