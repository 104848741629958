import { Module } from 'vuex';
import {
  AxiosCallResponse,
  FilterParams,
  MetaParams,
  SortParams,
} from '@/api/base';
import CounterpartyService, {
  CounterpartyFilterParams,
  CounterpartyItemType, CounterpartyQueryForList,
  CounterpartyResponseType,
} from '@/api/counterparty';
import { TYPE_LABEL_SUCCESS } from '@/components/ui/notifications/TheAlert/TheAlert.vue';
import i18n from '@/i18n';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import { StateType } from '@/store';

export interface CounterpartyListStateType {
  counterparties: CounterpartyItemType[],
  pagination: MetaParams,
  filter: CounterpartyFilterParams,
  sort: SortParams,
}

export interface AssignDataType {
  counterpartyId: string,
  data: {
    user: string,
    division: string,
    department: string,
    counterpartyType: string,
  },
}

const getInitialState = (): CounterpartyListStateType => ({
  counterparties: [],
  pagination: {
    currentPage: null,
    pageCount: null,
    perPage: null,
    totalCount: null,
  },
  filter: {
    query: null,
    onlyAssigned: null,
    industry: null,
    isFromRussia: null,
    block: null,
    divisionId: null,
    departmentId: null,
    assignUserId: null,
    regionId: null,
    address: null,
  },
  sort: {
    sortBy: null,
  },
});

const initialState = getInitialState();

const counterpartyListModule: Module<CounterpartyListStateType, StateType> = {
  namespaced: true,
  state: initialState,
  getters: {
    pagination: (state): MetaParams => state.pagination,
    filter: (state): FilterParams => state.filter,
    sort: (state): SortParams => state.sort,
    counterparties: (state): CounterpartyItemType[] => state.counterparties,
    loadCounterpartiesParams: (state): CounterpartyQueryForList => ({
      page: state.pagination.currentPage,
      perPage: state.pagination.perPage,
      query: state.filter.query,
      onlyAssigned: state.filter.onlyAssigned,
      industry: state.filter.industry,
      isFromRussia: state.filter.isFromRussia,
      block: state.filter.block,
      divisionId: state.filter.divisionId,
      departmentId: state.filter.departmentId,
      assignUserId: state.filter.assignUserId,
      regionId: state.filter.regionId,
      address: state.filter.address,
      sortBy: state.sort.sortBy,
    }),
  },
  mutations: {
    setPagination(state, pagination) {
      state.pagination = pagination;
    },
    setCounterparties(state, counterparties) {
      state.counterparties = counterparties;
    },
    setFilter(state, filter) {
      state.filter = filter;
    },
    setSort(state, sort) {
      state.sort = sort;
    },
    resetState: (state) => {
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    resetState({ commit }) {
      commit('resetState');
    },
    changePagination({ commit }, pagination: MetaParams) {
      commit('setPagination', pagination);
    },
    changeFilter({ commit, state }, filter: CounterpartyFilterParams) {
      commit('setFilter', filter);
      commit('setPagination', { ...state.pagination, currentPage: 1 });
    },
    changeSort({ commit }, sort: SortParams) {
      commit('setSort', sort);
    },
    async loadCounterparties({
      getters,
      rootGetters,
      commit,
    }, filterParams: CounterpartyFilterParams | null = null) {
      const localFilterParams = filterParams ?? getters.loadCounterpartiesParams;

      const counterpartyApi: CounterpartyService = rootGetters.$api.counterparty;

      const response: AxiosCallResponse<CounterpartyResponseType> = await counterpartyApi
        .list(localFilterParams);

      commit('setCounterparties', response.data?.items ?? initialState.counterparties);
      commit('setPagination', response.data?._meta ?? initialState.pagination);
      commit('setFilter', localFilterParams);
    },
    async addCounterparty({
      rootGetters,
      dispatch,
    }, params: { data: JsonData, block: string, createFromSearch: boolean }) {
      const { data, block, createFromSearch } = params;

      const counterpartyApi: CounterpartyService = rootGetters.$api.counterparty;
      const counterpartyData: AxiosCallResponse<null> = await counterpartyApi
        .create(data, { block });

      if (!counterpartyData.error) {
        await dispatch('loadCounterparties');

        if (createFromSearch) {
          await rootGetters.$notificationLogger
            .setNotification(TYPE_LABEL_SUCCESS, i18n.global.t('notification.counterparty.success.search'));
        } else {
          await rootGetters.$notificationLogger
            .setNotification(TYPE_LABEL_SUCCESS, i18n.global.t('notification.counterparty.success.add'));
        }
      }

      return counterpartyData;
    },

    async assignCounterparty({ rootGetters, dispatch }, assignData: AssignDataType) {
      const { counterpartyId, data } = assignData;

      const counterpartyApi: CounterpartyService = rootGetters.$api.counterparty;
      const assignResponseData: AxiosCallResponse<null> = await counterpartyApi
        .assignCounterparty(counterpartyId, data);

      if (!assignResponseData.error) {
        await dispatch('loadCounterparties');

        await rootGetters.$notificationLogger
          .setNotification(TYPE_LABEL_SUCCESS, i18n.global.t('notification.admin.dictionary.success.create'));
      }
    },
  },
};

export default counterpartyListModule;
