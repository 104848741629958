import { Module } from 'vuex';
import { StateType } from '@/store';
import list, { NomenclatureListStateType } from '@/store/modules/admin/dictionary/nomenclature/list';

export interface NomenclatureModuleStateType {
  list: NomenclatureListStateType,
}

const nomenclatureModule: Module<NomenclatureModuleStateType, StateType> = {
  namespaced: true,
  modules: {
    list,
  },
};

export default nomenclatureModule;
