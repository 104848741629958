import { Module } from 'vuex';
import one, { SalesSupportHistoryOneStateType } from '@/store/modules/counterparty/block/relationship/salesSupportHistory/one';
import list, { SalesSupportHistoryListStateType } from '@/store/modules/counterparty/block/relationship/salesSupportHistory/list';
import { StateType } from '@/store';

export interface SalesSupportHistoryStateType {
  list: SalesSupportHistoryListStateType,
  one: SalesSupportHistoryOneStateType,
}

const salesSupportHistoryModule: Module<SalesSupportHistoryStateType, StateType> = {
  namespaced: true,
  modules: {
    list,
    one,
  },
};

export default salesSupportHistoryModule;
