import { BoardingSteps } from 'boarding.js';

export const getSteps = (): BoardingSteps => ([
  {
    element: '.agents--cards',
    popover: {
      className: 'first-step-popover-class',
      title: 'Список контактных лиц',
      description: 'Здесь находятся контактные лица.',
      prefferedSide: 'bottom',
    },
  },
  {
    element: '.agent-card',
    popover: {
      className: 'first-step-popover-class',
      title: 'Карточка контактного лица',
      description: 'Краткая информация о контактном лице. Для открытия полной информации, кликните на карточку.',
      prefferedSide: 'bottom',
    },
  },
  {
    element: '.agent-card-actions',
    popover: {
      className: 'first-step-popover-class',
      title: 'Действия с карточкой',
      description: 'Здесь вы можете отредактировать данные или посмотреть историю изменений.',
      prefferedSide: 'bottom',
    },
  },
  {
    element: '.agents--header .btn',
    popover: {
      className: 'first-step-popover-class',
      title: 'Добавление нового контактного лица',
      description: 'Кликнув на эту кнопку, вы сможете добавить новое контактное лицо.',
      prefferedSide: 'bottom',
    },
  },
]);

export default {
  getSteps,
};
