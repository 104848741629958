import JsonData from '@/components/jsonforms/interfaces/JsonData';
import { Module } from 'vuex';
import { StateType } from '@/store';
import ConsumptionRegistryService from '@/api/block/registry/consumptionRegistry';
import { AxiosCallResponse } from '@/api/base';
import { TYPE_LABEL_SUCCESS } from '@/components/ui/notifications/TheAlert/TheAlert.vue';
import i18n from '@/i18n';

export interface ConsumptionFilter {
  year: string,
  group: string,
}

export interface ConsumptionRegistryOneStateType {
  data: JsonData | null,
  filter: ConsumptionFilter | null,
}

const getInitialState = () => ({
  data: null,
  filter: null,
});

const consumptionRegistryOneModule: Module<ConsumptionRegistryOneStateType, StateType> = {
  namespaced: true,
  state: getInitialState(),
  getters: {
    data: (state) => state.data,
    filter: (state) => state.filter,
  },
  mutations: {
    setData(state, data) {
      state.data = data;
    },
    setFilter(state, filter) {
      state.filter = filter;
    },
    resetState: (state) => {
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    resetState({ commit }) {
      commit('resetState');
    },
    changeFilter({ commit }, filter: ConsumptionFilter) {
      commit('setFilter', filter);
    },
    async loadData({ state, rootGetters, commit }, cardId: string) {
      if (state.filter?.year && state.filter?.group) {
        const api: ConsumptionRegistryService = rootGetters.$api.blocks.consumptionRegistry;

        const response: AxiosCallResponse<JsonData> = await api.one(
          cardId,
          state.filter.year,
          state.filter.group,
        );

        commit('setData', response.data ?? getInitialState().data);
      }
    },
    async save({ state, rootGetters }, params: {
      cardId: string,
      data: JsonData
    }) {
      if (state.filter?.year && state.filter?.group) {
        const api: ConsumptionRegistryService = rootGetters.$api.blocks.consumptionRegistry;

        const response: AxiosCallResponse<null> = await api.save(
          params.cardId,
          {
            year: state.filter.year,
            group: state.filter.group,
            data: params.data,
          },
        );

        if (!response.error) {
          await rootGetters
            .$notificationLogger
            .setNotification(
              TYPE_LABEL_SUCCESS,
              i18n.global.t('notification.admin.dictionary.success.create'),
            );
        }
      }
    },
  },
};

export default consumptionRegistryOneModule;
