import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-33669024"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "notification-block" }
const _hoisted_2 = { class: "h-full flex w-full justify-between items-center" }
const _hoisted_3 = { class: "text-[14px] w-full h-full pl-[14px] pr-[14px] flex items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_info_icon = _resolveComponent("info-icon")!
  const _component_success_icon = _resolveComponent("success-icon")!
  const _component_warning_icon = _resolveComponent("warning-icon")!
  const _component_error_icon = _resolveComponent("error-icon")!
  const _component_close_icon = _resolveComponent("close-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["notification-label", _ctx.type])
    }, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.isInfo)
          ? (_openBlock(), _createBlock(_component_info_icon, { key: 0 }))
          : _createCommentVNode("", true),
        (_ctx.isSuccess)
          ? (_openBlock(), _createBlock(_component_success_icon, { key: 1 }))
          : _createCommentVNode("", true),
        (_ctx.isWarning)
          ? (_openBlock(), _createBlock(_component_warning_icon, { key: 2 }))
          : _createCommentVNode("", true),
        (_ctx.isError)
          ? (_openBlock(), _createBlock(_component_error_icon, {
              key: 3,
              width: "16",
              height: "16",
              color: "#B91C1C"
            }))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        _createVNode(_component_close_icon, {
          "class-color": _ctx.type,
          onClick: _ctx.closeNotification,
          class: "cursor-pointer"
        }, null, 8, ["class-color", "onClick"])
      ])
    ], 2)
  ]))
}