import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-70bd7318"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-content" }
const _hoisted_2 = { class: "modal-block" }
const _hoisted_3 = { class: "modal-block__title" }
const _hoisted_4 = { class: "modal-block" }
const _hoisted_5 = {
  key: 0,
  class: "text-error"
}
const _hoisted_6 = { class: "modal-footer" }
const _hoisted_7 = { class: "text-[14px]" }
const _hoisted_8 = { class: "text-[14px]" }
const _hoisted_9 = { class: "text-[14px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search_input = _resolveComponent("search-input")!
  const _component_the_form = _resolveComponent("the-form")!
  const _component_default_button = _resolveComponent("default-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('admin.user.create.title')), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          class: _normalizeClass({ 'has-error': _ctx.hasError })
        }, [
          _createVNode(_component_search_input, {
            ref: "search",
            type: "text",
            debounce: 0,
            value: _ctx.email,
            "clear-button": false,
            disabled: _ctx.isLoading || _ctx.emailVerified,
            label: _ctx.$t('admin.user.detail.form.email'),
            placeholder: _ctx.$t('admin.user.detail.form.placeholder.email'),
            onInput: _ctx.changeEmailHandler,
            onKeyup: _withKeys(_ctx.verifyHandler, ["enter"])
          }, null, 8, ["value", "disabled", "label", "placeholder", "onInput", "onKeyup"]),
          (_ctx.hasError)
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.verifyErrorMessage), 1))
            : _createCommentVNode("", true)
        ], 2),
        (_ctx.emailVerified && _ctx.userData)
          ? (_openBlock(), _createBlock(_component_the_form, {
              key: 0,
              ref: "form",
              class: "mt-[24px]",
              data: _ctx.userData,
              departments: _ctx.preparedDepartments ,
              divisions: _ctx.preparedDivisions,
              companies: _ctx.preparedCompanies,
              onUpdateData: _ctx.updateDataHandler
            }, null, 8, ["data", "departments", "divisions", "companies", "onUpdateData"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_default_button, {
        height: "40",
        onClick: _ctx.cancelHandler
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('buttons.cancel')), 1)
        ]),
        _: 1
      }, 8, ["onClick"]),
      (!_ctx.emailVerified)
        ? (_openBlock(), _createBlock(_component_default_button, {
            key: 0,
            type: "primary",
            disabled: _ctx.isLoading || _ctx.disableVerifyButton,
            height: "40",
            onClick: _ctx.verifyHandler
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t(`admin.user.create.buttons.${ _ctx.$sizeWindow.isSm ? 'mobile.' : '' }verify`)), 1)
            ]),
            _: 1
          }, 8, ["disabled", "onClick"]))
        : (_openBlock(), _createBlock(_component_default_button, {
            key: 1,
            type: "primary",
            height: "40",
            disabled: _ctx.isLoading,
            onClick: _ctx.createHandler
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t(`admin.user.create.buttons.${ _ctx.$sizeWindow.isSm ? 'mobile.' : '' }create`)), 1)
            ]),
            _: 1
          }, 8, ["disabled", "onClick"]))
    ])
  ]))
}