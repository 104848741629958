import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "h-full flex items-center"
}
const _hoisted_2 = {
  key: 1,
  class: "sidebar-content"
}
const _hoisted_3 = { class: "sidebar-block" }
const _hoisted_4 = { class: "sidebar-block__title" }
const _hoisted_5 = { class: "sidebar-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_default_loader = _resolveComponent("default-loader")!
  const _component_form_generator = _resolveComponent("form-generator")!
  const _component_the_counterparty_data = _resolveComponent("the-counterparty-data")!

  return (_ctx.isLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_default_loader)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('dictionary.general.detail')), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_form_generator, {
            uischema: _ctx.uischema,
            schema: _ctx.schema,
            information: _ctx.data,
            config: { onlyText: true},
            "validation-mode": "ValidateAndHide",
            class: "mb-[24px]"
          }, null, 8, ["uischema", "schema", "information"]),
          _createVNode(_component_the_counterparty_data, { data: _ctx.data }, null, 8, ["data"])
        ])
      ]))
}