import { computed, reactive } from 'vue';
import permissions from '@/permissions';
import { role } from '@/config';
import store from '@/store';

export interface UserAccess {
  admin: {
    isSuperAdmin: boolean,
    isAdminCompany: boolean,
    isAdminDivision: boolean,
  },
  viewer: {
    isMainViewer: boolean,
    isExtraYourDepartment: boolean,
    isExtraOtherDepartment: boolean,
    isExtraEmptyDepartment: boolean,
    isEntireList: boolean,
  },
  editor: {
    isMainEditor: boolean,
    isExtraYourDepartment: boolean,
    isExtraOtherDepartment: boolean,
    isExtraEmptyDepartment: boolean,
  },
  dictionary: {
    viewer: {
      userRoles: boolean,
      pinning: boolean,
    },
    editor: {
      user: boolean,
      pinning: boolean,
      country: boolean,
      bank: boolean,
      bankAccount: boolean,
      activityDirection: boolean,
      counterpartyCategory: boolean,
      industry: boolean,
      structure: boolean,
      position: boolean,
      education: boolean,
      individual: boolean,
      region: boolean,
    },
  },
}

const userRoles = computed<string[]>(() => store.getters['user/roles']);

export default function useUserAccess() {
  return reactive<UserAccess>({
    admin: {
      isSuperAdmin: userRoles.value.includes(permissions.ROLE_SUPER_ADMIN)
          || userRoles.value.includes(role.ADMIN),
      isAdminCompany: userRoles.value.includes(permissions.ROLE_ADMIN_COMPANY),
      isAdminDivision: userRoles.value.includes(permissions.ROLE_ADMIN_DIVISION),
    },
    viewer: {
      isMainViewer: userRoles.value.includes(permissions.ROLE_VIEWER_MAIN),
      isExtraYourDepartment:
          userRoles.value.includes(permissions.ROLE_VIEWER_EXTRA_YOUR_DEPARTMENT),
      isExtraOtherDepartment:
          userRoles.value.includes(permissions.ROLE_VIEWER_EXTRA_OTHER_DEPARTMENT),
      isExtraEmptyDepartment:
          userRoles.value.includes(permissions.ROLE_VIEWER_EXTRA_WITHOUT_DEPARTMENT),
      isEntireList:
          userRoles.value.includes(permissions.ROLE_VIEWER_ENTIRE_LIST),
    },
    editor: {
      isMainEditor: userRoles.value.includes(permissions.ROLE_EDITOR_MAIN),
      isExtraYourDepartment:
          userRoles.value.includes(permissions.ROLE_EDITOR_EXTRA_YOUR_DEPARTMENT),
      isExtraOtherDepartment:
          userRoles.value.includes(permissions.ROLE_EDITOR_EXTRA_OTHER_DEPARTMENT),
      isExtraEmptyDepartment:
          userRoles.value.includes(permissions.ROLE_EDITOR_EXTRA_WITHOUT_DEPARTMENT),
    },
    dictionary: {
      viewer: {
        userRoles: userRoles.value.includes(permissions.ROLE_VIEWER_USER_ROLES),
        pinning: userRoles.value.includes(permissions.ROLE_VIEWER_DICTIONARY_ASSIGN),
      },
      editor: {
        user: userRoles.value.includes(permissions.ROLE_EDITOR_DICTIONARY_USER),
        pinning: userRoles.value.includes(permissions.ROLE_EDITOR_DICTIONARY_ASSIGN),
        country: userRoles.value.includes(permissions.ROLE_EDITOR_DICTIONARY_COUNTRY),
        bank: userRoles.value.includes(permissions.ROLE_EDITOR_DICTIONARY_BANK),
        bankAccount: userRoles.value.includes(permissions.ROLE_EDITOR_DICTIONARY_BANK_ACCOUNT),
        activityDirection:
          userRoles.value.includes(permissions.ROLE_EDITOR_DICTIONARY_ACTIVITY_DIRECTION),
        counterpartyCategory:
            userRoles.value.includes(permissions.ROLE_EDITOR_DICTIONARY_COUNTERPARTY_CATEGORY),
        industry: userRoles.value.includes(permissions.ROLE_EDITOR_DICTIONARY_INDUSTRY),
        structure: userRoles.value.includes(permissions.ROLE_EDITOR_DICTIONARY_STRUCTURE),
        position: userRoles.value.includes(permissions.ROLE_EDITOR_DICTIONARY_POSITION),
        education: userRoles.value.includes(permissions.ROLE_EDITOR_DICTIONARY_EDUCATION),
        individual: userRoles.value.includes(permissions.ROLE_EDITOR_DICTIONARY_INDIVIDUAL),
        region: userRoles.value.includes(permissions.ROLE_EDITOR_DICTIONARY_REGION),
      },
    },
  });
}
