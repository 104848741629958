
import { defineComponent } from 'vue';
import TheError from '@/components/error/TheError.vue';

export default defineComponent({
  name: 'NotFoundView',
  components: {
    TheError,
  },
});
