import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isSolid)
    ? (_openBlock(), _createElementBlock("svg", {
        key: 0,
        width: _ctx.width,
        height: _ctx.height,
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }, [
        _createElementVNode("rect", {
          width: "24",
          height: "24",
          rx: "12",
          fill: _ctx.color
        }, null, 8, _hoisted_2),
        _createElementVNode("path", {
          d: "M7.91655 12.2457L8.93075 11.2188L10.9975 13.2514L15.4677 8.7983L16.4904\n 9.82528L10.9975 15.2969L7.91655 12.2457Z",
          fill: _ctx.childColor
        }, null, 8, _hoisted_3)
      ], 8, _hoisted_1))
    : _createCommentVNode("", true)
}