
import { defineComponent, PropType } from 'vue';
import { JsonFormsChangeEvent } from '@jsonforms/vue';
import { JsonSchema } from '@jsonforms/core';
import config from '@/config';
import FormGenerator from '@/components/jsonforms/generators/FormGenerator.vue';
import { DivisionItem, StructureItem } from '@/api/admin/dictionary/structure';
import TreeHelper from '@/utils/helpers/TreeHelper';
import { IndustryData } from '@/api/admin/dictionary/industry';
import { CounterpartyFilterParams } from '@/api/counterparty';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import CustomUISchemaElement from '@/components/jsonforms/interfaces/CustomUISchemaElement';

export interface CounterpartyFilterForm {
  data: JsonData,
  currentData: JsonData,
  schema: JsonSchema,
  uischema: CustomUISchemaElement,
  onChange: () => void,
}

export default defineComponent({
  name: 'TheForm',
  emits: ['data-changed', 'submit', 'ajaxManagers', 'ajaxIndustries'],
  inject: ['$userAccess'],
  components: {
    FormGenerator,
  },
  props: {
    data: {
      type: Object as PropType<CounterpartyFilterParams>,
      default: () => ({}),
    },
    industries: {
      type: Array as PropType<IndustryData[]>,
      default: () => ([]),
    },
    divisions: {
      type: Array as PropType<DivisionItem[]>,
      default: () => ([]),
    },
    departments: {
      type: Array as PropType<StructureItem[]>,
      default: () => ([]),
    },
    userId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      currentData: this.data ?? {},
    };
  },
  computed: {
    schema() {
      return {
        type: 'object',
        properties: {
          industry: {
            type: ['string', 'null'],
            oneOf: this.preparedIndustries,
          },
          isFromRussia: {
            type: ['integer', 'null'],
            oneOf: this.preparedStatuses,
          },
          block: {
            type: ['integer', 'null'],
            oneOf: this.preparedBlocks,
          },
          divisionId: {
            type: ['string', 'null'],
            oneOf: this.preparedDivisions,
          },
          departmentId: {
            type: ['array', 'string', 'null'],
            uniqueItems: true,
            items: {
              type: 'string',
              oneOf: this.preparedDepartments,
            },
          },
          assignUserId: {
            type: ['string', 'null'],
          },
          regionId: {
            type: ['string', 'null'],
          },
          address: {
            type: ['string', 'null'],
          },
        },
      };
    },
    uischema() {
      return {
        type: 'VerticalLayout',
        elements: [
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: this.$t('counterparty.list.filter.form.industry'),
                scope: '#/properties/industry',
                options: {
                  search: true,
                  ajaxHandler: async (query: string) => {
                    this.$emit('ajaxIndustries', {
                      query: query === '' ? null : query,
                    });
                  },
                },
              },
              {
                type: 'Control',
                label: this.$t('counterparty.list.filter.form.status'),
                scope: '#/properties/isFromRussia',
              },
              {
                type: 'Control',
                label: this.$t('counterparty.list.filter.form.block'),
                scope: '#/properties/block',
                options: {
                  tooltip: '- **Рабочий блок** - контрагенты, закрепленные за сотрудниками компании и ведущие взаимоотношения с Компанией.\n'
                    + '- **Свободный блок** - содержит информацию обо всех контрагентах когда-либо занесенных в базу, в т.ч. ликвидированных',
                },
              },
            ],
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: this.$t('counterparty.list.filter.form.division'),
                scope: '#/properties/divisionId',
              },
              {
                type: 'Control',
                label: this.$t('counterparty.list.filter.form.department'),
                scope: '#/properties/departmentId',
                options: {
                  readonly: !this.currentData || !this.currentData.divisionId,
                  isTree: true,
                },
              },
              {
                type: 'AjaxControl',
                label: this.$t('counterparty.list.filter.form.assignUser'),
                scope: '#/properties/assignUserId',
                options: {
                  search: true,
                  readonly: !this.$userAccess.viewer.isEntireList,
                  ajaxConfiguration: {
                    categoryName: 'managers',
                  },
                },
              },
            ],
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'AjaxControl',
                label: this.$t('counterparty.list.filter.form.region'),
                scope: '#/properties/regionId',
                options: {
                  search: true,
                  ajaxConfiguration: {
                    categoryName: 'region',
                  },
                },
              },
              {
                type: 'Control',
                label: this.$t('counterparty.list.filter.form.address'),
                scope: '#/properties/address',
                options: {
                  placeholder: this.$t('counterparty.list.filter.form.addressPlaceholder'),
                },
              },
            ],
          },
        ],
      };
    },
    defaultOneOfList() {
      return [{ const: null, title: '' }];
    },
    defaultDepartmentList() {
      return [{
        const: null,
        title: '',
        properties: {
          parent: {
            const: '',
          },
        },
      }];
    },
    preparedIndustries() {
      return [
        ...this.defaultOneOfList,
        ...this.industries.map((industry: IndustryData) => ({
          const: industry.id,
          title: industry.name,
        })),
      ];
    },
    preparedDivisions() {
      if (this.divisions.length > 0) {
        return [
          ...this.defaultOneOfList,
          ...this.divisions.map((division: DivisionItem) => ({
            const: division.id,
            title: division.name,
          })),
        ];
      }

      return this.defaultOneOfList;
    },
    preparedDepartments() {
      if (this.departments.length > 0) {
        return TreeHelper.prepareStructure(this.departments);
      }

      return this.defaultDepartmentList;
    },
    preparedStatuses() {
      return [
        ...this.defaultOneOfList,
        {
          const: config.COUNTERPARTY_FILTER_STATUS_RESIDENT,
          title: this.$t(`counterparty.list.filter.form.statuses.${config.COUNTERPARTY_FILTER_STATUS_RESIDENT}`),
        },
        {
          const: config.COUNTERPARTY_FILTER_STATUS_NOT_RESIDENT,
          title: this.$t(`counterparty.list.filter.form.statuses.${config.COUNTERPARTY_FILTER_STATUS_NOT_RESIDENT}`),
        },
      ];
    },
    preparedBlocks() {
      return [
        ...this.defaultOneOfList,
        {
          const: config.COUNTERPARTY_FILTER_BLOCK_WORKING,
          title: this.$t(`counterparty.list.filter.form.blocks.${config.COUNTERPARTY_FILTER_BLOCK_WORKING}`),
        },
        {
          const: config.COUNTERPARTY_FILTER_BLOCK_FREE,
          title: this.$t(`counterparty.list.filter.form.blocks.${config.COUNTERPARTY_FILTER_BLOCK_FREE}`),
        },
      ];
    },
  },
  watch: {
    currentData(data: CounterpartyFilterParams, lastData: CounterpartyFilterParams) {
      if (data.divisionId !== lastData.divisionId) {
        this.currentData.departmentId = undefined;
      }

      if (data.block === config.COUNTERPARTY_FILTER_BLOCK_FREE) {
        this.currentData.assignUserId = undefined;
      } else if (!this.$userAccess.viewer.isEntireList) {
        this.currentData.assignUserId = this.userId;
      }

      this.$emit('data-changed', data, lastData);
    },
  },
  methods: {
    onChange(event: JsonFormsChangeEvent) {
      this.currentData = event.data;
    },
    onSubmit() {
      this.$emit('submit', this.currentData);
    },
  },
});
