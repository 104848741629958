
import { defineComponent, PropType } from 'vue';
import { JsonFormsChangeEvent } from '@jsonforms/vue';
import { JsonSchema } from '@jsonforms/core';
import FormGenerator from '@/components/jsonforms/generators/FormGenerator.vue';
import { DivisionItem, StructureItem } from '@/api/admin/dictionary/structure';
import TreeHelper from '@/utils/helpers/TreeHelper';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import CustomUISchemaElement from '@/components/jsonforms/interfaces/CustomUISchemaElement';
import { AssignFilterParams } from '@/api/admin/pinning';

export interface AssignFilterForm {
  data: JsonData,
  currentData: JsonData,
  schema: JsonSchema,
  uischema: CustomUISchemaElement,
  onChange: () => void,
}

export default defineComponent({
  name: 'TheForm',
  emits: ['data-changed', 'submit', 'ajaxManagers'],
  inject: ['$userAccess'],
  components: {
    FormGenerator,
  },
  props: {
    data: {
      type: Object as PropType<AssignFilterParams>,
      default: () => ({}),
    },
    divisions: {
      type: Array as PropType<DivisionItem[]>,
      default: () => ([]),
    },
    departments: {
      type: Array as PropType<StructureItem[]>,
      default: () => ([]),
    },
  },
  data() {
    return {
      currentData: this.data ?? {},
    };
  },
  computed: {
    schema() {
      return {
        type: 'object',
        properties: {
          divisionId: {
            type: ['string', 'null'],
            oneOf: this.preparedDivisions,
          },
          departmentId: {
            type: ['array', 'string', 'null'],
            uniqueItems: true,
            items: {
              type: 'string',
              oneOf: this.preparedDepartments,
            },
          },
          userId: {
            type: ['string', 'null'],
          },
        },
      };
    },
    uischema() {
      return {
        type: 'VerticalLayout',
        elements: [
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: this.$t('counterparty.list.filter.form.division'),
                scope: '#/properties/divisionId',
                options: {
                  visible: this.$userAccess.admin.isSuperAdmin
                    || this.$userAccess.admin.isAdminCompany,
                },
              },
              {
                type: 'Control',
                label: this.$t('counterparty.list.filter.form.department'),
                scope: '#/properties/departmentId',
                options: {
                  readonly: !this.currentData || !this.currentData.divisionId,
                  isTree: true,
                },
              },
              {
                type: 'AjaxControl',
                label: this.$t('counterparty.list.filter.form.assignUser'),
                scope: '#/properties/userId',
                options: {
                  search: true,
                  ajaxConfiguration: {
                    categoryName: 'managers',
                    filter: {
                      divisionId: this.currentData.division,
                      departmentId: this.currentData.department,
                    },
                  },
                },
              },
            ],
          },
        ],
      };
    },
    defaultOneOfList() {
      return [{ const: null, title: '' }];
    },
    defaultDepartmentList() {
      return [{
        const: null,
        title: '',
        properties: {
          parent: {
            const: '',
          },
        },
      }];
    },
    preparedDivisions() {
      if (this.divisions.length > 0) {
        return [
          ...this.defaultOneOfList,
          ...this.divisions.map((division: DivisionItem) => ({
            const: division.id,
            title: division.name,
          })),
        ];
      }

      return this.defaultOneOfList;
    },
    preparedDepartments() {
      if (this.departments.length > 0) {
        return TreeHelper.prepareStructure(this.departments);
      }

      return this.defaultDepartmentList;
    },
  },
  watch: {
    currentData(data: AssignFilterParams, lastData: AssignFilterParams) {
      if (data.divisionId !== lastData.divisionId) {
        this.currentData.departmentId = undefined;
        this.currentData.userId = undefined;
      }

      if (data.departmentId !== lastData.departmentId) {
        this.currentData.userId = undefined;
      }

      this.$emit('data-changed', data, lastData);
    },
  },
  methods: {
    onChange(event: JsonFormsChangeEvent) {
      this.currentData = event.data;
    },
    onSubmit() {
      this.$emit('submit', this.currentData);
    },
  },
});
