import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0bde7630"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "mb-[24px]"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "text-black"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_switch = _resolveComponent("the-switch")!
  const _component_select_tree = _resolveComponent("select-tree")!
  const _component_role_item = _resolveComponent("role-item", true)!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_the_switch, {
      class: "mb-[24px]",
      variant: "primary",
      "model-value": _ctx.localIsActive,
      style: _normalizeStyle({ marginLeft: `${34 * _ctx.level}px` }),
      disabled: _ctx.isDisableRole,
      "onUpdate:modelValue": _ctx.updateHandler
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.role.label), 1)
      ]),
      _: 1
    }, 8, ["model-value", "style", "disabled", "onUpdate:modelValue"]),
    (_ctx.isShowInput)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.disabled)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentRoleAttributes, (item, index) => {
                  return (_openBlock(), _createElementBlock("span", {
                    key: index,
                    class: "departments-item"
                  }, [
                    _createTextVNode(_toDisplayString(_ctx.getNameByValue(item)) + " ", 1),
                    (_ctx.isShowComma(index))
                      ? (_openBlock(), _createElementBlock("span", _hoisted_3, ", "))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ]))
            : (_openBlock(), _createBlock(_component_select_tree, {
                key: 1,
                multiple: "",
                data: _ctx.departments,
                value: _ctx.currentRoleAttributes,
                placeholder: _ctx.$t('admin.user.detail.role.placeholders.department'),
                onValueSelect: _ctx.updateRoleAttributesHandler
              }, null, 8, ["data", "value", "placeholder", "onValueSelect"]))
        ]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.children, (item) => {
      return (_openBlock(), _createBlock(_component_role_item, {
        key: item.id,
        role: item,
        level: _ctx.level + 1,
        disabled: _ctx.disabled,
        departments: _ctx.departments,
        "roles-attributes": _ctx.rolesAttributes,
        "user-roles": _ctx.userRoles,
        onUpdated: _ctx.updatedChildHandler,
        onUpdateRoleAttributes: _ctx.updateRoleAttributesChildHandler
      }, null, 8, ["role", "level", "disabled", "departments", "roles-attributes", "user-roles", "onUpdated", "onUpdateRoleAttributes"]))
    }), 128))
  ]))
}