import _ from 'lodash';
import { StructureItem } from '@/api/admin/dictionary/structure';

export interface TreeItem {
  id: string,
  items?: TreeItem[],
}

export interface JsonFormsTreeItem {
  const: string,
  title: string,
  properties?: {
    parent?: {
      const?: string,
    },
  },
}

export type TreeItemResponse<T = unknown> = T & TreeItem;

export default class TreeHelper {
  static getItemById<T = unknown>(
    id: string | number,
    elements: TreeItem[],
  ): TreeItemResponse<T> | null {
    const recursFunc = (localElements: TreeItem[]): TreeItemResponse | null => {
      for (const element of localElements) {
        if (element.id === id) {
          return element;
        }

        if (element.items?.length) {
          const foundedElement = recursFunc(element.items);

          if (foundedElement) {
            return foundedElement;
          }
        }
      }

      return null;
    };

    return recursFunc(elements) as TreeItemResponse<T>;
  }

  static prepareStructure(
    items: StructureItem[],
    parentId: string | null = null,
  ): JsonFormsTreeItem[] {
    const generateItems = (item: StructureItem) => (item.items
      ? this.prepareStructure(item.items, item.id)
      : []);

    return _.flattenDeep([
      ...items.map((item: StructureItem) => ([{
        const: item.id,
        title: item.name,
        properties: {
          parent: {
            const: parentId,
          },
        },
      }, ...generateItems(item)])),
    ]) as JsonFormsTreeItem[];
  }
}
