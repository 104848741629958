import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isSolid)
    ? (_openBlock(), _createElementBlock("svg", {
        key: 0,
        width: _ctx.width,
        height: _ctx.height,
        viewBox: "0 0 16 18",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }, [
        _createElementVNode("path", {
          "fill-rule": "evenodd",
          "clip-rule": "evenodd",
          d: "M0.166 3.99909C3.06114 3.96309 5.84481 2.87755 8 0.944092C10.155 2.87791 12.9387 3.96382\n 15.834 4.00009C15.944 4.65009 16 5.32009 16 6.00109C16 11.2261 12.66 15.6711 8 17.3181C3.34\n 15.6701 0 11.2251 0 6.00009C0 5.31809 0.0569999 4.65009 0.166 3.99909ZM11.707 7.70709C11.8892\n 7.51849 11.99 7.26589 11.9877 7.00369C11.9854 6.74149 11.8802 6.49068 11.6948 6.30527C11.5094\n 6.11986 11.2586 6.0147 10.9964 6.01242C10.7342 6.01014 10.4816 6.11093 10.293 6.29309L7\n 9.58609L5.707 8.29309C5.5184 8.11093 5.2658 8.01014 5.0036 8.01242C4.7414 8.0147 4.49059\n 8.11986 4.30518 8.30527C4.11977 8.49068 4.0146 8.74149 4.01233 9.00369C4.01005 9.26589\n 4.11084 9.51849 4.293 9.70709L6.293 11.7071C6.48053 11.8946 6.73484 11.9999 7 11.9999C7.26516\n 11.9999 7.51947 11.8946 7.707 11.7071L11.707 7.70709Z",
          fill: _ctx.color
        }, null, 8, _hoisted_2)
      ], 8, _hoisted_1))
    : _createCommentVNode("", true)
}