
import { defineComponent, PropType } from 'vue';
import BadgeInWork from '@/components/widgets/badge-in-work/BadgeInWork.vue';
import { SyncFieldErrorType } from '@/api/card';

export default defineComponent({
  name: 'SyncErrorContent',
  components: { BadgeInWork },
  props: {
    items: Array as PropType<SyncFieldErrorType[]>,
  },
});
