import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b8c0b7ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "block" }
const _hoisted_2 = { class: "flex items-center space-x-[10px] mb-[4px]" }
const _hoisted_3 = { class: "text" }
const _hoisted_4 = { class: "subtext" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = { class: "flex items-center space-x-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_resident_block = _resolveComponent("resident-block")!
  const _component_card_switcher = _resolveComponent("card-switcher")!
  const _component_warning_icon = _resolveComponent("warning-icon")!
  const _component_icon_button = _resolveComponent("icon-button")!
  const _component_clipboard_document_list_icon = _resolveComponent("clipboard-document-list-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_the_danger_modal = _resolveComponent("the-danger-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", null, [
          _createVNode(_component_resident_block, { "is-resident": _ctx.isResident }, null, 8, ["is-resident"])
        ]),
        _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.shortName), 1)
      ]),
      _createElementVNode("p", _hoisted_4, [
        (_ctx.inn)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t('counterpartyLabels.inn')) + " " + _toDisplayString(_ctx.inn), 1))
          : _createCommentVNode("", true),
        (_ctx.taxNumber)
          ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t('counterpartyLabels.taxNumber')) + " " + _toDisplayString(_ctx.taxNumber), 1))
          : _createCommentVNode("", true),
        (_ctx.kpp)
          ? (_openBlock(), _createElementBlock("span", _hoisted_7, " , " + _toDisplayString(_ctx.$t('counterpartyLabels.kpp')) + " " + _toDisplayString(_ctx.kpp), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      (this.cards.length > 1)
        ? (_openBlock(), _createBlock(_component_card_switcher, {
            key: 0,
            items: _ctx.cards,
            "current-card": _ctx.currentCard,
            onChangeCard: _ctx.changeCardHandler
          }, null, 8, ["items", "current-card", "onChangeCard"]))
        : _createCommentVNode("", true),
      (_ctx.showErrorButton)
        ? (_openBlock(), _createBlock(_component_icon_button, {
            key: 1,
            description: _ctx.$t('counterparty.card.syncFieldError.button'),
            onClick: _ctx.openFieldErrorHandler
          }, {
            default: _withCtx(() => [
              _createVNode(_component_warning_icon, {
                class: "warning-error",
                color: "red"
              })
            ]),
            _: 1
          }, 8, ["description", "onClick"]))
        : _createCommentVNode("", true),
      _createVNode(_component_router_link, {
        to: `/report/${_ctx.currentCard?.id}`,
        target: "_blank"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_icon_button, { description: "Сформировать отчет" }, {
            default: _withCtx(() => [
              _createVNode(_component_clipboard_document_list_icon, { type: "outline" })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["to"])
    ]),
    _createVNode(_component_the_danger_modal, {
      "approve-button-text": _ctx.$t('counterparty.header.dangerModal.confirmButton'),
      text: _ctx.$t('counterparty.header.dangerModal.text'),
      "hint-text": _ctx.$t('counterparty.header.dangerModal.hintText'),
      "is-open": _ctx.openDangerModal,
      onApprovalHandler: _ctx.approvalHandler,
      onCloseModal: _ctx.closeModal
    }, null, 8, ["approve-button-text", "text", "hint-text", "is-open", "onApprovalHandler", "onCloseModal"])
  ]))
}