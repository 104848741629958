import { JsonFormsChangeEvent } from '@jsonforms/vue';
import { defineComponent } from 'vue';
import config from '@/config';
import { NewsAddType, NewsDataType } from '@/api/news';
import TheModal from '@/components/ui/modal/TheModal/TheModal.vue';
import AddIcon from '@/components/ui/icons/AddIcon.vue';
import ModalContent
  from '@/components/counterparty/card/blocks/news/AddModal/content/TheContent.vue';
import IconButton from '@/components/ui/buttons/IconButton/IconButton.vue';
import TheSideModal from '@/components/ui/modal/TheSideModal/TheSideModal.vue';
import DefaultButton from '@/components/ui/buttons/DefaultButton/DefaultButton.vue';

export default defineComponent({
  name: 'TheAddModal',
  inject: ['$sizeWindow'],
  emits: ['addNewsHandler', 'closeUpdateModal'],
  components: {
    TheModal,
    AddIcon,
    ModalContent,
    IconButton,
    TheSideModal,
    DefaultButton,
  },
  props: {
    news: Object,
    newsId: Number,
  },
  data: () => ({
    isOpen: false,
    isLoading: false,
    formData: {},
    errors: [],
  }),
  computed: {
    isDisabledButton() {
      return this.errors.length > 0;
    },
    newsData() {
      if (this.newsId) {
        const newsItem = this.news.find((newItem: NewsDataType) => newItem.id === this.newsId);
        const inputDateObject = newsItem.publishedAt.split('.');
        const date = new Date(
          inputDateObject[2] ?? 0,
          inputDateObject[1] - 1 ?? 0,
          inputDateObject[0] ?? 0,
        );

        return {
          [config.NEWS_BLOCK_DATE]: date.toLocaleDateString('en-ca'),
          [config.NEWS_BLOCK_LINK]: newsItem.link,
          [config.NEWS_BLOCK_DESCRIPTION]: newsItem.content,
        };
      }

      return {};
    },
  },
  methods: {
    openModal() {
      this.isOpen = true;
    },
    closeModal() {
      this.isOpen = false;
      this.formData = {};

      this.$emit('closeUpdateModal');
    },
    onChange(data: JsonFormsChangeEvent) {
      this.formData = data.data;
      this.errors = data.errors;
    },
    addNewsHandler() {
      this.isLoading = true;

      const newData = <NewsAddType>{
        content: this.formData[config.NEWS_BLOCK_DESCRIPTION] ?? '',
        link: this.formData[config.NEWS_BLOCK_LINK] ?? '',
        publishedAt: this.formData[config.NEWS_BLOCK_DATE] ?? '',
      };

      this.$emit('addNewsHandler', newData, () => {
        this.isLoading = false;

        this.closeModal();
      });
    },
  },
});
