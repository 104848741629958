import { LocaleMessages } from "@intlify/core-base";
import { createI18n, VueMessageType } from "vue-i18n";
import en from "@/locales/en";
import ru from "@/locales/ru";

const messages: LocaleMessages<VueMessageType> = {
  en,
  ru,
};

const i18n = createI18n({
  legacy: false,
  locale: "ru",
  fallbackLocale: "en",
  globalInjection: true,
  messages,
});

export default i18n;
