import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["width", "height"]
const _hoisted_4 = ["stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isSolid)
    ? (_openBlock(), _createElementBlock("svg", {
        key: 0,
        width: _ctx.width,
        height: _ctx.height,
        viewBox: "0 0 20 20",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }, [
        _createElementVNode("path", {
          d: "M2 3C2 2.73478 2.10536 2.48043 2.29289 2.29289C2.48043 2.10536 2.73478 2 3 2H5.153C5.38971\n 2.00011 5.6187 2.08418 5.79924 2.23726C5.97979 2.39034 6.10018 2.6025 6.139 2.836L6.879\n 7.271C6.91436 7.48222 6.88097 7.69921 6.78376 7.89003C6.68655 8.08085 6.53065 8.23543\n 6.339 8.331L4.791 9.104C5.34611 10.4797 6.17283 11.7293 7.22178 12.7782C8.27072 13.8272\n 9.52035 14.6539 10.896 15.209L11.67 13.661C11.7655 13.4695 11.9199 13.3138 12.1106\n 13.2166C12.3012 13.1194 12.5179 13.0859 12.729 13.121L17.164 13.861C17.3975 13.8998\n 17.6097 14.0202 17.7627 14.2008C17.9158 14.3813 17.9999 14.6103 18 14.847V17C18 17.2652\n 17.8946 17.5196 17.7071 17.7071C17.5196 17.8946 17.2652 18 17 18H15C7.82 18 2 12.18 2 5V3Z",
          fill: _ctx.color
        }, null, 8, _hoisted_2)
      ], 8, _hoisted_1))
    : (_openBlock(), _createElementBlock("svg", {
        key: 1,
        width: _ctx.width,
        height: _ctx.height,
        viewBox: "0 0 20 20",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }, [
        _createElementVNode("path", {
          d: "M1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3V4C1 12.284 7.716 19 16 19H17C17.5304 19\n 18.0391 18.7893 18.4142 18.4142C18.7893 18.0391 19 17.5304 19 17V13.721C19.0001 13.511\n 18.934 13.3064 18.8112 13.136C18.6885 12.9657 18.5152 12.8383 18.316 12.772L13.823\n 11.274C13.5947 11.1981 13.3466 11.2071 13.1244 11.2993C12.9021 11.3915 12.7205 11.5607\n 12.613 11.776L11.483 14.033C9.03446 12.9267 7.07332 10.9655 5.967 8.517L8.224 7.387C8.43925\n 7.27945 8.60851 7.0979 8.70072 6.87564C8.79293 6.65339 8.8019 6.40534 8.726 6.177L7.228\n 1.684C7.16171 1.48496 7.03449 1.3118 6.86436 1.18905C6.69422 1.0663 6.48979 1.00016 6.28\n 1H3C2.46957 1 1.96086 1.21071 1.58579 1.58579Z",
          stroke: _ctx.color,
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }, null, 8, _hoisted_4)
      ], 8, _hoisted_3))
}