
import { defineComponent } from 'vue';
import IconButton from '@/components/ui/buttons/IconButton/IconButton.vue';
import AddIcon from '@/components/ui/icons/AddIcon.vue';
import DefaultButton from '@/components/ui/buttons/DefaultButton/DefaultButton.vue';

export default defineComponent({
  name: 'AddButtonComponent',
  inject: ['$sizeWindow'],
  emits: ['clickHandler'],
  components: {
    DefaultButton,
    IconButton,
    AddIcon,
  },
  methods: {
    clickHandler() {
      this.$emit('clickHandler');
    },
  },
});
