import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lock_icon = _resolveComponent("lock-icon")!
  const _component_the_tooltip = _resolveComponent("the-tooltip")!

  return (_openBlock(), _createBlock(_component_the_tooltip, null, {
    icon: _withCtx(() => [
      _createVNode(_component_lock_icon, {
        type: "solid",
        width: "14",
        height: "17"
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('common.blocked')), 1)
    ]),
    _: 1
  }))
}