import _ from 'lodash';
import { Module } from 'vuex';
import BankAccountService, {
  BankAccountCreateData,
  BankAccountData,
  BankAccountUpdateData,
} from '@/api/admin/dictionary/bankAccount';
import { AxiosCallResponse } from '@/api/base';
import { TYPE_LABEL_SUCCESS } from '@/components/ui/notifications/TheAlert/TheAlert.vue';
import i18n from '@/i18n';
import { StateType } from '@/store';

export interface BankAccountOneStateType {
  data: BankAccountData | null,
}

const initialState = {
  data: null,
};

const bankAccountOneModule: Module<BankAccountOneStateType, StateType> = {
  namespaced: true,
  state: _.cloneDeep(initialState),
  getters: {
    data: (state) => state.data,
  },
  mutations: {
    setData(state, item: BankAccountData) {
      state.data = item;
    },
  },
  actions: {
    async create({ rootGetters }, data: BankAccountCreateData) {
      const api: BankAccountService = rootGetters.$api.admin.dictionary.bankAccount;

      const response: AxiosCallResponse<BankAccountData> = await api.create(data);

      if (!response.error) {
        await rootGetters
          .$notificationLogger
          .setNotification(
            TYPE_LABEL_SUCCESS,
            i18n.global.t('notification.admin.dictionary.success.create'),
          );
      }
    },
    async update(
      { rootGetters },
      params: { id: string, data: BankAccountUpdateData },
    ) {
      const api: BankAccountService = rootGetters.$api.admin.dictionary.bankAccount;

      const response: AxiosCallResponse<null> = await api.update(params.id, params.data);

      if (!response.error) {
        await rootGetters
          .$notificationLogger
          .setNotification(
            TYPE_LABEL_SUCCESS,
            i18n.global.t('notification.admin.dictionary.success.update'),
          );
      }
    },
    async delete({ rootGetters }, id: string) {
      const api: BankAccountService = rootGetters.$api.admin.dictionary.bankAccount;

      const response: AxiosCallResponse<null> = await api.delete(id);

      if (!response.error) {
        await rootGetters
          .$notificationLogger
          .setNotification(
            TYPE_LABEL_SUCCESS,
            i18n.global.t('notification.admin.dictionary.success.delete'),
          );
      }
    },
  },
};

export default bankAccountOneModule;
