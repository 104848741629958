import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a5abfe92"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item-data" }
const _hoisted_2 = { class: "field" }
const _hoisted_3 = { class: "label-text" }
const _hoisted_4 = {
  key: 0,
  class: "status"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_lock_icon = _resolveComponent("lock-icon")!
  const _component_the_tooltip = _resolveComponent("the-tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: "item",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clickHandler && _ctx.clickHandler(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.name), 1),
        _createElementVNode("div", null, _toDisplayString(_ctx.billingNumber), 1)
      ])
    ]),
    (_ctx.isBlocked)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_the_tooltip, null, {
            icon: _withCtx(() => [
              _createVNode(_component_lock_icon, { type: "outline" })
            ]),
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('counterparty.payment.isBlock')), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}