import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9db7f0cc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "border-t-[1px]" }
const _hoisted_2 = { class: "text-[18px] font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_block_arrow = _resolveComponent("block-arrow")!
  const _component_form_generator = _resolveComponent("form-generator")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "block-counterparty-header",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleBlock && _ctx.toggleBlock(...args)))
    }, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('dictionary.pinning.counterpartyTitle')), 1),
      _createVNode(_component_block_arrow, {
        "is-open": !_ctx.isOpen,
        width: "16",
        height: "8"
      }, null, 8, ["is-open"])
    ]),
    _withDirectives(_createElementVNode("div", null, [
      _createVNode(_component_form_generator, {
        uischema: _ctx.uischema,
        schema: _ctx.schema,
        information: _ctx.data,
        config: { onlyText: true },
        "validation-mode": "ValidateAndHide"
      }, null, 8, ["uischema", "schema", "information"])
    ], 512), [
      [_vShow, _ctx.isOpen]
    ])
  ]))
}