
import {
  ControlElement,
  isOneOfEnumControl,
  JsonFormsRendererRegistryEntry,
  rankWith,
} from '@jsonforms/core';
import { RendererProps, rendererProps, useJsonFormsOneOfEnumControl } from '@jsonforms/vue';
import { useVanillaControl } from '@jsonforms/vue-vanilla';
import { defineComponent, ref, Ref } from 'vue';
import SelectDefault from '@/components/ui/selects/SelectDefault/SelectDefault.vue';
import { controlWrapper, validationMethods } from '@/components/jsonforms/util/renderer';
import config from '@/config';
import _ from 'lodash';
import ControlWrapper from './ControlWrapper.vue';

const TYPE_LINK = 'link';

export interface OneOfTree {
  const: string,
  title: string,
  items?: OneOfTree[]
}

const controlRenderer = defineComponent({
  name: 'enum-oneof-control-renderer',
  components: {
    ControlWrapper,
    SelectDefault,
  },
  props: {
    ...rendererProps<ControlElement>(),
  },
  computed: {
    data() {
      return this.prepareOneOf(this.control.schema.oneOf);
    },
    isTypeLink() {
      return this.appliedOptions.type === TYPE_LINK;
    },
    preparedControlWrapper() {
      return controlWrapper(this);
    },
    labelValue() {
      if (this.control.data) {
        return this.control.data.name
          ?? this.getTitleById(this.control.data)
          ?? this.control.data;
      }

      return this.$t('common.noData');
    },
  },
  watch: {
    controlWrapper(value, oldValue) {
      if (value.errors !== oldValue.errors) {
        this.errors = value.errors;
      }
    },
  },
  methods: {
    getTitleById(id: string): string | null {
      const items = this.control.schema.oneOf;
      const item = items.find((object: OneOfTree) => object.const === id);

      return item?.title ?? null;
    },
    getLink() {
      return `${this.appliedOptions.baseUrl}/${this.control.data}`;
    },
    blurHandler(value: string) {
      const localValue: string | undefined = !value && this.isRequired()
        ? undefined
        : value;

      this.isFocused = false;

      this.errors = this.getError(this.prepareData(localValue), this.prepareSchema());

      this.handleChange(this.control.path, value);
    },
    prepareOneOf(items: OneOfTree[]) {
      return [
        ...items.map((item: OneOfTree) => ({
          id: item.const,
          name: item.title,
        })),
      ];
    },
  },
  setup(props: RendererProps<ControlElement>) {
    const vanillaControl = useVanillaControl(useJsonFormsOneOfEnumControl(props));
    const control = vanillaControl.control as unknown as Ref;
    const errors = ref(vanillaControl.controlWrapper?.value?.errors ?? '');
    const debounceTime = config.CONTROL_DEBOUNCE ?? 500;
    const validMethods = validationMethods(control);

    const debounceChangeHandler = _.debounce(async (value: string) => {
      const localValue: string | undefined = !value && validMethods.isRequired()
        ? undefined
        : value;

      vanillaControl.handleChange(control.value.path, localValue);
    }, debounceTime);

    return {
      errors,
      ...vanillaControl,
      ...validMethods,
      debounceChangeHandler,
    };
  },
});

export default controlRenderer;

export const entry: JsonFormsRendererRegistryEntry = {
  renderer: controlRenderer,
  tester: rankWith(2, isOneOfEnumControl),
};
