import { Module } from 'vuex';
import list, { CommunicationListStateType } from '@/store/modules/counterparty/block/relationship/communication/list';
import one, { CommunicationOneStateType } from '@/store/modules/counterparty/block/relationship/communication/one';
import { StateType } from '@/store';

export interface CommunicationStateType {
  list: CommunicationListStateType,
  one: CommunicationOneStateType,
}

const communicationModule: Module<CommunicationStateType, StateType> = {
  namespaced: true,
  modules: {
    list,
    one,
  },
};

export default communicationModule;
