import i18n from '@/i18n';
import { Boarding, BoardingSteps } from 'boarding.js';
import { BoardingStepDefinition } from 'boarding.js/dist/boarding-types';

export default class BoardingTour {
  protected boarding: Boarding;

  constructor() {
    this.boarding = new Boarding({
      opacity: 0.5,
      padding: 0,
      closeBtnText: i18n.global.t('tour.closeButton'),
      prevBtnText: i18n.global.t('tour.prevButton'),
      nextBtnText: i18n.global.t('tour.nextButton'),
      doneBtnText: i18n.global.t('tour.doneButton'),
      allowClose: false,
      strictClickHandling: 'block-all',
      scrollIntoViewOptions: {
        block: 'center',
        inline: 'center',
      },
      keyboardControl: false,
    });
  }

  public start(index = 0) {
    this.boarding.start(index);
  }

  public reset() {
    this.boarding.reset();
  }

  public registerSteps(steps: BoardingSteps) {
    const localSteps = steps.filter((item: BoardingStepDefinition) => {
      const elementSelector = item.element as string;
      const element = document.querySelector(elementSelector);

      if (element) {
        return item;
      }

      return false;
    });

    this.boarding.defineSteps(localSteps);
  }

  public preventMove() {
    this.boarding.preventMove();
  }

  public clearMove() {
    this.boarding.clearMovePrevented();
  }

  public continue() {
    this.boarding.continue();
  }

  public delay(timeout: number) {
    this.boarding.preventMove();

    setTimeout(() => {
      this.boarding.continue();
    }, timeout);
  }
}
