<template>
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd" clip-rule="evenodd"
      d="M8 16.7999C10.1217 16.7999 12.1566 15.9571 13.6569 14.4568C15.1571 12.9565
 16 10.9217 16 8.79993C16 6.67819 15.1571 4.64336 13.6569 3.14307C12.1566
 1.64278 10.1217 0.799927 8 0.799927C5.87827 0.799927 3.84344 1.64278 2.34315
 3.14307C0.842855 4.64336 0 6.67819 0 8.79993C0 10.9217 0.842855 12.9565 2.34315
 14.4568C3.84344 15.9571 5.87827 16.7999 8 16.7999ZM11.707 7.50693C11.8892 7.31832
 11.99 7.06572 11.9877 6.80353C11.9854 6.54133 11.8802 6.29052 11.6948 6.10511C11.5094
 5.9197 11.2586 5.81453 10.9964 5.81225C10.7342 5.80997 10.4816 5.91077 10.293 6.09293L7
 9.38593L5.707 8.09293C5.5184 7.91077 5.2658 7.80997 5.0036 7.81225C4.7414 7.81453
 4.49059 7.9197 4.30518 8.10511C4.11977 8.29052 4.0146 8.54133 4.01233 8.80353C4.01005
 9.06572 4.11084 9.31832 4.293 9.50693L6.293 11.5069C6.48053 11.6944 6.73484 11.7997
 7 11.7997C7.26516 11.7997 7.51947 11.6944 7.707 11.5069L11.707 7.50693Z"
      fill="#15803D"
    />
  </svg>
</template>
