import { Module } from 'vuex';
import StructureService, {
  FilterStructureParams,
  StructureItem,
  StructureResponseType,
} from '@/api/admin/dictionary/structure';
import { StateType } from '@/store';

export interface StructureListStateType {
  items: StructureItem[],
  filter: FilterStructureParams,
}

const getInitialState = (): StructureListStateType => ({
  items: [],
  filter: {
    companyId: null,
    parentId: null,
  },
});

const initialState = getInitialState();

const structureListModule: Module<StructureListStateType, StateType> = {
  namespaced: true,
  state: initialState,
  getters: {
    items: (state) => state.items,
    filter: (state): FilterStructureParams => state.filter,
  },
  mutations: {
    setItems(state, items: StructureItem[]) {
      state.items = items;
    },
    setFilter(state, filter: FilterStructureParams) {
      state.filter = filter;
    },
    resetState: (state) => {
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    resetState({ commit }) {
      commit('resetState');
    },
    changeFilter({ commit }, filter: FilterStructureParams) {
      commit('setFilter', filter);
    },
    async loadItems({ getters, rootGetters, commit }, filter: FilterStructureParams | null = null) {
      const localFilterParams = filter ?? getters.filter;

      const api: StructureService = rootGetters.$api.admin.dictionary.structure;

      const response: StructureResponseType = await api.list(localFilterParams);

      commit('setItems', response.data?.items ?? initialState.items);
      commit('setFilter', localFilterParams);
    },
  },
};

export default structureListModule;
