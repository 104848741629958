
import { defineComponent, PropType } from 'vue';
import _, { isArray } from 'lodash';
import CloseIcon from '@/components/ui/icons/CloseIcon.vue';
import SelectRemoveIcon from '@/components/ui/icons/SelectRemoveIcon.vue';
import SelectedArrow from '@/components/ui/icons/SelectedArrow.vue';
import SelectArrow from '@/components/ui/icons/SelectArrow.vue';
import SearchIcon from '@/components/ui/icons/SearchIcon.vue';
import CalculatePosition from '@/utils/directives/CalculatePosition';
import OutsideClick from '@/utils/directives/OutsideClick';

export interface DataType {
  id: string | number | null,
  name: string,
}

export default defineComponent({
  name: 'SelectDefault',
  emits: ['valueSelect', 'blur', 'searchChanged', 'focus'],
  components: {
    SelectRemoveIcon,
    SelectedArrow,
    SelectArrow,
    SearchIcon,
    CloseIcon,
  },
  props: {
    data: {
      type: Array as PropType<DataType[]>,
      default: () => ([]),
    },
    value: {
      type: [Number, String, Array],
      default: undefined,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    calculatePosition: {
      type: [Boolean, Object],
      default: true,
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showSearch: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    debounce: {
      type: Number,
      default: 500,
    },
    ajaxHandler: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      localValue: this.multiple && !this.value ? [] : this.value,
      isOpen: false,
      search: '',
      isLoading: false,
      debounceFunction: null,
      initialValueText: null,
    };
  },
  computed: {
    preparedData() {
      const enums = [...this.data];

      if (this.value && !this.multiple) {
        enums.push({
          id: this.value,
          name: this.initialValueText ?? this.value,
        });
      }

      return _.uniqBy(enums, 'id');
    },
    filteredList() {
      return this.search && !this.ajaxHandler
        ? this.preparedData.filter((
          item: DataType,
        ) => item.name.toLowerCase().includes(this.search.toLowerCase()))
        : this.preparedData.filter((item: DataType) => item.id !== null);
    },
    showPlaceholder() {
      const hasLocalValue = this.multiple && Array.isArray(this.localValue)
        ? !!this.localValue.length
        : !!this.localValue;

      return this.placeholder && !hasLocalValue;
    },
    showClearButton(): boolean {
      if (isArray(this.localValue)) {
        return this.clearable
          && this.isOpen
          && !this.disabled
          && !this.required
          && (!!this.localValue || this.localValue === 0)
          && this.localValue.length > 0;
      }

      return this.clearable
          && this.isOpen
          && !this.disabled
          && !this.required
          && this.localValue !== null
          && this.value !== undefined;
    },
  },
  watch: {
    async isOpen(value: boolean, oldValue: boolean) {
      const ref = this.$refs.search as HTMLInputElement;

      if (value) {
        if (ref) {
          ref.focus();
        }
      }

      if (!value && oldValue) {
        this.$emit('blur', this.localValue);
      }

      if (this.ajaxHandler && value) {
        await this.loadDataByAjax(this.search);
      }
    },
    value() {
      this.localValue = this.value;
    },
    search(value) {
      if (value !== '') {
        this.isOpen = true;
      }

      this.$emit('searchChanged', value);
    },
  },
  methods: {
    isSelected(value: string | number) {
      return Array.isArray(this.localValue)
        ? this.localValue.includes(value)
        : this.localValue === value;
    },
    async closeDropdown() {
      if (!this.isOpen) {
        return;
      }
      this.isOpen = false;
    },
    toggleDropdown() {
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        this.$emit('focus');
      }
    },
    getNameByValue(value: string | number) {
      const foundItem = this.preparedData.find((item: DataType) => item.id === value);

      return foundItem?.name ?? '';
    },
    remove(value: string | number) {
      if (!this.disabled) {
        this.localValue = this.multiple && Array.isArray(this.localValue)
          ? this.localValue.filter((localValue: string | number) => localValue !== value)
          : null;

        if (this.multiple && !value) {
          this.localValue = [];
        }

        if (this.autoClose && !this.multiple) {
          this.closeDropdown();
        }

        this.$emit('valueSelect', this.localValue);
      }
    },
    select(value: string | number) {
      if (this.multiple && Array.isArray(this.localValue)) {
        this.localValue = this.localValue.includes(value)
          ? this.localValue.filter((localValue: string | number) => localValue !== value)
          : Array.from(new Set([...this.localValue, value]));
        this.initialValueText = this.getNameByValue(this.localValue);
      } else {
        this.localValue = value;
        this.initialValueText = this.getNameByValue(this.localValue);
      }

      if (this.autoClose && !this.multiple) {
        this.closeDropdown();
      }

      this.$emit('valueSelect', this.localValue);
    },
    async loadDataByAjax(search: string) {
      this.isLoading = true;

      await this.ajaxHandler(search);

      this.isLoading = false;
    },
  },
  directives: {
    OutsideClick,
    CalculatePosition,
  },
  created() {
    this.initialValueText = this.getNameByValue(this.localValue);
    this.debounceFunction = _.debounce(async function handler(e: { target: HTMLInputElement }) {
      this.search = String(e.target.value).trim();

      if (this.ajaxHandler) {
        await this.loadDataByAjax(this.search);
      }
    }, this.debounce);
  },
});
