
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'DayItem',
  props: {
    data: {
      type: Number,
      default: null,
    },
    isSelectDay: {
      type: Boolean,
      default: false,
    },
    isCurrentDay: {
      type: Boolean,
      default: false,
    },
    isIntervalDay: {
      type: Boolean,
      default: false,
    },
    isFirstIntervalDay: {
      type: Boolean,
      default: false,
    },
    isLastIntervalDay: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dayClass() {
      return {
        usable: this.isUsable,
        selected: this.isSelectDay,
        current: this.isCurrentDay,
      };
    },
    dayWrapperClass() {
      return {
        interval: this.isIntervalDay,
        first: this.isFirstIntervalDay,
        last: this.isLastIntervalDay,
      };
    },
    isUsable() {
      return this.data !== null;
    },
  },
});
