import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, mergeProps as _mergeProps, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7fd2a620"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sidebar-content" }
const _hoisted_2 = { class: "sidebar-block" }
const _hoisted_3 = { class: "flex justify-between items-end mb-[28px]" }
const _hoisted_4 = {
  key: 0,
  class: "action-buttons"
}
const _hoisted_5 = { class: "flex justify-between relative" }
const _hoisted_6 = { class: "sidebar-block__title" }
const _hoisted_7 = { class: "sidebar-block" }
const _hoisted_8 = { class: "sidebar-footer" }
const _hoisted_9 = { class: "text-[14px]" }
const _hoisted_10 = { class: "text-[14px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_avatar = _resolveComponent("the-avatar")!
  const _component_edit_icon = _resolveComponent("edit-icon")!
  const _component_icon_button = _resolveComponent("icon-button")!
  const _component_lock_icon = _resolveComponent("lock-icon")!
  const _component_unlock_icon = _resolveComponent("unlock-icon")!
  const _component_edit_form = _resolveComponent("edit-form")!
  const _component_view_form = _resolveComponent("view-form")!
  const _component_default_button = _resolveComponent("default-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_the_avatar, {
            status: _ctx.data.status,
            nameInitial: _ctx.nameInitial
          }, null, 8, ["status", "nameInitial"]),
          (!_ctx.isEditMode && _ctx.isCanEdit)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_icon_button, { onClick: _ctx.toggleEditMode }, {
                  default: _withCtx(() => [
                    _createVNode(_component_edit_icon)
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_icon_button, { onClick: _ctx.changeUserStatus }, {
                  default: _withCtx(() => [
                    (_ctx.isActive)
                      ? (_openBlock(), _createBlock(_component_lock_icon, {
                          key: 0,
                          type: "outline"
                        }))
                      : (_openBlock(), _createBlock(_component_unlock_icon, {
                          key: 1,
                          type: "outline"
                        }))
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.fullName), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        (_ctx.isEditMode)
          ? (_openBlock(), _createBlock(_component_edit_form, _mergeProps({
              key: 0,
              ref: "form",
              data: _ctx.data,
              schema: _ctx.schema,
              uischema: _ctx.uischema
            }, _ctx.$attrs), null, 16, ["data", "schema", "uischema"]))
          : (_openBlock(), _createBlock(_component_view_form, _mergeProps({
              key: 1,
              data: _ctx.data,
              schema: _ctx.schema,
              uischema: _ctx.uischema
            }, _ctx.$attrs), null, 16, ["data", "schema", "uischema"]))
      ])
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_8, [
      _createVNode(_component_default_button, {
        height: "40",
        onClick: _ctx.toggleEditMode
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('buttons.cancel')), 1)
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_default_button, {
        type: "primary",
        height: "40",
        disabled: _ctx.isLoading,
        onClick: _ctx.approvalHandler
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t('buttons.save')), 1)
        ]),
        _: 1
      }, 8, ["disabled", "onClick"])
    ], 512), [
      [_vShow, _ctx.isEditMode]
    ])
  ]))
}