import store from '@/store';
import UserService, { UserResponseType } from '@/api/admin/user';

export function useOneApi() {
  return {
    async loadData(id: string) {
      const api: UserService = store.getters.$api.admin.user;

      const response: UserResponseType = await api.one(id);

      return response.data ?? null;
    },
  };
}

export default {};
