import DictionaryBase from '@/api/admin/dictionary/base';
import {
  AxiosCallResponse,
  LoggerInterface,
  ResponseOfRecords,
} from '@/api/base';

export interface BankData {
  id?: string,
  code: string,
  name: string,
  bic: string,
  corrAccount: string,
  city: string,
  address: string,
  phone: string,
}

export type BankResponseType = AxiosCallResponse<ResponseOfRecords<BankData[]>>;

export default class BankService extends DictionaryBase {
  constructor(token: string, logger: LoggerInterface = console) {
    super('bank', token, logger);
  }
}
