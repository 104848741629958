import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_manager_notification = _resolveComponent("the-manager-notification")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_sidebar_default = _resolveComponent("sidebar-default")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex w-full", _ctx.styleClass])
  }, [
    _createVNode(_component_the_manager_notification),
    _createVNode(_component_router_view),
    _createVNode(_component_sidebar_default)
  ], 2))
}