
import _ from 'lodash';
import { JsonFormsChangeEvent } from '@jsonforms/vue';
import { ErrorObject } from 'ajv';
import { defineComponent, PropType } from 'vue';
import { JsonSchema, UISchemaElement } from '@jsonforms/core';
import { AgentSchema } from '@/api/agent';
import { validateData } from '@/utils/helpers/AjvValidator';
import FormGenerator from '@/components/jsonforms/generators/FormGenerator.vue';
import CustomUISchemaElement from '@/components/jsonforms/interfaces/CustomUISchemaElement';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import config from '@/config';
import useShowErrors from '@/composables/form/useShowErrors';

export interface DetailAgentForm {
  data: AgentSchema,
  currentData: AgentSchema,
  dataBySchema: JsonData,
  schema: JsonSchema,
  uischema: CustomUISchemaElement,
  errors: ErrorObject[],
  validateForm: (showErrors?: boolean) => boolean,
  showErrors: () => void,
  onChange: () => void,
  clearData: () => void,
}

export default defineComponent({
  name: 'TheForm',
  components: {
    FormGenerator,
  },
  props: {
    data: {
      type: Object as PropType<AgentSchema>,
      default: () => ({}),
    },
    schema: {
      required: true,
      type: Object as PropType<JsonSchema>,
      default: undefined,
    },
    uischema: {
      required: true,
      type: Object as PropType<UISchemaElement>,
      default: undefined,
    },
  },
  data() {
    return {
      errors: [] as ErrorObject[],
      currentData: this.data,
    };
  },
  watch: {
    data() {
      this.currentData = this.data;
    },
  },
  computed: {
    dataBySchema(): JsonData {
      return this.getDataBySchema(this.currentData, this.schema);
    },
    isBlockedStatus() {
      return this.data.status === config.STATUS_AGENT_IS_BLOCKED;
    },
  },
  methods: {
    clearData() {
      this.currentData = {};
      this.errors = [];
    },
    onChange(event: JsonFormsChangeEvent) {
      this.currentData = event.data;
    },
    validateForm(showErrors = false): boolean {
      this.errors = validateData(_.cloneDeep(this.dataBySchema), this.schema, this.uischema);

      if (showErrors) {
        this.showErrors(this.errors);
      }

      return !this.errors || !this.errors.length;
    },
    getDataBySchema(data: JsonData, schema: JsonSchema): JsonData {
      return _.omitBy(
        data,
        (value, key: string) => !Object.prototype.hasOwnProperty.call(schema.properties, key),
      );
    },
  },
  setup() {
    const { showErrors } = useShowErrors();

    return { showErrors };
  },
});
