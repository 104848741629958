import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-19eeda6a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "filter-block" }
const _hoisted_2 = { class: "filter__button-group" }
const _hoisted_3 = { class: "filter-block__fields-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_button_group = _resolveComponent("button-group")!
  const _component_datepicker_period = _resolveComponent("datepicker-period")!
  const _component_select_default = _resolveComponent("select-default")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_button_group, {
        items: _ctx.statusList,
        onGroupSelected: _ctx.changeStatusHandler
      }, null, 8, ["items", "onGroupSelected"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_datepicker_period, {
        class: "filter-block__fields-list-item",
        value: _ctx.filter.date,
        onChange: _ctx.changeDateHandler
      }, null, 8, ["value", "onChange"]),
      _createVNode(_component_select_default, {
        class: "filter-block__fields-list-item",
        data: _ctx.typeDataList,
        value: _ctx.filter.type,
        placeholder: _ctx.$t('counterparty.tabs.relationship.communication.fields.type'),
        onValueSelect: _ctx.changeTypeHandler
      }, null, 8, ["data", "value", "placeholder", "onValueSelect"]),
      _createVNode(_component_select_default, {
        class: "filter-block__fields-list-item",
        data: _ctx.agentData,
        value: _ctx.filter.user,
        "show-search": true,
        placeholder: _ctx.$t('counterparty.tabs.relationship.communication.fields.user'),
        onValueSelect: _ctx.changeUserHandler
      }, null, 8, ["data", "value", "placeholder", "onValueSelect"])
    ])
  ]))
}