<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd" clip-rule="evenodd"
      d="M1.15164 1.15159C1.37667 0.926629 1.68184 0.80025 2.00004 0.80025C2.31823
 0.80025 2.6234 0.926629 2.84844 1.15159L8.00004 6.30319L13.1516
 1.15159C13.2623 1.03698 13.3947 0.945563 13.5412 0.882672C13.6876
 0.819781 13.845 0.786678 14.0044 0.785293C14.1637 0.783909 14.3217
 0.81427 14.4692 0.874607C14.6167 0.934944 14.7506 1.02405 14.8633
 1.13672C14.976 1.24939 15.0651 1.38337 15.1254 1.53085C15.1858
 1.67832 15.2161 1.83634 15.2147 1.99568C15.2134 2.15501 15.1803
 2.31247 15.1174 2.45888C15.0545 2.60528 14.963 2.7377 14.8484
 2.84839L9.69684 7.99999L14.8484 13.1516C15.067 13.3779 15.188
 13.681 15.1852 13.9957C15.1825 14.3103 15.0563 14.6113 14.8338
 14.8338C14.6113 15.0563 14.3104 15.1825 13.9957 15.1852C13.6811
 15.1879 13.378 15.067 13.1516 14.8484L8.00004 9.69679L2.84844
 14.8484C2.62211 15.067 2.31899 15.1879 2.00436 15.1852C1.68972
 15.1825 1.38874 15.0563 1.16625 14.8338C0.943765 14.6113
 0.817562 14.3103 0.814828 13.9957C0.812093 13.681 0.933047
 13.3779 1.15164 13.1516L6.30324 7.99999L1.15164 2.84839C0.926672
 2.62336 0.800293 2.31819 0.800293 1.99999C0.800293 1.6818 0.926672
 1.37663 1.15164 1.15159Z"
      fill="#64748B"
    />
  </svg>
</template>

<script>
export default {
  name: 'CloseIcon',
  props: {
    width: {
      type: String,
      default: '16',
    },
    height: {
      type: String,
      default: '16',
    },
  },
};
</script>
