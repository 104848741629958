
import { defineComponent, PropType } from 'vue';
import LeftArrow from '@/components/ui/icons/LeftArrow.vue';
import RightArrow from '@/components/ui/icons/RightArrow.vue';
import { days, month } from '@/components/ui/datepickers/consts';
import DayItem from '@/components/ui/datepickers/datepicker-period/layouts/day/DayItem.vue';
import { TYPE_LAYOUT_YEAR, TYPE_LAYOUT_MONTH } from '@/components/ui/datepickers/datepicker-period/layouts/DatepickerLayout.vue';
import { DateTime } from 'luxon';
import { PeriodDateTimeType } from '@/components/ui/datepickers/datepicker-period/DatepickerPeriod.vue';

export default defineComponent({
  emit: ['change'],
  name: 'DayLayout',
  components: { DayItem, RightArrow, LeftArrow },
  props: {
    date: {
      type: Object as PropType<DateTime | null>,
      default: null,
    },
    rootDate: {
      type: Object as PropType<PeriodDateTimeType>,
      default: null,
    },
  },
  data() {
    return {
      nowDate: DateTime.now(),
      currentDate: null,
    };
  },
  computed: {
    daysOfTheWeek() {
      return days;
    },
    countDaysOfMonth() {
      return this.currentDate.daysInMonth;
    },
    offsetDay() {
      return this.currentDate.setLocale('ru_RU').startOf('month').weekday - 1;
    },
    month() {
      return month[this.currentDate.month - 1];
    },
    year() {
      return this.currentDate.year;
    },
  },
  methods: {
    nextMonthHandler() {
      this.currentDate = this.currentDate.plus({ month: 1 });
    },
    prevMonthHandler() {
      this.currentDate = this.currentDate.minus({ month: 1 });
    },
    isSelectDay(day: number) {
      const isSelectStartDay = this.rootDate?.startDate?.day === day
        && this.rootDate?.startDate?.month === this.currentDate.month
        && this.rootDate?.startDate?.year === this.currentDate.year;

      const isSelectEndDay = this.rootDate?.endDate?.day === day
        && this.rootDate?.endDate?.month === this.currentDate.month
        && this.rootDate?.endDate?.year === this.currentDate.year;

      return isSelectStartDay || isSelectEndDay;
    },
    isIntervalDay(day: number) {
      const date = DateTime.local(this.currentDate.year, this.currentDate.month, day);

      return this.rootDate.startDate?.startOf('day') <= date?.startOf('day')
        && date?.startOf('day') <= this.rootDate.endDate?.startOf('day');
    },
    isCurrentDay(day: number) {
      return this.currentDate.year === this.nowDate.year
        && this.currentDate.month === this.nowDate.month
        && this.nowDate.day === day;
    },
    isFirstIntervalDay(day: number) {
      const date = DateTime.local(this.currentDate.year, this.currentDate.month, day);

      return this.rootDate?.startDate?.startOf('day').toMillis() === date.startOf('day').toMillis();
    },
    isLastIntervalDay(day: number) {
      const date = DateTime.local(this.currentDate.year, this.currentDate.month, day);

      return this.rootDate?.endDate?.startOf('day').toMillis() === date.startOf('day').toMillis();
    },
    setDay(day: number) {
      const resultDate: PeriodDateTimeType = this.rootDate;

      this.currentDate = this.currentDate.set({ day });

      if (this.rootDate.startDate === null) {
        resultDate.startDate = this.currentDate;
      } else if (this.rootDate.endDate === null) {
        resultDate.endDate = this.currentDate;
      } else {
        resultDate.startDate = this.currentDate;
        resultDate.endDate = null;
      }

      this.$emit('change', {
        startDate: resultDate.startDate,
        endDate: resultDate.endDate,
      });
    },
    openYearLayout() {
      this.$emit('change-layout', TYPE_LAYOUT_YEAR);
    },
    openMonthLayout() {
      this.$emit('change-layout', TYPE_LAYOUT_MONTH);
    },
  },
  watch: {
    date(value) {
      this.currentDate = value ?? this.nowDate;
    },
  },
  created() {
    this.currentDate = this.date ?? this.nowDate;
  },
});
