<template>
  <svg :width="width" :height="height" viewBox="0 0 16 16" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path
      :fill="color"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.083 7H4.029C4.118 5.454 4.412 4.03 4.866 2.882C4.13501
 3.32992 3.50842 3.92919 3.02838 4.6395C2.54834 5.34982
 2.22598 6.1547 2.083 7ZM8 0C5.87827 0 3.84344 0.842855
 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0
 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344
 15.1571 5.87827 16 8 16C10.1217 16 12.1566 15.1571
 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16
 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566
 0.842855 10.1217 0 8 0ZM8 2C7.924 2 7.768 2.032
 7.535 2.262C7.297 2.496 7.038 2.885 6.798
 3.444C6.409 4.351 6.125 5.586 6.032 7H9.968C9.875
 5.586 9.591 4.351 9.202 3.444C8.962 2.884 8.702 2.496
 8.465 2.262C8.232 2.032 8.076 2 8 2ZM11.971 7C11.882
 5.454 11.588 4.03 11.134 2.882C11.865 3.32992 12.4916
 3.92919 12.9716 4.6395C13.4517 5.34982 13.774 6.1547
 13.917 7H11.971ZM9.968 9H6.032C6.125 10.414 6.409 11.649
 6.798 12.556C7.038 13.116 7.298 13.504 7.535 13.738C7.768
 13.968 7.924 14 8 14C8.076 14 8.232 13.968 8.465 13.738C8.703
 13.504 8.963 13.115 9.202 12.556C9.591 11.649 9.875 10.414 9.968
 9ZM11.134 13.118C11.588 11.971 11.882 10.546 11.971
 9H13.917C13.774 9.8453 13.4517 10.6502 12.9716 11.3605C12.4916
 12.0708 11.865 12.6701 11.134 13.118ZM4.866 13.118C4.412 11.97
 4.118 10.546 4.03 9H2.083C2.22598 9.8453 2.54834 10.6502 3.02838
 11.3605C3.50842 12.0708 4.13501 12.6701 4.866 13.118Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'InternationalIcon',
  props: {
    width: {
      type: String,
      default: '16',
    },
    height: {
      type: String,
      default: '16',
    },
    color: {
      type: String,
      default: '#64748B',
    },
  },
};
</script>
