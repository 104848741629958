
import { defineComponent, PropType } from 'vue';
import { JsonSchema, UISchemaElement } from '@jsonforms/core';
import DefaultButton from '@/components/ui/buttons/DefaultButton/DefaultButton.vue';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import IconButton from '@/components/ui/buttons/IconButton/IconButton.vue';
import EditIcon from '@/components/ui/icons/EditIcon.vue';
import TheForm, {
  PaymentForm,
} from '@/components/counterparty/card/blocks/general/payment/form/TheForm.vue';
import paymentAccount from '@/shared/consts/blocks/paymentAccount';
import LockIcon from '@/components/ui/icons/LockIcon.vue';
import UnlockIcon from '@/components/ui/icons/UnlockIcon.vue';

export default defineComponent({
  name: 'TheDetail',
  inject: ['$sizeWindow'],
  emits: [
    'cancel',
    'updated',
    'block',
    'unblock',
    'change-mode',
    'changed-form-data',
  ],
  components: {
    UnlockIcon,
    LockIcon,
    EditIcon,
    IconButton,
    DefaultButton,
    TheForm,
  },
  props: {
    data: Object as PropType<JsonData>,
    schema: Object as PropType<JsonSchema>,
    uischema: Object as PropType<UISchemaElement>,
    status: Number,
    isCanEdit: Boolean,
  },
  data() {
    return {
      isLoading: false,
      isView: true,
      localData: this.data,
    };
  },
  computed: {
    isActive() {
      return this.status === paymentAccount.STATUS_IS_ACTIVE;
    },
  },
  watch: {
    isView(value: boolean) {
      this.$emit('change-mode', !value);
    },
  },
  methods: {
    cancelHandler() {
      this.isView = true;
      this.localData = this.data;
    },
    changedFormDataHandler(data: JsonData) {
      this.localData = data;

      this.$emit('changed-form-data', data);
    },
    changeEditMode() {
      this.isView = false;
    },
    updateHandler() {
      this.isLoading = true;

      const formRef: PaymentForm = this.$refs.form as PaymentForm;
      const valid: boolean = formRef?.validateForm(true) ?? false;
      const data: JsonData | null = formRef?.currentData ?? null;

      if (!valid || !data) {
        this.isLoading = false;

        return false;
      }

      this.$emit('updated', data, () => {
        this.isLoading = false;
        this.cancelHandler();
      });

      return true;
    },
    blockHandler() {
      this.$emit('block');
    },
    unblockHandler() {
      this.$emit('unblock');
    },
    changeStatusHandler() {
      if (this.isActive) {
        this.blockHandler();
      } else {
        this.unblockHandler();
      }
    },
  },
});
