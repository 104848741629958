import { defineComponent } from 'vue';
import InfoIcon from '@/components/ui/icons/InfoIcon.vue';
import CalculatePosition from '@/utils/directives/CalculatePosition';

export default defineComponent({
  name: 'TheTooltip',
  inject: ['$sizeWindow'],
  components: {
    InfoIcon,
  },
  data: () => ({
    isShow: false,
  }),
  props: {
    content: String,
    calculatePosition: {
      type: [Boolean, Object],
      default: () => ({
        extraHeight: 90,
      }),
    },
  },
  computed: {
    showTooltip() {
      return !!this.$slots.default && this.isShow;
    },
  },
  methods: {
    open() {
      if (!this.$sizeWindow.isSm) {
        this.isShow = true;
      }
    },
    close() {
      this.isShow = false;
    },
  },
  directives: {
    CalculatePosition,
  },
});
