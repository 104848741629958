
import { defineComponent } from 'vue';
import _ from 'lodash';

export default defineComponent({
  name: 'DefaultInput',
  emits: ['onChange'],
  props: {
    placeholder: String,
    label: String,
    value: [String, Number],
    error: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    withDebounce: {
      type: Boolean,
      default: false,
    },
    debounceTime: {
      type: Number,
      default: 500,
    },
  },
  data() {
    return {
      dataValue: this.value ?? '',
      changeFunction: null,
    };
  },
  watch: {
    value(value: string | number) {
      this.dataValue = value;
    },
  },
  methods: {
    onChange() {
      this.$emit('onChange', this.dataValue);
    },
  },
  created() {
    this.changeFunction = this.withDebounce
      ? _.debounce(this.onChange, this.debounceTime)
      : this.onChange;
  },
});
