import { JsonSchema, UISchemaElement } from '@jsonforms/core';
import { Ref } from 'vue';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import { validateData } from '@/utils/helpers/AjvValidator';

interface ControlData {
  path: string,
  schema: object,
  required: boolean,
  uischema: UISchemaElement,
}

export const computeLabel = (
  label: string | undefined,
  required: boolean,
  hideRequiredAsterisk: boolean,
): string => {
  const localLabel = label ?? '';
  const asterisk = required && !hideRequiredAsterisk
    ? '<span class="asterisk">*</span>'
    : '';

  return localLabel + asterisk;
};

export const controlWrapper = (control: { controlWrapper: object, appliedOptions: object }) => ({
  ...control.controlWrapper,
  ...control.appliedOptions,
});

export const validationMethods = (controlRef: Ref) => ({
  getControl(): ControlData {
    return controlRef.value;
  },
  isRequired(): boolean {
    const control = validationMethods(controlRef).getControl();

    return control.required || control.uischema?.options?.required;
  },
  prepareData(value: string | number | null): JsonData {
    const control = validationMethods(controlRef).getControl();

    return {
      [control.path]: value,
    } as JsonData;
  },
  prepareSchema(): object {
    const control = validationMethods(controlRef).getControl();

    return {
      type: 'object',
      properties: {
        [control.path]: control.schema,
      },
      required: validationMethods(controlRef).isRequired()
        ? [control.path]
        : [],
    };
  },
  getError(data: JsonData, schema: JsonSchema): string {
    const errors = validateData(data, schema);

    return errors.length ? String(errors[0].message) : '';
  },
});

type defaultValueType = string | number | boolean | Date | [] | object | null;

const stringHandler = (schema: JsonSchema) => {
  if (
    schema.format === 'date-time'
    || schema.format === 'date'
    || schema.format === 'time'
  ) {
    return new Date();
  }

  return '';
};

const numberHandler = () => 0;

const booleanHandler = () => false;

const arrayHandler = () => [];

const nullHandler = () => null;

const objectHandler = (schema: JsonSchema) => {
  const object: { [key: string]: defaultValueType } = {};

  Object.keys(schema.properties ?? {}).forEach((key: string) => {
    // eslint-disable-next-line no-use-before-define
    object[key] = createDefaultValue(schema?.properties?.[key] ?? {});
  });

  return object;
};

export const createDefaultValue = (schema: JsonSchema): defaultValueType => {
  const types: { [key: string]: (jsonSchema: JsonSchema) => defaultValueType } = {
    string: stringHandler,
    integer: numberHandler,
    number: numberHandler,
    boolean: booleanHandler,
    array: arrayHandler,
    null: nullHandler,
    object: objectHandler,
  };

  const hasNullType = Array.isArray(schema.type)
    ? schema.type.includes('null')
    : schema.type === 'null';

  if (hasNullType) {
    return types.null(schema);
  }

  const type = Array.isArray(schema.type)
    ? schema.type[0]
    : schema.type;

  return types[type ?? ''] ? types[type ?? ''](schema) : {};
};
