import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-02a8b79c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "filter-block" }
const _hoisted_2 = { class: "filter-block__fields-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_datepicker_period = _resolveComponent("datepicker-period")!
  const _component_select_default = _resolveComponent("select-default")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_datepicker_period, {
        class: "filter-block__fields-list-item",
        value: _ctx.filter.date,
        onChange: _ctx.changeDateHandler
      }, null, 8, ["value", "onChange"]),
      _createVNode(_component_select_default, {
        class: "filter-block__fields-list-item",
        value: _ctx.filter.type,
        data: _ctx.supportViewList,
        "show-search": true,
        placeholder: _ctx.$t('counterparty.tabs.relationship.salesSupportHistory.type'),
        "ajax-handler": _ctx.ajaxSupportView,
        onValueSelect: _ctx.changeTypeHandler
      }, null, 8, ["value", "data", "placeholder", "ajax-handler", "onValueSelect"]),
      _createVNode(_component_select_default, {
        class: "filter-block__fields-list-item",
        data: _ctx.userDataList,
        "show-search": true,
        value: _ctx.filter.user,
        placeholder: _ctx.$t('counterparty.tabs.relationship.salesSupportHistory.user'),
        onValueSelect: _ctx.changeUserHandler
      }, null, 8, ["data", "value", "placeholder", "onValueSelect"])
    ])
  ]))
}