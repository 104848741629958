
import _ from 'lodash';
import {
  Actions, CoreActions, Dispatch,
  composePaths,
  JsonFormsRendererRegistryEntry,
  rankWith,
  ControlElement,
  schemaTypeIs,
  JsonSchema,
  uiTypeIs,
} from '@jsonforms/core';
import {
  useJsonFormsArrayControl,
  DispatchRenderer, rendererProps,
  RendererProps,
} from '@jsonforms/vue';
import { defineComponent, ref, inject } from 'vue';
import { useVanillaArrayControl } from '@jsonforms/vue-vanilla';
import config from '@/config';
import CustomUISchemaElement from '@/components/jsonforms/interfaces/CustomUISchemaElement';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import AddIcon from '@/components/ui/icons/AddIcon.vue';
import TablePaginationBack from '@/components/ui/icons/pagination/TablePaginationBack.vue';
import TablePaginationNext from '@/components/ui/icons/pagination/TablePaginationNext.vue';
import RemoveColumnIcon from '@/components/ui/icons/RemoveColumnIcon.vue';
import TheDangerModal from '@/components/ui/modal/TheDangerModal/TheDangerModal.vue';
import IconButton from '@/components/ui/buttons/IconButton/IconButton.vue';
import { getChangedData } from '@/components/jsonforms/util/util';
import { createDefaultValue } from '@/components/jsonforms/util/renderer';
import DefaultButton from '@/components/ui/buttons/DefaultButton/DefaultButton.vue';

const controlRenderer = defineComponent({
  inject: ['$sizeWindow'],
  name: 'financial-table-renderer',
  components: {
    DefaultButton,
    IconButton,
    AddIcon,
    RemoveColumnIcon,
    TheDangerModal,
    DispatchRenderer,
    TablePaginationBack,
    TablePaginationNext,
  },
  props: {
    ...rendererProps<ControlElement>(),
  },
  data() {
    return {
      dataShift: 0,
      initialData: [],
      yearConst: config.COUNTERPARTY_FINANCIAL_YEAR_FIELD,
    };
  },
  watch: {
    'control.data': function watchControlData(data: JsonData, oldData: JsonData) {
      if (this.hasChangedData(_.cloneDeep(data), _.cloneDeep(oldData))) {
        this.sortData();
      }
    },
    'control.config.onlyText': function watchOnlyText() {
      this.prepareInitialData();
    },
  },
  computed: {
    maxColumnsInTable() {
      return this.$sizeWindow.isSm ? 1 : 3;
    },
    showIconButtons(): boolean {
      return this.dataLength > this.maxColumnsInTable;
    },
    dataLength(): number {
      return this.control?.data?.length ?? 0;
    },
    disableLeftButton(): boolean {
      return this.dataShift === 0;
    },
    disableRightButton(): boolean {
      return this.dataShift === this.rightShearBoundary;
    },
    selectedYears(): string[] {
      return this.control.data ? this.control.data
        .map((item: { [key: string]: string }) => item[this.yearConst])
        .filter((value?: string) => !!value) : [];
    },
    currentShearOnLeft(): number {
      if (this.noData) {
        return 0;
      }

      return this.dataLength < this.maxColumnsInTable ? 1 : this.dataShift + 1;
    },
    currentShearOnRight(): number {
      if (this.dataLength < this.maxColumnsInTable) {
        return this.dataLength;
      }

      return (this.currentShearOnLeft + this.maxColumnsInTable) - 1;
    },
    rightShearBoundary(): number {
      return this.dataLength < this.maxColumnsInTable
        ? this.maxColumnsInTable
        : this.dataLength - this.maxColumnsInTable;
    },
    UISchema(): CustomUISchemaElement {
      if (_.has(this.uischema, 'options.detail')) {
        return this.uischema.options?.detail;
      }

      return this.childUiSchema;
    },
    countLabels(): number {
      const labels = this.getLabelsFromUISchema(this.UISchema);

      return labels?.length ?? 0;
    },
    noData(): boolean {
      return !this.control.data || this.dataLength === 0;
    },
    removeModalText(): string {
      const year: string = this.suggestYearToDelete ? this.suggestYearToDelete : 'этот';

      return `Вы уверены, что хотите удалить данные за ${year} год?`;
    },
  },
  methods: {
    composePaths,
    createDefaultValue,
    sortData() {
      if (this.dispatch) {
        this.dispatch(Actions.update(this.control.path, (array) => _.orderBy(
          _.cloneDeep(array),
          this.appliedOptions.sort.attribute,
          this.appliedOptions.sort.order ?? 'asc',
        )));
      }
    },
    hasChangedData(data: JsonData, oldData: JsonData) {
      return !!Object.keys(getChangedData(data, oldData)).length;
    },
    addButtonClick() {
      this.addItem(
        this.control.path,
        createDefaultValue(this.control.schema),
      )();

      this.dataShift = this.rightShearBoundary;
    },
    removeItemsClick(toDelete: number[]): void {
      this.removeItems?.(this.control.path, toDelete)();

      this.dataShift = this.dataShift > 0
        ? this.dataShift - 1
        : 0;

      this.closeRemoveModal();
    },
    getUISchemaWithoutLabels(UISchema: CustomUISchemaElement): CustomUISchemaElement {
      const self: CustomUISchemaElement = _.cloneDeep(UISchema);

      if (self.label) {
        self.label = '';
      }

      if (self.elements) {
        self.elements = self.elements.map((
          element: CustomUISchemaElement,
        ) => this.getUISchemaWithoutLabels(element));
      }

      return self;
    },
    getUISchemaWithDisableYear(UISchema: CustomUISchemaElement): CustomUISchemaElement {
      const self: CustomUISchemaElement = _.cloneDeep(UISchema);

      if (self.elements) {
        self.elements = self.elements.map((element: CustomUISchemaElement) => {
          if (element.scope?.includes(this.yearConst)) {
            return {
              ...element,
              options: { ...element.options, readonly: true },
            };
          }

          return element;
        });
      }

      return self;
    },
    getFilteredUISchema(element: JsonData, UISchema: CustomUISchemaElement): CustomUISchemaElement {
      const uiSchemaWithoutLabels = this.getUISchemaWithoutLabels(UISchema);
      const needDisableYear = !!this.initialData.find((
        initialItem: JsonData,
      ) => initialItem[this.yearConst] === element[this.yearConst]);

      if (!needDisableYear) {
        return uiSchemaWithoutLabels;
      }

      return this.getUISchemaWithDisableYear(uiSchemaWithoutLabels);
    },
    getLabelsFromUISchema(UISchema: CustomUISchemaElement): string[] {
      const elements: CustomUISchemaElement[] = UISchema.elements ?? [];

      return elements.map((element: CustomUISchemaElement) => element.label || '');
    },
    shiftData(newShift: number) {
      this.dataShift = newShift;
    },
    showColumn(index: number): boolean {
      const minColumns: boolean = this.dataLength <= this.maxColumnsInTable;
      const isInRange: boolean = index >= this.dataShift
        && index < (this.dataShift + this.maxColumnsInTable);

      return minColumns || isInRange;
    },
    getYearByIndex(index: number): string {
      return this.control.data[index][this.yearConst] ?? '';
    },
    openRemoveModal(index: number, year = '') {
      this.suggestToDelete = index;
      this.suggestYearToDelete = year;
    },
    closeRemoveModal() {
      this.suggestToDelete = null;
      this.suggestYearToDelete = null;
    },
    filterSchema(year = '') {
      const self: JsonSchema = _.cloneDeep(this.control.schema);

      if (self.properties && self.properties[this.yearConst]) {
        const selectedYears: string[] = _.without(this.selectedYears, year);
        const enums: string[] = self.properties[this.yearConst].enum ?? [];

        self.properties[this.yearConst].enum = enums.filter((
          value: string,
        ) => !selectedYears.includes(value));
      }

      return self;
    },
    prepareInitialData() {
      this.initialData = this.control.data ?? [];

      this.dataShift = this.control.data
        ? this.rightShearBoundary
        : 0;

      this.sortData();
    },
    approvalHandler(next: () => void): void {
      if (this.suggestToDelete || this.suggestToDelete === 0) {
        this.removeItemsClick([this.suggestToDelete]);
      }

      next();
    },
  },
  mounted() {
    this.prepareInitialData();
  },
  setup(props: RendererProps<ControlElement>) {
    const control = useVanillaArrayControl(useJsonFormsArrayControl(props));
    const suggestToDelete = ref<null | number>(null);
    const suggestYearToDelete = ref<null | string>(null);
    const dispatch = inject<Dispatch<CoreActions>>('dispatch');

    return {
      ...control, suggestToDelete, suggestYearToDelete, dispatch,
    };
  },
});

export default controlRenderer;

export const financialTableTester = schemaTypeIs('array')
  && uiTypeIs('FinancialTable');

export const entry: JsonFormsRendererRegistryEntry = {
  renderer: controlRenderer,
  tester: rankWith(2, financialTableTester),
};
