import {
  AxiosCallResponse,
  BaseService,
  LoggerInterface,
  QueryParamsForList,
  ResponseOfRecords,
} from '@/api/base';
import JsonData from '@/components/jsonforms/interfaces/JsonData';

export type SalesSupportHistoryItem = {
  id: string,
} & JsonData;

export type SalesSupportHistoryResponseType = AxiosCallResponse<
    ResponseOfRecords<SalesSupportHistoryItem[]
    >>

export default class SalesSupportHistoryService extends BaseService {
  constructor(token: string, logger: LoggerInterface = console) {
    super('card', token, logger, 'v2');
  }

  async list(
    cardId: string,
    params: QueryParamsForList,
  ): Promise<SalesSupportHistoryResponseType> {
    return this.axiosCall({ url: `/${cardId}/sales-support-history`, method: 'get', params });
  }

  async create(
    cardId: string,
    data: SalesSupportHistoryItem,
  ): Promise<AxiosCallResponse<SalesSupportHistoryItem>> {
    return this.axiosCall({ url: `/${cardId}/sales-support-history`, method: 'post', data });
  }

  async update(
    cardId: string,
    id: string,
    data: SalesSupportHistoryItem,
  ): Promise<AxiosCallResponse<null>> {
    return this.axiosCall({ url: `/${cardId}/sales-support-history/${id}`, method: 'patch', data });
  }

  async delete(
    cardId: string,
    id: string,
  ): Promise<AxiosCallResponse<null>> {
    return this.axiosCall({ url: `/${cardId}/sales-support-history/${id}`, method: 'delete' });
  }
}
