import { defineComponent } from 'vue';
import TablePaginationBack
  from '@/components/ui/icons/pagination/TablePaginationBack.vue';
import TablePaginationOverBack
  from '@/components/ui/icons/pagination/TablePaginationOverBack.vue';
import TablePaginationNext
  from '@/components/ui/icons/pagination/TablePaginationNext.vue';
import TablePaginationOverNext
  from '@/components/ui/icons/pagination/TablePaginationOverNext.vue';
import SelectDefault
  from '@/components/ui/selects/SelectDefault/SelectDefault.vue';

export default defineComponent({
  name: 'TablePagination',
  inject: ['$sizeWindow'],
  components: {
    TablePaginationBack,
    TablePaginationOverBack,
    TablePaginationNext,
    TablePaginationOverNext,
    SelectDefault,
  },
  props: {
    numberOfLines: {
      type: Array,
      default: () => ([10, 25, 50]),
    },
    pagination: {
      type: Object,
      default: () => ({
        currentPage: 0,
        perPage: 0,
        totalCount: 0,
        pageCount: 0,
      }),
    },
    variant: {
      type: String,
      default: 'expand',
    },
  },
  emits: ['paginationChanged'],
  data() {
    return {
      perPage: 10,
      currentPage: 1,
    };
  },
  computed: {
    selectData() {
      return this.numberOfLines.map((number: number) => ({
        id: number,
        name: number,
      }));
    },
    startNumber() {
      if (this.pagination.totalCount === 0) {
        return 0;
      }

      if (this.currentPage === 1) {
        return 1;
      }

      return (this.perPage * this.currentPage) - this.perPage;
    },
    endNumber() {
      const number: number = this.perPage * this.currentPage;

      return number > this.pagination.totalCount ? this.pagination.totalCount : number;
    },
    newPagination() {
      return {
        currentPage: this.currentPage,
        perPage: this.perPage,
        totalCount: this.pagination.totalCount,
        pageCount: this.pagination.pageCount,
      };
    },
    disablePrevPage() {
      return this.pagination.currentPage < 2;
    },
    disableNextPage() {
      return this.pagination.pageCount === 0
          || this.pagination.currentPage === this.pagination.pageCount;
    },
    isSimple() {
      return this.variant === 'simple';
    },
    isExpand() {
      return this.variant === 'expand';
    },
    styleClass() {
      return {
        expand: this.isExpand,
        mobile: this.$sizeWindow.isLg,
      };
    },
  },
  watch: {
    pagination() {
      this.currentPage = this.pagination.currentPage;
      this.perPage = this.pagination.perPage;
      this.scrollToAnchor();
    },
  },
  methods: {
    scrollToAnchor() {
      if (this.isSimple) {
        return false;
      }

      const element = document.querySelector('.anchor');

      element?.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });

      return true;
    },
    changePerPage(value: string) {
      this.currentPage = 1;
      this.perPage = Number(value
          || this.pagination.perPage);

      this.$emit('paginationChanged', this.newPagination);
    },
    nextPage() {
      this.currentPage += 1;
      this.$emit('paginationChanged', this.newPagination);
    },
    prevPage() {
      this.currentPage -= 1;
      this.$emit('paginationChanged', this.newPagination);
    },
    firstPage() {
      this.currentPage = 1;
      this.$emit('paginationChanged', this.newPagination);
    },
    lastPage() {
      this.currentPage = this.pagination.pageCount;
      this.$emit('paginationChanged', this.newPagination);
    },
  },
});
