import { Module } from 'vuex';
import PsychologicalCharacteristicService, {
  PsychologicalCharacteristicSchema,
  PsychologicalCharacteristicListResponseType,
} from '@/api/admin/dictionary/psychologicalCharacteristic';
import {
  MetaParams, SortParams,
} from '@/api/base';
import { StateType } from '@/store';
import { FilterDictionaryParams, QueryDictionaryParamsForList } from '@/api/admin/dictionary/base';

export interface PsychologicalCharacteristicListStateType {
  items: PsychologicalCharacteristicSchema[],
  filter: FilterDictionaryParams,
  sort: SortParams,
  pagination: MetaParams,
}

const getInitialState = (): PsychologicalCharacteristicListStateType => ({
  items: [],
  pagination: {
    currentPage: null,
    pageCount: null,
    perPage: null,
    totalCount: null,
  },
  filter: {
    query: null,
    status: null,
  },
  sort: {
    sortBy: null,
  },
});

const initialState = getInitialState();

const characteristicListModule: Module<PsychologicalCharacteristicListStateType, StateType> = {
  namespaced: true,
  state: initialState,
  getters: {
    items: (state): PsychologicalCharacteristicSchema[] => state.items,
    filter: (state): FilterDictionaryParams => state.filter,
    sort: (state): SortParams => state.sort,
    pagination: (state): MetaParams => state.pagination,
    loadParams: (state): QueryDictionaryParamsForList => ({
      page: state.pagination.currentPage,
      perPage: state.pagination.perPage,
      query: state.filter.query,
      sortBy: state.sort.sortBy,
      status: state.filter.status,
    }),
  },
  mutations: {
    setItems: (state, items) => {
      state.items = items;
    },
    setFilter: (state, filter) => {
      state.filter = filter;
    },
    setSort: (state, sort) => {
      state.sort = sort;
    },
    setPagination: (state, pagination) => {
      state.pagination = pagination;
    },
    resetState: (state) => {
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    resetState({ commit }) {
      commit('resetState');
    },
    async changePagination({ commit }, pagination: MetaParams) {
      commit('setPagination', pagination);
    },
    async changeFilter({ commit, state }, filter: FilterDictionaryParams) {
      commit('setFilter', filter);
      commit('setPagination', { ...state.pagination, currentPage: 1 });
    },
    async changeSort({ commit }, sort: SortParams) {
      commit('setSort', sort);
    },
    async loadItems({ getters, rootGetters, commit }) {
      const api: PsychologicalCharacteristicService = rootGetters
        .$api
        .admin
        .dictionary
        .psychologicalCharacteristic;

      const { loadParams } = getters;

      const response: PsychologicalCharacteristicListResponseType = await api.list(loadParams);

      commit('setItems', response.data?.items ?? initialState.items);
      commit('setPagination', response.data?._meta ?? initialState.pagination);
    },
  },
};

export default characteristicListModule;
