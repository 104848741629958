import { Module } from 'vuex';
import { StateType } from '@/store';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import AgentService, { AgentListResponseType } from '@/api/agent';

export interface AgentListStateType {
  items: JsonData[],
}

const initialState = {
  items: [],
};

const agentListModule: Module<AgentListStateType, StateType> = {
  namespaced: true,
  state: initialState,
  getters: {
    items: (state): JsonData[] => state.items,
  },
  mutations: {
    setItems(state, items) {
      state.items = items;
    },
  },
  actions: {
    async loadItems({ rootGetters, commit }, cardId: string) {
      if (cardId) {
        const api: AgentService = rootGetters.$api.agent;

        const response: AgentListResponseType = await api.list(cardId);

        commit('setItems', response.data?.items ?? initialState.items);
      }
    },
  },
};

export default agentListModule;
