
import { defineComponent, PropType } from 'vue';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import BlockArrow from '@/components/ui/icons/BlockArrow.vue';
import config from '@/config';

export default defineComponent({
  name: 'TheCounterpartyData',
  components: { BlockArrow },
  props: {
    data: {
      type: Object as PropType<JsonData>,
      required: true,
    },
  },
  data() {
    return {
      isOpen: true,
      schema: {
        type: 'object',
        properties: {
          counterparty: {
            type: ['string', 'null'],
          },
          countryCode: {
            type: ['string', 'null'],
          },
          taxNumber: {
            type: ['string', 'null'],
          },
          counterpartyType: {
            type: ['string', 'null'],
          },
          inn: {
            type: ['string', 'null'],
          },
          kpp: {
            type: ['string', 'null'],
          },
          registrationNumber: {
            type: ['string', 'null'],
          },
          actualAddress: {
            type: ['string', 'null'],
          },
          legalAddress: {
            type: ['string', 'null'],
          },
          inHolding: {
            type: 'boolean',
          },
          isHeadCompany: {
            type: 'boolean',
          },
          industry: {
            type: ['string', 'null'],
          },
          marketStatus: {
            type: ['string', 'null'],
          },
          ownDevelopedClient: {
            type: 'boolean',
          },
          singlePurchasingCenter: {
            type: 'boolean',
          },
        },
      },
      uischema: {
        type: 'VerticalLayout',
        elements: [
          {
            type: 'Control',
            label: this.$t('counterpartyLabels.name'),
            scope: '#/properties/counterparty',
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: this.$t('counterpartyLabels.registrationNumber'),
                scope: '#/properties/registrationNumber',
              },
              {
                type: 'Control',
                label: this.$t('counterpartyLabels.view'),
                scope: '#/properties/counterpartyType',
              },
            ],
          },
          {
            type: 'Control',
            label: this.$t('counterpartyLabels.taxNumber'),
            scope: '#/properties/taxNumber',
            rule: {
              effect: 'HIDE',
              condition: {
                scope: '#/properties/countryCode',
                schema: {
                  const: Number(config.SIGN_OF_RESIDENT),
                },
              },
            },
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: this.$t('counterpartyLabels.inn'),
                scope: '#/properties/inn',
              },
              {
                type: 'Control',
                label: this.$t('counterpartyLabels.kpp'),
                scope: '#/properties/kpp',
              },
            ],
            rule: {
              effect: 'SHOW',
              condition: {
                scope: '#/properties/countryCode',
                schema: {
                  const: Number(config.SIGN_OF_RESIDENT),
                },
              },
            },
          },
          {
            type: 'Control',
            label: this.$t('counterpartyLabels.legalAddress'),
            scope: '#/properties/legalAddress',
            options: {
              readonly: true,
            },
          },
          {
            type: 'Control',
            label: this.$t('counterpartyLabels.actualAddress'),
            scope: '#/properties/actualAddress',
            options: {
              readonly: true,
            },
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: this.$t('counterpartyLabels.inHolding'),
                scope: '#/properties/inHolding',
                options: {
                  readonly: true,
                },
              },
              {
                type: 'Control',
                label: this.$t('counterpartyLabels.isHeadCompany'),
                scope: '#/properties/isHeadCompany',
                options: {
                  readonly: true,
                },
              },
            ],
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: this.$t('counterpartyLabels.industry'),
                scope: '#/properties/industry',
                options: {
                  readonly: true,
                },
              },
              {
                type: 'Control',
                label: this.$t('counterpartyLabels.marketStatus'),
                scope: '#/properties/marketStatus',
                options: {
                  readonly: true,
                },
              },
            ],
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: this.$t('counterpartyLabels.ownDevelopedClient'),
                scope: '#/properties/ownDevelopedClient',
                options: {
                  readonly: true,
                },
              },
              {
                type: 'Control',
                label: this.$t('counterpartyLabels.singlePurchasingCenter'),
                scope: '#/properties/singlePurchasingCenter',
                options: {
                  readonly: true,
                },
              },
            ],
          },
        ],
      },
    };
  },
  methods: {
    toggleBlock() {
      this.isOpen = !this.isOpen;
    },
  },
});
