import { Module } from 'vuex';
import list, { PositionListStateType } from '@/store/modules/admin/dictionary/position/list';
import one, { PositionOneStateType } from '@/store/modules/admin/dictionary/position/one';
import { StateType } from '@/store';

export interface PositionModuleStateType {
  list: PositionListStateType,
  one: PositionOneStateType,
}

const positionModule: Module<PositionModuleStateType, StateType> = {
  namespaced: true,
  modules: {
    list,
    one,
  },
};

export default positionModule;
