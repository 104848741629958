
import { defineComponent } from 'vue';
import InfoIcon from '@/components/ui/icons/notifications/InfoIcon.vue';
import SuccessIcon from '@/components/ui/icons/notifications/SuccessIcon.vue';
import WarningIcon from '@/components/ui/icons/notifications/WarningIcon.vue';
import ErrorIcon from '@/components/ui/icons/notifications/ErrorIcon.vue';
import CloseIcon from '@/components/ui/icons/notifications/CloseIcon.vue';

export const TYPE_LABEL_INFO = 'info';
export const TYPE_LABEL_SUCCESS = 'success';
export const TYPE_LABEL_WARNING = 'warning';
export const TYPE_LABEL_ERROR = 'error';

export default defineComponent({
  name: 'TheAlert',
  components: {
    CloseIcon,
    InfoIcon,
    SuccessIcon,
    WarningIcon,
    ErrorIcon,
  },
  props: {
    type: String,
    id: String,
  },
  data: () => ({
    timeOut: 0,
  }),
  created() {
    this.timeOut = setTimeout(() => {
      this.$emit('closeNotification', this.id);
    }, 5000);
  },
  computed: {
    isSuccess() {
      return this.type === TYPE_LABEL_SUCCESS;
    },
    isError() {
      return this.type === TYPE_LABEL_ERROR;
    },
    isWarning() {
      return this.type === TYPE_LABEL_WARNING;
    },
    isInfo() {
      return this.type === TYPE_LABEL_INFO;
    },
  },
  emits: ['closeNotification'],
  methods: {
    closeNotification() {
      this.$emit('closeNotification', this.id);
      clearTimeout(this.timeOut);
    },
  },
});
