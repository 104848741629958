import DictionaryBase, { DictionaryListResponseType } from '@/api/admin/dictionary/base';
import { LoggerInterface } from '@/api/base';

export interface IndividualSchema {
  id: string,
  name: string,
  inn?: string,
  ifts?: string,
  birthday?: string,
}

export interface IndividualData {
  name: string,
  inn?: string,
  ifts?: string,
  birthday?: string,
}

export type IndividualListResponseType
  = DictionaryListResponseType<IndividualSchema>;

export default class IndividualService extends DictionaryBase {
  constructor(token: string, logger: LoggerInterface = console) {
    super('individual', token, logger);
  }
}
