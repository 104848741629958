import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_day_layout = _resolveComponent("day-layout")!
  const _component_month_layout = _resolveComponent("month-layout")!
  const _component_year_layout = _resolveComponent("year-layout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _withDirectives(_createVNode(_component_day_layout, {
      date: _ctx.currentDate,
      "root-date": _ctx.date,
      onChange: _ctx.changeDateHandler,
      onChangeLayout: _ctx.changeLayoutHandler
    }, null, 8, ["date", "root-date", "onChange", "onChangeLayout"]), [
      [_vShow, _ctx.isDayLayout]
    ]),
    _withDirectives(_createVNode(_component_month_layout, {
      date: _ctx.currentDate,
      onChange: _ctx.changeMonthHandler,
      onChangeLayout: _ctx.changeLayoutHandler
    }, null, 8, ["date", "onChange", "onChangeLayout"]), [
      [_vShow, _ctx.isMonthLayout]
    ]),
    _withDirectives(_createVNode(_component_year_layout, {
      date: _ctx.currentDate,
      onChange: _ctx.changeYearHandler,
      onChangeLayout: _ctx.changeLayoutHandler
    }, null, 8, ["date", "onChange", "onChangeLayout"]), [
      [_vShow, _ctx.isYearLayout]
    ])
  ], 64))
}