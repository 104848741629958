import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_error = _resolveComponent("the-error")!

  return (_openBlock(), _createBlock(_component_the_error, {
    class: "not-found",
    url: "/images/errors/404.svg",
    status: 404,
    text: _ctx.$t('errors.notFound.text'),
    subtext: _ctx.$t('errors.notFound.subtext')
  }, null, 8, ["text", "subtext"]))
}