import BoardingTour from '@/utils/tours/boarding/boarding';
import { BoardingSteps } from 'boarding.js';

export interface TourManagerType<T> {
  start: (index: number) => void,
  registerSteps: (steps: T) => void,
  reset: () => void,
  delay: (timeout: number) => void,
}

export default function useTour() {
  const tourManager: TourManagerType<BoardingSteps> = new BoardingTour();

  const start = (index = 0) => {
    tourManager.start(index);
  };

  const reset = () => {
    tourManager.reset();
  };

  const delay = (timeout: number) => {
    tourManager.delay(timeout);
  };

  const registerSteps = (steps: BoardingSteps) => {
    tourManager.registerSteps(steps);
  };

  const getFormattedPath = (path: string) => {
    const pattern = '[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}';
    const regex = new RegExp(pattern);
    let formatPath = path === '/' ? '' : path;
    let match: RegExpMatchArray | null = [];

    do {
      match = regex.exec(formatPath);
      if (match) {
        formatPath = formatPath.replace(`/${match[0]}`, '');
      }
    } while (match !== null);

    return formatPath;
  };

  const getScenario = async (path: string) => {
    const formatPath = getFormattedPath(path);

    try {
      const { getSteps } = await import(`@/utils/tours/scenarios${formatPath}/scenario.ts`);

      return getSteps(tourManager);
    } catch (e) {
      const { getSteps } = await import('@/utils/tours/scenarios/default');

      return getSteps();
    }
  };

  const getLearnedParams = (path: string) => {
    const learnedPath = getFormattedPath(path);
    const learnedPagesStorage = localStorage.getItem('learnedPages') ?? '{}';
    const learnedPages = JSON.parse(learnedPagesStorage);

    return {
      learnedPages,
      learnedPath,
    };
  };

  const setLearnedPage = (path: string) => {
    const { learnedPages, learnedPath } = getLearnedParams(path);

    learnedPages[learnedPath] = true;

    localStorage.setItem('learnedPages', JSON.stringify(learnedPages));
  };

  const pageIsLearned = (path: string) => {
    const { learnedPages, learnedPath } = getLearnedParams(path);

    return learnedPages?.[learnedPath];
  };

  return {
    delay,
    start,
    registerSteps,
    getScenario,
    setLearnedPage,
    pageIsLearned,
    reset,
  };
}
