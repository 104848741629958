import _ from 'lodash';
import { defineComponent } from 'vue';
import CloseIcon from '@/components/ui/icons/CloseIcon.vue';
import SearchIcon from '@/components/ui/icons/SearchIcon.vue';
import IconButton from '@/components/ui/buttons/IconButton/IconButton.vue';

export default defineComponent({
  name: 'SearchInput',
  emits: ['filterChanged', 'input', 'filterCleared'],
  components: {
    IconButton,
    CloseIcon,
    SearchIcon,
  },
  props: {
    filter: {
      type: Object,
      default: () => ({
        query: '',
      }),
    },
    value: {
      type: [String, Number],
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    clearButton: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    debounce: {
      type: Number,
      default: 500,
    },
    type: {
      type: String,
      default: 'text',
    },
  },
  data() {
    return {
      query: this.value,
      debounceFunction: null,
    };
  },
  computed: {
    newFilter() {
      return this.query
        ? { query: this.query }
        : {};
    },
  },
  watch: {
    value() {
      this.query = this.value;
    },
    query() {
      this.$emit('input', this.query);
    },
  },
  methods: {
    clearSearch() {
      this.query = '';

      this.$emit('filterChanged', this.newFilter);
      this.$emit('filterCleared');
    },
  },
  created() {
    this.debounceFunction = _.debounce(async function handler(e: { target: HTMLInputElement }) {
      this.query = String(e.target.value).trim();

      this.$emit('filterChanged', this.newFilter);
    }, this.debounce);
  },
});
