
import { defineComponent, PropType } from 'vue';
import { ErrorObject } from 'ajv';
import { JsonFormsChangeEvent } from '@jsonforms/vue';
import { mapActions, mapGetters } from 'vuex';
import DefaultButton from '@/components/ui/buttons/DefaultButton/DefaultButton.vue';
import { OneOfType } from '@/api/schema';
import TheCounterpartyData from '@/components/admin/dictionary/assign/forms/counterpartyData/TheCounterpartyData.vue';
import { PinningItem } from '@/api/admin/pinning';
import useShowErrors from '@/composables/form/useShowErrors';
import { validateData } from '@/utils/helpers/AjvValidator';

export default defineComponent({
  name: 'TheReassign',
  emits: ['reassign', 'cancel'],
  components: { TheCounterpartyData, DefaultButton },
  props: {
    departments: {
      type: Array as PropType<OneOfType[]>,
      default: () => [],
    },
    users: {
      type: Array as PropType<OneOfType[]>,
      default: () => [],
    },
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isLoading: false,
      errors: [] as ErrorObject[],
      currentData: {},
    };
  },
  computed: {
    ...mapGetters({
      data: 'admin/dictionary/pinning/one/detail',
    }),
    schema() {
      return {
        type: 'object',
        properties: {
          department: {
            type: ['string', 'null'],
            oneOf: this.departments,
          },
          user: {
            type: ['string', 'null'],
            oneOf: this.users,
          },
          comment: {
            type: 'string',
          },
          reason: {
            type: ['string', 'null'],
            enum: [
              'Вступление контрагента в холдинг',
              'Закуп ведут через головную компанию',
              'На основании решения заседания комитета рабочего совещания',
              'Распоряжение директора дивизиона коммерческого директора',
              'Смена менеджера (по решению сотрудников ОП)',
              'Смена хозяйствующего субъекта',
              'Увольнение ответственного менеджера',
            ],
          },
        },
        required: [
          'department',
          'user',
          'reason',
        ],
      };
    },
    uischema() {
      return {
        type: 'VerticalLayout',
        elements: [
          {
            type: 'Control',
            label: this.$t('dictionary.pinning.fields.department'),
            scope: '#/properties/department',
            options: {
              search: true,
            },
          },
          {
            type: 'Control',
            label: this.$t('dictionary.pinning.fields.user'),
            scope: '#/properties/user',
            options: {
              search: true,
              readonly: !this.currentData || !this.currentData.department,
              ajaxHandler: async (query: string) => {
                await this.setFilterUser({
                  query,
                  division: this.data.divisionId,
                  department: this.currentData.department,
                });
              },
            },
          },
          {
            type: 'Control',
            label: this.$t('dictionary.pinning.fields.reasonReassignment'),
            scope: '#/properties/reason',
          },
          {
            type: 'Control',
            label: this.$t('dictionary.pinning.fields.comment'),
            scope: '#/properties/comment',
            options: {
              multi: true,
            },
          },
        ],
      };
    },
  },
  methods: {
    ...mapActions({
      setFilterStructure: 'admin/dictionary/structure/list/changeFilter',
      loadStructure: 'admin/dictionary/structure/list/loadItems',
      loadUsers: 'admin/user/list/loadItems',
      setFilterUser: 'admin/user/list/changeFilter',
      loadDetailData: 'admin/dictionary/pinning/one/get',
    }),
    cancelHandler() {
      this.$emit('cancel');
    },
    async onChange(event: JsonFormsChangeEvent) {
      if (event.data.department && event.data.department !== this.currentData.department) {
        await this.setFilterUser({
          division: this.data.divisionId,
          department: event.data.department,
        });

        if (this.currentData.department) {
          this.currentData.user = undefined;
        }
      }

      if (!event.data.department && event.data.department !== this.currentData.department) {
        this.currentData.user = undefined;
      }

      this.currentData = event.data;
    },
    validateForm(showErrors = false): boolean {
      this.errors = validateData(this.currentData, this.schema, this.uischema);

      if (showErrors) {
        this.showErrors(this.errors);
      }

      return !this.errors || !this.errors.length;
    },
    reassignHandler() {
      this.isLoading = true;

      const valid: boolean = this.validateForm(true) ?? false;
      const data: PinningItem | null = this.currentData ?? null;

      if (!valid || !data) {
        this.isLoading = false;

        return false;
      }

      this.$emit('reassign', data, () => {
        this.isLoading = false;
      });

      return true;
    },
    async loadData() {
      this.isLoading = true;
      await this.loadDetailData(this.id);

      await this.setFilterStructure({
        parentId: this.data.divisionId,
      });

      await this.loadStructure();
      this.isLoading = false;
    },
  },
  async mounted() {
    // TODO: Исправить в будущем
    this.timeoutFunction = setTimeout(this.loadData, 300);
  },
  unmounted() {
    clearTimeout(this.timeoutFunction);
  },
  setup() {
    const { showErrors } = useShowErrors();

    return { showErrors };
  },
});
