import _ from 'lodash';
import { Module } from 'vuex';
import { AxiosCallResponse } from '@/api/base';
import { StateType } from '@/store';
import { TYPE_LABEL_SUCCESS } from '@/components/ui/notifications/TheAlert/TheAlert.vue';
import i18n from '@/i18n';
import CountryService, { CountryFormData, CountryItem } from '@/api/admin/dictionary/country';

export interface CountryOneStateType {
  data: CountryItem | null,
}

const initialState = {
  data: null,
};

const countryOneModule: Module<CountryOneStateType, StateType> = {
  namespaced: true,
  state: _.cloneDeep(initialState),
  getters: {
    data: (state) => state.data,
  },
  mutations: {
    setData(state, items: CountryItem) {
      state.data = items;
    },
  },
  actions: {
    async create({ rootGetters }, data: CountryFormData) {
      const api: CountryService = rootGetters.$api.admin.dictionary.country;

      const response: AxiosCallResponse<null> = await api.create(data);

      if (!response.error) {
        await rootGetters
          .$notificationLogger
          .setNotification(
            TYPE_LABEL_SUCCESS,
            i18n.global.t('notification.admin.dictionary.success.create'),
          );
      }
    },
    async update({ rootGetters }, params: { id: string, data: CountryFormData }) {
      const { id, data } = params;
      const api: CountryService = rootGetters.$api.admin.dictionary.country;

      const response: AxiosCallResponse<null> = await api.update(id, data);

      if (!response.error) {
        await rootGetters
          .$notificationLogger
          .setNotification(
            TYPE_LABEL_SUCCESS,
            i18n.global.t('notification.admin.dictionary.success.update'),
          );
      }
    },
    async delete({ rootGetters }, id: string) {
      if (id) {
        const api: CountryService = rootGetters.$api.admin.dictionary.country;

        const response: AxiosCallResponse<null> = await api.delete(id);

        if (!response.error) {
          await rootGetters
            .$notificationLogger
            .setNotification(
              TYPE_LABEL_SUCCESS,
              i18n.global.t('notification.admin.dictionary.success.delete'),
            );
        }
      }
    },

    async restore({ rootGetters }, id: string) {
      if (id) {
        const api: CountryService = rootGetters.$api.admin.dictionary.country;

        const response: AxiosCallResponse<null> = await api.restore(id);

        if (!response.error) {
          await rootGetters
            .$notificationLogger
            .setNotification(
              TYPE_LABEL_SUCCESS,
              i18n.global.t('notification.admin.dictionary.success.restore'),
            );
        }
      }
    },
  },
};

export default countryOneModule;
