
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BlockArrow',
  props: {
    isOpen: {
      type: Boolean,
    },
    width: {
      type: String,
      default: '24',
    },
    height: {
      type: String,
      default: '12',
    },
    color: {
      type: String,
      default: '#64748B',
    },
  },
});
