import { Component, defineComponent, PropType } from 'vue';
import TheHeader from '@/components/ui/tables/simple/header/TheHeader.vue';
import TheBody from '@/components/ui/tables/simple/body/TheBody.vue';
import TheHierarchyBody
  from '@/components/ui/tables/hierarchy/body/TheBody.vue';
import ThePagination
  from '@/components/ui/tables/simple/pagination/ThePagination.vue';

export interface SortParams {
  sortBy?: string | null,
}

export interface PaginationParams {
  currentPage?: number | null,
  pageCount?: number | null,
  perPage?: number | null,
  totalCount?: number | null,
}

export interface TableItemSettings {
  type?: 'link' | 'component',
  link?: string,
  text?: string,
  component?: Component,
  props?: object,
  events?: object,
  isVisible: boolean,
}

export interface TableItem {
  [key: string]: TableItemSettings | string | number | boolean | null,
}

export interface TableFieldObject {
  key: string,
  label: string,
  notSortable?: boolean,
  width?: number,
}

export default defineComponent({
  name: 'TheTable',
  components: {
    TheHeader,
    TheBody,
    ThePagination,
    TheHierarchyBody,
  },
  props: {
    variant: {
      type: String,
      default: 'expand',
    },
    items: {
      type: Array as PropType<TableItem[]>,
      default: () => ([]),
    },
    fields: {
      type: Array as PropType<TableFieldObject[]>,
      default: () => ([]),
    },
    pagination: {
      type: [Object, Boolean],
      default: () => ({
        currentPage: 0,
        perPage: 0,
        totalCount: 0,
        pageCount: 0,
      }),
    },
    sort: {
      type: Object,
      default: () => ({}),
    },
    isAllOpen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isSimple() {
      return this.variant === 'simple';
    },
    isExpand() {
      return this.variant === 'expand';
    },
  },
  emits: ['bodyItemClick', 'changeSortBy', 'changePagination'],
  methods: {
    bodyItemClickHandler(id: string) {
      this.$emit('bodyItemClick', id);
    },
    changeSortByHandler(sort: SortParams) {
      this.$emit('changeSortBy', sort);
    },
    changePaginationHandler(pagination: PaginationParams) {
      this.$emit('changePagination', pagination);
    },
  },
});
