import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-566d8355"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sidebar-content" }
const _hoisted_2 = { class: "sidebar-block" }
const _hoisted_3 = { class: "sidebar-block__title" }
const _hoisted_4 = { class: "sidebar-block history" }
const _hoisted_5 = { class: "history-cards" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { key: 0 }
const _hoisted_8 = {
  key: 0,
  class: "history-detail"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_status_bar = _resolveComponent("status-bar")!
  const _component_default_loader = _resolveComponent("default-loader")!
  const _component_item_content = _resolveComponent("item-content")!
  const _component_the_danger_modal = _resolveComponent("the-danger-modal")!
  const _component_the_side_modal = _resolveComponent("the-side-modal")!

  return (_openBlock(), _createBlock(_component_the_side_modal, {
    size: "xl",
    "is-show-modal": _ctx.isOpen,
    onCloseHandler: _ctx.closeHistory
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('history.label')) + " " + _toDisplayString(_ctx.blockName), 1)
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: _normalizeClass(["history-card", { active: _ctx.currentHistoryItemId === item.id }]),
                  key: item.id,
                  onClick: ($event: any) => (_ctx.clickCardHandler(item.id))
                }, [
                  (!_ctx.$sizeWindow.isSm)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(item.createdAt), 1))
                    : _createCommentVNode("", true),
                  _createVNode(_component_status_bar, {
                    status: item.status,
                    type: _ctx.$sizeWindow.isSm ? 'icon' : 'text'
                  }, null, 8, ["status", "type"])
                ], 10, _hoisted_6))
              }), 128)),
              (_ctx.isLoading)
                ? (_openBlock(), _createBlock(_component_default_loader, {
                    key: 0,
                    class: "!-translate-y-[25px]"
                  }))
                : _createCommentVNode("", true)
            ]),
            (!!_ctx.activeItem)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createVNode(_component_item_content, {
                    data: _ctx.activeItem,
                    schema: _ctx.schema,
                    onClickHandler: _ctx.removeHandler
                  }, null, 8, ["data", "schema", "onClickHandler"])
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _createVNode(_component_the_danger_modal, {
        "approve-button-text": _ctx.$t('history.dangerModal.confirmButton'),
        text: _ctx.$t('history.dangerModal.text'),
        "is-open": _ctx.openDangerModal,
        onApprovalHandler: _ctx.approvalHandler,
        onCloseModal: _ctx.closeModal
      }, null, 8, ["approve-button-text", "text", "is-open", "onApprovalHandler", "onCloseModal"])
    ]),
    _: 1
  }, 8, ["is-show-modal", "onCloseHandler"]))
}