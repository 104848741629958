import { defineComponent } from 'vue';
import CounterpartiesCard from '@/components/counterparty/list/card/TheCard.vue';
import AddPersonIcon from '@/components/ui/icons/AddPersonIcon.vue';
import BlockArrow from '@/components/ui/icons/BlockArrow.vue';
import EditIcon from '@/components/ui/icons/EditIcon.vue';
import ContextMenu from '@/components/ui/menu/ContextMenu/ContextMenu.vue';
import { TableFieldObject, TableItem } from '@/components/ui/tables/simple/TheTable';
import DateHelper from '@/utils/helpers/DateHelper';
import TheTooltip from '@/components/ui/modal/TheTooltip/TheTooltip';

export default defineComponent({
  name: 'TableItem',
  components: {
    TheTooltip,
    ContextMenu,
    CounterpartiesCard,
    AddPersonIcon,
    EditIcon,
    BlockArrow,
  },
  data() {
    return {
      isOpen: true,
    };
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    fields: {
      type: Object,
      default: () => ({}),
    },
    variant: {
      type: String,
      default: 'expand',
    },
  },
  computed: {
    countCommonColumns(): number {
      return Object.keys(this.fields)
        .filter((key: string) => key !== 'id')
        .length;
    },
    filteredFields() {
      return this.fields.filter((item: { isVisible: boolean }) => item.isVisible !== false);
    },
    isSimple() {
      return this.variant === 'simple';
    },
    isExpand() {
      return this.variant === 'expand';
    },
  },
  methods: {
    checkObjectType(item: TableItem, field: TableFieldObject, type: string) {
      const key: string = field.key ?? '';
      const options: string | { type?: string } = key && !!item[key]
        ? item[key] as string | { type?: string }
        : '';

      if (typeof options === 'string') {
        return false;
      }

      return typeof options === 'object' && options?.type === type;
    },
    isLink(item: TableItem, field: TableFieldObject): boolean {
      return this.checkObjectType(item, field, 'link');
    },
    isComponent(item: TableItem, field: TableFieldObject): boolean {
      return this.checkObjectType(item, field, 'component');
    },
    isDate(format: string) {
      return format === 'date';
    },
    textValue(field: { key: string }) {
      return this.item[field.key]?.name ?? this.item[field.key] ?? '';
    },
    formattedDate(date: string) {
      return DateHelper.formattedDate(date);
    },
  },
});
