
import { defineComponent, PropType } from 'vue';
import { JsonSchema, UISchemaElement } from '@jsonforms/core';
import config from '@/config';
import { AgentSchema } from '@/api/agent';
import FormGenerator from '@/components/jsonforms/generators/FormGenerator.vue';
import BriefcaseIcon from '@/components/ui/icons/BriefcaseIcon.vue';
import EmailIcon from '@/components/ui/icons/EmailIcon.vue';
import PhoneIcon from '@/components/ui/icons/PhoneIcon.vue';
import CustomUISchemaElement from '@/components/jsonforms/interfaces/CustomUISchemaElement';
import useAjaxConfiguration from '@/composables/form/useAjaxConfiguration';

export default defineComponent({
  name: 'TheForm',
  emits: ['changeUserStatus'],
  components: {
    BriefcaseIcon,
    EmailIcon,
    PhoneIcon,
    FormGenerator,
  },
  props: {
    data: {
      type: Object as PropType<AgentSchema>,
      default: () => ({}),
    },
    schema: {
      required: true,
      type: Object as PropType<JsonSchema>,
      default: undefined,
    },
    uischema: {
      required: true,
      type: Object as PropType<UISchemaElement>,
      default: undefined,
    },
  },
  data() {
    return {
      position: null,
    };
  },
  computed: {
    fullName(): string {
      return this.data[config.COUNTERPARTY_AGENT_FULL_NAME_FIELD] ?? '';
    },
    phone(): string {
      const phoneItem = this.data[config.COUNTERPARTY_AGENT_PHONE_ITEM] ?? null;
      const firstPhone = phoneItem ? phoneItem[0] : null;

      return firstPhone ? firstPhone[config.COUNTERPARTY_AGENT_PHONE_FIELD] : '';
    },
    email(): string {
      const emailItem = this.data[config.COUNTERPARTY_AGENT_EMAIL_ITEM] ?? null;
      const firstEmail = emailItem ? emailItem[0] : null;

      return firstEmail ? firstEmail[config.COUNTERPARTY_AGENT_EMAIL_FIELD] : '';
    },
  },
  methods: {
    async setPositionName() {
      const positionUischema = this.uischema.elements.find(
        (item: CustomUISchemaElement) => item.scope?.split('/').pop() === config.COUNTERPARTY_AGENT_POSITION_FIELD,
      );
      const ajaxConfigurator = useAjaxConfiguration(positionUischema.options.ajaxConfiguration);

      if (this.data[config.COUNTERPARTY_AGENT_POSITION_FIELD]) {
        this.position = await ajaxConfigurator.getNameById(
          this.data[config.COUNTERPARTY_AGENT_POSITION_FIELD],
        );
      }
    },
  },
  watch: {
    data() {
      this.setPositionName();
    },
  },
  async created() {
    await this.setPositionName();
  },
});
