import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isSolid)
    ? (_openBlock(), _createElementBlock("svg", {
        key: 0,
        width: _ctx.width,
        height: _ctx.height,
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }, [
        _createElementVNode("rect", {
          width: "24",
          height: "24",
          rx: "12",
          fill: _ctx.color
        }, null, 8, _hoisted_2),
        _createElementVNode("path", {
          d: "M12.6804 7.27273L12.5398 13.4347H11.1676L11.0312 7.27273H12.6804ZM11.8537\n 16.0938C11.5952 16.0938 11.3736 16.0028 11.1889 15.821C11.0071 15.6392 10.9162\n 15.4176 10.9162 15.1562C10.9162 14.9006 11.0071 14.6818 11.1889 14.5C11.3736\n 14.3182 11.5952 14.2273 11.8537 14.2273C12.1065 14.2273 12.3253 14.3182 12.5099\n 14.5C12.6974 14.6818 12.7912 14.9006 12.7912 15.1562C12.7912 15.3295 12.7472\n 15.4872 12.6591 15.6293C12.5739 15.7713 12.4602 15.8849 12.3182 15.9702C12.179\n 16.0526 12.0241 16.0938 11.8537 16.0938Z",
          fill: _ctx.childColor
        }, null, 8, _hoisted_3)
      ], 8, _hoisted_1))
    : _createCommentVNode("", true)
}