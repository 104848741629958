
import {
  ControlElement,
  JsonFormsRendererRegistryEntry,
  rankWith,
} from '@jsonforms/core';
import { RendererProps, rendererProps, useJsonFormsOneOfEnumControl } from '@jsonforms/vue';
import { useVanillaControl } from '@jsonforms/vue-vanilla';
import { defineComponent, ref, Ref } from 'vue';
import { controlWrapper, validationMethods } from '@/components/jsonforms/util/renderer';
import SelectTree from '@/components/ui/selects/SelectTree/SelectTree.vue';
import { isMultiTree } from '@/components/jsonforms/util/testers';
import { deserializeTree } from '@/components/jsonforms/helpers/TreeHelper';
import ControlWrapper from './ControlWrapper.vue';

export interface OneOfTree<T = string | number> {
  const: T,
  title: string,
  properties: {
    parent: {
      const: T | null,
    },
  },
}

const controlRenderer = defineComponent({
  name: 'multi-tree-control-renderer',
  components: {
    ControlWrapper,
    SelectTree,
  },
  props: {
    ...rendererProps<ControlElement>(),
  },
  setup(props: RendererProps<ControlElement>) {
    const vanillaControl = useVanillaControl(useJsonFormsOneOfEnumControl(props));
    const control = vanillaControl.control as unknown as Ref;
    const errors = ref(vanillaControl.controlWrapper?.value?.errors ?? '');

    return {
      errors,
      ...vanillaControl,
      ...validationMethods(control),
    };
  },
  computed: {
    data() {
      return this.prepareOneOf(this.control.schema.items.oneOf);
    },
    preparedControlWrapper() {
      return controlWrapper(this);
    },
  },
  watch: {
    controlWrapper(value, oldValue) {
      if (value.errors !== oldValue.errors) {
        this.errors = value.errors;
      }
    },
  },
  methods: {
    changeHandler(value: string) {
      const localValue: string | undefined = !value && this.isRequired()
        ? undefined
        : value;

      this.handleChange(this.control.path, localValue);
    },
    blurHandler(value: string) {
      const localValue: string | undefined = !value && this.isRequired()
        ? undefined
        : value;

      this.isFocused = false;

      this.errors = this.getError(this.prepareData(localValue), this.prepareSchema());
    },
    prepareOneOf(items: OneOfTree[]) {
      return [
        ...items
          .filter((item: OneOfTree) => !item.properties.parent.const)
          .map((item: OneOfTree) => ({
            id: item.const,
            name: item.title,
            items: deserializeTree(items, item.const), // ToDo: найти children
          })),
      ];
    },
  },
});

export default controlRenderer;

export const entry: JsonFormsRendererRegistryEntry = {
  renderer: controlRenderer,
  tester: rankWith(5, isMultiTree),
};
