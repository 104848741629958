import DictionaryBase, { DictionaryListResponseType } from '@/api/admin/dictionary/base';
import { LoggerInterface } from '@/api/base';

export interface TechnologicalSupportTypeSchema {
  id: string,
  name: string,
}

export interface TechnologicalSupportTypeData {
  name: string,
}

export type TechnologicalSupportTypeListResponseType
  = DictionaryListResponseType<TechnologicalSupportTypeSchema>;

export default class TechnologicalSupportTypeService extends DictionaryBase {
  constructor(token: string, logger: LoggerInterface = console) {
    super('technological-support-type', token, logger);
  }
}
