import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, resolveDynamicComponent as _resolveDynamicComponent, toHandlers as _toHandlers, mergeProps as _mergeProps, KeepAlive as _KeepAlive, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a09a4d1e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table-item" }
const _hoisted_2 = {
  key: 0,
  class: "!p-[0] !w-[24px]"
}
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { key: 3 }
const _hoisted_5 = {
  key: 1,
  class: "!p-[0] !w-[24px] md:!w-[24px]"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("tr", _hoisted_1, [
    (_ctx.isExpand)
      ? (_openBlock(), _createElementBlock("td", _hoisted_2))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredFields, (field, index) => {
      return (_openBlock(), _createElementBlock("td", {
        key: `body-td-${index}`,
        style: _normalizeStyle(field.width ? `width: ${field.width}px;` : '')
      }, [
        (_ctx.isLink(_ctx.item, field))
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: _ctx.item[field.key]?.link,
              class: "primary-color"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.item[field.key].text), 1)
              ]),
              _: 2
            }, 1032, ["to"]))
          : (_ctx.isComponent(_ctx.item, field))
            ? (_openBlock(), _createBlock(_KeepAlive, { key: 1 }, [
                (_ctx.item[field.key].isVisible !== false)
                  ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.item[field.key].component), _mergeProps({ key: 0 }, _ctx.item[field.key].props, _toHandlers(_ctx.item[field.key].events ?? {})), null, 16))
                  : _createCommentVNode("", true)
              ], 1024))
            : (_ctx.isDate(field.format))
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.formattedDate(_ctx.item[field.key])), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.textValue(field)), 1))
      ], 4))
    }), 128)),
    (_ctx.isExpand)
      ? (_openBlock(), _createElementBlock("td", _hoisted_5))
      : _createCommentVNode("", true)
  ]))
}