import JsonData from '@/components/jsonforms/interfaces/JsonData';
import {
  AxiosCallResponse,
  BaseService,
  LoggerInterface,
  QueryParamsForList,
  ResponseOfRecords,
} from '@/api/base';

export type AgentSchema = {
  id: string,
  status: number,
} & JsonData;

export type AgentListResponseType = AxiosCallResponse<ResponseOfRecords<AgentSchema[]>>;

export type AgentResponseType = AxiosCallResponse<AgentSchema>;

export default class AgentService extends BaseService {
  constructor(token: string, logger: LoggerInterface = console) {
    super('card', token, logger, 'v2');
  }

  async one(id: string, cardId: string): Promise<AgentResponseType> {
    return this.axiosCall({ url: `/${cardId}/agent/${id}`, method: 'get' });
  }

  async list(
    cardId: string,
    params: QueryParamsForList = {},
  ): Promise<AgentListResponseType> {
    return this.axiosCall({ url: `/${cardId}/agent`, method: 'get', params });
  }

  async create(cardId: string, data: JsonData): Promise<AgentResponseType> {
    return this.axiosCall({ url: `/${cardId}/agent`, method: 'post', data });
  }

  async update(
    id: string,
    cardId: string,
    data: JsonData,
  ): Promise<AxiosCallResponse<null>> {
    return this.axiosCall({ url: `/${cardId}/agent/${id}`, method: 'patch', data });
  }

  async block(id: string, cardId: string): Promise<AxiosCallResponse<null>> {
    return this.axiosCall({ url: `/${cardId}/agent/${id}/block`, method: 'post' });
  }

  async unblock(id: string, cardId: string): Promise<AxiosCallResponse<null>> {
    return this.axiosCall({ url: `/${cardId}/agent/${id}/unblock`, method: 'post' });
  }
}
