import { Module } from 'vuex';
import { StateType } from '@/store';
import list, {
  TechnologicalSupportTypeListStateType,
} from '@/store/modules/admin/dictionary/technologicalSupportType/list';
import one, {
  TechnologicalSupportTypeOneStateType,
} from '@/store/modules/admin/dictionary/technologicalSupportType/one';

export interface TechnologicalSupportTypeModuleStateType {
  list: TechnologicalSupportTypeListStateType,
  one: TechnologicalSupportTypeOneStateType,
}

const technologicalSupportTypeModule: Module<TechnologicalSupportTypeModuleStateType, StateType> = {
  namespaced: true,
  modules: {
    list,
    one,
  },
};

export default technologicalSupportTypeModule;
