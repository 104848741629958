import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-77005d24"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "filter-block sm:mt" }
const _hoisted_2 = { class: "input-label block" }
const _hoisted_3 = { class: "flex space-x-[16px]" }
const _hoisted_4 = { class: "w-full relative" }
const _hoisted_5 = ["value", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search_icon = _resolveComponent("search-icon")!
  const _component_select_default = _resolveComponent("select-default")!
  const _component_close_icon = _resolveComponent("close-icon")!
  const _component_icon_button = _resolveComponent("icon-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("input", {
            type: "text",
            class: "input",
            value: _ctx.query,
            placeholder: _ctx.$t('common.search'),
            onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.searchInputHandler && _ctx.searchInputHandler(...args)))
          }, null, 40, _hoisted_5),
          _createVNode(_component_search_icon, { class: "absolute top-[8px] right-[8px]" })
        ]),
        _withDirectives(_createVNode(_component_select_default, {
          class: "filter-block__company-select",
          required: "",
          value: _ctx.localCompanyId,
          data: _ctx.companies,
          "show-search": true,
          placeholder: _ctx.$t('admin.user.detail.form.company'),
          "ajax-handler": _ctx.ajaxCompanyHandler,
          onValueSelect: _ctx.companyChange
        }, null, 8, ["value", "data", "placeholder", "ajax-handler", "onValueSelect"]), [
          [_vShow, _ctx.isShowCompanyFilter]
        ]),
        _createVNode(_component_icon_button, {
          class: "circle-button",
          border: "default",
          description: _ctx.$t('counterparty.list.filter.clear'),
          onClick: _ctx.clearSearch
        }, {
          default: _withCtx(() => [
            _createVNode(_component_close_icon)
          ]),
          _: 1
        }, 8, ["description", "onClick"])
      ])
    ])
  ]))
}