import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_tree = _resolveComponent("select-tree")!
  const _component_control_wrapper = _resolveComponent("control-wrapper")!

  return (_openBlock(), _createBlock(_component_control_wrapper, _mergeProps(_ctx.preparedControlWrapper, {
    styles: _ctx.styles,
    isFocused: _ctx.isFocused,
    appliedOptions: _ctx.appliedOptions,
    errors: _ctx.errors
  }), {
    default: _withCtx(() => [
      (!!_ctx.control.config.onlyText)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass(_ctx.styles.control.text)
          }, _toDisplayString(_ctx.control.data ? _ctx.control.data : _ctx.$t('common.noData')), 3))
        : (_openBlock(), _createBlock(_component_select_tree, {
            key: 1,
            value: _ctx.control.data,
            disabled: !_ctx.control.enabled,
            data: _ctx.data,
            error: !!_ctx.errors,
            placeholder: _ctx.appliedOptions.placeholder,
            required: _ctx.control.required,
            multiple: _ctx.appliedOptions.multiple,
            "show-search": _ctx.appliedOptions.search,
            onFocus: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isFocused = true)),
            onBlur: _ctx.blurHandler,
            onValueSelect: _ctx.changeHandler
          }, null, 8, ["value", "disabled", "data", "error", "placeholder", "required", "multiple", "show-search", "onBlur", "onValueSelect"]))
    ]),
    _: 1
  }, 16, ["styles", "isFocused", "appliedOptions", "errors"]))
}