
import { defineComponent, PropType } from 'vue';
import config from '@/config';
import TheSwitch from '@/components/ui/switch/TheSwitch.vue';
import RoleItemHelper from '@/utils/helpers/RoleItemHelper';
import SelectTree, { DataTypeTree } from '@/components/ui/selects/SelectTree/SelectTree.vue';
import { RoleAttribute, RoleItem } from '@/api/admin/role';
import { findElementById } from '@/components/jsonforms/helpers/TreeHelper';

export interface UpdatedRoleParams {
  roleId: string,
  value: boolean,
}

export default defineComponent({
  name: 'RoleItem',
  emits: ['updated', 'updateRoleAttributes'],
  components: {
    TheSwitch,
    SelectTree,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    level: {
      type: Number,
      default: 0,
    },
    role: {
      type: Object as PropType<RoleItem>,
      default: () => ({}),
    },
    userRoles: {
      type: Array as PropType<string[]>,
      default: () => ([]),
    },
    rolesAttributes: {
      type: Object as PropType<RoleAttribute>,
      default: () => ({}),
    },
    departments: {
      type: Array as PropType<DataTypeTree[]>,
      default: () => ([]),
    },
  },
  data() {
    return {
      localIsActive: this.userRoles.includes(this.role.id),
    };
  },
  watch: {
    localIsActive() {
      this.$emit('updated', {
        roles: [
          { roleId: this.role.id, value: this.localIsActive },
        ],
      });
    },
    userRoles() {
      this.localIsActive = this.children.length
        ? this.children.every((child: RoleItem) => this.userRoles.includes(child.id))
        : this.userRoles.includes(this.role.id);
    },
  },
  computed: {
    children() {
      return this.role.children ?? [];
    },
    isShowInput() {
      return this.localIsActive && this.role.type === config.ROLE_TYPE_EXTRA_DEPARTMENT;
    },
    currentRoleAttributes() {
      return this.rolesAttributes[this.role.id];
    },
    isDisableRole() {
      if (this.role.type === config.ROLE_TYPE_EXTRA_DEPARTMENT) {
        return this.disabled || this.departments.length <= 0;
      }
      return this.disabled;
    },
  },
  methods: {
    updateHandler(value: boolean) {
      const allChildrenIds = RoleItemHelper.getRoleChildrenIds(this.role);

      this.$emit('updated', {
        roles: [
          { roleId: this.role.id, value },
          ...allChildrenIds.map((roleId: string) => ({ roleId, value })),
        ],
      });
    },
    updatedChildHandler(params: UpdatedRoleParams[]) {
      this.$emit('updated', params);
    },
    updateRoleAttributesHandler(value: string[]) {
      this.$emit('updateRoleAttributes', value, this.role.id);
    },
    updateRoleAttributesChildHandler(data: string[], roleId: string) {
      this.$emit('updateRoleAttributes', data, roleId);
    },
    getNameByValue(value: string | number) {
      const item = findElementById(this.departments, value);

      return item?.name ?? '';
    },
    isShowComma(index: number) {
      return this.rolesAttributes[this.role.id].length > 1
        && this.rolesAttributes[this.role.id].length !== index + 1;
    },
  },
});
