import { Module } from 'vuex';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import { StateType } from '@/store';
import ProductionIndicatorsService, {
  ProductionIndicatorsListResponseType,
} from '@/api/block/characteristic/productionIndicators';
import { MetaParams, PaginationParams } from '@/api/base';

export interface ProductionIndicatorsListStateType {
  items: JsonData[],
  pagination: MetaParams,
}

const initialState = {
  items: [],
  pagination: {
    currentPage: null,
    pageCount: null,
    perPage: 5,
    totalCount: null,
  },
};

const productionIndicatorsListModule: Module<ProductionIndicatorsListStateType, StateType> = {
  namespaced: true,
  state: initialState,
  getters: {
    items: (state): JsonData[] => state.items,
    pagination: (state): MetaParams => state.pagination,
    loadParams: (state): PaginationParams => ({
      page: state.pagination.currentPage,
      perPage: state.pagination.perPage,
    }),
  },
  mutations: {
    setPagination(state, pagination: MetaParams) {
      state.pagination = pagination;
    },
    setItems(state, items) {
      state.items = items;
    },
  },
  actions: {
    changePagination({ commit }, pagination: MetaParams) {
      commit('setPagination', pagination);
    },
    async loadItems({ rootGetters, commit, getters }, cardId: string) {
      if (cardId) {
        const { loadParams } = getters;

        const api: ProductionIndicatorsService = rootGetters.$api.blocks.productionIndicators;

        const response: ProductionIndicatorsListResponseType = await api.list(
          cardId,
          loadParams,
        );

        commit('setItems', response.data?.items ?? initialState.items);
        commit('setPagination', response.data?._meta ?? initialState.pagination);
      }
    },
  },
};

export default productionIndicatorsListModule;
