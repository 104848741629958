import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toHandlers as _toHandlers, mergeProps as _mergeProps, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fb5ba900"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]
const _hoisted_2 = { class: "icon-box" }
const _hoisted_3 = {
  key: 0,
  class: "badge"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_tooltip = _resolveComponent("the-tooltip")!

  return (_openBlock(), _createBlock(_component_the_tooltip, null, _createSlots({
    icon: _withCtx(() => [
      _createElementVNode("button", _mergeProps(_toHandlers(_ctx.vOnList, true), {
        class: ["btn icon-btn", _ctx.classList],
        disabled: _ctx.disabled,
        onMousedown: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.pressed && _ctx.pressed(...args))),
        onMouseup: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.unpressed && _ctx.unpressed(...args))),
        onMouseleave: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.unpressed && _ctx.unpressed(...args)))
      }), [
        _createElementVNode("i", _hoisted_2, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        (_ctx.showBadge)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3))
          : _createCommentVNode("", true)
      ], 16, _hoisted_1)
    ]),
    _: 2
  }, [
    (!!_ctx.description)
      ? {
          name: "default",
          fn: _withCtx(() => [
            _createElementVNode("p", null, _toDisplayString(_ctx.description), 1)
          ]),
          key: "0"
        }
      : undefined
  ]), 1024))
}