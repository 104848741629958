import {
  AxiosCallResponse, BaseService, LoggerInterface, PaginationParams, ResponseOfRecords,
} from '@/api/base';
import JsonData from '@/components/jsonforms/interfaces/JsonData';

export type ProductionIndicatorsSchema = {
  id: string,
} & JsonData;

export type ProductionIndicatorsListResponseType = AxiosCallResponse<
  ResponseOfRecords<ProductionIndicatorsSchema[]>
>;

export default class ProductionIndicatorsService extends BaseService {
  constructor(token: string, logger: LoggerInterface) {
    super('card', token, logger, 'v2');
  }

  async list(
    cardId: string,
    params: PaginationParams = {},
  ): Promise<ProductionIndicatorsListResponseType> {
    return this.axiosCall({ url: `/${cardId}/production-indicators`, method: 'get', params });
  }

  async create(
    cardId: string,
    data: ProductionIndicatorsSchema,
  ): Promise<AxiosCallResponse<ProductionIndicatorsSchema>> {
    return this.axiosCall({ url: `/${cardId}/production-indicators`, method: 'post', data });
  }

  async update(
    cardId: string,
    id: string,
    data: ProductionIndicatorsSchema,
  ): Promise<AxiosCallResponse<null>> {
    return this.axiosCall({
      url: `/${cardId}/production-indicators/${id}`,
      method: 'patch',
      data,
    });
  }

  async delete(
    cardId: string,
    id: string,
  ): Promise<AxiosCallResponse<null>> {
    return this.axiosCall({
      url: `/${cardId}/production-indicators/${id}`,
      method: 'delete',
    });
  }
}
