import { BoardingSteps } from 'boarding.js';

// eslint-disable-next-line import/prefer-default-export
export const getSteps = (): BoardingSteps => [
  {
    element: 'tbody',
    popover: {
      className: 'first-step-popover-class',
      title: 'Список пользователей',
      description: 'Список доступных вам пользователей.',
      prefferedSide: 'top',
    },
  },
  {
    element: '.table-item',
    popover: {
      className: 'first-step-popover-class',
      title: 'Детальная информация',
      description: 'При клике по пользователю, откроется окно с детальной информацией.',
      prefferedSide: 'top',
    },
  },
  {
    element: '.title-block button',
    popover: {
      className: 'first-step-popover-class',
      title: 'Добавление нового пользователя',
      description: 'Здесь вы можете добавить нового пользователя.',
      prefferedSide: 'left',
    },
  },
  {
    element: '.filter-block',
    popover: {
      className: 'first-step-popover-class',
      title: 'Фильтры',
      description: 'Здесь вы можете отфильтровать пользователей по необходимым критериям.',
      prefferedSide: 'bottom',
    },
  },
];
