import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6302a68e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table mt-7" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_table_header = _resolveComponent("table-header")!
  const _component_table_body = _resolveComponent("table-body")!

  return (_openBlock(), _createElementBlock("table", _hoisted_1, [
    _createVNode(_component_table_header, {
      sort: _ctx.sort,
      onSortChanged: _ctx.sortHandler
    }, null, 8, ["sort", "onSortChanged"]),
    _createVNode(_component_table_body, {
      news: _ctx.news,
      onActionHandler: _ctx.actionContext
    }, null, 8, ["news", "onActionHandler"])
  ]))
}