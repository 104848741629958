import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3a57bb93"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled", "for"]
const _hoisted_2 = ["id", "checked", "disabled"]
const _hoisted_3 = { class: "checkbox-material mr-[8px]" }
const _hoisted_4 = { class: "checkbox-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["checkbox", { clicked: _ctx.isClicked }]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clickHandler && _ctx.clickHandler(...args)))
  }, [
    _createElementVNode("label", {
      disabled: _ctx.isDisabled,
      for: _ctx.id
    }, [
      _createElementVNode("input", {
        type: "checkbox",
        class: "custom-checkbox",
        id: _ctx.id,
        checked: _ctx.value,
        disabled: _ctx.isDisabled
      }, null, 8, _hoisted_2),
      _createElementVNode("span", _hoisted_3, [
        _createElementVNode("span", {
          class: _normalizeClass(["check", !_ctx.value && _ctx.isIncompleteFilling ? 'incomplete-filling' : ''])
        }, null, 2)
      ]),
      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.label), 1)
    ], 8, _hoisted_1)
  ], 2))
}