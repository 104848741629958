import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-eeebbb46"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "loader-block"
}
const _hoisted_3 = {
  key: 1,
  class: "w-full"
}
const _hoisted_4 = { class: "w-full" }
const _hoisted_5 = { class: "header-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_default_loader = _resolveComponent("default-loader")!
  const _component_the_title = _resolveComponent("the-title")!
  const _component_the_filter = _resolveComponent("the-filter")!
  const _component_the_table = _resolveComponent("the-table")!
  const _component_user_detail = _resolveComponent("user-detail")!
  const _component_user_create = _resolveComponent("user-create")!
  const _component_the_side_modal = _resolveComponent("the-side-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_default_loader)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_the_title, {
                "is-can-edit": _ctx.isCanEdit,
                onButtonClick: _ctx.openShortInfo
              }, null, 8, ["is-can-edit", "onButtonClick"]),
              _createVNode(_component_the_filter, {
                filter: _ctx.filter,
                divisions: _ctx.preparedDivisions,
                companies: _ctx.preparedCompanies,
                roles: _ctx.preparedRoles,
                onFilterChanged: _ctx.filterChangedHandler,
                onAjaxCompany: _ctx.ajaxCompanyHandler,
                onAjaxDivision: _ctx.ajaxDivisionHandler
              }, null, 8, ["filter", "divisions", "companies", "roles", "onFilterChanged", "onAjaxCompany", "onAjaxDivision"])
            ])
          ]),
          _createVNode(_component_the_table, {
            items: _ctx.preparedItems,
            fields: _ctx.fields,
            sort: _ctx.sort,
            pagination: _ctx.pagination,
            onChangeSortBy: _ctx.changeSort,
            onChangePagination: _ctx.changePagination,
            onBodyItemClick: _ctx.openUserDetail
          }, null, 8, ["items", "fields", "sort", "pagination", "onChangeSortBy", "onChangePagination", "onBodyItemClick"])
        ])),
    _createVNode(_component_the_side_modal, {
      "is-show-modal": _ctx.isOpenShortInfo,
      "is-sidebar-with-footer": _ctx.isSidebarWithFooter,
      onCloseHandler: _ctx.closeShortInfo
    }, {
      default: _withCtx(() => [
        (!!_ctx.currentUserId)
          ? (_openBlock(), _createBlock(_component_user_detail, {
              key: 0,
              "user-id": _ctx.currentUserId,
              "is-edit": _ctx.isSideModalWithEditMode,
              "is-can-edit": _ctx.isCanEdit,
              "is-visible-roles": _ctx.isVisibleRoles,
              onUpdated: _ctx.updatedHandler,
              onCancel: _ctx.closeShortInfo,
              onChangedEditMode: _ctx.changedEditModeHandler,
              onChangedUserStatus: _ctx.changedUserStatusHandler
            }, null, 8, ["user-id", "is-edit", "is-can-edit", "is-visible-roles", "onUpdated", "onCancel", "onChangedEditMode", "onChangedUserStatus"]))
          : (_openBlock(), _createBlock(_component_user_create, {
              key: 1,
              onCreated: _ctx.createdHandler,
              onCancel: _ctx.closeShortInfo
            }, null, 8, ["onCreated", "onCancel"]))
      ]),
      _: 1
    }, 8, ["is-show-modal", "is-sidebar-with-footer", "onCloseHandler"])
  ]))
}