import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dispatch_renderer = _resolveComponent("dispatch-renderer")!

  return (_ctx.layout.visible)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(_ctx.layoutClassObject.root)
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.layout.uischema.elements, (element, index) => {
          return (_openBlock(), _createBlock(_component_dispatch_renderer, {
            key: `${_ctx.layout.path}-${index}`,
            class: _normalizeClass(_ctx.layoutClassObject.item),
            schema: _ctx.layout.schema,
            uischema: element,
            path: _ctx.layout.path,
            enabled: _ctx.layout.enabled,
            renderers: _ctx.layout.renderers,
            cells: _ctx.layout.cells
          }, null, 8, ["class", "schema", "uischema", "path", "enabled", "renderers", "cells"]))
        }), 128))
      ], 2))
    : _createCommentVNode("", true)
}