
import _ from 'lodash';
import { defineComponent, PropType } from 'vue';
import { JsonSchema } from '@jsonforms/core';
import { ErrorObject } from 'ajv';
import { JsonFormsChangeEvent } from '@jsonforms/vue';
import config from '@/config';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import FormGenerator from '@/components/jsonforms/generators/FormGenerator.vue';
import CustomUISchemaElement from '@/components/jsonforms/interfaces/CustomUISchemaElement';
import { validateData } from '@/utils/helpers/AjvValidator';
import useShowErrors from '@/composables/form/useShowErrors';
import fields from '@/shared/consts/counterparty/fields';

export interface CounterpartyCreateForm {
  data: JsonData,
  currentData: JsonData,
  schema: JsonSchema,
  uischema: CustomUISchemaElement,
  errors: JsonData,
  validateForm: (showErrors?: boolean) => boolean,
  showErrors: () => void,
  onChange: () => void,
}

export default defineComponent({
  name: 'TheForm',
  inject: ['$sizeWindow'],
  components: {
    FormGenerator,
  },
  props: {
    schema: {
      required: true,
      type: Object as PropType<JsonSchema>,
    },
    data: {
      type: Object as PropType<JsonData>,
      default: () => ({}),
    },
  },
  watch: {
    data() {
      this.currentData = this.getDataBySchema(this.data, this.schema);
    },
  },
  data() {
    return {
      localSchema: this.schema,
      uischema: {
        type: 'VerticalLayout',
        elements: [
          {
            type: 'Control',
            label: this.$t('counterpartyLabels.name'),
            scope: `#/properties/${fields.NAME_FIELD}`,
            options: {
              readonly: this.keyExistsInData(fields.NAME_FIELD, this.data),
            },
          },
          {
            type: 'Control',
            label: this.$t('counterpartyLabels.fullName'),
            scope: `#/properties/${fields.FULL_NAME_FIELD}`,
            options: {
              readonly: this.keyExistsInData(fields.FULL_NAME_FIELD, this.data),
            },
          },
          {
            type: 'Control',
            label: this.$t('counterpartyLabels.inHolding'),
            scope: `#/properties/${fields.IN_HOLDING}`,
          },
          {
            type: 'Control',
            label: this.$t('counterpartyLabels.headCompany'),
            scope: `#/properties/${fields.HEAD_COMPANY}`,
            options: {
              search: true,
            },
            rule: {
              effect: 'SHOW',
              condition: {
                scope: `#/properties/${fields.IN_HOLDING}`,
                schema: { const: true },
              },
            },
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: this.$t('counterpartyLabels.type'),
                scope: `#/properties/${fields.TYPE_FIELD}`,
                options: {
                  readonly: this.keyExistsInData(fields.TYPE_FIELD, this.data),
                  select: {
                    calculatePosition: { extraHeight: 225 },
                  },
                },
              },
              {
                type: 'Control',
                label: this.$t('counterpartyLabels.view'),
                scope: `#/properties/${fields.VIEW_FIELD}`,
                options: {
                  readonly: this.keyExistsInData(fields.VIEW_FIELD, this.data),
                  select: {
                    calculatePosition: { extraHeight: 225 },
                  },
                },
              },
            ],
          },
          {
            type: 'AjaxControl',
            label: this.$t('counterpartyLabels.industry'),
            scope: `#/properties/${fields.INDUSTRY_FIELD}`,
            options: {
              readonly: this.keyExistsInData(fields.INDUSTRY_FIELD, this.data),
              select: {
                calculatePosition: { extraHeight: 225 },
              },
              ajaxConfiguration: {
                categoryName: 'industry',
              },
            },
          },
          {
            type: 'Control',
            label: this.$t('counterpartyLabels.marketStatus'),
            scope: `#/properties/${fields.MARKET_STATUS}`,
            options: {
              readonly: this.keyExistsInData(fields.MARKET_STATUS, this.data),
              select: {
                calculatePosition: { extraHeight: 225 },
              },
            },
          },
          {
            type: 'AjaxControl',
            label: this.$t('counterpartyLabels.country'),
            scope: `#/properties/${fields.COUNTRY_FIELD}`,
            options: {
              search: true,
              readonly: this.keyExistsInData(fields.COUNTRY_FIELD, this.data),
              select: {
                calculatePosition: { extraHeight: 225 },
              },
              ajaxConfiguration: {
                categoryName: 'country',
                mapper: {
                  name: 'shortName',
                  id: 'code',
                },
              },
            },
          },
          {
            type: 'AddressControl',
            label: this.$t('counterpartyLabels.legalAddress'),
            scope: `#/properties/${fields.LEGAL_ADDRESS_FIELD}`,
            options: {
              mapper: {
                text: config.ADDRESS_MAPPER_FIELD_TEXT,
                fields: {
                  postalCode: config.ADDRESS_MAPPER_FIELD_POSTAL_CODE,
                  country: config.ADDRESS_MAPPER_FIELD_COUNTRY,
                  province: config.ADDRESS_MAPPER_FIELD_PROVINCE,
                  locality: config.ADDRESS_MAPPER_FIELD_LOCALITY,
                  area: config.ADDRESS_MAPPER_FIELD_AREA,
                  street: config.ADDRESS_MAPPER_FIELD_STREET,
                  house: config.ADDRESS_MAPPER_FIELD_HOUSE,
                  housing: config.ADDRESS_MAPPER_FIELD_HOUSING,
                  flat: config.ADDRESS_MAPPER_FIELD_FLAT,
                },
              },
              readonly: this.keyExistsInData(fields.LEGAL_ADDRESS_FIELD, this.data),
            },
          },
          {
            type: 'AddressControl',
            label: this.$t('counterpartyLabels.actualAddress'),
            scope: `#/properties/${fields.ACTUAL_ADDRESS_FIELD}`,
            options: {
              mapper: {
                text: config.ADDRESS_MAPPER_FIELD_TEXT,
                fields: {
                  postalCode: config.ADDRESS_MAPPER_FIELD_POSTAL_CODE,
                  country: config.ADDRESS_MAPPER_FIELD_COUNTRY,
                  province: config.ADDRESS_MAPPER_FIELD_PROVINCE,
                  locality: config.ADDRESS_MAPPER_FIELD_LOCALITY,
                  area: config.ADDRESS_MAPPER_FIELD_AREA,
                  street: config.ADDRESS_MAPPER_FIELD_STREET,
                  house: config.ADDRESS_MAPPER_FIELD_HOUSE,
                  housing: config.ADDRESS_MAPPER_FIELD_HOUSING,
                  flat: config.ADDRESS_MAPPER_FIELD_FLAT,
                },
              },
              readonly: this.keyExistsInData(fields.ACTUAL_ADDRESS_FIELD, this.data),
            },
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: this.$t('counterpartyLabels.taxNumber'),
                scope: `#/properties/${fields.TAX_NUMBER_FIELD}`,
                rule: {
                  effect: 'HIDE',
                  condition: {
                    scope: `#/properties/${fields.COUNTRY_FIELD}`,
                    schema: {
                      const: config.SIGN_OF_RESIDENT,
                    },
                  },
                },
                options: {
                  readonly: this.keyExistsInData(fields.TAX_NUMBER_FIELD, this.data),
                  required: true,
                },
              },
              {
                type: 'Control',
                label: this.$t('counterpartyLabels.inn'),
                scope: `#/properties/${fields.INN_FIELD}`,
                rule: {
                  effect: 'SHOW',
                  condition: {
                    scope: `#/properties/${fields.COUNTRY_FIELD}`,
                    schema: {
                      const: config.SIGN_OF_RESIDENT,
                    },
                  },
                },
                options: {
                  readonly: this.keyExistsInData(fields.INN_FIELD, this.data),
                  required: true,
                },
              },
              {
                type: 'Control',
                label: this.$t('counterpartyLabels.kpp'),
                scope: `#/properties/${fields.KPP_FIELD}`,
                rule: {
                  effect: 'SHOW',
                  condition: {
                    scope: `#/properties/${fields.COUNTRY_FIELD}`,
                    schema: {
                      const: config.SIGN_OF_RESIDENT,
                    },
                  },
                },
                options: {
                  readonly: this.keyExistsInData(fields.KPP_FIELD, this.data),
                },
              },
            ],
          },
          {
            type: 'Control',
            label: this.$t('counterpartyLabels.registrationNumber'),
            scope: `#/properties/${fields.REG_NUMBER}`,
            options: {
              readonly: this.keyExistsInData(fields.REG_NUMBER, this.data),
            },
          },
          {
            type: 'Control',
            label: this.$t('counterpartyLabels.haventOwnVolume'),
            scope: `#/properties/${fields.HAVENT_OWN_VOLUME}`,
          },
        ],
      },
      currentData: this.getDataBySchema(this.data, this.schema),
      errors: [] as ErrorObject[],
    };
  },
  methods: {
    onChange(event: JsonFormsChangeEvent) {
      this.currentData = this.getDataBySchema(event.data, this.schema);
    },
    validateForm(showErrors = false): boolean {
      this.errors = validateData(this.currentData, this.schema, this.uischema);

      if (showErrors) {
        this.showErrors(this.errors);
      }

      return !this.errors || !this.errors.length;
    },
    getDataBySchema(data: JsonData, schema: JsonSchema): JsonData {
      return _.omitBy(data, (
        value,
        key: string,
      ) => !value || !Object.prototype.hasOwnProperty.call(schema.properties, key));
    },
    keyExistsInData(key: string, data: JsonData) {
      return _.has(data, key);
    },
  },
  setup() {
    const { showErrors } = useShowErrors();

    return { showErrors };
  },
});
