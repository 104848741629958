import { Module } from 'vuex';
import list, { SeminarHistoryListStateType } from '@/store/modules/counterparty/block/relationship/seminarHistory/list';
import one, { SeminarHistoryOneStateType } from '@/store/modules/counterparty/block/relationship/seminarHistory/one';
import { StateType } from '@/store';

export interface SeminarHistoryStateType {
  list: SeminarHistoryListStateType,
  one: SeminarHistoryOneStateType,
}

const conflictHistoryModule: Module<SeminarHistoryStateType, StateType> = {
  namespaced: true,
  modules: {
    list,
    one,
  },
};

export default conflictHistoryModule;
