
import { defineComponent, ref } from 'vue';
import IconButton from '@/components/ui/buttons/IconButton/IconButton.vue';
import InternationalIcon from '@/components/ui/icons/InternationalIcon.vue';
import BuildingIcon from '@/components/ui/icons/BuildingIcon.vue';
import ListIcon from '@/components/ui/icons/ListIcon.vue';
import LinkIcon from '@/components/ui/icons/LinkIcon.vue';
import FactoryIcon from '@/components/ui/icons/FactoryIcon.vue';
import ViewGridIcon from '@/components/ui/icons/ViewGridIcon.vue';
import BookOpenIcon from '@/components/ui/icons/BookOpenIcon.vue';
import BlockArrow from '@/components/ui/icons/BlockArrow.vue';
import LibraryIcon from '@/components/ui/icons/LibraryIcon.vue';
import DocumentReportIcon from '@/components/ui/icons/DocumentReportIcon.vue';
import useCheckPermissions from '@/composables/useCheckPermissions';
import ClipboardListIcon from '@/components/ui/icons/ClipboardListIcon.vue';
import ChartPieIcon from '@/components/ui/icons/ChartPieIcon.vue';
import useUserAccess from '@/composables/useUserAccess';
import TheCustomNotification from '@/views/notification/TheCustomNotification.vue';
import PresentationChartLine from '@/components/ui/icons/PresentationChartLine.vue';
import TrendingUpIcon from '@/components/ui/icons/TrendingUpIcon.vue';
import BriefcaseIcon from '@/components/ui/icons/BriefcaseIcon.vue';
import OutsideClick from '@/utils/directives/OutsideClick';
import AcademicCapIcon from '@/components/ui/icons/AcademicCapIcon.vue';
import UserGroupIcon from '@/components/ui/icons/UserGroupIcon.vue';
import UsersIcon from '@/components/ui/icons/UsersIcon.vue';
import UserCircleIcon from '@/components/ui/icons/UserCircleIcon.vue';
import { mapGetters } from 'vuex';
import MapIcon from '@/components/ui/icons/MapIcon.vue';
import QuestionMarkCircleIcon from '@/components/ui/icons/QuestionMarkCircleIcon.vue';
import useTour from '@/utils/tours/useTour';
import CreditCardIcon from '@/components/ui/icons/CreditCardIcon.vue';
import DefaultButton from '@/components/ui/buttons/DefaultButton/DefaultButton.vue';

export default defineComponent({
  name: 'SidebarDefault',
  inject: ['$userAccess', '$sizeWindow'],
  components: {
    DefaultButton,
    CreditCardIcon,
    QuestionMarkCircleIcon,
    MapIcon,
    UserCircleIcon,
    UsersIcon,
    UserGroupIcon,
    AcademicCapIcon,
    TrendingUpIcon,
    BriefcaseIcon,
    PresentationChartLine,
    TheCustomNotification,
    ChartPieIcon,
    IconButton,
    InternationalIcon,
    BuildingIcon,
    ListIcon,
    LinkIcon,
    FactoryIcon,
    ViewGridIcon,
    BookOpenIcon,
    BlockArrow,
    LibraryIcon,
    DocumentReportIcon,
    ClipboardListIcon,
  },
  data() {
    return {
      isOpen: false,
      isLearnedPage: false,
    };
  },
  methods: {
    toggleDictionary() {
      this.isOpen = !this.isOpen;
    },
    closeDictionary() {
      this.isOpen = false;
    },
    isActiveColor(active: boolean) {
      return active ? '#6366F1' : undefined;
    },
    async startTour() {
      const scenario = await this.tour.getScenario(this.$route.path);

      if (scenario.length > 0) {
        this.tour.registerSteps(scenario);
        this.tour.start();
        this.tour.setLearnedPage(this.$route.path);
        this.checkLearnPage();
      }
    },
    checkLearnPage() {
      this.isLearnedPage = this.tour.pageIsLearned(this.$route.path);
    },
  },
  watch: {
    '$route.path': function changeRouteHandler() {
      this.checkLearnPage();
    },
  },
  computed: {
    ...mapGetters({
      user: 'user/localUserData',
    }),
    userName() {
      return `${this.user.lastName} ${this.user.firstName}`;
    },
    mobileClass() {
      return {
        mobile: this.$sizeWindow.isLg,
      };
    },
  },
  directives: {
    OutsideClick,
  },
  mounted() {
    this.checkLearnPage();
  },
  setup() {
    const checkPermissions = useCheckPermissions();
    const userAccess = useUserAccess();
    const isShowAssign = ref(checkPermissions.dictionary.canViewAssign);
    const isShowCompany = ref(checkPermissions.dictionary.canViewCompany);
    const isShowSidebar = ref(userAccess.viewer.isMainViewer || userAccess.admin.isSuperAdmin);
    const isShowTechnologicalSupportType = ref(
      checkPermissions.dictionary.canEditTechnologicalSupportType,
    );
    const tour = useTour();

    return {
      isShowAssign,
      isShowCompany,
      isShowSidebar,
      isShowTechnologicalSupportType,
      tour,
    };
  },
});
