import _ from 'lodash';
import { Module } from 'vuex';
import PinningService, {
  AssignDetailItem,
  PinningDetachItem,
  PinningItem,
  PinningReassignItem,
} from '@/api/admin/pinning';
import { AxiosCallResponse } from '@/api/base';
import { StateType } from '@/store';
import { TYPE_LABEL_SUCCESS } from '@/components/ui/notifications/TheAlert/TheAlert.vue';
import i18n from '@/i18n';

export interface PinningOneStateType {
  data: PinningItem | null,
  detail: AssignDetailItem | null,
}

const getInitialState = () => ({
  data: null,
  detail: null,
});

const pinningOneModule: Module<PinningOneStateType, StateType> = {
  namespaced: true,
  state: _.cloneDeep(getInitialState()),
  getters: {
    data: (state) => state.data,
    detail: (state) => state.detail,
  },
  mutations: {
    setData(state, item: PinningItem) {
      state.data = item;
    },
    setDetail(state, item: AssignDetailItem) {
      state.detail = item;
    },
    resetState: (state) => {
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    resetState({ commit }) {
      commit('resetState');
    },
    async get({ rootGetters, commit }, id: string) {
      const api: PinningService = rootGetters.$api.admin.pinning;

      const response: AxiosCallResponse<AssignDetailItem> = await api.getDetailInfo(id);

      if (!response.error) {
        commit('setDetail', response.data ?? getInitialState().detail);
      }
    },
    async create({ rootGetters, dispatch }, data: PinningItem) {
      const api: PinningService = rootGetters.$api.admin.pinning;

      const response: AxiosCallResponse<PinningItem> = await api.create(data);

      if (!response.error) {
        await rootGetters
          .$notificationLogger
          .setNotification(
            TYPE_LABEL_SUCCESS,
            i18n.global.t('notification.admin.dictionary.success.create'),
          );

        await dispatch('admin/dictionary/pinning/list/loadItems', null, { root: true });
      }
    },
    async detach({ rootGetters }, params: {
      id: string,
      data: PinningDetachItem,
    }) {
      const { id, data } = params;
      const api: PinningService = rootGetters.$api.admin.pinning;

      const response: AxiosCallResponse<null> = await api.detach(id, data);

      if (!response.error) {
        await rootGetters
          .$notificationLogger
          .setNotification(
            TYPE_LABEL_SUCCESS,
            i18n.global.t('notification.admin.pinning.success.detach'),
          );
      }
    },

    async reassign({ rootGetters }, params: {
      id: string,
      data: PinningReassignItem,
    }) {
      const { id, data } = params;
      const api: PinningService = rootGetters.$api.admin.pinning;

      const response: AxiosCallResponse<null> = await api.reassign(id, data);

      if (!response.error) {
        await rootGetters
          .$notificationLogger
          .setNotification(
            TYPE_LABEL_SUCCESS,
            i18n.global.t('notification.admin.pinning.success.reassign'),
          );
      }
    },
  },
};

export default pinningOneModule;
