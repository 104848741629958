import DictionaryBase, { DictionaryListResponseType } from '@/api/admin/dictionary/base';
import { LoggerInterface } from '@/api/base';

export interface NomenclatureItem {
  id: string,
  codeMdm: string,
  name: string,
  fullName: string,
  groupId: string,
  measUnit: string,
  vatRate: string,
  vendorCode: string,
  isDeleted: boolean,
}

export type NomenclatureListResponseType = DictionaryListResponseType<NomenclatureItem>;

export default class NomenclatureService extends DictionaryBase {
  constructor(token: string, logger: LoggerInterface = console) {
    super('nomenclature', token, logger);
  }
}
