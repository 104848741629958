
import { defineComponent } from 'vue';
import { TableFieldObject, TableItem } from '@/components/ui/tables/simple/TheTable';
import SelectArrow from '@/components/ui/icons/SelectArrow.vue';
import IconButton from '@/components/ui/buttons/IconButton/IconButton.vue';

export default defineComponent({
  name: 'TheItem',
  components: {
    IconButton,
    SelectArrow,
  },
  data() {
    return {
      isOpenItems: this.isAllOpen,
    };
  },
  watch: {
    isAllOpen(value: boolean) {
      this.isOpenItems = value;
    },
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    fields: {
      type: Object,
      default: () => ({}),
    },
    variant: {
      type: String,
      default: 'expand',
    },
    offset: {
      type: Number,
      default: 0,
    },
    isAllOpen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    countCommonColumns(): number {
      return Object.keys(this.fields)
        .filter((key: string) => key !== 'id')
        .length;
    },
    isSimple() {
      return this.variant === 'simple';
    },
    isExpand() {
      return this.variant === 'expand';
    },
    isHaveChild() {
      return this.item.items !== undefined && this.item.items.length > 0;
    },
    isOpenArrow() {
      return this.isOpenItems ? '' : 'rotate-[-90deg]';
    },
    filteredFields() {
      return this.fields.filter((item: { isVisible: boolean }) => item.isVisible !== false);
    },
  },
  methods: {
    checkObjectType(item: TableItem, field: TableFieldObject, type: string) {
      const key: string = field.key ?? '';
      const options: string | { type?: string } = key && !!item[key]
        ? item[key] as string | { type?: string }
        : '';

      if (typeof options === 'string') {
        return false;
      }

      return typeof options === 'object' && options?.type === type;
    },
    isLink(item: TableItem, field: TableFieldObject): boolean {
      return this.checkObjectType(item, field, 'link');
    },
    isComponent(item: TableItem, field: TableFieldObject): boolean {
      return this.checkObjectType(item, field, 'component');
    },
    toggleList() {
      this.isOpenItems = !this.isOpenItems;
    },
    clickItem($event: Event) {
      this.$emit('clickItem', this.item.id, $event);
    },
    addOffset(index: number) {
      return index === 0 ? `margin-left: ${this.offset}px` : '';
    },
    addNameOffset(index: number) {
      return index === 0 ? 'ml-[26px]' : '';
    },
    isShowToggleButton(index: number) {
      return index === 0 && this.isHaveChild;
    },
  },
});
