
import { defineComponent, PropType } from 'vue';
import TheTooltip from '@/components/ui/modal/TheTooltip/TheTooltip.vue';

type ButtonType = 'default' | 'primary' | 'inline';
type BorderType = 'default' | 'primary' | 'non-border';

export default defineComponent({
  name: 'DefaultButton',
  components: { TheTooltip },
  props: {
    description: String,
    type: {
      type: String as PropType<ButtonType>,
      default: 'default',
    },
    border: {
      type: String as PropType<BorderType>,
      default: 'default',
    },
    disabled: Boolean,
    height: {
      type: Number,
      default: 32,
    },
  },
  data() {
    return {
      isPress: false,
    };
  },
  computed: {
    classes() {
      return {
        press: this.isPress,
        primary: this.type === 'primary',
        inline: this.type === 'inline',
        'border-primary': this.border === 'primary',
        'non-border': this.border === 'non-border',
      };
    },
    vOnList() {
      return {
        touchstart: this.pressed,
        touchend: this.unpressed,
        touchcancel: this.unpressed,
      };
    },
  },
  methods: {
    pressed() {
      this.isPress = true;
    },
    unpressed() {
      this.isPress = false;
    },
  },
});
