import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3ce15e85"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "bg-[#F9FAFB]" }
const _hoisted_2 = ["data-key"]
const _hoisted_3 = ["innerHTML", "data-key"]
const _hoisted_4 = { key: 1 }
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sort_arrow = _resolveComponent("sort-arrow")!

  return (_openBlock(), _createElementBlock("thead", null, [
    _createElementVNode("tr", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.newsAttributes, (item, name) => {
        return (_openBlock(), _createElementBlock("th", {
          key: name,
          class: "text-[14px]"
        }, [
          (name !== 'hidden' && item.isSort)
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeSort && _ctx.changeSort(...args))),
                class: "cursor-pointer",
                "data-key": name
              }, [
                _createElementVNode("span", {
                  innerHTML: _ctx.$t(item.label),
                  "data-key": name
                }, null, 8, _hoisted_3),
                _createVNode(_component_sort_arrow, {
                  "data-key": name,
                  direction: _ctx.getSortDirection(name),
                  "fill-opacity": _ctx.getFillOpacity(name)
                }, null, 8, ["data-key", "direction", "fill-opacity"])
              ], 8, _hoisted_2))
            : _createCommentVNode("", true),
          (name !== 'hidden' && !item.isSort)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                _createElementVNode("span", {
                  innerHTML: _ctx.$t(item.label)
                }, null, 8, _hoisted_5)
              ]))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ])
  ]))
}