import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TabsMenuItem',
  props: {
    name: String,
    shortName: String,
    counterpartyId: String,
    isActiveTab: {
      type: Boolean,
      default: false,
    },
  },
});
