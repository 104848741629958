import DictionaryBase, { DictionaryListResponseType } from '@/api/admin/dictionary/base';
import { LoggerInterface } from '@/api/base';

export interface ActivityDirectionSchema {
  id: string,
  name: string,
}

export interface ActivityDirectionData {
  name: string,
}

export type ActivityDirectionListResponseType
  = DictionaryListResponseType<ActivityDirectionSchema>;

export default class ActivityDirectionService extends DictionaryBase {
  constructor(token: string, logger: LoggerInterface = console) {
    super('activity-direction', token, logger);
  }
}
