import { Module } from 'vuex';
import {
  JsonDataOneItem, MetaParams, PaginationParams, SortParams,
} from '@/api/base';
import ConflictHistoryService, {
  ConflictHistoryItem,
  ConflictHistoryResponseType,
} from '@/api/block/relationship/conflictHistory';
import { StateType } from '@/store';

export type FilterConflictHistory = JsonDataOneItem | null;

export type QueryParamsForListConflictHistory = PaginationParams
    | FilterConflictHistory | SortParams;

export interface ConflictHistoryListStateType {
  items: ConflictHistoryItem[],
  filter: FilterConflictHistory,
  sort: SortParams,
  pagination: MetaParams,
}

const initialState = {
  items: [],
  pagination: {
    currentPage: null,
    pageCount: null,
    perPage: 5,
    totalCount: null,
  },
  filter: null,
  sort: {
    sortBy: null,
  },
};

const conflictHistoryListModule: Module<ConflictHistoryListStateType, StateType> = {
  namespaced: true,
  state: initialState,
  getters: {
    items: (state) => state.items,
    filter: (state): FilterConflictHistory => state.filter,
    sort: (state): SortParams => state.sort,
    pagination: (state): MetaParams => state.pagination,
    loadParams: (state): QueryParamsForListConflictHistory => ({
      page: state.pagination.currentPage,
      perPage: state.pagination.perPage,
      sortBy: state.sort.sortBy,
      ...state.filter,
    }),
  },
  mutations: {
    setItems: (state, items) => {
      state.items = items;
    },
    setFilter: (state, filter) => {
      state.filter = filter;
    },
    setSort: (state, sort) => {
      state.sort = sort;
    },
    setPagination: (state, pagination) => {
      state.pagination = pagination;
    },
  },
  actions: {
    async changePagination({ commit }, pagination: MetaParams) {
      commit('setPagination', pagination);
    },
    async changeFilter({ commit, state }, filter: FilterConflictHistory) {
      commit('setFilter', filter);
      commit('setPagination', { ...state.pagination, currentPage: 1 });
    },
    async changeSort({ commit }, sort: SortParams) {
      commit('setSort', sort);
    },
    async loadItems({ getters, rootGetters, commit }, cardId: string) {
      const api: ConflictHistoryService = rootGetters.$api.relationship.conflictHistory;
      const { loadParams } = getters;

      const response: ConflictHistoryResponseType = await api.list(cardId, loadParams);

      commit('setItems', response.data?.items ?? initialState.items);
      commit('setPagination', response.data?._meta ?? initialState.pagination);
    },
  },
};

export default conflictHistoryListModule;
