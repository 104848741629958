<template>
  <svg v-if="isSolid"
       xmlns="http://www.w3.org/2000/svg"
       viewBox="0 0 20 20"
       :fill="color"
       :width="width"
       :height="height"
  >
    <path fill-rule="evenodd"
          d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952
          0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1
          1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z"
          clip-rule="evenodd"></path>
    <path
      d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974
        24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z"></path>
  </svg>

  <svg
    v-else
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    :stroke="color"
    :height="height"
    :width="width"
  >
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
          d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16
          6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2
          2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
    >
    </path>
  </svg>
</template>

<script>
import iconsMixin from '@/components/ui/utils/mixins/icons';

export default {
  name: 'BriefcaseIcon',
  mixins: [iconsMixin],
};
</script>
