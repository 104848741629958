import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    width: _ctx.width,
    height: _ctx.width,
    viewBox: "0 0 16 16",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571\n          12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569\n          2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344\n          0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0\n          10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571\n          5.87827 16 8 16ZM6.707 5.293C6.5184 5.11084 6.2658 5.01005\n          6.0036 5.01233C5.7414 5.0146 5.49059 5.11977 5.30518\n          5.30518C5.11977 5.49059 5.0146 5.7414 5.01233 6.0036C5.01005\n          6.2658 5.11084 6.5184 5.293 6.707L6.586 8L5.293 9.293C5.19749\n          9.38525 5.12131 9.49559 5.0689 9.6176C5.01649 9.7396 4.9889\n          9.87082 4.98775 10.0036C4.9866 10.1364 5.0119 10.2681 5.06218\n          10.391C5.11246 10.5139 5.18671 10.6255 5.2806 10.7194C5.3745\n          10.8133 5.48615 10.8875 5.60905 10.9378C5.73194 10.9881 5.86362\n          11.0134 5.9964 11.0123C6.12918 11.0111 6.2604 10.9835 6.3824\n          10.9311C6.50441 10.8787 6.61475 10.8025 6.707 10.707L8 9.414L9.293\n          10.707C9.4816 10.8892 9.7342 10.99 9.9964 10.9877C10.2586 10.9854\n          10.5094 10.8802 10.6948 10.6948C10.8802 10.5094 10.9854 10.2586\n          10.9877 9.9964C10.99 9.7342 10.8892 9.4816 10.707 9.293L9.414 8L10.707\n          6.707C10.8892 6.5184 10.99 6.2658 10.9877 6.0036C10.9854 5.7414 10.8802\n          5.49059 10.6948 5.30518C10.5094 5.11977 10.2586 5.0146 9.9964\n          5.01233C9.7342 5.01005 9.4816 5.11084 9.293 5.293L8 6.586L6.707 5.293Z",
      fill: _ctx.color
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}