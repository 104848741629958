import { OneOfTree } from '@/components/jsonforms/renderers/controls/MultiTreeControlRenderer.vue';
import { DataTypeTree } from '@/components/ui/selects/SelectTree/SelectTree.vue';

export const deserializeTree = <T>(treeData: OneOfTree<T>[], parentId: T) => {
  const result: DataTypeTree<T>[] = [];

  treeData.forEach((item: OneOfTree<T>) => {
    if (item.properties.parent.const === parentId) {
      const children: DataTypeTree<T>[] = deserializeTree(treeData, item.const);

      if (children && children.length) {
        result.push({
          id: item.const,
          name: item.title,
          items: children,
        });
      } else {
        result.push({
          id: item.const,
          name: item.title,
        });
      }
    }
  });

  return result;
};

export const findElementById = <T>(array: DataTypeTree<T>[], id: T) => {
  let resultItem: DataTypeTree<T> | undefined;

  for (const item of array) {
    if (item.id === id) {
      resultItem = item;
      break;
    } else if (item.items) {
      resultItem = findElementById(item.items, id);

      if (resultItem) {
        break;
      }
    }
  }
  return resultItem;
};

export const clearChildrenElement = <T>(array: DataTypeTree<T>[], valueList: T[]) => {
  if (valueList.length <= 0) {
    return [];
  }

  let list: T[] = [];

  array.forEach((item: DataTypeTree<T>) => {
    const indexEl = valueList.indexOf(item.id);

    list = valueList.filter((value: T, index: number) => index !== indexEl);

    if (item.items && item.items.length > 0) {
      list = clearChildrenElement(item.items, valueList);
    }
  });

  return list;
};

// ToDo:
//  - не чистая функция, нужно переписать
//  - имеется такая же функция в методах TheList структуры
export const findToTree = (
  array: DataTypeTree[],
  query: string,
) => array.filter((item: DataTypeTree) => {
  if (item.name.toLowerCase().includes(query.toLowerCase())) {
    return item;
  }

  if (item.items) {
    const items = findToTree(item.items, query);

    if (items.length) {
      // eslint-disable-next-line no-param-reassign
      item.items = items;

      return item;
    }
  }

  return false;
});

// ToDo: не чистая функция, нужно переписать
export const fillSearchText = (
  array: DataTypeTree[],
  query: string,
) => array.forEach((item: DataTypeTree) => {
  if (item.name.toLowerCase().includes(query.toLowerCase())) {
    // eslint-disable-next-line no-param-reassign
    item.name = item.name.replace('<span class="selected-text">', '');
    // eslint-disable-next-line no-param-reassign
    item.name = item.name.replace('</span>', '');

    const firstIndex = item.name.toLowerCase().indexOf(query.toLowerCase());
    const lastIndex = firstIndex + query.length;

    // eslint-disable-next-line no-param-reassign
    item.name = `${item.name.substring(0, firstIndex)}<span class="selected-text">${item.name.substring(firstIndex, lastIndex)}</span>${item.name.substring(lastIndex, item.name.length)}`;
  }

  if (item.items) {
    fillSearchText(item.items, query);
  }
});
