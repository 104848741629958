import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-13e53776"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "filter-block" }
const _hoisted_2 = { class: "flex space-x-[16px]" }
const _hoisted_3 = { class: "w-full relative" }
const _hoisted_4 = ["value", "placeholder"]
const _hoisted_5 = {
  key: 0,
  class: "w-full h-full"
}
const _hoisted_6 = { class: "loader-block min-h-[200px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search_icon = _resolveComponent("search-icon")!
  const _component_filter_icon = _resolveComponent("filter-icon")!
  const _component_icon_button = _resolveComponent("icon-button")!
  const _component_close_icon = _resolveComponent("close-icon")!
  const _component_default_loader = _resolveComponent("default-loader")!
  const _component_the_form = _resolveComponent("the-form")!
  const _component_the_modal = _resolveComponent("the-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("input", {
          type: "text",
          class: "input",
          value: _ctx.query,
          placeholder: _ctx.$t('common.search'),
          onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.searchInputHandler && _ctx.searchInputHandler(...args)))
        }, null, 40, _hoisted_4),
        _createVNode(_component_search_icon, { class: "absolute top-[8px] right-[8px]" })
      ]),
      _createVNode(_component_icon_button, {
        border: "default",
        description: _ctx.$t('counterparty.list.filter.filters'),
        "show-badge": _ctx.isHaveFilters,
        onClick: _ctx.openFiltersHandler
      }, {
        default: _withCtx(() => [
          _createVNode(_component_filter_icon, {
            type: "outline",
            width: "20",
            height: "20"
          })
        ]),
        _: 1
      }, 8, ["description", "show-badge", "onClick"]),
      _createVNode(_component_icon_button, {
        border: "default",
        description: _ctx.$t('counterparty.list.filter.clear'),
        onClick: _ctx.clearFiltersHandler
      }, {
        default: _withCtx(() => [
          _createVNode(_component_close_icon)
        ]),
        _: 1
      }, 8, ["description", "onClick"]),
      _createVNode(_component_the_modal, {
        width: "900px",
        "is-open": _ctx.isOpenPopup,
        "add-button-text": _ctx.$t('buttons.apply'),
        onCloseModal: _ctx.closeFiltersHandler,
        onApprovalHandler: _ctx.approveFiltersHandler
      }, {
        default: _withCtx(() => [
          (_ctx.isLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_default_loader)
                ])
              ]))
            : (_openBlock(), _createBlock(_component_the_form, {
                key: 1,
                ref: "filters",
                class: "w-full mt-4",
                data: _ctx.filters,
                "user-id": this.user.id,
                industries: _ctx.industries,
                divisions: _ctx.divisions,
                departments: _ctx.departments,
                onDataChanged: _ctx.filtersChangedHandler,
                onSubmit: _ctx.submitFiltersHandler,
                onAjaxIndustries: _ctx.ajaxIndustriesHandler
              }, null, 8, ["data", "user-id", "industries", "divisions", "departments", "onDataChanged", "onSubmit", "onAjaxIndustries"]))
        ]),
        _: 1
      }, 8, ["is-open", "add-button-text", "onCloseModal", "onApprovalHandler"])
    ])
  ]))
}