<template>
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.59961 0.800049L11.1996 10.4M1.59961 10.4L11.1996 0.800049L1.59961 10.4Z"
      :class="classColor"
      stroke-width="1.6"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CloseIcon',
  props: {
    classColor: String,
  },
});
</script>

<style scoped lang="scss">
@import "@/components/ui/consts.scss";

.success {
  stroke: $notification-color-success;
}
.info {
  stroke: $notification-color-info;
}
.error {
  stroke: $notification-color-error;
}
.warning {
  stroke: $notification-color-warning;
}
</style>
