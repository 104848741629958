import {
  AxiosCallResponse,
  FilterParams,
  BaseService,
  ResponseOfRecords,
  LoggerInterface,
} from '@/api/base';

type KeyValue = string | number | boolean;

export interface CounterpartyItemType {
  [key: string]: KeyValue,
  codeMdm: string,
  foundField: string,
  isPriority: boolean,
}

export type CounterpartyResponseType = AxiosCallResponse<ResponseOfRecords<CounterpartyItemType[]>>;

export type GetCounterpartyListParams = FilterParams;

export default class MdmService extends BaseService {
  constructor(token: string, logger: LoggerInterface = console) {
    super('mdm', token, logger);
  }

  async getCounterpartyList(params: GetCounterpartyListParams): Promise<CounterpartyResponseType> {
    return this.axiosCall({ url: '/', method: 'get', params });
  }
}
