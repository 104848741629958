
import { defineComponent, PropType, ref } from 'vue';
import { JsonSchema } from '@jsonforms/core';
import _ from 'lodash';
import { MetaParams, PaginationParams } from '@/api/base';
import CustomUISchemaElement from '@/components/jsonforms/interfaces/CustomUISchemaElement';
import ThePagination
  from '@/components/counterparty/card/blocks/general/payment/pagination/ThePagination.vue';
import TheDetail from '@/components/counterparty/card/blocks/general/payment/detail/TheDetail.vue';
import TheItem from '@/components/counterparty/card/blocks/general/payment/item/TheItem.vue';
import BlockDefault from '@/components/ui/blocks/BlockDefault/BlockDefault.vue';
import AddButtonComponent from '@/components/widgets/add-button-component/AddButtonComponent.vue';
import TheSideModal from '@/components/ui/modal/TheSideModal/TheSideModal.vue';
import TheCreate from '@/components/counterparty/card/blocks/general/payment/create/TheCreate.vue';
import TheDangerModal from '@/components/ui/modal/TheDangerModal/TheDangerModal.vue';
import JsonData from '@/components/jsonforms/interfaces/JsonData';

export default defineComponent({
  name: 'TheListPayment',
  emits: [
    'created',
    'updated',
    'block',
    'unblock',
    'pagination-changed',
    'changed-form-data'],
  components: {
    TheDangerModal,
    TheCreate,
    TheSideModal,
    AddButtonComponent,
    BlockDefault,
    TheItem,
    TheDetail,
    ThePagination,
  },
  props: {
    blockLabel: String,
    items: Array,
    pagination: Object as PropType<MetaParams>,
    uischema: Object as PropType<CustomUISchemaElement>,
    schema: Object as PropType<JsonSchema>,
    isCanEdit: Boolean,
  },
  data() {
    return {
      isOpenForm: false,
      isCreate: false,
      isUpdate: false,
      isOpenConfirmBlockModal: false,
      isOpenConfirmUnblockModal: false,
    };
  },
  computed: {
    noData() {
      return !this.items || this.items.length <= 0;
    },
    currentItem() {
      return this.items.find((
        item: JsonData,
      ) => item.id === this.selectedItemId);
    },
    statusCurrentItem() {
      return this.currentItem.status;
    },
    selectedItemData() {
      return _.pickBy(this.currentItem, (el: string, key: string) => key !== 'id' && key !== 'status');
    },
  },
  methods: {
    openForm() {
      this.isOpenForm = true;
    },
    closeForm() {
      this.isOpenConfirmBlockModal = false;
      this.isOpenConfirmUnblockModal = false;
      this.isCreate = false;
      this.isUpdate = false;
      this.selectedItemId = null;
      this.isOpenForm = false;
    },
    openInfo(id: string) {
      this.isOpenForm = true;
      this.selectedItemId = id;
    },
    openCreateForm() {
      this.isCreate = true;
      this.openForm();
    },
    openBlockConfirmModal() {
      this.isOpenConfirmBlockModal = true;
    },
    openUnblockConfirmModal() {
      this.isOpenConfirmUnblockModal = true;
    },
    confirmCancel() {
      this.isOpenConfirmBlockModal = false;
      this.isOpenConfirmUnblockModal = false;
    },
    createHandler(data: JsonData, next: () => void) {
      this.$emit('created', data, next);
      this.closeForm();
    },
    updatedHandler(data: JsonData, next: () => void) {
      this.$emit('updated', this.selectedItemId, data, next);
      this.closeForm();
    },
    blockHandler(next: () => void) {
      this.$emit('block', this.selectedItemId);
      this.isOpenConfirm = false;
      this.closeForm();
      next();
    },
    unblockHandler(next: () => void) {
      this.$emit('unblock', this.selectedItemId);
      this.isOpenConfirm = false;
      this.closeForm();
      next();
    },
    changeModeHandler(value: boolean) {
      this.isUpdate = value;
    },
    async changePaginationHandler(pagination: PaginationParams) {
      this.$emit('pagination-changed', pagination);
    },
    changedFormDataHandler(data: JsonData) {
      this.$emit('changed-form-data', data);
    },
  },
  setup() {
    const selectedItemId = ref<string | null>(null);

    return {
      selectedItemId,
    };
  },
});
