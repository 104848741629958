import { Module } from 'vuex';
import { StateType } from '@/store';
import list, {
  ActivityDirectionListStateType,
} from '@/store/modules/admin/dictionary/activityDirection/list';
import one, {
  ActivityDirectionOneStateType,
} from '@/store/modules/admin/dictionary/activityDirection/one';

export interface ActivityDirectionModuleStateType {
  list: ActivityDirectionListStateType,
  one: ActivityDirectionOneStateType,
}

const activityDirectionModule: Module<ActivityDirectionModuleStateType, StateType> = {
  namespaced: true,
  modules: {
    list,
    one,
  },
};

export default activityDirectionModule;
