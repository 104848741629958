import {
  AxiosCallResponse,
  BaseService,
  LoggerInterface,
  QueryParamsForList,
  ResponseOfRecords,
} from '@/api/base';
import JsonData from '@/components/jsonforms/interfaces/JsonData';

export type ConflictHistoryItem = {
  id: string,
} & JsonData;

export type ConflictHistoryResponseType = AxiosCallResponse<
    ResponseOfRecords<ConflictHistoryItem[]
    >>

export default class ConflictHistoryService extends BaseService {
  constructor(token: string, logger: LoggerInterface = console) {
    super('card', token, logger, 'v2');
  }

  async list(
    cardId: string,
    params: QueryParamsForList,
  ): Promise<ConflictHistoryResponseType> {
    return this.axiosCall({ url: `/${cardId}/conflict-history`, method: 'get', params });
  }

  async create(
    cardId: string,
    data: ConflictHistoryItem,
  ): Promise<AxiosCallResponse<ConflictHistoryItem>> {
    return this.axiosCall({ url: `/${cardId}/conflict-history`, method: 'post', data });
  }

  async update(
    cardId: string,
    id: string,
    data: ConflictHistoryItem,
  ): Promise<AxiosCallResponse<null>> {
    return this.axiosCall({ url: `/${cardId}/conflict-history/${id}`, method: 'patch', data });
  }

  async delete(
    cardId: string,
    id: string,
  ): Promise<AxiosCallResponse<null>> {
    return this.axiosCall({ url: `/${cardId}/conflict-history/${id}`, method: 'delete' });
  }
}
