<template>
  <svg v-if="isSolid" :width="width" :height="height" viewBox="0 0 18 18" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.6933 3.36003L14.64 6.3067M12.9433 2.11003C13.3341
 1.71928 13.8641 1.49976 14.4167 1.49976C14.9693 1.49976
 15.4992 1.71928 15.89 2.11003C16.2808 2.50078 16.5003
 3.03076 16.5003 3.58336C16.5003 4.13597 16.2808 4.66595
 15.89 5.0567L4.41667 16.53H1.5V13.5534L12.9433 2.11003Z"
      :stroke="color"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
import { REGULAR_TYPE, SOLID_TYPE } from '@/components/ui/icons/types';

export default {
  name: 'EditIcon',
  props: {
    type: {
      type: String,
      default: SOLID_TYPE,
    },
    width: {
      type: String,
      default: '18',
    },
    height: {
      type: String,
      default: '18',
    },
    color: {
      type: String,
      default: '#64748B',
    },
  },
  computed: {
    isSolid() {
      return this.type === SOLID_TYPE;
    },
    isRegular() {
      return this.type === REGULAR_TYPE;
    },
  },
};
</script>
