
import { defineComponent, PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { JsonSchema } from '@jsonforms/core';
import CustomUISchemaElement from '@/components/jsonforms/interfaces/CustomUISchemaElement';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import { PaginationParams } from '@/api/base';
import TheListPayment
  from '@/components/counterparty/card/blocks/general/payment/TheListPayment.vue';
import fields from '@/shared/consts/counterparty/fields';
import config from '@/config';
import { useOneApi } from '@/composables/api/bank/one';

export default defineComponent({
  name: 'ThePayment',
  components: { TheListPayment },
  props: {
    title: String,
    uischema: Object as PropType<CustomUISchemaElement>,
    schema: Object as PropType<JsonSchema>,
    isCanEdit: Boolean,
  },
  data() {
    return {
      formData: {},
    };
  },
  computed: {
    ...mapGetters({
      items: 'payment/payments',
      pagination: 'payment/pagination',
      currentCounterpartyId: 'counterparty/card/currentCounterpartyId',
      counterpartyData: 'counterparty/card/data',
    }),
  },
  methods: {
    ...mapActions({
      loadItems: 'payment/loadPayments',
      changePagination: 'payment/changePagination',
      create: 'payment/addPayment',
      update: 'payment/editPayment',
      block: 'payment/blockPayment',
      unblock: 'payment/unblockPayment',
    }),
    async changePaginationHandler(pagination: PaginationParams) {
      await this.changePagination(pagination);

      await this.loadItems(this.currentCounterpartyId);
    },
    async createHandler(data: JsonData, next: () => void) {
      await this.create({
        counterpartyId: this.currentCounterpartyId,
        data,
      });
      await this.loadItems(this.currentCounterpartyId);

      next();
    },
    async updateHandler(id: string, data: JsonData, next: () => void) {
      await this.update({
        counterpartyId: this.currentCounterpartyId,
        id,
        data,
      });
      await this.loadItems(this.currentCounterpartyId);

      next();
    },
    async blockHandler(id: string) {
      await this.block({
        counterpartyId: this.currentCounterpartyId,
        id,
      });
      await this.loadItems(this.currentCounterpartyId);
    },
    async unblockHandler(id: string) {
      await this.unblock({
        counterpartyId: this.currentCounterpartyId,
        id,
      });
      await this.loadItems(this.currentCounterpartyId);
    },
    changedFormDataHandler(data: JsonData) {
      this.formData = data;
    },
  },
  watch: {
    async formData(data: JsonData, lastData: JsonData) {
      if (data) {
        const fullNameCounterparty = this.counterpartyData[fields.FULL_NAME_FIELD];

        if (data[config.PAYMENT_BLOCK_SELLER_BANK]
          && data[config.PAYMENT_BLOCK_CORRESPONDENT]
          === lastData[config.PAYMENT_BLOCK_CORRESPONDENT]) {
          const account = data[config.PAYMENT_BLOCK_BILLING_NUMBER] ?? '';

          const bank = await this.bankApi.loadData(data[config.PAYMENT_BLOCK_BANK]);

          const bankName = bank.name ?? '';
          const bankCity = bank.city ?? '';

          this.formData[config.PAYMENT_BLOCK_CORRESPONDENT] = `${fullNameCounterparty} ${account} ${bankName} ${bankCity}`;
        } else {
          this.formData[config.PAYMENT_BLOCK_CORRESPONDENT] = fullNameCounterparty;
        }
      }
    },
  },
  async created() {
    await this.loadItems(this.currentCounterpartyId);
  },
  setup() {
    const bankApi = useOneApi();

    return {
      bankApi,
    };
  },
});
