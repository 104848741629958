import _ from 'lodash';
import JsonData, { JsonDataType } from '@/components/jsonforms/interfaces/JsonData';

export const toDataPathSegments = (schemaPath: string): string[] => {
  const s = schemaPath
    .replace(/anyOf\/[\d]\//g, '')
    .replace(/allOf\/[\d]\//g, '')
    .replace(/oneOf\/[\d]\//g, '');
  const segments = s.split('/');

  const startFromRoot = segments[0] === '#' || segments[0] === '';
  const startIndex = startFromRoot ? 2 : 1;

  return _.range(startIndex, segments.length, 2).map((idx) => segments[idx]);
};

export const resolveData = (instance: JsonData, scope: string): JsonDataType => {
  if (!scope) {
    return [];
  }

  const dataPathSegments = toDataPathSegments(scope);

  return dataPathSegments
    .map((segment: string) => decodeURIComponent(segment))
    .reduce((curInstance: null | JsonDataType, decodedSegment: string) => {
      if (!curInstance || !Object.prototype.hasOwnProperty.call(curInstance, decodedSegment)) {
        return null;
      }

      return (curInstance as JsonData)[decodedSegment] as JsonDataType;
    }, instance) as JsonDataType;
};

export const getChangedData = (
  newData: JsonData,
  oldData: JsonData,
): JsonData => _.omitBy(newData, (value, key: string) => {
  if (Array.isArray(value)) {
    const oldValue: JsonData[] = oldData && oldData[key]
      ? oldData[key] as []
      : [];

    if (oldValue.length !== value.length) {
      return false;
    }

    return (value as JsonData[]).every((item: JsonData, itemKey: number) => {
      const data: JsonData = oldValue
        ? oldValue[itemKey] as JsonData
        : {};

      return !Object.keys(getChangedData(item, data)).length;
    });
  }

  if (_.isObject(value)) {
    const oldValue: JsonData = oldData && oldData[key]
      ? oldData[key] as JsonData
      : {};

    return !Object.keys(getChangedData(value, oldValue)).length;
  }

  return oldData && oldData[key] === value;
});
