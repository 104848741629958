
import { defineComponent, PropType } from 'vue';
import TheItem from '@/components/counterparty/list/modal/create/content/tabs/search/list/item/TheItem.vue';
import { CounterpartyItemType } from '@/api/mdm';
import fields from '@/shared/consts/counterparty/fields';

export default defineComponent({
  name: 'TheList',
  emits: ['selected', 'redirectToManually'],
  components: {
    TheItem,
  },
  props: {
    searchText: {
      type: String,
      default: '',
    },
    items: {
      type: Array as PropType<CounterpartyItemType[]>,
    },
  },
  methods: {
    getItemName(item: CounterpartyItemType) {
      return item[fields.NAME_FIELD] ?? '';
    },
    getAdditionalInfoByItem(item: CounterpartyItemType) {
      return [
        {
          label: this.$t('counterpartyLabels.codeMdm'),
          value: item.codeMdm ?? null,
          isFound: item.foundField === 'codeMdm',
        },
        {
          label: this.$t('counterpartyLabels.inn'),
          value: item[fields.INN_FIELD] ?? null,
          isFound: item.foundField === fields.INN_FIELD,
        },
        {
          label: this.$t('counterpartyLabels.taxNumber'),
          value: item[fields.TAX_NUMBER_FIELD] ?? null,
          isFound: item.foundField === fields.TAX_NUMBER_FIELD,
        },
        {
          label: this.$t('counterpartyLabels.kpp'),
          value: item[fields.KPP_FIELD] ?? null,
          isFound: item.foundField === fields.KPP_FIELD,
        },
        {
          label: this.$t('counterpartyLabels.legalAddress'),
          value: item[fields.LEGAL_ADDRESS_FIELD] ?? null,
          isFound: item.foundField === fields.LEGAL_ADDRESS_FIELD,
        },
        {
          label: this.$t('counterpartyLabels.actualAddress'),
          value: item[fields.ACTUAL_ADDRESS_FIELD] ?? null,
          isFound: item.foundField === fields.ACTUAL_ADDRESS_FIELD,
        },
      ];
    },
    onClick(key: number) {
      this.$emit('selected', key);
    },
    onClickToManuallyForm() {
      this.$emit('redirectToManually');
    },
  },
});
