import { defineComponent } from 'vue';

import SortArrow from '@/components/ui/icons/SortArrow.vue';

export interface TableHeaderNewsInterface {
  sortBy: string | null,
  newsAttributes: object,
}

export default defineComponent({
  name: 'TableHeader',
  data: (): TableHeaderNewsInterface => ({
    sortBy: null,
    newsAttributes: {
      publishedAt: {
        label: 'newsAttributes.publishedAt',
        isSort: true,
      },
      content: {
        label: 'newsAttributes.content',
        isSort: false,
      },
      link: {
        label: 'newsAttributes.link',
        isSort: false,
      },
      hidden: {
        label: '',
      },
    },
  }),
  components: {
    SortArrow,
  },
  emits: ['sortChanged'],
  props: {
    sort: {
      type: Object,
      default: () => ({
        sortBy: '',
      }),
    },
  },
  computed: {
    newSort() {
      return {
        sortBy: this.sortBy,
      };
    },
    sortDirection() {
      return String(this.sortBy).startsWith('-')
        ? 'desc'
        : 'asc';
    },
  },
  methods: {
    changeSort(event: { target: HTMLSpanElement }) {
      const { key } = event.target.dataset;

      if (key) {
        this.sortBy = key === this.sortBy ? `-${key}` : key;
        this.$emit('sortChanged', this.newSort);
      }
    },
    isActiveSort(name: string) {
      return String(this.sortBy).includes(name);
    },
    getSortDirection(name: string) {
      return this.isActiveSort(name)
        ? this.sortDirection
        : null;
    },
    getFillOpacity(name: string) {
      return this.isActiveSort(name)
        ? 1
        : 0.5;
    },
  },
});
