
import { defineComponent, PropType } from 'vue';
import config from '@/config';
import BriefcaseIcon from '@/components/ui/icons/BriefcaseIcon.vue';
import EmailIcon from '@/components/ui/icons/EmailIcon.vue';
import PhoneIcon from '@/components/ui/icons/PhoneIcon.vue';
import BuildingIcon from '@/components/ui/icons/BuildingIcon.vue';
import RightArrowIcon from '@/components/ui/icons/RightArrowIcon.vue';
import { UserDetailData } from '@/api/admin/user';
import { DivisionItem, StructureItem } from '@/api/admin/dictionary/structure';
import TreeHelper, { TreeItemResponse } from '@/utils/helpers/TreeHelper';

export default defineComponent({
  name: 'ViewForm',
  components: {
    BriefcaseIcon,
    EmailIcon,
    PhoneIcon,
    RightArrowIcon,
    BuildingIcon,
  },
  props: {
    user: {
      type: Object as PropType<UserDetailData>,
      required: true,
    },
    divisions: {
      type: Array as PropType<DivisionItem[]>,
      default: () => ([]),
    },
    departments: {
      type: Array as PropType<StructureItem[]>,
      default: () => ([]),
    },
    isCanEdit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    division(): string {
      const division = this.getDivisionById(this.user.division);

      return division?.name ?? '';
    },
    department(): string {
      const structure = this.getStructureById(this.user.department);

      return structure?.name ?? '';
    },
    isBlockedStatus(): boolean {
      return this.user.status === config.STATUS_USER_IS_BLOCKED;
    },
  },
  methods: {
    getDivisionById(id: string): DivisionItem | null {
      return this.divisions.find((item: DivisionItem) => item.id === id) ?? null;
    },
    getStructureById(id: string): TreeItemResponse<StructureItem> | null {
      return TreeHelper.getItemById<StructureItem>(id, this.departments);
    },
  },
});
