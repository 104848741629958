
import { defineComponent, PropType } from 'vue';
import DateIcon from '@/components/ui/icons/DateIcon.vue';
import OutsideClick from '@/utils/directives/OutsideClick';
import { DateTime } from 'luxon';
import config from '@/config';
import DatepickerLayout from '@/components/ui/datepickers/datepicker-period/layouts/DatepickerLayout.vue';
import CalculatePosition from '@/utils/directives/CalculatePosition';

export interface DatepickerPeriodType {
  beforeDate?: string,
  afterDate?: string,
}

export interface PeriodDateTimeType {
  startDate?: DateTime | null,
  endDate?: DateTime | null,
}

export default defineComponent({
  components: {
    DatepickerLayout, DateIcon,
  },
  emits: ['change', 'blur'],
  name: 'DatepickerPeriod',
  props: {
    value: {
      type: Object as PropType<DatepickerPeriodType | null>,
      default: null,
    },
    format: {
      type: String,
      default: config.DATE_FORMAT,
    },
    autoclose: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
    error: {
      type: Boolean,
      default: false,
    },
    calculatePosition: {
      type: [Boolean, Object],
      default: () => ({
        extraHeight: 0,
      }),
    },
  },
  data() {
    return {
      startInputDate: null,
      endInputDate: null,
      startDateValue: null,
      endDateValue: null,
      isOpen: false,
    };
  },
  computed: {
    resultDate(): DatepickerPeriodType {
      return {
        beforeDate: this.startDateValue?.toFormat(config.DATE_FORMAT_API) ?? null,
        afterDate: this.endDateValue?.toFormat(config.DATE_FORMAT_API) ?? null,
      };
    },
    dateObject(): PeriodDateTimeType {
      return {
        startDate: this.startDateValue,
        endDate: this.endDateValue,
      };
    },
  },
  methods: {
    open() {
      if (!this.disabled) {
        this.isOpen = true;
      }
    },
    close() {
      this.isOpen = false;
      this.$emit('blur', this.resultDate);
    },
    changeStartInputHandler() {
      this.startDateValue = DateTime.fromFormat(this.startInputDate, config.DATE_FORMAT);

      if (!this.startDateValue.isValid) {
        this.startDateValue = null;
        this.endDateValue = null;
        this.startInputDate = null;
        this.endInputDate = null;
      }

      this.$emit('change', this.resultDate);
    },
    changeEndInputHandler() {
      this.endDateValue = DateTime.fromFormat(this.endInputDate, config.DATE_FORMAT);

      if (!this.endDateValue.isValid) {
        this.endDateValue = null;
        this.endInputDate = null;
      }

      this.$emit('change', this.resultDate);
    },
    changeDateHandler(data: PeriodDateTimeType) {
      this.startDateValue = data.startDate;
      this.endDateValue = data.endDate;
      this.startInputDate = this.startDateValue?.toFormat(this.format);
      this.endInputDate = this.endDateValue?.toFormat(this.format);

      if (
        this.autoclose
        && this.startDateValue
        && this.endDateValue
      ) {
        this.close();
      }

      this.emitDate();
    },
    emitDate() {
      if (this.startDateValue && this.endDateValue) {
        if (this.startDateValue > this.endDateValue) {
          [this.startDateValue, this.endDateValue] = [this.endDateValue, this.startDateValue];

          this.startInputDate = this.startDateValue?.toFormat(this.format);
          this.endInputDate = this.endDateValue?.toFormat(this.format);
        }
      }

      this.$emit('change', this.resultDate);
    },
  },
  directives: {
    OutsideClick,
    CalculatePosition,
  },
  watch: {
    value(date: DatepickerPeriodType) {
      if (date.beforeDate) {
        this.startDateValue = DateTime.fromISO(date.beforeDate);
        this.startInputDate = this.startDateValue.toFormat(this.format);
      }

      if (date.afterDate) {
        this.endDateValue = DateTime.fromISO(date.afterDate);
        this.endInputDate = this.endDateValue.toFormat(this.format);
      }
    },
  },
  mounted() {
    if (this.value?.beforeDate) {
      this.startDateValue = DateTime.fromISO(this.value.beforeDate);
      this.startInputDate = this.startDateValue.toFormat(this.format);
    }

    if (this.value?.afterDate) {
      this.endDateValue = DateTime.fromISO(this.value.afterDate);
      this.endInputDate = this.endDateValue.toFormat(this.format);
    }
  },
});
