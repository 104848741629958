<template>
  <the-modal width="512px"
             :add-button-text="approveButtonText"
             :is-open="isOpen"
  >
    <div class="remove-modal-content" :class="this.$sizeWindow.isMd ? 'modal-centered' : ''">
      <div>
        <div class="danger-icon">
          <danger-icon/>
        </div>
      </div>

      <div class="modal-content-text">
        <div class="info">
          {{ text }}
        </div>

        <div v-if="!!hintText" class="hint">
          {{ hintText }}
        </div>
      </div>
    </div>
  </the-modal>
</template>

<script>
import { defineComponent } from 'vue';
import DangerIcon from '@/components/ui/icons/DangerIcon.vue';
import TheModal from '@/components/ui/modal/TheModal/TheModal.vue';

export default defineComponent({
  name: 'TheDangerModal',
  inject: ['$sizeWindow'],
  components: {
    DangerIcon,
    TheModal,
  },
  props: {
    approveButtonText: {
      type: String,
      default: 'Да',
    },
    text: {
      type: String,
      default: '',
    },
    hintText: {
      type: String,
      default: '',
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style scoped lang="scss">
@import "src/components/ui/consts.scss";
$panel-height: 200px;

.danger-icon {
  @apply w-[48px] h-[48px] flex justify-center items-center rounded-full;
  background-color: $danger-background;
}

.remove-modal-content {
  @apply p-[24px] flex flex-col items-center space-y-[12px];
}

.modal-centered {
  margin-top: calc(50vh - $panel-height);
}

.modal-content-text {
  @apply text-center space-y-[8px];

  & .info {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  }

  & .hint {
    color: $text-gray;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }
}
</style>
