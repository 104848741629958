import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-61597ff0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = { class: "table" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_header = _resolveComponent("the-header")!
  const _component_the_hierarchy_body = _resolveComponent("the-hierarchy-body")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      _createVNode(_component_the_header, {
        class: "!hidden sm:!table",
        fields: _ctx.fields,
        sort: _ctx.sort,
        variant: _ctx.variant
      }, null, 8, ["fields", "sort", "variant"]),
      _createVNode(_component_the_hierarchy_body, {
        items: _ctx.items,
        fields: _ctx.fields,
        variant: _ctx.variant,
        "is-all-open": _ctx.isAllOpen,
        onItemClick: _ctx.bodyItemClickHandler
      }, null, 8, ["items", "fields", "variant", "is-all-open", "onItemClick"])
    ])
  ]))
}