import { Module } from 'vuex';
import list, { RoleListStateType } from '@/store/modules/admin/role/list';
import { StateType } from '@/store';

export interface RoleStateType {
  list: RoleListStateType,
}

const roleModule: Module<RoleStateType, StateType> = {
  namespaced: true,
  modules: {
    list,
  },
};

export default roleModule;
