import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ce3ebfe0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = { class: "vertical-table" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_item = _resolveComponent("the-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (field) => {
        return (_openBlock(), _createElementBlock("tr", {
          key: field.key
        }, [
          _createElementVNode("th", null, _toDisplayString(field.label), 1),
          _createVNode(_component_the_item, {
            field: field,
            item: _ctx.item
          }, null, 8, ["field", "item"])
        ]))
      }), 128))
    ])
  ]))
}