
import { defineComponent } from 'vue';
import IconButton from '@/components/ui/buttons/IconButton/IconButton.vue';
import TrashIcon from '@/components/ui/icons/TrashIcon.vue';

export default defineComponent({
  name: 'DeleteAction',
  components: {
    IconButton,
    TrashIcon,
  },
  props: {
    itemId: {
      type: String,
      default: '',
    },
    isAbsolute: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['clickHandler'],
  methods: {
    clickHandler() {
      this.$emit('clickHandler');
    },
  },
});
