
import { defineComponent, PropType } from 'vue';
import DefaultButton from '@/components/ui/buttons/DefaultButton/DefaultButton.vue';
import TheForm, { StructureForm } from '@/components/admin/dictionary/structure/form/TheForm.vue';
import { StructureItem } from '@/api/admin/dictionary/structure';
import { CompanyItem } from '@/api/admin/dictionary/company';

export const TYPE_ADDING_CHILD = 'child';
export const TYPE_ADDING_PARENT = 'parent';

export default defineComponent({
  name: 'TheCreate',
  inject: ['$sizeWindow'],
  emits: ['cancel', 'create'],
  components: {
    DefaultButton,
    TheForm,
  },
  props: {
    type: {
      type: String,
      default: 'parent',
    },
    companies: {
      type: Object as PropType<CompanyItem[]>,
      default: () => ({}),
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    isChildAdd() {
      return this.type === TYPE_ADDING_CHILD;
    },
    isParentAdd() {
      return this.type === TYPE_ADDING_PARENT;
    },
  },
  methods: {
    cancelHandler() {
      this.$emit('cancel');
    },
    createHandler() {
      this.isLoading = true;

      const formRef: StructureForm = this.$refs.form as StructureForm;
      const valid: boolean = formRef?.validateForm(true) ?? false;
      const data: StructureItem | null = formRef?.currentData ?? null;

      if (!valid || !data) {
        this.isLoading = false;

        return false;
      }

      this.$emit('create', data, () => {
        this.isLoading = false;
      });

      return true;
    },
  },
});
