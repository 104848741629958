import {
  AxiosCallResponse,
  BaseService, FilterParams,
  LoggerInterface, PaginationParams,
  QueryParamsForList,
  ResponseOfRecords, SortParams,
} from '@/api/base';

export interface PinningItem {
  id?: string,
  counterparty?: string,
  user: string,
  division: string,
  department: string,
}

export interface PinningDetachItem {
  comment: string,
  detachmentDate: string,
}

export interface PinningReassignItem {
  user: string,
  department: string,
  comment: string,
}

export interface AssignDetailItem {
  id: string,
}

export interface AssignManager {
  id: string,
  name: string,
  company: string,
  department: string,
  division: string,
  email: string,
  phone: string,
  vacancy: string,
  counterpartyType: string,
}

export interface AssignFilterParams extends FilterParams {
  divisionId?: string | null,
  departmentId?: string | null,
  userId?: string | null,
}

export interface AssignManagerFilterParams {
  query?: string,
  divisionId?: string,
  departmentId?: string,
}

export type PinningResponseType = AxiosCallResponse<ResponseOfRecords<PinningItem[]>>;

export type AssignQueryForList = PaginationParams | AssignFilterParams | SortParams;

export default class PinningService extends BaseService {
  constructor(token: string, logger: LoggerInterface = console) {
    super('admin/assign', token, logger);
  }

  async list(params: QueryParamsForList = {}): Promise<PinningResponseType> {
    return this.axiosCall({ method: 'get', params });
  }

  async getDetailInfo(id: string): Promise<AxiosCallResponse<AssignDetailItem>> {
    return this.axiosCall({ method: 'get', url: `/${id}` });
  }

  async create(data: PinningItem): Promise<AxiosCallResponse<PinningItem>> {
    return this.axiosCall({ method: 'post', data });
  }

  async detach(id: string, data: PinningDetachItem): Promise<AxiosCallResponse<null>> {
    return this.axiosCall({ url: `/${id}/detach`, method: 'post', data });
  }

  async reassign(id: string, data: PinningReassignItem): Promise<AxiosCallResponse<null>> {
    return this.axiosCall({ url: `/${id}/reassign`, method: 'post', data });
  }

  async managers(
    params: AssignManagerFilterParams,
  ): Promise<AxiosCallResponse<ResponseOfRecords<AssignManager>>> {
    return this.axiosCall({ url: '/managers', method: 'get', params });
  }
}
