import { ref } from 'vue';

export default function useBlockScroll() {
  const hasClass = ref(false);

  return {
    toggleClass: (value: boolean) => {
      const addClass = () => {
        if (document.body.classList.contains('scroll-block')) {
          hasClass.value = true;
        } else {
          document.body.classList.add('scroll-block');
        }
      };

      const removeClass = () => {
        if (!hasClass.value) {
          document.body.classList.remove('scroll-block');
        }
      };

      if (value) {
        addClass();
      } else {
        removeClass();
      }
    },
  };
}
