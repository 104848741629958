import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createBlock as _createBlock, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4827f8b7"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "tab-menu",
  ref: "tabMenu"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tab_button = _resolveComponent("tab-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["animation-element", _ctx.sizeClass]),
      style: _normalizeStyle(`width:${_ctx.elementWidth}px; left:${_ctx.leftOffsetElement}px`)
    }, null, 6),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, name) => {
      return (_openBlock(), _createBlock(_component_tab_button, {
        key: name,
        active: String(_ctx.currentGroup) === String(item.name),
        "data-link": item.name,
        onClick: _ctx.changeGroup
      }, {
        default: _withCtx(() => [
          (!!item.content)
            ? (_openBlock(), _createBlock(_resolveDynamicComponent(item.content), _normalizeProps(_mergeProps({ key: 0 }, item.props)), null, 16))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(item.label), 1)
        ]),
        _: 2
      }, 1032, ["active", "data-link", "onClick"]))
    }), 128))
  ], 512))
}