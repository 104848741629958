import { Module } from 'vuex';
import list, { UserListStateType } from '@/store/modules/admin/user/list';
import one, { UserOneStateType } from '@/store/modules/admin/user/one';
import { StateType } from '@/store';

export interface UserStateType {
  list: UserListStateType,
  one: UserOneStateType,
}

const userModule: Module<UserStateType, StateType> = {
  namespaced: true,
  modules: {
    list,
    one,
  },
};

export default userModule;
