import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, resolveDynamicComponent as _resolveDynamicComponent, toHandlers as _toHandlers, mergeProps as _mergeProps, KeepAlive as _KeepAlive, normalizeClass as _normalizeClass, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "!p-[0] !w-[24px]"
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = {
  key: 1,
  class: "!p-[0] !w-[24px] md:!w-[24px]"
}
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_select_arrow = _resolveComponent("select-arrow")!
  const _component_icon_button = _resolveComponent("icon-button")!
  const _component_the_item = _resolveComponent("the-item", true)!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("tr", {
      class: "table-item",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clickItem($event)))
    }, [
      (_ctx.isExpand)
        ? (_openBlock(), _createElementBlock("td", _hoisted_1))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredFields, (field, index) => {
        return (_openBlock(), _createElementBlock("td", {
          key: `body-td-${index}`,
          style: _normalizeStyle(field.width ? `width: ${field.width}px;` : '')
        }, [
          (_ctx.isLink(_ctx.item, field))
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: _ctx.item[field.key]?.link,
                class: "primary-color"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.item[field.key].text), 1)
                ]),
                _: 2
              }, 1032, ["to"]))
            : (_ctx.isComponent(_ctx.item, field))
              ? (_openBlock(), _createBlock(_KeepAlive, { key: 1 }, [
                  (_ctx.item[field.key].isVisible !== false)
                    ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.item[field.key].component), _mergeProps({ key: 0 }, _ctx.item[field.key].props, _toHandlers(_ctx.item[field.key].events ?? {})), null, 16))
                    : _createCommentVNode("", true)
                ], 1024))
              : (_openBlock(), _createElementBlock("span", {
                  key: 2,
                  class: "flex items-center relative",
                  style: _normalizeStyle(_ctx.addOffset(index))
                }, [
                  (_ctx.isShowToggleButton(index))
                    ? (_openBlock(), _createBlock(_component_icon_button, {
                        key: 0,
                        class: "toggle-button",
                        onClick: _withModifiers(_ctx.toggleList, ["stop"])
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_select_arrow, {
                            class: _normalizeClass(_ctx.isOpenArrow)
                          }, null, 8, ["class"])
                        ]),
                        _: 1
                      }, 8, ["onClick"]))
                    : _createCommentVNode("", true),
                  _createElementVNode("span", {
                    class: _normalizeClass(["element-name", _ctx.addNameOffset(index)]),
                    innerHTML: _ctx.item[field.key] ?? ''
                  }, null, 10, _hoisted_2)
                ], 4))
        ], 4))
      }), 128)),
      (_ctx.isExpand)
        ? (_openBlock(), _createElementBlock("td", _hoisted_3))
        : _createCommentVNode("", true)
    ]),
    (_ctx.isOpenItems)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.items, (item) => {
            return (_openBlock(), _createBlock(_component_the_item, _mergeProps({
              item: item,
              key: item.id,
              variant: _ctx.variant,
              fields: _ctx.fields,
              offset: _ctx.offset + 15,
              "is-all-open": _ctx.isAllOpen
            }, _ctx.$attrs), null, 16, ["item", "variant", "fields", "offset", "is-all-open"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}