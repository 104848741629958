import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_table_block_template = _resolveComponent("table-block-template")!

  return (_openBlock(), _createBlock(_component_table_block_template, {
    title: _ctx.title,
    items: _ctx.preparedFormItems,
    "prepared-items": _ctx.preparedItems,
    fields: _ctx.fields,
    schema: _ctx.schema,
    uischema: _ctx.uischema,
    sort: _ctx.sort,
    "is-can-edit": false,
    pagination: _ctx.pagination,
    onChangePagination: _ctx.changePaginationHandler,
    onChangeSort: _ctx.changeSortHandler
  }, null, 8, ["title", "items", "prepared-items", "fields", "schema", "uischema", "sort", "pagination", "onChangePagination", "onChangeSort"]))
}