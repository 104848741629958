import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    width: _ctx.width,
    height: _ctx.height,
    viewBox: "0 0 16 16",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566\n          15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315\n          13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855\n          3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217\n          0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827\n          16 8ZM9 12C9 12.2652 8.89464 12.5196 8.70711 12.7071C8.51957\n          12.8946 8.26522 13 8 13C7.73478 13 7.48043 12.8946 7.29289\n          12.7071C7.10536 12.5196 7 12.2652 7 12C7 11.7348 7.10536\n          11.4804 7.29289 11.2929C7.48043 11.1054 7.73478 11 8 11C8.26522\n          11 8.51957 11.1054 8.70711 11.2929C8.89464 11.4804 9 11.7348\n          9 12ZM8 3C7.73478 3 7.48043 3.10536 7.29289 3.29289C7.10536\n          3.48043 7 3.73478 7 4V8C7 8.26522 7.10536 8.51957 7.29289\n          8.70711C7.48043 8.89464 7.73478 9 8 9C8.26522 9 8.51957\n          8.89464 8.70711 8.70711C8.89464 8.51957 9 8.26522 9 8V4C9\n          3.73478 8.89464 3.48043 8.70711 3.29289C8.51957 3.10536 8.26522 3 8 3Z",
      fill: _ctx.color
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}