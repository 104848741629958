import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_right_arrow = _resolveComponent("right-arrow")!
  const _component_icon_button = _resolveComponent("icon-button")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: _ctx.link,
    onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_icon_button, { description: "Перейти в карточку" }, {
        default: _withCtx(() => [
          _createVNode(_component_right_arrow)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["to"]))
}