import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "bg-gray-50" }
const _hoisted_2 = {
  key: 0,
  class: "!p-[0] !w-[24px]"
}
const _hoisted_3 = { class: "w-[220px] lg:w-auto" }
const _hoisted_4 = ["data-key", "data-not-sortable"]
const _hoisted_5 = {
  key: 1,
  class: "!p-[0] w-[24px]"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sort_arrow = _resolveComponent("sort-arrow")!

  return (_openBlock(), _createElementBlock("thead", null, [
    _createElementVNode("tr", _hoisted_1, [
      (_ctx.isExpand)
        ? (_openBlock(), _createElementBlock("th", _hoisted_2))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredFields, (item, index) => {
        return (_openBlock(), _createElementBlock("th", {
          key: `thead-th-${index}`,
          style: _normalizeStyle(item.width ? `width: ${item.width}px;` : '')
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", {
              class: _normalizeClass({ 'cursor-pointer': !item.notSortable }),
              "data-key": item.key,
              "data-not-sortable": Number(!!item.notSortable),
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.changeSort && _ctx.changeSort(...args)))
            }, [
              _createTextVNode(_toDisplayString(item.label) + " ", 1),
              (!item.notSortable)
                ? (_openBlock(), _createBlock(_component_sort_arrow, {
                    key: 0,
                    "data-key": item.key,
                    direction: _ctx.getSortDirection(item.key),
                    "fill-opacity": _ctx.getFillOpacity(item.key)
                  }, null, 8, ["data-key", "direction", "fill-opacity"]))
                : _createCommentVNode("", true)
            ], 10, _hoisted_4)
          ])
        ], 4))
      }), 128)),
      (_ctx.isExpand)
        ? (_openBlock(), _createElementBlock("th", _hoisted_5))
        : _createCommentVNode("", true)
    ])
  ]))
}