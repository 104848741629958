import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    width: _ctx.width,
    height: _ctx.height,
    viewBox: "0 0 18 19",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      d: "M12 0V4V6V8H10L9 5L5 8L4 5L0 8L0 19H18V0L12 0ZM14 2H16V4H14V2ZM14\n 6H16V17H14V16H12V17H10V16H8V17H6V16H4V17H2V9L2.98047 8.26562L3.10352\n 8.63281L3.98047 11.2656L6.19922 9.59961L7.98047 8.26562L8.10352\n 8.63281L8.55859 10H10H12H14V8V6ZM4 12V14H6V12H4ZM8\n 12V14H10V12H8ZM12 12V14H14V12H12Z",
      fill: _ctx.color
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}