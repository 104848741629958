import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_button_group = _resolveComponent("button-group")!
  const _component_role_block = _resolveComponent("role-block")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_button_group, {
      class: "mb-[24px]",
      items: _ctx.groups,
      onGroupChanged: _ctx.groupChangedHandler
    }, null, 8, ["items", "onGroupChanged"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentGroupItems, (block) => {
      return (_openBlock(), _createBlock(_component_role_block, {
        key: block.id,
        block: block,
        "user-roles": _ctx.userRoles,
        disabled: _ctx.disabled,
        "roles-attributes": _ctx.rolesAttributes,
        departments: _ctx.departments,
        onUpdated: _ctx.updatedHandler,
        onUpdateRoleAttributes: _ctx.updateRoleAttributesHandler
      }, null, 8, ["block", "user-roles", "disabled", "roles-attributes", "departments", "onUpdated", "onUpdateRoleAttributes"]))
    }), 128))
  ]))
}