import { Module } from 'vuex';
import one, { NomenclatureGroupOneStateType } from '@/store/modules/admin/dictionary/nomenclature-group/one';
import list, { NomenclatureGroupListStateType } from '@/store/modules/admin/dictionary/nomenclature-group/list';
import { StateType } from '@/store';

export interface NomenclatureGroupModuleStateType {
  list: NomenclatureGroupListStateType,
  one: NomenclatureGroupOneStateType,
}

const nomenclatureGroupModule: Module<NomenclatureGroupModuleStateType, StateType> = {
  namespaced: true,
  modules: {
    list,
    one,
  },
};

export default nomenclatureGroupModule;
