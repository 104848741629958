import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-61e3878c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-col text-[12px] sm:text-[14px]" }
const _hoisted_2 = { class: "flex justify-between mb-[24px]" }
const _hoisted_3 = { class: "item-header" }
const _hoisted_4 = { class: "flex flex-col" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "item-header" }
const _hoisted_7 = { class: "w-full flex flex-col md:flex-row md:space-x-[12px]" }
const _hoisted_8 = { class: "w-full sm:w-1/2" }
const _hoisted_9 = { class: "sub-header" }
const _hoisted_10 = { class: "w-full sm:w-1/2" }
const _hoisted_11 = { class: "sub-header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_delete_action = _resolveComponent("delete-action")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('history.detail.main')), 1),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", null, _toDisplayString(_ctx.data.createdAt), 1),
          _createElementVNode("div", null, _toDisplayString(_ctx.data.createdBy), 1)
        ])
      ]),
      (_ctx.isProcessing)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_delete_action, {
              "is-absolute": false,
              onClick: _ctx.clickHandler
            }, null, 8, ["onClick"])
          ]))
        : _createCommentVNode("", true)
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.changes, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        key: item.name,
        class: "mb-[24px]"
      }, [
        _createElementVNode("div", _hoisted_6, _toDisplayString(item.name), 1),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('history.detail.newValue')), 1),
            _createElementVNode("div", null, _toDisplayString(_ctx.getNewValue(item) ?? _ctx.$t('common.noData')), 1)
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('history.detail.oldValue')), 1),
            _createElementVNode("div", null, _toDisplayString(_ctx.getOldValue(item) ?? _ctx.$t('common.noData')), 1)
          ])
        ])
      ]))
    }), 128))
  ]))
}