import { computed, reactive } from 'vue';
import useUserAccess, { UserAccess } from '@/composables/useUserAccess';

const userAccess = computed<UserAccess>(() => (useUserAccess()));

export interface PermissionType {
  counterparty: {
    canEditMdmData: boolean,
  }
  dictionary: {
    canViewRoles: boolean,
    canViewAssign: boolean,
    canViewCompany: boolean,
    canEditAssign: boolean,
    canEditUser: boolean,
    canEditCountry: boolean,
    canEditStructure: boolean,
    canEditCategory: boolean,
    canEditBank: boolean,
    canEditIndustry: boolean,
    canEditBankAccount: boolean,
    canEditPsychologicalCharacteristic: boolean,
    canEditNomenclatureGroup: boolean,
    canEditTechnologicalSupportType: boolean,
    canEditActivityDirection: boolean,
    canEditPosition: boolean,
    canEditEducation: boolean,
    canEditIndividual: boolean,
    canEditRegion: boolean,
  },
}

export default function useCheckPermissions() {
  return reactive<PermissionType>({
    counterparty: {
      canEditMdmData: userAccess.value.editor.isMainEditor || userAccess.value.admin.isSuperAdmin,
    },
    dictionary: {
      canViewRoles: userAccess.value.dictionary.viewer.userRoles
          || userAccess.value.admin.isSuperAdmin,
      canViewAssign: userAccess.value.dictionary.viewer.pinning
          || userAccess.value.admin.isSuperAdmin,
      canViewCompany: userAccess.value.admin.isSuperAdmin,
      canEditAssign: userAccess.value.dictionary.editor.pinning
          || userAccess.value.admin.isSuperAdmin,
      canEditUser: userAccess.value.dictionary.editor.user
          || userAccess.value.admin.isSuperAdmin,
      canEditCountry: userAccess.value.admin.isSuperAdmin
          || userAccess.value.dictionary.editor.country,
      canEditStructure: userAccess.value.dictionary.editor.structure
          || userAccess.value.admin.isSuperAdmin,
      canEditCategory: userAccess.value.dictionary.editor.counterpartyCategory
          || userAccess.value.admin.isSuperAdmin,
      canEditBank: userAccess.value.dictionary.editor.bank
          || userAccess.value.admin.isSuperAdmin,
      canEditIndustry: userAccess.value.dictionary.editor.industry
          || userAccess.value.admin.isSuperAdmin,
      canEditBankAccount: userAccess.value.dictionary.editor.bankAccount
          || userAccess.value.admin.isSuperAdmin,
      canEditActivityDirection: userAccess.value.dictionary.editor.activityDirection
        || userAccess.value.admin.isSuperAdmin,
      canEditPsychologicalCharacteristic: userAccess.value.admin.isSuperAdmin,
      canEditNomenclatureGroup: userAccess.value.admin.isSuperAdmin,
      canEditTechnologicalSupportType: userAccess.value.admin.isSuperAdmin
        || userAccess.value.admin.isAdminCompany
        || userAccess.value.admin.isAdminDivision,
      canEditPosition: userAccess.value.dictionary.editor.position
        || userAccess.value.admin.isSuperAdmin,
      canEditEducation: userAccess.value.dictionary.editor.education
        || userAccess.value.admin.isSuperAdmin,
      canEditIndividual: userAccess.value.dictionary.editor.individual
        || userAccess.value.admin.isSuperAdmin,
      canEditRegion: userAccess.value.dictionary.editor.region
          || userAccess.value.admin.isSuperAdmin,
    },
  });
}
