import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-35e669bc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "list" }
const _hoisted_2 = { class: "list-title !mb-[8px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_item = _resolveComponent("the-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('counterparty.list.all')), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
      return (_openBlock(), _createBlock(_component_the_item, {
        key: index,
        "search-text": _ctx.searchText,
        name: _ctx.getItemName(item),
        "additional-info": _ctx.getAdditionalInfoByItem(item),
        "is-priority": item.isPriority,
        onClick: ($event: any) => (_ctx.onClick(index))
      }, null, 8, ["search-text", "name", "additional-info", "is-priority", "onClick"]))
    }), 128)),
    _createElementVNode("div", {
      class: "link-item",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClickToManuallyForm && _ctx.onClickToManuallyForm(...args)))
    }, _toDisplayString(_ctx.$t('counterparty.list.linkToManuallyForm')), 1)
  ]))
}