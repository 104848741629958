import _ from 'lodash';

import { Module } from 'vuex';
import i18n from '@/i18n';
import { StateType } from '@/store';
import { AxiosCallResponse, NullableResponseType } from '@/api/base';
import { TYPE_LABEL_SUCCESS } from '@/components/ui/notifications/TheAlert/TheAlert.vue';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import AgentService, { AgentResponseType, AgentSchema } from '@/api/agent';

export interface AgentOneStateType {
  data: JsonData | null,
  currentAgentId: string | null,
}

const initialState = {
  data: null,
  currentAgentId: null,
};

const agentOneModule: Module<AgentOneStateType, StateType> = {
  namespaced: true,
  state: _.cloneDeep(initialState),
  getters: {
    data: (state): JsonData | null => state.data,
    currentAgentId: (state): string => state.currentAgentId ?? '',
  },
  mutations: {
    setData(state, data) {
      state.data = { ...data };
    },
    setCurrentAgentId(state, currentUserId: string) {
      state.currentAgentId = currentUserId;
    },
  },
  actions: {
    async loadData({ rootGetters, commit }, params: {
      cardId: string,
      id: string,
    }): Promise<AgentSchema | null> {
      const { id, cardId } = params;

      const api: AgentService = rootGetters.$api.agent;

      const response: AgentResponseType = await api.one(id, cardId);

      commit('setCurrentAgentId', id);
      commit('setData', response.data ?? initialState.data);

      return response.data ?? initialState.data;
    },
    async create({ rootGetters }, params: {
      cardId: string,
      data: JsonData,
    }): Promise<AgentSchema | null> {
      const { cardId, data } = params;

      if (cardId) {
        const api: AgentService = rootGetters.$api.agent;

        const response: AgentResponseType = await api.create(cardId, data);

        if (!response.error) {
          return response.data ?? null;
        }
      }

      return null;
    },
    async update({ rootGetters, state }, params: {
      id: string,
      cardId: string,
      data: JsonData,
    }) {
      const { id, cardId, data } = params;

      const currentAgentId: string | null = id ?? state.currentAgentId;

      if (cardId && currentAgentId) {
        const api: AgentService = rootGetters.$api.agent;

        const response: AxiosCallResponse<null> = await api.update(
          currentAgentId,
          cardId,
          data,
        );

        if (!response.error) {
          await rootGetters
            .$notificationLogger
            .setNotification(
              TYPE_LABEL_SUCCESS,
              i18n.global.t('notification.agents.success.update'),
            );
        }
      }
    },
    async block({ state, rootGetters }, params: {
      id: string,
      cardId: string,
    }) {
      const { id, cardId } = params;

      const currentAgentId: string | null = id ?? state.currentAgentId;

      if (cardId && currentAgentId) {
        const api: AgentService = rootGetters.$api.agent;
        const response: NullableResponseType = await api.block(currentAgentId, cardId);

        if (!response.error) {
          await rootGetters
            .$notificationLogger
            .setNotification(
              TYPE_LABEL_SUCCESS,
              i18n.global.t('notification.agents.success.block'),
            );
        }
      }
    },
    async unblock({ state, rootGetters }, params: {
      id: string,
      cardId: string,
    }) {
      const { id, cardId } = params;

      const currentAgentId: string | null = id ?? state.currentAgentId;

      if (cardId && currentAgentId) {
        const api: AgentService = rootGetters.$api.agent;
        const response: NullableResponseType = await api.unblock(currentAgentId, cardId);

        if (!response.error) {
          await rootGetters
            .$notificationLogger
            .setNotification(
              TYPE_LABEL_SUCCESS,
              i18n.global.t('notification.agents.success.unblock'),
            );
        }
      }
    },
  },
};

export default agentOneModule;
