<template>
  <svg viewBox="0 0 24 24" :height="height" :width="width"
       focusable="false">
    <path fill="none"
          stroke-linejoin="round"
          stroke-linecap="round"
          stroke-width="2"
          :stroke="color"
          d="M8 6h13M8 12h13M8 18h13M3 6h.01M3 12h.01M3 18h.01"></path>
  </svg>
</template>

<script>
import iconsMixin from '@/components/ui/utils/mixins/icons';

export default {
  name: 'ListIcon',
  mixins: [iconsMixin],
};
</script>

<style scoped>

</style>
