const fields = Object.freeze({
  NAME_FIELD: '5587a4fe-fb06-44b3-b394-2b527d37cf0a',
  FULL_NAME_FIELD: '4227b959-c36e-4e07-8d69-58987efaee57',
  TYPE_FIELD: '7a36adca-7e51-458a-bb18-6b9c1c1b76b3',
  VIEW_FIELD: '653d863b-87ca-46e3-9db1-87fcd8fe711f',
  INDUSTRY_FIELD: '514d843a-0727-4aa7-8769-da368c531a02',
  COUNTRY_FIELD: '371681f7-dd15-4827-9537-49f241b97e90',
  LEGAL_ADDRESS_FIELD: 'a408da76-6990-417c-b935-367a4d4d6442',
  ACTUAL_ADDRESS_FIELD: 'c802d785-0d0a-4aad-b0cd-98641ffaaa05',
  TAX_NUMBER_FIELD: '8d892d3e-b319-477e-80d3-ccaa4ebcfe72',
  INN_FIELD: '665db3ce-f933-4205-8e96-23f9c597f2c6',
  KPP_FIELD: 'ac1c469a-11b7-4d64-bae1-ab19321922fc',
  REG_NUMBER: 'bd47ea43-5907-4ae8-8fed-823ed6b1a3b0',
  IN_HOLDING: 'a689697a-88c3-488c-9528-c64315ac80b6',
  HEAD_COMPANY: 'a03982aa-25cf-4f64-bcea-ff1212148601',
  IS_HEAD_COMPANY: '8c6ddb0c-24db-4d08-8584-23a91ae3d2f3',
  HAVENT_OWN_VOLUME: '062c2971-32fa-4d2b-9192-d754dc9305be',
  OWN_DEVELOPED_CLIENT: '3a7e15d7-f353-45fa-9b6d-a0f2821d930a',
  MARKET_STATUS: '0df4819d-23f4-4093-80ab-d4726bb2cb84',
});

export default fields;
