import { entry as financialTableRendererEntry } from './FinancialTable/FinancialTableRenderer.vue';
import { entry as generalInformationRendererEntry } from './GeneralInformation/GeneralInformationRenderer.vue';
import { entry as tableOfMonthsRendererEntry } from './Tables/TableOfMonthsRenderer.vue';
import { entry as verticalTableRendererEntry } from './Tables/VerticalTableRenderer.vue';

export { default as FinancialTableRenderer } from './FinancialTable/FinancialTableRenderer.vue';
export { default as GeneralInformationRenderer } from './GeneralInformation/GeneralInformationRenderer.vue';
export { default as TableOfMonthsRenderer } from './Tables/TableOfMonthsRenderer.vue';
export { default as VerticalTableRenderer } from './Tables/VerticalTableRenderer.vue';

export const customRenderers = [
  financialTableRendererEntry,
  generalInformationRendererEntry,
  tableOfMonthsRendererEntry,
  verticalTableRendererEntry,
];
