import { Module } from 'vuex';
import { StateType } from '@/store';
import PinningService, { AssignManager, AssignManagerFilterParams } from '@/api/admin/pinning';

export interface AssignManagerListStateType {
  items: AssignManager[],
  filter: AssignManagerFilterParams,
}

const getInitialState = (): AssignManagerListStateType => ({
  items: [],
  filter: {},
});

const initialState = getInitialState();

const assignManagerListModule: Module<AssignManagerListStateType, StateType> = {
  namespaced: true,
  state: initialState,
  getters: {
    items: (state): AssignManager[] => state.items,
    filter: (state) => state.filter,
  },
  mutations: {
    setItems: (state, items) => {
      state.items = items;
    },
    setFilter: (state, filter) => {
      state.filter = filter;
    },
  },
  actions: {
    changeFilter({ commit }, filter: AssignManagerFilterParams) {
      commit('setFilter', filter);
    },
    async loadItems({ state, rootGetters, commit }) {
      const api: PinningService = rootGetters.$api.admin.pinning;

      const response = await api.managers(state.filter);

      commit('setItems', response.data?.items ?? initialState.items);
    },
  },
};

export default assignManagerListModule;
