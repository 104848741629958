
import { defineComponent } from 'vue';
import config from '@/config';
import ActiveStatusIcon from '@/components/ui/statuses/ActiveStatusIcon.vue';
import BlockedStatusIcon from '@/components/ui/statuses/BlockedStatusIcon.vue';

export default defineComponent({
  name: 'ActivityStatus',
  components: {
    ActiveStatusIcon,
    BlockedStatusIcon,
  },
  props: {
    status: {
      type: Number,
      default: config.STATUS_USER_IS_ACTIVE,
    },
  },
  data: () => ({
    isShowTooltip: false,
  }),
  computed: {
    isBlocked() {
      return this.status === config.STATUS_USER_IS_BLOCKED;
    },
  },
  methods: {
    openTooltip() {
      this.isShowTooltip = true;
    },
    closeTooltip() {
      this.isShowTooltip = false;
    },
  },
});
