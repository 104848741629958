import { Module } from 'vuex';
import { StateType } from '@/store';
import { AxiosCallResponse } from '@/api/base';
import { RegistryInfo } from '@/api/block/registry/base';
import DeliveryRegistryService from '@/api/block/registry/deliveryRegistry';

export interface DeliveryRegistryInfoStateType {
  filterState: RegistryInfo,
}

const getInitialState = () => ({
  filterState: {
    existingYears: null,
    existingGroups: null,
  },
});

const deliveryInfoModule: Module<DeliveryRegistryInfoStateType, StateType> = {
  namespaced: true,
  state: getInitialState(),
  getters: {
    filterState: (state): RegistryInfo => state.filterState,
  },
  mutations: {
    setFilter(state, filter) {
      state.filterState = filter;
    },
  },
  actions: {
    async loadFilterState({ rootGetters, commit }, cardId: string) {
      const api: DeliveryRegistryService = rootGetters.$api.blocks.deliveryRegistry;

      const response: AxiosCallResponse<RegistryInfo> = await api.info(cardId);

      commit('setFilter', response.data ?? getInitialState().filterState);
    },
  },
};

export default deliveryInfoModule;
