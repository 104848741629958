<template>
  <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd" clip-rule="evenodd"
      d="M6.25685 1.69896C7.02185 0.338955 8.97885 0.338955 9.74285 1.69896L15.3228
 11.619C16.0728 12.953 15.1098 14.599 13.5808 14.599H2.41985C0.889848 14.599
 -0.0731517 12.953 0.676848 11.619L6.25685 1.69896ZM8.99985 11.6C8.99985 11.8652
 8.89449 12.1195 8.70695 12.3071C8.51942 12.4946 8.26506 12.6 7.99985 12.6C7.73463
 12.6 7.48028 12.4946 7.29274 12.3071C7.10521 12.1195 6.99985 11.8652 6.99985
 11.6C6.99985 11.3347 7.10521 11.0804 7.29274 10.8928C7.48028 10.7053 7.73463
 10.6 7.99985 10.6C8.26506 10.6 8.51942 10.7053 8.70695 10.8928C8.89449 11.0804
 8.99985 11.3347 8.99985 11.6ZM7.99985 3.59996C7.73463 3.59996 7.48028 3.70531
 7.29274 3.89285C7.10521 4.08038 6.99985 4.33474 6.99985 4.59996V7.59996C6.99985
 7.86517 7.10521 8.11953 7.29274 8.30706C7.48028 8.4946 7.73463 8.59996 7.99985
 8.59996C8.26506 8.59996 8.51942 8.4946 8.70695 8.30706C8.89449 8.11953 8.99985
 7.86517 8.99985 7.59996V4.59996C8.99985 4.33474 8.89449 4.08038 8.70695
 3.89285C8.51942 3.70531 8.26506 3.59996 7.99985 3.59996Z"
      fill="#A16207"
    />
  </svg>
</template>
