
import { defineComponent, PropType } from 'vue';
import VacancyIcon from '@/components/ui/icons/BriefcaseIcon.vue';
import HomeIcon from '@/components/ui/icons/BuildingIcon.vue';
import PhoneIcon from '@/components/ui/icons/PhoneIcon.vue';
import MailIcon from '@/components/ui/icons/EmailIcon.vue';
import ImageAvatar from '@/components/ui/avatars/ImageAvatar.vue';
import BlockArrow from '@/components/ui/icons/BlockArrow.vue';
import RightArrowIcon from '@/components/ui/icons/RightArrowIcon.vue';
import { CounterpartyManager } from '@/api/counterparty';
import config from '@/config';
import { trim } from 'lodash';
import useStringHelper from '@/composables/helpers/text/useStringHelper';

export default defineComponent({
  name: 'UserItem',
  emits: ['opened', 'closed'],
  components: {
    VacancyIcon,
    HomeIcon,
    PhoneIcon,
    MailIcon,
    ImageAvatar,
    BlockArrow,
    RightArrowIcon,
  },
  props: {
    manager: {
      type: Object as PropType<CounterpartyManager>,
      default: () => ({}),
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    nameInitial() {
      return useStringHelper().getLetter(trim(this.manager.name)
        ? this.manager.name
        : this.$t(`counterparty.list.filter.form.blocks.${config.COUNTERPARTY_FILTER_BLOCK_FREE}`));
    },
    userName() {
      return trim(this.manager.name)
        ? this.manager.name
        : this.$t(`counterparty.list.filter.form.blocks.${config.COUNTERPARTY_FILTER_BLOCK_FREE}`);
    },
  },
  methods: {
    toggleShow() {
      this.isOpen = !this.isOpen;
    },
  },
});
