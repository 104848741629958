import { Module } from 'vuex';
import { StateType } from '@/store';
import RoleService, { RoleItem, RoleListType } from '@/api/admin/role';

export interface RoleListStateType {
  items: RoleItem[],
}

const initialState = {
  items: [],
};

const roleListModule: Module<RoleListStateType, StateType> = {
  namespaced: true,
  state: initialState,
  getters: {
    items: (state): RoleItem[] => state.items,
  },
  mutations: {
    setItems(state, items) {
      state.items = items;
    },
  },
  actions: {
    async loadItems({ rootGetters, commit }) {
      const api: RoleService = rootGetters.$api.admin.role;

      const response: RoleListType = await api.list();

      commit('setItems', response.data?.items ?? initialState.items);
    },
  },
};

export default roleListModule;
