import { Module } from 'vuex';
import { AxiosCallResponse } from '@/api/base';
import i18n from '@/i18n';
import KeyProductionAssetsService, { KeyProductionAssetsSchema } from '@/api/block/general/keyProductionAssets';
import { TYPE_LABEL_SUCCESS } from '@/components/ui/notifications/TheAlert/TheAlert.vue';
import { StateType } from '@/store';

export interface KeyProductionOneStateType {
  data: KeyProductionAssetsSchema | null,
}

const initialState = {
  data: null,
};

const keyProductionOneModule: Module<KeyProductionOneStateType, StateType> = {
  namespaced: true,
  state: initialState,
  actions: {
    async create({ rootGetters }, params: {
      cardId: string,
      data: KeyProductionAssetsSchema,
    }) {
      const api: KeyProductionAssetsService = rootGetters.$api.blocks.keyProductionAssets;

      if (params.cardId) {
        const response: AxiosCallResponse<KeyProductionAssetsSchema> = await api.create(
          params.cardId,
          params.data,
        );

        if (!response.error) {
          await rootGetters
            .$notificationLogger
            .setNotification(
              TYPE_LABEL_SUCCESS,
              i18n.global.t('notification.admin.dictionary.success.create'),
            );
        }
      }
    },
    async update({ rootGetters }, params: {
      cardId: string,
      id: string,
      data: KeyProductionAssetsSchema,
    }) {
      const api: KeyProductionAssetsService = rootGetters.$api.blocks.keyProductionAssets;

      if (params.cardId) {
        const response: AxiosCallResponse<null> = await api.update(
          params.cardId,
          params.id,
          params.data,
        );

        if (!response.error) {
          await rootGetters
            .$notificationLogger
            .setNotification(
              TYPE_LABEL_SUCCESS,
              i18n.global.t('notification.admin.dictionary.success.update'),
            );
        }
      }
    },
    async delete({ rootGetters }, params: {
      cardId: string,
      id: string,
    }) {
      const api: KeyProductionAssetsService = rootGetters.$api.blocks.keyProductionAssets;

      if (params.cardId) {
        const response: AxiosCallResponse<null> = await api.delete(
          params.cardId,
          params.id,
        );

        if (!response.error) {
          await rootGetters
            .$notificationLogger
            .setNotification(
              TYPE_LABEL_SUCCESS,
              i18n.global.t('notification.admin.dictionary.success.delete'),
            );
        }
      }
    },
  },
};

export default keyProductionOneModule;
