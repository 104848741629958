
import _ from 'lodash';

import { defineComponent } from 'vue';
import ShieldTooltip from '@/components/counterparty/list/modal/create/content/tabs/search/list/item/ShieldTooltip.vue';
import config from '@/config';

interface AdditionalInfo {
  isFound: boolean,
  label: string,
  value: string | { [key: string]: string }
}

export default defineComponent({
  name: 'TheItem',
  components: {
    ShieldTooltip,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    searchText: {
      type: String,
      default: '',
    },
    additionalInfo: {
      type: Array,
      default: () => ([]),
    },
    isPriority: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    preparedText() {
      const nameOnLowerCase: string = this.name.toLowerCase();
      const searchTextOnLowerCase: string = this.searchText.toLowerCase();

      if (!nameOnLowerCase.includes(searchTextOnLowerCase)) {
        return this.name;
      }

      const firstIndex: number = nameOnLowerCase.indexOf(searchTextOnLowerCase);
      const lastIndex: number = firstIndex + this.searchText.length;

      const firstPart: string = this.name.substring(0, firstIndex);
      const foundPart: string = this.name.substring(firstIndex, lastIndex);
      const lastPart: string = this.name.substring(lastIndex, this.name.length);

      return `${firstPart}<span class="font-semibold">${foundPart}</span>${lastPart}`;
    },
    filteredAdditionalInfo() {
      return Object.keys(this.additionalInfo)
        .filter((key: string) => !!this.additionalInfo?.[key]?.value)
        .reduce((object, key) => ({ ...object, [key]: this.additionalInfo[key] }), {});
    },
    sortedAdditionalInfo() {
      return _.orderBy(this.filteredAdditionalInfo, ['isFound'], 'desc');
    },
  },
  methods: {
    valueInfo(info: AdditionalInfo) {
      if (typeof info.value === 'object') {
        return info.value[config.ADDRESS_MAPPER_FIELD_TEXT];
      }
      return info.value;
    },
  },
});
