import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from 'vue-router';
import { role } from '@/config';
import permissions from '@/permissions';
import store from '@/store';

const ProfilePage = () => import('../views/profile/TheProfile.vue');
const CounterpartiesList = () => import('../views/counterparty/CounterpartiesList.vue');
const CounterpartiesCard = () => import('../views/counterparty/CounterpartiesCard.vue');
const UserList = () => import('../views/admin/user/TheList.vue');
const CountryList = () => import('../views/admin/dictionary/country/TheList.vue');
const StructureList = () => import('../views/admin/dictionary/structure/TheList.vue');
const PinningList = () => import('@/views/admin/assign/TheList.vue');
const CompanyList = () => import('../views/admin/dictionary/company/TheList.vue');
const CategoryCounterpartyList = () => import('../views/admin/dictionary/category/TheList.vue');
const BankList = () => import('../views/admin/dictionary/bank/TheList.vue');
const IndustryList = () => import('../views/admin/dictionary/industry/TheList.vue');
const PsychologicalCharacteristicList = () => import('../views/admin/dictionary/psychological-characteristic/TheList.vue');
const NomenclatureGroupList = () => import('../views/admin/dictionary/nomenclature-group/TheList.vue');
const TechnologicalSupportTypeList = () => import('../views/admin/dictionary/technological-support-type/TheList.vue');
const ActivityDirectionList = () => import('../views/admin/dictionary/activity-direction/TheList.vue');
const IndividualList = () => import('../views/admin/dictionary/individual/TheList.vue');
const EducationList = () => import('../views/admin/dictionary/education/TheList.vue');
const PositionList = () => import('../views/admin/dictionary/position/TheList.vue');
const RegionList = () => import('../views/admin/dictionary/region/TheList.vue');
const NomenclatureList = () => import('../views/admin/dictionary/nomenclature/TheList.vue');
const ReportPage = () => import('../views/report/TheReport.vue');

const NotFoundView = () => import('../views/error/NotFoundView.vue');
const AccessDenied = () => import('../views/error/TheAccessDenied.vue');
const ServerError = () => import('../views/error/TheServerError.vue');

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: CounterpartiesList,
    meta: { title: 'Список контрагентов' },
  },
  {
    path: '/counterparty/:id/:groupName?',
    component: CounterpartiesCard,
    meta: { title: 'Карточка контрагента' },
  },
  {
    path: '/admin/users',
    component: UserList,
    meta: {
      title: 'Управление пользователями',
    },
  },
  {
    path: '/admin/dictionary/countries',
    component: CountryList,
    meta: {
      title: 'Справочник стран',
    },
  },
  {
    path: '/admin/dictionary/structure',
    component: StructureList,
    meta: {
      title: 'Структура компании',
    },
  },
  {
    path: '/admin/dictionary/pinning',
    component: PinningList,
    meta: {
      title: 'Закрепления',
      authorize: [
        role.ADMIN,
        permissions.ROLE_SUPER_ADMIN,
        permissions.ROLE_VIEWER_DICTIONARY_ASSIGN,
      ],
    },
  },
  {
    path: '/admin/dictionary/company',
    component: CompanyList,
    meta: { title: 'Компании', authorize: [role.ADMIN, permissions.ROLE_SUPER_ADMIN] },
  },
  {
    path: '/admin/dictionary/category-counterparty',
    component: CategoryCounterpartyList,
    meta: {
      title: 'Категории контрагента',
    },
  },
  {
    path: '/admin/dictionary/bank',
    component: BankList,
    meta: {
      title: 'Банки',
    },
  },
  {
    path: '/admin/dictionary/industry',
    component: IndustryList,
    meta: {
      title: 'Отрасли',
    },
  },
  {
    path: '/admin/dictionary/psychological-characteristic',
    component: PsychologicalCharacteristicList,
    meta: { title: 'Психологические характеристики' },
  },
  {
    path: '/admin/dictionary/nomenclature-group',
    component: NomenclatureGroupList,
    meta: { title: 'Номенклатурные группы' },
  },
  {
    path: '/admin/dictionary/activity-direction',
    component: ActivityDirectionList,
    meta: { title: 'Основные направления деятельности' },
  },
  {
    path: '/admin/dictionary/education',
    component: EducationList,
    meta: { title: 'Образование' },
  },
  {
    path: '/admin/dictionary/individuals',
    component: IndividualList,
    meta: { title: 'Физические лица' },
  },
  {
    path: '/admin/dictionary/technological-support-type',
    component: TechnologicalSupportTypeList,
    meta: {
      title: 'Виды технологической поддержки',
      authorize: [
        role.ADMIN,
        permissions.ROLE_SUPER_ADMIN,
        permissions.ROLE_ADMIN_COMPANY,
        permissions.ROLE_ADMIN_DIVISION,
      ],
    },
  },
  {
    path: '/admin/dictionary/position',
    component: PositionList,
    meta: { title: 'Должности' },
  },
  {
    path: '/admin/dictionary/region',
    component: RegionList,
    meta: { title: 'Регионы' },
  },
  {
    path: '/admin/dictionary/nomenclature',
    component: NomenclatureList,
    meta: { title: 'Номенклатуры' },
  },
  {
    path: '/profile',
    component: ProfilePage,
    meta: { title: 'Профиль' },
  },
  {
    path: '/report/:cardId',
    component: ReportPage,
    meta: { title: 'Отчет' },
  },
  {
    path: '/403',
    component: AccessDenied,
  },
  {
    path: '/500',
    component: ServerError,
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFoundView,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) => {
  const { authorize } = to.meta;
  const hasAuthorizeParams = Array.isArray(authorize) && authorize.length;

  if (hasAuthorizeParams) {
    const clientRoles: string[] = store.getters['user/roles'] ?? [];

    if (!authorize.find((authorizeRole: string) => clientRoles.includes(authorizeRole))) {
      return next({ path: '/404' });
    }
  }

  return next();
});

export default router;
