
import { defineComponent, PropType, ref } from 'vue';
import { JsonSchema } from '@jsonforms/core';
import _ from 'lodash';
import BlockDefault from '@/components/ui/blocks/BlockDefault/BlockDefault.vue';
import AddButtonComponent from '@/components/widgets/add-button-component/AddButtonComponent.vue';
import TheSideModal from '@/components/ui/modal/TheSideModal/TheSideModal.vue';
import TheItem from '@/components/counterparty/card/blocks/list-block-template/item/TheItem.vue';
import CustomUISchemaElement from '@/components/jsonforms/interfaces/CustomUISchemaElement';
import TheCreate from '@/components/counterparty/card/blocks/list-block-template/create/TheCreate.vue';
import TheDetail from '@/components/counterparty/card/blocks/list-block-template/detail/TheDetail.vue';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import TheDangerModal from '@/components/ui/modal/TheDangerModal/TheDangerModal.vue';
import ThePagination
  from '@/components/counterparty/card/blocks/list-block-template/pagination/ThePagination.vue';
import { MetaParams, PaginationParams } from '@/api/base';

export default defineComponent({
  name: 'TheListBlockTemplate',
  emits: ['created', 'updated', 'deleted', 'pagination-changed'],
  components: {
    TheDangerModal,
    TheCreate,
    TheSideModal,
    AddButtonComponent,
    BlockDefault,
    TheItem,
    TheDetail,
    ThePagination,
  },
  props: {
    blockLabel: String,
    items: Array,
    pagination: Object as PropType<MetaParams>,
    fieldList: Array as PropType<string[]>,
    uischema: Object as PropType<CustomUISchemaElement>,
    schema: Object as PropType<JsonSchema>,
    isCanEdit: Boolean,
  },
  data() {
    return {
      isOpenForm: false,
      isCreate: false,
      isUpdate: false,
      isOpenConfirm: false,
    };
  },
  computed: {
    noData() {
      return !this.items || this.items.length <= 0;
    },
    selectedItemData() {
      const obj = this.items.find((
        item: JsonData,
      ) => item.id === this.selectedItemId);
      return _.pickBy(obj, (el: string, key: string) => key !== 'id');
    },
  },
  methods: {
    openForm() {
      this.isOpenForm = true;
    },
    closeForm() {
      this.isOpenForm = false;
      this.isCreate = false;
      this.isUpdate = false;
      this.selectedItemId = null;
    },
    openInfo(id: string) {
      this.isOpenForm = true;
      this.selectedItemId = id;
    },
    openCreateForm() {
      this.isCreate = true;
      this.openForm();
    },
    openConfirmModal() {
      this.isOpenConfirm = true;
    },
    confirmCancel() {
      this.isOpenConfirm = false;
    },
    createHandler(data: JsonData, next:() => void) {
      this.$emit('created', data, next);
      this.closeForm();
    },
    updateHandler(data: JsonData, next:() => void) {
      this.$emit('updated', this.selectedItemId, data, next);
      this.closeForm();
    },
    deleteHandler(next:() => void) {
      this.$emit('deleted', this.selectedItemId);
      this.isOpenConfirm = false;
      this.closeForm();
      next();
    },
    changeModeHandler(value: boolean) {
      this.isUpdate = value;
    },
    async changePaginationHandler(pagination: PaginationParams) {
      this.$emit('pagination-changed', pagination);
    },
  },
  setup() {
    const selectedItemId = ref<string | null>(null);

    return {
      selectedItemId,
    };
  },
});
