
import { defineComponent, PropType } from 'vue';
import { JsonSchema, UISchemaElement } from '@jsonforms/core';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import TheForm from '@/components/counterparty/card/blocks/table-block-template/form/TheForm.vue';
import IconButton from '@/components/ui/buttons/IconButton/IconButton.vue';
import EditIcon from '@/components/ui/icons/EditIcon.vue';
import TrashIcon from '@/components/ui/icons/TrashIcon.vue';
import DefaultButton from '@/components/ui/buttons/DefaultButton/DefaultButton.vue';

export default defineComponent({
  name: 'TheDetail',
  inject: ['$sizeWindow'],
  emits: ['cancel', 'changeEditMode', 'delete', 'update'],
  components: {
    TheForm,
    IconButton,
    TrashIcon,
    EditIcon,
    DefaultButton,
  },
  props: {
    data: {
      type: Object as PropType<JsonData>,
      default: () => ({}),
    },
    schema: {
      required: true,
      type: Object as PropType<JsonSchema>,
      default: () => ({}),
    },
    uischema: {
      required: true,
      type: Object as PropType<UISchemaElement>,
      default: () => ({}),
    },
    isCanEdit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isLoading: false,
      isEdit: false,
      localData: this.data,
    };
  },
  methods: {
    cancelHandler() {
      this.isEdit = false;
      this.localData = this.data;
      this.$emit('cancel');

      this.$emit('changeEditMode', !this.isEdit);
    },
    changeEditMode() {
      this.isEdit = true;

      this.$emit('changeEditMode', !this.isEdit);
    },
    deleteHandler() {
      this.$emit('delete');
    },
    updateHandler() {
      this.isLoading = true;

      this.$emit('update', this.localData, () => {
        this.isLoading = false;
      });
    },
    updateDataHandler(data: JsonData) {
      this.localData = data;
    },
  },
});
