import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f297a90c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item-data" }
const _hoisted_2 = { class: "label-text" }
const _hoisted_3 = { class: "field-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "item",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clickHandler && _ctx.clickHandler(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fieldList, (field) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "field",
          key: field
        }, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.getLabel(field)), 1),
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.getValue(field)), 1)
        ]))
      }), 128))
    ])
  ]))
}