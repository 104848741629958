import {
  AxiosCallResponse,
  BaseService,
  LoggerInterface,
  QueryParamsForList,
  ResponseOfRecords,
} from '@/api/base';

export interface ChangedDataType {
  id: string,
  name: string,
  new: string | number | boolean,
  old: string | number | boolean,
}

export interface HistoryItemType {
  id: string,
  createdAt: string,
  createdBy: string,
  status: number,
  changes: ChangedDataType[]
}

export type HistoryListParams = {
  itemId?: string,
} & QueryParamsForList;

export type HistoryResponseType = ResponseOfRecords<HistoryItemType[]>

export default class BlockHistoryService extends BaseService {
  constructor(token: string, logger: LoggerInterface = console) {
    super('card', token, logger, 'v2');
  }

  async getBlockHistory(
    cardId: string,
    blockId: string,
    params: HistoryListParams = {},
  ): Promise<AxiosCallResponse<HistoryResponseType>> {
    return this.axiosCall({
      url: `/${cardId}/history/${blockId}`,
      method: 'get',
      params,
    });
  }

  async deleteHistoryItem(historyId: string): Promise<AxiosCallResponse<null>> {
    return this.axiosCall({ url: `/history/${historyId}/`, method: 'delete' });
  }
}
