import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sidebar-content" }
const _hoisted_2 = { class: "sidebar-block" }
const _hoisted_3 = { class: "sidebar-block__title" }
const _hoisted_4 = { class: "sidebar-block" }
const _hoisted_5 = { class: "sidebar-footer" }
const _hoisted_6 = { class: "text-[14px]" }
const _hoisted_7 = { class: "text-[14px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_add_icon = _resolveComponent("add-icon")!
  const _component_default_button = _resolveComponent("default-button")!
  const _component_icon_button = _resolveComponent("icon-button")!
  const _component_modal_content = _resolveComponent("modal-content")!
  const _component_the_side_modal = _resolveComponent("the-side-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.$sizeWindow.isSm)
      ? (_openBlock(), _createBlock(_component_default_button, {
          key: 0,
          onClick: _ctx.openModal
        }, {
          icon: _withCtx(() => [
            _createVNode(_component_add_icon, {
              width: "12",
              height: "12"
            })
          ]),
          default: _withCtx(() => [
            _createTextVNode(" " + _toDisplayString(_ctx.$t('buttons.add')), 1)
          ]),
          _: 1
        }, 8, ["onClick"]))
      : (_openBlock(), _createBlock(_component_icon_button, {
          key: 1,
          onClick: _ctx.openModal,
          border: "default"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_add_icon, {
              width: "16",
              height: "16"
            })
          ]),
          _: 1
        }, 8, ["onClick"])),
    _createVNode(_component_the_side_modal, {
      "is-sidebar-with-footer": true,
      "is-show-modal": _ctx.isOpen || !!_ctx.newsId,
      onCloseHandler: _ctx.closeModal
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(!_ctx.newsId ? _ctx.$t('news.addNews') : _ctx.$t('news.editNews')), 1)
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_modal_content, {
                onChange: _ctx.onChange,
                data: _ctx.newsData
              }, null, 8, ["onChange", "data"])
            ])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_default_button, {
              height: "40",
              onClick: _ctx.closeModal
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('buttons.cancel')), 1)
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_default_button, {
              type: "primary",
              height: "40",
              disabled: _ctx.isDisabledButton || _ctx.isLoading,
              onClick: _ctx.addNewsHandler
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('buttons.save')), 1)
              ]),
              _: 1
            }, 8, ["disabled", "onClick"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["is-show-modal", "onCloseHandler"])
  ], 64))
}