import { defineComponent, PropType } from 'vue';
import DateHelper from '@/utils/helpers/DateHelper';
import {
  TableFieldObject,
  TableItem,
  TableItemType,
} from '@/components/ui/tables/vertical/TheTable';

export default defineComponent({
  name: 'VerticalTableItem',
  props: {
    item: {
      type: Object as PropType<TableItem>,
      default: () => ({}),
    },
    field: {
      type: Object as PropType<TableFieldObject>,
      default: () => ({}),
    },
  },
  methods: {
    checkObjectType(item: TableItem, field: TableFieldObject, type: string) {
      const key: string = field.key ?? '';
      const options: string | { type?: string } = key && !!item[key]
        ? item[key] as string | { type?: string }
        : '';

      if (typeof options === 'string') {
        return false;
      }

      return typeof options === 'object' && options?.type === type;
    },
    isLink(item: TableItem, field: TableFieldObject): boolean {
      return this.checkObjectType(item, field, TableItemType.LINK);
    },
    isComponent(item: TableItem, field: TableFieldObject): boolean {
      return this.checkObjectType(item, field, TableItemType.COMPONENT);
    },
    isDate(format: string) {
      return format === 'date';
    },
    textValue(field: TableFieldObject) {
      return this.item[field.key]?.name ?? this.item[field.key] ?? '';
    },
    formattedDate(date: string) {
      return DateHelper.formattedDate(date);
    },
  },
});
