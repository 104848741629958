import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sidebar-content" }
const _hoisted_2 = { class: "sidebar-block" }
const _hoisted_3 = { class: "sidebar-block__title" }
const _hoisted_4 = { class: "sidebar-block" }
const _hoisted_5 = { class: "sidebar-footer" }
const _hoisted_6 = { class: "text-[14px]" }
const _hoisted_7 = { class: "text-[14px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_form = _resolveComponent("the-form")!
  const _component_default_button = _resolveComponent("default-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('dictionary.general.create')), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_the_form, {
          ref: "form",
          schema: _ctx.schema,
          uischema: _ctx.uischema,
          "is-view": false
        }, null, 8, ["schema", "uischema"])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_default_button, {
        height: "40",
        onClick: _ctx.cancelHandler
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('buttons.cancel')), 1)
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_default_button, {
        type: "primary",
        height: "40",
        disabled: _ctx.isLoading,
        onClick: _ctx.createHandler
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('counterparty.create.buttons.send')), 1)
        ]),
        _: 1
      }, 8, ["disabled", "onClick"])
    ])
  ], 64))
}