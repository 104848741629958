
import { defineComponent } from 'vue';
import TheTooltip from '@/components/ui/modal/TheTooltip/TheTooltip.vue';
import TrashIcon from '@/components/ui/icons/TrashIcon.vue';

export default defineComponent({
  name: 'TrashStatusIcon',
  components: {
    TheTooltip,
    TrashIcon,
  },
});
