import { AxiosCallResponse } from '@/api/base';
import store from '@/store';
import CountryService, { CountryItem } from '@/api/admin/dictionary/country';

export function useOneApi() {
  return {
    async loadData(id: string) {
      const countryApi: CountryService = store.getters.$api.admin.dictionary.country;

      const response: AxiosCallResponse<CountryItem> = await countryApi.one(id);

      return response.data ?? null;
    },
  };
}

export default {};
