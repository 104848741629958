import {
  AxiosCallResponse,
  BaseService,
  LoggerInterface,
  QueryParamsForList,
  ResponseOfRecords,
} from '@/api/base';

export interface CounterpartyTypeItem {
  id: string,
  name: string,
}

export type CounterpartyTypeResponseType = AxiosCallResponse<
    ResponseOfRecords<CounterpartyTypeItem[]>
>;

export default class CounterpartyTypeService extends BaseService {
  constructor(token: string, logger: LoggerInterface = console) {
    super('admin/dictionary/counterparty-type', token, logger);
  }

  async list(params: QueryParamsForList): Promise<CounterpartyTypeResponseType> {
    return this.axiosCall({ method: 'get', params });
  }
}
