import _ from 'lodash';
import { defineComponent, ref } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import useCheckPermissions from '@/composables/useCheckPermissions';
import { CompanyItem } from '@/api/admin/dictionary/company';
import { DivisionItem } from '@/api/admin/dictionary/structure';
import DefaultLoader from '@/components/ui/loaders/DefaultLoader.vue';
import TheTable from '@/components/ui/tables/simple/TheTable.vue';
import TheSideModal from '@/components/ui/modal/TheSideModal/TheSideModal.vue';
import TheTitle from '@/components/admin/user/list/title/TheTitle.vue';
import TheFilter from '@/components/admin/user/list/filter/TheFilter.vue';
import ActivityStatus from '@/components/admin/user/status/ActivityStatus.vue';
import RolesItem from '@/components/admin/user/list/table/roles/RolesItem.vue';
import UserDetail from '@/views/admin/user/modal/detail/TheDetail.vue';
import UserCreate from '@/views/admin/user/modal/create/TheCreate.vue';
import { DataType } from '@/components/ui/selects/SelectDefault/SelectDefault.vue';
import { RoleItem } from '@/api/admin/role';
import { TableItem } from '@/components/ui/tables/simple/TheTable';
import { UserItem, UserListFilterParams } from '@/api/admin/user';
import RoleItemHelper from '@/utils/helpers/RoleItemHelper';

export default defineComponent({
  inject: ['$sizeWindow', '$userAccess'],
  name: 'UserList',
  components: {
    TheTitle,
    DefaultLoader,
    TheSideModal,
    TheFilter,
    TheTable,
    UserDetail,
    UserCreate,
  },
  setup() {
    const currentUserId = ref<null | string>(null);
    const checkPermissions = useCheckPermissions();
    const isCanEdit = ref(checkPermissions.dictionary.canEditUser);
    const isVisibleRoles = ref(checkPermissions.dictionary.canViewRoles);

    return { currentUserId, isCanEdit, isVisibleRoles };
  },
  data() {
    return {
      isLoading: false,
      isOpenShortInfo: false,
      isSideModalWithEditMode: false,
      fields: [
        {
          key: 'status', label: '', notSortable: true, width: 35,
        },
        { key: 'fullName', label: this.$t('admin.user.list.table.fio') },
        {
          key: 'email',
          label: this.$t('admin.user.list.table.email'),
          notSortable: true,
        },
        { key: 'division', label: this.$t('admin.user.list.table.division') },
      ],
    };
  },
  computed: {
    ...mapGetters({
      user: 'admin/user/one/data',
      items: 'admin/user/list/items',
      pagination: 'admin/user/list/pagination',
      filter: 'admin/user/list/filter',
      sort: 'admin/user/list/sort',
      roles: 'admin/role/list/items',
      divisions: 'admin/dictionary/structure/division/items',
      departments: 'admin/dictionary/structure/list/items',
      companies: 'admin/dictionary/company/list/items',
    }),
    preparedItems(): TableItem[] {
      const items: UserItem[] = this.items ?? [];

      return items.map((item: UserItem) => ({
        id: item.id,
        fullName: item.fullName,
        email: item.email,
        division: item.division,
        status: {
          type: 'component',
          component: ActivityStatus,
          props: {
            status: item.status,
          },
          isVisible: true,
        },
        roles: {
          type: 'component',
          component: RolesItem,
          props: {
            roles: this.getRoleLabels(item.roles),
          },
          isVisible: true,
        },
      }));
    },
    preparedDivisions(): DataType[] {
      return this.divisions.map((division: DivisionItem) => ({
        id: division.id,
        name: division.name,
      }));
    },
    preparedCompanies(): DataType[] {
      return this.companies.map((company: CompanyItem) => ({
        id: company.id,
        name: company.name,
      }));
    },
    preparedRoles(): DataType[] {
      const roles = RoleItemHelper.getLinearListOfRoles(_.cloneDeep(this.roles));

      return roles.map((role: RoleItem) => ({ id: role.id, name: role.label }));
    },
    isSidebarWithFooter() {
      return this.isSideModalWithEditMode
          || (this.isOpenShortInfo && !this.currentUserId);
    },
  },
  methods: {
    ...mapActions({
      verifyUser: 'admin/user/one/verify',
      createUser: 'admin/user/one/create',
      loadItems: 'admin/user/list/loadItems',
      loadRoles: 'admin/role/list/loadItems',
      resetState: 'admin/user/list/resetState',
      loadDivisions: 'admin/dictionary/structure/division/loadItems',
      loadCompanies: 'admin/dictionary/company/list/loadItems',
      changePagination: 'admin/user/list/changePagination',
      changeFilter: 'admin/user/list/changeFilter',
      changeFilterCompany: 'admin/dictionary/company/list/changeFilter',
      changeFilterDivision: 'admin/dictionary/structure/division/changeFilter',
      changeSort: 'admin/user/list/changeSort',
    }),
    openShortInfo() {
      this.isOpenShortInfo = true;
    },
    async openUserDetail(id: string) {
      this.currentUserId = id;

      this.openShortInfo();
    },
    async filterChangedHandler(filter: UserListFilterParams) {
      if (this.filter.company !== filter.company) {
        await this.loadDivisions({ companyId: filter.company });
      }

      await this.changeFilter(filter);
    },
    closeShortInfo() {
      this.currentUserId = null;
      this.isOpenShortInfo = false;
      this.isSideModalWithEditMode = false;
    },
    getRoleLabels(roles: string[]): string[] {
      const foundRoles = roles.map((
        role: string,
      ) => this.preparedRoles.find((roleObject: DataType) => roleObject.id === role));

      return foundRoles.map((roleObject: DataType | undefined) => (roleObject && roleObject.name ? roleObject.name : ''));
    },
    async createdHandler(userId: string) {
      await this.loadItems();
      await this.openUserDetail(userId);

      this.isSideModalWithEditMode = true;
    },
    async updatedHandler() {
      await this.loadItems();

      this.closeShortInfo();
    },
    async changedUserStatusHandler() {
      await this.loadItems();
    },
    changedEditModeHandler(value: boolean) {
      this.isSideModalWithEditMode = value;
    },
    async ajaxCompanyHandler(query: string) {
      await this.changeFilterCompany({ query });
      await this.loadCompanies();
    },
    async ajaxDivisionHandler(query: string) {
      await this.changeFilterDivision({ query });
      await this.loadDivisions();
    },
  },
  async created() {
    this.isLoading = true;

    await this.loadItems({});
    await this.loadRoles();

    if (this.$userAccess.admin.isSuperAdmin) {
      await this.loadCompanies({});
    } else if (this.$userAccess.admin.isAdminCompany) {
      await this.loadDivisions({});
    }

    this.isLoading = false;
  },
  unmounted() {
    this.resetState();
  },
});
