<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24.714 16.3807C25.9642 15.1305 26.6666 13.4348 26.6666 11.6667C26.6666 9.89856 25.9642
 8.20286 24.714 6.95262C23.4637 5.70238 21.768 5 19.9999 5C18.2318 5 16.5361 5.70238 15.2859
 6.95262C14.0356 8.20286 13.3333 9.89856 13.3333 11.6667C13.3333 13.4348 14.0356 15.1305
 15.2859 16.3807C16.5361 17.631 18.2318 18.3333 19.9999 18.3333C21.768 18.3333 23.4637 17.631
 24.714 16.3807Z"
      stroke="#4F46E5"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.7503 26.7504C13.9383 24.5625 16.9057 23.3333 19.9999 23.3333C23.0941 23.3333 26.0616
 24.5625 28.2495 26.7504C30.4374 28.9383 31.6666 31.9058 31.6666 35H8.33325C8.33325 31.9058
 9.56242 28.9383 11.7503 26.7504Z"
      stroke="#4F46E5"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'ManagerAvatar',
};
</script>

<style scoped>

</style>
