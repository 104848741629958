import { defineComponent } from 'vue';
import {
  EmitDataType,
} from '@/components/ui/menu/ContextMenu/ContextMenu';
import TableHeader
  from '@/components/counterparty/card/blocks/news/table/header/TableHeader.vue';
import TableBody
  from '@/components/counterparty/card/blocks/news/table/body/TableBody.vue';
import TablePagination
  from '@/components/counterparty/card/blocks/news/table/pagination/TablePagination.vue';

export default defineComponent({
  name: 'TableDefault',
  components: {
    TableHeader,
    TableBody,
    TablePagination,
  },
  props: {
    news: {
      type: Array,
      default: () => [],
    },
    sortHandler: {
      type: Function,
    },
    sort: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['contextEdit', 'contextDelete'],
  methods: {
    actionContext(emitData: EmitDataType) {
      if (emitData.emit === 'contextEdit') {
        this.$emit('contextEdit', emitData.id);
      }
      if (emitData.emit === 'contextDelete') {
        this.$emit('contextDelete', emitData.id);
      }
    },
  },
});
