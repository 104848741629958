<template>
  <a class="tab-button" :class="{ active }">
    <slot>
      {{ name }}
    </slot>
  </a>
</template>

<script>
export default {
  name: 'TabButton',
  props: {
    name: String,
    active: Boolean,
  },
};
</script>

<style scoped lang="scss">
.tab-button {
  @apply cursor-pointer
  text-[12px] md:text-[14px]
  text-center
  font-semibold
  w-full
  flex
  justify-center
  items-center
  rounded-full
  py-[10px]
  z-[2];
  line-height: 20px;
}
</style>
