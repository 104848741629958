
import { defineComponent } from 'vue';
import payment from '@/shared/consts/blocks/general/payment';
import paymentAccount from '@/shared/consts/blocks/paymentAccount';
import LockIcon from '@/components/ui/icons/LockIcon.vue';
import TheTooltip from '@/components/ui/modal/TheTooltip/TheTooltip.vue';

export default defineComponent({
  name: 'TheItem',
  components: { TheTooltip, LockIcon },
  emits: ['clickItemHandler'],
  props: {
    item: Object,
  },
  computed: {
    name() {
      return this.item[payment.NAME];
    },
    billingNumber() {
      return this.item[payment.BILLING_NUMBER];
    },
    isBlocked() {
      return this.item.status === paymentAccount.STATUS_IS_BLOCKED;
    },
  },
  methods: {
    clickHandler() {
      this.$emit('clickItemHandler', this.item.id);
    },
  },
});
