import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3dd539ed"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.classes)
  }, [
    (_ctx.isIconType)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon), {
          key: 0,
          width: "24",
          height: "24"
        }))
      : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.text), 1))
  ], 2))
}