
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { defineComponent } from 'vue';
import IconButton from '@/components/ui/buttons/IconButton/IconButton.vue';
import FilterIcon from '@/components/ui/icons/FilterIcon.vue';
import { CounterpartyFilterParams } from '@/api/counterparty';
import SearchIcon from '@/components/ui/icons/SearchIcon.vue';
import CloseIcon from '@/components/ui/icons/CloseIcon.vue';
import TheForm, {
  CounterpartyFilterForm,
} from '@/components/counterparty/list/filter/form/TheForm.vue';
import TheModal from '@/components/ui/modal/TheModal/TheModal.vue';
import DefaultLoader from '@/components/ui/loaders/DefaultLoader.vue';
import config from '@/config';
import { FilterParams } from '@/api/base';

export default defineComponent({
  name: 'TheFilter',
  inject: ['$userAccess'],
  components: {
    CloseIcon,
    SearchIcon,
    IconButton,
    FilterIcon,
    TheModal,
    TheForm,
    DefaultLoader,
  },
  data() {
    return {
      isLoading: false,
      isOpenPopup: false,
      query: null,
      filters: {
        industry: undefined,
        isFromRussia: undefined,
        block: undefined,
        divisionId: undefined,
        departmentId: undefined,
        assignUserId: undefined,
        regionId: undefined,
        address: undefined,
      },
    };
  },
  computed: {
    ...mapGetters({
      divisions: 'admin/dictionary/structure/division/items',
      departments: 'admin/dictionary/structure/list/items',
      industries: 'admin/dictionary/industry/list/items',
      user: 'user/localUserData',
    }),
    newFilter() {
      return {
        query: this.query,
        ...this.filters,
      };
    },
    isHaveFilters() {
      return _.values(this.filters).filter((value) => !!value).length > 0;
    },
  },
  watch: {
    async isOpenPopup(value: boolean) {
      if (value) {
        await this.loadDefaultData();
      }
    },
  },
  methods: {
    ...mapActions({
      setFilterStructure: 'admin/dictionary/structure/list/changeFilter',
      loadDivisions: 'admin/dictionary/structure/division/loadItems',
      loadStructure: 'admin/dictionary/structure/list/loadItems',
      loadIndustries: 'admin/dictionary/industry/list/loadItems',
      changeFilter: 'counterparty/list/changeFilter',
      changeIndustryFilter: 'admin/dictionary/industry/list/changeFilter',
      loadCounterparty: 'counterparty/list/loadCounterparties',
    }),
    searchInputHandler: _.debounce(async function handler(e: { target: HTMLInputElement }) {
      this.query = String(e.target.value);

      await this.changeFilter(this.newFilter);
      await this.loadCounterparty();
    }, 500),
    openFiltersHandler() {
      this.isOpenPopup = true;
    },
    closeFiltersHandler() {
      this.isOpenPopup = false;
    },
    async filtersChangedHandler(
      data: CounterpartyFilterParams,
      lastData: CounterpartyFilterParams,
    ) {
      if (data.divisionId !== lastData.divisionId) {
        await this.setFilterStructure({
          parentId: data.divisionId,
        });
        await this.loadStructure();
      }
    },
    async submitFiltersHandler(data: CounterpartyFilterParams) {
      this.filters = data;

      await this.changeFilter(this.newFilter);
      await this.loadCounterparty();

      this.closeFiltersHandler();
    },
    async approveFiltersHandler(next: () => void) {
      const formRef: CounterpartyFilterForm = this.$refs.filters as CounterpartyFilterForm;

      this.filters = formRef?.currentData ?? {};

      await this.changeFilter(this.newFilter);
      await this.loadCounterparty();

      this.closeFiltersHandler();

      next();
    },
    async clearFiltersHandler() {
      this.filters = {
        industry: undefined,
        isFromRussia: undefined,
        block: !this.$userAccess.viewer.isEntireList
          ? config.COUNTERPARTY_FILTER_BLOCK_WORKING
          : undefined,
        divisionId: undefined,
        departmentId: undefined,
        assignUserId: !this.$userAccess.viewer.isEntireList
          ? this.user.id
          : undefined,
        regionId: undefined,
        address: undefined,
      };

      await this.changeFilter(this.newFilter);
      await this.loadCounterparty();
    },
    setDefaultFilters() {
      this.query = null;

      this.filters = {
        industry: undefined,
        isFromRussia: undefined,
        block: config.COUNTERPARTY_FILTER_BLOCK_WORKING,
        divisionId: this.user?.division ?? undefined,
        departmentId: undefined,
        assignUserId: !this.$userAccess.viewer.isEntireList
          ? this.user.id
          : undefined,
        regionId: undefined,
        address: undefined,
      };
    },
    async loadDefaultData() {
      this.isLoading = true;

      await this.loadDivisions({});

      if (this.filters.divisionId !== null) {
        await this.setFilterStructure({
          parentId: this.filters.divisionId,
        });
        await this.loadStructure();
      }

      this.isLoading = false;
    },
    async ajaxIndustriesHandler(filter: FilterParams) {
      await this.changeIndustryFilter(filter);
      await this.loadIndustries();
    },
  },
  created() {
    this.setDefaultFilters();
  },
});
