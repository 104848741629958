
import { Styles } from '@jsonforms/vue-vanilla';
import { defineComponent, PropType } from 'vue';
import { computeLabel } from '@/components/jsonforms/util/renderer';
import TheToggleTooltip from '@/components/ui/tooltips/TheToggleTooltip/TheToggleTooltip.vue';

export default defineComponent({
  name: 'control-wrapper',
  components: { TheToggleTooltip },
  props: {
    id: {
      type: String,
    },
    description: {
      required: false,
      type: String,
      default: undefined,
    },
    errors: {
      required: false,
      type: String,
      default: undefined,
    },
    label: {
      required: false,
      type: String,
      default: undefined,
    },
    appliedOptions: {
      required: false,
      type: Object,
      default: undefined,
    },
    visible: {
      required: false,
      type: Boolean,
      default: true,
    },
    required: {
      required: false,
      type: Boolean,
      default: false,
    },
    isFocused: {
      required: false,
      type: Boolean,
      default: false,
    },
    styles: {
      required: true,
      type: Object as PropType<Styles>,
    },
  },
  computed: {
    computedLabel(): string {
      const hideRequiredAsterisk = !!this.appliedOptions?.hideRequiredAsterisk
        || this.appliedOptions?.onlyText;

      return computeLabel(
        this.label,
        this.required,
        hideRequiredAsterisk,
      );
    },
    showError(): boolean {
      return !!this.errors && !this.appliedOptions.onlyText;
    },
    showDescription(): boolean {
      return !!this.description && !this.appliedOptions.onlyText && !this.showError;
    },
  },
});
