import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import ContextMenu
  from '@/components/ui/menu/ContextMenu/ContextMenu.vue';
import EditIcon from '@/components/ui/icons/EditIcon.vue';
import DeleteIcon from '@/components/ui/icons/TrashIcon.vue';
import {
  ContextMenuType, EmitDataType,
} from '@/components/ui/menu/ContextMenu/ContextMenu';

export default defineComponent({
  name: 'TableItem',
  components: {
    ContextMenu,
  },
  props: {
    newItem: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    isOpen: false,
  }),
  setup() {
    const { t } = useI18n({
      useScope: 'global',
      inheritLocale: true,
    });

    return { t };
  },
  computed: {
    contextMenuParams(): ContextMenuType[] {
      return [
        {
          text: this.t('buttons.edit'),
          icon: EditIcon,
          emit: 'contextEdit',
        },
        {
          text: this.t('buttons.delete'),
          icon: DeleteIcon,
          emit: 'contextDelete',
        },
      ];
    },
    contentLength() {
      return this.newItem.content.split('').length;
    },
    shortLink() {
      const shortLink = this.newItem.link.split('/');
      return shortLink[2] ?? this.$t('errors.link');
    },
  },
  emits: ['actionHandler'],
  methods: {
    toggleShow() {
      this.isOpen = !this.isOpen;
    },
    actionHandler(emitDate: EmitDataType) {
      this.$emit('actionHandler', emitDate);
    },
  },
});
