import { Module } from 'vuex';
import i18n from '@/i18n';
import { StateType } from '@/store';
import MdmService, { CounterpartyItemType, CounterpartyResponseType } from '@/api/mdm';
import SearchCounterpartyListException from '@/store/exception/search/SearchCounterpartyListException';

export interface CounterpartyListStateType {
  items: CounterpartyItemType[],
}

const initialState = {
  items: [],
};

const counterpartyListModule: Module<CounterpartyListStateType, StateType> = {
  namespaced: true,
  state: initialState,
  getters: {
    items: (state): CounterpartyItemType[] => state.items,
  },
  mutations: {
    setItems(state, items) {
      state.items = items;
    },
  },
  actions: {
    async loadItems({ rootGetters, commit }, query: string): Promise<CounterpartyItemType[]> {
      const api: MdmService = rootGetters.$api.mdm;

      const response: CounterpartyResponseType = await api.getCounterpartyList({ query });

      if (response.error || !response.data?.items?.length) {
        throw new SearchCounterpartyListException(i18n.global.t('counterparty.create.error.notFoundExpand'));
      }

      commit('setItems', response.data?.items ?? initialState.items);

      return response.data?.items ?? initialState.items;
    },
  },
};

export default counterpartyListModule;
