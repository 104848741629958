import { Module } from 'vuex';
import counterpartyList, { CounterpartyListStateType } from '@/store/modules/search/counterpartyList';
import { StateType } from '@/store';

export interface SearchStateType {
  counterpartyList: CounterpartyListStateType,
}

const searchModule: Module<SearchStateType, StateType> = {
  namespaced: true,
  modules: {
    counterpartyList,
  },
};

export default searchModule;
