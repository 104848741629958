import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-23c15dae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "font-bold text-[18px] md:text-[24px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.showBlock && _ctx.showBlock(...args))),
    class: _normalizeClass(["block", !_ctx.isOpen ? 'block-hidden' : '' ])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.headerClasses),
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleBlock && _ctx.toggleBlock(...args)), ["stop"]))
    }, [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.text), 1),
      _renderSlot(_ctx.$slots, "header-icon", {}, undefined, true)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["block-content", { hidden: !_ctx.isOpen }])
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2)
  ], 2))
}