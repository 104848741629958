
import {
  ControlElement,
  JsonFormsRendererRegistryEntry,
  rankWith,
  isTimeControl,
} from '@jsonforms/core';
import { RendererProps, rendererProps, useJsonFormsControl } from '@jsonforms/vue';
import { useVanillaControl } from '@jsonforms/vue-vanilla';
import { defineComponent, ref, Ref } from 'vue';
import { controlWrapper, validationMethods } from '@/components/jsonforms/util/renderer';
import ControlWrapper from './ControlWrapper.vue';

const controlRenderer = defineComponent({
  name: 'time-control-renderer',
  components: {
    ControlWrapper,
  },
  props: {
    ...rendererProps<ControlElement>(),
  },
  setup(props: RendererProps<ControlElement>) {
    const vanillaControl = useVanillaControl(useJsonFormsControl(props));
    const control = vanillaControl.control as unknown as Ref;
    const errors = ref(vanillaControl.controlWrapper?.value?.errors ?? '');

    return {
      errors,
      ...vanillaControl,
      ...validationMethods(control),
    };
  },
  watch: {
    controlWrapper(value, oldValue) {
      if (value.errors !== oldValue.errors) {
        this.errors = value.errors;
      }
    },
  },
  computed: {
    preparedControlWrapper() {
      return controlWrapper(this);
    },
  },
  methods: {
    changeHandler(event: Event) {
      const el: HTMLInputElement = event.target as HTMLInputElement;
      const value: string | undefined = !el.value && this.isRequired()
        ? undefined
        : el.value;

      this.handleChange(this.control.path, value);
    },
    blurHandler(event: Event) {
      const el: HTMLInputElement = event.target as HTMLInputElement;
      const value: string | undefined = !el.value && this.isRequired()
        ? undefined
        : el.value;

      this.isFocused = false;

      this.errors = this.getError(this.prepareData(value), this.prepareSchema());
    },
  },
});

export default controlRenderer;

export const entry: JsonFormsRendererRegistryEntry = {
  renderer: controlRenderer,
  tester: rankWith(2, isTimeControl),
};
