import { QueryParamsForList } from '@/api/base';
import store from '@/store';
import IndividualService, { IndividualListResponseType } from '@/api/admin/dictionary/individual';

export function useListApi() {
  return {
    async loadItems(params: QueryParamsForList) {
      const individualApi: IndividualService = store.getters.$api.admin.dictionary.individual;

      const response: IndividualListResponseType = await individualApi.list(
        params,
      );

      return response.data?.items ?? [];
    },
  };
}

export default {};
