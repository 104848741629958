import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-55d22b37"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_image_avatar = _resolveComponent("image-avatar")!
  const _component_active_badge = _resolveComponent("active-badge")!
  const _component_blocked_badge = _resolveComponent("blocked-badge")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_image_avatar, {
      size: "large",
      src: _ctx.src,
      letter: _ctx.nameInitial
    }, null, 8, ["src", "letter"]),
    (_ctx.isActiveStatus)
      ? (_openBlock(), _createBlock(_component_active_badge, {
          key: 0,
          class: "badge-block"
        }))
      : _createCommentVNode("", true),
    (_ctx.isBlockedStatus)
      ? (_openBlock(), _createBlock(_component_blocked_badge, {
          key: 1,
          class: "badge-block"
        }))
      : _createCommentVNode("", true)
  ]))
}