
import { defineComponent, PropType } from 'vue';

type AvatarSize = 'small' | 'default' | 'large';

export default defineComponent({
  name: 'ImageAvatar',
  props: {
    src: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: '',
    },
    letter: {
      type: String,
      default: '',
    },
    size: {
      type: String as PropType<AvatarSize>,
      default: 'default',
    },
  },
});
