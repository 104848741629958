import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, toHandlers as _toHandlers, mergeProps as _mergeProps, KeepAlive as _KeepAlive, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-52ef0364"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 2 }
const _hoisted_2 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("td", {
    style: _normalizeStyle(_ctx.field.width ? `width: ${_ctx.field.width}px;` : '')
  }, [
    (_ctx.isLink(_ctx.item, _ctx.field))
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          to: _ctx.item[_ctx.field.key]?.link,
          class: "primary-color"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.item[_ctx.field.key].text), 1)
          ]),
          _: 1
        }, 8, ["to"]))
      : (_ctx.isComponent(_ctx.item, _ctx.field))
        ? (_openBlock(), _createBlock(_KeepAlive, { key: 1 }, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.item[_ctx.field.key].component), _mergeProps(_ctx.item[_ctx.field.key].props, _toHandlers(_ctx.item[_ctx.field.key].events ?? {})), null, 16))
          ], 1024))
        : (_ctx.isDate(_ctx.field.format))
          ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.formattedDate(_ctx.item[_ctx.field.key])), 1))
          : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.textValue(_ctx.field)), 1))
  ], 4))
}