import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-34fbfc47"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_arrow = _resolveComponent("select-arrow")!
  const _component_icon_button = _resolveComponent("icon-button")!
  const _component_checkbox_input = _resolveComponent("checkbox-input")!
  const _component_the_item = _resolveComponent("the-item", true)!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "select-tree-item",
      style: _normalizeStyle(`padding-left: ${_ctx.offsetCount}px`)
    }, [
      (_ctx.isHaveChild)
        ? (_openBlock(), _createBlock(_component_icon_button, {
            key: 0,
            onClick: _withModifiers(_ctx.toggleList, ["stop"]),
            class: "mx-[4px]"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_select_arrow, {
                "is-open": !_ctx.isOpen
              }, null, 8, ["is-open"])
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true),
      (_ctx.multiple)
        ? (_openBlock(), _createBlock(_component_checkbox_input, {
            key: 1,
            "is-incomplete-filling": _ctx.isFilling,
            value: _ctx.selectedValue.includes(_ctx.item.id) || _ctx.parentSelected,
            onClickHandler: _ctx.selectValue
          }, null, 8, ["is-incomplete-filling", "value", "onClickHandler"]))
        : _createCommentVNode("", true),
      (_ctx.multiple)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            innerHTML: _ctx.item.name
          }, null, 8, _hoisted_1))
        : (_openBlock(), _createElementBlock("div", {
            key: 3,
            innerHTML: _ctx.item.name,
            class: _normalizeClass(["cursor-pointer", {
           'font-semibold': _ctx.isSelectedItem
         }]),
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.selectValue && _ctx.selectValue(...args)))
          }, null, 10, _hoisted_2))
    ], 4),
    (_ctx.isOpen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.items, (item) => {
            return (_openBlock(), _createBlock(_component_the_item, {
              item: item,
              key: item.id,
              "is-all-open": _ctx.isAllOpen,
              offset: _ctx.offset + 20,
              "parent-selected": _ctx.parentSelect,
              "selected-value": _ctx.selectedValue,
              multiple: _ctx.multiple,
              onSelectValue: _ctx.selectChildValue,
              onSelectChildrenItems: _ctx.selectChildrenItems
            }, null, 8, ["item", "is-all-open", "offset", "parent-selected", "selected-value", "multiple", "onSelectValue", "onSelectChildrenItems"]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}