import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "notification-manager-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_alert = _resolveComponent("the-alert")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.preparedList, (notification) => {
      return (_openBlock(), _createBlock(_component_the_alert, {
        key: notification.id,
        id: notification.id,
        type: notification.type,
        onCloseNotification: _ctx.deleteNotification
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(notification.message), 1)
        ]),
        _: 2
      }, 1032, ["id", "type", "onCloseNotification"]))
    }), 128))
  ]))
}