import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3b5f9a5c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "accordion" }
const _hoisted_2 = { class: "flex items-center" }
const _hoisted_3 = { class: "user-name" }
const _hoisted_4 = { class: "little-text" }
const _hoisted_5 = {
  key: 0,
  class: "accordion-body"
}
const _hoisted_6 = {
  key: 0,
  class: "info-item"
}
const _hoisted_7 = {
  key: 1,
  class: "info-item"
}
const _hoisted_8 = { class: "flex items-center space-x-[8px]" }
const _hoisted_9 = {
  key: 0,
  class: "flex items-center space-x-[8px]"
}
const _hoisted_10 = {
  key: 2,
  class: "info-item click-info-item"
}
const _hoisted_11 = ["href"]
const _hoisted_12 = {
  key: 3,
  class: "info-item click-info-item"
}
const _hoisted_13 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_image_avatar = _resolveComponent("image-avatar")!
  const _component_block_arrow = _resolveComponent("block-arrow")!
  const _component_vacancy_icon = _resolveComponent("vacancy-icon")!
  const _component_home_icon = _resolveComponent("home-icon")!
  const _component_right_arrow_icon = _resolveComponent("right-arrow-icon")!
  const _component_phone_icon = _resolveComponent("phone-icon")!
  const _component_mail_icon = _resolveComponent("mail-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "accordion-header",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleShow && _ctx.toggleShow(...args)))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_image_avatar, {
          class: "mr-[8px]",
          size: "small",
          letter: _ctx.nameInitial
        }, null, 8, ["letter"]),
        _createElementVNode("div", _hoisted_3, [
          _createTextVNode(_toDisplayString(_ctx.userName) + " ", 1),
          _createElementVNode("span", _hoisted_4, " :: " + _toDisplayString(_ctx.manager.division ?? '') + " " + _toDisplayString(_ctx.manager.counterpartyType ?? ''), 1)
        ])
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_block_arrow, {
          "is-open": !_ctx.isOpen,
          width: "16",
          height: "8"
        }, null, 8, ["is-open"])
      ])
    ]),
    (_ctx.isOpen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (!!_ctx.manager.vacancy)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("div", null, [
                  _createVNode(_component_vacancy_icon, {
                    type: "outline",
                    width: "20",
                    height: "20"
                  })
                ]),
                _createElementVNode("div", null, _toDisplayString(_ctx.manager.vacancy ?? ''), 1)
              ]))
            : _createCommentVNode("", true),
          (!!_ctx.manager.company || !!_ctx.manager.department)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("div", null, [
                  _createVNode(_component_home_icon, {
                    width: "18",
                    height: "18",
                    type: "regular",
                    color: "#64748B"
                  })
                ]),
                _createElementVNode("div", _hoisted_8, [
                  (!!_ctx.manager.company)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _createElementVNode("div", null, _toDisplayString(_ctx.manager.company ?? ''), 1),
                        _createElementVNode("div", null, [
                          (!!_ctx.manager.company && !!_ctx.manager.department)
                            ? (_openBlock(), _createBlock(_component_right_arrow_icon, { key: 0 }))
                            : _createCommentVNode("", true)
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", null, _toDisplayString(_ctx.manager.department ?? ''), 1)
                ])
              ]))
            : _createCommentVNode("", true),
          (!!_ctx.manager.phone)
            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                _createElementVNode("div", null, [
                  _createVNode(_component_phone_icon, {
                    width: "18",
                    height: "18",
                    type: "regular",
                    color: "#64748B"
                  })
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("a", {
                    href: `tel:${_ctx.manager.phone}`
                  }, _toDisplayString(_ctx.manager.phone ?? ''), 9, _hoisted_11)
                ])
              ]))
            : _createCommentVNode("", true),
          (!!_ctx.manager.email)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _createElementVNode("div", null, [
                  _createVNode(_component_mail_icon, {
                    width: "18",
                    height: "18",
                    type: "regular",
                    color: "#64748B"
                  })
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("a", {
                    href: `mailto:${_ctx.manager.email}`
                  }, _toDisplayString(_ctx.manager.email ?? ''), 9, _hoisted_13)
                ])
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}