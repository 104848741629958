
import { defineComponent } from 'vue';
import config from '@/config';
import SuccessIcon from '@/components/ui/icons/CircleSmallCheckIcon.vue';
import InfoIcon from '@/components/ui/icons/CircleMdmIcon.vue';
import WarningIcon from '@/components/ui/icons/CircleWarningIcon.vue';
import ErrorIcon from '@/components/ui/icons/CircleErrorIcon.vue';

export const TYPE_TEXT = 'text';
export const TYPE_ICON = 'icon';

export default defineComponent({
  name: 'StatusBar',
  props: {
    status: Number,
    type: {
      type: String,
      default: TYPE_TEXT,
    },
  },
  computed: {
    isTextType() {
      return this.type === TYPE_TEXT;
    },
    isIconType() {
      return this.type === TYPE_ICON;
    },
    icon() {
      switch (this.status) {
        case config.STATUS_REQUEST_APPROVED:
          return SuccessIcon;
        case config.STATUS_REQUEST_REJECTED:
          return ErrorIcon;
        case config.STATUS_REQUEST_PROCESSING:
          return WarningIcon;
        case config.STATUS_REQUEST_AGREEMENT:
          return InfoIcon;
        default:
          return WarningIcon;
      }
    },
    text() {
      switch (this.status) {
        case config.STATUS_REQUEST_APPROVED:
          return 'Одобрена';
        case config.STATUS_REQUEST_REJECTED:
          return 'Отклонена';
        case config.STATUS_REQUEST_PROCESSING:
          return 'В обработке';
        case config.STATUS_REQUEST_AGREEMENT:
          return 'Согласование MDM';
        default:
          return 'Неизвестный статус';
      }
    },
    classes() {
      return {
        'status-bar': this.isTextType,
        [this.getStatusClass()]: true,
      };
    },
  },
  methods: {
    getStatusClass() {
      switch (this.status) {
        case config.STATUS_REQUEST_APPROVED:
          return 'approved';
        case config.STATUS_REQUEST_REJECTED:
          return 'rejected';
        case config.STATUS_REQUEST_PROCESSING:
          return 'processing';
        case config.STATUS_REQUEST_AGREEMENT:
          return 'agreement';
        default:
          return 'processing';
      }
    },
  },
});
