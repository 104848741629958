import { JsonSchema } from "@jsonforms/core";
import { mapGetters, mapActions } from "vuex";
import { defineComponent, ref } from "vue";
import useCheckPermissions from "@/composables/useCheckPermissions";
import OpenCardButton from "@/components/counterparty/list/table/OpenCardButton.vue";
import useShowErrors from "@/composables/form/useShowErrors";
import JsonData from "@/components/jsonforms/interfaces/JsonData";
import CustomUISchemaElement from "@/components/jsonforms/interfaces/CustomUISchemaElement";
import TheEditMdmData from "@/views/counterparty/modal/editMdmData/TheEditMdmData.vue";
import config from "@/config";
import DefaultLoader from "@/components/ui/loaders/DefaultLoader.vue";
import TheSideModal from "@/components/ui/modal/TheSideModal/TheSideModal.vue";
import SidebarContent from "@/components/counterparty/list/sidebar/content/SidebarContent.vue";
import TheTable from "@/components/ui/tables/simple/TheTable.vue";
import { TableItem } from "@/components/ui/tables/simple/TheTable";
import TheModal from "@/views/counterparty/modal/create/TheModal.vue";
import TheFilter from "@/views/counterparty/filter/TheFilter.vue";
import fields from "@/shared/consts/counterparty/fields";
import { MetaParams, SortParams } from "@/api/base";
import { BlockType } from "@/api/card";
import groups from "@/shared/consts/groups";
import DefaultButton from "@/components/ui/buttons/DefaultButton/DefaultButton.vue";
import IconButton from "@/components/ui/buttons/IconButton/IconButton.vue";
import AddIcon from "@/components/ui/icons/AddIcon.vue";
import AddCircleIcon from "@/components/ui/icons/AddCircleIcon.vue";
import CounterpartyService from "@/api/counterparty";
import store from "@/store";

export default defineComponent({
  inject: ["$sizeWindow", "$userAccess"],
  name: "CounterpartiesList",
  components: {
    AddCircleIcon,
    AddIcon,
    IconButton,
    DefaultButton,
    TheEditMdmData,
    DefaultLoader,
    TheSideModal,
    SidebarContent,
    TheTable,
    TheModal,
    TheFilter,
  },
  data() {
    return {
      isLoading: false,
      isShowShortInfo: true,
      isShortInfoLoading: false,
      isEditMdmLoading: false,
      assignFormData: {},
      fields: [
        { key: fields.NAME_FIELD, label: this.$t("counterpartyLabels.name") },
        { key: fields.INN_FIELD, label: this.$t("counterpartyLabels.taxNumberOrInn") },
        { key: fields.KPP_FIELD, label: this.$t("counterpartyLabels.kpp") },
        { key: "action", notSortable: true, width: this.$sizeWindow.isSm ? 48 : 110 },
      ],
    };
  },
  computed: {
    ...mapGetters({
      counterparties: "counterparty/list/counterparties",
      users: "admin/user/list/items",
      managers: "counterparty/card/managers",
      pagination: "counterparty/list/pagination",
      sort: "counterparty/list/sort",
      counterpartyData: "counterparty/card/data",
      schema: "schema/schema",
      blocks: "counterparty/card/blocks",
      user: "user/localUserData",
    }),
    mdmBlockUischema() {
      return this.getUischemaByBlock(config.COUNTERPARTY_MDM_DATA_BLOCK);
    },
    mdmBlockSchema() {
      return this.getSchemaByBlock(config.COUNTERPARTY_MDM_DATA_BLOCK, this.schema);
    },
    preparedItems() {
      const items: TableItem[] = this.counterparties ?? [];

      return items.map((item: TableItem) => ({
        ...item,
        action: {
          type: "component",
          component: OpenCardButton,
          props: {
            link: `/counterparty/${item.id}/general`,
          },
          isVisible: item.canView,
        },
      }));
    },
  },
  watch: {
    async currentCounterpartyId(id: string) {
      if (id) {
        this.isShortInfoLoading = true;

        await this.loadCounterparty(id);
        await this.loadBlocks();
        await this.loadManagers(id);

        this.isShortInfoLoading = false;
      }
    },
  },
  methods: {
    ...mapActions({
      loadCounterparties: "counterparty/list/loadCounterparties",
      changePagination: "counterparty/list/changePagination",
      changeFilter: "counterparty/list/changeFilter",
      loadManagers: "counterparty/card/loadManagers",
      changeSort: "counterparty/list/changeSort",
      resetState: "counterparty/list/resetState",
      loadCounterparty: "counterparty/card/loadKeyDataByCounterpartyId",
      loadBlocks: "counterparty/card/loadBlocks",
      updateMdmData: "counterparty/card/updateMdmData",
      changeCurrentGroupId: "counterparty/card/changeCurrentGroupId",
      setCurrentCounterpartyId: "counterparty/card/changeCurrentCounterpartyId",
    }),
    openShortInfo(counterpartyId: string) {
      this.currentCounterpartyId = counterpartyId;
    },
    closeShortInfo() {
      this.currentCounterpartyId = null;
      this.isShowShortInfo = true;
    },
    getSchemaByBlock(blockId: string, schema: JsonSchema): JsonSchema {
      return schema?.properties?.[blockId] ?? {};
    },
    getUischemaByBlock(blockId: string): CustomUISchemaElement {
      const block = this.blocks.find((item: BlockType) => item.id === blockId);

      return block?.schema ?? {};
    },
    async openEditMdmForm() {
      this.isEditMdmLoading = true;
      this.isShowShortInfo = false;

      await this.setCurrentCounterpartyId(this.currentCounterpartyId);
      await this.changeCurrentGroupId(groups.GENERAL_INFORMATION);

      this.isEditMdmLoading = false;
    },
    closeEditModal() {
      this.isShowShortInfo = true;
    },
    async updateMdmHandler(data: JsonData, next: () => void) {
      await this.updateMdmData(data);

      next();
      this.closeEditModal();
    },
    async changePaginationHandler(pagination: MetaParams) {
      await this.changePagination(pagination);
      await this.loadCounterparties();
    },
    async changeSortHandler(sort: SortParams) {
      await this.changeSort(sort);
      await this.loadCounterparties();
    },
    async downloadReport() {
      this.isLoading = true;
      const counterpartyApi: CounterpartyService = store.getters.$api.counterparty;
      const report = await counterpartyApi.downloadReport();
      if (report.data) {
        console.log(report.data);
        const url = window.URL.createObjectURL(new Blob([report.data]));
        const link = document.createElement("a");
        link.setAttribute("download", `${Date.now()}.xlsx`);
        link.href = url;
        link.click();
        this.isLoading = false;
      }
    },
  },
  async created() {
    this.isLoading = true;

    await this.changeFilter({
      block: config.COUNTERPARTY_FILTER_BLOCK_WORKING,
      divisionId: this.user?.division ?? undefined,
      assignUserId: !this.$userAccess.viewer.isEntireList ? this.user.id : undefined,
    });
    await this.loadCounterparties();

    this.isLoading = false;
  },
  unmounted() {
    this.resetState();
  },
  setup() {
    const { showErrors } = useShowErrors();
    const permissionManager = useCheckPermissions();
    const isCanEditDataMdm = permissionManager.counterparty.canEditMdmData;
    const currentCounterpartyId = ref<null | string>(null);

    return {
      currentCounterpartyId,
      showErrors,
      isCanEditDataMdm,
    };
  },
});
