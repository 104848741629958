import { AxiosCallResponse, BaseService, LoggerInterface } from '@/api/base';

export interface NewsDataType {
  id: number,
  content: string,
  link: string,
  publishedAt: string | null,
}

export interface NewsItemType {
  [key: string]: NewsDataType;
}

export interface NewsAddType {
  content: string,
  link: string,
  publishedAt: string | null,
}

export interface FilterParams {
  beforeDate?: string | null,
  afterDate?: string | null
}

export interface GetNewsParams {
  page?: number | null,
  perPage?: number | null,
  beforeDate?: string | null,
  afterDate?: string | null,
  sortBy?: string | null
}

export default class NewsService extends BaseService {
  constructor(token: string, logger: LoggerInterface = console) {
    super('news/', token, logger);
  }

  async getNews(params: GetNewsParams = {}): Promise<AxiosCallResponse<NewsItemType>> {
    return this.axiosCall({ method: 'get', params });
  }

  async addNews(data: NewsAddType): Promise<AxiosCallResponse<NewsAddType>> {
    return this.axiosCall({ method: 'post', data });
  }

  async updateNews(data: NewsDataType): Promise<AxiosCallResponse<NewsAddType>> {
    return this.axiosCall({ method: 'patch', data });
  }

  async deleteNews(id: number): Promise<AxiosCallResponse<NewsAddType>> {
    return this.axiosCall({
      method: 'delete',
      data: {
        id,
      },
    });
  }
}
