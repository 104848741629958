import { AxiosCallResponse } from '@/api/base';
import store from '@/store';
import NomenclatureGroupService, { NomenclatureGroupData } from '@/api/admin/dictionary/nomenclatureGroup';

export function useOneApi() {
  return {
    async loadData(id: string) {
      const api: NomenclatureGroupService = store.getters.$api.admin.dictionary.nomenclatureGroup;

      const response: AxiosCallResponse<NomenclatureGroupData> = await api.one(id);

      return response.data ?? null;
    },
  };
}

export default {};
