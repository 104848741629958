import { controlRenderers } from './controls';
import { layoutRenderers } from './layouts';
import { arrayRenderers } from './array';
import { customRenderers } from './custom';

const customVanillaRenderers = [
  ...customRenderers,
  ...controlRenderers,
  ...arrayRenderers,
  ...layoutRenderers,
];

export default customVanillaRenderers;
