import { Module } from 'vuex';
import one, { ConflictHistoryOneStateType } from '@/store/modules/counterparty/block/relationship/conflictHistory/one';
import list, { ConflictHistoryListStateType } from '@/store/modules/counterparty/block/relationship/conflictHistory/list';
import { StateType } from '@/store';

export interface ConflictHistoryStateType {
  list: ConflictHistoryListStateType,
  one: ConflictHistoryOneStateType,
}

const conflictHistoryModule: Module<ConflictHistoryStateType, StateType> = {
  namespaced: true,
  modules: {
    list,
    one,
  },
};

export default conflictHistoryModule;
