import { Module } from 'vuex';
import { StateType } from '@/store';
import list, { AssignManagerListStateType } from '@/store/modules/admin/pinning/managers/list';

export interface AssignManagerStateType {
  list: AssignManagerListStateType,
}

const assignMangerModule: Module<AssignManagerStateType, StateType> = {
  namespaced: true,
  modules: {
    list,
  },
};

export default assignMangerModule;
