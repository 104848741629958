import { RoleAttribute } from '@/api/admin/role';
import {
  AxiosCallResponse,
  BaseService,
  FilterParams,
  LoggerInterface,
  QueryParamsForList,
  ResponseOfRecords,
} from '@/api/base';

export interface UserItem {
  id: string,
  fullName: string,
  avatar: string | null,
  status: number,
  email: string,
  division: string | null,
  department: string | null,
  company: string | null,
  roles: string[],
}

export interface UserDetailData {
  id: string,
  lastName: string,
  firstName: string,
  middleName: string | null,
  avatar: string | null,
  status: number,
  email: string,
  phone: string | null,
  division: string | null,
  department: string | null,
  company: string | null,
  position: string | null,
  roles: string[],
  rolesAttributes: RoleAttribute,
}

export interface UserVerifyData {
  email: string,
  lastName: string | null,
  firstName: string | null,
  middleName: string | null,
  position: string | null,
  division?: string | null,
  department?: string | null,
  company?: string | null,
  phone: string | null,
}

export interface UserCreateData {
  lastName: string,
  firstName: string,
  middleName: string | null,
  email: string,
  position: string | null,
  division: string | null,
  department: string | null,
  phone: string | null,
}

export interface UserUpdateData {
  status: number,
  avatar: string | null,
  lastName: string,
  firstName: string,
  middleName: string | null,
  email: string,
  position: string | null,
  division: string | null,
  department: string | null,
  phone: string | null,
  roles: string[],
  rolesAttributes: RoleAttribute
}

export interface UserStatusUpdate {
  status: number;
}

export interface UserCreateResponse {
  id: string,
}

export interface UserListFilterParams extends FilterParams {
  role?: string | null,
  division?: string | null,
  department?: string | null,
  company?: string | null,
  status?: number | null,
}

export type UserListResponseType = AxiosCallResponse<ResponseOfRecords<UserItem[]>>;

export type UserResponseType = AxiosCallResponse<UserDetailData>;

export type UserVerifyResponseType = AxiosCallResponse<UserVerifyData>;

export type UserCreateResponseType = AxiosCallResponse<UserCreateResponse>;

export type UserListQueryParams = UserListFilterParams | QueryParamsForList;

export default class UserService extends BaseService {
  constructor(token: string, logger: LoggerInterface = console) {
    super('admin/user', token, logger);
  }

  async list(params: UserListQueryParams = {}): Promise<UserListResponseType> {
    return this.axiosCall({ method: 'get', params });
  }

  async one(id: string): Promise<UserResponseType> {
    return this.axiosCall({ url: `/${id}`, method: 'get' }, false);
  }

  async verify(email: string): Promise<UserVerifyResponseType> {
    return this.axiosCall({ url: '/verify', method: 'post', data: { email } }, false);
  }

  async create(data: UserCreateData): Promise<UserCreateResponseType> {
    return this.axiosCall({ method: 'post', data });
  }

  async update(id: string, data: UserUpdateData): Promise<AxiosCallResponse<null>> {
    return this.axiosCall({ url: `/${id}`, method: 'patch', data });
  }

  async blockUser(id: string): Promise<AxiosCallResponse<null>> {
    return this.axiosCall({ url: `/${id}/block`, method: 'post' });
  }

  async unblockUser(id: string): Promise<AxiosCallResponse<null>> {
    return this.axiosCall({ url: `/${id}/unblock`, method: 'post' });
  }
}
