
import { defineComponent, PropType } from 'vue';
import TheForm, {
  PinningAssignForm,
} from '@/components/counterparty/list/modal/assign/form/TheForm.vue';
import DefaultButton from '@/components/ui/buttons/DefaultButton/DefaultButton.vue';
import { DivisionItem, StructureItem } from '@/api/admin/dictionary/structure';
import { UserItem, UserListFilterParams } from '@/api/admin/user';
import { PinningItem } from '@/api/admin/pinning';
import JsonData from '@/components/jsonforms/interfaces/JsonData';

export default defineComponent({
  name: 'TheAssign',
  emits: ['submit', 'create', 'updateData', 'ajaxUsers'],
  components: {
    TheForm,
    DefaultButton,
  },
  props: {
    data: {
      type: Object as PropType<JsonData>,
      default: () => ({}),
    },
    divisions: {
      type: Array as PropType<DivisionItem[]>,
      default: () => ([]),
    },
    departments: {
      type: Array as PropType<StructureItem[]>,
      default: () => ([]),
    },
    users: {
      type: Array as PropType<UserItem[]>,
      default: () => ([]),
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    defaultOneOfList() {
      return [{ const: null, title: '' }];
    },
    preparedDivisions() {
      if (this.divisions.length > 0) {
        return [
          ...this.divisions.map((division: DivisionItem) => ({
            const: division.id,
            title: division.name,
          })),
        ];
      }

      return this.defaultOneOfList;
    },
    preparedDepartments() {
      if (this.departments.length > 0) {
        return [
          ...this.defaultOneOfList,
          ...this.departments.map((department: StructureItem) => ({
            const: department.id,
            title: department.name,
          })),
        ];
      }

      return this.defaultOneOfList;
    },
    preparedUsers() {
      if (this.users.length > 0) {
        return [
          ...this.defaultOneOfList,
          ...this.users.map((user: UserItem) => ({
            const: user.id,
            title: user.fullName,
          })),
        ];
      }

      return this.defaultOneOfList;
    },
  },
  methods: {
    updateDataHandler(data: PinningItem) {
      this.$emit('updateData', data);
    },
    cancelHandler() {
      this.$emit('cancel');
    },
    ajaxUsersHandler(filter: UserListFilterParams) {
      this.$emit('ajaxUsers', filter);
    },
    submitHandler() {
      this.isLoading = true;

      const formRef: PinningAssignForm = this.$refs.form as PinningAssignForm;
      const valid: boolean = formRef?.validateForm(true) ?? false;
      const data: JsonData | null = formRef?.currentData ?? null;

      if (!valid || !data) {
        this.isLoading = false;

        return false;
      }

      this.$emit('submit', data, () => {
        this.isLoading = false;
      });

      return true;
    },
  },
});
