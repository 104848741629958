import { Module } from 'vuex';
import PinningService, {
  AssignFilterParams,
  AssignQueryForList,
  PinningItem,
  PinningResponseType,
} from '@/api/admin/pinning';
import {
  MetaParams,
  SortParams,
} from '@/api/base';
import { StateType } from '@/store';

export interface PinningListStateType {
  items: PinningItem[],
  filter: AssignFilterParams,
  sort: SortParams,
  pagination: MetaParams,
}

const getInitialState = (): PinningListStateType => ({
  items: [],
  pagination: {
    currentPage: null,
    pageCount: null,
    perPage: null,
    totalCount: null,
  },
  filter: {
    query: null,
  },
  sort: {
    sortBy: null,
  },
});

const initialState = getInitialState();

const pinningListModule: Module<PinningListStateType, StateType> = {
  namespaced: true,
  state: initialState,
  getters: {
    items: (state): PinningItem[] => state.items,
    filter: (state): AssignFilterParams => state.filter,
    sort: (state): SortParams => state.sort,
    pagination: (state): MetaParams => state.pagination,
    loadParams: (state): AssignQueryForList => ({
      page: state.pagination.currentPage,
      perPage: state.pagination.perPage,
      query: state.filter.query,
      sortBy: state.sort.sortBy,
      divisionId: state.filter.divisionId,
      departmentId: state.filter.departmentId,
      userId: state.filter.userId,
    }),
  },
  mutations: {
    setItems: (state, items) => {
      state.items = items;
    },
    setFilter: (state, filter) => {
      state.filter = filter;
    },
    setSort: (state, sort) => {
      state.sort = sort;
    },
    setPagination: (state, pagination) => {
      state.pagination = pagination;
    },
    resetState: (state) => {
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    resetState({ commit }) {
      commit('resetState');
    },
    async changePagination({ dispatch, commit }, pagination: MetaParams) {
      commit('setPagination', pagination);

      await dispatch('loadItems');
    },
    async changeFilter({ commit, state }, filter: AssignFilterParams) {
      commit('setFilter', filter);
      commit('setPagination', { ...state.pagination, currentPage: 1 });
    },
    async changeSort({ dispatch, commit }, sort: SortParams) {
      commit('setSort', sort);

      await dispatch('loadItems');
    },
    async loadItems({ getters, rootGetters, commit }) {
      const api: PinningService = rootGetters.$api.admin.pinning;
      const { loadParams } = getters;

      const response: PinningResponseType = await api.list(loadParams);

      commit('setItems', response.data?.items ?? initialState.items);
      commit('setPagination', response.data?._meta ?? initialState.pagination);
    },
  },
};

export default pinningListModule;
