import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7abbcce0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "modal-content h-full"
}
const _hoisted_2 = { class: "loader-block" }
const _hoisted_3 = {
  key: 1,
  class: "modal-content"
}
const _hoisted_4 = { class: "modal-block relative" }
const _hoisted_5 = { class: "text-[24px] font-[700] mb-[8px]" }
const _hoisted_6 = { class: "subtext" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 2 }
const _hoisted_10 = {
  key: 0,
  class: "subtext"
}
const _hoisted_11 = {
  key: 0,
  class: "absolute right-[-6px] bottom-4"
}
const _hoisted_12 = { class: "modal-block" }
const _hoisted_13 = { class: "text-[18px] font-semibold mb-[24px]" }
const _hoisted_14 = { class: "contact-list" }
const _hoisted_15 = { class: "address-item" }
const _hoisted_16 = { class: "w-[20px] h-[20px] flex items-center justify-center mr-[24px]" }
const _hoisted_17 = { class: "subtext" }
const _hoisted_18 = { class: "address-item" }
const _hoisted_19 = { class: "w-[20px] h-[20px] flex items-center justify-center mr-[24px]" }
const _hoisted_20 = { class: "subtext" }
const _hoisted_21 = {
  key: 0,
  class: "site-item"
}
const _hoisted_22 = { class: "w-[20px] h-[20px] flex items-center justify-center mr-[24px]" }
const _hoisted_23 = ["href"]
const _hoisted_24 = { class: "subtext" }
const _hoisted_25 = { class: "modal-block" }
const _hoisted_26 = { class: "text-[18px] font-semibold mb-[20px]" }
const _hoisted_27 = { class: "users-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_default_loader = _resolveComponent("default-loader")!
  const _component_edit_icon = _resolveComponent("edit-icon")!
  const _component_icon_button = _resolveComponent("icon-button")!
  const _component_home_icon = _resolveComponent("home-icon")!
  const _component_factory_icon = _resolveComponent("factory-icon")!
  const _component_international_icon = _resolveComponent("international-icon")!
  const _component_user_item = _resolveComponent("user-item")!

  return (_ctx.isLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_default_loader)
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.counterpartyName), 1),
          _createElementVNode("div", null, [
            _createElementVNode("p", _hoisted_6, [
              (_ctx.inn)
                ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t('counterpartyLabels.inn')) + " " + _toDisplayString(_ctx.inn), 1))
                : _createCommentVNode("", true),
              (_ctx.taxNumber)
                ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$t('counterpartyLabels.taxNumber')) + " " + _toDisplayString(_ctx.taxNumber), 1))
                : _createCommentVNode("", true),
              (_ctx.kpp)
                ? (_openBlock(), _createElementBlock("span", _hoisted_9, " , " + _toDisplayString(_ctx.$t('counterpartyLabels.kpp')) + " " + _toDisplayString(_ctx.kpp), 1))
                : _createCommentVNode("", true)
            ]),
            (_ctx.counterpartyRegNumber)
              ? (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(_ctx.$t('counterpartyLabels.regNumber')) + " " + _toDisplayString(_ctx.counterpartyRegNumber), 1))
              : _createCommentVNode("", true)
          ]),
          (_ctx.isCanEditDataMdm)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createVNode(_component_icon_button, {
                  description: _ctx.$t('jsonforms.buttons.editMdmData'),
                  onClick: _ctx.openEditMdmData
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_edit_icon)
                  ]),
                  _: 1
                }, 8, ["description", "onClick"])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t('counterpartyLabels.contacts')), 1),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, [
                _createVNode(_component_home_icon, {
                  width: "18",
                  height: "18"
                })
              ]),
              _createElementVNode("div", null, [
                _createTextVNode(_toDisplayString(_ctx.counterpartyLegalAddress) + " ", 1),
                _createElementVNode("span", _hoisted_17, " :: " + _toDisplayString(_ctx.$t('counterpartyLabels.legalAddress')), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("div", _hoisted_19, [
                _createVNode(_component_factory_icon, {
                  width: "18",
                  height: "18"
                })
              ]),
              _createElementVNode("div", null, [
                _createTextVNode(_toDisplayString(_ctx.counterpartyActualAddress) + " ", 1),
                _createElementVNode("span", _hoisted_20, " :: " + _toDisplayString(_ctx.$t('counterpartyLabels.actualAddress')), 1)
              ])
            ]),
            (!!_ctx.counterpartySite)
              ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                  _createElementVNode("div", _hoisted_22, [
                    _createVNode(_component_international_icon, {
                      width: "18",
                      height: "18"
                    })
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("a", {
                      class: "link",
                      target: "_blank",
                      href: _ctx.counterpartySite
                    }, _toDisplayString(_ctx.counterpartySite), 9, _hoisted_23),
                    _createElementVNode("span", _hoisted_24, " :: " + _toDisplayString(_ctx.$t('counterpartyLabels.site')), 1)
                  ])
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_25, [
          _createElementVNode("div", _hoisted_26, _toDisplayString(_ctx.$t('counterpartyLabels.managers')), 1),
          _createElementVNode("div", _hoisted_27, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.managers, (manager) => {
              return (_openBlock(), _createBlock(_component_user_item, {
                key: manager.id,
                manager: manager
              }, null, 8, ["manager"]))
            }), 128))
          ])
        ])
      ]))
}