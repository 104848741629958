
import _ from 'lodash';
import { defineComponent, PropType } from 'vue';
import CloseIcon from '@/components/ui/icons/CloseIcon.vue';
import SearchIcon from '@/components/ui/icons/SearchIcon.vue';
import SelectDefault, { DataType } from '@/components/ui/selects/SelectDefault/SelectDefault.vue';
import { FilterStructureParams } from '@/api/admin/dictionary/structure';
import IconButton from '@/components/ui/buttons/IconButton/IconButton.vue';

export default defineComponent({
  name: 'TheFilter',
  inject: ['$sizeWindow', '$userAccess'],
  emits: ['filterChanged', 'queryChanged', 'ajaxCompany'],
  components: {
    IconButton,
    CloseIcon,
    SearchIcon,
    SelectDefault,
  },
  props: {
    filter: {
      type: Object as PropType<FilterStructureParams>,
      default: () => ({
        query: '',
        companyId: '',
      }),
    },
    label: {
      type: String,
      default: '',
    },
    companyId: {
      type: String,
      default: '',
    },
    clearButton: {
      type: Boolean,
      default: true,
    },
    companies: {
      type: Array as PropType<DataType[]>,
      default: () => ([]),
    },
    roles: {
      type: Array as PropType<DataType[]>,
      default: () => ([]),
    },
  },
  data() {
    return {
      query: '',
      localCompanyId: this.companyId,
      isPress: false,
    };
  },
  computed: {
    isShowCompanyFilter() {
      return this.$userAccess.admin.isSuperAdmin;
    },
    newFilter() {
      return {
        query: this.query,
        companyId: this.localCompanyId,
      };
    },
  },
  methods: {
    searchInputHandler: _.debounce(async function handler(e: { target: HTMLInputElement }) {
      this.query = String(e.target.value);

      this.$emit('queryChanged', this.query);
    }, 500),
    companyChange(value: string) {
      this.localCompanyId = value;

      this.$emit('filterChanged', this.newFilter);
    },
    clearSearch() {
      this.query = '';
      this.localCompanyId = this.companyId;

      this.$emit('filterChanged', this.newFilter);
    },
    ajaxCompanyHandler(query: string) {
      this.$emit('ajaxCompany', query);
    },
  },
});
