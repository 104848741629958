import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f7e8544a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "icon-edit-button"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 2,
  class: "block-info"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 3,
  class: "block-action-button"
}
const _hoisted_7 = { class: "flex space-x-[12px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_edit_icon = _resolveComponent("edit-icon")!
  const _component_icon_button = _resolveComponent("icon-button")!
  const _component_the_filter = _resolveComponent("the-filter")!
  const _component_form_generator = _resolveComponent("form-generator")!
  const _component_default_loader = _resolveComponent("default-loader")!
  const _component_default_button = _resolveComponent("default-button")!
  const _component_block_default = _resolveComponent("block-default")!

  return (_openBlock(), _createBlock(_component_block_default, {
    text: _ctx.title,
    class: "relative"
  }, {
    default: _withCtx(() => [
      (_ctx.isCanEdit)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (!_ctx.isEdit)
              ? (_openBlock(), _createBlock(_component_icon_button, {
                  key: 0,
                  description: _ctx.$t('jsonforms.buttons.edit'),
                  onClick: _withModifiers(_ctx.toggleEdit, ["stop"])
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_edit_icon)
                  ]),
                  _: 1
                }, 8, ["description", "onClick"]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_the_filter, {
        class: "mb-[48px]",
        "nomenclature-group": _ctx.preparedNomenclatureGroup,
        "years-list": _ctx.preparedYearList,
        filter: _ctx.filter,
        onChangeFilter: _ctx.changeFilterHandler,
        onSwitchSlice: _ctx.switchFilterTypeHandler
      }, null, 8, ["nomenclature-group", "years-list", "filter", "onChangeFilter", "onSwitchSlice"]),
      (_ctx.isShowTable)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (!_ctx.isLoading)
              ? (_openBlock(), _createBlock(_component_form_generator, {
                  key: 0,
                  uischema: _ctx.uischema,
                  schema: _ctx.schema,
                  information: this.currentData,
                  "additional-errors": _ctx.errors,
                  config: { onlyText: !_ctx.isEdit },
                  "validation-mode": "ValidateAndHide",
                  onChange: _ctx.onChange
                }, null, 8, ["uischema", "schema", "information", "additional-errors", "config", "onChange"]))
              : (_openBlock(), _createBlock(_component_default_loader, { key: 1 }))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (!_ctx.isEdit)
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t('counterparty.consumptionRegistry.info')), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t('counterparty.consumptionRegistry.infoEdit')), 1))
          ])),
      (_ctx.isEdit)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_default_button, { onClick: _ctx.closeEdit }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('buttons.cancel')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_default_button, {
                type: "primary",
                disabled: !_ctx.needConfirmation || _ctx.isLoading || !_ctx.isShowTable,
                onClick: _ctx.saveDataHandler
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('buttons.save')), 1)
                ]),
                _: 1
              }, 8, ["disabled", "onClick"])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["text"]))
}