import _ from 'lodash';
import { RoleBlock, RoleGroup, RoleItem } from '@/api/admin/role';

export default class RoleItemHelper {
  static getRoleByParent(parent: RoleItem, roleId: string): RoleItem | undefined {
    const children: RoleItem[] = parent?.children ?? [];

    for (const role of children) {
      if (role.id === roleId) {
        return role;
      }

      const childRole: RoleItem | undefined = RoleItemHelper.getRoleByParent(role, roleId);

      if (childRole) {
        return childRole;
      }
    }

    return undefined;
  }

  static getRoleByBlock(block: RoleBlock, roleId: string): RoleItem | undefined {
    for (const role of block.items) {
      if (role.id === roleId) {
        return role;
      }

      const childRole: RoleItem | undefined = RoleItemHelper.getRoleByParent(role, roleId);

      if (childRole) {
        return childRole;
      }
    }

    return undefined;
  }

  static getRoleChildrenIds(parentRole: RoleItem): string[] {
    const recursFunc = (role: RoleItem): string[] => {
      if (!role.children) {
        return [role.id];
      }

      return _.flattenDeep([
        role.id,
        ...role.children.map((child: RoleItem) => recursFunc(child)),
      ]);
    };

    return recursFunc(parentRole)
      .filter((item: string) => item !== parentRole.id);
  }

  static getRootParent(block: RoleBlock, roleId: string) {
    return block.items.find((item: RoleItem) => !!RoleItemHelper.getRoleByParent(item, roleId));
  }

  static getRoleParents(block: RoleBlock, roleId: string): RoleItem[] {
    const rootParent: RoleItem | undefined = RoleItemHelper.getRootParent(block, roleId);

    if (rootParent) {
      const parents: RoleItem[] = [];
      const recursFunc = (parent: RoleItem) => {
        const children = parent.children ?? [];

        if (RoleItemHelper.getRoleByParent(parent, roleId)) {
          parents.push(parent);
        }

        for (const child of children) {
          if (RoleItemHelper.getRoleByParent(child, roleId)) {
            parents.push(child);
          }
        }
      };

      recursFunc(rootParent);

      return parents;
    }

    return [];
  }

  static getLinearListOfRoles(roles: RoleGroup[]): RoleItem[] {
    const newRoles: RoleItem[][] = [];

    const getAllRoles = (role: RoleItem): RoleItem[] => {
      if (role.children && role.children.length) {
        const allChildren = _.flattenDeep(role.children.map((
          item: RoleItem,
        ) => getAllRoles(item))) as RoleItem[];

        return [
          role,
          ...allChildren,
        ];
      }

      return [role];
    };

    roles.forEach((
      group: RoleGroup,
    ) => group.items.forEach((block: RoleBlock) => block.items.forEach((role: RoleItem) => {
      newRoles.push(getAllRoles(role));
    })));

    return _.flattenDeep(newRoles) as RoleItem[];
  }

  static getRoleParentsIds(block: RoleBlock, roleId: string): string[] {
    const parents: RoleItem[] = RoleItemHelper.getRoleParents(block, roleId);

    return parents.map((item: RoleItem) => item.id);
  }
}
