import { createStore, Store } from 'vuex';
import api, { ApiServices } from '@/api';
import news, { NewsStateType } from '@/store/modules/news';
import notifications, { NotificationsStateType } from '@/store/modules/notifications';
import payment, { PaymentStateType } from '@/store/modules/payment';
import schema, { SchemaStateType } from '@/store/modules/schema';
import user, { UserStateType } from '@/store/modules/user';
import NotificationLogger, { NotificationLoggerInterface } from '@/utils/helpers/NotificationLogger';
import admin, { AdminStateType } from '@/store/modules/admin';
import history, { HistoryStateType } from '@/store/modules/counterparty/block/history';
import search, { SearchStateType } from '@/store/modules/search';
import ApiErrorLogger from '@/utils/adapters/ApiErrorLogger';
import counterparty, { CounterpartyStateType } from './modules/counterparty';

export interface StateType {
  counterparty: CounterpartyStateType,
  history: HistoryStateType,
  user: UserStateType,
  schema: SchemaStateType,
  news: NewsStateType,
  payment: PaymentStateType,
  notifications: NotificationsStateType,
  admin: AdminStateType,
  search: SearchStateType,
}

const store: Store<StateType> = createStore({
  getters: {
    $api: (state, getters): ApiServices => {
      const logger = new ApiErrorLogger(getters.$notificationLogger);

      return api(state.user.token ?? '', logger);
    },
    $notificationLogger: (): NotificationLoggerInterface => new NotificationLogger(store),
  },
  modules: {
    counterparty, schema, user, news, payment, notifications, admin, history, search,
  },
});

export default store;
