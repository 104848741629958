import './assets/tailwind.css';
import VuePlausible from '@/shared/plugins/plausible';
import VueKeycloakJs from '@dsb-norge/vue-keycloak-js';
import { createApp } from 'vue';
import KeycloakInstance from 'keycloak-js';
import { VueKeycloakInstance } from '@dsb-norge/vue-keycloak-js/dist/types';
import NotificationCenterPlugin from '@novu/notification-center-vue';
import { WindowSize } from '@/App';
import FormGenerator from '@/components/jsonforms/generators/FormGenerator.vue';
import { UserAccess } from '@/composables/useUserAccess';
import vMaskV3 from '@/interfaces/mask/v-mask';
import { NotificationLoggerInterface } from '@/utils/helpers/NotificationLogger';
import i18n from './i18n';
import store from './store';
import router from './router';
import App from './App.vue';

const app = createApp(App);

const loadKeycloakData = async (keycloak: KeycloakInstance) => {
  await store.dispatch('user/loadUserData', keycloak.tokenParsed ?? {});
  await store.dispatch('user/loadUserToken', keycloak.token ?? null);
  await store.dispatch('user/loadUserLocalData');
};

app.use(VueKeycloakJs, {
  init: {
    onLoad: 'login-required',
  },
  config: {
    url: process.env.VUE_APP_KEYCLOAK_URL,
    realm: process.env.VUE_APP_KEYCLOAK_REALM_NAME,
    clientId: process.env.VUE_APP_KEYCLOAK_CLIENT_ID,
  },
  async onAuthRefreshSuccess(keycloak: KeycloakInstance) {
    await loadKeycloakData(keycloak);
  },
  async onReady(keycloak: KeycloakInstance) {
    await loadKeycloakData(keycloak);

    if (process.env.VUE_APP_ANALYTICS_IS_ENABLED === 'true') {
      app.use(VuePlausible, {
        domain: process.env.VUE_APP_ANALYTICS_DOMAIN,
        apiHost: process.env.VUE_APP_ANALYTICS_HOST,
      });
    }

    app
      .use(store)
      .use(i18n)
      .use(NotificationCenterPlugin)
      .use(router)
      .directive('mask', vMaskV3)
      .component('form-generator', FormGenerator)
      .mount('#app');
  },
});

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $keycloak: VueKeycloakInstance,
    $notificationLogger: NotificationLoggerInterface,
    $userAccess: UserAccess,
    $sizeWindow: WindowSize,
  }
}
