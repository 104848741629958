import { JsonSchema } from '@jsonforms/core';
import { defineComponent, PropType } from 'vue';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import DefaultButton from '@/components/ui/buttons/DefaultButton/DefaultButton.vue';
import CreateForm, { CounterpartyCreateForm } from '@/components/counterparty/list/modal/create/content/form/TheForm.vue';

export default defineComponent({
  name: 'ManuallyForm',
  inject: ['$sizeWindow'],
  emits: ['submit', 'cancel'],
  components: {
    CreateForm,
    DefaultButton,
  },
  props: {
    schema: {
      required: true,
      type: Object as PropType<JsonSchema>,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    submitHandler() {
      this.isLoading = true;

      const formRef: CounterpartyCreateForm = this.$refs.form as CounterpartyCreateForm;
      const valid: boolean = formRef?.validateForm(true) ?? false;
      const data: JsonData | null = formRef?.currentData ?? null;

      if (!valid || !data) {
        this.isLoading = false;

        return false;
      }

      this.$emit('submit', data, () => {
        this.isLoading = false;
      });

      return true;
    },
    cancelHandler() {
      this.$emit('cancel');
    },
  },
});
