import { TourManagerType } from '@/utils/tours/useTour';
import { BoardingSteps } from 'boarding.js';
import HighlightElement from 'boarding.js/dist/core/highlight-element';

export const getSteps = (tour: TourManagerType<BoardingSteps>): BoardingSteps => ([
  {
    element: '.tabs-menu-block',
    popover: {
      className: 'first-step-popover-class',
      title: 'Панель навигации по вкладкам',
      description: '',
      prefferedSide: 'bottom',
    },
  },
  {
    element: '.tabs-menu-item.active',
    popover: {
      className: 'first-step-popover-class',
      title: 'Активная вкладка',
      description: '',
      prefferedSide: 'bottom',
    },
    onNext: () => {
      const blocks = document.querySelectorAll('.blocks .block:not(.block-hidden)');

      blocks.forEach((item) => {
        const element = item.querySelector('.block-header') as HTMLElement;

        element.click();
      });
    },
  },
  {
    element: '.blocks',
    popover: {
      className: 'first-step-popover-class',
      title: 'Список блоков с данными',
      description: '',
      prefferedSide: 'top',
    },
  },
  {
    element: '.block-item .block',
    popover: {
      className: 'first-step-popover-class',
      title: 'Раскрытие блока',
      description: 'При клике на область закрытого блока, он раскроется.',
      prefferedSide: 'top',
      doneBtnText: 'Вперед',
    },
    onNext(element: HighlightElement) {
      const el = element.getElement() as HTMLElement;
      el.click();
    },
  },
  {
    element: '.block-item',
    popover: {
      className: 'first-step-popover-class',
      title: 'Форма для заполнения данных',
      description: 'Блок типа <strong>Форма</strong> предлагает пользователю перейти сразу в режим редактирования.',
      prefferedSide: 'top',
      doneBtnText: 'Вперед',
    },
    onPrevious(element: HighlightElement) {
      const el = element.getElement() as HTMLElement;
      const parentElement = el.closest('.block')?.querySelector('.block-header') as HTMLElement;

      parentElement.click();
    },
  },
  {
    element: '.block-header .tooltip',
    popover: {
      className: 'first-step-popover-class',
      title: 'История',
      description: 'Нажав на эту кнопку вы можете перейти к истории блока.',
      prefferedSide: 'left',
    },
  },
  {
    element: '.block-header .tooltip:nth-child(2)',
    popover: {
      className: 'first-step-popover-class',
      title: 'Редактирование',
      description: 'Нажав на эту кнопку вы можете перейти к редактированию блока.',
      prefferedSide: 'left',
    },
    onNext(element: HighlightElement) {
      const el = element.getElement() as HTMLElement;
      el.click();

      tour.delay(100);
    },
  },
  {
    element: '.footer-actions .btn:nth-child(1)',
    popover: {
      className: 'first-step-popover-class',
      title: 'Отмена',
      description: 'Нажав на эту кнопку вы можете отменить все изменения и выйти из редактирования.',
      prefferedSide: 'left',
    },
    onPrevious(element: HighlightElement) {
      const el = element.getElement() as HTMLElement;
      el.click();

      tour.delay(200);
    },
  },
  {
    element: '.footer-actions .btn:nth-child(2)',
    popover: {
      className: 'first-step-popover-class',
      title: 'Сохранить',
      description: 'Нажав на эту кнопку вы сохраните все данные и выйдете из редактирования.',
      prefferedSide: 'left',
    },
    onNext() {
      const el = document.querySelector('.footer-actions .btn:nth-child(1)') as HTMLElement;
      el.click();
    },
  },
  {
    element: '.block-header',
    popover: {
      className: 'first-step-popover-class',
      title: 'Свернуть блок',
      description: 'Чтобы свернуть блок, нажмите на его загаловок',
      prefferedSide: 'bottom',
    },
    onPrevious(element: HighlightElement) {
      const el = element.getElement().querySelector('.tooltip:nth-child(2)') as HTMLElement;
      el.click();

      tour.delay(200);
    },
    onNext(element: HighlightElement) {
      const el = element.getElement() as HTMLElement;
      el.click();
    },
  },
  {
    element: '.blocks .block-item:nth-child(2)',
    popover: {
      className: 'first-step-popover-class',
      title: 'Список для заполнения данных по форме',
      description: 'Блок типа <strong>Список</strong> предлагает пользователю добавить заполненные формы.',
      prefferedSide: 'left',
    },
    onPrevious() {
      const el = document.querySelector('.block-header') as HTMLElement;
      el.click();

      tour.delay(200);
    },
    onNext(element: HighlightElement) {
      const el = element.getElement() as HTMLElement;
      const header = el.querySelector('.block-header') as HTMLElement;

      header.click();
    },
  },
  {
    element: '.blocks .block-item:nth-child(2) .block-content',
    popover: {
      className: 'first-step-popover-class',
      title: 'Список добавленых элементов',
      description: 'В этом списке будут показываться добавленные записи.',
      prefferedSide: 'left',
    },
  },
  {
    element: '.blocks .block-item:nth-child(2) .item',
    popover: {
      className: 'first-step-popover-class',
      title: 'Просмотр и редактирования',
      description: 'Для просмотра информации о записи нажмите на нее. Для редактирования, удаления или блокировки записи нажмите на соответсвующие кнопки внутри открывшегося окна.',
      prefferedSide: 'left',
    },
  },
  {
    element: '.blocks .block-item:nth-child(2) .add-button',
    popover: {
      className: 'first-step-popover-class',
      title: 'Добавление новой записи',
      description: 'Для добавления новой записи, нажмите на эту кнопку и заполните форму.',
      prefferedSide: 'left',
    },
    onNext(element: HighlightElement) {
      const el = element.getElement() as HTMLElement;
      const parentElement = el.closest('.block')?.querySelector('.block-header') as HTMLElement;

      if (parentElement) {
        parentElement.click();
      }
    },
  },
]);

export default {
  getSteps,
};
