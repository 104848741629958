import { OUTLINE_TYPE, REGULAR_TYPE, SOLID_TYPE } from '@/components/ui/icons/types';

const iconsMixin = {
  props: {
    type: {
      type: String,
      default: SOLID_TYPE,
    },
    width: {
      type: String,
      default: '24',
    },
    height: {
      type: String,
      default: '24',
    },
    color: {
      type: String,
      default: '#64748B',
    },
  },
  computed: {
    isSolid() {
      return this.type === SOLID_TYPE;
    },
    isRegular() {
      return this.type === REGULAR_TYPE;
    },
    isOutline() {
      return this.type === OUTLINE_TYPE;
    },
  },
};

export default iconsMixin;
