import _ from 'lodash';
import { Module } from 'vuex';
import PsychologicalCharacteristicService, {
  PsychologicalCharacteristicSchema,
  PsychologicalCharacteristicData,
} from '@/api/admin/dictionary/psychologicalCharacteristic';
import { AxiosCallResponse } from '@/api/base';
import { TYPE_LABEL_SUCCESS } from '@/components/ui/notifications/TheAlert/TheAlert.vue';
import i18n from '@/i18n';
import { StateType } from '@/store';

export interface PsychologicalCharacteristicOneStateType {
  data: PsychologicalCharacteristicSchema | null,
}

const initialState = {
  data: null,
};

const characteristicOneModule: Module<PsychologicalCharacteristicOneStateType, StateType> = {
  namespaced: true,
  state: _.cloneDeep(initialState),
  getters: {
    data: (state) => state.data,
  },
  actions: {
    async create({ rootGetters }, data: PsychologicalCharacteristicData) {
      const api: PsychologicalCharacteristicService = rootGetters
        .$api
        .admin
        .dictionary
        .psychologicalCharacteristic;

      const response: AxiosCallResponse<PsychologicalCharacteristicSchema> = await api.create(data);

      if (!response.error) {
        await rootGetters
          .$notificationLogger
          .setNotification(
            TYPE_LABEL_SUCCESS,
            i18n.global.t('notification.admin.dictionary.success.create'),
          );
      }
    },
    async update(
      { rootGetters },
      params: { id: string, data: PsychologicalCharacteristicData },
    ) {
      const api: PsychologicalCharacteristicService = rootGetters
        .$api
        .admin
        .dictionary
        .psychologicalCharacteristic;

      const response: AxiosCallResponse<null> = await api.update(params.id, params.data);

      if (!response.error) {
        await rootGetters
          .$notificationLogger
          .setNotification(
            TYPE_LABEL_SUCCESS,
            i18n.global.t('notification.admin.dictionary.success.update'),
          );
      }
    },
    async delete({ rootGetters }, id: string) {
      const api: PsychologicalCharacteristicService = rootGetters
        .$api
        .admin
        .dictionary
        .psychologicalCharacteristic;

      const response: AxiosCallResponse<null> = await api.delete(id);

      if (!response.error) {
        await rootGetters
          .$notificationLogger
          .setNotification(
            TYPE_LABEL_SUCCESS,
            i18n.global.t('notification.admin.dictionary.success.delete'),
          );
      }
    },
    async restore({ rootGetters }, id: string) {
      if (id) {
        const api: PsychologicalCharacteristicService = rootGetters
          .$api
          .admin
          .dictionary
          .psychologicalCharacteristic;

        const response: AxiosCallResponse<null> = await api.restore(id);

        if (!response.error) {
          await rootGetters
            .$notificationLogger
            .setNotification(
              TYPE_LABEL_SUCCESS,
              i18n.global.t('notification.admin.dictionary.success.restore'),
            );
        }
      }
    },
  },
};

export default characteristicOneModule;
