import {
  AxiosCallResponse, BaseService, LoggerInterface, PaginationParams, ResponseOfRecords,
} from '@/api/base';
import JsonData from '@/components/jsonforms/interfaces/JsonData';

export type KeyProductionAssetsSchema = {
  id: string,
} & JsonData;

export type KeyProductionAssetsListResponseType = AxiosCallResponse<
    ResponseOfRecords<KeyProductionAssetsSchema[]>
>;

export default class KeyProductionAssetsService extends BaseService {
  constructor(token: string, logger: LoggerInterface) {
    super('card', token, logger, 'v2');
  }

  async list(
    cardId: string,
    params: PaginationParams = {},
  ): Promise<KeyProductionAssetsListResponseType> {
    return this.axiosCall({ url: `/${cardId}/key-production-assets`, method: 'get', params });
  }

  async create(
    cardId: string,
    data: KeyProductionAssetsSchema,
  ): Promise<AxiosCallResponse<KeyProductionAssetsSchema>> {
    return this.axiosCall({ url: `/${cardId}/key-production-assets`, method: 'post', data });
  }

  async update(
    cardId: string,
    id: string,
    data: KeyProductionAssetsSchema,
  ): Promise<AxiosCallResponse<null>> {
    return this.axiosCall({
      url: `/${cardId}/key-production-assets/${id}`,
      method: 'patch',
      data,
    });
  }

  async delete(
    cardId: string,
    id: string,
  ): Promise<AxiosCallResponse<null>> {
    return this.axiosCall({
      url: `/${cardId}/key-production-assets/${id}`,
      method: 'delete',
    });
  }
}
