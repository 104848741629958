<template>
  <div class="title-block" aria-label="user-list-title">
    <h1>{{ $t('admin.user.list.title') }}</h1>

    <div v-if="isCanEdit">
      <default-button
        v-if="!$sizeWindow.isSm"
        @click="buttonClickHandler"
      >
        <template #icon>
          <add-icon width="12" height="12"/>
        </template>

        {{ $t('buttons.add') }}
      </default-button>

      <icon-button
          v-else
          @click="buttonClickHandler"
          border="default"
      >
        <add-icon width="16" height="16"/>
      </icon-button>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import AddIcon from '@/components/ui/icons/AddIcon.vue';
import IconButton from '@/components/ui/buttons/IconButton/IconButton';
import DefaultButton from '@/components/ui/buttons/DefaultButton/DefaultButton.vue';

export default defineComponent({
  name: 'TheHeader',
  inject: ['$sizeWindow'],
  emits: ['button-click'],
  components: {
    DefaultButton,
    AddIcon,
    IconButton,
  },
  props: {
    isCanEdit: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    buttonClickHandler() {
      this.$emit('button-click');
    },
  },
});
</script>

<style scoped lang="scss">
@import "src/components/ui/consts.scss";

.title-block {
  @apply w-full h-[32px] flex justify-between items-center;
}

h1 {
  @apply text-[20px] md:text-[32px] font-bold;
  color: $label-color;
}
</style>
