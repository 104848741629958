import _ from 'lodash';
import { Module } from 'vuex';
import IndustryService, { IndustryData } from '@/api/admin/dictionary/industry';
import { AxiosCallResponse } from '@/api/base';
import { TYPE_LABEL_SUCCESS } from '@/components/ui/notifications/TheAlert/TheAlert.vue';
import i18n from '@/i18n';
import { StateType } from '@/store';

export interface IndustryOneStateType {
  data: IndustryData | null,
}

const initialState = {
  data: null,
};

const industryOneModule: Module<IndustryOneStateType, StateType> = {
  namespaced: true,
  state: _.cloneDeep(initialState),
  getters: {
    data: (state) => state.data,
  },
  mutations: {
    setData(state, item: IndustryData) {
      state.data = item;
    },
  },
  actions: {
    async create({ rootGetters }, data: IndustryData) {
      const api: IndustryService = rootGetters.$api.admin.dictionary.industry;

      const response: AxiosCallResponse<IndustryData> = await api.create(data);

      if (!response.error) {
        await rootGetters
          .$notificationLogger
          .setNotification(
            TYPE_LABEL_SUCCESS,
            i18n.global.t('notification.admin.dictionary.success.create'),
          );
      }
    },
    async update(
      { rootGetters },
      params: { id: string, data: IndustryData },
    ) {
      const api: IndustryService = rootGetters.$api.admin.dictionary.industry;

      const response: AxiosCallResponse<null> = await api.update(params.id, params.data);

      if (!response.error) {
        await rootGetters
          .$notificationLogger
          .setNotification(
            TYPE_LABEL_SUCCESS,
            i18n.global.t('notification.admin.dictionary.success.update'),
          );
      }
    },
    async delete({ rootGetters }, id: string) {
      const api: IndustryService = rootGetters.$api.admin.dictionary.industry;

      const response: AxiosCallResponse<null> = await api.delete(id);

      if (!response.error) {
        await rootGetters
          .$notificationLogger
          .setNotification(
            TYPE_LABEL_SUCCESS,
            i18n.global.t('notification.admin.dictionary.success.delete'),
          );
      }
    },
    async restore({ rootGetters }, id: string) {
      if (id) {
        const api: IndustryService = rootGetters.$api.admin.dictionary.industry;

        const response: AxiosCallResponse<null> = await api.restore(id);

        if (!response.error) {
          await rootGetters
            .$notificationLogger
            .setNotification(
              TYPE_LABEL_SUCCESS,
              i18n.global.t('notification.admin.dictionary.success.restore'),
            );
        }
      }
    },
  },
};

export default industryOneModule;
