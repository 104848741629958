import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import QueryString from 'qs';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import errorMiddleware from '@/api/middlewares/errorMiddleware';

export interface ErrorResponse {
  message: string,
  details: object,
}

export interface JsonDataOneItem {
  [key: string]: string | null,
}

export interface AxiosCallResponse<T> {
  data?: T,
  error?: AxiosError<ErrorResponse | null>,
}

export interface MetaParams {
  currentPage?: number | null,
  pageCount?: number | null,
  perPage?: number | null,
  totalCount?: number | null,
}

export interface ResponseOfRecords<T> {
  items: T,
  _meta?: MetaParams,
}

export interface PaginationParams {
  page?: number | null,
  perPage?: number | null,
}

export interface FilterParams {
  query?: string | null,
}

export interface SortParams {
  sortBy?: string | null,
}

export type QueryParamsForList = PaginationParams | FilterParams | SortParams;

export type NullableResponseType = AxiosCallResponse<null>;

export interface LoggerInterface {
  log: (status: number, text: string) => void,
}

export class BaseService {
  protected axiosInstance: AxiosInstance | undefined;

  protected logger: LoggerInterface;

  constructor(resource: string, token: string, logger: LoggerInterface = console, version = 'v1') {
    this.axiosInstance = axios.create({
      baseURL: `${process.env.VUE_APP_API_BASE_URL}/${version}/${resource}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      paramsSerializer: {
        serialize: (record) => QueryString.stringify(record, { skipNulls: true }),
      },
    });

    this.logger = logger;
  }

  protected async axiosCall<T>(
    config: AxiosRequestConfig,
    needLogging = true,
  ): Promise<AxiosCallResponse<T>> {
    if (!this.axiosInstance) {
      throw new Error('axiosInstance not set');
    }

    const requestInterceptor = this.axiosInstance.interceptors.request.use(
      BaseService.requestMiddleware,
    );
    const responseInterceptor = this.axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => response,
      async (error: AxiosError<ErrorResponse | null>) => {
        await errorMiddleware(
          error,
          this.logger,
          needLogging,
        );
      },
    );

    const clearInterceptors = () => {
      this.axiosInstance?.interceptors.request.eject(requestInterceptor);
      this.axiosInstance?.interceptors.response.eject(responseInterceptor);
    };

    try {
      const { data } = await this.axiosInstance.request(config);

      clearInterceptors();

      return { data };
    } catch (err: unknown) {
      const error = err as AxiosError<ErrorResponse | null>;

      clearInterceptors();

      return { error };
    }
  }

  private static requestMiddleware(config: InternalAxiosRequestConfig) {
    if (config.data) {
      return {
        ...config,
        data: BaseService.getFormattedData(config.data),
      };
    }

    return config;
  }

  private static getFormattedData(data: JsonData) {
    const formattedData: JsonData = {};

    Object.keys(data).forEach((key: string) => {
      if (Array.isArray(data[key]) && BaseService.isArrayOfObjects(data[key] as [])) {
        formattedData[key] = (data[key] as [])
          .filter((item: JsonData) => Object.keys(item).length)
          .map((item: JsonData) => BaseService.getFormattedData(item));

        return;
      }

      formattedData[key] = data[key];
    });

    return formattedData;
  }

  private static isArrayOfObjects(items: []) {
    return items.every((item: never) => typeof item === 'object');
  }
}
