import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "w-full min-h-[100vh] mx-auto p-4"
}
const _hoisted_2 = { class: "text-2xl font-semibold mb-2" }
const _hoisted_3 = { class: "text-xl font-semibold my-4" }
const _hoisted_4 = { class: "flex flex-wrap space-y-4 mb-6" }
const _hoisted_5 = {
  key: 0,
  class: "flex"
}
const _hoisted_6 = { class: "text-gray-500 print:text-gray-300 w-1/2 pr-2" }
const _hoisted_7 = { class: "flex w-1/2" }
const _hoisted_8 = {
  key: 1,
  class: "flex flex-wrap py-4"
}
const _hoisted_9 = {
  key: 0,
  class: "flex"
}
const _hoisted_10 = { class: "text-gray-500 print:text-gray-300 w-1/2 pr-2" }
const _hoisted_11 = { class: "flex w-1/2" }
const _hoisted_12 = {
  key: 1,
  class: "w-full"
}
const _hoisted_13 = {
  key: 0,
  class: "flex w-full"
}
const _hoisted_14 = { class: "text-gray-500 print:text-gray-300 w-1/2 pr-2" }
const _hoisted_15 = { class: "flex w-1/2" }
const _hoisted_16 = {
  key: 1,
  class: "w-full my-2"
}
const _hoisted_17 = { class: "text-gray-500 print:text-gray-300 w-1/2 pr-2" }
const _hoisted_18 = { class: "flex w-1/2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_default_loader = _resolveComponent("default-loader")!

  return (_ctx.isLoading)
    ? (_openBlock(), _createBlock(_component_default_loader, { key: 0 }))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (tab) => {
          return (_openBlock(), _createElementBlock("div", {
            key: tab.id,
            class: "print:space-y-4"
          }, [
            _createElementVNode("div", _hoisted_2, _toDisplayString(tab.name), 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tab.blocks, (block) => {
              return (_openBlock(), _createElementBlock("div", {
                key: block.id
              }, [
                _createElementVNode("div", _hoisted_3, _toDisplayString(block.name), 1),
                _createElementVNode("div", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(block.fields, (field, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: "w-full"
                    }, [
                      (!_ctx.isArray(field))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                            _createElementVNode("div", _hoisted_6, _toDisplayString(field.name), 1),
                            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.replaceBoolean(field.value !== '' ? field.value : '-')), 1)
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(field, (item, index) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: index,
                                class: "w-full list"
                              }, [
                                (!_ctx.isArray(item))
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                      _createElementVNode("div", _hoisted_10, _toDisplayString(item.name), 1),
                                      _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.replaceBoolean(item.value !== '' ? item.value : '-')), 1)
                                    ]))
                                  : (_openBlock(), _createElementBlock("div", _hoisted_12, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item, (value, index) => {
                                        return (_openBlock(), _createElementBlock("div", {
                                          key: index,
                                          class: "flex w-full"
                                        }, [
                                          (!_ctx.isArray(value))
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                                _createElementVNode("div", _hoisted_14, _toDisplayString(value.name), 1),
                                                _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.replaceBoolean(value.value !== '' ? value.value : '-')), 1)
                                              ]))
                                            : (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(value, (nextValue, index) => {
                                                  return (_openBlock(), _createElementBlock("div", {
                                                    key: index,
                                                    class: "flex"
                                                  }, [
                                                    _createElementVNode("div", _hoisted_17, _toDisplayString(nextValue.name), 1),
                                                    _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.replaceBoolean(nextValue.value !== '' ? nextValue.value : '-')), 1)
                                                  ]))
                                                }), 128))
                                              ]))
                                        ]))
                                      }), 128))
                                    ]))
                              ]))
                            }), 128))
                          ]))
                    ]))
                  }), 128))
                ])
              ]))
            }), 128))
          ]))
        }), 128))
      ]))
}