
import { defineComponent, PropType } from 'vue';
import { JsonSchema } from '@jsonforms/core';
import DefaultButton from '@/components/ui/buttons/DefaultButton/DefaultButton.vue';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import IconButton from '@/components/ui/buttons/IconButton/IconButton.vue';
import EditIcon from '@/components/ui/icons/EditIcon.vue';
import TrashIcon from '@/components/ui/icons/TrashIcon.vue';
import CustomUISchemaElement from '@/components/jsonforms/interfaces/CustomUISchemaElement';
import RefreshIcon from '@/components/ui/icons/RefreshIcon.vue';
import TheForm, { StructureForm } from '@/components/admin/dictionary/structure/form/TheForm.vue';
import { StructureItem } from '@/api/admin/dictionary/structure';
import AddCircleIcon from '@/components/ui/icons/AddCircleIcon.vue';

export default defineComponent({
  name: 'TheDetail',
  inject: ['$sizeWindow'],
  emits: ['cancel', 'update', 'delete', 'changeMode', 'restore', 'add-children'],
  components: {
    AddCircleIcon,
    RefreshIcon,
    TrashIcon,
    EditIcon,
    IconButton,
    DefaultButton,
    TheForm,
  },
  props: {
    data: {
      type: Object as PropType<StructureItem | null>,
    },
    schema: Object as PropType<JsonSchema>,
    uischema: Object as PropType<CustomUISchemaElement>,
    isUpdate: {
      type: Boolean,
      default: false,
    },
    isDeleted: {
      type: Boolean,
      default: false,
    },
    isShowEditButton: {
      type: Boolean,
      default: false,
    },
    isShowRemoveButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      isView: !this.isUpdate,
      localData: this.data,
    };
  },
  watch: {
    isView(value: boolean) {
      this.$emit('changeMode', !value);
    },
  },
  methods: {
    cancelHandler() {
      this.isView = true;
      this.localData = this.data;
    },
    updateDataHandler(data: JsonData) {
      this.localData = data;
    },
    changeEditMode() {
      this.isView = false;
    },
    updateHandler() {
      this.isLoading = true;

      const formRef: StructureForm = this.$refs.form as StructureForm;
      const valid: boolean = formRef?.validateForm(true) ?? false;
      const data: StructureItem | null = formRef?.currentData ?? null;

      if (!valid || !data) {
        this.isLoading = false;

        return false;
      }

      this.$emit('update', {
        id: this.data.id,
        data,
      }, () => {
        this.isLoading = false;
        this.cancelHandler();
      });

      return true;
    },
    deleteHandler() {
      this.$emit('delete', this.data.id);
    },
    restoreHandler() {
      this.$emit('restore');
    },
    addChildrenHandler() {
      this.$emit('add-children');
    },
  },
});
