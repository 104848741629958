export default function useDateHelper() {
  const currentYear = new Date().getFullYear();

  return {
    getYearPeriodList(startDate: number = currentYear, endDate: number = currentYear): string[] {
      const result = [];

      for (let i = startDate; i <= endDate; i += 1) {
        result.push(String(i));
      }

      return result.reverse();
    },
    getDateFormatter() {
      return Intl.DateTimeFormat('ru-RU');
    },
  };
}
