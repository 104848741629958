import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["id", "value", "disabled", "autofocus", "placeholder", "maxlength"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_control_wrapper = _resolveComponent("control-wrapper")!

  return (_openBlock(), _createBlock(_component_control_wrapper, _mergeProps(_ctx.preparedControlWrapper, {
    styles: _ctx.styles,
    isFocused: _ctx.isFocused,
    appliedOptions: _ctx.appliedOptions,
    errors: _ctx.errors
  }), {
    default: _withCtx(() => [
      (!!_ctx.control.config.onlyText)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass(_ctx.styles.control.text)
          }, _toDisplayString(_ctx.control.data ? _ctx.control.data : _ctx.$t('common.noData')), 3))
        : (_openBlock(), _createElementBlock("textarea", {
            key: 1,
            id: _ctx.control.id + '-input',
            class: _normalizeClass(_ctx.styles.control.textarea),
            value: _ctx.control.data,
            disabled: !_ctx.control.enabled,
            autofocus: _ctx.appliedOptions.focus,
            placeholder: _ctx.appliedOptions.placeholder,
            maxlength: _ctx.control?.schema?.maxLength ?? null,
            onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.debounceChangeHandler && _ctx.debounceChangeHandler(...args))),
            onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isFocused = true)),
            onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.blurHandler && _ctx.blurHandler(...args)))
          }, null, 42, _hoisted_1))
    ]),
    _: 1
  }, 16, ["styles", "isFocused", "appliedOptions", "errors"]))
}