import { QueryParamsForList } from '@/api/base';
import store from '@/store';
import CompanyService, { CompanyResponseType } from '@/api/admin/dictionary/company';

export function useListApi() {
  return {
    async loadItems(params: QueryParamsForList) {
      const api: CompanyService = store.getters.$api.admin.dictionary.company;

      const response: CompanyResponseType = await api.list(params);

      return response.data?.items ?? [];
    },
  };
}

export default {};
