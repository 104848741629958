import { AxiosCallResponse } from '@/api/base';
import store from '@/store';
import CounterpartyService, { CounterpartyDataType } from '@/api/counterparty';

export function useOneApi() {
  return {
    async loadData(id: string) {
      const counterpartyApi: CounterpartyService = store.getters.$api.counterparty;

      const counterpartyData: AxiosCallResponse<CounterpartyDataType> = await counterpartyApi
        .getKeyDataOfCounterparty(id);

      return counterpartyData.data ?? null;
    },
  };
}

export default {};
