<template>
  <svg :width="width" :height="height" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 15L1 8L8 1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LeftArrow',
  props: {
    width: {
      type: String,
      default: '9',
    },
    height: {
      type: String,
      default: '16',
    },
  },
});
</script>

<style scoped lang="scss">
@import "src/components/ui/consts.scss";

svg {
  stroke: $gray-fill-arrow;
}
</style>
