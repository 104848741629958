import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7ada8af3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "loader-block"
}
const _hoisted_3 = {
  key: 1,
  class: "w-full"
}
const _hoisted_4 = { class: "sticky top-0 z-[5]" }
const _hoisted_5 = { class: "container" }
const _hoisted_6 = {
  key: 0,
  class: "loader-block loader-in-block"
}
const _hoisted_7 = {
  key: 1,
  class: "w-full relative"
}
const _hoisted_8 = {
  key: 1,
  class: "mt-[52px]"
}
const _hoisted_9 = { class: "blocks" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_default_loader = _resolveComponent("default-loader")!
  const _component_header_default = _resolveComponent("header-default")!
  const _component_tabs_menu = _resolveComponent("tabs-menu")!
  const _component_manager_counterparty = _resolveComponent("manager-counterparty")!
  const _component_badge_in_work = _resolveComponent("badge-in-work")!
  const _component_form_generator = _resolveComponent("form-generator")!
  const _component_the_blocks = _resolveComponent("the-blocks")!
  const _component_sync_error_content = _resolveComponent("sync-error-content")!
  const _component_the_side_modal = _resolveComponent("the-side-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_default_loader)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_header_default, {
              information: _ctx.data,
              "current-card": _ctx.currentCard,
              cards: _ctx.cards,
              "need-confirmation": _ctx.needConfirmation,
              "show-error-button": _ctx.isShowErrorButton,
              onOpenFieldError: _ctx.openFiledErrorHandler,
              onChangeCard: _ctx.changeCardHandler 
            }, null, 8, ["information", "current-card", "cards", "need-confirmation", "show-error-button", "onOpenFieldError", "onChangeCard"]),
            _createVNode(_component_tabs_menu, {
              tabs: _ctx.groups,
              "selected-tab": _ctx.currentGroupId,
              disabled: _ctx.isLoading || _ctx.isBlockLoading,
              "need-confirmation": _ctx.needConfirmation,
              "counterparty-id": _ctx.currentCounterpartyId,
              onChangeTab: _ctx.changeCurrentGroupHandler
            }, null, 8, ["tabs", "selected-tab", "disabled", "need-confirmation", "counterparty-id", "onChangeTab"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            (_ctx.isBlockLoading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_default_loader)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  (_ctx.isShowManagerCard)
                    ? (_openBlock(), _createBlock(_component_manager_counterparty, {
                        key: 0,
                        manager: _ctx.manager
                      }, null, 8, ["manager"]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_8)),
                  (_ctx.isShowBadgeInWork)
                    ? (_openBlock(), _createBlock(_component_badge_in_work, {
                        key: 2,
                        class: "mb-4 -mt-4"
                      }))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_9, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredBlocks, (block) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: block.id,
                        class: "block-item"
                      }, [
                        (_ctx.isSchemaBlock(block.type) && block.schema)
                          ? (_openBlock(), _createBlock(_component_form_generator, {
                              schema: _ctx.getSchemaByBlock(block.id, _ctx.schema),
                              uischema: block.schema,
                              key: block.type,
                              information: _ctx.data,
                              "data-id": block.id,
                              config: {
                    groupId: block.id,
                    onlyText: _ctx.currentEditBlock !== block.id,
                    mdm: {
                      schema: _ctx.mdmBlockSchema,
                      uischema: _ctx.mdmBlockUischema,
                    },
                  },
                              "load-history": _ctx.loadHistory,
                              "validation-mode": "ValidateAndHide",
                              onEditHandler: _ctx.editHandler,
                              onChangeHandler: _ctx.changeHandler,
                              onUpdateBlockHandler: _ctx.updateCounterpartyHandler,
                              onHasErrorsHandler: _ctx.showErrors,
                              onDeleteBlockHistory: _ctx.deleteHistory,
                              onUpdateMdmHandler: _ctx.updateMdmHandler
                            }, null, 8, ["schema", "uischema", "information", "data-id", "config", "load-history", "onEditHandler", "onChangeHandler", "onUpdateBlockHandler", "onHasErrorsHandler", "onDeleteBlockHistory", "onUpdateMdmHandler"]))
                          : _createCommentVNode("", true),
                        _createVNode(_component_the_blocks, {
                          type: block.type,
                          id: block.id,
                          "can-edit": _ctx.data.canEdit,
                          label: block.label,
                          uischema: block.schema,
                          "block-schema-list": _ctx.blockSchemaList
                        }, null, 8, ["type", "id", "can-edit", "label", "uischema", "block-schema-list"])
                      ]))
                    }), 128))
                  ])
                ]))
          ])
        ])),
    _createVNode(_component_the_side_modal, {
      "is-show-modal": _ctx.isOpenFieldError,
      size: "xl",
      onCloseHandler: _ctx.closeOpenFieldHandler
    }, {
      default: _withCtx(() => [
        _createVNode(_component_sync_error_content, { items: _ctx.fieldsErrors }, null, 8, ["items"])
      ]),
      _: 1
    }, 8, ["is-show-modal", "onCloseHandler"])
  ]))
}