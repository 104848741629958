import {
  AxiosCallResponse,
  BaseService,
  JsonDataOneItem,
  LoggerInterface,
  PaginationParams,
  ResponseOfRecords,
  SortParams,
} from '@/api/base';
import JsonData from '@/components/jsonforms/interfaces/JsonData';

export type FilterRelationshipBreak = JsonDataOneItem | null;

export type QueryParamsForListRelationshipBreak = PaginationParams
  | FilterRelationshipBreak
  | SortParams;

export type RelationshipBreakItem = {
  id: string,
} & JsonData;

export type RelationshipBreakResponseType =
  AxiosCallResponse<ResponseOfRecords<RelationshipBreakItem[]>>

export default class RelationshipBreakService extends BaseService {
  constructor(token: string, logger: LoggerInterface = console) {
    super('card', token, logger, 'v2');
  }

  async list(
    cardId: string,
    params: QueryParamsForListRelationshipBreak,
  ): Promise<RelationshipBreakResponseType> {
    return this.axiosCall({ url: `/${cardId}/relationship-break`, method: 'get', params });
  }

  async create(
    cardId: string,
    data: JsonData,
  ): Promise<AxiosCallResponse<RelationshipBreakItem>> {
    return this.axiosCall({ url: `/${cardId}/relationship-break`, method: 'post', data });
  }

  async update(
    cardId: string,
    id: string,
    data: JsonData,
  ): Promise<AxiosCallResponse<null>> {
    return this.axiosCall({ url: `/${cardId}/relationship-break/${id}`, method: 'patch', data });
  }

  async delete(
    cardId: string,
    id: string,
  ): Promise<AxiosCallResponse<null>> {
    return this.axiosCall({ url: `/${cardId}/relationship-break/${id}`, method: 'delete' });
  }
}
