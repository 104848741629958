
import { defineComponent } from 'vue';
import CircleCheckIcon from '@/components/ui/icons/CircleCheckIcon.vue';
import communication from '@/shared/consts/blocks/communication';
import InfoCircleIcon from '@/components/ui/icons/InfoCircleIcon.vue';
import ErrorIcon from '@/components/ui/icons/notifications/ErrorIcon.vue';

export default defineComponent({
  name: 'ActivityStatus',
  components: {
    CircleCheckIcon,
    InfoCircleIcon,
    ErrorIcon,
  },
  props: {
    status: {
      type: String,
      default: communication.STATUS_COMPLETED,
    },
  },
  computed: {
    isCompleted() {
      return this.status === communication.STATUS_COMPLETED;
    },
    isPlanned() {
      return this.status === communication.STATUS_PLANNED;
    },
    isCanceled() {
      return this.status === communication.STATUS_CANCELED;
    },
  },
});
