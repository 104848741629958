import { PinningItem } from '@/api/admin/pinning';
import {
  AxiosCallResponse,
  BaseService, FilterParams, LoggerInterface, PaginationParams,
  QueryParamsForList,
  ResponseOfRecords, SortParams,
} from '@/api/base';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import { DataType } from '@/components/ui/selects/SelectDefault/SelectDefault.vue';

export interface CounterpartyItemType {
  id: string,
  [key: string]: string | number | boolean,
}

export interface CounterpartyCardItem {
  id: string,
  division: DataType,
  counterpartyType: DataType,
  manager: DataType,
}

export interface AddCounterpartyParams {
  block: string,
}

export interface UpdateCounterpartyParams {
  block: string,
  cardId: string,
}

export interface GetOneCounterpartyParams {
  cardId: string,
}

export interface CounterpartyFilterParams extends FilterParams {
  onlyAssigned?: number | null,
  industry?: string | null,
  isFromRussia?: number | null,
  block?: number | null,
  divisionId?: string | null,
  departmentId?: string | null,
  assignUserId?: string | null,
  regionId?: string | null,
  address?: string | null,
}

export interface CounterpartyManager {
  id: string,
  name: string,
  vacancy?: string | null,
  company?: string | null,
  division?: string | null,
  department?: string | null,
  phone?: string | null,
  email?: string | null,
  counterpartyType?: string | null,
}

export type CounterpartyQueryForList = PaginationParams | CounterpartyFilterParams | SortParams;

export type CounterpartyResponseType = ResponseOfRecords<CounterpartyItemType[]>;

export type CounterpartyManagerResponseType = ResponseOfRecords<CounterpartyManager[]>;

export type CounterpartyCardsResponseType = ResponseOfRecords<CounterpartyCardItem[]>;

export type CounterpartyDataType = {
  canEdit?: boolean,
  manager?: CounterpartyManager | null,
} & JsonData;

export default class CounterpartyService extends BaseService {
  constructor(token: string, logger: LoggerInterface = console) {
    super('counterparty', token, logger);
  }

  async my(
    params: QueryParamsForList = {},
  ): Promise<AxiosCallResponse<CounterpartyResponseType>> {
    return this.axiosCall({ url: '/my', method: 'get', params });
  }

  async list(
    params: QueryParamsForList = {},
  ): Promise<AxiosCallResponse<CounterpartyResponseType>> {
    return this.axiosCall({ method: 'get', params });
  }

  async create(
    data: JsonData,
    params: AddCounterpartyParams,
  ): Promise<AxiosCallResponse<null>> {
    return this.axiosCall({ method: 'post', data, params });
  }

  async update(
    id: string,
    data: JsonData,
    params: UpdateCounterpartyParams,
  ): Promise<AxiosCallResponse<null>> {
    return this.axiosCall({
      url: `/${id}`, method: 'patch', data, params,
    });
  }

  async one(
    id: string,
    params: GetOneCounterpartyParams,
  ): Promise<AxiosCallResponse<CounterpartyDataType>> {
    return this.axiosCall({ url: `/${id}`, method: 'get', params });
  }

  async getKeyDataOfCounterparty(id: string): Promise<AxiosCallResponse<CounterpartyDataType>> {
    return this.axiosCall({ url: `/${id}/key-data`, method: 'get' });
  }

  async updateMdmData(id: string, data: JsonData): Promise<AxiosCallResponse<null>> {
    return this.axiosCall({ url: `/${id}/mdm`, method: 'patch', data });
  }

  async assignCounterparty(
    id: string,
    data: PinningItem,
  ): Promise<AxiosCallResponse<null>> {
    return this.axiosCall({ url: `/${id}/assign`, method: 'post', data });
  }

  async getCounterpartyManagers(
    id: string,
  ): Promise<AxiosCallResponse<CounterpartyManagerResponseType>> {
    return this.axiosCall({ url: `/${id}/managers`, method: 'get' });
  }

  async getCounterpartyCards(
    id: string,
  ): Promise<AxiosCallResponse<CounterpartyCardsResponseType>> {
    return this.axiosCall({ url: `/${id}/cards`, method: 'get' });
  }

  async downloadReport(): Promise<AxiosCallResponse<string>> {
    return this.axiosCall({ url: '/my/report', method: 'get', responseType: 'arraybuffer' });
  }
}
