import { defineComponent, PropType } from 'vue';

export type SwitchVariants = 'default' | 'primary';

export default defineComponent({
  name: 'TheSwitch',
  props: {
    id: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String as PropType<SwitchVariants>,
      default: 'default',
    },
    modelValue: {
      type: [Boolean, Number],
      default: false,
    },
  },
  data() {
    return {
      value: this.modelValue,
    };
  },
  watch: {
    modelValue() {
      this.value = this.modelValue;
    },
  },
  computed: {
    isSelected() {
      return !!this.value;
    },
    classes() {
      return {
        checked: this.isSelected,
        disabled: this.disabled,
        required: this.required,
        [`variant-${this.variant}`]: true,
      };
    },
  },
  methods: {
    toggleCheck() {
      if (!this.disabled) {
        this.value = !this.value;

        this.$emit('update:modelValue', this.value);
      }
    },
  },
});
