
import { defineComponent, PropType } from 'vue';
import { RoleAttribute, RoleGroup } from '@/api/admin/role';
import { UpdatedRoleParams } from '@/components/admin/user/one/modal/content/detail/role/RoleItem.vue';
import ButtonGroup, { GroupItem } from '@/components/ui/groups/ButtonGroup/ButtonGroup.vue';
import RoleBlock, { UpdatedBlockParams } from '@/components/admin/user/one/modal/content/detail/role/RoleBlock.vue';
import { DataTypeTree } from '@/components/ui/selects/SelectTree/SelectTree.vue';

export interface UpdatedGroupParams {
  groupId: string,
  blockId: string,
  roles: UpdatedRoleParams[],
}

export default defineComponent({
  name: 'RoleGroup',
  emits: ['updated', 'updateRoleAttributes'],
  components: {
    ButtonGroup,
    RoleBlock,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    roles: {
      type: Array as PropType<RoleGroup[]>,
      default: () => ([]),
    },
    rolesAttributes: {
      type: Object as PropType<RoleAttribute>,
      default: () => ({}),
    },
    departments: {
      type: Array as PropType<DataTypeTree[]>,
      default: () => ([]),
    },
    userRoles: {
      type: Array as PropType<string[]>,
      default: () => ([]),
    },
  },
  data() {
    return {
      activeGroupId: null as string | null,
    };
  },
  computed: {
    currentGroupItems() {
      return this.currentGroup && this.currentGroup.items
        ? this.currentGroup.items
        : [];
    },
    currentGroup(): RoleGroup | undefined {
      return this.roles.find((group: RoleGroup) => group.id === this.activeGroupId);
    },
    groups(): GroupItem[] {
      return this.roles.map((group: RoleGroup) => ({
        name: group.id,
        label: group.title,
      }));
    },
  },
  methods: {
    groupChangedHandler(groupId: string) {
      this.activeGroupId = groupId;
    },
    updatedHandler(params: UpdatedBlockParams) {
      this.$emit('updated', { groupId: this.currentGroup?.id, ...params });
    },
    updateRoleAttributesHandler(data: string[], roleId: string) {
      this.$emit('updateRoleAttributes', data, roleId);
    },
  },
});
