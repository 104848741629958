import _ from 'lodash';

import { Module } from 'vuex';
import i18n from '@/i18n';
import UserVerifyException
  from '@/store/exception/admin/user/UserVerifyException';
import UserService, {
  UserCreateData,
  UserCreateResponseType,
  UserDetailData,
  UserResponseType,
  UserUpdateData, UserVerifyData,
  UserVerifyResponseType,
} from '@/api/admin/user';
import { StateType } from '@/store';
import { NullableResponseType } from '@/api/base';
import { TYPE_LABEL_SUCCESS } from '@/components/ui/notifications/TheAlert/TheAlert.vue';

export interface UserOneStateType {
  data: UserDetailData | null,
  currentUserId: string | null,
}

const initialState = {
  data: null,
  currentUserId: null,
};

const userOneModule: Module<UserOneStateType, StateType> = {
  namespaced: true,
  state: _.cloneDeep(initialState),
  getters: {
    data: (state): UserDetailData | null => state.data,
    currentUserId: (state): string => state.currentUserId ?? '',
  },
  mutations: {
    setData(state, data) {
      state.data = { ...data };
    },
    setCurrentUserId(state, currentUserId: string) {
      state.currentUserId = currentUserId;
    },
  },
  actions: {
    async loadData({ rootGetters, commit }, id: string) {
      const api: UserService = rootGetters.$api.admin.user;
      const response: UserResponseType = await api.one(id);

      commit('setCurrentUserId', id);
      commit('setData', response.data ?? initialState.data);
    },
    async verify({ rootGetters }, email: string): Promise<UserVerifyData> {
      const api: UserService = rootGetters.$api.admin.user;
      const response: UserVerifyResponseType = await api.verify(email);

      if (response.error || !response.data) {
        const message: string = response.error?.response?.status === 409
          ? i18n.global.t('admin.user.create.error.userAlreadyExists')
          : i18n.global.t('admin.user.create.error.userNotFound');

        throw new UserVerifyException(message);
      }

      return response.data;
    },
    async create({ rootGetters }, data: UserCreateData) {
      const api: UserService = rootGetters.$api.admin.user;
      const response: UserCreateResponseType = await api.create(data);

      return !response.error && response.data?.id
        ? response.data.id
        : null;
    },
    async update({ rootGetters, state }, data: UserUpdateData) {
      const { currentUserId } = state;

      if (currentUserId) {
        const api: UserService = rootGetters.$api.admin.user;
        const response: NullableResponseType = await api.update(currentUserId, data);

        if (!response.error) {
          await rootGetters
            .$notificationLogger
            .setNotification(
              TYPE_LABEL_SUCCESS,
              i18n.global.t('notification.admin.user.success.update'),
            );
        }
      }
    },
    async blockUser({ state, rootGetters }) {
      const { currentUserId } = state;

      if (currentUserId) {
        const api: UserService = rootGetters.$api.admin.user;
        const response: NullableResponseType = await api.blockUser(currentUserId);

        if (!response.error) {
          await rootGetters
            .$notificationLogger
            .setNotification(
              TYPE_LABEL_SUCCESS,
              i18n.global.t('notification.admin.user.success.block'),
            );
        }
      }
    },
    async unblockUser({ state, rootGetters }) {
      const { currentUserId } = state;

      if (currentUserId) {
        const api: UserService = rootGetters.$api.admin.user;
        const response: NullableResponseType = await api.unblockUser(currentUserId);

        if (!response.error) {
          await rootGetters
            .$notificationLogger
            .setNotification(
              TYPE_LABEL_SUCCESS,
              i18n.global.t('notification.admin.user.success.unblock'),
            );
        }
      }
    },
  },
};

export default userOneModule;
