import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-47f952d2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "text-[14px]" }
const _hoisted_3 = { class: "financial-table__content" }
const _hoisted_4 = { class: "financial-table__content-block" }
const _hoisted_5 = { class: "financial-table__labels" }
const _hoisted_6 = {
  key: 0,
  class: "financial-table__column"
}
const _hoisted_7 = { class: "financial-table__column_size" }
const _hoisted_8 = {
  key: 1,
  class: "financial-table__column"
}
const _hoisted_9 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_table_pagination_back = _resolveComponent("table-pagination-back")!
  const _component_icon_button = _resolveComponent("icon-button")!
  const _component_add_icon = _resolveComponent("add-icon")!
  const _component_default_button = _resolveComponent("default-button")!
  const _component_table_pagination_next = _resolveComponent("table-pagination-next")!
  const _component_remove_column_icon = _resolveComponent("remove-column-icon")!
  const _component_dispatch_renderer = _resolveComponent("dispatch-renderer")!
  const _component_the_danger_modal = _resolveComponent("the-danger-modal")!

  return (_ctx.control.visible)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["financial-table", _ctx.styles.financialTable.root])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["financial-table__legend", _ctx.styles.financialTable.legend])
        }, [
          (_ctx.showIconButtons)
            ? (_openBlock(), _createBlock(_component_icon_button, {
                key: 0,
                disabled: _ctx.disableLeftButton,
                onClick: _cache[0] || (_cache[0] = ($event: any) => (!_ctx.disableLeftButton && _ctx.shiftData(--_ctx.dataShift)))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_table_pagination_back)
                ]),
                _: 1
              }, 8, ["disabled"]))
            : _createCommentVNode("", true),
          (_ctx.control.config.onlyText)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.currentShearOnLeft) + "-" + _toDisplayString(_ctx.currentShearOnRight) + " из " + _toDisplayString(_ctx.dataLength), 1))
            : _createCommentVNode("", true),
          (!_ctx.control.config.onlyText)
            ? (_openBlock(), _createBlock(_component_default_button, {
                key: 2,
                class: _normalizeClass(_ctx.styles.financialTable.addButton),
                onClick: _ctx.addButtonClick
              }, {
                icon: _withCtx(() => [
                  _createVNode(_component_add_icon, {
                    width: "12",
                    height: "12"
                  })
                ]),
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.control.label), 1)
                ]),
                _: 1
              }, 8, ["class", "onClick"]))
            : _createCommentVNode("", true),
          (_ctx.showIconButtons)
            ? (_openBlock(), _createBlock(_component_icon_button, {
                key: 3,
                disabled: _ctx.disableRightButton,
                onClick: _cache[1] || (_cache[1] = ($event: any) => (!_ctx.disableRightButton && _ctx.shiftData(++_ctx.dataShift)))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_table_pagination_next)
                ]),
                _: 1
              }, 8, ["disabled"]))
            : _createCommentVNode("", true)
        ], 2),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getLabelsFromUISchema(_ctx.UISchema), (label) => {
                return (_openBlock(), _createElementBlock("span", {
                  class: "label",
                  key: label
                }, _toDisplayString(label), 1))
              }), 128))
            ]),
            (_ctx.noData)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countLabels, (index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: index,
                        class: "financial-table__column-row"
                      }, _toDisplayString(_ctx.$t('common.noData')), 1))
                    }), 128))
                  ])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.control.data, (element, index) => {
                    return _withDirectives((_openBlock(), _createElementBlock("div", {
                      class: "financial-table__column_size",
                      key: `${_ctx.control.path}-${index}`
                    }, [
                      (!_ctx.control.config.onlyText)
                        ? (_openBlock(), _createElementBlock("button", {
                            key: 0,
                            type: "button",
                            class: "remove-icon",
                            onClick: ($event: any) => (_ctx.openRemoveModal(index, _ctx.getYearByIndex(index)))
                          }, [
                            _createVNode(_component_remove_column_icon)
                          ], 8, _hoisted_9))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", {
                        class: _normalizeClass(_ctx.styles.financialTable.item)
                      }, [
                        _createVNode(_component_dispatch_renderer, {
                          schema: _ctx.filterSchema(_ctx.getYearByIndex(index)),
                          uischema: _ctx.getFilteredUISchema(element, _ctx.UISchema),
                          path: _ctx.composePaths(_ctx.control.path, `${index}`),
                          enabled: _ctx.control.enabled,
                          renderers: _ctx.control.renderers,
                          cells: _ctx.control.cells
                        }, null, 8, ["schema", "uischema", "path", "enabled", "renderers", "cells"])
                      ], 2)
                    ])), [
                      [_vShow, _ctx.showColumn(index)]
                    ])
                  }), 128))
                ])),
            _createVNode(_component_the_danger_modal, {
              "approve-button-text": "Да, удалить",
              text: _ctx.removeModalText,
              "is-open": _ctx.suggestToDelete !== null,
              onCloseModal: _ctx.closeRemoveModal,
              onApprovalHandler: _ctx.approvalHandler
            }, null, 8, ["text", "is-open", "onCloseModal", "onApprovalHandler"])
          ])
        ])
      ], 2))
    : _createCommentVNode("", true)
}