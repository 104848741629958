
import { defineComponent } from 'vue';
import DateIcon from '@/components/ui/icons/DateIcon.vue';
import OutsideClick from '@/utils/directives/OutsideClick';
import { DateTime } from 'luxon';
import config from '@/config';
import DatepickerLayout from '@/components/ui/datepickers/datepicker-default/layouts/DatepickerLayout.vue';
import CalculatePosition from '@/utils/directives/CalculatePosition';

export default defineComponent({
  components: {
    DatepickerLayout, DateIcon,
  },
  emits: ['change', 'blur'],
  name: 'DatepickerDefault',
  props: {
    value: {
      type: String,
      default: null,
    },
    format: {
      type: String,
      default: config.DATE_FORMAT,
    },
    autoclose: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
    error: {
      type: Boolean,
      default: false,
    },
    calculatePosition: {
      type: [Boolean, Object],
      default: () => ({
        extraHeight: 0,
      }),
    },
  },
  data() {
    return {
      inputValue: null,
      dateValue: null,
      isOpen: false,
    };
  },
  methods: {
    open() {
      if (!this.disabled) {
        this.isOpen = true;
      }
    },
    close() {
      this.isOpen = false;
      this.$emit('blur', this.dateValue?.toFormat(config.DATE_FORMAT_API) ?? null);
    },
    changeInputHandler() {
      this.dateValue = DateTime.fromFormat(this.inputValue, config.DATE_FORMAT);

      if (!this.dateValue.isValid) {
        this.dateValue = null;
        this.inputValue = null;
      }

      this.emitDate();
      this.close();
    },
    changeDateHandler(date: DateTime) {
      this.dateValue = date;

      this.inputValue = this.dateValue.toFormat(this.format);

      if (this.autoclose) {
        this.close();
      }

      this.emitDate();
    },
    emitDate() {
      this.$emit('change', this.dateValue?.toFormat(config.DATE_FORMAT_API) ?? null);
    },
  },
  directives: {
    OutsideClick,
    CalculatePosition,
  },
  mounted() {
    if (this.value) {
      this.dateValue = DateTime.fromISO(this.value);

      this.inputValue = this.dateValue.toFormat(this.format);
    }
  },
});
