
import {
  ControlElement,
  JsonFormsRendererRegistryEntry,
  rankWith,
  isBooleanControl,
  computeLabel,
} from '@jsonforms/core';
import { RendererProps, rendererProps, useJsonFormsControl } from '@jsonforms/vue';
import { useVanillaControl } from '@jsonforms/vue-vanilla';
import { defineComponent } from 'vue';
import TheToggleTooltip from '@/components/ui/tooltips/TheToggleTooltip/TheToggleTooltip.vue';

const controlRenderer = defineComponent({
  name: 'boolean-control-renderer',
  components: { TheToggleTooltip },
  props: {
    ...rendererProps<ControlElement>(),
  },
  data() {
    return {
      isClicked: false,
    };
  },
  computed: {
    computedLabel(): string {
      return computeLabel(
        this.controlWrapper.label,
        this.controlWrapper.required,
        !!this.appliedOptions?.hideRequiredAsterisk,
      );
    },
    isDisabled(): boolean {
      return !this.control.enabled || this.control.config.onlyText;
    },
  },
  methods: {
    clickHandler() {
      if (!this.isDisabled) {
        this.isClicked = true;
        setTimeout(() => {
          this.isClicked = false;
        }, 700);
      }
    },
  },
  setup(props: RendererProps<ControlElement>) {
    return useVanillaControl(useJsonFormsControl(props), (target) => target.checked);
  },
});

export default controlRenderer;

export const entry: JsonFormsRendererRegistryEntry = {
  renderer: controlRenderer,
  tester: rankWith(1, isBooleanControl),
};
