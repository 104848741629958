
import {
  Component, defineComponent, PropType, Raw, ref,
} from 'vue';
import TabButton from '@/components/ui/buttons/TabButton/TabButton.vue';

export interface GroupItem {
  name: string | number,
  label: string,
  content?: Component | Raw<never>,
  visible?: boolean,
  active?: boolean,
  props?: object,
}

const BORDER_WEIGHT = 2;
const SIZE_MD = 'md';

export default defineComponent({
  name: 'ButtonGroup',
  emits: ['groupChanged', 'groupSelected'],
  components: {
    TabButton,
  },
  props: {
    items: {
      required: true,
      type: Array as PropType<GroupItem[]>,
    },
    activeGroup: {
      type: [String, Number],
      default: '',
    },
    size: {
      type: String,
      default: SIZE_MD,
    },
  },
  data() {
    return {
      currentGroup: this.activeGroup,
    };
  },
  setup() {
    const elementWidth = ref<null | number>(null);

    return { elementWidth };
  },
  watch: {
    activeGroup() {
      this.currentGroup = this.activeGroup;
    },
    currentGroup(groupName: string) {
      this.$emit('groupChanged', groupName);
    },
  },
  computed: {
    preparedList() {
      return this.items.filter((group: GroupItem) => group.visible !== false);
    },
    leftOffsetElement() {
      const currentIndex = this.items.findIndex((
        group: GroupItem,
        // eslint-disable-next-line eqeqeq
      ) => group.name == this.currentGroup);

      return (currentIndex * this.elementWidth) || BORDER_WEIGHT;
    },
    sizeClass() {
      return `size-${this.size}`;
    },
  },
  methods: {
    initializeData() {
      const activeObject = this.items.find((group: GroupItem) => group.active);
      this.currentGroup = activeObject?.name ?? this.items[0]?.name ?? '';
    },
    changeGroup(e: { currentTarget: HTMLLinkElement }) {
      const { link: groupName = '' } = e.currentTarget.dataset;

      this.currentGroup = groupName;

      this.$emit('groupSelected', groupName);
    },
  },
  mounted() {
    this.initializeData();

    this.observer = new IntersectionObserver(() => {
      const menuWidth = this.$refs.tabMenu.offsetWidth - BORDER_WEIGHT;

      this.elementWidth = this.preparedList.length > 1
        ? menuWidth / this.preparedList.length
        : menuWidth - BORDER_WEIGHT;
    }, {
      root: document.documentElement,
    });

    this.observer.observe(this.$refs.tabMenu);
  },
  unmounted() {
    this.observer.disconnect();
  },
});
