import { QueryParamsForList } from '@/api/base';
import store from '@/store';
import AgentService, { AgentListResponseType } from '@/api/agent';

export function useListApi() {
  return {
    async loadItems(params: QueryParamsForList) {
      const currentCardId = store.getters['counterparty/card/currentCardId'];

      if (!currentCardId) {
        return [];
      }

      const api: AgentService = store.getters.$api.agent;

      const response: AgentListResponseType = await api.list(currentCardId, params);

      return response.data?.items ?? [];
    },
  };
}

export default {};
