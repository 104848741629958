import { DataType } from '@/components/ui/selects/SelectDefault/SelectDefault.vue';
import {
  AxiosCallResponse, BaseService, LoggerInterface, QueryParamsForList, ResponseOfRecords,
} from '@/api/base';

export interface AssignHistoryType {
  id: string,
  user: DataType,
  department?: DataType,
  assignmentDate: string,
  detachmentDate: string,
  reason?: string,
}

export type AssignHistoryResponseType = ResponseOfRecords<AssignHistoryType[]>;

export default class AssignHistoryService extends BaseService {
  constructor(token: string, logger: LoggerInterface = console) {
    super('card', token, logger, 'v2');
  }

  async list(
    cardId: string,
    params: QueryParamsForList = {},
  ): Promise<AxiosCallResponse<AssignHistoryResponseType>> {
    return this.axiosCall({ url: `/${cardId}/assign-history`, method: 'get', params });
  }
}
