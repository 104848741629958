
import { defineComponent, PropType } from 'vue';
import { ErrorObject } from 'ajv';
import { JsonSchema } from '@jsonforms/core';
import { JsonFormsChangeEvent } from '@jsonforms/vue';
import { mapActions } from 'vuex';
import DefaultButton from '@/components/ui/buttons/DefaultButton/DefaultButton.vue';
import { PinningItem } from '@/api/admin/pinning';
import { OneOfType } from '@/api/schema';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import CustomUISchemaElement from '@/components/jsonforms/interfaces/CustomUISchemaElement';
import { validateData } from '@/utils/helpers/AjvValidator';
import useShowErrors from '@/composables/form/useShowErrors';

export interface PinningCreateForm {
  data: JsonData,
  currentData: PinningItem,
  schema: JsonSchema,
  uischema: CustomUISchemaElement,
  errors: JsonData,
  validateForm: (showErrors?: boolean) => boolean,
  showErrors: () => void,
  onChange: () => void,
}

export default defineComponent({
  name: 'TheCreate',
  inject: ['$sizeWindow'],
  emits: ['cancel', 'create'],
  components: {
    DefaultButton,
  },
  props: {
    type: {
      type: String,
      default: 'parent',
    },
    departments: {
      type: Array as PropType<OneOfType[]>,
      default: () => [],
    },
    divisions: {
      type: Array as PropType<OneOfType[]>,
      default: () => [],
    },
    counterparties: {
      type: Array as PropType<OneOfType[]>,
      default: () => [],
    },
    counterpartyTypes: {
      type: Array as PropType<OneOfType[]>,
      default: () => [],
    },
    users: {
      type: Array as PropType<OneOfType[]>,
      default: () => [],
    },
  },
  data() {
    return {
      isLoading: false,
      errors: [] as ErrorObject[],
      currentData: {},
    };
  },
  computed: {
    schema() {
      return {
        type: 'object',
        properties: {
          counterparty: {
            type: ['string', 'null'],
            oneOf: this.counterparties,
          },
          counterpartyType: {
            type: ['string', 'null'],
            oneOf: this.counterpartyTypes,
          },
          division: {
            type: ['string', 'null'],
            oneOf: this.divisions,
          },
          department: {
            type: ['string', 'null'],
            oneOf: this.departments,
          },
          user: {
            type: ['string', 'null'],
            oneOf: this.users,
          },
          region: {
            type: ['string', 'null'],
          },
          nomenclatureGroup: {
            type: ['string', 'null'],
          },
          nomenclature: {
            type: ['string', 'null'],
          },
        },
        required: [
          'counterpartyType',
          'counterparty',
          'division',
        ],
      };
    },
    uischema() {
      return {
        type: 'VerticalLayout',
        elements: [
          {
            type: 'Control',
            label: this.$t('dictionary.pinning.fields.counterparty'),
            scope: '#/properties/counterparty',
            options: {
              search: true,
              ajaxHandler: async (query: string) => {
                await this.changeFilterCounterparty({ query });
                await this.loadCounterparties();
              },
            },
          },
          {
            type: 'Control',
            label: this.$t('dictionary.pinning.fields.counterpartyType'),
            scope: '#/properties/counterpartyType',
          },
          {
            type: 'Control',
            label: this.$t('dictionary.pinning.fields.division'),
            scope: '#/properties/division',
            options: {
              search: true,
            },
          },
          {
            type: 'Control',
            label: this.$t('dictionary.pinning.fields.department'),
            scope: '#/properties/department',
            options: {
              search: true,
              readonly: !this.currentData || !this.currentData.division,
            },
          },
          {
            type: 'Control',
            label: this.$t('dictionary.pinning.fields.user'),
            scope: '#/properties/user',
            options: {
              search: true,
              readonly: !this.currentData || !this.currentData.division
                || !this.currentData.department,
              ajaxHandler: async (query: string) => {
                await this.setFilterUser({
                  query,
                  division: this.currentData.division,
                  department: this.currentData.department,
                });
              },
            },
          },
          {
            type: 'AjaxControl',
            label: this.$t('dictionary.pinning.fields.region'),
            scope: '#/properties/region',
            options: {
              search: true,
              ajaxConfiguration: {
                categoryName: 'region',
              },
            },
          },
          {
            type: 'AjaxControl',
            label: this.$t('dictionary.pinning.fields.nomenclatureGroup'),
            scope: '#/properties/nomenclatureGroup',
            options: {
              search: true,
              ajaxConfiguration: {
                categoryName: 'nomenclatureGroup',
              },
            },
          },
          {
            type: 'AjaxControl',
            label: this.$t('dictionary.pinning.fields.nomenclature'),
            scope: '#/properties/nomenclature',
            options: {
              search: true,
              ajaxConfiguration: {
                categoryName: 'nomenclature',
                filter: {
                  nomenclatureGroup: this.currentData.nomenclature,
                },
              },
            },
          },
        ],
      };
    },
    defaultOneOfList() {
      return [{ const: null, title: '' }];
    },
  },
  methods: {
    ...mapActions({
      changeFilterCounterparty: 'counterparty/list/changeFilter',
      loadCounterparties: 'counterparty/list/loadCounterparties',
      setFilterStructure: 'admin/dictionary/structure/list/changeFilter',
      loadStructure: 'admin/dictionary/structure/list/loadItems',
      loadUsers: 'admin/user/list/loadItems',
      setFilterUser: 'admin/user/list/changeFilter',
    }),
    cancelHandler() {
      this.$emit('cancel');
    },
    validateForm(showErrors = false): boolean {
      this.errors = validateData(this.currentData, this.schema, this.uischema);

      if (showErrors) {
        this.showErrors(this.errors);
      }

      return !this.errors || !this.errors.length;
    },
    createHandler() {
      this.isLoading = true;

      const valid: boolean = this.validateForm(true) ?? false;
      const data: PinningItem | null = this.currentData ?? null;

      if (!valid || !data) {
        this.isLoading = false;

        return false;
      }

      this.$emit('create', data, () => {
        this.isLoading = false;
      });

      return true;
    },
    async onChange(event: JsonFormsChangeEvent) {
      if (event.data.division && event.data.division !== this.currentData.division) {
        await this.setFilterStructure({
          parentId: event.data.division,
        });

        if (this.currentData.division) {
          this.currentData.department = undefined;
          this.currentData.user = undefined;
        }

        await this.loadStructure();
      }

      if (event.data.department && event.data.department !== this.currentData.department) {
        await this.setFilterUser({
          division: event.data.division,
          department: event.data.department,
        });

        if (this.currentData.department) {
          this.currentData.user = undefined;
        }
      }

      if (!event.data.division && event.data.division !== this.currentData.division) {
        this.currentData.department = undefined;
        this.currentData.user = undefined;
      }

      if (!event.data.department && event.data.department !== this.currentData.department) {
        this.currentData.user = undefined;
      }

      this.currentData = event.data;
    },
  },
  watch: {
    data() {
      this.currentData = this.data;
    },
  },
  setup() {
    const { showErrors } = useShowErrors();

    return { showErrors };
  },
});
