import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_filter = _resolveComponent("the-filter")!
  const _component_table_block_template = _resolveComponent("table-block-template")!

  return (_openBlock(), _createBlock(_component_table_block_template, {
    title: _ctx.title,
    items: _ctx.items,
    "prepared-items": _ctx.items,
    fields: _ctx.fields,
    schema: _ctx.schema,
    uischema: _ctx.uischema,
    sort: _ctx.sort,
    pagination: _ctx.pagination,
    onChangePagination: _ctx.changePaginationHandler,
    onChangeSort: _ctx.changeSortHandler,
    onCreate: _ctx.createHandler,
    onUpdate: _ctx.updateHandler,
    onDelete: _ctx.deleteHandler
  }, {
    default: _withCtx(() => [
      _createVNode(_component_the_filter, { onFilterChanged: _ctx.changeFilterHandler }, null, 8, ["onFilterChanged"])
    ]),
    _: 1
  }, 8, ["title", "items", "prepared-items", "fields", "schema", "uischema", "sort", "pagination", "onChangePagination", "onChangeSort", "onCreate", "onUpdate", "onDelete"]))
}