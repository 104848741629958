
import {
  composePaths,
  JsonFormsRendererRegistryEntry,
  rankWith,
  ControlElement,
  schemaTypeIs,
} from '@jsonforms/core';
import {
  DispatchRenderer,
  RendererProps,
  rendererProps,
  useJsonFormsArrayControl,
} from '@jsonforms/vue';
import { useVanillaArrayControl } from '@jsonforms/vue-vanilla';
import _ from 'lodash';
import { defineComponent, ref } from 'vue';
import AddCircleIcon from '@/components/ui/icons/AddCircleIcon.vue';
import TheDangerModal from '@/components/ui/modal/TheDangerModal/TheDangerModal.vue';
import CustomUISchemaElement from '@/components/jsonforms/interfaces/CustomUISchemaElement';
import { createDefaultValue } from '@/components/jsonforms/util/renderer';
import DefaultButton from '@/components/ui/buttons/DefaultButton/DefaultButton.vue';
import ArrayListElement from './ArrayListElement.vue';

const controlRenderer = defineComponent({
  name: 'array-list-renderer',
  components: {
    DefaultButton,
    ArrayListElement,
    DispatchRenderer,
    AddCircleIcon,
    TheDangerModal,
  },
  props: {
    ...rendererProps<ControlElement>(),
  },
  setup(props: RendererProps<ControlElement>) {
    const control = useVanillaArrayControl(useJsonFormsArrayControl(props));
    const suggestToDelete = ref<null | number>(null);

    return { ...control, suggestToDelete };
  },
  watch: {
    noData() {
      if (this.noData) {
        this.createDefaultItem();
      }
    },
  },
  mounted() {
    if (this.noData) {
      this.createDefaultItem();
    }
  },
  computed: {
    noData(): boolean {
      return !this.control.data || this.control.data.length === 0;
    },
    labels(): string[] {
      const labels: string[] = [String(this.uischema.label ?? '')];
      const detailLabels: string[] = this.UISchema.elements?.map((item: CustomUISchemaElement) => item.label ?? '') ?? [];

      return detailLabels.length ? detailLabels : labels;
    },
    UISchema(): CustomUISchemaElement {
      if (_.has(this.uischema, 'options.detail')) {
        return this.uischema.options?.detail;
      }

      return this.childUiSchema;
    },
  },
  methods: {
    composePaths,
    createDefaultItem() {
      this.addItem(
        this.control.path,
        createDefaultValue(this.control.schema),
      )();
    },
    openRemoveModal(index: number) {
      this.suggestToDelete = index;
    },
    removeItemsClick(toDelete: number[]): void {
      this.removeItems?.(this.control.path, toDelete)();

      this.closeRemoveModal();
    },
    closeRemoveModal() {
      this.suggestToDelete = null;
    },
    approvalHandler(next: () => void): void {
      if (this.suggestToDelete || this.suggestToDelete === 0) {
        this.removeItemsClick([this.suggestToDelete]);
      }

      next();
    },
  },
});

export default controlRenderer;

export const entry: JsonFormsRendererRegistryEntry = {
  renderer: controlRenderer,
  tester: rankWith(2, schemaTypeIs('array')),
};
