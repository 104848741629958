import { Module } from 'vuex';
import info, { ConsumptionRegistryInfoStateType } from '@/store/modules/counterparty/block/registry/consumptionRegistry/info';
import { StateType } from '@/store';
import one, { ConsumptionRegistryOneStateType } from '@/store/modules/counterparty/block/registry/consumptionRegistry/one';

export interface ConsumptionRegistryStateType {
  info: ConsumptionRegistryInfoStateType,
  one: ConsumptionRegistryOneStateType,
}

const consumptionRegistryModule: Module<ConsumptionRegistryStateType, StateType> = {
  namespaced: true,
  modules: {
    info,
    one,
  },
};

export default consumptionRegistryModule;
