import { Module } from 'vuex';
import StructureService, {
  DivisionItem,
  DivisionResponseType,
  FilterDivisionParams,
} from '@/api/admin/dictionary/structure';
import { StateType } from '@/store';

export interface DivisionListStateType {
  items: DivisionItem[],
  filter: FilterDivisionParams,
}

const initialState = {
  items: [],
  filter: {
    companyId: null,
  },
};

const divisionListModule: Module<DivisionListStateType, StateType> = {
  namespaced: true,
  state: initialState,
  getters: {
    items: (state): DivisionItem[] => state.items,
    filter: (state): FilterDivisionParams => state.filter,
  },
  mutations: {
    setItems(state, items) {
      state.items = items;
    },
    setFilter(state, filter) {
      state.filter = filter;
    },
  },
  actions: {
    async changeFilter({ commit, dispatch }, filter: FilterDivisionParams) {
      commit('setFilter', filter);

      await dispatch('loadItems');
    },
    async loadItems({
      rootGetters, commit, getters,
    }, filterParams: FilterDivisionParams | null = null) {
      const localFilterParams = filterParams ?? getters.filter;

      const api: StructureService = rootGetters.$api.admin.dictionary.structure;

      const response: DivisionResponseType = await api.divisionList(localFilterParams);

      commit('setItems', response.data?.items ?? initialState.items);
      commit('setFilter', localFilterParams);
    },
  },
};

export default divisionListModule;
