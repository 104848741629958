import { Component, defineComponent, PropType } from 'vue';
import TheItem from '@/components/ui/tables/vertical/item/TheItem.vue';

export enum TableItemType {
  LINK = 'link',
  COMPONENT = 'component',
}

export interface TableItemSettings {
  type?: TableItemType,
  link?: string,
  text?: string,
  component?: Component,
  props?: object,
  events?: object,
}

export interface TableItem {
  [key: string]: TableItemSettings | string | number | boolean | null,
}

export interface TableFieldObject {
  key: string,
  label: string,
}

export default defineComponent({
  name: 'TheTable',
  components: {
    TheItem,
  },
  props: {
    item: {
      type: Object as PropType<TableItem>,
      default: () => ({}),
    },
    fields: {
      type: Array as PropType<TableFieldObject[]>,
      default: () => ([]),
    },
  },
});
