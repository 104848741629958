import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-521b17f5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "news-card" }
const _hoisted_2 = { class: "general-info" }
const _hoisted_3 = ["href"]
const _hoisted_4 = { class: "sub-menu" }
const _hoisted_5 = { class: "title" }
const _hoisted_6 = { class: "subtitle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass({'truncate-text': _ctx.contentLength > 100 && !_ctx.isOpen})
      }, _toDisplayString(_ctx.newsItem.content), 3),
      (_ctx.contentLength > 100)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleShow && _ctx.toggleShow(...args))),
            class: "inline-button"
          }, _toDisplayString(_ctx.isOpen ? _ctx.$t('buttons.hide') + ' ↑' : _ctx.$t('buttons.show')), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("a", {
      href: _ctx.newsItem.link,
      target: "_blank"
    }, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.newsItem.publishedAt), 1),
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.shortLink), 1)
      ])
    ], 8, _hoisted_3)
  ]))
}