import { defineComponent, PropType, ref } from 'vue';
import TabsMenuItem
  from '@/components/counterparty/card/header/tabs/item/TabsMenuItem.vue';
import TheDangerModal
  from '@/components/ui/modal/TheDangerModal/TheDangerModal.vue';
import RightArrow from '@/components/ui/icons/RightArrow.vue';
import { GroupType } from '@/api/card';

export default defineComponent({
  inject: ['$sizeWindow'],
  name: 'TabsMenu',
  components: {
    TabsMenuItem,
    TheDangerModal,
    RightArrow,
  },
  props: {
    selectedTab: {
      type: String,
      default: '',
    },
    tabs: {
      type: Array as PropType<GroupType[]>,
      default: () => ([]),
    },
    needConfirmation: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    counterpartyId: String,
  },
  emits: ['changeTab'],
  data() {
    return {
      activeTab: this.selectedTab,
      openDangerModal: false,
      isOpen: false,
    };
  },
  computed: {
    activeCurrentTab() {
      const currentTab = Object.keys(this.tabs).find((
        item,
      ) => this.tabs[item]?.id === this.selectedTab);
      return currentTab ? this.tabs[currentTab].name : '';
    },
    mobileMenuClass() {
      return this.isOpen ? 'h-fill absolute' : 'h-[60px]';
    },
  },
  setup() {
    const suggestToSelect = ref<null | string>(null);

    return { suggestToSelect };
  },
  mounted() {
    if (this.tabs && !this.activeTab) {
      this.activeTab = this.tabs[0]?.id ?? '';
    }
  },
  watch: {
    selectedTab(selectedTab: string) {
      if (selectedTab) {
        this.activeTab = selectedTab;
      }
    },
    tabs(tabs: GroupType[]) {
      if (!this.activeTab) {
        this.activeTab = tabs[0]?.id ?? '';
      }
    },
    activeTab(activeTab) {
      if (activeTab) {
        this.$emit('changeTab', this.activeTab);
      }
    },
  },
  methods: {
    isActiveTab(tab: string): boolean {
      return this.activeTab === tab;
    },
    closeModal() {
      this.suggestToSelect = null;
      this.openDangerModal = false;
    },
    approvalHandler(next?: () => void) {
      if (this.suggestToSelect) {
        this.activeTab = this.suggestToSelect;

        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });

        this.closeModal();

        if (next) {
          next();
        }
      }
    },
    clickHandler(event: { target: HTMLDivElement }) {
      const { key } = event.target.dataset;

      if (this.disabled || !key) {
        return;
      }

      this.suggestToSelect = key;

      if (this.needConfirmation && !this.isActiveTab(key)) {
        this.openDangerModal = true;

        return;
      }

      this.approvalHandler();

      if (this.isOpen) {
        this.toggleTab();
      }
    },
    toggleTab() {
      this.toggleScrollBlockClass();
      this.isOpen = !this.isOpen;
    },
    toggleScrollBlockClass() {
      document.body.classList.toggle('scroll-block');
    },
  },
});
