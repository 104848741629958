import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-[24px] w-[24px] flex items-center justify-center" }
const _hoisted_2 = ["width", "height"]
const _hoisted_3 = ["stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock("svg", {
      class: _normalizeClass(_ctx.isOpen ? '' : 'rotate-180' ),
      width: _ctx.width,
      height: _ctx.height,
      viewBox: "0 0 16 9",
      fill: "none",
      xmlns: "http://www.w3.org/2000/svg"
    }, [
      _createElementVNode("path", {
        d: "M15 1L8 8L1 1",
        stroke: _ctx.color,
        "stroke-width": "2",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }, null, 8, _hoisted_3)
    ], 10, _hoisted_2))
  ]))
}