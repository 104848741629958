
import _ from 'lodash';

import { defineComponent, PropType } from 'vue';
import { ErrorObject } from 'ajv';
import { JsonSchema, UISchemaElement } from '@jsonforms/core';
import { JsonFormsChangeEvent } from '@jsonforms/vue';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import FormGenerator from '@/components/jsonforms/generators/FormGenerator.vue';
import DefaultButton from '@/components/ui/buttons/DefaultButton/DefaultButton.vue';
import { validateData } from '@/utils/helpers/AjvValidator';
import { getChangedData } from '@/components/jsonforms/util/util';
import DefaultLoader from '@/components/ui/loaders/DefaultLoader.vue';

export default defineComponent({
  name: 'TheEditMdmData',
  emits: ['save', 'cancel', 'hasErrorsHandler'],
  components: {
    DefaultLoader,
    FormGenerator,
    DefaultButton,
  },
  props: {
    schema: {
      required: true,
      type: Object as PropType<JsonSchema>,
    },
    uischema: {
      required: true,
      type: Object as PropType<UISchemaElement>,
    },
    information: {
      type: Object as PropType<JsonData>,
      default: () => ({}),
    },
    isSchemaLoading: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    information() {
      this.data = this.information;
    },
  },
  data() {
    return {
      isOpen: false,
      isLoading: false,
      canSendRequest: false,
      data: this.information,
      changedData: {},
      errors: [] as ErrorObject[],
    };
  },
  computed: {
    preparedUischema() {
      return { ...this.uischema, type: 'VerticalLayout' };
    },
    dataBySchema(): JsonData {
      return this.getDataBySchema(this.data, this.schema);
    },
  },
  methods: {
    onChange(event: JsonFormsChangeEvent) {
      this.data = event.data;

      this.changedData = getChangedData(this.data, this.information);

      this.canSendRequest = Object.keys(this.changedData).length > 0;
    },
    validateForm(): boolean {
      this.errors = validateData(this.dataBySchema, this.schema, this.uischema);

      this.$emit('hasErrorsHandler', this.errors);

      return !this.errors || !this.errors.length;
    },
    getDataBySchema(data: JsonData, schema: JsonSchema): JsonData {
      return _.omitBy(
        data,
        (value, key: string) => !Object.prototype.hasOwnProperty.call(schema.properties, key),
      );
    },
    saveHandler() {
      this.isLoading = true;

      const valid: boolean = this.validateForm();

      if (!valid) {
        this.isLoading = false;

        return false;
      }

      this.$emit('save', this.dataBySchema, () => {
        this.isLoading = false;
      });

      return true;
    },
    cancelHandler() {
      this.$emit('cancel');
    },
  },
});
