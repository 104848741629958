import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-70cf0f2c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "month-layout" }
const _hoisted_2 = { class: "navigation-menu" }
const _hoisted_3 = { class: "month-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_month_item = _resolveComponent("month-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "change-day__button",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openDayLayout && _ctx.openDayLayout(...args)))
      }, _toDisplayString(_ctx.day), 1),
      _createElementVNode("div", {
        class: "change-year__button",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openYearLayout && _ctx.openYearLayout(...args)))
      }, _toDisplayString(_ctx.year), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.monthList, (month, index) => {
        return (_openBlock(), _createBlock(_component_month_item, {
          key: index,
          data: month,
          "is-selected": _ctx.isSelectedMonth(index),
          "is-current": _ctx.isCurrentMonth(index),
          onClick: ($event: any) => (_ctx.setMonth(index))
        }, null, 8, ["data", "is-selected", "is-current", "onClick"]))
      }), 128))
    ])
  ]))
}