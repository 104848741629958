import { defineComponent } from 'vue';
import BlockArrow from '@/components/ui/icons/BlockArrow.vue';
import IconButton from '@/components/ui/buttons/IconButton/IconButton.vue';
import CloseIcon from '@/components/ui/icons/CloseIcon.vue';
import EditIcon from '@/components/ui/icons/EditIcon.vue';

export default defineComponent({
  name: 'BlockDefault',
  emits: ['changed'],
  components: {
    BlockArrow,
    IconButton,
    CloseIcon,
    EditIcon,
  },
  props: {
    text: String,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  watch: {
    isOpen() {
      this.$emit('changed', this.isOpen);
    },
  },
  computed: {
    hasHeaderIcon() {
      return !!this.$slots['header-icon'];
    },
    headerClasses() {
      return {
        'block-header cursor-pointer': true,
        'has-header-icon': this.hasHeaderIcon,
      };
    },
  },
  methods: {
    toggleBlock() {
      this.isOpen = !this.isOpen;
    },
    hideBlock() {
      this.isOpen = false;
    },
    showBlock() {
      this.isOpen = true;
    },
  },
});
