import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "h-full flex items-center"
}
const _hoisted_2 = {
  key: 1,
  class: "sidebar-content"
}
const _hoisted_3 = { class: "sidebar-block" }
const _hoisted_4 = { class: "sidebar-block__title" }
const _hoisted_5 = { class: "sidebar-block" }
const _hoisted_6 = { class: "sidebar-footer" }
const _hoisted_7 = { class: "text-[14px]" }
const _hoisted_8 = { class: "text-[14px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_default_loader = _resolveComponent("default-loader")!
  const _component_form_generator = _resolveComponent("form-generator")!
  const _component_the_counterparty_data = _resolveComponent("the-counterparty-data")!
  const _component_default_button = _resolveComponent("default-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_default_loader)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('dictionary.pinning.detach')), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_form_generator, {
              uischema: _ctx.uischema,
              schema: _ctx.schema,
              information: _ctx.currentData,
              "additional-errors": _ctx.errors,
              "validation-mode": "ValidateAndHide",
              onChange: _ctx.onChange,
              class: "mb-[24px]"
            }, null, 8, ["uischema", "schema", "information", "additional-errors", "onChange"]),
            _createVNode(_component_the_counterparty_data, { data: _ctx.counterpartyData }, null, 8, ["data"])
          ])
        ])),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_default_button, {
        height: "40",
        onClick: _ctx.cancelHandler
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('buttons.cancel')), 1)
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_default_button, {
        type: "primary",
        height: "40",
        disabled: _ctx.isLoading,
        onClick: _ctx.detachHandler
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('dictionary.pinning.buttons.detach')), 1)
        ]),
        _: 1
      }, 8, ["disabled", "onClick"])
    ])
  ], 64))
}