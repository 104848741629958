import { QueryParamsForList } from '@/api/base';
import store from '@/store';
import PsychologicalCharacteristicService, {
  PsychologicalCharacteristicListResponseType,
} from '@/api/admin/dictionary/psychologicalCharacteristic';

export function useListApi() {
  return {
    async loadItems(params: QueryParamsForList) {
      const api: PsychologicalCharacteristicService = store
        .getters
        .$api
        .admin
        .dictionary
        .psychologicalCharacteristic;

      const response: PsychologicalCharacteristicListResponseType = await api.list(
        params,
      );

      return response.data?.items ?? [];
    },
  };
}

export default {};
