import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createBlock as _createBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, vShow as _vShow, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-693fa590"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "relative" }
const _hoisted_2 = { class: "flex w-full rounded-md shadow-sm" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = {
  key: 0,
  class: "default-select--placeholder"
}
const _hoisted_5 = {
  key: 1,
  class: "default-select--multiple"
}
const _hoisted_6 = ["title"]
const _hoisted_7 = { class: "default-select--clear-icon-block" }
const _hoisted_8 = { class: "default-select--icon-block" }
const _hoisted_9 = {
  ref: "calculatedBlock",
  class: "default-select-block"
}
const _hoisted_10 = {
  key: 0,
  class: "default-select--inline"
}
const _hoisted_11 = ["placeholder"]
const _hoisted_12 = {
  key: 0,
  tabindex: "0",
  role: "option",
  "aria-selected": "false",
  class: "default-select-item-disabled"
}
const _hoisted_13 = { class: "flex items-center space-x-3" }
const _hoisted_14 = { class: "block truncate" }
const _hoisted_15 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_remove_icon = _resolveComponent("select-remove-icon")!
  const _component_close_icon = _resolveComponent("close-icon")!
  const _component_select_arrow = _resolveComponent("select-arrow")!
  const _component_search_icon = _resolveComponent("search-icon")!
  const _component_selected_arrow = _resolveComponent("selected-arrow")!
  const _directive_calculate_position = _resolveDirective("calculate-position")!
  const _directive_outside_click = _resolveDirective("outside-click")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, [
      _createElementVNode("button", {
        type: "button",
        ref: "mainBlock",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleDropdown && _ctx.toggleDropdown(...args))),
        "aria-haspopup": "listbox",
        "aria-expanded": "true",
        "aria-labelledby": "listbox-label",
        class: _normalizeClass(["default-select", { error: _ctx.error, 'open' : _ctx.isOpen }]),
        disabled: _ctx.disabled
      }, [
        (_ctx.showPlaceholder)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.placeholder), 1))
          : (_ctx.multiple)
            ? (_openBlock(), _createElementBlock("ul", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localValue, (value) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: value,
                    class: "default-select-multiple--choice"
                  }, [
                    _createTextVNode(_toDisplayString(_ctx.getNameByValue(value)) + " ", 1),
                    (!this.disabled)
                      ? (_openBlock(), _createBlock(_component_select_remove_icon, {
                          key: 0,
                          class: "ml-[4px]",
                          onClick: _withModifiers(($event: any) => (_ctx.remove(value)), ["stop"])
                        }, null, 8, ["onClick"]))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ]))
            : (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: _normalizeClass(["default-select--choice", { 'with-clear-button': _ctx.showClearButton }])
              }, [
                _createElementVNode("span", {
                  title: _ctx.getNameByValue(_ctx.localValue)
                }, _toDisplayString(_ctx.getNameByValue(_ctx.localValue)), 9, _hoisted_6)
              ], 2)),
        _createElementVNode("div", _hoisted_7, [
          (_ctx.showClearButton)
            ? (_openBlock(), _createBlock(_component_close_icon, {
                key: 0,
                onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.remove('')), ["stop"]))
              }))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_select_arrow, {
            class: _normalizeClass({ 'rotate-180': _ctx.isOpen })
          }, null, 8, ["class"])
        ])
      ], 10, _hoisted_3)
    ]),
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_9, [
      (_ctx.showSearch)
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createElementVNode("input", {
              class: "default-select--search",
              ref: "search",
              placeholder: _ctx.$t('common.search'),
              onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.debounceFunction && _ctx.debounceFunction(...args)))
            }, null, 40, _hoisted_11),
            _createVNode(_component_search_icon, { class: "absolute w-[20px] h-[20px] top-[11px] left-[16px]" })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("ul", {
        tabindex: "-1",
        role: "listbox",
        "aria-labelledby": "listbox-label",
        class: _normalizeClass(["default-select-list", _ctx.showSearch ? 'use-search' : ''])
      }, [
        ((_ctx.filteredList && _ctx.filteredList.length === 0) || _ctx.isLoading)
          ? (_openBlock(), _createElementBlock("li", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.isLoading
                  ? _ctx.$t('ui.select.search.loading')
                  : _ctx.$t('ui.select.search.notFound')), 1)
              ])
            ]))
          : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.filteredList, (item) => {
              return (_openBlock(), _createElementBlock("li", {
                class: "default-select-item",
                tabindex: "0",
                role: "option",
                "aria-selected": "false",
                key: item.id,
                onClick: ($event: any) => (_ctx.select(item.id))
              }, [
                _createElementVNode("span", {
                  class: _normalizeClass(["truncate w-full flex justify-between items-center whitespace-normal", _ctx.isSelected(item.id) ? 'font-semibold' : 'font-normal'])
                }, [
                  _createTextVNode(_toDisplayString(item.name) + " ", 1),
                  (_ctx.multiple && _ctx.isSelected(item.id))
                    ? (_openBlock(), _createBlock(_component_selected_arrow, {
                        key: 0,
                        class: "default-select-success-arrow"
                      }))
                    : _createCommentVNode("", true)
                ], 2)
              ], 8, _hoisted_15))
            }), 128))
      ], 2)
    ])), [
      [_vShow, _ctx.isOpen],
      [_directive_calculate_position]
    ])
  ])), [
    [_directive_outside_click, _ctx.closeDropdown]
  ])
}