import { Module } from 'vuex';
import list, { BankListStateType } from '@/store/modules/admin/dictionary/bank/list';
import one, { BankOneStateType } from '@/store/modules/admin/dictionary/bank/one';
import { StateType } from '@/store';

export interface BankStateType {
  list: BankListStateType,
  one: BankOneStateType,
}

const bankModule: Module<BankStateType, StateType> = {
  namespaced: true,
  modules: {
    list,
    one,
  },
};

export default bankModule;
