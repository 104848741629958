import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_close_icon = _resolveComponent("close-icon")!
  const _component_icon_button = _resolveComponent("icon-button")!
  const _component_the_danger_modal = _resolveComponent("the-danger-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_Transition, { name: "slide-sidebar" }, {
      default: _withCtx(() => [
        (_ctx.isOpen)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "sidebar-background",
              onMousedown: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.outsideClose && _ctx.outsideClose(...args)))
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(["sidebar", _ctx.classes])
              }, [
                _createVNode(_component_icon_button, {
                  class: "close-button",
                  onClick: _ctx.closeModal
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_close_icon)
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _renderSlot(_ctx.$slots, "default")
              ], 2)
            ], 32))
          : _createCommentVNode("", true)
      ]),
      _: 3
    }),
    _createVNode(_component_the_danger_modal, {
      "approve-button-text": _ctx.$t('modals.confirmation.dangerModal.confirmButton'),
      text: _ctx.$t('modals.confirmation.dangerModal.text'),
      "hint-text": _ctx.$t('modals.confirmation.dangerModal.hintText'),
      "is-open": _ctx.isOpenDangerModal,
      onApprovalHandler: _ctx.approvalHandler,
      onCloseModal: _ctx.closeDangerModal
    }, null, 8, ["approve-button-text", "text", "hint-text", "is-open", "onApprovalHandler", "onCloseModal"])
  ], 64))
}