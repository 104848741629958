import {
  AxiosCallResponse,
  BaseService,
  LoggerInterface,
  ResponseOfRecords,
} from '@/api/base';

export interface RoleItem {
  id: string,
  type?: number,
  label: string,
  children?: RoleItem[],
}

export interface RoleBlock {
  id: string,
  title: string,
  items: RoleItem[],
}

export interface RoleGroup {
  id: string,
  title: string,
  items: RoleBlock[],
}

export interface RoleAttribute {
  [key: string]: string[],
}

export type RoleListType = AxiosCallResponse<ResponseOfRecords<RoleGroup[]>>;

export default class RoleService extends BaseService {
  constructor(token: string, logger: LoggerInterface = console) {
    super('admin/role', token, logger);
  }

  async list(): Promise<RoleListType> {
    return this.axiosCall({ method: 'get' });
  }
}
