import { BoardingSteps } from 'boarding.js';

export const getSteps = (): BoardingSteps => [
  {
    element: 'tbody',
    popover: {
      className: 'first-step-popover-class',
      title: 'Список записей',
      description: 'Список доступных вам записей.',
      prefferedSide: 'top',
    },
  },
  {
    element: '.table-item',
    popover: {
      className: 'first-step-popover-class',
      title: 'Детальная информация',
      description: 'При клике по записи откроется окно с детальной информацией.',
      prefferedSide: 'top',
    },
  },
  {
    element: '.table-item td:nth-child(2) .context-button',
    popover: {
      className: 'first-step-popover-class',
      title: 'Раскрытие вложенных данных',
      description: 'Если в записи имеются вложенные данные, нажмите на данную кнопку для их раскрытия',
      prefferedSide: 'bottom',
    },
  },
  {
    element: '.title-block button',
    popover: {
      className: 'first-step-popover-class',
      title: 'Добавление новой записи',
      description: 'Здесь вы можете добавить новую родительскую запись',
      prefferedSide: 'left',
    },
  },
  {
    element: '.filter-block',
    popover: {
      className: 'first-step-popover-class',
      title: 'Фильтры',
      description: 'Здесь вы можете отфильтровать записи по необходимым критериям.',
      prefferedSide: 'bottom',
    },
  },
];

export default {
  getSteps,
};
