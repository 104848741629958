import { Module } from 'vuex';
import SchemaService, { GetSchemaParams, SchemaType } from '@/api/schema';
import { StateType } from '@/store';
import { AxiosCallResponse } from '@/api/base';

export interface SchemaStateType {
  schema: SchemaType,
}

const initialState = {
  schema: {},
};

const schemaModule: Module<SchemaStateType, StateType> = {
  namespaced: true,
  state: initialState,
  getters: {
    schema: (state): SchemaType => state.schema,
  },
  mutations: {
    setSchema(state, schema: SchemaType) {
      state.schema = schema;
    },
  },
  actions: {
    async loadSchema({ commit, rootGetters }, params: GetSchemaParams) {
      const schemaApi: SchemaService = rootGetters.$api.schema;
      const response: AxiosCallResponse<SchemaType> = await schemaApi.getSchema(params);

      commit('setSchema', response.data ?? initialState.schema);
    },
  },
};

export default schemaModule;
