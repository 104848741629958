import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d9c0bed8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sidebar-content" }
const _hoisted_2 = { class: "sidebar-block" }
const _hoisted_3 = { class: "sidebar-block__title" }
const _hoisted_4 = { class: "sidebar-block" }
const _hoisted_5 = { class: "text-lg font-semibold mb-2" }
const _hoisted_6 = { class: "item-list" }
const _hoisted_7 = { class: "error-field__title" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "error-filed__value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_badge_in_work = _resolveComponent("badge-in-work")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('counterparty.card.syncFieldError.title')), 1),
        _createVNode(_component_badge_in_work, { class: "mt-4" })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('counterparty.card.syncFieldError.info')), 1),
        _createElementVNode("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "error-field"
            }, [
              _createElementVNode("div", _hoisted_7, [
                _createTextVNode(_toDisplayString(item.group.name) + " ➜ " + _toDisplayString(item.block.name) + " ", 1),
                (item.item)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_8, " ➜ " + _toDisplayString(item.item.name), 1))
                  : _createCommentVNode("", true),
                _createTextVNode(" ➜ " + _toDisplayString(item.field.name), 1)
              ]),
              _createElementVNode("div", _hoisted_9, _toDisplayString(item.value), 1)
            ]))
          }), 128))
        ])
      ])
    ])
  ]))
}