import { AxiosCallResponse, ResponseOfRecords } from '@/api/base';
import store from '@/store';
import PinningService, { AssignManager, AssignManagerFilterParams } from '@/api/admin/pinning';

export function useListApi() {
  return {
    async loadItems(params: AssignManagerFilterParams) {
      const api: PinningService = store.getters.$api.admin.pinning;

      const response: AxiosCallResponse<ResponseOfRecords<AssignManager>> = await api.managers(
        params,
      );

      return response.data?.items ?? [];
    },
  };
}

export default {};
