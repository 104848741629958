
import { defineComponent } from 'vue';
import ImageAvatar from '@/components/ui/avatars/ImageAvatar.vue';
import { mapGetters } from 'vuex';
import TheLogout from '@/components/ui/icons/TheLogout.vue';
import useStringHelper from '@/composables/helpers/text/useStringHelper';
import DefaultButton from '@/components/ui/buttons/DefaultButton/DefaultButton.vue';

export default defineComponent({
  name: 'TheProfile',
  components: {
    DefaultButton, TheLogout, ImageAvatar,
  },
  computed: {
    ...mapGetters({
      user: 'user/localUserData',
    }),
    userName() {
      return `${this.user.lastName ?? ''} ${this.user.firstName ?? ''} ${this.user.middleName ?? ''}`;
    },
    nameLetter() {
      return useStringHelper().getLetter(this.userName);
    },
  },
  methods: {
    async logoutHandler() {
      await this.$keycloak.logoutFn();
    },
  },
});
