import {
  AxiosCallResponse,
  BaseService,
  LoggerInterface,
  ResponseOfRecords,
} from '@/api/base';
import JsonData from '@/components/jsonforms/interfaces/JsonData';

export interface PaymentDataType {
  id: string,
  status: number,
  [key: string]: string | number | boolean,
}

export interface GetPaymentParams {
  page?: number | null,
  perPage?: number | null,
}

export type PaymentResponseType = ResponseOfRecords<PaymentDataType[]>;

export default class PaymentService extends BaseService {
  constructor(token: string, logger: LoggerInterface = console) {
    super('counterparty', token, logger);
  }

  async list(
    counterpartyId: string,
    params: GetPaymentParams = {},
  ): Promise<AxiosCallResponse<PaymentResponseType>> {
    return this.axiosCall({ url: `/${counterpartyId}/payment`, method: 'get', params });
  }

  async create(counterpartyId: string, payment: JsonData): Promise<AxiosCallResponse<null>> {
    return this.axiosCall({
      url: `/${counterpartyId}/payment`,
      method: 'post',
      data: payment,
    });
  }

  async edit(
    counterpartyId: string,
    id: string,
    payment: JsonData,
  ): Promise<AxiosCallResponse<null>> {
    return this.axiosCall({
      url: `/${counterpartyId}/payment/${id}`,
      method: 'patch',
      data: payment,
    });
  }

  async block(counterpartyId: string, id: string): Promise<AxiosCallResponse<null>> {
    return this.axiosCall({
      url: `/${counterpartyId}/payment/${id}/block`,
      method: 'post',
    });
  }

  async unblock(counterpartyId: string, id: string): Promise<AxiosCallResponse<null>> {
    return this.axiosCall({
      url: `/${counterpartyId}/payment/${id}/unblock`,
      method: 'post',
    });
  }
}
