import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6fc030fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal modal-bottom sm:modal-middle" }
const _hoisted_2 = { class: "modal-header" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "px-[32px] pb-[24px] md:pb-[32px]" }
const _hoisted_5 = {
  key: 0,
  class: "modal-action"
}
const _hoisted_6 = { class: "text-[14px]" }
const _hoisted_7 = { class: "text-[14px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_close_icon = _resolveComponent("close-icon")!
  const _component_icon_button = _resolveComponent("icon-button")!
  const _component_default_button = _resolveComponent("default-button")!

  return (_ctx.isShowModal)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "modal-background",
        onMousedown: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.outsideClose && _ctx.outsideClose(...args)))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", {
            class: "p-0",
            style: _normalizeStyle(_ctx.$sizeWindow.isMd ? '' : `width:${_ctx.width}` )
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_icon_button, {
                class: "close-button-icon",
                onClick: _ctx.closeModal
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_close_icon)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createElementVNode("h1", {
                class: "title-modal",
                innerHTML: _ctx.title
              }, null, 8, _hoisted_3)
            ]),
            _createElementVNode("div", _hoisted_4, [
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ]),
            (_ctx.action)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_default_button, {
                    height: "40",
                    onClick: _ctx.closeModal
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('buttons.cancel')), 1)
                    ]),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_default_button, {
                    type: "primary",
                    height: "40",
                    disabled: _ctx.isDisabledButton,
                    onClick: _ctx.approve
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.addButtonText), 1)
                    ]),
                    _: 1
                  }, 8, ["disabled", "onClick"])
                ]))
              : _createCommentVNode("", true)
          ], 4)
        ])
      ], 32))
    : _createCommentVNode("", true)
}