import { Module } from 'vuex';
import {
  AxiosCallResponse, MetaParams, QueryParamsForList, SortParams,
} from '@/api/base';
import BlockHistoryService, {
  HistoryItemType,
  HistoryResponseType,
  HistoryListParams,
} from '@/api/block/history';
import { StateType } from '@/store';

export interface HistoryLoadParams {
  id: string,
  params: HistoryListParams,
}

export interface HistoryStateType {
  history: HistoryItemType[],
  currentBlockId?: string | null,
  pagination: MetaParams,
  sort: SortParams
}

const initialState = {
  history: [],
  currentBlockId: null,
  pagination: {
    currentPage: null,
    pageCount: null,
    perPage: null,
    totalCount: null,
  },
  sort: {
    sortBy: null,
  },
};

const historyModule: Module<HistoryStateType, StateType> = {
  namespaced: true,
  state: initialState,
  getters: {
    history: (state): HistoryItemType[] => state.history,
    pagination: (state): MetaParams => state.pagination,
    sort: (state): SortParams => state.sort,
    currentBlockId: (state): string => state.currentBlockId ?? '',
    loadHistoryParams: (state): QueryParamsForList => ({
      page: state.pagination.currentPage,
      perPage: state.pagination.perPage,
      sortBy: state.sort.sortBy,
    }),
  },
  mutations: {
    setPagination(state, pagination) {
      state.pagination = pagination;
    },
    setSort(state, sort) {
      state.sort = sort;
    },
    setHistory(state, history) {
      state.history = history;
    },
    setCurrentBlockId(state, id) {
      state.currentBlockId = id;
    },
  },
  actions: {
    async changePagination({ dispatch, commit }, pagination: MetaParams) {
      commit('setPagination', pagination);

      await dispatch('loadHistory');
    },
    async changeSort({ dispatch, commit }, sort: SortParams) {
      commit('setSort', sort);

      await dispatch('loadHistory');
    },
    async changeCurrentBlockId({ dispatch, commit }, id: string) {
      commit('setCurrentBlockId', id);

      await dispatch('loadHistory');
    },
    async deleteHistoryItem({
      getters, rootGetters, dispatch, rootState,
    }, historyId: string) {
      const cardId = rootState.counterparty.card.currentCardId;

      if (cardId) {
        const historyApi: BlockHistoryService = rootGetters.$api.history;

        await historyApi.deleteHistoryItem(historyId);
      }

      await dispatch('loadHistory', getters.currentBlockId);
    },
    async loadHistory({
      getters, rootGetters, commit, rootState,
    }, data: HistoryLoadParams): Promise<HistoryResponseType | null> {
      const cardId = rootState.counterparty.card.currentCardId;

      if (cardId) {
        const historyApi: BlockHistoryService = rootGetters.$api.history;
        const { id: blockId, params = null } = data;

        const loadParams: QueryParamsForList = params ?? getters.loadHistoryParams;

        const response: AxiosCallResponse<HistoryResponseType> = await historyApi
          .getBlockHistory(cardId, blockId, loadParams);

        commit('setHistory', response.data?.items ?? initialState.history);
        commit('setPagination', response.data?._meta ?? initialState.pagination);

        return response.data ?? null;
      }

      return null;
    },
  },
};

export default historyModule;
