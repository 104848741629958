import { BoardingSteps } from 'boarding.js';

export const getSteps = (): BoardingSteps => [
  {
    element: '.help-button',
    popover: {
      className: 'first-step-popover-class',
      title: 'Помощь',
      description: 'Вы в любой момент можете вызвать подсказки по навигации, кликнув поэтой кнопке. Для вызова подсказок для конкретной страницы нужно кликнуть на эту кнопку находясь на ней.',
      prefferedSide: 'top',
    },
  },
  {
    element: '.profile-button',
    popover: {
      className: 'first-step-popover-class',
      title: 'Профиль',
      description: 'В этом разделе находятся данные, идентифицирующие вас как пользователя системы. Для выхода из системы, вам необходимо войти в профиль и нажать кнопку <strong>Выйти</strong>.',
      prefferedSide: 'top',
    },
  },
  {
    element: '.counterparty-button',
    popover: {
      className: 'first-step-popover-class',
      title: 'Список контрагентов',
      description: 'В данный список выводятся доступные Вам контрагенты: закрепленные, свободный блок дивизиона или закрепленные в Вашем подразделении.',
      prefferedSide: 'right',
    },
  },
  {
    element: '.users-button',
    popover: {
      className: 'first-step-popover-class',
      title: 'Список пользователей',
      description: 'В этом разделе находится список и настройки пользователей.',
      prefferedSide: 'right',
    },
  },
  {
    element: '.assign-button',
    popover: {
      className: 'first-step-popover-class',
      title: 'Список закреплений',
      description: 'В этом разделе находится список закреплений.',
      prefferedSide: 'right',
    },
  },
  {
    element: '.dictionary-button',
    popover: {
      className: 'first-step-popover-class',
      title: 'Справочники',
      description: 'В данном разделе находятся справочники системы.',
      prefferedSide: 'right',
    },
  },
  {
    element: '.notification-button',
    popover: {
      className: 'first-step-popover-class',
      title: 'Уведомления',
      description: 'Здесь находятся уведомления и их настройки.',
      prefferedSide: 'right',
    },
  },
  {
    element: 'tbody',
    popover: {
      className: 'first-step-popover-class',
      title: 'Список контрагентов',
      description: 'Список ваших контрагентов.',
      prefferedSide: 'top',
    },
  },
  {
    element: '.table-item',
    popover: {
      className: 'first-step-popover-class',
      title: 'Краткая информация',
      description: 'При клике по контрагенту, откроется окно с краткой информацией.',
      prefferedSide: 'top',
    },
  },
  {
    element: '.table-item .icon-btn.relative',
    popover: {
      className: 'first-step-popover-class',
      title: 'Карточка контрагента',
      description: 'Для перехода к полной информации по контрагенту кликните по этой стрелочке',
      prefferedSide: 'top',
    },
  },
  {
    element: '.title-block button',
    popover: {
      className: 'first-step-popover-class',
      title: 'Добавление нового контрагента',
      description: 'Здесь Вы можете добавить нового контрагента.',
      prefferedSide: 'left',
    },
  },
  {
    element: '.filter-block .input',
    popover: {
      className: 'first-step-popover-class',
      title: 'Поиск',
      description: 'Здесь Вы можете отфильтровать список по необходимым критериям.',
      prefferedSide: 'bottom',
    },
  },
  {
    element: '.filter-block .tooltip.circle-button',
    popover: {
      className: 'first-step-popover-class',
      title: 'Фильтры',
      description: 'Здесь Вы можете отфильтровать список по нужным атрибутам.',
      prefferedSide: 'left',
    },
  },
  {
    element: '.filter-block .tooltip.circle-button:nth-child(3)',
    popover: {
      className: 'first-step-popover-class',
      title: 'Очистка фильтра',
      description: 'Кликнув на эту кнопку Вы сбросите все настроенные ранее фильтры.',
      prefferedSide: 'left',
    },
  },
];

export default {
  getSteps,
};
