
import { defineComponent, ref } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import DictionaryGenerator from '@/components/admin/dictionary/generator/DictionaryGenerator.vue';
import useCheckPermissions from '@/composables/useCheckPermissions';
import { FilterParams, PaginationParams, SortParams } from '@/api/base';

export default defineComponent({
  name: 'TheList',
  components: {
    DictionaryGenerator,
  },
  data() {
    return {
      isLoading: false,
      fields: [
        { key: 'name', label: this.$t('dictionary.nomenclature.fields.name') },
      ],
      schema: {
        type: 'object',
        properties: {
          name: {
            type: 'string',
          },
          fullName: {
            type: 'string',
          },
          codeMdm: {
            type: 'string',
          },
          groupId: {
            type: 'string',
          },
          measUnit: {
            type: 'string',
          },
          vatRate: {
            type: 'string',
          },
          vendorCode: {
            type: 'string',
          },
        },
        required: ['name'],
      },
      uischema: {
        type: 'VerticalLayout',
        elements: [
          {
            type: 'Control',
            label: this.$t('dictionary.nomenclature.fields.name'),
            scope: '#/properties/name',
          },
          {
            type: 'Control',
            label: this.$t('dictionary.nomenclature.fields.fullName'),
            scope: '#/properties/fullName',
          },
          {
            type: 'Control',
            label: this.$t('dictionary.nomenclature.fields.codeMdm'),
            scope: '#/properties/codeMdm',
          },
          {
            type: 'AjaxControl',
            label: this.$t('dictionary.nomenclature.fields.groupId'),
            scope: '#/properties/groupId',
            options: {
              search: true,
              ajaxConfiguration: {
                categoryName: 'nomenclatureGroup',
              },
            },
          },
          {
            type: 'Control',
            label: this.$t('dictionary.nomenclature.fields.measUnit'),
            scope: '#/properties/measUnit',
          },
          {
            type: 'Control',
            label: this.$t('dictionary.nomenclature.fields.vatRate'),
            scope: '#/properties/vatRate',
          },
          {
            type: 'Control',
            label: this.$t('dictionary.nomenclature.fields.vendorCode'),
            scope: '#/properties/vendorCode',
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters({
      items: 'admin/dictionary/nomenclature/list/items',
      filter: 'admin/dictionary/nomenclature/list/filter',
      pagination: 'admin/dictionary/nomenclature/list/pagination',
      sort: 'admin/dictionary/nomenclature/list/sort',
    }),
  },
  methods: {
    ...mapActions({
      loadItems: 'admin/dictionary/nomenclature/list/loadItems',
      resetState: 'admin/dictionary/nomenclature/list/resetState',
      changeFilter: 'admin/dictionary/nomenclature/list/changeFilter',
      changePagination: 'admin/dictionary/nomenclature/list/changePagination',
      changeSort: 'admin/dictionary/nomenclature/list/changeSort',
    }),
    async changeFilterHandler(filter: FilterParams) {
      await this.changeFilter(filter);

      await this.loadItems();
    },
    async changePaginationHandler(pagination: PaginationParams) {
      await this.changePagination(pagination);

      await this.loadItems();
    },
    async changeSortHandler(sort: SortParams) {
      await this.changeSort(sort);

      await this.loadItems();
    },
  },
  async created() {
    this.isLoading = true;

    await this.loadItems();

    this.isLoading = false;
  },
  unmounted() {
    this.resetState();
  },
  setup() {
    const checkPermissions = useCheckPermissions();

    const isCanEdit = ref(checkPermissions.dictionary.canEditNomenclatureGroup);

    return { isCanEdit };
  },
});
