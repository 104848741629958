import { Module } from 'vuex';
import one, { EducationOneStateType } from '@/store/modules/admin/dictionary/education/one';
import list, { EducationListStateType } from '@/store/modules/admin/dictionary/education/list';
import { StateType } from '@/store';

export interface EducationStateType {
  list: EducationListStateType,
  one: EducationOneStateType,
}

const educationModule: Module<EducationStateType, StateType> = {
  namespaced: true,
  modules: {
    list,
    one,
  },
};

export default educationModule;
