import {
  AxiosCallResponse,
  LoggerInterface,
  ResponseOfRecords,
} from '@/api/base';
import DictionaryBase from '@/api/admin/dictionary/base';

export interface CountryItem {
  id: number,
  shortName: string,
  fullName: string,
  code: string,
  alpha2: string,
  alpha3: string,
}

export interface CountryFormData {
  shortName: string,
  fullName: string,
  code: string,
  alpha2: string,
  alpha3: string,
}

export type CountryListResponseType = AxiosCallResponse<ResponseOfRecords<CountryItem[]>>;

export default class CountryService extends DictionaryBase {
  constructor(token: string, logger: LoggerInterface = console) {
    super('country', token, logger);
  }
}
