
import { defineComponent } from 'vue';
import RightArrow from '@/components/ui/icons/RightArrow.vue';
import IconButton from '@/components/ui/buttons/IconButton/IconButton.vue';

export default defineComponent({
  name: 'OpenCardButton',
  components: {
    IconButton, RightArrow,
  },
  props: {
    link: {
      type: String,
      default: '',
    },
  },
});
