import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_table_item = _resolveComponent("table-item")!

  return (_openBlock(), _createElementBlock("tbody", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.news, (newItem) => {
      return (_openBlock(), _createBlock(_component_table_item, {
        newItem: newItem,
        key: newItem.id,
        onActionHandler: _ctx.actionHandler
      }, null, 8, ["newItem", "onActionHandler"]))
    }), 128))
  ]))
}