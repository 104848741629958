
import { defineComponent, ref } from 'vue';
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import DefaultLoader from '@/components/ui/loaders/DefaultLoader.vue';
import TheTitle from '@/components/admin/dictionary/structure/title/TheTitle.vue';
import TheTable from '@/components/ui/tables/hierarchy/TheTable.vue';
import { FilterStructureParams, StructureItem } from '@/api/admin/dictionary/structure';
import CircleCheckIcon from '@/components/ui/icons/CircleCheckIcon.vue';
import { TableItem } from '@/components/ui/tables/simple/TheTable';
import TheCreate from '@/components/admin/dictionary/structure/create/TheCreate.vue';
import TheSideModal from '@/components/ui/modal/TheSideModal/TheSideModal.vue';
import ContextMenu from '@/components/ui/menu/ContextMenu/ContextMenu.vue';
import EditIcon from '@/components/ui/icons/EditIcon.vue';
import DeleteIcon from '@/components/ui/icons/TrashIcon.vue';
import TheDangerModal from '@/components/ui/modal/TheDangerModal/TheDangerModal.vue';
import AddCircleIcon from '@/components/ui/icons/AddCircleIcon.vue';
import TheFilter from '@/components/admin/dictionary/structure/filter/TheFilter.vue';
import { fillSearchText } from '@/components/jsonforms/helpers/TreeHelper';
import { CompanyItem } from '@/api/admin/dictionary/company';
import useCheckPermissions from '@/composables/useCheckPermissions';
import TheDetail from '@/components/admin/dictionary/structure/detail/TheDetail.vue';
import statuses from '@/shared/consts/statuses';

export default defineComponent({
  name: 'TheList',
  inject: ['$userAccess'],
  components: {
    TheDetail,
    DefaultLoader,
    TheTitle,
    TheFilter,
    TheTable,
    TheSideModal,
    TheCreate,
    TheDangerModal,
  },
  data() {
    return {
      isLoading: false,
      isOpenSidebar: false,
      isOpenDangerModal: false,
      localItems: [],
      isCreate: false,
      isUpdate: false,
      queryTree: '',
      fields: [
        { key: 'name', label: this.$t('dictionary.structure.table.name'), notSortable: true },
        {
          key: 'department',
          label: this.$t('dictionary.structure.table.department'),
          notSortable: true,
        },
        { key: 'code', label: this.$t('dictionary.structure.table.code'), notSortable: true },
        {
          key: 'action', notSortable: true, width: 52, isVisible: this.isCanEdit,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      items: 'admin/dictionary/structure/list/items',
      companies: 'admin/dictionary/company/list/items',
      filter: 'admin/dictionary/structure/list/filter',
      user: 'user/localUserData',
    }),
    preparedItems(): TableItem[] {
      const items: StructureItem[] = this.localItems ?? [];

      return this.prepareItem(items);
    },
    defaultOneOfList() {
      return [{ id: null, name: '' }];
    },
    preparedCompanies() {
      if (this.companies.length > 0) {
        return [
          { id: null, name: '' },
          ...this.companies.map((company: CompanyItem) => ({
            id: company.id,
            name: company.name,
          })),
        ];
      }
      return this.defaultOneOfList;
    },
    isDeletedStatus() {
      return Number(this.filter.status) === statuses.STATUS_TYPE_DELETE;
    },
  },
  methods: {
    ...mapActions({
      loadItems: 'admin/dictionary/structure/list/loadItems',
      resetState: 'admin/dictionary/structure/list/resetState',
      loadCompanies: 'admin/dictionary/company/list/loadItems',
      changeCompanyFilter: 'admin/dictionary/company/list/changeFilter',
      changeFilter: 'admin/dictionary/structure/list/changeFilter',
      create: 'admin/dictionary/structure/one/create',
      delete: 'admin/dictionary/structure/one/delete',
      update: 'admin/dictionary/structure/one/update',
    }),
    searchInTreeHandler(value: string) {
      if (value) {
        this.queryTree = value;
        this.localItems = this.findToTree(_.cloneDeep(this.items), value);
        fillSearchText(this.localItems, value);
      } else {
        this.localItems = _.cloneDeep(this.items);
        this.queryTree = '';
      }
    },
    addNewCompany() {
      this.isOpenSidebar = true;
      this.isCreate = true;
      this.isUpdate = false;
    },
    addChildrenHandler() {
      this.isCreate = true;
      this.isUpdate = false;
      this.parentId = this.selectedItemId;
    },
    prepareItem(items: StructureItem[]) {
      return items.map((item: StructureItem) => ({
        id: item.id,
        name: item.name,
        department: {
          type: 'component',
          component: item.isDepartment ? CircleCheckIcon : null,
          props: {
            width: '16',
            height: '16',
            color: '#22C55E',
          },
        },
        code: item.code,
        items: item.items ? this.prepareItem(item.items) : [],
        action: {
          type: 'component',
          component: ContextMenu,
          props: this.contextMenuProps(item),
        },
      }));
    },
    contextMenuProps(item: StructureItem) {
      return {
        params: [
          {
            text: this.$t('dictionary.structure.create.addChild'),
            icon: AddCircleIcon,
            events: {
              click: () => {
                this.isOpenSidebar = true;
                this.parentId = item.id;
                this.isCreate = true;
              },
            },
          },
          {
            text: this.$t('buttons.edit'),
            icon: EditIcon,
            events: {
              click: () => {
                this.isOpenSidebar = true;
                this.selectedItemData = this.findElementById(this.items, item.id);
                this.isUpdate = true;
              },
            },
          },
          {
            text: this.$t('buttons.delete'),
            icon: DeleteIcon,
            events: {
              click: () => {
                this.openDangerModal(item.id);
              },
            },
            iconProps: {
              type: 'outline',
            },
          },
        ],
      };
    },
    openDangerModal(id: string) {
      this.isOpenDangerModal = true;
      this.selectedItemId = id;
    },
    async loadAndPrepareItems() {
      await this.loadItems();

      this.localItems = _.cloneDeep(this.items);
    },
    async changeFilterHandler(filter: FilterStructureParams) {
      await this.changeFilter(filter);
      await this.loadAndPrepareItems();
    },
    viewHandler(id: string) {
      this.selectedItemData = this.findElementById(this.items, id);
      this.selectedItemId = id;
      this.isOpenSidebar = true;
    },
    closeSidebar() {
      this.isOpenSidebar = false;
      this.selectedItemData = null;
      this.isUpdate = false;
      this.isCreate = false;
      this.parentId = null;
    },
    async dangerApprovalHandler(next: () => void) {
      await this.delete(this.selectedItemId);

      await this.loadAndPrepareItems();

      this.selectedItemId = null;
      this.isOpenDangerModal = false;

      this.closeSidebar();

      next();
    },
    dangerCloseHandler() {
      this.selectedItemId = null;
      this.isOpenDangerModal = false;
    },
    findElementById(array: StructureItem[], id: string) {
      let resultItem: StructureItem | undefined;

      for (const item of array) {
        if (item.id === id) {
          resultItem = item;
          break;
        } else if (item.items) {
          resultItem = this.findElementById(item.items, id);

          if (resultItem) {
            break;
          }
        }
      }

      return resultItem;
    },
    findToTree(array: StructureItem[], query: string) {
      return array.filter((item: StructureItem) => {
        if (item.name.toLowerCase().includes(query.toLowerCase())) {
          return item;
        }

        if (item.items) {
          const items = this.findToTree(item.items, query);

          if (items.length) {
            // eslint-disable-next-line no-param-reassign
            item.items = items;

            return item;
          }
        }

        return false;
      });
    },
    async createHandler(data: StructureItem, next: () => void) {
      await this.create({
        ...data,
        companyId: this.filter.companyId,
      });

      await this.loadAndPrepareItems();

      next();

      this.closeSidebar();
    },
    async createChildHandler(data: StructureItem, next: () => void) {
      await this.createHandler({
        ...data,
        parentId: this.parentId,
      }, next);
    },
    async updateHandler(data: { id: string, data: StructureItem }, next: () => void) {
      await this.update(data);

      await this.loadAndPrepareItems();
      next();

      this.closeSidebar();
    },
    async ajaxCompanyHandler(query: string) {
      await this.changeCompanyFilter({ query });
      await this.loadCompanies();
    },
    changeModeHandler(value: boolean) {
      this.isUpdate = value;
    },
  },
  async created() {
    this.isLoading = true;

    if (this.$userAccess.admin.isSuperAdmin) {
      await this.loadCompanies();
    }

    await this.changeFilter({
      companyId: this.$userAccess.admin.isSuperAdmin ? this.user?.company : null,
    });

    await this.loadAndPrepareItems();

    this.isLoading = false;
  },
  unmounted() {
    this.resetState();
  },
  setup() {
    const selectedItemData = ref<StructureItem | null>(null);
    const selectedItemId = ref<string | null>(null);
    const parentId = ref<string | undefined>(undefined);
    const checkPermissions = useCheckPermissions();
    const isCanEdit = ref(checkPermissions.dictionary.canEditStructure);

    return {
      selectedItemData, selectedItemId, parentId, isCanEdit,
    };
  },
});
