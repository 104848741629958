import _ from 'lodash';
import i18n from '@/i18n';
import { Module } from 'vuex';
import { AxiosCallResponse } from '@/api/base';
import { StateType } from '@/store';
import { TYPE_LABEL_SUCCESS } from '@/components/ui/notifications/TheAlert/TheAlert.vue';
import RelationshipBreakService, {
  RelationshipBreakItem,
} from '@/api/block/relationship/relationshipBreak';
import JsonData from '@/components/jsonforms/interfaces/JsonData';

export interface RelationshipBreakOneStateType {
  data: RelationshipBreakItem | null,
}

const initialState = {
  data: null,
};

const relationshipBreakOneModule: Module<RelationshipBreakOneStateType, StateType> = {
  namespaced: true,
  state: _.cloneDeep(initialState),
  getters: {
    data: (state) => state.data,
  },
  mutations: {
    setData(state, data) {
      state.data = data;
    },
  },
  actions: {
    async create({ rootGetters }, params: {
      cardId: string,
      data: JsonData,
    }): Promise<RelationshipBreakItem | null> {
      const { cardId, data } = params;

      if (cardId) {
        const api: RelationshipBreakService = rootGetters.$api.relationship.relationshipBreak;

        const response: AxiosCallResponse<RelationshipBreakItem> = await api.create(
          cardId,
          data,
        );

        if (!response.error) {
          await rootGetters
            .$notificationLogger
            .setNotification(
              TYPE_LABEL_SUCCESS,
              i18n.global.t('notification.admin.dictionary.success.create'),
            );

          return response.data ?? null;
        }
      }

      return null;
    },
    async update({ rootGetters }, params: {
      cardId: string,
      id: string,
      data: JsonData,
    }): Promise<void> {
      const { cardId, id, data } = params;

      if (cardId) {
        const api: RelationshipBreakService = rootGetters.$api.relationship.relationshipBreak;

        const response: AxiosCallResponse<null> = await api.update(
          cardId,
          id,
          data,
        );

        if (!response.error) {
          await rootGetters
            .$notificationLogger
            .setNotification(
              TYPE_LABEL_SUCCESS,
              i18n.global.t('notification.admin.dictionary.success.update'),
            );
        }
      }
    },
    async delete({ rootGetters }, params: {
      cardId: string,
      id: string,
    }): Promise<void> {
      const { cardId, id } = params;

      if (cardId) {
        const api: RelationshipBreakService = rootGetters.$api.relationship.relationshipBreak;

        const response: AxiosCallResponse<null> = await api.delete(cardId, id);

        if (!response.error) {
          await rootGetters
            .$notificationLogger
            .setNotification(
              TYPE_LABEL_SUCCESS,
              i18n.global.t('notification.admin.dictionary.success.delete'),
            );
        }
      }
    },
  },
};

export default relationshipBreakOneModule;
