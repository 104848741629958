import { QueryParamsForList } from '@/api/base';
import store from '@/store';
import ActivityDirectionService, { ActivityDirectionListResponseType } from '@/api/admin/dictionary/activityDirection';

export function useListApi() {
  return {
    async loadItems(params: QueryParamsForList) {
      const api: ActivityDirectionService = store.getters.$api.admin.dictionary.activityDirection;

      const response: ActivityDirectionListResponseType = await api.list(
        params,
      );

      return response.data?.items ?? [];
    },
  };
}

export default {};
