import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dictionary_generator = _resolveComponent("dictionary-generator")!

  return (_openBlock(), _createBlock(_component_dictionary_generator, {
    title: _ctx.$t('dictionary.nomenclature.title'),
    "is-loading": _ctx.isLoading,
    items: _ctx.items,
    fields: _ctx.fields,
    filter: _ctx.filter,
    sort: _ctx.sort,
    pagination: _ctx.pagination,
    schema: _ctx.schema,
    uischema: _ctx.uischema,
    "is-can-edit": false,
    onChangeFilter: _ctx.changeFilterHandler,
    onChangeSort: _ctx.changeSortHandler,
    onChangePagination: _ctx.changePaginationHandler
  }, null, 8, ["title", "is-loading", "items", "fields", "filter", "sort", "pagination", "schema", "uischema", "onChangeFilter", "onChangeSort", "onChangePagination"]))
}