
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CheckboxInput',
  emits: ['clickHandler'],
  data() {
    return {
      isClicked: false,
      localValue: this.value,
    };
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isIncompleteFilling: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
  },
  methods: {
    clickHandler() {
      if (!this.isDisabled) {
        this.isClicked = true;
        this.localValue = !this.localValue;
        this.$emit('clickHandler');
        setTimeout(() => {
          this.isClicked = false;
        }, 700);
      }
    },
  },
});
