const communication = Object.freeze({
  BLOCK_FIELD_DATE_CONTACT: 'c668e710-9da2-49e6-aef9-e96994a258a2',
  BLOCK_FIELD_TYPE: '67b258a2-fafb-4a28-9c93-2399838d033e',
  BLOCK_FIELD_USER: '2ed72aee-f3c2-42a6-9719-f79b7b70aa0d',
  BLOCK_FIELD_STATUS: 'da08ea50-d89b-4554-9c5b-05c70107b46a',

  STATUS_PLANNED: 'Запланировано',
  STATUS_COMPLETED: 'Завершено',
  STATUS_CANCELED: 'Отменено',
});

export default communication;
