
import { defineComponent } from 'vue';
import SelectArrow from '@/components/ui/icons/SelectArrow.vue';
import IconButton from '@/components/ui/buttons/IconButton/IconButton.vue';
import CheckboxInput from '@/components/ui/inputs/CheckboxInput/CheckboxInput.vue';
import { DataTypeTree } from '@/components/ui/selects/SelectTree/SelectTree.vue';

const OFFSET_ITEM = 16;

export default defineComponent({
  name: 'TheItem',
  components: {
    SelectArrow,
    IconButton,
    CheckboxInput,
  },
  emits: ['selectValue', 'parentChoice', 'selectChildrenItems'],
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    parentSelected: {
      type: Boolean,
      default: false,
    },
    selectedValue: {
      type: [Array, String],
      default: () => ([]),
    },
    offset: {
      type: Number,
      default: 0,
    },
    isAllOpen: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: this.isAllOpen,
    };
  },
  watch: {
    isAllOpen(val: boolean) {
      this.isOpen = val;
    },
  },
  computed: {
    isSelectedItem() {
      return !this.multiple && this.selectedValue === this.item.id;
    },
    isHaveChild() {
      return this.item.items && this.item.items.length > 0;
    },
    parentSelect() {
      const hasSelectedValue = this.selectedValue && this.selectedValue.includes(this.item.id);

      return hasSelectedValue || this.parentSelected;
    },
    offsetCount() {
      return this.isHaveChild ? this.offset : this.offset + OFFSET_ITEM;
    },
    isFilling() {
      if (this.item.items && this.item.items.length) {
        let result = 0;
        this.item.items.forEach((item: DataTypeTree) => {
          if (this.selectedValue.includes(item.id)) {
            result += 1;
          }
        });

        if (this.item.items.length === result) {
          this.$emit('selectValue', this.item.id);
        }

        if (result !== 0) {
          return true;
        }

        const allChild = this.getAllChildren(this.item.items);

        let childResult = 0;

        allChild.forEach((item: string) => {
          if (this.selectedValue.includes(item)) {
            childResult += 1;
          }
        });

        if (childResult !== 0 && allChild.length > childResult) {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    toggleList() {
      this.isOpen = !this.isOpen;
    },
    selectValue() {
      if (this.selectedValue && !this.selectedValue.includes(this.item.id) && this.parentSelected) {
        this.$emit('selectChildrenItems', this.item.id);
      } else {
        this.$emit('selectValue', this.item.id);
      }
    },
    selectChildValue(id: string | number) {
      this.$emit('selectValue', id);
    },
    selectChildrenItems(id: string | number) {
      if (this.selectedValue && this.selectedValue.includes(this.item.id)) {
        this.$emit('selectValue', this.item.id);
      }

      if (this.parentSelected) {
        this.$emit('selectChildrenItems', this.item.id);
      }

      this.item.items.filter((item: DataTypeTree) => (item.id !== id))
        .forEach((item: DataTypeTree) => {
          this.$emit('selectValue', item.id);
        });
    },
    getAllChildren<T>(items: DataTypeTree<T> []) {
      const result: T[] = [];

      items.forEach((item: DataTypeTree<T>) => {
        result.push(item.id);

        if (item.items && item.items.length > 0) {
          result.push(...this.getAllChildren(item.items));
        }
      });

      return result;
    },
  },
});
