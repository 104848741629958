
import _ from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import { defineComponent } from 'vue';
import IconButton from '@/components/ui/buttons/IconButton/IconButton.vue';
import FilterIcon from '@/components/ui/icons/FilterIcon.vue';
import SearchIcon from '@/components/ui/icons/SearchIcon.vue';
import CloseIcon from '@/components/ui/icons/CloseIcon.vue';
import TheModal from '@/components/ui/modal/TheModal/TheModal.vue';
import DefaultLoader from '@/components/ui/loaders/DefaultLoader.vue';
import TheForm, { AssignFilterForm } from '@/components/admin/dictionary/assign/filter/TheForm.vue';
import { AssignFilterParams } from '@/api/admin/pinning';

export default defineComponent({
  name: 'TheFilter',
  inject: ['$userAccess'],
  components: {
    CloseIcon,
    SearchIcon,
    IconButton,
    FilterIcon,
    TheModal,
    DefaultLoader,
    TheForm,
  },
  data() {
    return {
      isLoading: false,
      isOpenPopup: false,
      query: null,
      filters: {
        divisionId: undefined,
        departmentId: undefined,
        userId: undefined,
      },
    };
  },
  computed: {
    ...mapGetters({
      divisions: 'admin/dictionary/structure/division/items',
      departments: 'admin/dictionary/structure/list/items',
      managers: 'admin/dictionary/pinning/managers/list/items',
      user: 'user/localUserData',
    }),
    newFilter() {
      return {
        query: this.query,
        ...this.filters,
      };
    },
    isHaveFilters() {
      return _.values(this.filters).filter((value) => !!value).length > 0;
    },
  },
  watch: {
    async isOpenPopup(value: boolean) {
      if (value) {
        await this.loadDefaultData();
      }
    },
  },
  methods: {
    ...mapActions({
      changeFilterStructure: 'admin/dictionary/structure/list/changeFilter',
      loadDivisions: 'admin/dictionary/structure/division/loadItems',
      loadStructure: 'admin/dictionary/structure/list/loadItems',
      changeFilter: 'admin/dictionary/pinning/list/changeFilter',
      loadAssign: 'admin/dictionary/pinning/list/loadItems',
    }),
    searchInputHandler: _.debounce(async function handler(e: { target: HTMLInputElement }) {
      this.query = String(e.target.value);

      await this.changeFilter(this.newFilter);
      await this.loadAssign();
    }, 500),
    openFiltersHandler() {
      this.isOpenPopup = true;
    },
    closeFiltersHandler() {
      this.isOpenPopup = false;
    },
    async filtersChangedHandler(
      data: AssignFilterParams,
      lastData: AssignFilterParams,
    ) {
      if (data.divisionId !== lastData.divisionId) {
        await this.changeFilterStructure({
          parentId: data.divisionId,
        });
        await this.loadStructure();
      }
    },
    async submitFiltersHandler(data: AssignFilterParams) {
      this.filters = data;

      await this.changeFilter(this.newFilter);
      await this.loadAssign();

      this.closeFiltersHandler();
    },
    async approveFiltersHandler(next: () => void) {
      const formRef: AssignFilterForm = this.$refs.filters as AssignFilterForm;

      this.filters = formRef?.currentData ?? {};

      await this.changeFilter(this.newFilter);
      await this.loadAssign();

      this.closeFiltersHandler();

      next();
    },
    async clearFiltersHandler() {
      this.setDefaultFilters();

      await this.changeFilter(this.newFilter);
      await this.loadAssign();
    },
    setDefaultFilters() {
      this.query = null;

      this.filters = {
        divisionId: this.user?.division ?? undefined,
        departmentId: undefined,
        userId: undefined,
      };
    },
    async loadDefaultData() {
      this.isLoading = true;

      if (this.$userAccess.admin.isSuperAdmin || this.$userAccess.admin.isAdminCompany) {
        await this.loadDivisions({});
      }

      if (this.filters.division !== null) {
        await this.changeFilterStructure({
          parentId: this.filters.divisionId,
        });
        await this.loadStructure();
      }

      this.isLoading = false;
    },
  },
  created() {
    this.setDefaultFilters();
  },
});
