import {
  AxiosCallResponse, BaseService, LoggerInterface, PaginationParams, ResponseOfRecords,
} from '@/api/base';
import JsonData from '@/components/jsonforms/interfaces/JsonData';

export type ImplementationSchema = {
  id: string,
} & JsonData;

export type ImplementationListResponseType = AxiosCallResponse<
    ResponseOfRecords<ImplementationSchema[]>
>;

export default class ImplementationService extends BaseService {
  constructor(token: string, logger: LoggerInterface) {
    super('card', token, logger, 'v2');
  }

  async list(
    cardId: string,
    params: PaginationParams = {},
  ): Promise<ImplementationListResponseType> {
    return this.axiosCall({ url: `/${cardId}/implementation`, method: 'get', params });
  }

  async create(
    cardId: string,
    data: ImplementationSchema,
  ): Promise<AxiosCallResponse<ImplementationSchema>> {
    return this.axiosCall({ url: `/${cardId}/implementation`, method: 'post', data });
  }

  async update(
    cardId: string,
    id: string,
    data: ImplementationSchema,
  ): Promise<AxiosCallResponse<null>> {
    return this.axiosCall({
      url: `/${cardId}/implementation/${id}`,
      method: 'patch',
      data,
    });
  }

  async delete(
    cardId: string,
    id: string,
  ): Promise<AxiosCallResponse<null>> {
    return this.axiosCall({
      url: `/${cardId}/implementation/${id}`,
      method: 'delete',
    });
  }
}
