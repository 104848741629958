import config from '@/config';
import { DateTime } from 'luxon';

export default class DateHelper {
  static formattedDate(
    date: string,
    oldFormat = config.DATE_FORMAT_API,
    newFormat = config.DATE_FORMAT,
  ) {
    if (!date) {
      return '';
    }
    const data = DateTime.fromFormat(date, oldFormat);

    return data.toFormat(newFormat);
  }
}
