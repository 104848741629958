export default function useStringHelper() {
  return {
    getLetter(fullName: string): string {
      const reduce = (acc: string, str: string) => acc + str.slice(0, 1);
      const arrayFromFullName: string[] = fullName.split(' ');

      return arrayFromFullName.slice(0, 2)
        .reduce(reduce, '');
    },
  };
}
