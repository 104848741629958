import { defineComponent, PropType } from 'vue';
import { JsonSchema } from '@jsonforms/core';
import SearchInput from '@/components/ui/inputs/SearchInput/SearchInput.vue';
import DefaultButton from '@/components/ui/buttons/DefaultButton/DefaultButton.vue';
import { ErrorInterface } from '@/store/exception/ErrorInterface';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import LeftArrow from '@/components/ui/icons/LeftArrow.vue';
import DefaultLoader from '@/components/ui/loaders/DefaultLoader.vue';
import IconButton from '@/components/ui/buttons/IconButton/IconButton.vue';
import TheList from '@/components/counterparty/list/modal/create/content/tabs/search/list/TheList.vue';
import CreateForm, { CounterpartyCreateForm } from '@/components/counterparty/list/modal/create/content/form/TheForm.vue';

export default defineComponent({
  name: 'SearchForm',
  inject: ['$sizeWindow'],
  emits: ['submit', 'search', 'cancel', 'redirectToManually'],
  components: {
    SearchInput,
    DefaultButton,
    DefaultLoader,
    TheList,
    IconButton,
    CreateForm,
    LeftArrow,
  },
  props: {
    schema: {
      required: true,
      type: Object as PropType<JsonSchema>,
    },
  },
  data() {
    return {
      search: '',
      isLoading: false,
      disableSearchButton: true,
      searchErrorMessage: '',
      selectedItem: null,
      items: [],
    };
  },
  computed: {
    hasError() {
      return !!this.searchErrorMessage;
    },
    hasItems() {
      return !!this.items.length;
    },
    hasSelectedItem() {
      return !!this.selectedItem;
    },
  },
  watch: {
    selectedItem() {
      const sidebar = document.querySelector('.sidebar');

      sidebar?.scrollTo({ top: 0 });
    },
    hasError(value: boolean) {
      if (value) {
        this.focusToInput();
      }
    },
  },
  methods: {
    inputHandler(value: string) {
      this.search = value;
      this.disableSearchButton = !value;
      this.searchErrorMessage = '';
      this.items = [];
    },
    selectedHandler(key: number) {
      this.selectedItem = this.items[key] ?? null;
    },
    clearSelectedItem() {
      this.selectedItem = null;
    },
    cancelHandler() {
      this.$emit('cancel');
    },
    redirectToManuallyHandler() {
      this.$emit('redirectToManually');
    },
    searchHandler() {
      if (this.disableSearchButton) return;

      this.isLoading = true;

      this.$emit('search', this.search, (items: JsonData[]) => {
        this.disableSearchButton = true;
        this.items = items;

        this.isLoading = false;
      }, (error: ErrorInterface) => {
        this.searchErrorMessage = error.message;

        this.isLoading = false;
      });
    },
    submitHandler() {
      this.isLoading = true;

      const formRef: CounterpartyCreateForm = this.$refs.form as CounterpartyCreateForm;
      const valid: boolean = formRef?.validateForm() ?? false;
      const data: JsonData | null = formRef?.currentData ?? null;

      if (!valid || !data) {
        this.isLoading = false;

        return false;
      }

      this.$emit('submit', data, () => {
        this.isLoading = false;
      }, true);

      return true;
    },
    focusToInput() {
      this.$nextTick(function focusHandler() {
        const searchComponent = this.$refs?.search;

        searchComponent?.$refs?.input?.focus();
      });
    },
  },
  mounted() {
    this.focusToInput();
  },
});
