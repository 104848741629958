
import { JsonFormsChangeEvent } from '@jsonforms/vue';
import { ErrorObject } from 'ajv';
import { defineComponent, PropType } from 'vue';
import { JsonSchema } from '@jsonforms/core';
import { validateData } from '@/utils/helpers/AjvValidator';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import FormGenerator from '@/components/jsonforms/generators/FormGenerator.vue';
import CustomUISchemaElement from '@/components/jsonforms/interfaces/CustomUISchemaElement';
import { StructureItem } from '@/api/admin/dictionary/structure';
import useShowErrors from '@/composables/form/useShowErrors';
import structure from '@/shared/consts/dictionary/structure';

export interface StructureForm {
  data: JsonData | null,
  currentData: StructureItem,
  schema: JsonSchema,
  uischema: CustomUISchemaElement,
  errors: JsonData,
  validateForm: (showErrors?: boolean) => boolean,
  showErrors: () => void,
  onChange: () => void,
}

export default defineComponent({
  name: 'TheForm',
  inject: ['$userAccess'],
  components: {
    FormGenerator,
  },
  props: {
    data: {
      type: Object as PropType<JsonData>,
      default: () => ({}),
    },
    isView: {
      type: Boolean,
      default: false,
    },
    isCreate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      errors: [] as ErrorObject[],
      currentData: this.data,
    };
  },
  computed: {
    schema() {
      return {
        type: 'object',
        properties: {
          name: {
            type: 'string',
          },
          fullName: {
            type: ['string', 'null'],
          },
          isDepartment: {
            type: 'boolean',
          },
          isDivision: {
            type: 'boolean',
          },
          code: {
            type: 'string',
          },
          assignmentStrategy: {
            type: ['number', 'null'],
            oneOf: [
              { const: null, title: '' },
              { const: structure.ONE_ASSIGNMENT_TO_COUNTERPARTY_IN_DEPARTMENT, title: this.$t('dictionary.structure.assignmentStrategies.oneAssignment') },
              { const: structure.MANY_ASSIGNMENTS_TO_COUNTERPARTY_IN_DEPARTMENT, title: this.$t('dictionary.structure.assignmentStrategies.manyAssignments') },
            ],
          },
        },
        if: {
          properties: {
            isDivision: { const: true },
          },
          required: ['isDivision'],
        },
        then: {
          required: ['assignmentStrategy'],
        },
        required: ['code', 'name'],
      };
    },
    uischema() {
      return {
        type: 'VerticalLayout',
        elements: [
          {
            type: 'Control',
            label: this.$t('dictionary.structure.table.name'),
            scope: '#/properties/name',
          },
          {
            type: 'Control',
            label: this.$t('dictionary.structure.table.fullName'),
            scope: '#/properties/fullName',
          },
          {
            type: 'Control',
            label: this.$t('dictionary.structure.table.code'),
            scope: '#/properties/code',
          },
          {
            type: 'Control',
            label: this.$t('dictionary.structure.table.department'),
            scope: '#/properties/isDepartment',
          },
          {
            type: 'Control',
            label: this.$t('dictionary.structure.table.division'),
            scope: '#/properties/isDivision',
          },
          {
            type: 'Control',
            label: this.$t('dictionary.structure.table.assignmentStrategy'),
            scope: '#/properties/assignmentStrategy',
            rule: {
              effect: 'SHOW',
              condition: {
                scope: '#/properties/isDivision',
                schema: {
                  const: true,
                },
              },
            },
            options: {
              required: true,
            },
          },
        ],
      };
    },
    defaultOneOfList() {
      return [{ const: null, title: '' }];
    },
  },
  watch: {
    data() {
      this.currentData = this.data;
    },
  },
  methods: {
    onChange(event: JsonFormsChangeEvent) {
      this.currentData = event.data;
    },
    validateForm(showErrors = false): boolean {
      this.errors = validateData(this.currentData, this.schema, this.uischema);

      if (showErrors) {
        this.showErrors(this.errors);
      }

      return !this.errors || !this.errors.length;
    },
  },
  setup() {
    const { showErrors } = useShowErrors();

    return { showErrors };
  },
});
