
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { UserItem } from '@/api/admin/user';
import DictionaryGenerator from '@/components/admin/dictionary/generator/DictionaryGenerator.vue';
import { CompanyItem } from '@/api/admin/dictionary/company';
import { FilterParams, PaginationParams, SortParams } from '@/api/base';

export default defineComponent({
  name: 'TheList',
  inject: ['$userAccess'],
  components: {
    DictionaryGenerator,
  },
  data() {
    return {
      isLoading: false,
      fields: [
        { key: 'name', label: this.$t('dictionary.company.fields.name') },
        { key: 'action', notSortable: true, width: 52 },
      ],
      uischema: {
        type: 'VerticalLayout',
        elements: [
          {
            type: 'Control',
            label: this.$t('dictionary.company.fields.name'),
            scope: '#/properties/name',
          },
          {
            type: 'Control',
            label: this.$t('dictionary.company.fields.code'),
            scope: '#/properties/code',
          },
          {
            type: 'Control',
            label: this.$t('dictionary.company.fields.subdomain'),
            scope: '#/properties/subdomain',
          },
          {
            type: 'Control',
            label: this.$t('dictionary.company.fields.admins'),
            scope: '#/properties/admins',
            options: {
              multiple: true,
              search: true,
            },
          },
          {
            type: 'Control',
            label: this.$t('dictionary.company.fields.authorizationMethod'),
            scope: '#/properties/authorizationMethod',
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters({
      items: 'admin/dictionary/company/list/items',
      users: 'admin/user/list/items',
      filter: 'admin/dictionary/company/list/filter',
      sort: 'admin/dictionary/company/list/sort',
      pagination: 'admin/dictionary/company/list/pagination',
    }),
    schema() {
      return {
        type: 'object',
        properties: {
          name: {
            type: 'string',
          },
          code: {
            type: 'string',
          },
          subdomain: {
            type: ['string', 'null'],
          },
          admins: {
            type: ['array', 'string', 'null'],
            uniqueItems: true,
            items: {
              type: 'string',
              oneOf: this.preparedUsers(),
            },
          },
          authorizationMethod: {
            type: ['string', 'null'],
          },
        },
        required: [
          'name',
          'code',
        ],
      };
    },
    isCanEdit() {
      return this.$userAccess.admin.isSuperAdmin;
    },
  },
  methods: {
    ...mapActions({
      loadItems: 'admin/dictionary/company/list/loadItems',
      resetState: 'admin/dictionary/company/list/resetState',
      loadUsers: 'admin/user/list/loadItems',
      changeSort: 'admin/dictionary/company/list/changeSort',
      changeFilter: 'admin/dictionary/company/list/changeFilter',
      changePagination: 'admin/dictionary/company/list/changePagination',
      createItem: 'admin/dictionary/company/one/create',
      updateItem: 'admin/dictionary/company/one/update',
      deleteItem: 'admin/dictionary/company/one/delete',
      restoreItem: 'admin/dictionary/company/one/restore',
    }),
    preparedUsers() {
      return [
        { const: null, title: '' },
        ...this.users.map((direction: UserItem) => ({
          const: direction.id,
          title: direction.fullName,
        })),
      ];
    },
    async createHandler(data: CompanyItem, next: () => void) {
      await this.createItem(data);

      next();
    },
    async updateHandler(data: { id: string, data: CompanyItem }, next: () => void) {
      await this.updateItem(data);

      next();
    },
    async deleteHandler(id: string) {
      await this.deleteItem(id);
    },
    async restoreHandler(id: string) {
      await this.restoreItem(id);

      await this.loadItems();
    },
    async changePaginationHandler(pagination: PaginationParams) {
      await this.changePagination(pagination);

      await this.loadItems();
    },
    async changeSortHandler(sort: SortParams) {
      await this.changeSort(sort);

      await this.loadItems();
    },
    async changeFilterHandler(filter: FilterParams) {
      await this.changeFilter(filter);
      await this.loadItems();
    },
  },
  async created() {
    this.isLoading = true;

    await this.loadItems();
    await this.loadUsers();

    this.isLoading = false;
  },
  unmounted() {
    this.resetState();
  },
});
