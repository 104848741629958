import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Transition as _Transition, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-754125c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "modal-content h-full"
}
const _hoisted_2 = { class: "loader-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_add_icon = _resolveComponent("add-icon")!
  const _component_default_button = _resolveComponent("default-button")!
  const _component_default_loader = _resolveComponent("default-loader")!
  const _component_modal_content = _resolveComponent("modal-content")!
  const _component_the_assign = _resolveComponent("the-assign")!
  const _component_the_side_modal = _resolveComponent("the-side-modal")!
  const _component_the_danger_modal = _resolveComponent("the-danger-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_default_button, { onClick: _ctx.openModal }, {
      icon: _withCtx(() => [
        _createVNode(_component_add_icon, {
          width: "12",
          height: "12"
        })
      ]),
      default: _withCtx(() => [
        _createTextVNode(" " + _toDisplayString(_ctx.$t('buttons.add')), 1)
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createVNode(_component_the_side_modal, {
      "is-sidebar-with-footer": !_ctx.isLoading,
      "is-show-modal": _ctx.isOpen,
      "is-external-control": true,
      "check-filling": false,
      onCloseHandler: _ctx.closeModal
    }, {
      default: _withCtx(() => [
        (_ctx.isLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_default_loader)
              ])
            ]))
          : (_openBlock(), _createBlock(_Transition, {
              key: 1,
              name: !_ctx.counterpartyData ? 'slide' : 'slide-back'
            }, {
              default: _withCtx(() => [
                (!_ctx.counterpartyData)
                  ? (_openBlock(), _createBlock(_component_modal_content, {
                      key: 0,
                      schema: _ctx.generalBlockSchema,
                      onSubmit: _ctx.submitHandler,
                      onSearch: _ctx.searchHandler,
                      onCancel: _ctx.closeModal
                    }, null, 8, ["schema", "onSubmit", "onSearch", "onCancel"]))
                  : (_openBlock(), _createBlock(_component_the_assign, {
                      key: 1,
                      users: _ctx.users,
                      data: _ctx.assignData,
                      divisions: _ctx.divisions,
                      departments: _ctx.departments,
                      onUpdateData: _ctx.updateDataHandler,
                      onAjaxUsers: _ctx.ajaxUsersHandler,
                      onCancel: _ctx.closeModal,
                      onSubmit: _ctx.assignHandler
                    }, null, 8, ["users", "data", "divisions", "departments", "onUpdateData", "onAjaxUsers", "onCancel", "onSubmit"]))
              ]),
              _: 1
            }, 8, ["name"]))
      ]),
      _: 1
    }, 8, ["is-sidebar-with-footer", "is-show-modal", "onCloseHandler"]),
    _createVNode(_component_the_danger_modal, {
      "approve-button-text": _ctx.$t('counterparty.list.modal.create.dangerModal.confirmButton'),
      text: _ctx.$t('counterparty.list.modal.create.dangerModal.text'),
      "hint-text": _ctx.$t('counterparty.list.modal.create.dangerModal.hintText'),
      "is-open": _ctx.isOpenDangerModal,
      onApprovalHandler: _ctx.approveCloseHandler,
      onCloseModal: _ctx.closeDangerModal
    }, null, 8, ["approve-button-text", "text", "hint-text", "is-open", "onApprovalHandler", "onCloseModal"])
  ], 64))
}