import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]
const _hoisted_4 = ["width", "height"]
const _hoisted_5 = ["stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isSolid)
    ? (_openBlock(), _createElementBlock("svg", {
        key: 0,
        width: _ctx.width,
        height: _ctx.height,
        viewBox: "0 0 20 20",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }, [
        _createElementVNode("path", {
          d: "M2.00301 5.884L10 9.882L17.997 5.884C17.9674 5.37444 17.7441\n 4.89549 17.3728 4.54523C17.0016 4.19497 16.5104 3.99991 16\n 4H4.00001C3.48959 3.99991 2.99845 4.19497 2.62718 4.54523C2.25591\n 4.89549 2.03261 5.37444 2.00301 5.884Z",
          fill: _ctx.color
        }, null, 8, _hoisted_2),
        _createElementVNode("path", {
          d: "M18 8.11719L10 12.1172L2 8.11719V13.9992C2 14.5296 2.21071 15.0383\n 2.58579 15.4134C2.96086 15.7885 3.46957 15.9992 4 15.9992H16C16.5304\n 15.9992 17.0391 15.7885 17.4142 15.4134C17.7893 15.0383 18 14.5296\n 18 13.9992V8.11719Z",
          fill: _ctx.color
        }, null, 8, _hoisted_3)
      ], 8, _hoisted_1))
    : (_openBlock(), _createElementBlock("svg", {
        key: 1,
        width: _ctx.width,
        height: _ctx.height,
        viewBox: "0 0 20 16",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }, [
        _createElementVNode("path", {
          d: "M1 4L8.89 9.26C9.21866 9.47928 9.6049 9.5963 10 9.5963C10.3951\n 9.5963 10.7813 9.47928 11.11 9.26L19 4M3 15H17C17.5304 15 18.0391\n 14.7893 18.4142 14.4142C18.7893 14.0391 19 13.5304 19 13V3C19 2.46957\n 18.7893 1.96086 18.4142 1.58579C18.0391 1.21071 17.5304 1 17 1H3C2.46957\n 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3V13C1\n 13.5304 1.21071 14.0391 1.58579 14.4142C1.96086 14.7893 2.46957 15 3 15Z",
          stroke: _ctx.color,
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }, null, 8, _hoisted_5)
      ], 8, _hoisted_4))
}