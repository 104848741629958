import SyncErrorContent from "@/components/counterparty/card/sync-error/SyncErrorContent.vue";
import TheSideModal from "@/components/ui/modal/TheSideModal/TheSideModal.vue";
import { JsonSchema } from "@jsonforms/core";
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import config from "@/config";
import JsonData from "@/components/jsonforms/interfaces/JsonData";
import HeaderDefault from "@/components/counterparty/card/header/TheHeader.vue";
import TabsMenu from "@/components/counterparty/card/header/tabs/TabsMenu.vue";
import FormGenerator from "@/components/jsonforms/generators/FormGenerator.vue";
import DefaultLoader from "@/components/ui/loaders/DefaultLoader.vue";
import { TYPE_LABEL_WARNING } from "@/components/ui/notifications/TheAlert/TheAlert.vue";
import TheBlocks from "@/views/counterparty/card/blocks/TheBlocks.vue";
import CustomUISchemaElement from "@/components/jsonforms/interfaces/CustomUISchemaElement";
import AddressDefault from "@/components/ui/address/AddressDefault.vue";
import useShowErrors from "@/composables/form/useShowErrors";
import RedirectManager from "@/utils/helpers/RedirectManager";
import { BlockType, GroupType } from "@/api/card";
import { CounterpartyCardItem } from "@/api/counterparty";
import BadgeInWork from "@/components/widgets/badge-in-work/BadgeInWork.vue";
import groups from "@/shared/consts/groups";
import ManagerCounterparty from "@/components/counterparty/card/manager/ManagerCounterparty.vue";

interface CounterpartyCardData {
  isLoading: boolean;
  isBlockLoading: boolean;
  needConfirmation: boolean;
  currentEditBlock: null | string;
  blockIdList: string[];
  isOpenFieldError: boolean;
}

const initialState = (): CounterpartyCardData => ({
  isLoading: false,
  isBlockLoading: false,
  needConfirmation: false,
  currentEditBlock: null,
  isOpenFieldError: false,
  blockIdList: [
    config.COUNTERPARTY_AGENT_BLOCK,
    config.COUNTERPARTY_PAYMENT_BLOCK,
    config.COUNTERPARTY_COMMUNICATION_BLOCK,
    config.COUNTERPARTY_CONFLICT_HISTORY_BLOCK,
    config.COUNTERPARTY_SALES_SUPPORT_HISTORY_BLOCK,
    config.COUNTERPARTY_SEMINAR_HISTORY_BLOCK,
    config.COUNTERPARTY_KEY_PRODUCTION_ASSETS_BLOCK,
    config.COUNTERPARTY_IMPLEMENTATION_BLOCK,
    config.COUNTERPARTY_PRODUCTION_INDICATORS,
    config.COUNTERPARTY_PRESENCE_OF_BRANDS,
    config.COUNTERPARTY_CONSUMPTION_REGISTRY,
    config.COUNTERPARTY_DELIVERY_REGISTRY,
    config.COUNTERPARTY_RELATIONSHIP_BREAKS_BLOCK,
  ],
});

export default defineComponent({
  name: "CounterpartiesCard",
  inject: ["$notificationLogger", "$sizeWindow"],
  components: {
    ManagerCounterparty,
    BadgeInWork,
    SyncErrorContent,
    TheSideModal,
    TheBlocks,
    HeaderDefault,
    TabsMenu,
    FormGenerator,
    DefaultLoader,
    AddressDefault,
  },
  watch: {
    async counterpartyId() {
      this.reset();
      await this.init();
    },
  },
  data: initialState,
  computed: {
    ...mapGetters({
      cards: "counterparty/one/cards",
      schema: "schema/schema",
      data: "counterparty/card/data",
      groups: "counterparty/card/groups",
      blocks: "counterparty/card/blocks",
      currentCounterpartyId: "counterparty/card/currentCounterpartyId",
      currentGroupId: "counterparty/card/currentGroupId",
      currentCardId: "counterparty/card/currentCardId",
      fieldsErrors: "counterparty/card/fieldsErrors",
      user: "user/localUserData",
    }),
    counterpartyId() {
      return this.$route.params.id ?? "";
    },
    filteredBlocks(): BlockType[] {
      return this.blocks.filter((block: BlockType) => this.isVisibleBlock(block.visible));
    },
    blockSchemaList() {
      const result: { [key: string]: JsonSchema } = {};

      this.blockIdList.forEach((item: string) => {
        result[item] = this.getSchemaByBlock(item, this.schema);
      });

      return result;
    },
    mdmBlockSchema() {
      return this.getSchemaByBlock(config.COUNTERPARTY_MDM_DATA_BLOCK, this.schema);
    },
    mdmBlockUischema() {
      return this.getUischemaByBlock(config.COUNTERPARTY_MDM_DATA_BLOCK);
    },
    manager() {
      return this.data.manager ?? null;
    },
    isShowManagerCard() {
      return this.currentGroupId === groups.GENERAL_INFORMATION;
    },
    isShowErrorButton() {
      return this.fieldsErrors && this.fieldsErrors.length > 0;
    },
    currentCard(): CounterpartyCardItem {
      return this.cards.find((item: CounterpartyCardItem) => item.id === this.currentCardId);
    },
    isShowBadgeInWork() {
      return this.currentGroupId === groups.VOLUME_REGISTRY || this.currentGroupId === groups.NEWS;
    },
  },
  methods: {
    ...mapActions({
      loadCards: "counterparty/one/loadCards",
      changeCurrentCounterpartyId: "counterparty/card/changeCurrentCounterpartyId",
      changeCurrentGroupId: "counterparty/card/changeCurrentGroupId",
      changeCurrentCardId: "counterparty/card/changeCurrentCardId",
      updateCounterparty: "counterparty/card/updateCounterparty",
      updateMdmData: "counterparty/card/updateMdmData",
      resetCardState: "counterparty/card/resetState",
      loadHistory: "history/loadHistory",
      loadSyncFieldError: "counterparty/card/loadSyncFieldsErrors",
      loadDataAndGroupsByCardIdAndCounterpartyId: "counterparty/card/loadDataAndGroupsByCardIdAndCounterpartyId",
      deleteHistory: "history/deleteHistoryItem",
    }),
    async init() {
      this.isLoading = true;

      window.addEventListener("beforeunload", this.beforeUnload);

      await this.changeCurrentCounterpartyId(this.counterpartyId);
      await this.loadCards(this.counterpartyId);

      if (this.cards.length > 0) {
        console.log(this.user);
        console.log(this.cards);

        const selectedCard = this.cards.filter((item: CounterpartyCardItem) => item.division.id === this.user.division);
        if (selectedCard.length === 1) {
          await this.changeCurrentCardId(selectedCard[0].id);
        } else {
          await this.changeCurrentCardId(this.cards[0].id);
        }

        await this.loadDataAndGroupsByCardIdAndCounterpartyId({
          counterpartyId: this.currentCounterpartyId,
          cardId: this.currentCardId,
        });
        await this.loadSyncFieldError(this.currentCardId);
      }

      if (Object.keys(this.data).length <= 0 || this.cards.length <= 0) {
        await RedirectManager.redirect("/");
      } else {
        const groupName: string | null = this.$route.params.groupName ?? null;

        if (groupName) {
          const groupId = this.groups.find((item: GroupType) => item.shortName === groupName)?.id;

          await this.changeCurrentGroupId(groupId);
        }
      }

      this.isLoading = false;
    },
    reset() {
      window.removeEventListener("beforeunload", this.beforeUnload);

      this.resetCardState();
    },
    setInitialState() {
      Object.assign(this.$data, initialState());
    },
    scrollToBlock(block: string) {
      const element = document.querySelector(`[data-id="${block}"]`);

      element?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    },
    async changeCurrentGroupHandler(activeTab: string) {
      this.isBlockLoading = true;
      sessionStorage.currentGroup = activeTab;

      await this.changeCurrentGroupId(activeTab);

      this.setInitialState();
    },
    async editHandler(blockId: string, value: boolean, breakEdit: () => void) {
      const needConfirmation = this.currentEditBlock && this.currentEditBlock !== blockId;

      if (needConfirmation) {
        if (value) {
          const block = this.getUischemaByBlock(this.currentEditBlock);

          await this.$notificationLogger.setNotification(
            TYPE_LABEL_WARNING,
            this.$t("counterparty.card.block.finishEdit", { block: block.label ?? null })
          );

          this.scrollToBlock(this.currentEditBlock);

          breakEdit();
        }

        return;
      }

      this.currentEditBlock = value ? blockId : null;
    },
    async changeHandler(blockId: string, changedData: JsonData) {
      this.needConfirmation = this.currentEditBlock !== null && Object.keys(changedData).length > 0;
    },
    async updateCounterpartyHandler(block: string, data: JsonData, changedData: JsonData, next: () => void) {
      await this.updateCounterparty({ data, block });

      next();

      this.setInitialState();
    },
    async updateMdmHandler(data: JsonData, next: () => void) {
      await this.updateMdmData(data);

      next();
    },
    // eslint-disable-next-line consistent-return
    beforeUnload(event: BeforeUnloadEvent) {
      if (this.needConfirmation) {
        event.preventDefault();

        // eslint-disable-next-line no-param-reassign
        event.returnValue = this.$t("counterparty.refresh.dangerModal.text");

        return this.$t("counterparty.refresh.dangerModal.text");
      }
    },
    getSchemaByBlock(blockId: string, schema: JsonSchema): JsonSchema {
      return schema?.properties?.[blockId] ?? {};
    },
    getUischemaByBlock(blockId: string): CustomUISchemaElement {
      const block = this.blocks.find((item: BlockType) => item.id === blockId);

      return block?.schema ?? {};
    },
    isSchemaBlock: (type: string) => type === config.BLOCK_TYPE_SCHEMA,
    isVisibleBlock: (status: boolean | undefined) => status !== false,
    openFiledErrorHandler() {
      this.isOpenFieldError = true;
    },
    closeOpenFieldHandler() {
      this.isOpenFieldError = false;
    },
    async changeCardHandler(cardId: string) {
      this.isLoading = true;

      await this.changeCurrentCardId(cardId);
      await this.loadDataAndGroupsByCardIdAndCounterpartyId({
        counterpartyId: this.currentCounterpartyId,
        cardId: this.currentCardId,
      });
      await this.changeCurrentGroupId(groups.GENERAL_INFORMATION);
      await this.loadSyncFieldError(this.currentCardId);

      this.isLoading = false;
    },
  },
  async created() {
    await this.init();
  },
  unmounted() {
    this.reset();
  },
  setup() {
    const { showErrors } = useShowErrors();

    return { showErrors };
  },
});
