import { Module } from 'vuex';
import { StateType } from '@/store';
import list, { PresenceOfBrandsListStateType } from '@/store/modules/counterparty/block/strategy/presenceOfBrands/list';
import one, { PresenceOfBrandsOneStateType } from '@/store/modules/counterparty/block/strategy/presenceOfBrands/one';

export interface PresenceOfBrandsStateType {
  list: PresenceOfBrandsListStateType,
  one: PresenceOfBrandsOneStateType,
}

const presenceOfBrandsModule: Module<PresenceOfBrandsStateType, StateType> = {
  namespaced: true,
  modules: {
    list,
    one,
  },
};

export default presenceOfBrandsModule;
