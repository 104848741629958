import { Module } from 'vuex';
import ImplementationService, { ImplementationSchema } from '@/api/block/characteristic/implementation';
import { AxiosCallResponse } from '@/api/base';
import i18n from '@/i18n';
import { TYPE_LABEL_SUCCESS } from '@/components/ui/notifications/TheAlert/TheAlert.vue';
import { StateType } from '@/store';

export interface ImplementationOneStateType {
  data: ImplementationSchema | null,
}

const initialState = {
  data: null,
};

const keyProductionOneModule: Module<ImplementationOneStateType, StateType> = {
  namespaced: true,
  state: initialState,
  actions: {
    async create({ rootGetters }, params: {
      cardId: string,
      data: ImplementationSchema
    }) {
      if (params.cardId) {
        const api: ImplementationService = rootGetters.$api.blocks.implementation;

        const response: AxiosCallResponse<ImplementationSchema> = await api.create(
          params.cardId,
          params.data,
        );

        if (!response.error) {
          await rootGetters
            .$notificationLogger
            .setNotification(
              TYPE_LABEL_SUCCESS,
              i18n.global.t('notification.admin.dictionary.success.create'),
            );
        }
      }
    },
    async update({ rootGetters }, params: {
      cardId: string,
      id: string,
      data: ImplementationSchema,
    }) {
      if (params.cardId) {
        const api: ImplementationService = rootGetters.$api.blocks.implementation;
        const response: AxiosCallResponse<null> = await api.update(
          params.cardId,
          params.id,
          params.data,
        );

        if (!response.error) {
          await rootGetters
            .$notificationLogger
            .setNotification(
              TYPE_LABEL_SUCCESS,
              i18n.global.t('notification.admin.dictionary.success.update'),
            );
        }
      }
    },
    async delete({ rootGetters }, params: {
      cardId: string,
      id: string,
    }) {
      if (params.cardId) {
        const api: ImplementationService = rootGetters.$api.blocks.implementation;

        const response: AxiosCallResponse<null> = await api.delete(
          params.cardId,
          params.id,
        );

        if (!response.error) {
          await rootGetters
            .$notificationLogger
            .setNotification(
              TYPE_LABEL_SUCCESS,
              i18n.global.t('notification.admin.dictionary.success.delete'),
            );
        }
      }
    },
  },
};

export default keyProductionOneModule;
