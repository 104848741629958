
import { defineComponent } from 'vue';
import IconButton from '@/components/ui/buttons/IconButton/IconButton.vue';
import CloseIcon from '@/components/ui/icons/CloseIcon.vue';
import useBlockScroll from '@/composables/modal/useBlockScroll';
import { changeConfirmation, needConfirmation } from '@/composables/form/useCheckConfirmation';
import TheDangerModal from '@/components/ui/modal/TheDangerModal/TheDangerModal.vue';

export const SIZE_MD = 'md';
export const SIZE_LG = 'lg';
export const SIZE_XL = 'xl';

export default defineComponent({
  name: 'TheSideModal',
  emits: ['closeHandler'],
  components: {
    TheDangerModal,
    CloseIcon,
    IconButton,
  },
  props: {
    isShowModal: {
      type: Boolean,
      default: false,
    },
    isSidebarWithFooter: {
      type: Boolean,
      default: false,
    },
    isExternalControl: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: SIZE_MD,
    },
    checkFilling: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isOpen: this.isShowModal,
      isOpenDangerModal: false,
    };
  },
  watch: {
    isShowModal(newVal) {
      this.toggleScrollBlock(newVal);
      this.toggleListener(newVal);

      this.isOpen = newVal;
    },
  },
  computed: {
    classes() {
      return {
        'size-md': this.size === SIZE_MD,
        'size-lg': this.size === SIZE_LG,
        'size-xl': this.size === SIZE_XL,
        'sidebar-with-footer': this.isSidebarWithFooter,
      };
    },
  },
  methods: {
    closeModalHandler() {
      if (!this.isExternalControl) {
        this.isOpen = false;
      }

      changeConfirmation(false);
      this.$emit('closeHandler');
    },
    closeModal() {
      if (this.checkFilling && needConfirmation.value) {
        this.isOpenDangerModal = true;
      } else {
        this.closeModalHandler();
      }
    },
    toggleListener(value: boolean) {
      if (value) {
        document.addEventListener('keydown', this.clickEscHandler);
        changeConfirmation(false);
      } else {
        document.removeEventListener('keydown', this.clickEscHandler);
      }
    },
    closeDangerModal() {
      this.isOpenDangerModal = false;
    },
    approvalHandler(next: () => void) {
      this.closeModalHandler();

      this.isOpenDangerModal = false;
      next();
    },
    outsideClose(event: { target: HTMLDivElement }) {
      if (event.target.classList.contains('sidebar-background')) {
        this.closeModal();
      }
    },
    clickEscHandler(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        this.closeModal();
      }
    },
  },
  setup() {
    const { toggleClass: toggleScrollBlock } = useBlockScroll();

    return { toggleScrollBlock };
  },
});
