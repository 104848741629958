import {
  AxiosCallResponse,
  LoggerInterface,
  ResponseOfRecords,
} from '@/api/base';
import DictionaryBase from '@/api/admin/dictionary/base';

export interface CompanyItem {
  id?: string,
  name: string,
  code: string,
}

export type CompanyResponseType = AxiosCallResponse<ResponseOfRecords<CompanyItem[]>>;

export default class CompanyService extends DictionaryBase {
  constructor(token: string, logger: LoggerInterface = console) {
    super('company', token, logger);
  }
}
