import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-325074c9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "day-layout" }
const _hoisted_2 = { class: "navigation-menu" }
const _hoisted_3 = { class: "pagination-menu" }
const _hoisted_4 = { class: "days-of-the-week" }
const _hoisted_5 = { class: "calendar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_left_arrow = _resolveComponent("left-arrow")!
  const _component_right_arrow = _resolveComponent("right-arrow")!
  const _component_day_item = _resolveComponent("day-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "change-month__button",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openMonthLayout && _ctx.openMonthLayout(...args)))
      }, _toDisplayString(_ctx.month), 1),
      _createElementVNode("div", {
        class: "change-year__button",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openYearLayout && _ctx.openYearLayout(...args)))
      }, _toDisplayString(_ctx.year), 1),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: "prev__button",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.prevMonthHandler && _ctx.prevMonthHandler(...args)))
        }, [
          _createVNode(_component_left_arrow)
        ]),
        _createElementVNode("div", {
          class: "next__button",
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.nextMonthHandler && _ctx.nextMonthHandler(...args)))
        }, [
          _createVNode(_component_right_arrow)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.daysOfTheWeek, (day) => {
        return (_openBlock(), _createElementBlock("div", {
          key: day,
          class: "day-item"
        }, _toDisplayString(day), 1))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.offsetDay, (day) => {
        return (_openBlock(), _createBlock(_component_day_item, { key: day }))
      }), 128)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countDaysOfMonth, (day) => {
        return (_openBlock(), _createBlock(_component_day_item, {
          key: day,
          data: day,
          "is-select-day": _ctx.isSelectDay(day),
          "is-current-day": _ctx.isCurrentDay(day),
          onClick: ($event: any) => (_ctx.setDay(day))
        }, null, 8, ["data", "is-select-day", "is-current-day", "onClick"]))
      }), 128))
    ])
  ]))
}