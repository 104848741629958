
import { VueTelInput } from 'vue-tel-input';
import SelectArrow from '@/components/ui/icons/SelectArrow.vue';
import countries from '@/components/ui/inputs/PhoneInput/assets/countries';

export default {
  components: {
    VueTelInput,
    SelectArrow,
  },
  emits: ['update:modelValue', 'validate'],
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    defaultCountry: {
      type: String,
      default: 'ru',
    },
    preferredCountries: {
      type: Array,
      default: () => (['ru', 'kz', 'by']),
    },
    dropdownOptions: {
      type: Object,
      default: () => ({
        disabled: false,
        showSearchBox: true,
        showDialCodeInList: true,
        showDialCodeInSelection: true,
        showFlags: true,
        tabindex: 0,
        width: '100%',
      }),
    },
    inputOptions: {
      type: Object,
      default: () => ({
        id: '',
        placeholder: '',
        name: 'telephone',
        type: 'tel',
        styleClasses: '',
        'aria-describedby': '',
        autocomplete: 'on',
        autofocus: false,
        showDialCode: false,
        readonly: false,
        required: false,
        maxlength: 25,
        tabindex: 0,
      }),
    },
  },
  data() {
    return {
      countries,
      value: this.modelValue ?? '',
      isOpen: false,
    };
  },
  watch: {
    modelValue() {
      this.value = this.modelValue ?? '';
    },
  },
  methods: {
    toggleIsOpen() {
      this.isOpen = !this.isOpen;
    },
    validateHandler(object: object) {
      this.$emit('validate', object);
    },
    onInputHandler(value: string, object: object) {
      this.$emit('update:modelValue', value, object);
    },
  },
};
