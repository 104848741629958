import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-62b8fecd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-full h-[72px]" }
const _hoisted_2 = { class: "w-full h-full flex items-center justify-between lg:px-5" }
const _hoisted_3 = { class: "h-full flex items-center" }
const _hoisted_4 = { class: "hidden sm:block text-[12px] lg:text-[14px] text-gray" }
const _hoisted_5 = { class: "flex space-x-[4px] lg:space-x-[20px] ml-3 lg:ml-5" }
const _hoisted_6 = ["disabled"]
const _hoisted_7 = ["disabled"]
const _hoisted_8 = { class: "flex items-center text-[12px] lg:text-[14px] min-w-[45px]" }
const _hoisted_9 = ["disabled"]
const _hoisted_10 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_default = _resolveComponent("select-default")!
  const _component_table_pagination_over_back = _resolveComponent("table-pagination-over-back")!
  const _component_table_pagination_back = _resolveComponent("table-pagination-back")!
  const _component_table_pagination_next = _resolveComponent("table-pagination-next")!
  const _component_table_pagination_over_next = _resolveComponent("table-pagination-over-next")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('news.newsList')), 1),
        _createVNode(_component_select_default, {
          class: "sm:ml-3 lg:ml-5 h-[32px] w-[64px] custom-select",
          value: _ctx.perPage,
          data: _ctx.selectData,
          clearable: false,
          onValueSelect: _ctx.changePerPage
        }, null, 8, ["value", "data", "onValueSelect"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.firstPage && _ctx.firstPage(...args))),
          disabled: _ctx.prevButtonsDisabled
        }, [
          _createVNode(_component_table_pagination_over_back, {
            opacity: _ctx.getOpacity(_ctx.prevButtonsDisabled)
          }, null, 8, ["opacity"])
        ], 8, _hoisted_6),
        _createElementVNode("button", {
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.prevPage && _ctx.prevPage(...args))),
          disabled: _ctx.prevButtonsDisabled
        }, [
          _createVNode(_component_table_pagination_back, {
            opacity: _ctx.getOpacity(_ctx.prevButtonsDisabled)
          }, null, 8, ["opacity"])
        ], 8, _hoisted_7),
        _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.startNumber) + " - " + _toDisplayString(_ctx.endNumber) + " из " + _toDisplayString(_ctx.pagination.totalCount), 1),
        _createElementVNode("button", {
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.nextPage && _ctx.nextPage(...args))),
          disabled: _ctx.nextButtonsDisabled
        }, [
          _createVNode(_component_table_pagination_next, {
            opacity: _ctx.getOpacity(_ctx.nextButtonsDisabled)
          }, null, 8, ["opacity"])
        ], 8, _hoisted_9),
        _createElementVNode("button", {
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.lastPage && _ctx.lastPage(...args))),
          disabled: _ctx.nextButtonsDisabled
        }, [
          _createVNode(_component_table_pagination_over_next, {
            opacity: _ctx.getOpacity(_ctx.nextButtonsDisabled)
          }, null, 8, ["opacity"])
        ], 8, _hoisted_10)
      ])
    ])
  ]))
}