
import { defineComponent, PropType } from 'vue';
import { UserDetailData } from '@/api/admin/user';
import TheForm, { UserForm } from '@/components/admin/user/one/modal/content/form/TheForm.vue';
import DefaultInput from '@/components/ui/inputs/DefaultInput/DefaultInput.vue';
import { DivisionItem, StructureItem } from '@/api/admin/dictionary/structure';
import TreeHelper from '@/utils/helpers/TreeHelper';
import { CompanyItem } from '@/api/admin/dictionary/company';

export default defineComponent({
  name: 'EditForm',
  components: {
    DefaultInput,
    TheForm,
  },
  props: {
    user: {
      type: Object as PropType<UserDetailData>,
      required: true,
    },
    companies: {
      type: Array as PropType<CompanyItem[]>,
      default: () => [],
    },
    divisions: {
      type: Array as PropType<DivisionItem[]>,
      default: () => ([]),
    },
    departments: {
      type: Array as PropType<StructureItem[]>,
      default: () => ([]),
    },
  },
  computed: {
    defaultOneOfList() {
      return [{
        const: null,
        title: '',
      }];
    },
    defaultDepartmentList() {
      return [{
        const: null,
        title: '',
        properties: {
          parent: {
            const: '',
          },
        },
      }];
    },
    preparedCompanies() {
      return [
        ...this.companies.map((company: CompanyItem) => ({
          const: company.id,
          title: company.name,
        })),
      ];
    },
    preparedDivisions() {
      if (this.divisions.length > 0) {
        return [
          ...this.defaultOneOfList,
          ...this.divisions.map((division: DivisionItem) => ({
            const: division.id,
            title: division.name,
          })),
        ];
      }
      return this.defaultOneOfList;
    },
    preparedDepartments() {
      if (this.departments.length > 0) {
        return TreeHelper.prepareStructure(this.departments);
      }

      return this.defaultDepartmentList;
    },
    formRef(): UserForm {
      return this.$refs.form as UserForm;
    },
    currentData() {
      return this.formRef?.currentData ?? null;
    },
  },
  methods: {
    validateForm(): boolean {
      return this.formRef?.validateForm() ?? false;
    },
  },
});
