import BankService from '@/api/admin/dictionary/bank';
import BankAccountService from '@/api/admin/dictionary/bankAccount';
import CategoryService from '@/api/admin/dictionary/category';
import CompanyService from '@/api/admin/dictionary/company';
import CounterpartyTypeService from '@/api/admin/dictionary/counterpartyType';
import IndustryService from '@/api/admin/dictionary/industry';
import NomenclatureGroupService from '@/api/admin/dictionary/nomenclatureGroup';
import RegionService from '@/api/admin/dictionary/region';
import PinningService from '@/api/admin/pinning';
import StructureService from '@/api/admin/dictionary/structure';
import CountryService from '@/api/admin/dictionary/country';
import ImplementationService from '@/api/block/characteristic/implementation';
import KeyProductionAssetsService from '@/api/block/general/keyProductionAssets';
import PsychologicalCharacteristicService from '@/api/admin/dictionary/psychologicalCharacteristic';
import BlockHistoryService from '@/api/block/history';
import CounterpartyService from '@/api/counterparty';
import CommunicationService from '@/api/block/relationship/communication';
import RelationshipBreakService from '@/api/block/relationship/relationshipBreak';
import ConflictHistoryService from '@/api/block/relationship/conflictHistory';
import SalesSupportHistoryService from '@/api/block/relationship/salesSupportHistory';
import SeminarHistoryService from '@/api/block/relationship/seminarHistory';
import NewsService from '@/api/news';
import PaymentService from '@/api/payment';
import SchemaService from '@/api/schema';
import UserService from '@/api/admin/user';
import RoleService from '@/api/admin/role';
import MdmService from '@/api/mdm';
import AgentService from '@/api/agent';
import { LoggerInterface } from '@/api/base';
import TechnologicalSupportTypeService from '@/api/admin/dictionary/typesTechnologicalSupport';
import ActivityDirectionService from '@/api/admin/dictionary/activityDirection';
import PositionService from '@/api/admin/dictionary/position';
import EducationService from '@/api/admin/dictionary/education';
import ProductionIndicatorsService from '@/api/block/characteristic/productionIndicators';
import PresenceOfBrandsService from '@/api/block/strategy/presenceOfBrands';
import IndividualService from '@/api/admin/dictionary/individual';
import ConsumptionRegistryService from '@/api/block/registry/consumptionRegistry';
import DeliveryRegistryService from '@/api/block/registry/deliveryRegistry';
import NomenclatureService from '@/api/admin/dictionary/nomenclature';
import CardService from '@/api/card';
import AssignHistoryService from '@/api/block/assignHistory';

export interface ApiServices {
  counterparty: CounterpartyService,
  card: CardService,
  relationship: {
    communication: CommunicationService,
    conflictHistory: ConflictHistoryService,
    salesSupportHistory: SalesSupportHistoryService,
    seminarHistory: SeminarHistoryService,
    relationshipBreak: RelationshipBreakService,
  },
  schema: SchemaService,
  payment: PaymentService,
  news: NewsService,
  blocks: {
    keyProductionAssets: KeyProductionAssetsService,
    implementation: ImplementationService,
    productionIndicators: ProductionIndicatorsService,
    presenceOfBrands: PresenceOfBrandsService,
    consumptionRegistry: ConsumptionRegistryService,
    deliveryRegistry: DeliveryRegistryService,
    assignHistory: AssignHistoryService,
  },
  history: BlockHistoryService,
  mdm: MdmService,
  agent: AgentService,
  admin: {
    user: UserService,
    role: RoleService,
    pinning: PinningService,
    dictionary: {
      country: CountryService,
      structure: StructureService,
      company: CompanyService,
      category: CategoryService,
      bank: BankService,
      industry: IndustryService,
      psychologicalCharacteristic: PsychologicalCharacteristicService,
      nomenclatureGroup: NomenclatureGroupService,
      bankAccount: BankAccountService,
      counterpartyType: CounterpartyTypeService,
      technologicalSupportType: TechnologicalSupportTypeService,
      position: PositionService,
      activityDirection: ActivityDirectionService,
      education: EducationService,
      individual: IndividualService,
      region: RegionService,
      nomenclature: NomenclatureService,
    },
  },
}

export default (token: string, logger: LoggerInterface = console): ApiServices => ({
  counterparty: new CounterpartyService(token, logger),
  card: new CardService(token, logger),
  relationship: {
    communication: new CommunicationService(token, logger),
    conflictHistory: new ConflictHistoryService(token, logger),
    salesSupportHistory: new SalesSupportHistoryService(token, logger),
    seminarHistory: new SeminarHistoryService(token, logger),
    relationshipBreak: new RelationshipBreakService(token, logger),
  },
  schema: new SchemaService(token, logger),
  payment: new PaymentService(token, logger),
  news: new NewsService(token, logger),
  blocks: {
    keyProductionAssets: new KeyProductionAssetsService(token, logger),
    implementation: new ImplementationService(token, logger),
    productionIndicators: new ProductionIndicatorsService(token, logger),
    presenceOfBrands: new PresenceOfBrandsService(token, logger),
    consumptionRegistry: new ConsumptionRegistryService(token, logger),
    deliveryRegistry: new DeliveryRegistryService(token, logger),
    assignHistory: new AssignHistoryService(token, logger),
  },
  history: new BlockHistoryService(token, logger),
  mdm: new MdmService(token, logger),
  agent: new AgentService(token, logger),
  admin: {
    user: new UserService(token, logger),
    role: new RoleService(token, logger),
    pinning: new PinningService(token, logger),
    dictionary: {
      country: new CountryService(token, logger),
      structure: new StructureService(token, logger),
      company: new CompanyService(token, logger),
      category: new CategoryService(token, logger),
      bank: new BankService(token, logger),
      industry: new IndustryService(token, logger),
      bankAccount: new BankAccountService(token, logger),
      psychologicalCharacteristic: new PsychologicalCharacteristicService(token, logger),
      nomenclatureGroup: new NomenclatureGroupService(token, logger),
      counterpartyType: new CounterpartyTypeService(token, logger),
      technologicalSupportType: new TechnologicalSupportTypeService(token, logger),
      activityDirection: new ActivityDirectionService(token, logger),
      position: new PositionService(token, logger),
      education: new EducationService(token, logger),
      individual: new IndividualService(token, logger),
      region: new RegionService(token, logger),
      nomenclature: new NomenclatureService(token, logger),
    },
  },
});
