import { DirectiveBinding } from 'vue';

export interface OutsideClickElement extends HTMLElement {
  clickOutsideEvent: (event: Event) => void,
}

export default {
  mounted(el: OutsideClickElement, binding: DirectiveBinding) {
    // eslint-disable-next-line no-param-reassign
    el.clickOutsideEvent = function clickOutsideEvent(event: Event) {
      if (!(el === event.target || el.contains(event.target as HTMLElement))) {
        binding.value(event, el);
      }
    };

    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unmounted(el: OutsideClickElement) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
};
