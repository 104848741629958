import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "notification-badge"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bell_icon = _resolveComponent("bell-icon")!
  const _component_icon_button = _resolveComponent("icon-button")!
  const _component_notification_center_component = _resolveComponent("notification-center-component")!

  return (_openBlock(), _createBlock(_component_notification_center_component, {
    "subscriber-id": _ctx.userId,
    "application-identifier": _ctx.appNovuId,
    "backend-url": _ctx.apiNovuUrl,
    "socket-url": _ctx.wsNovuUrl,
    "notification-clicked": _ctx.onNotificationClick,
    "color-scheme": "light",
    i18n: "ru"
  }, {
    default: _withCtx(({unseenCount}) => [
      _createVNode(_component_icon_button, { class: "relative" }, {
        default: _withCtx(() => [
          _createVNode(_component_bell_icon),
          unseenCount
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(unseenCount), 1))
            : _createCommentVNode("", true)
        ]),
        _: 2
      }, 1024)
    ]),
    _: 1
  }, 8, ["subscriber-id", "application-identifier", "backend-url", "socket-url", "notification-clicked"]))
}