
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import TableBlockTemplate
  from '@/components/counterparty/card/blocks/table-block-template/TableBlockTemplate.vue';
import { FilterParams, PaginationParams, SortParams } from '@/api/base';
import { AssignHistoryType } from '@/api/block/assignHistory';

export default defineComponent({
  name: 'AssignHistory',
  components: { TableBlockTemplate },
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'assignmentDate',
          format: 'date',
          label: this.$t('counterparty.tabs.general.assignHistory.fields.assignmentDate'),
        },
        {
          key: 'detachmentDate',
          format: 'date',
          label: this.$t('counterparty.tabs.general.assignHistory.fields.detachmentDate'),
        },
        {
          key: 'user',
          label: this.$t('counterparty.tabs.general.assignHistory.fields.user'),
          notSortable: true,
        },
      ],
      schema: {
        type: 'object',
        properties: {
          assignmentDate: {
            type: ['string', 'null'],
          },
          detachmentDate: {
            type: ['string', 'null'],
          },
          user: {
            type: ['string', 'null'],
          },
          department: {
            type: ['string', 'null'],
          },
          reason: {
            type: ['string', 'null'],
          },
        },
      },
      uischema: {
        type: 'VerticalLayout',
        elements: [
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: this.$t('counterparty.tabs.general.assignHistory.fields.assignmentDate'),
                scope: '#/properties/assignmentDate',
                options: {
                  format: 'date',
                },
              },
              {
                type: 'Control',
                label: this.$t('counterparty.tabs.general.assignHistory.fields.detachmentDate'),
                scope: '#/properties/detachmentDate',
                options: {
                  format: 'date',
                },
              },
            ],
          },
          {
            type: 'Control',
            label: this.$t('counterparty.tabs.general.assignHistory.fields.user'),
            scope: '#/properties/user',
          },
          {
            type: 'Control',
            label: this.$t('counterparty.tabs.general.assignHistory.fields.department'),
            scope: '#/properties/department',
          },
          {
            type: 'Control',
            label: this.$t('counterparty.tabs.general.assignHistory.fields.reason'),
            scope: '#/properties/reason',
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters({
      items: 'counterparty/block/assignHistory/list/items',
      filter: 'counterparty/block/assignHistory/list/filter',
      sort: 'counterparty/block/assignHistory/list/sort',
      pagination: 'counterparty/block/assignHistory/list/pagination',
      currentCardId: 'counterparty/card/currentCardId',
    }),
    preparedFormItems() {
      const items: AssignHistoryType[] = this.items ?? [];

      return items.map((item: AssignHistoryType) => ({
        ...item,
        user: item.user?.name,
        department: item.department?.name,
      }));
    },
    preparedItems() {
      const items: AssignHistoryType[] = this.items ?? [];

      return items.map((item: AssignHistoryType) => ({
        id: item.id,
        assignmentDate: item.assignmentDate,
        detachmentDate: item.detachmentDate,
        user: item.user.name,
      }));
    },
  },
  methods: {
    ...mapActions({
      loadItems: 'counterparty/block/assignHistory/list/loadItems',
      changeFilter: 'counterparty/block/assignHistory/list/changeFilter',
      changeSort: 'counterparty/block/assignHistory/list/changeSort',
      changePagination: 'counterparty/block/assignHistory/list/changePagination',
    }),
    async changePaginationHandler(pagination: PaginationParams) {
      await this.changePagination(pagination);

      await this.loadItems(this.currentCardId);
    },
    async changeSortHandler(sort: SortParams) {
      await this.changeSort(sort);

      await this.loadItems(this.currentCardId);
    },
    async filterChangedHandler(filter: FilterParams) {
      await this.changeFilter(filter);

      await this.loadItems(this.currentCardId);
    },
  },
  async created() {
    await this.loadItems(this.currentCardId);
  },
});
