import { resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fc3ebf66"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "loader-block mt-[32px]"
}
const _hoisted_3 = {
  key: 1,
  class: "edit-block"
}
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "text" }
const _hoisted_6 = { class: "modal-footer" }
const _hoisted_7 = { class: "text-[14px]" }
const _hoisted_8 = { class: "text-[14px]" }
const _hoisted_9 = { class: "text-[14px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search_input = _resolveComponent("search-input")!
  const _component_default_loader = _resolveComponent("default-loader")!
  const _component_the_list = _resolveComponent("the-list")!
  const _component_left_arrow = _resolveComponent("left-arrow")!
  const _component_icon_button = _resolveComponent("icon-button")!
  const _component_create_form = _resolveComponent("create-form")!
  const _component_default_button = _resolveComponent("default-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_Transition, {
      name: _ctx.hasSelectedItem ? 'slide' : 'slide-back'
    }, {
      default: _withCtx(() => [
        (!_ctx.hasSelectedItem)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_search_input, {
                ref: "search",
                type: "text",
                debounce: 0,
                value: _ctx.search,
                "clear-button": false,
                disabled: _ctx.isLoading,
                label: _ctx.$t('counterparty.create.form.search.input'),
                placeholder: _ctx.$t('counterparty.create.form.search.placeholder.input'),
                onInput: _ctx.inputHandler,
                onKeyup: _withKeys(_ctx.searchHandler, ["enter"])
              }, null, 8, ["value", "disabled", "label", "placeholder", "onInput", "onKeyup"]),
              (_ctx.isLoading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_default_loader)
                  ]))
                : (_ctx.hasItems || _ctx.hasError)
                  ? (_openBlock(), _createBlock(_component_the_list, {
                      key: 1,
                      class: "mt-[32px]",
                      items: _ctx.items,
                      "search-text": _ctx.search,
                      onSelected: _ctx.selectedHandler,
                      onRedirectToManually: _ctx.redirectToManuallyHandler
                    }, null, 8, ["items", "search-text", "onSelected", "onRedirectToManually"]))
                  : _createCommentVNode("", true)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_icon_button, { onClick: _ctx.clearSelectedItem }, {
                  default: _withCtx(() => [
                    _createVNode(_component_left_arrow)
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('counterparty.create.form.search.edit')), 1)
              ]),
              _createVNode(_component_create_form, {
                ref: "form",
                schema: _ctx.schema,
                data: _ctx.selectedItem
              }, null, 8, ["schema", "data"])
            ]))
      ]),
      _: 1
    }, 8, ["name"]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_default_button, {
        height: "40",
        onClick: _ctx.cancelHandler
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('buttons.cancel')), 1)
        ]),
        _: 1
      }, 8, ["onClick"]),
      (!_ctx.hasSelectedItem)
        ? (_openBlock(), _createBlock(_component_default_button, {
            key: 0,
            type: "primary",
            height: "40",
            disabled: _ctx.isLoading || _ctx.disableSearchButton,
            onClick: _ctx.searchHandler
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t(`counterparty.create.buttons.${_ctx.$sizeWindow.isSm ? 'mobile.' : ''}search`)), 1)
            ]),
            _: 1
          }, 8, ["disabled", "onClick"]))
        : (_openBlock(), _createBlock(_component_default_button, {
            key: 1,
            type: "primary",
            height: "40",
            disabled: _ctx.isLoading,
            onClick: _ctx.submitHandler
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t(`counterparty.create.buttons.${_ctx.$sizeWindow.isSm ? 'mobile.' : ''}next`)), 1)
            ]),
            _: 1
          }, 8, ["disabled", "onClick"]))
    ])
  ]))
}