import { Module } from 'vuex';
import { StateType } from '@/store';
import list, { CategoryCounterpartyListStateType } from '@/store/modules/admin/dictionary/category/list';
import one, { CategoryCounterpartyOneStateType } from '@/store/modules/admin/dictionary/category/one';

export interface CategoryCounterpartyStateType {
  list: CategoryCounterpartyListStateType,
  one: CategoryCounterpartyOneStateType,
}

const categoryModule: Module<CategoryCounterpartyStateType, StateType> = {
  namespaced: true,
  modules: {
    list,
    one,
  },
};

export default categoryModule;
