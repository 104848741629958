import { Module } from 'vuex';
import {
  AxiosCallResponse,
  FilterParams,
  MetaParams,
  QueryParamsForList,
  SortParams,
} from '@/api/base';
import AssignHistoryService, { AssignHistoryResponseType, AssignHistoryType } from '@/api/block/assignHistory';
import { StateType } from '@/store';

export interface AssignHistoryListStateType {
  items: AssignHistoryType[],
  pagination: MetaParams,
  filter: FilterParams,
  sort: SortParams,
}

const initialState = {
  items: [],
  pagination: {
    currentPage: null,
    pageCount: null,
    perPage: 5,
    totalCount: null,
  },
  filter: {
    query: null,
  },
  sort: {
    sortBy: null,
  },
};

const assignHistoryListModule: Module<AssignHistoryListStateType, StateType> = {
  namespaced: true,
  state: initialState,
  getters: {
    pagination: (state): MetaParams => state.pagination,
    filter: (state): FilterParams => state.filter,
    sort: (state): SortParams => state.sort,
    items(state): AssignHistoryType[] {
      return state.items;
    },
    loadParams: (state): QueryParamsForList => ({
      page: state.pagination.currentPage,
      perPage: state.pagination.perPage,
      query: state.filter.query,
      sortBy: state.sort.sortBy,
    }),
  },
  mutations: {
    setItems(state, items) {
      state.items = items;
    },
    setPagination(state, pagination) {
      state.pagination = pagination;
    },
    setFilter(state, filter) {
      state.filter = filter;
    },
    setSort(state, sort) {
      state.sort = sort;
    },
  },
  actions: {
    async changePagination({ commit }, pagination: MetaParams) {
      commit('setPagination', pagination);
    },
    async changeFilter({ commit, state }, filter: FilterParams) {
      commit('setFilter', filter);
      commit('setPagination', { ...state.pagination, currentPage: 1 });
    },
    async changeSort({ dispatch, commit }, sort: SortParams) {
      commit('setSort', sort);

      await dispatch('loadItems');
    },
    async loadItems({ getters, rootGetters, commit }, cardId: string) {
      if (cardId) {
        const api: AssignHistoryService = rootGetters.$api.blocks.assignHistory;
        const { loadParams } = getters;

        const response: AxiosCallResponse<AssignHistoryResponseType> = await api.list(
          cardId,
          loadParams,
        );

        commit('setItems', response.data?.items ?? initialState.items);
        commit('setPagination', response.data?._meta ?? initialState.pagination);
      }
    },
  },
};

export default assignHistoryListModule;
