import { Module } from 'vuex';
import { AxiosCallResponse, MetaParams, SortParams } from '@/api/base';
import NewsService, {
  FilterParams,
  GetNewsParams,
  NewsAddType,
  NewsDataType,
  NewsItemType,
} from '@/api/news';
import { StateType } from '@/store';
import i18n from '@/i18n';
import { TYPE_LABEL_SUCCESS } from '@/components/ui/notifications/TheAlert/TheAlert.vue';

export interface NewsStateType {
  news: NewsItemType[],
  pagination: MetaParams,
  filter: FilterParams,
  sort: SortParams,
}

const initialState = {
  news: [],
  pagination: {
    currentPage: null,
    pageCount: null,
    perPage: null,
    totalCount: null,
  },
  filter: {
    beforeDate: null,
    afterDate: null,
  },
  sort: {
    sortBy: null,
  },
};

const newsModule: Module<NewsStateType, StateType> = {
  namespaced: true,
  state: initialState,
  getters: {
    pagination: (state): MetaParams => state.pagination,
    filter: (state): FilterParams => state.filter,
    sort: (state): SortParams => state.sort,
    news: (state): NewsItemType[] => state.news,
    loadNewsParams: (state): GetNewsParams => ({
      page: state.pagination.currentPage,
      perPage: state.pagination.perPage,
      beforeDate: state.filter.beforeDate,
      afterDate: state.filter.afterDate,
      sortBy: state.sort.sortBy,
    }),
  },
  mutations: {
    setPagination(state, pagination) {
      state.pagination = pagination;
    },
    setNews(state, news) {
      state.news = news;
    },
    setFilter(state, filter) {
      state.filter = filter;
    },
    setSort(state, sort) {
      state.sort = sort;
    },
  },
  actions: {
    async changePagination({ dispatch, commit }, pagination: MetaParams) {
      commit('setPagination', pagination);

      await dispatch('loadNews');
    },
    async changeFilter({ dispatch, commit, state }, filter: FilterParams) {
      commit('setFilter', filter);
      commit('setPagination', { ...state.pagination, currentPage: 1 });

      await dispatch('loadNews');
    },
    async changeSort({ dispatch, commit }, sort: SortParams) {
      commit('setSort', sort);

      await dispatch('loadNews');
    },
    async addNews({ rootGetters, dispatch }, data: NewsAddType) {
      const newsApi: NewsService = rootGetters.$api.news;
      const response = await newsApi.addNews(data);

      if (!response.error) {
        await rootGetters.$notificationLogger.setNotification(TYPE_LABEL_SUCCESS, i18n.global.t('notification.news.success.add'));
      }

      await dispatch('loadNews');
    },
    async updateNews({ rootGetters, dispatch }, data: NewsDataType) {
      const newsApi: NewsService = rootGetters.$api.news;
      const response = await newsApi.updateNews(data);

      if (!response.error) {
        await rootGetters.$notificationLogger.setNotification(TYPE_LABEL_SUCCESS, i18n.global.t('notification.news.success.edit'));
      }

      await dispatch('loadNews');
    },
    async deleteNews({ rootGetters, dispatch }, id: number) {
      const newsApi: NewsService = rootGetters.$api.news;
      const response = await newsApi.deleteNews(id);

      if (!response.error) {
        await rootGetters.$notificationLogger.setNotification(TYPE_LABEL_SUCCESS, i18n.global.t('notification.news.success.delete'));
      }

      await dispatch('loadNews');
    },
    async loadNews({ getters, rootGetters, commit }) {
      const newsApi: NewsService = rootGetters.$api.news;
      const loadParams: GetNewsParams = getters.loadNewsParams;
      const response: AxiosCallResponse<NewsItemType> = await newsApi.getNews(loadParams);

      commit('setNews', response.data?.items ?? initialState.news);
      commit('setPagination', response.data?._meta ?? initialState.pagination);
    },
  },
};

export default newsModule;
