
import { defineComponent, PropType } from 'vue';

export type BadgeVariant = 'default' | 'success';

export type BadgeSize = 'sm' | 'md' | 'lg' | 'xl';

export default defineComponent({
  name: 'TheBadge',
  props: {
    variant: {
      type: String as PropType<BadgeVariant>,
      default: 'default',
    },
    size: {
      type: String as PropType<BadgeSize>,
      default: 'sm',
    },
  },
  computed: {
    classes() {
      return `variant-${this.variant} size-${this.size}`;
    },
  },
});
