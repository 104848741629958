import DictionaryBase, { DictionaryListResponseType } from '@/api/admin/dictionary/base';
import { LoggerInterface } from '@/api/base';

export interface EducationSchema {
  id: string,
  name: string,
}

export interface EducationData {
  name: string,
}

export type EducationResponseType
  = DictionaryListResponseType<EducationSchema>;

export default class EducationService extends DictionaryBase {
  constructor(token: string, logger: LoggerInterface = console) {
    super('education', token, logger);
  }
}
