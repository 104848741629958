import { AxiosError } from 'axios';
import { ErrorResponse, LoggerInterface } from '@/api/base';

export default async function errorMiddleware(
  error: AxiosError<ErrorResponse | null>,
  logger: LoggerInterface = console,
  needLogging = true,
) {
  if (needLogging) {
    const message: string = error.response?.data?.message ?? error.message;
    const status: number = error.response?.status ?? 500;

    await logger.log(status, message);
  }

  throw error;
}
