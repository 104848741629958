import _ from 'lodash';
import { Module } from 'vuex';
import StructureService, {
  StructureItem,
} from '@/api/admin/dictionary/structure';
import {
  AxiosCallResponse,
} from '@/api/base';
import { StateType } from '@/store';
import { TYPE_LABEL_SUCCESS } from '@/components/ui/notifications/TheAlert/TheAlert.vue';
import i18n from '@/i18n';

export interface StructureOneStateType {
  data: StructureItem | null,
}

const initialState = {
  data: null,
};

const structureOneModule: Module<StructureOneStateType, StateType> = {
  namespaced: true,
  state: _.cloneDeep(initialState),
  getters: {
    data: (state) => state.data,
  },
  mutations: {
    setData(state, items: StructureItem) {
      state.data = items;
    },
  },
  actions: {
    async create({ rootGetters, dispatch }, data: StructureItem) {
      const api: StructureService = rootGetters.$api.admin.dictionary.structure;

      const response: AxiosCallResponse<StructureItem> = await api.create(data);

      if (!response.error) {
        await rootGetters
          .$notificationLogger
          .setNotification(
            TYPE_LABEL_SUCCESS,
            i18n.global.t('notification.admin.dictionary.success.create'),
          );

        await dispatch('admin/dictionary/structure/list/loadItems', null, { root: true });
      }
    },
    async update({ rootGetters, dispatch }, params: { id: string, data: StructureItem }) {
      const api: StructureService = rootGetters.$api.admin.dictionary.structure;

      const response: AxiosCallResponse<null> = await api.update(params.id, params.data);

      if (!response.error) {
        await rootGetters
          .$notificationLogger
          .setNotification(
            TYPE_LABEL_SUCCESS,
            i18n.global.t('notification.admin.dictionary.success.update'),
          );

        await dispatch('admin/dictionary/structure/list/loadItems', null, { root: true });
      }
    },
    async delete({ rootGetters, dispatch }, id: string) {
      const api: StructureService = rootGetters.$api.admin.dictionary.structure;

      const response: AxiosCallResponse<null> = await api.delete(id);

      if (!response.error) {
        await rootGetters
          .$notificationLogger
          .setNotification(
            TYPE_LABEL_SUCCESS,
            i18n.global.t('notification.admin.dictionary.success.delete'),
          );

        await dispatch('admin/dictionary/structure/list/loadItems', null, { root: true });
      }
    },
  },
};

export default structureOneModule;
