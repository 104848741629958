
import { defineComponent, ref } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import DefaultLoader from '@/components/ui/loaders/DefaultLoader.vue';
import TheTitle from '@/components/admin/dictionary/assign/title/TheTitle.vue';
import TheTable from '@/components/ui/tables/simple/TheTable.vue';
import { TableItem } from '@/components/ui/tables/simple/TheTable';
import TheSideModal from '@/components/ui/modal/TheSideModal/TheSideModal.vue';
import ContextMenu from '@/components/ui/menu/ContextMenu/ContextMenu.vue';
import useCheckPermissions from '@/composables/useCheckPermissions';
import TheCreate from '@/components/admin/dictionary/assign/forms/create/TheCreate.vue';
import { StructureItem } from '@/api/admin/dictionary/structure';
import { CounterpartyItemType } from '@/api/counterparty';
import { CounterpartyTypeItem } from '@/api/admin/dictionary/counterpartyType';
import { UserItem } from '@/api/admin/user';
import { PinningDetachItem, PinningItem, PinningReassignItem } from '@/api/admin/pinning';
import TheView from '@/components/admin/dictionary/assign/forms/view/TheView.vue';
import TheDetach from '@/components/admin/dictionary/assign/forms/detach/TheDetach.vue';
import TheReassign from '@/components/admin/dictionary/assign/forms/reassign/TheReassign.vue';
import fields from '@/shared/consts/counterparty/fields';
import ReplyIcon from '@/components/ui/icons/ReplyIcon.vue';
import SwitchHorizontalIcon from '@/components/ui/icons/SwitchHorizontalIcon.vue';
import TheFilter from '@/views/admin/assign/filter/TheFilter.vue';

export default defineComponent({
  name: 'TheList',
  components: {
    TheReassign,
    TheDetach,
    TheSideModal,
    TheTable,
    TheTitle,
    DefaultLoader,
    TheCreate,
    TheView,
    TheFilter,
  },
  data() {
    return {
      isLoading: false,
      isCounterpartyLoading: true,
      isView: false,
      isDetach: false,
      isReassign: false,
      isOpenSidebar: false,
      fields: [
        { key: 'counterparty', label: this.$t('dictionary.pinning.fields.counterparty') },
        { key: 'user', notSortable: true, label: this.$t('dictionary.pinning.fields.user') },
        { key: 'division', notSortable: true, label: this.$t('dictionary.pinning.fields.division') },
        { key: 'department', notSortable: true, label: this.$t('dictionary.pinning.fields.department') },
        { key: 'action', notSortable: true, width: 52 },
      ],
    };
  },
  computed: {
    ...mapGetters({
      items: 'admin/dictionary/pinning/list/items',
      counterparties: 'counterparty/list/counterparties',
      divisions: 'admin/dictionary/structure/division/items',
      departments: 'admin/dictionary/structure/list/items',
      users: 'admin/user/list/items',
      counterpartyType: 'admin/dictionary/counterpartyType/list/items',
      filter: 'admin/dictionary/pinning/list/filter',
      sort: 'admin/dictionary/pinning/list/sort',
      pagination: 'admin/dictionary/pinning/list/pagination',
      user: 'user/localUserData',
    }),
    hasSelectedData() {
      return !!this.selectedItemId;
    },
    preparedItems() {
      const items: TableItem[] = this.items ?? [];

      return items.map((item: TableItem) => ({
        ...item,
        action: {
          type: 'component',
          component: ContextMenu,
          props: this.contextMenuProps(item),
          isVisible: this.isCanEdit && !!item.user,
        },
      }));
    },
    defaultOneOfList() {
      return [
        { const: null, title: '' },
      ];
    },
    preparedDivisions() {
      return [
        ...this.defaultOneOfList,
        ...this.divisions.map((structure: StructureItem) => ({
          const: structure.id,
          title: structure.name,
        })),
      ];
    },
    preparedCounterparties() {
      if (this.counterparties.length > 0) {
        return [
          ...this.defaultOneOfList,
          ...this.counterparties.map((counterparty: CounterpartyItemType) => ({
            const: counterparty.id,
            title: counterparty[fields.NAME_FIELD],
          })),
        ];
      }

      return this.defaultOneOfList;
    },
    preparedCounterpartyType() {
      if (this.counterpartyType.length > 0) {
        return [
          ...this.defaultOneOfList,
          ...this.counterpartyType.map((counterpartyType: CounterpartyTypeItem) => ({
            const: counterpartyType.id,
            title: counterpartyType.name,
          })),
        ];
      }

      return this.defaultOneOfList;
    },
    preparedDepartments() {
      if (this.departments.length > 0) {
        return [
          ...this.defaultOneOfList,
          ...this.departments.map((department: StructureItem) => ({
            const: department.id,
            title: department.name,
          })),
        ];
      }

      return this.defaultOneOfList;
    },
    preparedUsers() {
      if (this.users.length > 0) {
        return [
          ...this.defaultOneOfList,
          ...this.users.map((user: UserItem) => ({
            const: user.id,
            title: user.fullName,
          })),
        ];
      }

      return this.defaultOneOfList;
    },
  },
  methods: {
    ...mapActions({
      loadItems: 'admin/dictionary/pinning/list/loadItems',
      resetState: 'admin/dictionary/pinning/list/resetState',
      loadDivision: 'admin/dictionary/structure/division/loadItems',
      loadCounterpartyType: 'admin/dictionary/counterpartyType/list/loadItems',
      changeSort: 'admin/dictionary/pinning/list/changeSort',
      changeFilter: 'admin/dictionary/pinning/list/changeFilter',
      changePagination: 'admin/dictionary/pinning/list/changePagination',
      createItem: 'admin/dictionary/pinning/one/create',
      deleteItem: 'admin/dictionary/pinning/one/delete',
      detachItem: 'admin/dictionary/pinning/one/detach',
      reassignItem: 'admin/dictionary/pinning/one/reassign',
      resetCounterpartyData: 'admin/dictionary/pinning/one/resetState',
      loadCounterparties: 'counterparty/list/loadCounterparties',
    }),
    addHandler() {
      this.isOpenSidebar = true;
    },
    viewHandler(id: string | number) {
      this.isOpenSidebar = true;
      this.isView = true;
      this.selectedItemId = id;
    },
    closeSidebar() {
      this.isOpenSidebar = false;
      this.selectedItemId = null;
      this.isReassign = false;
      this.isDetach = false;
      this.isView = false;

      this.resetCounterpartyData();
    },
    contextMenuProps(item: TableItem) {
      return {
        params: [
          {
            text: this.$t('dictionary.pinning.buttons.detach'),
            icon: ReplyIcon,
            events: {
              click: () => {
                this.isOpenSidebar = true;
                this.selectedItemId = item.id;
                this.isDetach = true;
              },
            },
          },
          {
            text: this.$t('dictionary.pinning.buttons.reassign'),
            icon: SwitchHorizontalIcon,
            events: {
              click: () => {
                this.isOpenSidebar = true;
                this.selectedItemId = item.id;
                this.isReassign = true;
              },
            },
          },
        ],
      };
    },
    async createHandler(data: PinningItem, next:() => void) {
      await this.createItem(data);

      next();
      this.closeSidebar();
    },
    async detachHandler(data: PinningDetachItem, next: () => void) {
      await this.detachItem({
        id: this.selectedItemId,
        data,
      });

      next();
      this.closeSidebar();
      await this.loadItems();
    },
    async reassignHandler(data: PinningReassignItem, next: () => void) {
      await this.reassignItem({
        id: this.selectedItemId,
        data,
      });

      next();
      this.closeSidebar();
      await this.loadItems();
    },
  },
  async created() {
    this.isLoading = true;

    await this.changeFilter({
      divisionId: this.user?.division ?? undefined,
    });
    await this.loadItems();
    await this.loadDivision();
    await this.loadCounterpartyType();
    await this.loadCounterparties();

    this.isLoading = false;
  },
  unmounted() {
    this.resetState();
  },
  setup() {
    const selectedItemId = ref<string | null>(null);
    const checkPermissions = useCheckPermissions();
    const isCanEdit = ref(checkPermissions.dictionary.canEditAssign);

    return { selectedItemId, isCanEdit };
  },
});
