import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-col space-y-[18px]" }
const _hoisted_2 = {
  key: 0,
  class: "flex flex-col space-y-[6px]"
}
const _hoisted_3 = { class: "text-[14px] text-gray-600 font-semibold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dispatch_renderer = _resolveComponent("dispatch-renderer")!
  const _component_array_list_element = _resolveComponent("array-list-element")!
  const _component_add_circle_icon = _resolveComponent("add-circle-icon")!
  const _component_default_button = _resolveComponent("default-button")!
  const _component_the_danger_modal = _resolveComponent("the-danger-modal")!

  return (_ctx.control.visible)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(_ctx.styles.arrayList.root)
      }, [
        _createElementVNode("div", _hoisted_1, [
          (_ctx.noData)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", {
                  class: _normalizeClass(["flex w-full", _ctx.styles.horizontalLayout.root])
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.labels, (element, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: _normalizeClass(_ctx.styles.horizontalLayout.item),
                      key: index
                    }, [
                      _createElementVNode("label", {
                        class: _normalizeClass(_ctx.styles.control.label)
                      }, _toDisplayString(element), 3)
                    ], 2))
                  }), 128))
                ], 2),
                _createElementVNode("span", {
                  class: _normalizeClass(_ctx.styles.arrayList.noData)
                }, _toDisplayString(_ctx.$t('common.noDataToDisplay')), 3)
              ]))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.control.data, (element, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: `${_ctx.control.path}-${index}`,
              class: _normalizeClass(_ctx.styles.arrayList.itemWrapper)
            }, [
              _createVNode(_component_array_list_element, {
                moveUp: _ctx.moveUp(_ctx.control.path, index),
                moveUpEnabled: index > 0,
                moveDown: _ctx.moveDown(_ctx.control.path, index),
                moveDownEnabled: index < _ctx.control.data.length - 1,
                delete: _ctx.openRemoveModal.bind(this, index),
                deleteEnabled: _ctx.control.data.length <= 1,
                label: _ctx.childLabelForIndex(index),
                styles: _ctx.styles,
                options: _ctx.uischema.options,
                "only-text": _ctx.control.config.onlyText
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_dispatch_renderer, {
                    class: "flex w-full",
                    schema: _ctx.control.schema,
                    uischema: _ctx.UISchema,
                    path: _ctx.composePaths(_ctx.control.path, `${index}`),
                    enabled: _ctx.control.enabled,
                    renderers: _ctx.control.renderers,
                    cells: _ctx.control.cells
                  }, null, 8, ["schema", "uischema", "path", "enabled", "renderers", "cells"])
                ]),
                _: 2
              }, 1032, ["moveUp", "moveUpEnabled", "moveDown", "moveDownEnabled", "delete", "deleteEnabled", "label", "styles", "options", "only-text"])
            ], 2))
          }), 128)),
          (!_ctx.control.config.onlyText)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(_ctx.styles.arrayList.legend)
              }, [
                _createVNode(_component_default_button, {
                  border: "non-border",
                  class: _normalizeClass(_ctx.styles.arrayList.addButton),
                  onClick: _ctx.createDefaultItem
                }, {
                  icon: _withCtx(() => [
                    _createVNode(_component_add_circle_icon)
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('buttons.add')), 1)
                  ]),
                  _: 1
                }, 8, ["class", "onClick"])
              ], 2))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_the_danger_modal, {
          "approve-button-text": _ctx.$t('modals.removeConfirm'),
          text: _ctx.$t('modals.removeItem'),
          "is-open": _ctx.suggestToDelete !== null,
          class: "m-0",
          onCloseModal: _ctx.closeRemoveModal,
          onApprovalHandler: _ctx.approvalHandler
        }, null, 8, ["approve-button-text", "text", "is-open", "onCloseModal", "onApprovalHandler"])
      ], 2))
    : _createCommentVNode("", true)
}