import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_circle_check_icon = _resolveComponent("circle-check-icon")!
  const _component_info_circle_icon = _resolveComponent("info-circle-icon")!
  const _component_error_icon = _resolveComponent("error-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isCompleted)
      ? (_openBlock(), _createBlock(_component_circle_check_icon, {
          key: 0,
          width: "16",
          height: "16",
          color: "#22C55E"
        }))
      : _createCommentVNode("", true),
    (_ctx.isPlanned)
      ? (_openBlock(), _createBlock(_component_info_circle_icon, {
          key: 1,
          width: "16",
          height: "16",
          color: "#F59E0B"
        }))
      : _createCommentVNode("", true),
    (_ctx.isCanceled)
      ? (_openBlock(), _createBlock(_component_error_icon, {
          key: 2,
          width: "16",
          height: "16",
          color: "#B91C1C"
        }))
      : _createCommentVNode("", true)
  ], 64))
}