import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isSolid)
    ? (_openBlock(), _createElementBlock("svg", {
        key: 0,
        width: _ctx.width,
        height: _ctx.height,
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }, [
        _createElementVNode("rect", {
          width: "24",
          height: "24",
          rx: "12",
          fill: _ctx.color
        }, null, 8, _hoisted_2),
        _createElementVNode("path", {
          d: "M9.58949 15.2969L8.5625 14.2699L13.9872 8.87074L15.0142\n 9.89773L9.58949 15.2969ZM13.9787 15.2969L8.55398 9.89773L9.58097\n 8.87074L15.0057 14.2699L13.9787 15.2969Z",
          fill: _ctx.childColor
        }, null, 8, _hoisted_3)
      ], 8, _hoisted_1))
    : _createCommentVNode("", true)
}