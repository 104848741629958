import { JsonSchema, SchemaBasedCondition } from '@jsonforms/core';
import CustomUISchemaElement from '@/components/jsonforms/interfaces/CustomUISchemaElement';
import JsonData from '@/components/jsonforms/interfaces/JsonData';

export default function useCheckRules() {
  const ARRAY_TYPE = 'array';

  return {
    checkSchemaRule(data: JsonData, schema: JsonSchema, uischema: CustomUISchemaElement) {
      const resultData = data;

      uischema.elements?.forEach((item: CustomUISchemaElement) => {
        if (item.rule) {
          const condition = item.rule.condition as SchemaBasedCondition;
          const fieldId: string | undefined = condition.scope.split('/').pop();

          if (fieldId && !data[fieldId]) {
            const elementScope: string | undefined = item.scope?.split('/').pop();

            if (elementScope && schema.properties?.[elementScope].type !== ARRAY_TYPE) {
              resultData[elementScope] = null;
            }
          }
        }
      });

      return resultData;
    },
  };
}
