
import { defineComponent, markRaw } from 'vue';
import {
  DispatchRenderer,
  RendererProps,
  rendererProps,
  useJsonFormsLayout,
} from '@jsonforms/vue';
import {
  JsonFormsRendererRegistryEntry,
  JsonSchema,
  rankWith,
  schemaTypeIs,
  uiTypeIs,
} from '@jsonforms/core';
import { createDefaultValue } from '@/components/jsonforms/util/renderer';
import { useVanillaLayout } from '@jsonforms/vue-vanilla';
import TheTable from '@/components/ui/tables/vertical/TheTable.vue';
import {
  TableFieldObject,
  TableItem,
  TableItemType,
} from '@/components/ui/tables/vertical/TheTable';
import CustomUISchemaElement from '@/components/jsonforms/interfaces/CustomUISchemaElement';
import CustomUISchemaLayout from '@/components/jsonforms/interfaces/CustomUISchemaLayout';

const verticalTableRenderer = defineComponent({
  name: 'vertical-table-renderer',
  components: {
    TheTable,
  },
  props: {
    ...rendererProps<CustomUISchemaLayout>(),
  },
  data() {
    return {
      item: {},
      fields: [],
    };
  },
  methods: {
    createDefaultValue,
    createDefaultSchema(key: string, schema: object): JsonSchema {
      return {
        type: 'object',
        properties: {
          [key]: schema,
        },
      };
    },
    createDefaultControl(key: string, label = ''): CustomUISchemaElement {
      return {
        type: 'Control',
        label,
        scope: `#/properties/${key}`,
      };
    },
    prepareFields(): TableFieldObject[] {
      return this.layout.uischema.elements.map((element: CustomUISchemaElement) => {
        const key: string = element.scope?.split('/').pop() ?? '';

        return {
          key,
          label: element.label ?? key,
        };
      });
    },
    prepareItem(): TableItem {
      const propertyKeys = Object.keys(this.layout.schema.properties);
      const defaultValues: TableItem = {};

      propertyKeys.forEach((key: string) => {
        const schema: object = this.layout.schema.properties[key];

        defaultValues[key] = {
          type: TableItemType.COMPONENT,
          component: markRaw(DispatchRenderer),
          props: {
            schema: this.createDefaultSchema(key, schema),
            uischema: this.createDefaultControl(key),
            path: key,
            enabled: true,
            renderers: this.layout.renderers,
            cells: this.layout.cells,
          },
        };
      });

      return defaultValues;
    },
  },
  mounted() {
    this.item = this.prepareItem();
    this.fields = this.prepareFields();
  },
  setup(props: RendererProps<CustomUISchemaLayout>) {
    return useVanillaLayout(useJsonFormsLayout(props));
  },
});

export default verticalTableRenderer;

export const verticalTableTester = schemaTypeIs('object')
  && uiTypeIs('VerticalTable');

export const entry: JsonFormsRendererRegistryEntry = {
  renderer: verticalTableRenderer,
  tester: rankWith(2, verticalTableTester),
};
