import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-27efd6f4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-content" }
const _hoisted_2 = { class: "modal-block" }
const _hoisted_3 = { class: "modal-block__title" }
const _hoisted_4 = { class: "modal-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search_form = _resolveComponent("search-form")!
  const _component_manually_form = _resolveComponent("manually-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('counterparty.create.title')), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.isSearchForm)
        ? (_openBlock(), _createBlock(_component_search_form, _mergeProps({ key: 0 }, _ctx.$attrs, { onRedirectToManually: _ctx.redirectToManuallyHandler }), null, 16, ["onRedirectToManually"]))
        : (_openBlock(), _createBlock(_component_manually_form, _normalizeProps(_mergeProps({ key: 1 }, _ctx.$attrs)), null, 16))
    ])
  ]))
}