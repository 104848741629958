
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'RolesItem',
  props: {
    roles: {
      type: Array as PropType<string[]>,
      default: () => ([]),
    },
  },
  computed: {
    rolesString() {
      return this.roles.join(', ');
    },
  },
});
