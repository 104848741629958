import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createVNode as _createVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b1fd2712"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["data-key"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tabs_menu_item = _resolveComponent("tabs-menu-item")!
  const _component_right_arrow = _resolveComponent("right-arrow")!
  const _component_the_danger_modal = _resolveComponent("the-danger-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives(_createElementVNode("div", {
      class: "tabs-menu-block",
      disabled: _ctx.disabled
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
        return (_openBlock(), _createBlock(_component_tabs_menu_item, {
          key: tab.id,
          name: tab.name,
          shortName: tab.shortName,
          "counterparty-id": _ctx.counterpartyId,
          isActiveTab: _ctx.isActiveTab(tab.id),
          "data-key": tab.id,
          onClick: _ctx.clickHandler
        }, null, 8, ["name", "shortName", "counterparty-id", "isActiveTab", "data-key", "onClick"]))
      }), 128))
    ], 8, _hoisted_1), [
      [_vShow, !_ctx.$sizeWindow.isLg]
    ]),
    _withDirectives(_createElementVNode("div", {
      class: _normalizeClass(["w-full bg-white overflow-hidden", _ctx.mobileMenuClass])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["current-block", { 'border-open': _ctx.isOpen }]),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleTab && _ctx.toggleTab(...args)))
      }, [
        _createTextVNode(_toDisplayString(_ctx.activeCurrentTab) + " ", 1),
        _createElementVNode("div", {
          class: _normalizeClass([{ '!rotate-[-90deg]': _ctx.isOpen }, "rotate-90"])
        }, [
          _createVNode(_component_right_arrow)
        ], 2)
      ], 2),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass([{ selected: tab.id === _ctx.selectedTab }, "mobile-tab-element"]),
          key: tab.id,
          "data-key": tab.id,
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.clickHandler && _ctx.clickHandler(...args)))
        }, _toDisplayString(tab.name), 11, _hoisted_2))
      }), 128))
    ], 2), [
      [_vShow, _ctx.$sizeWindow.isLg]
    ]),
    _createVNode(_component_the_danger_modal, {
      "approve-button-text": _ctx.$t('counterparty.tabs.dangerModal.confirmButton'),
      text: _ctx.$t('counterparty.tabs.dangerModal.text'),
      "hint-text": _ctx.$t('counterparty.tabs.dangerModal.hintText'),
      "is-open": _ctx.openDangerModal,
      onApprovalHandler: _ctx.approvalHandler,
      onCloseModal: _ctx.closeModal
    }, null, 8, ["approve-button-text", "text", "hint-text", "is-open", "onApprovalHandler", "onCloseModal"])
  ]))
}