
import _ from 'lodash';
import { defineComponent, markRaw, PropType } from 'vue';
import config from '@/config';
import { UserListFilterParams } from '@/api/admin/user';
import CloseIcon from '@/components/ui/icons/CloseIcon.vue';
import SearchIcon from '@/components/ui/icons/SearchIcon.vue';
import ButtonGroup from '@/components/ui/groups/ButtonGroup/ButtonGroup.vue';
import SelectDefault, { DataType } from '@/components/ui/selects/SelectDefault/SelectDefault.vue';
import IconButton from '@/components/ui/buttons/IconButton/IconButton.vue';
import ActiveStatusIcon from '@/components/ui/statuses/ActiveStatusIcon.vue';
import BlockedStatusIcon from '@/components/ui/statuses/BlockedStatusIcon.vue';

export default defineComponent({
  name: 'TheFilter',
  inject: ['$sizeWindow', '$userAccess'],
  emits: ['filterChanged', 'ajaxDivision', 'ajaxCompany'],
  components: {
    IconButton,
    CloseIcon,
    SearchIcon,
    SelectDefault,
    ButtonGroup,
  },
  props: {
    filter: {
      type: Object as PropType<UserListFilterParams>,
      default: () => ({
        query: '',
        division: '',
        status: '',
        role: '',
      }),
    },
    label: {
      type: String,
      default: '',
    },
    clearButton: {
      type: Boolean,
      default: true,
    },
    divisions: {
      type: Array as PropType<DataType[]>,
      default: () => ([]),
    },
    companies: {
      type: Array as PropType<DataType[]>,
      default: () => ([]),
    },
    roles: {
      type: Array as PropType<DataType[]>,
      default: () => ([]),
    },
  },
  data() {
    return {
      query: '',
      division: '',
      company: '',
      status: '',
      role: '',
      statusList: [
        { name: '', label: 'Все', active: true },
        { name: config.STATUS_USER_IS_ACTIVE, label: '', content: markRaw(ActiveStatusIcon) },
        {
          name: config.STATUS_USER_IS_BLOCKED,
          label: '',
          content: markRaw(BlockedStatusIcon),
        },
      ],
    };
  },
  computed: {
    newFilter() {
      return {
        query: this.query,
        division: this.division,
        company: this.company,
        status: this.status,
        role: this.role,
      };
    },
    isShowCompanyFilter() {
      return this.$userAccess.admin.isSuperAdmin && !this.$sizeWindow.isSm;
    },
    isShowDivisionFilter() {
      const isAdmin = this.$userAccess.admin.isSuperAdmin
        || this.$userAccess.admin.isAdminCompany;

      return isAdmin && !this.$sizeWindow.isSm;
    },
    isDisabledDivisionFilter() {
      return this.isShowCompanyFilter && !this.company;
    },
  },
  methods: {
    searchInputHandler: _.debounce(async function handler(e: { target: HTMLInputElement }) {
      this.query = String(e.target.value);

      this.$emit('filterChanged', this.newFilter);
    }, 500),
    divisionChange(value: string) {
      this.division = value;

      this.$emit('filterChanged', this.newFilter);
    },
    companyChange(value: string) {
      this.company = value;
      this.division = '';

      this.$emit('filterChanged', this.newFilter);
    },
    statusChange(value: string) {
      this.status = value;

      this.$emit('filterChanged', this.newFilter);
    },
    clearSearch() {
      this.query = '';
      this.division = '';
      this.company = '';
      this.status = '';
      this.role = '';

      this.$emit('filterChanged', this.newFilter);
    },
    ajaxHandlerCompany(query: string) {
      this.$emit('ajaxCompany', query);
    },
    ajaxHandlerDivision(query: string) {
      this.$emit('ajaxDivision', query);
    },
  },
});
