import {
  and,
  or,
  hasType,
  JsonSchema,
  schemaMatches,
  schemaSubPathMatches,
  Tester,
  uiTypeIs,
  optionIs,
} from '@jsonforms/core';

const hasOneOfItems = (schema: JsonSchema): boolean => schema.oneOf !== undefined
  && schema.oneOf.length > 0
  && (schema.oneOf as JsonSchema[]).every((entry: JsonSchema) => entry.const !== undefined);

const hasEnumItems = (schema: JsonSchema): boolean => schema.type === 'string' && schema.enum !== undefined;

export const isMultiEnum: Tester = and(
  uiTypeIs('Control'),
  and(
    schemaMatches(
      (schema) => hasType(schema, 'array')
        && !Array.isArray(schema.items)
        && schema.uniqueItems === true,
    ),
    schemaSubPathMatches('items', (schema) => hasOneOfItems(schema) || hasEnumItems(schema)),
  ),
);

export const isMultiTree: Tester = and(
  optionIs('isTree', true),
  uiTypeIs('Control'),
  and(
    schemaMatches(
      (schema) => hasType(schema, 'array')
        && !Array.isArray(schema.items)
        && schema.uniqueItems === true,
    ),
    schemaSubPathMatches('items', (schema) => hasOneOfItems(schema)),
  ),
);

export const isPhoneControl: Tester = and(
  uiTypeIs('PhoneControl'),
  schemaMatches((schema) => hasType(schema, 'string')),
);

export const isAddressControl: Tester = and(
  uiTypeIs('AddressControl'),
  schemaMatches((schema) => hasType(schema, 'object')),
);

export const isAjaxControl: Tester = and(
  uiTypeIs('AjaxControl'),
  or(
    schemaMatches((schema) => hasType(schema, 'string')),
    and(
      optionIs('multiple', true),
      schemaMatches(
        (schema) => hasType(schema, 'array')
          && !Array.isArray(schema.items)
          && schema.uniqueItems === true,
      ),
    ),
  ),
);
