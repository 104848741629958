import RegionService, { RegionData, RegionItem } from '@/api/admin/dictionary/region';
import { Module } from 'vuex';
import _ from 'lodash';
import { StateType } from '@/store';
import { AxiosCallResponse } from '@/api/base';
import i18n from '@/i18n';
import { TYPE_LABEL_SUCCESS } from '@/components/ui/notifications/TheAlert/TheAlert.vue';

export interface RegionOneStateType {
  data: RegionItem | null,
}

const initialState = {
  data: null,
};

const regionOneModule: Module<RegionOneStateType, StateType> = {
  namespaced: true,
  state: _.cloneDeep(initialState),
  getters: {
    data: (state) => state.data,
  },
  actions: {
    async create({ rootGetters }, data: RegionData) {
      const api: RegionService = rootGetters
        .$api
        .admin
        .dictionary
        .region;

      const response: AxiosCallResponse<RegionItem> = await api.create(data);

      if (!response.error) {
        await rootGetters
          .$notificationLogger
          .setNotification(
            TYPE_LABEL_SUCCESS,
            i18n.global.t('notification.admin.dictionary.success.create'),
          );
      }
    },
    async update(
      { rootGetters },
      params: { id: string, data: RegionData },
    ) {
      const api: RegionService = rootGetters
        .$api
        .admin
        .dictionary
        .region;

      const response: AxiosCallResponse<null> = await api.update(params.id, params.data);

      if (!response.error) {
        await rootGetters
          .$notificationLogger
          .setNotification(
            TYPE_LABEL_SUCCESS,
            i18n.global.t('notification.admin.dictionary.success.update'),
          );
      }
    },
    async delete({ rootGetters }, id: string) {
      const api: RegionService = rootGetters
        .$api
        .admin
        .dictionary
        .region;

      const response: AxiosCallResponse<null> = await api.delete(id);

      if (!response.error) {
        await rootGetters
          .$notificationLogger
          .setNotification(
            TYPE_LABEL_SUCCESS,
            i18n.global.t('notification.admin.dictionary.success.delete'),
          );
      }
    },

    async restore({ rootGetters }, id: string) {
      if (id) {
        const api: RegionService = rootGetters.$api.admin.dictionary.region;

        const response: AxiosCallResponse<null> = await api.restore(id);

        if (!response.error) {
          await rootGetters
            .$notificationLogger
            .setNotification(
              TYPE_LABEL_SUCCESS,
              i18n.global.t('notification.admin.dictionary.success.restore'),
            );
        }
      }
    },
  },
};

export default regionOneModule;
