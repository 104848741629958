import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_arrow = _resolveComponent("select-arrow")!
  const _component_vue_tel_input = _resolveComponent("vue-tel-input")!

  return (_openBlock(), _createBlock(_component_vue_tel_input, {
    modelValue: $data.value,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($data.value) = $event)),
    mode: "international",
    styleClasses: "input",
    "valid-characters-only": true,
    "all-countries": $data.countries,
    "input-options": $props.inputOptions,
    "default-country": $props.defaultCountry,
    "dropdown-options": $props.dropdownOptions,
    "preferred-countries": $props.preferredCountries,
    onOpen: $options.toggleIsOpen,
    onClose: $options.toggleIsOpen,
    onOnInput: $options.onInputHandler,
    onValidate: $options.validateHandler
  }, {
    "arrow-icon": _withCtx(() => [
      _createVNode(_component_select_arrow, {
        class: _normalizeClass({ 'rotate-180': $data.isOpen })
      }, null, 8, ["class"])
    ]),
    _: 1
  }, 8, ["modelValue", "all-countries", "input-options", "default-country", "dropdown-options", "preferred-countries", "onOpen", "onClose", "onOnInput", "onValidate"]))
}