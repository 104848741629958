<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_5728_49473)">
      <rect x="1" y="1" width="16" height="5.33333" fill="white"/>
      <rect x="1" y="6.3335" width="16" height="5.33333" fill="#2563EB"/>
      <rect x="1" y="11.6665" width="16" height="5.33333" fill="#DC2626"/>
    </g>
    <rect
      x="0.75"
      y="0.75"
      width="16.5"
      height="16.5"
      rx="8.25"
      stroke="#64748B"
      stroke-width="0.5"
    />
    <defs>
      <clipPath id="clip0_5728_49473">
        <rect x="1" y="1" width="16" height="16" rx="8" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'RussiaIcon',
  props: {
    width: {
      type: String,
      default: '18',
    },
    height: {
      type: String,
      default: '18',
    },
  },
};
</script>
