import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_default_input = _resolveComponent("default-input")!
  const _component_the_form = _resolveComponent("the-form")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_default_input, {
      label: _ctx.$t('admin.user.detail.form.email'),
      disabled: true,
      value: _ctx.user.email
    }, null, 8, ["label", "value"]),
    _createVNode(_component_the_form, {
      ref: "form",
      class: "mt-[24px]",
      data: _ctx.user,
      departments: _ctx.preparedDepartments,
      divisions: _ctx.preparedDivisions,
      companies: _ctx.preparedCompanies
    }, null, 8, ["data", "departments", "divisions", "companies"])
  ]))
}