
import { defineComponent } from 'vue';
import TablePaginationBack from '@/components/ui/icons/pagination/TablePaginationBack.vue';
import TablePaginationOverBack from '@/components/ui/icons/pagination/TablePaginationOverBack.vue';
import TablePaginationNext from '@/components/ui/icons/pagination/TablePaginationNext.vue';
import TablePaginationOverNext from '@/components/ui/icons/pagination/TablePaginationOverNext.vue';

export default defineComponent({
  name: 'TablePagination',
  emits: ['paginationChanged'],
  components: {
    TablePaginationBack,
    TablePaginationOverBack,
    TablePaginationNext,
    TablePaginationOverNext,
  },
  props: {
    pagination: {
      type: Object,
      default: () => ({
        currentPage: 0,
        perPage: 0,
        totalCount: 0,
        pageCount: 0,
      }),
    },
  },
  data() {
    return {
      perPage: 5,
      currentPage: 1,
    };
  },
  computed: {
    startNumber() {
      if (this.pagination.totalCount === 0) {
        return 0;
      }

      if (this.currentPage === 1) {
        return 1;
      }

      return (this.perPage * this.currentPage) - this.perPage;
    },
    endNumber() {
      const number: number = this.perPage * this.currentPage;

      return number > this.pagination.totalCount ? this.pagination.totalCount : number;
    },
    newPagination() {
      return {
        currentPage: this.currentPage,
        perPage: this.perPage,
        totalCount: this.pagination.totalCount,
        pageCount: this.pagination.pageCount,
      };
    },
    prevButtonsDisabled() {
      return this.pagination.currentPage < 2;
    },
    nextButtonsDisabled() {
      return this.pagination.currentPage === this.pagination.pageCount;
    },
  },
  watch: {
    pagination() {
      this.currentPage = this.pagination.currentPage;
      this.perPage = this.pagination.perPage;
    },
  },
  methods: {
    nextPage() {
      this.currentPage += 1;
      this.$emit('paginationChanged', this.newPagination);
    },
    prevPage() {
      this.currentPage -= 1;
      this.$emit('paginationChanged', this.newPagination);
    },
    firstPage() {
      this.currentPage = 1;
      this.$emit('paginationChanged', this.newPagination);
    },
    lastPage() {
      this.currentPage = this.pagination.pageCount;
      this.$emit('paginationChanged', this.newPagination);
    },
    getOpacity(isDisabled: boolean) {
      return isDisabled ? '0.25' : '1';
    },
  },
});

