import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_button = _resolveComponent("icon-button")!
  const _component_remove_column_icon = _resolveComponent("remove-column-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex w-full items-start relative", _ctx.styles.arrayList.item])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["flex w-full", _ctx.contentClasses])
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 2),
    (!_ctx.onlyText)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["flex items-center min-h-[42px] mt-[20px]", _ctx.toolbarClasses])
        }, [
          (_ctx.showSortButtons)
            ? (_openBlock(), _createBlock(_component_icon_button, {
                key: 0,
                onClick: _ctx.moveUpClicked,
                disabled: !_ctx.moveUpEnabled,
                class: _normalizeClass(_ctx.styles.arrayList.itemMoveUp),
                type: "button"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" ↑ ")
                ]),
                _: 1
              }, 8, ["onClick", "disabled", "class"]))
            : _createCommentVNode("", true),
          (_ctx.showSortButtons)
            ? (_openBlock(), _createBlock(_component_icon_button, {
                key: 1,
                onClick: _ctx.moveDownClicked,
                disabled: !_ctx.moveDownEnabled,
                class: _normalizeClass(_ctx.styles.arrayList.itemMoveDown),
                type: "button"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" ↓ ")
                ]),
                _: 1
              }, 8, ["onClick", "disabled", "class"]))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    (!_ctx.onlyText && !_ctx.deleteEnabled)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(_ctx.styles.arrayList.itemDelete),
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.deleteClicked && _ctx.deleteClicked(...args)))
        }, [
          _createVNode(_component_remove_column_icon)
        ], 2))
      : _createCommentVNode("", true)
  ], 2))
}