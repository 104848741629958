import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-57b360fc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "block" }
const _hoisted_2 = { class: "block-header !p-0" }
const _hoisted_3 = {
  key: 0,
  class: "mt-[24px] w-full sm:w-[352px]"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = {
  key: 3,
  class: "mt-[24px]"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_news_add_modal = _resolveComponent("news-add-modal")!
  const _component_the_danger_modal = _resolveComponent("the-danger-modal")!
  const _component_datepicker_period = _resolveComponent("datepicker-period")!
  const _component_table_default = _resolveComponent("table-default")!
  const _component_table_pagination = _resolveComponent("table-pagination")!
  const _component_news_item = _resolveComponent("news-item")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h1", null, _toDisplayString(_ctx.$t('news.newsTitle')), 1),
        (_ctx.isCanEdit)
          ? (_openBlock(), _createBlock(_component_news_add_modal, {
              key: 0,
              news: _ctx.news,
              "news-id": _ctx.newsEditId,
              onAddNewsHandler: _ctx.newsHandler,
              onCloseUpdateModal: _ctx.closeUpdateModal
            }, null, 8, ["news", "news-id", "onAddNewsHandler", "onCloseUpdateModal"]))
          : _createCommentVNode("", true),
        _createVNode(_component_the_danger_modal, {
          "approve-button-text": _ctx.$t('modals.removeConfirm'),
          text: _ctx.$t('news.removeItem'),
          "is-open": _ctx.newsDeleteId !== null,
          onApprovalHandler: _ctx.actionDelete,
          onCloseModal: _ctx.closeDeleteModal
        }, null, 8, ["approve-button-text", "text", "is-open", "onApprovalHandler", "onCloseModal"])
      ]),
      (_ctx.datepickerVisible)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_datepicker_period, { onChange: _ctx.changeFilter }, null, 8, ["onChange"])
          ]))
        : _createCommentVNode("", true),
      (this.news.length > 0 && !this.$sizeWindow.isLg)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_table_default, {
              class: "w-full",
              news: _ctx.news,
              sort: _ctx.sort,
              "sort-handler": _ctx.changeSort,
              onContextEdit: _ctx.actionEdit,
              onContextDelete: _ctx.openRemoveModal
            }, null, 8, ["news", "sort", "sort-handler", "onContextEdit", "onContextDelete"]),
            _createVNode(_component_table_pagination, {
              pagination: _ctx.pagination,
              onPaginationChanged: _ctx.changePagination
            }, null, 8, ["pagination", "onPaginationChanged"])
          ]))
        : _createCommentVNode("", true),
      (this.news.length > 0 && this.$sizeWindow.isLg)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.news, (newsItem) => {
              return (_openBlock(), _createBlock(_component_news_item, {
                key: newsItem.id,
                "news-item": newsItem
              }, null, 8, ["news-item"]))
            }), 128)),
            _createVNode(_component_table_pagination, {
              pagination: _ctx.pagination,
              onPaginationChanged: _ctx.changePagination
            }, null, 8, ["pagination", "onPaginationChanged"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.news.length === 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.$t('errors.notFoundNews')), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}