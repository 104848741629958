
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NewsItem',
  props: {
    newsItem: Object,
  },
  data: () => ({
    isOpen: false,
  }),
  computed: {
    contentLength() {
      return this.newsItem?.content.length;
    },
    // Функция для обрезки протокола и параметров у ссылки
    shortLink() {
      const shortLink = this.newsItem?.link.split('/');
      return shortLink[2] ?? this.$t('errors.link');
    },
  },
  methods: {
    toggleShow() {
      this.isOpen = !this.isOpen;
    },
  },
});
