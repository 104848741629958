<template>
  <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.0004 7.00024V9.00024M10.0004 13.0002H10.0104M3.07241
 17.0002H16.9284C18.4684 17.0002 19.4304 15.3332 18.6604 14.0002L11.7324
 2.00024C10.9624 0.667238 9.03841 0.667238 8.26841 2.00024L1.34041
 14.0002C0.570413 15.3332 1.53241 17.0002 3.07241 17.0002Z"
      stroke="#B91C1C"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
