import { Module } from 'vuex';
import list, { ImplementationListStateType } from '@/store/modules/counterparty/block/characteristic/implementation/list';
import one, { ImplementationOneStateType } from '@/store/modules/counterparty/block/characteristic/implementation/one';
import { StateType } from '@/store';

export interface ImplementationStateType {
  list: ImplementationListStateType,
  one: ImplementationOneStateType,
}

const implementationModule: Module<ImplementationStateType, StateType> = {
  namespaced: true,
  modules: {
    list,
    one,
  },
};

export default implementationModule;
