import { Module } from 'vuex';
import { StateType } from '@/store';
import list, { AssignHistoryListStateType } from '@/store/modules/counterparty/block/general/assignHistory/list';

export interface AssignHistoryStateType {
  list: AssignHistoryListStateType,
}

const assignHistoryModule: Module<AssignHistoryStateType, StateType> = {
  namespaced: true,
  modules: {
    list,
  },
};

export default assignHistoryModule;
