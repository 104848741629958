import { AxiosCallResponse } from '@/api/base';
import store from '@/store';
import CompanyService, { CompanyItem } from '@/api/admin/dictionary/company';

export function useOneApi() {
  return {
    async loadData(id: string) {
      const api: CompanyService = store.getters.$api.admin.dictionary.company;

      const response: AxiosCallResponse<CompanyItem> = await api.one(id);

      return response.data ?? null;
    },
  };
}

export default {};
