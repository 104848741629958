import { Module } from 'vuex';
import one, { CompanyOneStateType } from '@/store/modules/admin/dictionary/company/one';
import list, { CompanyListStateType } from '@/store/modules/admin/dictionary/company/list';
import { StateType } from '@/store';

export interface CompanyStateType {
  list: CompanyListStateType,
  one: CompanyOneStateType,
}

const companyModule: Module<CompanyStateType, StateType> = {
  namespaced: true,
  modules: {
    list,
    one,
  },
};

export default companyModule;
