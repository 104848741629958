import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1cd15416"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "loader-block"
}
const _hoisted_3 = {
  key: 1,
  class: "w-full"
}
const _hoisted_4 = { class: "w-full" }
const _hoisted_5 = { class: "header-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_default_loader = _resolveComponent("default-loader")!
  const _component_the_title = _resolveComponent("the-title")!
  const _component_the_filter = _resolveComponent("the-filter")!
  const _component_the_table = _resolveComponent("the-table")!
  const _component_the_create = _resolveComponent("the-create")!
  const _component_the_view = _resolveComponent("the-view")!
  const _component_the_detach = _resolveComponent("the-detach")!
  const _component_the_reassign = _resolveComponent("the-reassign")!
  const _component_the_side_modal = _resolveComponent("the-side-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_default_loader)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_the_title, { onButtonClick: _ctx.addHandler }, null, 8, ["onButtonClick"]),
              _createVNode(_component_the_filter)
            ])
          ]),
          _createVNode(_component_the_table, {
            items: _ctx.preparedItems,
            fields: _ctx.fields,
            sort: _ctx.sort,
            pagination: _ctx.pagination,
            onBodyItemClick: _ctx.viewHandler,
            onChangeSortBy: _ctx.changeSort,
            onChangePagination: _ctx.changePagination
          }, null, 8, ["items", "fields", "sort", "pagination", "onBodyItemClick", "onChangeSortBy", "onChangePagination"]),
          _createVNode(_component_the_side_modal, {
            "is-show-modal": _ctx.isOpenSidebar,
            "is-sidebar-with-footer": !_ctx.isView,
            onCloseHandler: _ctx.closeSidebar
          }, {
            default: _withCtx(() => [
              (!_ctx.isView && !_ctx.hasSelectedData)
                ? (_openBlock(), _createBlock(_component_the_create, {
                    key: 0,
                    departments: _ctx.preparedDepartments,
                    divisions: _ctx.preparedDivisions,
                    counterparties: _ctx.preparedCounterparties,
                    counterpartyTypes: _ctx.preparedCounterpartyType,
                    users: _ctx.preparedUsers,
                    onCancel: _ctx.closeSidebar,
                    onCreate: _ctx.createHandler
                  }, null, 8, ["departments", "divisions", "counterparties", "counterpartyTypes", "users", "onCancel", "onCreate"]))
                : _createCommentVNode("", true),
              (_ctx.isView && _ctx.hasSelectedData)
                ? (_openBlock(), _createBlock(_component_the_view, {
                    key: 1,
                    departments: _ctx.preparedDepartments,
                    divisions: _ctx.preparedDivisions,
                    counterparties: _ctx.preparedCounterparties,
                    counterpartyTypes: _ctx.preparedCounterpartyType,
                    users: _ctx.preparedUsers,
                    id: _ctx.selectedItemId,
                    "is-loading": _ctx.isCounterpartyLoading
                  }, null, 8, ["departments", "divisions", "counterparties", "counterpartyTypes", "users", "id", "is-loading"]))
                : _createCommentVNode("", true),
              (_ctx.isDetach)
                ? (_openBlock(), _createBlock(_component_the_detach, {
                    key: 2,
                    id: _ctx.selectedItemId,
                    onCancel: _ctx.closeSidebar,
                    onDetach: _ctx.detachHandler
                  }, null, 8, ["id", "onCancel", "onDetach"]))
                : _createCommentVNode("", true),
              (_ctx.isReassign)
                ? (_openBlock(), _createBlock(_component_the_reassign, {
                    key: 3,
                    departments: _ctx.preparedDepartments,
                    users: _ctx.preparedUsers,
                    id: _ctx.selectedItemId,
                    onCancel: _ctx.closeSidebar,
                    onReassign: _ctx.reassignHandler
                  }, null, 8, ["departments", "users", "id", "onCancel", "onReassign"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["is-show-modal", "is-sidebar-with-footer", "onCloseHandler"])
        ]))
  ]))
}