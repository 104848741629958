import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_circle_check_icon = _resolveComponent("circle-check-icon")!
  const _component_the_badge = _resolveComponent("the-badge")!

  return (_openBlock(), _createBlock(_component_the_badge, {
    size: "xl",
    variant: "success"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_circle_check_icon, {
        width: "18",
        height: "18",
        type: "regular",
        color: "#166534"
      }),
      _createTextVNode(" " + _toDisplayString(_ctx.$t('common.active')), 1)
    ]),
    _: 1
  }))
}