
import _ from 'lodash';

import {
  JsonFormsRendererRegistryEntry,
  Layout,
  rankWith,
  and,
  isLayout,
  uiTypeIs, JsonFormsSubStates, JsonSchema,
} from '@jsonforms/core';
import {
  DispatchRenderer,
  RendererProps,
  rendererProps,
  useJsonFormsLayout,
} from '@jsonforms/vue';
import { useVanillaLayout } from '@jsonforms/vue-vanilla';
import { ErrorObject } from 'ajv';
import { defineComponent, inject, ref } from 'vue';
import BlockDefault from '@/components/ui/blocks/BlockDefault/BlockDefault.vue';
import CustomUISchemaElement from '@/components/jsonforms/interfaces/CustomUISchemaElement';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import DefaultButton from '@/components/ui/buttons/DefaultButton/DefaultButton.vue';
import IconButton from '@/components/ui/buttons/IconButton/IconButton.vue';
import EditIcon from '@/components/ui/icons/EditIcon.vue';
import HistoryIcon from '@/components/ui/icons/HistoryIcon.vue';
import BlocksHistory from '@/components/counterparty/card/blocks/history/BlocksHistory.vue';
import ContextMenu
  from '@/components/ui/menu/ContextMenu/ContextMenu.vue';
import { getChangedData } from '@/components/jsonforms/util/util';
import { validateData } from '@/utils/helpers/AjvValidator';
import { ContextMenuType } from '@/components/ui/menu/ContextMenu/ContextMenu';

interface CounterpartyCardData {
  needConfirmation: boolean,
  changedData: JsonData,
  allData: JsonData,
  errors: ErrorObject[],
  isEdit: boolean,
  isLoading: boolean,
  isOpenHistory: boolean,
  isOpenEditModal: boolean,
}

const initialState = (): CounterpartyCardData => ({
  needConfirmation: false,
  changedData: {},
  allData: {},
  errors: [],
  isEdit: false,
  isLoading: false,
  isOpenHistory: false,
  isOpenEditModal: false,
});

const layoutRenderer = defineComponent({
  name: 'general-information-renderer',
  inject: ['$sizeWindow'],
  emits: [
    'updateBlockHandler',
    'hasErrorsHandler',
    'editHandler',
    'changeHandler',
    'loadBlockHistory',
  ],
  components: {
    DispatchRenderer,
    BlockDefault,
    DefaultButton,
    EditIcon,
    IconButton,
    HistoryIcon,
    BlocksHistory,
    ContextMenu,
  },
  data: initialState,
  props: {
    ...rendererProps<Layout>(),
  },
  watch: {
    isEdit(value: boolean) {
      this.$emit('editHandler', this.groupId, value, () => {
        this.closeEdit();
      });

      if (this.jsonFormState) {
        this.oldData = this.jsonFormState.core?.data;
        this.jsonFormState.config.onlyText = !value;
        this.jsonFormState.readonly = !value;
      }
    },
    // eslint-disable-next-line func-names
    'jsonFormState.core.data': function () {
      this.onChange();
    },
  },
  computed: {
    data() {
      return this.jsonFormState.core?.data ?? {};
    },
    groupId() {
      return this.jsonFormState?.config?.groupId ?? '';
    },
    dataBySchema(): JsonData {
      return this.getDataBySchema(this.allData, this.layout.schema);
    },
    contextMenuParams(): ContextMenuType[] {
      return [
        {
          text: this.$t('jsonforms.buttons.edit'),
          icon: EditIcon,
          events: {
            click: () => this.openEdit(),
          },
        },
        {
          text: this.$t('jsonforms.buttons.history'),
          icon: HistoryIcon,
          events: {
            click: () => this.openHistory(),
          },
        },
      ];
    },
  },
  methods: {
    closeEdit() {
      if (this.jsonFormState && this.jsonFormState.core) {
        this.jsonFormState.core.data = this.oldData;
        this.jsonFormState.core.additionalErrors = [];
      }

      this.setInitialState();
    },
    openEdit() {
      this.isEdit = true;
      this.isOpenHistory = false;
    },
    toggleEdit() {
      this.isEdit = !this.isEdit;
      this.needConfirmation = false;
    },
    openHistory() {
      this.isOpenHistory = true;

      this.$emit('loadBlockHistory', this.groupId);
    },
    closeHistory() {
      this.isOpenHistory = false;
    },
    closeEditModal() {
      this.isOpenEditModal = false;
    },
    setInitialState() {
      Object.assign(this.$data, initialState());
    },
    blockChangedHandler(value: boolean) {
      this.isOpenedBlock = value;
    },
    onChange() {
      const data = this.jsonFormState ? this.jsonFormState.core?.data : null;

      this.allData = { ...this.oldData, ...this.allData, ...data };

      this.changedData = getChangedData(this.allData, this.oldData);

      this.needConfirmation = Object.keys(this.changedData).length > 0;

      this.$emit('changeHandler', this.groupId, this.changedData);
    },
    getAllScopes(schema: CustomUISchemaElement): string[] {
      if (schema.elements) {
        return _.flattenDeep(schema.elements.map((
          item: CustomUISchemaElement,
        ) => this.getAllScopes(item)));
      }

      return [schema.scope ?? ''];
    },
    updateBlockHandler() {
      this.validateForm();

      if (this.errors && this.errors.length) {
        this.$emit('hasErrorsHandler', this.errors);

        return false;
      }

      this.isLoading = true;

      this.$emit('updateBlockHandler', this.groupId, this.dataBySchema, this.changedData, () => {
        this.setInitialState();
      });

      return true;
    },
    validateForm() {
      this.errors = validateData(this.dataBySchema, this.layout.schema, this.layout.uischema);

      if (this.jsonFormState && this.jsonFormState.core) {
        this.jsonFormState.core.additionalErrors = this.errors;
      }
    },
    getDataBySchema(data: JsonData, schema: JsonSchema): JsonData {
      return _.omitBy(
        data,
        (value, key: string) => !Object.prototype.hasOwnProperty.call(schema.properties, key),
      );
    },
  },
  setup(props: RendererProps<Layout>) {
    const jsonFormState = inject<JsonFormsSubStates>('jsonforms');
    const isOpenedBlock = ref(false);
    let oldData = ref<JsonData>({});

    if (jsonFormState) {
      jsonFormState.config.onlyText = true;
      jsonFormState.readonly = true;
      oldData = jsonFormState.core?.data;
    }

    return {
      ...useVanillaLayout(useJsonFormsLayout(props)),
      jsonFormState,
      oldData,
      isOpenedBlock,
    };
  },
});

export default layoutRenderer;

export const entry: JsonFormsRendererRegistryEntry = {
  renderer: layoutRenderer,
  tester: rankWith(2, and(isLayout, uiTypeIs('GeneralInformationGroup'))),
};
