
import { defineComponent, PropType } from 'vue';
import ManagerAvatar from '@/components/ui/icons/ManagerAvatar.vue';
import RightArrowIcon from '@/components/ui/icons/RightArrowIcon.vue';
import { CounterpartyManager } from '@/api/counterparty';

export default defineComponent({
  name: 'ManagerCounterparty',
  components: {
    ManagerAvatar,
    RightArrowIcon,
  },
  props: {
    manager: {
      type: Object as PropType<CounterpartyManager>,
      default: () => ({}),
    },
  },
});
