import { AxiosCallResponse } from '@/api/base';
import store from '@/store';
import EducationService, { EducationSchema } from '@/api/admin/dictionary/education';

export function useOneApi() {
  return {
    async loadData(id: string) {
      const countryApi: EducationService = store.getters.$api.admin.dictionary.education;

      const response: AxiosCallResponse<EducationSchema> = await countryApi.one(id);

      return response.data ?? null;
    },
  };
}

export default {};
