import config from '@/config';

export default {
  common: {
    yes: 'Да',
    no: 'Нет',
    noData: '–',
    isDeleted: 'Удалено',
    noDataToDisplay: 'Нет данных для отображения',
    search: 'Поиск',
    active: 'Активен',
    blocked: 'Заблокирован',
    notActive: 'Не активен',
  },
  buttons: {
    add: 'Добавить',
    characteristic: 'Характеристика',
    cancel: 'Отмена',
    save: 'Сохранить',
    create: 'Создать',
    notSave: 'Не сохранять',
    edit: 'Редактировать',
    send: 'Отправить',
    hide: 'Свернуть',
    delete: 'Удалить',
    show: 'Показать полностью...',
    history: 'История изменений',
    backToView: 'Вернуться к просмотру',
    apply: 'Применить',
    switch: 'Сменить разрез фильтрации',
    restore: 'Восстановить',
    logout: 'Выйти',
  },
  counterparty: {
    list: {
      all: 'Список контрагентов',
      goToCard: 'Перейти в карточку',
      title: 'Контрагенты',
      linkToManuallyForm: 'Не нашли нужного контрагента? Попробуйте заполнить его вручную.',
      modal: {
        create: {
          dangerModal: {
            confirmButton: 'Да, продолжить',
            text: 'При закрытии модального окна заполненные данные будут утеряны.',
            hintText: 'Вы действительно хотите отменить создание закрепления?',
          },
        },
      },
      filter: {
        filters: 'Фильтры',
        clear: 'Очистить фильтры',
        form: {
          industry: 'Отрасль',
          status: 'Статус',
          block: 'Блок',
          division: 'Дивизион',
          department: 'Подразделение',
          assignUser: 'Ответственный менеджер',
          region: 'Регион',
          address: 'Адрес',
          addressPlaceholder: 'Введите часть адреса',
          statuses: {
            [config.COUNTERPARTY_FILTER_STATUS_RESIDENT]: 'Резидент',
            [config.COUNTERPARTY_FILTER_STATUS_NOT_RESIDENT]: 'Нерезидент',
          },
          blocks: {
            [config.COUNTERPARTY_FILTER_BLOCK_WORKING]: 'Рабочий блок',
            [config.COUNTERPARTY_FILTER_BLOCK_FREE]: 'Свободный блок',
          },
        },
      },
    },
    card: {
      block: {
        finishEdit: 'Завершите редактирование блока "{block}"',
      },
      syncFieldError: {
        title: 'Данные синхронизированные с ошибками',
        info: 'Ошибки синхронизации, которые нужно проверить и исправить',
        button: 'Кофликты синхронизации',
      },
    },
    refresh: {
      dangerModal: {
        confirmButton: 'Да, продолжить',
        text: 'При обновлении страницы заполненные данные будут утеряны.',
        hintText: 'Вы уверены, что хотите продолжить?',
      },
    },
    header: {
      dangerModal: {
        confirmButton: 'Да, продолжить',
        text: 'При выходе с данной страницы заполненные данные будут утеряны.',
        hintText: 'Вы уверены, что хотите продолжить?',
      },
    },
    tabs: {
      dangerModal: {
        confirmButton: 'Да, продолжить',
        text: 'При переходе на другую вкладку заполненные данные будут утеряны.',
        hintText: 'Вы уверены, что хотите продолжить?',
      },
      relationship: {
        communication: {
          fields: {
            date: 'Дата контакта',
            status: 'Состояние контакта',
            place: 'Точка контакта',
            type: 'Вид контакта',
            user: 'Контактное лицо',
            duration: 'Продолжительность контакта',
            description: 'Описание контакта',
            content: 'Содержание контакта',
          },
          statuses: {
            completed: 'Завершено',
            planned: 'Запланировано',
            canceled: 'Отменено',
          },
        },
        conflictHistory: {
          date: 'Дата претензии',
          type: 'Тип претензии',
          user: 'Менеджер ОП',
        },
        salesSupportHistory: {
          date: 'Дата выезда',
          type: 'Вид поддержки',
          user: 'Менеджер ТПП',
        },
        seminarHistory: {
          date: 'Дата проведения',
          name: 'Наименование',
          point: 'Место проведения',
          filter: {
            invite: 'По дате приглашения',
            conducting: 'По дате проведения',
          },
        },
        relationshipBreak: {
          dateStart: 'Дата начала перерыва',
          dateEnd: 'Дата окончания перерыва',
          reason: 'Причина перерыва',
          resumptionPlan: 'План возобновления отношений',
          filter: {
            dateStart: 'По дате начала',
            dateEnd: 'По дате окончания',
          },
        },
      },
      general: {
        assignHistory: {
          fields: {
            assignmentDate: 'Дата закрепления',
            detachmentDate: 'Дата открепления',
            user: 'Пользователь',
            department: 'Отдел',
            reason: 'Причина',
          },
        },
      },
    },
    create: {
      title: 'Добавить нового контрагента',
      tabs: {
        manually: 'Вручную',
        search: 'Поиск',
      },
      form: {
        search: {
          input: 'Код MDM или ИНН',
          edit: 'Редактирование данных',
          isPriority: 'Приоритетные данные',
          placeholder: {
            input: 'Введите код MDM или ИНН',
          },
        },
      },
      buttons: {
        search: 'Получить данные',
        send: 'Создать',
        next: 'Далее',
        mobile: {
          search: 'Получить',
          send: 'Создать',
          next: 'Далее',
        },
      },
      error: {
        notFound: 'Не найдено ни одной записи',
        notFoundExpand: 'Не найдено ни одной записи, воспользуйтесь вкладкой "Вручную"',
      },
    },
    pinning: {
      datePin: 'Дата закрепления',
      dateUnpin: 'Дата открепления',
      manager: 'Менеджер',
      division: 'Подразделение продаж',
      cause: 'Причина',
      responsible: 'Ответственный за принятие решений',
      view: {
        title: 'Детальная информация',
      },
      buttons: {
        pinning: 'Закрепить',
      },
    },
    payment: {
      isBlock: 'Заблокирован',
      blockPopup: {
        title: 'Заблокировать расчетный счет?',
        description: 'Вы уверены, что хотите заблокировать выбранный расчетный счет?',
        confirmButton: 'Да, заблокировать',
      },
      unblockPopup: {
        title: 'Разблокировать расчетный счет?',
        description: 'Вы уверены, что хотите разблокировать выбранный расчетный счет?',
        confirmButton: 'Да, разблокировать',
      },
    },
    consumptionRegistry: {
      info: 'Заполните фильтры для загрузки данных или перейдите в режим редактирования.',
      infoEdit: 'Заполните фильтры для редактирования данных.',
      placeholders: {
        year: 'Год',
        group: 'Группа товаров',
      },
    },
  },
  counterparties: {
    list: 'Список контрагентов',
    goToCard: 'Перейти в карточку',
    changeData: 'Изменить данные',
    addRepresentative: 'Добавить представителя',
  },
  counterpartyLabels: {
    name: 'Наименование контрагента',
    fullName: 'Полное наименование контрагента',
    type: 'Тип контрагента',
    country: 'Страна',
    industry: 'Отрасль',
    marketStatus: 'Рыночный статус',
    view: 'Вид',
    legalAddress: 'Юридический адрес',
    actualAddress: 'Фактический адрес',
    taxNumber: 'Налоговый номер',
    inn: 'ИНН',
    taxNumberOrInn: 'Налоговый номер (ИНН)',
    kpp: 'КПП',
    codeMdm: 'Код MDM',
    division: 'Дивизион',
    regNumber: 'Рег. номер',
    registrationNumber: 'Регистрационный номер',
    contacts: 'Контакты',
    site: 'Сайт',
    department: 'Отдел продаж',
    manager: 'Менеджер',
    managers: 'Закрепленные менеджеры',
    inHolding: 'Входит в холдинг',
    headCompany: 'Головная компания',
    isHeadCompany: 'Является головной компанией',
    haventOwnVolume: 'Собственных объемов не имеет',
    ownDevelopedClient: 'Лично разработанный клиент',
    singlePurchasingCenter: 'Единый центр закупа',
  },
  agent: {
    add: 'Добавить агента влияния',
    modal: {
      create: {
        title: 'Добавить агента влияния',
      },
      detail: {
        form: {
          block: 'Заблокировать',
        },
      },
    },
    noData: 'Нет данных по агентам влияния',
  },
  modals: {
    removeItem: 'Вы уверены, что хотите удалить выбранную запись?',
    removeConfirm: 'Да, удалить',
    confirmation: {
      dangerModal: {
        confirmButton: 'Да, продолжить',
        text: 'При закрытии формы заполненные данные будут утеряны.',
        hintText: 'Вы уверены, что хотите продолжить?',
      },
    },
  },
  news: {
    newsTitle: 'Новости',
    addNews: 'Добавить новость',
    editNews: 'Редактировать новость',
    newsList: 'Новостей на странице',
    removeItem: 'Вы уверены, что хотите удалить новость?',
    dateLabel: 'Дата публикации в источнике',
    shortDescription: 'Краткое содержание',
  },
  newsAttributes: {
    publishedAt: 'Дата публикации',
    content: 'Краткое содержание',
    link: 'Ссылка',
  },
  errors: {
    notFoundNews: 'Нет ни одной новости',
    link: 'Невалидная ссылка',
    serverError: {
      text: 'Ошибка на сервере',
      subtext: 'На сервере произошла непредвиденная ошибка.',
    },
    accessDenied: {
      text: 'Доступ запрещен',
      subtext: 'У вас недостаточно прав для доступа к этой странице.',
    },
    notFound: {
      text: 'Страница не найдена',
      subtext: 'Страница, которую вы ищете, не существует.',
    },
  },
  billingNumber: {
    button: 'Заявка на добавление счета',
    newRequest: 'Заявка на добавление нового расчетного счета',
    newRequestShortLabel: 'Добавление расчетного счета',
    editRequest: 'Редактирование расчетного счета',
    formLabels: {
      name: 'Наименование расчетного счета',
      number: 'Номер расчетного счета',
      currency: 'Валюта',
      bank: 'Банк',
      bik: 'БИК',
      inn: 'ИНН',
    },
    tabs: {
      home: 'Действующие счета',
      alterHome: 'Счета',
      request: 'Заявки',
      archive: 'Архив',
    },
  },
  address: {
    labels: {
      postalCode: 'Индекс',
      country: 'Страна',
      province: 'Регион',
      locality: 'Город',
      area: 'Район',
      street: 'Улица',
      house: 'Дом',
      housing: 'Корпус',
      flat: 'Квартира',
    },
    shortLabels: {
      province: '',
      locality: 'г',
      area: 'р-н',
      street: 'ул',
      house: 'д',
      housing: 'корп',
      flat: 'кв',
    },
  },

  notification: {
    counterparty: {
      success: {
        add: 'Новый контрагент направлен на согласование',
        search: 'Контрагент создан',
        update: 'Блок сохранен',
        updateMdm: 'Данные MDM отправлены на согласование',
      },
      error: {
        add: 'Не удалось добавить нового контрагента',
        update: 'Не удалось сохранить введенные данные',
        updateMdm: 'Не удалось отправить на согласование данные MDM',
      },
    },
    agents: {
      success: {
        add: 'Агент влияния добавлен',
        remove: 'Агент влияния удален',
        create: 'Агент влияния добавлен',
        update: 'Агент влияния сохранен',
        block: 'Агент влияния заблокирован',
        unblock: 'Агент влияния разблокирован',
      },
      error: {
        add: 'Не удалось сохранить агента влияния',
        create: 'Не удалось сохранить агента влияния',
        remove: 'Не удалось удалить агента влияния',
        update: 'Не удалось обновить агента влияния',
        block: 'Не удалось заблокировать агента влияния',
        unblock: 'Не удалось разблокировать агента влияния',
      },
    },
    news: {
      success: {
        add: 'Новость добавлена',
        edit: 'Новость отредактирована',
        delete: 'Новость удалена',
      },
      error: {
        add: 'Не удалось добавить новость',
        edit: 'Не удалось отредактировать новость',
        delete: 'Не удалось удалить новость',
      },
    },
    admin: {
      dictionary: {
        success: {
          create: 'Запись успешно создана',
          update: 'Запись успешно отредактирована',
          delete: 'Запись успешно удалена',
          restore: 'Запись успешно восстановлена',
          block: 'Запись успешно заблокирована',
          unblock: 'Запись успешно разблокирована',
        },
      },
      user: {
        success: {
          update: 'Пользователь отредактирован',
          block: 'Пользователь заблокирован',
          unblock: 'Пользователь разблокирован',
        },
        error: {
          create: 'Не удалось создать пользователя',
          update: 'Не удалось отредактировать пользователя',
          changeStatus: 'Не удалось изменить статус пользователя',
        },
      },
      pinning: {
        success: {
          detach: 'Запись успешно откреплена',
          reassign: 'Запись успешно перезакреплена',
        },
      },
    },
    forms: {
      requiredError: 'Для добавления данной записи не хватает связанных значений для поля {field}. Заполните соответсвующий справочник.',
    },
  },
  admin: {
    user: {
      detail: {
        form: {
          block: 'Заблокировать',
          email: 'Email',
          firstName: 'Имя',
          middleName: 'Отчество',
          position: 'Должность',
          lastName: 'Фамилия',
          company: 'Компания',
          division: 'Дивизион',
          direction: 'Подразделение',
          phone: 'Телефон',
          placeholder: {
            email: 'Введите email учетной записи пользователя',
          },
        },
        role: {
          title: 'Права доступа',
          placeholders: {
            department: 'Выберите подразделение',
          },
        },
      },
      create: {
        title: 'Новый пользователь',
        buttons: {
          verify: 'Получить данные',
          create: 'Создать пользователя',
          mobile: {
            verify: 'Получить',
            create: 'Создать',
          },
        },
        error: {
          userNotFound: 'Пользователь не найден',
          userAlreadyExists: 'Пользователь уже существует',
        },
      },
      list: {
        title: 'Пользователи',
        table: {
          fio: 'ФИО',
          email: 'Email',
          division: 'Дивизион',
          role: 'Роль',
        },
        search: {
          division: 'Дивизион',
          role: 'Роль',
        },
      },
    },
  },
  dictionary: {
    general: {
      detail: 'Детальная информация',
      update: 'Редактировать',
      create: 'Создать',
    },
    country: {
      title: 'Страны',
      new: 'Новая страна',
      edit: 'Редактирование',
      table: {
        code: 'Код',
        shortName: 'Наименование',
        fullName: 'Полное наименование',
        alpha2: 'CodeAlpha2',
        alpha3: 'CodeAlpha3',
      },
      placeholders: {
        code: 'Введите код страны',
        shortName: 'Введите наименование',
        fullName: 'Введите полное наименование',
        alpha2: 'Введите буквенный код альфа-2',
        alpha3: 'Введите буквенный код альфа-3',
      },
    },
    structure: {
      title: 'Структура компании',
      table: {
        name: 'Наименование',
        fullName: 'Полное наименование',
        department: 'Отдел продаж',
        division: 'Дивизион',
        code: 'Код',
        assignmentStrategy: 'Стратегия закрепления',
      },
      view: {
        title: 'Детальная информация',
      },
      create: {
        addChild: 'Добавить дочернюю структуру',
        title: 'Добавить структуру',
      },
      dangerModal: {
        text: 'Вы уверены, что хотите удалить выбранную структуру?',
        confirmButton: 'Да, удалить',
      },
      assignmentStrategies: {
        oneAssignment: 'Одно закрепление на контрагента в подразделении',
        manyAssignments: 'Много закреплений на контрагента в подразделении',
      },
    },
    pinning: {
      title: 'Закрепление',
      counterpartyTitle: 'Данные контрагента',
      fields: {
        counterpartyType: 'Вид контрагента',
        counterparty: 'Контрагент',
        user: 'Менеджер',
        createdAt: 'Дата создания',
        division: 'Дивизион',
        department: 'Подразделение',
        detachmentDate: 'Дата открепления',
        result: 'Решение',
        region: 'Регион',
        nomenclature: 'Номенклатура',
        nomenclatureGroup: 'Номенклатурная группа',
        comment: 'Комментарий',
        reasonDetachment: 'Причина открепления',
        reasonReassignment: 'Причина перезакрепления',
        reasonComment: 'Комментарий к причине',
      },
      detach: 'Открепить запись',
      reassign: 'Перезакрепить запись',
      buttons: {
        detach: 'Открепить',
        reassign: 'Перезакрепить',
      },
    },
    company: {
      title: 'Компании',
      fields: {
        name: 'Наименование',
        code: 'Код',
        subdomain: 'Поддомен',
        admins: 'Администраторы',
        authorizationMethod: 'Метод авторизации',
      },
      placeholders: {
        name: 'Введите наименование',
        code: 'Введите код',
        subdomain: 'Введите поддомен',
        admins: 'Выберите администраторов',
        authorizationMethod: 'Выберите метод авторизации',
      },
    },
    category: {
      title: 'Категории контрагента',
      fields: {
        name: 'Наименование',
        shortName: 'Краткое наименование',
      },
      placeholders: {
        name: 'Введите наименование',
        shortName: 'Введите краткое наименование',
      },
    },
    bank: {
      title: 'Банки',
      fields: {
        name: 'Наименование',
        code: 'Код',
        bic: 'БИК',
        corrAccount: 'Корреспондентский счет',
        city: 'Город',
        address: 'Адрес',
        phone: 'Телефон',
      },
    },
    industry: {
      title: 'Отрасли',
      fields: {
        name: 'Наименование',
        description: 'Описание',
      },
      placeholders: {
        name: 'Введите наименование',
      },
    },
    psychologicalCharacteristic: {
      title: 'Психологические характеристики',
      fields: {
        type: 'Метапрограмма',
        value: 'Значение',
        interpretation: 'Интерпретация',
      },
      placeholders: {
        type: 'Выберите метапрограмму',
        value: 'Введите значение',
        interpretation: 'Введите интерпретацию',
      },
    },
    nomenclatureGroup: {
      title: 'Номенклатурные группы',
      fields: {
        name: 'Наименование',
        certificateName: 'Наименование по сертификату',
      },
      placeholders: {
        name: 'Введите наименование',
        certificateName: 'Введите наименование по сертификату',
      },
    },
    nomenclature: {
      title: 'Номенклатуры',
      fields: {
        name: 'Наименование',
        fullName: 'Полное наименование',
        codeMdm: 'Код MDM',
        groupId: 'Номенклатурная группа',
        measUnit: 'Единица измерения',
        vatRate: 'Ставка НДС',
        vendorCode: 'Артикул',
      },
    },
    bankAccount: {
      title: 'Банковские счета',
      fields: {
        name: 'Наименование',
        counterparty: 'Контрагент',
        account: 'Номер счета',
        bank: 'Банк',
        settlementsBank: 'Банк для непрямых расчетов',
        correspondent: 'Корреспондент',
        type: 'Вид',
        currency: 'Валюта',
        isGovContract: 'Государственный контракт',
      },
    },
    technologicalSupportType: {
      title: 'Виды технологической поддержки',
      fields: {
        name: 'Наименование',
      },
      placeholders: {
        name: 'Введите наименование',
      },
    },
    activityDirection: {
      title: 'Основные направления деятельности',
      fields: {
        name: 'Наименование',
      },
      placeholders: {
        name: 'Введите наименование',
      },
    },
    position: {
      title: 'Должности',
      fields: {
        name: 'Наименование',
      },
      placeholders: {
        name: 'Введите наименование',
      },
    },
    education: {
      title: 'Образование',
      fields: {
        name: 'Наименование',
      },
      placeholders: {
        name: 'Введите наименование',
      },
    },
    individual: {
      title: 'Физические лица',
      fields: {
        name: 'Наименование',
        counterparty: 'Контрагент',
        inn: 'ИНН',
        ifts: 'Код ИФНС',
        birthday: 'День рождения',
      },
    },
    region: {
      title: 'Регионы',
      fields: {
        name: 'Наименование',
      },
    },
  },
  history: {
    label: 'История',
    date: 'Дата',
    responsible: 'Ответственный',
    status: 'Статус',
    changes: 'Изменения',
    detail: {
      main: 'Дата и ответственный',
      oldValue: 'Старое значение',
      newValue: 'Новое значение',
    },
    dangerModal: {
      text: 'Вы уверены, что хотите удалить выбранную запись?',
      confirmButton: 'Да, удалить',
    },
  },
  jsonforms: {
    buttons: {
      editMdmData: 'Редактировать данные MDM',
      cancel: 'Отмена',
      send: 'Отправить заявку',
      edit: 'Редактировать',
      history: 'История изменений',
    },
    generalInformation: {
      mdm: {
        title: 'Данные MDM',
      },
    },
    tableOfMonths: {
      month: 'Месяц',
      months: {
        january: 'Январь',
        february: 'Февраль',
        march: 'Март',
        april: 'Апрель',
        may: 'Май',
        june: 'Июнь',
        july: 'Июль',
        august: 'Август',
        september: 'Сентябрь',
        october: 'Октябрь',
        november: 'Ноябрь',
        december: 'Декабрь',
      },
      monthlyAverage: 'Среднемесячный показатель',
      monthlyAmount: 'Суммарный показатель',
    },
  },
  ui: {
    select: {
      search: {
        notFound: 'Не найдено ни одной записи',
        loading: 'Загрузка данных...',
      },
    },
  },
  sidebar: {
    title: {
      counterparties: 'Контрагенты',
      users: 'Пользователи',
      pinning: 'Закрепления',
      countries: 'Страны',
      structure: 'Структура компании',
      companies: 'Компании',
      categories: 'Категории контрагента',
      banks: 'Банки',
      industries: 'Отрасли',
      bankAccounts: 'Банковские счета',
      psychologicalCharacteristics: 'Психологические характеристики',
      nomenclatureGroup: 'Номенклатурные группы',
      technologicalSupportType: 'Виды технологической поддержки',
      position: 'Должности',
      activityDirection: 'Основные направления деятельности',
      education: 'Образование',
      individual: 'Физические лица',
      nomenclature: 'Номенклатуры',
      region: 'Регионы',
      profile: 'Профиль',
    },
  },
  tour: {
    closeButton: 'Закрыть',
    prevButton: 'Назад',
    nextButton: 'Далее',
    doneButton: 'Завершить',
  },
};
