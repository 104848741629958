import { Module } from 'vuex';
import list, { StructureListStateType } from '@/store/modules/admin/dictionary/structure/list';
import one, { StructureOneStateType } from '@/store/modules/admin/dictionary/structure/one';
import division, { DivisionListStateType } from '@/store/modules/admin/dictionary/structure/division';
import { StateType } from '@/store';

export interface StructureStateType {
  list: StructureListStateType,
  one: StructureOneStateType,
  division: DivisionListStateType,
}

const directionModule: Module<StructureListStateType, StateType> = {
  namespaced: true,
  modules: {
    list,
    one,
    division,
  },
};

export default directionModule;
