
import { ControlElement, JsonFormsRendererRegistryEntry, rankWith } from '@jsonforms/core';
import { RendererProps, rendererProps, useJsonFormsControl } from '@jsonforms/vue';
import { useVanillaControl } from '@jsonforms/vue-vanilla';
import { defineComponent, ref, Ref } from 'vue';
import { controlWrapper, validationMethods } from '@/components/jsonforms/util/renderer';
import AddressDefault, { AddressData } from '@/components/ui/address/AddressDefault.vue';
import { isAddressControl } from '@/components/jsonforms/util/testers';
import config from '@/config';
import _ from 'lodash';
import ControlWrapper from './ControlWrapper.vue';

const controlRenderer = defineComponent({
  name: 'address-control-renderer',
  components: {
    ControlWrapper,
    AddressDefault,
  },
  props: {
    ...rendererProps<ControlElement>(),
  },
  watch: {
    controlWrapper(value, oldValue) {
      if (value.errors !== oldValue.errors) {
        this.errors = value.errors;
      }
    },
  },
  computed: {
    preparedControlWrapper() {
      return controlWrapper(this);
    },
    formattedData() {
      const fieldsKeys = Object.keys(this.appliedOptions.mapper.fields);
      const getFieldValue = (key: string) => this.appliedOptions.mapper.fields[key] ?? null;

      const fields: { [key: string]: string } = {};
      const text: string | null = this.control.data?.[this.appliedOptions.mapper.text] ?? null;

      fieldsKeys.forEach((key: string) => {
        fields[key] = this.control.data?.[getFieldValue(key)] ?? null;
      });

      return { text, fields };
    },
    textData() {
      return this.control.data?.[config.ADDRESS_MAPPER_FIELD_TEXT]
        ? this.control.data?.[config.ADDRESS_MAPPER_FIELD_TEXT]
        : this.$t('common.noData');
    },
  },
  setup(props: RendererProps<ControlElement>) {
    const vanillaControl = useVanillaControl(useJsonFormsControl(props));
    const control = vanillaControl.control as unknown as Ref;
    const errors = ref(vanillaControl.controlWrapper?.value?.errors ?? '');
    const debounceTime = config.CONTROL_DEBOUNCE ?? 500;
    const validMethods = validationMethods(control);

    const getJsonValidData = (data: AddressData) => {
      const fieldsKeys = Object.keys(vanillaControl.appliedOptions.value.mapper.fields);
      const getFieldValue = (
        key: string,
      ) => vanillaControl.appliedOptions.value.mapper.fields[key] ?? null;

      const fields: { [key: string]: string } = {};

      fieldsKeys.forEach((key: string) => {
        fields[getFieldValue(key)] = data.fields[key] ?? null;
      });

      return {
        [vanillaControl.appliedOptions.value.mapper.text]: data.text,
        ...fields,
      };
    };

    const debounceChangeHandler = _.debounce((addressData: AddressData) => {
      const localValue: object | undefined = !addressData && validMethods.isRequired()
        ? undefined
        : getJsonValidData(addressData);

      vanillaControl.handleChange(control.value.path, localValue);
    }, debounceTime);

    return {
      errors,
      ...vanillaControl,
      ...validationMethods(control),
      debounceChangeHandler,
      getJsonValidData,
    };
  },
});

export default controlRenderer;

export const entry: JsonFormsRendererRegistryEntry = {
  renderer: controlRenderer,
  tester: rankWith(1, isAddressControl),
};
