import _ from 'lodash';
import { Module } from 'vuex';
import { AxiosCallResponse } from '@/api/base';
import { TYPE_LABEL_SUCCESS } from '@/components/ui/notifications/TheAlert/TheAlert.vue';
import i18n from '@/i18n';
import BankService, { BankData } from '@/api/admin/dictionary/bank';
import { StateType } from '@/store';

export interface BankOneStateType {
  data: BankData | null,
}

const initialState = {
  data: null,
};

const bankOneModule: Module<BankOneStateType, StateType> = {
  namespaced: true,
  state: _.cloneDeep(initialState),
  getters: {
    data: (state): BankData | null => state.data,
  },
  mutations: {
    setData(state, item: BankData) {
      state.data = item;
    },
  },
  actions: {
    async loadData({ rootGetters, commit }, id: string) {
      const api: BankService = rootGetters.$api.admin.dictionary.bank;

      const response: AxiosCallResponse<BankData> = await api.one(id);

      commit('setData', response.data ?? initialState.data);
    },
    async create({ rootGetters }, data: BankData) {
      const api: BankService = rootGetters.$api.admin.dictionary.bank;

      const response: AxiosCallResponse<BankData> = await api.create(data);

      if (!response.error) {
        await rootGetters
          .$notificationLogger
          .setNotification(
            TYPE_LABEL_SUCCESS,
            i18n.global.t('notification.admin.dictionary.success.create'),
          );
      }
    },
    async update(
      { rootGetters },
      params: { id: string, data: BankData },
    ) {
      const api: BankService = rootGetters.$api.admin.dictionary.bank;

      const response: AxiosCallResponse<null> = await api.update(params.id, params.data);

      if (!response.error) {
        await rootGetters
          .$notificationLogger
          .setNotification(
            TYPE_LABEL_SUCCESS,
            i18n.global.t('notification.admin.dictionary.success.update'),
          );
      }
    },
    async delete({ rootGetters }, id: string) {
      const api: BankService = rootGetters.$api.admin.dictionary.bank;

      const response: AxiosCallResponse<null> = await api.delete(id);

      if (!response.error) {
        await rootGetters
          .$notificationLogger
          .setNotification(
            TYPE_LABEL_SUCCESS,
            i18n.global.t('notification.admin.dictionary.success.delete'),
          );
      }
    },
    async restore({ rootGetters }, id: string) {
      if (id) {
        const api: BankService = rootGetters.$api.admin.dictionary.bank;

        const response: AxiosCallResponse<null> = await api.restore(id);

        if (!response.error) {
          await rootGetters
            .$notificationLogger
            .setNotification(
              TYPE_LABEL_SUCCESS,
              i18n.global.t('notification.admin.dictionary.success.restore'),
            );
        }
      }
    },
  },
};

export default bankOneModule;
