import { Module } from 'vuex';
import one, { PinningOneStateType } from '@/store/modules/admin/pinning/one';
import list, { PinningListStateType } from '@/store/modules/admin/pinning/list';
import { StateType } from '@/store';
import managers, { AssignManagerStateType } from '@/store/modules/admin/pinning/managers';

export interface PinningStateType {
  list: PinningListStateType,
  one: PinningOneStateType,
  managers: AssignManagerStateType,
}

const pinningModule: Module<PinningListStateType, StateType> = {
  namespaced: true,
  modules: {
    list,
    one,
    managers,
  },
};

export default pinningModule;
