
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MonthItem',
  props: {
    data: {
      type: String,
      default: '',
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
    isCurrent: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    monthClass() {
      return {
        selected: this.isSelected,
        current: this.isCurrent,
      };
    },
  },
});
