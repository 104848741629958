import { Module } from 'vuex';
import { StateType } from '@/store';
import list, { IndividualListStateType } from '@/store/modules/admin/dictionary/individual/list';
import one, { IndividualOneStateType } from '@/store/modules/admin/dictionary/individual/one';

export interface IndividualStateType {
  list: IndividualListStateType,
  one: IndividualOneStateType,
}

const individualModule: Module<IndividualStateType, StateType> = {
  namespaced: true,
  modules: {
    list,
    one,
  },
};

export default individualModule;
