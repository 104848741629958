import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-32000d53"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "toggle-tooltip" }
const _hoisted_2 = {
  key: 0,
  class: "tooltip-content",
  ref: "calculatedBlock"
}
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_info_icon = _resolveComponent("info-icon")!
  const _directive_calculate_position = _resolveDirective("calculate-position")!
  const _directive_outside_click = _resolveDirective("outside-click")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      ref: "mainBlock",
      class: "icon-btn",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleTooltip && _ctx.toggleTooltip(...args)))
    }, [
      _renderSlot(_ctx.$slots, "icon", {}, () => [
        _createVNode(_component_info_icon, { class: "w-[16px] h-[16px]" })
      ], true)
    ], 512),
    (_ctx.isOpen)
      ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.text)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                innerHTML: _ctx.formattedText,
                class: "tooltip-content__description"
              }, null, 8, _hoisted_3))
            : _createCommentVNode("", true)
        ])), [
          [_directive_calculate_position]
        ])
      : _createCommentVNode("", true)
  ])), [
    [_directive_outside_click, _ctx.closeTooltip]
  ])
}