import { vModelText as _vModelText, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1531246a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "datepicker",
  ref: "mainBlock"
}
const _hoisted_2 = ["disabled", "placeholder"]
const _hoisted_3 = {
  key: 0,
  class: "datepicker-layout",
  ref: "calculatedBlock"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_date_icon = _resolveComponent("date-icon")!
  const _component_datepicker_layout = _resolveComponent("datepicker-layout")!
  const _directive_mask = _resolveDirective("mask")!
  const _directive_calculate_position = _resolveDirective("calculate-position")!
  const _directive_outside_click = _resolveDirective("outside-click")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "datepicker-input",
      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.open && _ctx.open(...args)))
    }, [
      _withDirectives(_createElementVNode("input", {
        type: "text",
        class: _normalizeClass(["input", { error: _ctx.error }]),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
        disabled: _ctx.disabled,
        placeholder: _ctx.placeholder,
        onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.changeInputHandler && _ctx.changeInputHandler(...args)))
      }, null, 42, _hoisted_2), [
        [_vModelText, _ctx.inputValue],
        [_directive_mask, '##.##.####']
      ]),
      _createVNode(_component_date_icon, {
        type: "outline",
        width: "20",
        height: "20",
        class: "datepicker-icon"
      })
    ]),
    (_ctx.isOpen)
      ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_datepicker_layout, {
            date: _ctx.dateValue,
            onChange: _ctx.changeDateHandler
          }, null, 8, ["date", "onChange"])
        ])), [
          [_directive_calculate_position]
        ])
      : _createCommentVNode("", true)
  ])), [
    [_directive_outside_click, _ctx.close]
  ])
}