
import { defineComponent, PropType } from 'vue';
import BlockDefault from '@/components/ui/blocks/BlockDefault/BlockDefault.vue';
import { JsonSchema } from '@jsonforms/core';
import CustomUISchemaElement from '@/components/jsonforms/interfaces/CustomUISchemaElement';
import { ErrorObject } from 'ajv';
import { JsonFormsChangeEvent } from '@jsonforms/vue';
import DefaultButton from '@/components/ui/buttons/DefaultButton/DefaultButton.vue';
import IconButton from '@/components/ui/buttons/IconButton/IconButton.vue';
import EditIcon from '@/components/ui/icons/EditIcon.vue';
import TheFilter, {
  TYPE_SLICE_GROUP,
  TYPE_SLICE_YEAR,
} from '@/components/counterparty/card/blocks/registry/consumption-registry/filter/TheFilter.vue';
import { mapActions, mapGetters } from 'vuex';
import useSelectDataHelper from '@/composables/helpers/select/useSelectDataHelper';
import useDateHelper from '@/composables/helpers/date/useDateHelper';
import { DataType } from '@/components/ui/selects/SelectDefault/SelectDefault.vue';
import DefaultLoader from '@/components/ui/loaders/DefaultLoader.vue';
import { ConsumptionFilter } from '@/store/modules/counterparty/block/registry/consumptionRegistry/one';
import { getChangedData } from '@/components/jsonforms/util/util';

export default defineComponent({
  name: 'TheConsumptionRegistry',
  components: {
    DefaultLoader,
    TheFilter,
    EditIcon,
    IconButton,
    DefaultButton,
    BlockDefault,
  },
  data() {
    return {
      isLoading: true,
      filterType: TYPE_SLICE_GROUP,
      needConfirmation: false,
      isEdit: false,
      currentData: {},
      errors: [] as ErrorObject[],
    };
  },
  props: {
    title: String,
    schema: Object as PropType<JsonSchema>,
    uischema: Object as PropType<CustomUISchemaElement>,
    isCanEdit: Boolean,
  },
  computed: {
    ...mapGetters({
      data: 'counterparty/block/consumptionRegistry/one/data',
      filterState: 'counterparty/block/consumptionRegistry/info/filterState',
      filter: 'counterparty/block/consumptionRegistry/one/filter',
      currentCardId: 'counterparty/card/currentCardId',
      nomenclatureGroup: 'admin/dictionary/nomenclatureGroup/list/items',
    }),
    isShowTable() {
      return this.filter?.year && this.filter?.group;
    },
    yearDataList() {
      const yearsList = this.dateHelper.getYearPeriodList(2000);

      return yearsList.map((item: string) => ({
        id: item,
        name: item,
      }));
    },
    preparedYearList() {
      let yearList = this.yearDataList;

      if (!this.isEdit && this.filterType === TYPE_SLICE_GROUP) {
        if (this.filter?.group) {
          const years = this.filterState.existingGroups?.[this.filter.group];

          yearList = yearList.filter((item: DataType) => years.includes(Number(item.id)));
        } else {
          return [];
        }
      }

      return this.isEdit
        ? yearList
        : yearList.filter((
          item: DataType,
        ) => this.filterState.existingYears?.[item.id ?? ''] ?? false);
    },
    preparedNomenclatureGroup() {
      let nomenclatureList = this.selectHandler.prepareData(this.nomenclatureGroup ?? []);

      if (!this.isEdit && this.filterType === TYPE_SLICE_YEAR) {
        if (this.filter?.year) {
          const nomenclatures = this.filterState.existingYears?.[this.filter.year];

          nomenclatureList = nomenclatureList.filter(
            (item: DataType) => nomenclatures.includes(item.id),
          );
        } else {
          return [];
        }
      }

      return this.isEdit
        ? nomenclatureList
        : nomenclatureList.filter((
          item: DataType,
        ) => this.filterState.existingGroups?.[item.id ?? ''] ?? false);
    },
  },
  methods: {
    ...mapActions({
      loadFilterState: 'counterparty/block/consumptionRegistry/info/loadFilterState',
      loadData: 'counterparty/block/consumptionRegistry/one/loadData',
      saveData: 'counterparty/block/consumptionRegistry/one/save',
      loadNomenclatureGroup: 'admin/dictionary/nomenclatureGroup/list/loadItems',
      changeFilter: 'counterparty/block/consumptionRegistry/one/changeFilter',
      resetState: 'counterparty/block/consumptionRegistry/one/resetState',
    }),
    onChange(event: JsonFormsChangeEvent) {
      const changedData = getChangedData(event.data, this.data);

      this.needConfirmation = Object.keys(changedData).length > 0;

      this.currentData = event.data;
    },
    closeEdit() {
      this.isEdit = false;
      this.resetState();
    },
    toggleEdit() {
      this.isEdit = true;
    },
    async changeFilterHandler(filter: ConsumptionFilter) {
      this.isLoading = true;

      await this.changeFilter(filter);
      await this.loadData(this.currentCardId);

      this.currentData = { ...this.data };

      this.isLoading = false;
    },
    async saveDataHandler() {
      await this.saveData({
        cardId: this.currentCardId,
        data: this.currentData,
      });
      await this.loadFilterState(this.currentCardId);
      await this.loadData(this.currentCardId);

      this.isEdit = false;
    },
    switchFilterTypeHandler(value: string) {
      this.filterType = value;
    },
  },
  async created() {
    await this.loadFilterState(this.currentCardId);
    await this.loadNomenclatureGroup();
  },
  unmounted() {
    this.resetState();
  },
  setup() {
    const selectHandler = useSelectDataHelper();
    const dateHelper = useDateHelper();

    return { selectHandler, dateHelper };
  },
});
