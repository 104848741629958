
import {
  isLayout,
  JsonFormsRendererRegistryEntry,
  rankWith,
} from '@jsonforms/core';
import {
  DispatchRenderer, RendererProps, rendererProps, useJsonFormsLayout,
} from '@jsonforms/vue';
import { useVanillaLayout } from '@jsonforms/vue-vanilla';
import { defineComponent } from 'vue';
import CustomUISchemaLayout from '@/components/jsonforms/interfaces/CustomUISchemaLayout';

const layoutRenderer = defineComponent({
  name: 'layout-renderer',
  components: {
    DispatchRenderer,
  },
  props: {
    ...rendererProps<CustomUISchemaLayout>(),
  },
  setup(props: RendererProps<CustomUISchemaLayout>) {
    return useVanillaLayout(useJsonFormsLayout(props));
  },
  computed: {
    layoutClassObject() {
      return this.layout.direction === 'row'
        ? this.styles.horizontalLayout
        : this.styles.verticalLayout;
    },
  },
});

export default layoutRenderer;

export const entry: JsonFormsRendererRegistryEntry = {
  renderer: layoutRenderer,
  tester: rankWith(1, isLayout),
};
