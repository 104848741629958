import { entry as stringControlRendererEntry } from './StringControlRenderer.vue';
import { entry as booleanControlRendererEntry } from './BooleanControlRenderer.vue';
import { entry as enumControlRendererEntry } from './EnumControlRenderer.vue';
import { entry as enumOneOfControlRendererEntry } from './EnumOneOfControlRenderer.vue';
import { entry as dateControlRendererEntry } from './DateControlRenderer.vue';
import { entry as dateTimeControlRendererEntry } from './DateTimeControlRenderer.vue';
import { entry as timeControlRendererEntry } from './TimeControlRenderer.vue';
import { entry as integerControlRendererEntry } from './IntegerControlRenderer.vue';
import { entry as numberControlRendererEntry } from './NumberControlRenderer.vue';
import { entry as multiStringControlRendererEntry } from './MultiStringControlRenderer.vue';
import { entry as multiEnumControlRendererEntry } from './MultiEnumControlRenderer.vue';
import { entry as phoneControlRendererEntry } from './PhoneControlRenderer.vue';
import { entry as multiTreeControlRendererEntry } from './MultiTreeControlRenderer.vue';
import { entry as addressControlRendererEntry } from './AddressControlRenderer.vue';
import { entry as ajaxControlRendererEntry } from './AjaxControlRenderer.vue';

export {
  default as ControlWrapperRenderer,
} from './ControlWrapper.vue';
export {
  default as StringControlRenderer,
} from './StringControlRenderer.vue';
export {
  default as BooleanControlRenderer,
} from './BooleanControlRenderer.vue';
export {
  default as EnumControlRenderer,
} from './EnumControlRenderer.vue';
export {
  default as EnumOneOfControlRenderer,
} from './EnumOneOfControlRenderer.vue';
export {
  default as DateControlRenderer,
} from './DateControlRenderer.vue';
export {
  default as DateTimeControlRenderer,
} from './DateTimeControlRenderer.vue';
export {
  default as TimeControlRenderer,
} from './TimeControlRenderer.vue';
export {
  default as IntegerControlRenderer,
} from './IntegerControlRenderer.vue';
export {
  default as NumberControlRenderer,
} from './NumberControlRenderer.vue';
export {
  default as MultiStringControlRenderer,
} from './MultiStringControlRenderer.vue';
export {
  default as MultiEnumControlRenderer,
} from './MultiEnumControlRenderer.vue';
export {
  default as PhoneControlRenderer,
} from './PhoneControlRenderer.vue';
export {
  default as AddressControlRenderer,
} from './AddressControlRenderer.vue';
export {
  default as AjaxControlRenderer,
} from './AjaxControlRenderer.vue';
export {
  default as MultiTreeControlRenderer,
} from './MultiTreeControlRenderer.vue';

export const controlRenderers = [
  ajaxControlRendererEntry,
  phoneControlRendererEntry,
  multiTreeControlRendererEntry,
  multiEnumControlRendererEntry,
  stringControlRendererEntry,
  booleanControlRendererEntry,
  enumControlRendererEntry,
  enumOneOfControlRendererEntry,
  dateTimeControlRendererEntry,
  timeControlRendererEntry,
  dateControlRendererEntry,
  integerControlRendererEntry,
  numberControlRendererEntry,
  multiStringControlRendererEntry,
  addressControlRendererEntry,
];
