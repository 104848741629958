import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-382289e9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "filter-block" }
const _hoisted_2 = { class: "filter-block__fields-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_default = _resolveComponent("select-default")!
  const _component_datepicker_period = _resolveComponent("datepicker-period")!
  const _component_search_input = _resolveComponent("search-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_select_default, {
        class: "filter-block__fields-list-item",
        data: _ctx.dateData,
        value: _ctx.filter.typeDate,
        clearable: false,
        onValueSelect: _ctx.changeTypeHandler
      }, null, 8, ["data", "value", "onValueSelect"]),
      _createVNode(_component_datepicker_period, {
        class: "filter-block__fields-list-item",
        value: _ctx.filter.date,
        onChange: _ctx.changeDateHandler
      }, null, 8, ["value", "onChange"]),
      _createVNode(_component_search_input, {
        class: "filter-block__fields-list-item",
        placeholder: _ctx.$t('common.search'),
        onInput: _ctx.changeSearch,
        onFilterCleared: _ctx.clearFilter
      }, null, 8, ["placeholder", "onInput", "onFilterCleared"])
    ])
  ]))
}