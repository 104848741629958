import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    width: _ctx.width,
    height: _ctx.height,
    viewBox: "0 0 20 20",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      d: "M17 19V3C17 2.46957 16.7893 1.96086 16.4142 1.58579C16.0391\n 1.21071 15.5304 1 15 1H5C4.46957 1 3.96086 1.21071 3.58579\n 1.58579C3.21071 1.96086 3 2.46957 3 3V19M17 19H3M17 19H19M17\n 19H12M3 19H1M3 19H8M12 19V14C12 13.7348 11.8946 13.4804\n 11.7071 13.2929C11.5196 13.1054 11.2652 13 11 13H9C8.73478\n 13 8.48043 13.1054 8.29289 13.2929C8.10536 13.4804 8 13.7348\n 8 14V19M12 19H8M7 5H8M7 9H8M12 5H13M12 9H13",
      stroke: _ctx.color,
      "stroke-width": "2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, 8, _hoisted_2)
  ], 8, _hoisted_1))
}