import { Module } from 'vuex';
import list, { BankAccountListStateType } from '@/store/modules/admin/dictionary/bank-account/list';
import one, { BankAccountOneStateType } from '@/store/modules/admin/dictionary/bank-account/one';
import { StateType } from '@/store';

export interface BankAccountStateType {
  list: BankAccountListStateType,
  one: BankAccountOneStateType,
}

const bankAccountModule: Module<BankAccountStateType, StateType> = {
  namespaced: true,
  modules: {
    list,
    one,
  },
};

export default bankAccountModule;
