
import { defineComponent, PropType } from 'vue';
import TheTooltip from '@/components/ui/modal/TheTooltip/TheTooltip.vue';

type ButtonType = 'default' | 'primary';
type BorderType = 'default' | 'primary' | 'non-border';

export default defineComponent({
  name: 'IconButton',
  inject: ['$sizeWindow'],
  components: {
    TheTooltip,
  },
  props: {
    type: {
      type: String as PropType<ButtonType>,
      default: '',
    },
    border: {
      type: String as PropType<BorderType>,
      default: 'non-border',
    },
    description: String,
    disabled: Boolean,
    showBadge: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isPress: false,
    };
  },
  computed: {
    classList() {
      return {
        press: this.isPress,
        primary: this.type === 'primary',
        'border-default': this.border === 'default',
        'border-primary': this.border === 'primary',
        'non-border': this.border === 'non-border',
      };
    },
    vOnList() {
      return {
        touchstart: this.pressed,
        touchend: this.unpressed,
        touchcancel: this.unpressed,
      };
    },
  },
  methods: {
    pressed() {
      this.isPress = true;
    },
    unpressed() {
      this.isPress = false;
    },
  },
});
