<template>
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd" clip-rule="evenodd"
      d="M16 8.59998C16 10.7217 15.1571 12.7565 13.6569 14.2568C12.1566 15.7571 10.1217 16.6
 8 16.6C5.87827 16.6 3.84344 15.7571 2.34315 14.2568C0.842855 12.7565 0 10.7217 0 8.59998C0
 6.47824 0.842855 4.44341 2.34315 2.94312C3.84344 1.44283 5.87827 0.599976 8 0.599976C10.1217
 0.599976 12.1566 1.44283 13.6569 2.94312C15.1571 4.44341 16 6.47824 16 8.59998ZM9 4.59998C9
 4.86519 8.89464 5.11955 8.70711 5.30708C8.51957 5.49462 8.26522 5.59998 8 5.59998C7.73478
 5.59998 7.48043 5.49462 7.29289 5.30708C7.10536 5.11955 7 4.86519 7 4.59998C7 4.33476
 7.10536 4.08041 7.29289 3.89287C7.48043 3.70533 7.73478 3.59998 8 3.59998C8.26522 3.59998
 8.51957 3.70533 8.70711 3.89287C8.89464 4.08041 9 4.33476 9 4.59998ZM7 7.59998C6.73478
 7.59998 6.48043 7.70533 6.29289 7.89287C6.10536 8.08041 6 8.33476 6 8.59998C6 8.86519
 6.10536 9.11955 6.29289 9.30708C6.48043 9.49462 6.73478 9.59998 7 9.59998V12.6C7 12.8652
 7.10536 13.1195 7.29289 13.3071C7.48043 13.4946 7.73478 13.6 8 13.6H9C9.26522 13.6 9.51957
 13.4946 9.70711 13.3071C9.89464 13.1195 10 12.8652 10 12.6C10 12.3348 9.89464 12.0804 9.70711
 11.8929C9.51957 11.7053 9.26522 11.6 9 11.6V8.59998C9 8.33476 8.89464 8.08041 8.70711
 7.89287C8.51957 7.70533 8.26522 7.59998 8 7.59998H7Z"
      fill="#0369A1"
    />
  </svg>
</template>
