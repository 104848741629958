import _ from 'lodash';
import { Module } from 'vuex';
import { StateType } from '@/store';

interface NotificationDataType {
  id: string
  type: string,
  message: string
}

interface NewNotificationDataType {
  type: string,
  message: string
}

export interface NotificationsStateType {
  notifications: NotificationDataType[];
}

const initialState = {
  notifications: [],
};

const notificationsModule: Module<NotificationsStateType, StateType> = {
  namespaced: true,
  state: initialState,
  getters: {
    notifications: (state): NotificationDataType[] => state.notifications,
  },
  mutations: {
    addNewNotification(state, notification: NewNotificationDataType) {
      const newNotification = {
        id: _.uniqueId(),
        ...notification,
      };

      state.notifications = [...state.notifications, newNotification];
    },
    deleteNotification(state, id: string) {
      const element = state.notifications.find((item) => item.id === id);
      if (element) {
        const index = state.notifications.indexOf(element);
        state.notifications.splice(index, 1);
      }
    },
  },
  actions: {
    newNotification({ commit }, notification: NewNotificationDataType) {
      commit('addNewNotification', notification);
    },
    deleteNotification({ commit }, id: string) {
      commit('deleteNotification', id);
    },
  },
};

export default notificationsModule;
