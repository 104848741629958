
import { defineComponent, PropType } from 'vue';
import { JsonSchema } from '@jsonforms/core';
import { mapActions, mapGetters } from 'vuex';
import TheListBlockTemplate from '@/components/counterparty/card/blocks/list-block-template/TheListBlockTemplate.vue';
import CustomUISchemaElement from '@/components/jsonforms/interfaces/CustomUISchemaElement';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import productionIndicators from '@/shared/consts/blocks/characteristic/productionIndicators';
import { PaginationParams } from '@/api/base';

export default defineComponent({
  name: 'TheProductionIndicators',
  components: { TheListBlockTemplate },
  props: {
    title: String,
    uischema: Object as PropType<CustomUISchemaElement>,
    schema: Object as PropType<JsonSchema>,
    isCanEdit: Boolean,
  },
  data() {
    return {
      fieldList: [
        productionIndicators.CHARACTERISTIC,
        productionIndicators.TYPE_OF_EQUIPMENT,
      ],
    };
  },
  computed: {
    ...mapGetters({
      items: 'counterparty/block/productionIndicators/list/items',
      pagination: 'counterparty/block/productionIndicators/list/pagination',
      currentCardId: 'counterparty/card/currentCardId',
    }),
  },
  methods: {
    ...mapActions({
      loadItems: 'counterparty/block/productionIndicators/list/loadItems',
      changePagination: 'counterparty/block/productionIndicators/list/changePagination',
      create: 'counterparty/block/productionIndicators/one/create',
      update: 'counterparty/block/productionIndicators/one/update',
      delete: 'counterparty/block/productionIndicators/one/delete',
    }),
    async changePaginationHandler(pagination: PaginationParams) {
      await this.changePagination(pagination);

      await this.loadItems(this.currentCardId);
    },
    async createHandler(data: JsonData, next: () => void) {
      await this.create({
        cardId: this.currentCardId,
        data,
      });
      await this.loadItems(this.currentCardId);

      next();
    },
    async updateHandler(id: string, data: JsonData, next: () => void) {
      await this.update({
        cardId: this.currentCardId,
        id,
        data,
      });
      await this.loadItems(this.currentCardId);

      next();
    },
    async deleteHandler(id: string) {
      await this.delete({
        cardId: this.currentCardId,
        id,
      });
      await this.loadItems(this.currentCardId);
    },
  },
  async created() {
    await this.loadItems(this.currentCardId);
  },
});
