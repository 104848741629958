import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "modal-footer" }
const _hoisted_2 = { class: "text-[14px]" }
const _hoisted_3 = { class: "text-[14px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_create_form = _resolveComponent("create-form")!
  const _component_default_button = _resolveComponent("default-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_create_form, {
      ref: "form",
      schema: _ctx.schema
    }, null, 8, ["schema"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_default_button, {
        height: "40",
        onClick: _ctx.cancelHandler
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('buttons.cancel')), 1)
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_default_button, {
        type: "primary",
        height: "40",
        disabled: _ctx.isLoading,
        onClick: _ctx.submitHandler
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t(`counterparty.create.buttons.${_ctx.$sizeWindow.isSm ? 'mobile.' : ''}next`)), 1)
        ]),
        _: 1
      }, 8, ["disabled", "onClick"])
    ])
  ]))
}