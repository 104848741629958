
import { defineComponent } from 'vue';
import TheTooltip from '@/components/ui/modal/TheTooltip/TheTooltip.vue';
import ErrorIcon from '@/components/ui/icons/notifications/ErrorIcon.vue';

export default defineComponent({
  name: 'CanceledIcon',
  components: {
    TheTooltip,
    ErrorIcon,
  },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    color() {
      return this.isActive ? '#B91C1C' : undefined;
    },
  },
});
