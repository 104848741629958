import _ from 'lodash';
import { Module } from 'vuex';
import { AxiosCallResponse } from '@/api/base';
import { TYPE_LABEL_SUCCESS } from '@/components/ui/notifications/TheAlert/TheAlert.vue';
import i18n from '@/i18n';
import { StateType } from '@/store';
import TechnologicalSupportTypeService, {
  TechnologicalSupportTypeData,
  TechnologicalSupportTypeSchema,
} from '@/api/admin/dictionary/typesTechnologicalSupport';

export interface TechnologicalSupportTypeOneStateType {
  data: TechnologicalSupportTypeSchema | null,
}

const initialState = {
  data: null,
};

const technologicalSupportOneModule: Module<TechnologicalSupportTypeOneStateType, StateType> = {
  namespaced: true,
  state: _.cloneDeep(initialState),
  getters: {
    data: (state) => state.data,
  },
  mutations: {
    setData(state, data) {
      state.data = data;
    },
  },
  actions: {
    async loadData({ commit, rootGetters }, id: string) {
      const api: TechnologicalSupportTypeService = rootGetters
        .$api
        .admin
        .dictionary
        .technologicalSupportType;

      const response: AxiosCallResponse<TechnologicalSupportTypeSchema> = await api.one(id);

      commit('setData', response.data ?? initialState.data);
    },
    async create({ rootGetters }, data: TechnologicalSupportTypeData) {
      const api: TechnologicalSupportTypeService = rootGetters
        .$api
        .admin
        .dictionary
        .technologicalSupportType;

      const response: AxiosCallResponse<TechnologicalSupportTypeSchema> = await api.create(data);

      if (!response.error) {
        await rootGetters
          .$notificationLogger
          .setNotification(
            TYPE_LABEL_SUCCESS,
            i18n.global.t('notification.admin.dictionary.success.create'),
          );
      }
    },
    async update(
      { rootGetters },
      params: { id: string, data: TechnologicalSupportTypeData },
    ) {
      const api: TechnologicalSupportTypeService = rootGetters
        .$api
        .admin
        .dictionary
        .technologicalSupportType;

      const response: AxiosCallResponse<null> = await api.update(params.id, params.data);

      if (!response.error) {
        await rootGetters
          .$notificationLogger
          .setNotification(
            TYPE_LABEL_SUCCESS,
            i18n.global.t('notification.admin.dictionary.success.update'),
          );
      }
    },
    async delete({ rootGetters }, id: string) {
      const api: TechnologicalSupportTypeService = rootGetters
        .$api
        .admin
        .dictionary
        .technologicalSupportType;

      const response: AxiosCallResponse<null> = await api.delete(id);

      if (!response.error) {
        await rootGetters
          .$notificationLogger
          .setNotification(
            TYPE_LABEL_SUCCESS,
            i18n.global.t('notification.admin.dictionary.success.delete'),
          );
      }
    },
    async restore({ rootGetters }, id: string) {
      if (id) {
        const api: TechnologicalSupportTypeService = rootGetters
          .$api
          .admin
          .dictionary
          .technologicalSupportType;

        const response: AxiosCallResponse<null> = await api.restore(id);

        if (!response.error) {
          await rootGetters
            .$notificationLogger
            .setNotification(
              TYPE_LABEL_SUCCESS,
              i18n.global.t('notification.admin.dictionary.success.restore'),
            );
        }
      }
    },
  },
};

export default technologicalSupportOneModule;
