import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b4d7f26"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "template-block-table" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_add_button_component = _resolveComponent("add-button-component")!
  const _component_the_table = _resolveComponent("the-table")!
  const _component_the_create = _resolveComponent("the-create")!
  const _component_the_detail = _resolveComponent("the-detail")!
  const _component_the_side_modal = _resolveComponent("the-side-modal")!
  const _component_the_danger_modal = _resolveComponent("the-danger-modal")!
  const _component_block_default = _resolveComponent("block-default")!

  return (_openBlock(), _createBlock(_component_block_default, { text: _ctx.title }, {
    default: _withCtx(() => [
      (_ctx.isCanEdit)
        ? (_openBlock(), _createBlock(_component_add_button_component, {
            key: 0,
            onClickHandler: _ctx.openForm
          }, null, 8, ["onClickHandler"]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_the_table, {
          items: _ctx.preparedItems,
          sort: _ctx.sort,
          pagination: _ctx.pagination,
          fields: _ctx.fields,
          variant: "simple",
          onChangeSortBy: _ctx.changeSortHandler,
          onChangePagination: _ctx.changePaginationHandler,
          onBodyItemClick: _ctx.openDetailForm
        }, null, 8, ["items", "sort", "pagination", "fields", "onChangeSortBy", "onChangePagination", "onBodyItemClick"])
      ]),
      _createVNode(_component_the_side_modal, {
        "is-show-modal": _ctx.isOpenForm,
        "is-sidebar-with-footer": !_ctx.isView,
        onCloseHandler: _ctx.closeForm
      }, {
        default: _withCtx(() => [
          (_ctx.isCreate)
            ? (_openBlock(), _createBlock(_component_the_create, {
                key: 0,
                uischema: _ctx.uischema,
                schema: _ctx.schema,
                onCancel: _ctx.closeForm,
                onCreate: _ctx.createHandler
              }, null, 8, ["uischema", "schema", "onCancel", "onCreate"]))
            : (_openBlock(), _createBlock(_component_the_detail, {
                key: 1,
                data: _ctx.selectedItemData,
                uischema: _ctx.uischema,
                schema: _ctx.schema,
                "is-can-edit": _ctx.isCanEdit,
                onChangeEditMode: _ctx.changeEditModeHandler,
                onUpdate: _ctx.updateHandler,
                onDelete: _ctx.deleteHandler
              }, null, 8, ["data", "uischema", "schema", "is-can-edit", "onChangeEditMode", "onUpdate", "onDelete"]))
        ]),
        _: 1
      }, 8, ["is-show-modal", "is-sidebar-with-footer", "onCloseHandler"]),
      _createVNode(_component_the_danger_modal, {
        "approve-button-text": _ctx.$t('history.dangerModal.confirmButton'),
        text: _ctx.$t('history.dangerModal.text'),
        "is-open": _ctx.isOpenDangerModal,
        onApprovalHandler: _ctx.dangerApprovalHandler,
        onCloseModal: _ctx.dangerCloseHandler
      }, null, 8, ["approve-button-text", "text", "is-open", "onApprovalHandler", "onCloseModal"])
    ]),
    _: 3
  }, 8, ["text"]))
}