import { defineComponent } from 'vue';
import ManuallyForm
  from '@/components/counterparty/list/modal/create/content/tabs/manually/TheForm.vue';
import SearchForm
  from '@/components/counterparty/list/modal/create/content/tabs/search/TheForm.vue';

const TYPE_SEARCH = 'search';
const TYPE_MANUALLY = 'manually';

export default defineComponent({
  name: 'TheContent',
  components: {
    SearchForm,
    ManuallyForm,
  },
  data() {
    return {
      type: TYPE_SEARCH,
    };
  },
  watch: {
    isSearchForm() {
      const sidebar = document.querySelector('.sidebar');

      sidebar?.scrollTo({ top: 0 });
    },
  },
  computed: {
    isSearchForm() {
      return this.type === TYPE_SEARCH;
    },
  },
  methods: {
    redirectToManuallyHandler() {
      this.type = TYPE_MANUALLY;
    },
  },
});
