import { Module } from 'vuex';
import list, { AgentListStateType } from '@/store/modules/counterparty/block/agents/list';
import one, { AgentOneStateType } from '@/store/modules/counterparty/block/agents/one';
import { StateType } from '@/store';

export interface AgentStateType {
  list: AgentListStateType,
  one: AgentOneStateType,
}

const agentModule: Module<AgentStateType, StateType> = {
  namespaced: true,
  modules: {
    list,
    one,
  },
};

export default agentModule;
