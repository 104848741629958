
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import TheManager from '@/components/ui/notifications/TheManager/TheManager.vue';

export default defineComponent({
  name: 'TheNotification',
  inject: ['$sizeWindow'],
  components: {
    TheManager,
  },
  computed: mapGetters({
    notifications: 'notifications/notifications',
  }),
  methods: mapActions({
    deleteNotification: 'notifications/deleteNotification',
  }),
});
