
import { defineComponent, PropType } from 'vue';
import { ErrorObject } from 'ajv';
import { JsonFormsChangeEvent } from '@jsonforms/vue';
import config from '@/config';
import FormGenerator from '@/components/jsonforms/generators/FormGenerator.vue';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import { validateData } from '@/utils/helpers/AjvValidator';
import useShowErrors from '@/composables/form/useShowErrors';

export default defineComponent({
  name: 'TheContent',
  components: {
    FormGenerator,
  },
  emits: ['change'],
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
    maxSymbol: {
      type: Number,
      default: 500,
    },
    data: {
      type: Object as PropType<JsonData>,
      default: () => ({}),
    },
  },
  watch: {
    data() {
      this.currentData = this.data ?? {};
    },
  },
  data() {
    return {
      schema: {
        type: 'object',
        properties: {
          [config.NEWS_BLOCK_DATE]: {
            type: 'string',
            format: 'date',
          },
          [config.NEWS_BLOCK_LINK]: {
            type: 'string',
            pattern: 'https?:\\/\\/([\\w-]{1,32}\\.[\\w-]{1,32})[^\\s@]*',
            errorMessage: {
              pattern: 'невалидный формат ссылки',
            },
          },
          [config.NEWS_BLOCK_DESCRIPTION]: {
            type: 'string',
            maxLength: this.maxSymbol,
          },
        },
        required: [
          config.NEWS_BLOCK_DATE,
          config.NEWS_BLOCK_LINK,
          config.NEWS_BLOCK_DESCRIPTION,
        ],
      },
      uischema: {
        type: 'VerticalLayout',
        elements: [
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: this.$t('newsAttributes.publishedAt'),
                scope: `#/properties/${config.NEWS_BLOCK_DATE}`,
              },
              {
                type: 'Control',
                label: this.$t('newsAttributes.link'),
                scope: `#/properties/${config.NEWS_BLOCK_LINK}`,
              },
            ],
          },
          {
            type: 'Control',
            label: this.$t('newsAttributes.content'),
            scope: `#/properties/${config.NEWS_BLOCK_DESCRIPTION}`,
            options: {
              multi: true,
            },
          },
        ],
      },
      currentData: this.data ?? {},
      errors: [] as ErrorObject[],
    };
  },
  computed: {
    currentSymbolCount() {
      const currentDescription = this.currentData[config.NEWS_BLOCK_DESCRIPTION];

      if (currentDescription) {
        return currentDescription.length;
      }

      return 0;
    },
  },
  methods: {
    clearData() {
      this.currentData = {};
      this.errors = [];
    },
    onChange(event: JsonFormsChangeEvent) {
      this.currentData = event.data;

      this.$emit('change', event);
    },
    validateForm(showErrors = false): boolean {
      this.errors = validateData(this.currentData, this.schema, this.uischema);

      if (showErrors) {
        this.showErrors(this.errors);
      }

      return !this.errors || !this.errors.length;
    },
  },
  setup() {
    const { showErrors } = useShowErrors();

    return { showErrors };
  },
});
