import { AxiosCallResponse, BaseService, LoggerInterface } from '@/api/base';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import { RegistryData, RegistryInfo } from '@/api/block/registry/base';

export default class ConsumptionRegistryService extends BaseService {
  constructor(token: string, logger: LoggerInterface) {
    super('card', token, logger, 'v2');
  }

  async one(
    cardId: string,
    year: string,
    group: string,
  ): Promise<AxiosCallResponse<JsonData>> {
    return this.axiosCall({ url: `/${cardId}/consumption-registry/${year}/${group}` });
  }

  async info(cardId: string): Promise<AxiosCallResponse<RegistryInfo>> {
    return this.axiosCall({ url: `/${cardId}/consumption-registry/info` });
  }

  async save(cardId: string, data: RegistryData): Promise<AxiosCallResponse<null>> {
    return this.axiosCall({ url: `/${cardId}/consumption-registry`, method: 'put', data });
  }
}
