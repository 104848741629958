
import { defineComponent, PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { JsonSchema, UISchemaElement } from '@jsonforms/core';
import TableBlockTemplate
  from '@/components/counterparty/card/blocks/table-block-template/TableBlockTemplate.vue';
import { CommunicationItem } from '@/api/block/relationship/communication';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import TheFilter
  from '@/components/counterparty/card/blocks/relationship/communication/filter/TheFilter.vue';
import { FilterCommunication } from '@/store/modules/counterparty/block/relationship/communication/list';
import communication from '@/shared/consts/blocks/communication';
import { PaginationParams, SortParams } from '@/api/base';
import ActivityStatus
  from '@/components/counterparty/card/blocks/relationship/communication/status/ActivityStatus.vue';
import UserRenderList
  from '@/components/counterparty/card/blocks/relationship/communication/user-render-list/UserRenderList.vue';

export default defineComponent({
  name: 'TheCommunication',
  components: { TableBlockTemplate, TheFilter },
  props: {
    title: {
      type: String,
      default: '',
    },
    schema: {
      required: true,
      type: Object as PropType<JsonSchema>,
      default: undefined,
    },
    uischema: {
      required: true,
      type: Object as PropType<UISchemaElement>,
      default: undefined,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'statusComponent', label: '', notSortable: true, width: 60,
        },
        {
          key: communication.BLOCK_FIELD_DATE_CONTACT,
          format: 'date',
          label: this.$t('counterparty.tabs.relationship.communication.fields.date'),
        },
        { key: communication.BLOCK_FIELD_TYPE, label: this.$t('counterparty.tabs.relationship.communication.fields.type') },
        { key: communication.BLOCK_FIELD_USER, label: this.$t('counterparty.tabs.relationship.communication.fields.user'), width: 300 },
      ],
    };
  },
  computed: {
    ...mapGetters({
      items: 'counterparty/block/communication/list/items',
      filter: 'counterparty/block/communication/list/filter',
      sort: 'counterparty/block/communication/list/sort',
      pagination: 'counterparty/block/communication/list/pagination',
      currentCardId: 'counterparty/card/currentCardId',
      agents: 'counterparty/agent/list/items',
    }),
    preparedItems() {
      const items: CommunicationItem[] = this.items ?? [];

      return items.map((item: CommunicationItem) => ({
        id: item.id,
        [communication.BLOCK_FIELD_DATE_CONTACT]: item[communication.BLOCK_FIELD_DATE_CONTACT],
        [communication.BLOCK_FIELD_TYPE]: item[communication.BLOCK_FIELD_TYPE],
        [communication.BLOCK_FIELD_USER]: {
          type: 'component',
          component: UserRenderList,
          props: {
            userList: this.prepareUserColumn(item),
          },
          isVisible: true,
        },
        statusComponent: {
          type: 'component',
          component: ActivityStatus,
          props: {
            status: item[communication.BLOCK_FIELD_STATUS] ?? '',
          },
          isVisible: true,
        },
      }));
    },
  },
  methods: {
    ...mapActions({
      loadItems: 'counterparty/block/communication/list/loadItems',
      changeFilter: 'counterparty/block/communication/list/changeFilter',
      changeSort: 'counterparty/block/communication/list/changeSort',
      changePagination: 'counterparty/block/communication/list/changePagination',
      loadAgents: 'counterparty/agent/list/loadItems',
      create: 'counterparty/block/communication/one/create',
      update: 'counterparty/block/communication/one/update',
      delete: 'counterparty/block/communication/one/delete',
    }),
    prepareUserColumn(item: CommunicationItem) {
      return (item[communication.BLOCK_FIELD_USER] ?? []) as string[];
    },
    async createHandler(data: JsonData, next: () => void) {
      await this.create({
        cardId: this.currentCardId,
        data,
      });

      next();
      await this.loadItems(this.currentCardId);
    },
    async updateHandler(id: string, data: JsonData, next: () => void) {
      await this.update({
        cardId: this.currentCardId,
        id,
        data,
      });

      next();
      await this.loadItems(this.currentCardId);
    },
    async deleteHandler(id: string, next: () => void) {
      await this.delete({
        cardId: this.currentCardId,
        id,
      });

      next();
      await this.loadItems(this.currentCardId);
    },
    async changePaginationHandler(pagination: PaginationParams) {
      await this.changePagination(pagination);

      await this.loadItems(this.currentCardId);
    },
    async changeSortHandler(sort: SortParams) {
      await this.changeSort(sort);

      await this.loadItems(this.currentCardId);
    },
    async filterChangedHandler(filter: FilterCommunication) {
      await this.changeFilter(filter);

      await this.loadItems(this.currentCardId);
    },
  },
  async created() {
    await this.loadItems(this.currentCardId);
    await this.loadAgents(this.currentCardId);
  },
});
