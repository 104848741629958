
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TheError',
  props: {
    url: {
      type: String,
      default: '',
    },
    status: {
      type: Number,
      default: 404,
    },
    text: {
      type: String,
      default: '',
    },
    subtext: {
      type: String,
      default: '',
    },
  },
});
