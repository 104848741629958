
import { defineComponent, PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { JsonSchema } from '@jsonforms/core';
import TheListBlockTemplate from '@/components/counterparty/card/blocks/list-block-template/TheListBlockTemplate.vue';
import CustomUISchemaElement from '@/components/jsonforms/interfaces/CustomUISchemaElement';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import keyProductionAssets from '@/shared/consts/blocks/general/keyProductionAssets';
import { PaginationParams } from '@/api/base';

export default defineComponent({
  name: 'KeyProductionAssets',
  components: { TheListBlockTemplate },
  props: {
    title: String,
    uischema: Object as PropType<CustomUISchemaElement>,
    schema: Object as PropType<JsonSchema>,
    isCanEdit: Boolean,
  },
  data() {
    return {
      fieldList: [
        keyProductionAssets.NAME,
        keyProductionAssets.TYPE,
      ],
    };
  },
  computed: {
    ...mapGetters({
      items: 'counterparty/block/keyProductionAssets/list/items',
      pagination: 'counterparty/block/keyProductionAssets/list/pagination',
      currentCardId: 'counterparty/card/currentCardId',
    }),
  },
  methods: {
    ...mapActions({
      loadItems: 'counterparty/block/keyProductionAssets/list/loadItems',
      changePagination: 'counterparty/block/keyProductionAssets/list/changePagination',
      create: 'counterparty/block/keyProductionAssets/one/create',
      update: 'counterparty/block/keyProductionAssets/one/update',
      delete: 'counterparty/block/keyProductionAssets/one/delete',
    }),
    async changePaginationHandler(pagination: PaginationParams) {
      await this.changePagination(pagination);

      await this.loadItems(this.currentCardId);
    },
    async createHandler(data: JsonData, next:() => void) {
      await this.create({
        cardId: this.currentCardId,
        data,
      });
      await this.loadItems(this.currentCardId);

      next();
    },
    async updateHandler(id: string, data: JsonData, next:() => void) {
      await this.update({
        cardId: this.currentCardId,
        id,
        data,
      });
      await this.loadItems(this.currentCardId);

      next();
    },
    async deleteHandler(id: string) {
      await this.delete({
        cardId: this.currentCardId,
        id,
      });
      await this.loadItems(this.currentCardId);
    },
  },
  async created() {
    await this.loadItems(this.currentCardId);
  },
});
