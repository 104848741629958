import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_list_payment = _resolveComponent("the-list-payment")!

  return (_openBlock(), _createBlock(_component_the_list_payment, {
    "block-label": _ctx.title,
    items: _ctx.items,
    uischema: _ctx.uischema,
    schema: _ctx.schema,
    pagination: _ctx.pagination,
    "is-can-edit": _ctx.isCanEdit,
    onCreated: _ctx.createHandler,
    onUpdated: _ctx.updateHandler,
    onBlock: _ctx.blockHandler,
    onUnblock: _ctx.unblockHandler,
    onPaginationChanged: _ctx.changePaginationHandler,
    onChangedFormData: _ctx.changedFormDataHandler
  }, null, 8, ["block-label", "items", "uischema", "schema", "pagination", "is-can-edit", "onCreated", "onUpdated", "onBlock", "onUnblock", "onPaginationChanged", "onChangedFormData"]))
}