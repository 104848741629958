import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: `/counterparty/${_ctx.counterpartyId}/${_ctx.shortName}`,
    class: _normalizeClass([{ active: _ctx.isActiveTab }, "tabs-menu-item"])
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.name), 1)
    ]),
    _: 1
  }, 8, ["to", "class"]))
}