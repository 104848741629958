
import _ from 'lodash';
import { defineComponent, PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { UserItem, UserUpdateData } from '@/api/admin/user';
import {
  UpdatedRoleParams,
} from '@/components/admin/user/one/modal/content/detail/role/RoleItem.vue';
import { UserFormData, UserForm } from '@/components/admin/user/one/modal/content/form/TheForm.vue';
import EditForm from '@/components/admin/user/one/modal/content/detail/form/EditForm.vue';
import ViewForm from '@/components/admin/user/one/modal/content/detail/form/ViewForm.vue';
import TheAvatar from '@/components/admin/user/one/modal/content/detail/avatar/TheAvatar.vue';
import RoleGroupBlock, {
  UpdatedGroupParams,
} from '@/components/admin/user/one/modal/content/detail/role/RoleGroup.vue';
import DefaultButton from '@/components/ui/buttons/DefaultButton/DefaultButton.vue';
import IconButton from '@/components/ui/buttons/IconButton/IconButton.vue';
import EditIcon from '@/components/ui/icons/EditIcon.vue';
import { FilterDivisionParams, FilterStructureParams, StructureItem } from '@/api/admin/dictionary/structure';
import DefaultLoader from '@/components/ui/loaders/DefaultLoader.vue';
import RoleItemHelper from '@/utils/helpers/RoleItemHelper';
import { RoleItem } from '@/api/admin/role';
import config from '@/config';
import { OneOfType } from '@/api/schema';
import useStringHelper from '@/composables/helpers/text/useStringHelper';
import UnlockIcon from '@/components/ui/icons/UnlockIcon.vue';
import LockIcon from '@/components/ui/icons/LockIcon.vue';

export default defineComponent({
  name: 'TheDetail',
  inject: ['$userAccess'],
  emits: ['changedEditMode', 'changedUserStatus', 'cancel', 'updated'],
  components: {
    LockIcon,
    UnlockIcon,
    DefaultButton,
    EditIcon,
    IconButton,
    EditForm,
    ViewForm,
    TheAvatar,
    RoleGroupBlock,
    DefaultLoader,
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    isCanEdit: {
      type: Boolean,
      default: false,
    },
    isVisibleRoles: {
      type: Boolean,
      default: false,
    },
    preparedCompanies: {
      type: Array as PropType<OneOfType[]>,
      default: () => [],
    },
  },
  data() {
    return {
      isLoading: false,
      isLoadingUser: false,
      isEditMode: this.isEdit,
      formData: null,
      userRoles: [],
      userRolesAttributes: {},
    };
  },
  watch: {
    async userId() {
      await this.loadInitialData();
    },
    isEdit() {
      this.isEditMode = this.isEdit;
    },
    async isEditMode(value: boolean) {
      this.$emit('changedEditMode', value);

      if (!value) {
        await this.loadInitialData();
      }
    },
    async formData(data: UserItem, lastData: UserItem) {
      if (this.$userAccess.admin.isSuperAdmin
        && data?.company && data?.company !== lastData?.company) {
        await this.loadDivisionWithFilters({
          companyId: data.company,
        });
      }

      if (data?.division && data?.division !== lastData?.division) {
        await this.loadStructureWithFilters({
          companyId: data.company,
          parentId: data.division,
        });
      }

      if (data?.division !== lastData?.division) {
        this.clearRolesAndRoleAttributesByIds(this.rolesWithExtraDepartmentType);
      }
    },
  },
  computed: {
    ...mapGetters({
      user: 'admin/user/one/data',
      roles: 'admin/role/list/items',
      divisions: 'admin/dictionary/structure/division/items',
      departments: 'admin/dictionary/structure/list/items',
      companies: 'admin/dictionary/company/list/items',
    }),
    fullName() {
      return `${this.user.lastName ?? ''} ${this.user.firstName ?? ''} ${this.user.middleName ?? ''}`;
    },
    nameInitial(): string {
      return useStringHelper().getLetter(this.fullName);
    },
    rolesWithExtraDepartmentType(): string[] {
      return RoleItemHelper.getLinearListOfRoles(_.cloneDeep(this.roles))
        .filter((role: RoleItem) => role.type === config.ROLE_TYPE_EXTRA_DEPARTMENT)
        .map((role: RoleItem) => role.id);
    },
    preparedDepartments() {
      if (this.formData) {
        return this.formData.division
          ? this.departments.map((item: StructureItem) => ({
            id: item.id,
            name: item.name,
          })) : [];
      }
      return this.user.division
        ? this.departments.map((item: StructureItem) => ({
          id: item.id,
          name: item.name,
        })) : [];
    },
    isActive() {
      return this.user.status === config.STATUS_USER_IS_ACTIVE;
    },
  },
  methods: {
    ...mapActions({
      setFilterDivision: 'admin/dictionary/structure/division/changeFilter',
      setFilterStructure: 'admin/dictionary/structure/list/changeFilter',
      loadStructure: 'admin/dictionary/structure/list/loadItems',
      loadUser: 'admin/user/one/loadData',
      updateUser: 'admin/user/one/update',
      blockUser: 'admin/user/one/blockUser',
      unblockUser: 'admin/user/one/unblockUser',
    }),
    cancelHandler() {
      this.$emit('cancel');
    },
    toggleIsEditMode() {
      this.isEditMode = !this.isEditMode;
    },
    prepareData(data: UserFormData): UserUpdateData {
      return {
        ...data,
        avatar: null,
        status: this.user.status,
        roles: this.userRoles,
        rolesAttributes: { ...this.userRolesAttributes },
      };
    },
    updatedRoleGroupHandler(params: UpdatedGroupParams) {
      const roleIds = params.roles.map((param: UpdatedRoleParams) => param.roleId);
      const value = params.roles.every((param: UpdatedRoleParams) => param.value);

      this.userRoles = value
        ? _.uniq([...this.userRoles, ...roleIds])
        : this.userRoles.filter((id: string) => !roleIds.includes(id));
    },
    updatedRoleAttributesHandler(data: string[], roleId: string) {
      this.userRolesAttributes[roleId] = data;
    },
    clearRolesAndRoleAttributesByIds(roleIds: string[]) {
      this.userRoles = this.userRoles.filter((role: string) => !roleIds.includes(role));

      roleIds.forEach((role: string) => {
        this.userRolesAttributes[role] = [];
      });
    },
    async updateHandler() {
      this.isLoading = true;

      const formRef: UserForm = this.$refs.form as UserForm;
      const valid: boolean = formRef?.validateForm() ?? false;
      const data: UserFormData | null = formRef?.currentData ?? null;

      if (!valid || !data) {
        this.isLoading = false;

        return false;
      }

      await this.updateUser(this.prepareData(data));

      this.$emit('updated');

      this.isLoading = false;

      return true;
    },
    async changeUserStatus() {
      if (this.isActive) {
        await this.blockUser();
      } else {
        await this.unblockUser();
      }

      await this.loadUser(this.userId);

      this.$emit('changedUserStatus');
    },
    async loadDivisionWithFilters(filter: FilterDivisionParams) {
      await this.setFilterDivision(filter);
    },
    async loadStructureWithFilters(filter: FilterStructureParams) {
      await this.setFilterStructure(filter);
      await this.loadStructure();
    },
    async loadInitialData() {
      this.isLoadingUser = true;

      await this.loadUser(this.userId);

      if (this.user?.company) {
        await this.loadDivisionWithFilters({
          companyId: this.user.company,
        });
      }

      if (this.user?.division) {
        await this.loadStructureWithFilters({
          companyId: this.user.company,
          parentId: this.user.division,
        });
      }

      this.userRoles = this.user?.roles ?? [];
      this.userRolesAttributes = this.user?.rolesAttributes ?? {};

      this.isLoadingUser = false;
    },
  },
  async created() {
    await this.loadInitialData();
  },
});
