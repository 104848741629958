import { BoardingSteps } from 'boarding.js';

export const getSteps = (): BoardingSteps => [
  {
    element: 'tbody',
    popover: {
      className: 'first-step-popover-class',
      title: 'Список записей',
      description: 'Список доступных вам записей.',
      prefferedSide: 'top',
    },
  },
  {
    element: '.table-item',
    popover: {
      className: 'first-step-popover-class',
      title: 'Детальная информация',
      description: 'При клике по записи откроется окно с детальной информацией, удалением и редактированием.',
      prefferedSide: 'top',
    },
  },
  {
    element: '.title-block button',
    popover: {
      className: 'first-step-popover-class',
      title: 'Добавление новой записи',
      description: 'Здесь вы можете добавить новую запись в выбранный вами справочник.',
      prefferedSide: 'left',
    },
  },
  {
    element: '.filter-block .input',
    popover: {
      className: 'first-step-popover-class',
      title: 'Поиск',
      description: 'Здесь вы можете найти нужную вам запись',
      prefferedSide: 'bottom',
    },
  },
  {
    element: '.filter-block .tab-menu',
    popover: {
      className: 'first-step-popover-class',
      title: 'Фильтр активности',
      description: 'Здесь вы можете отфильтровать записи по активным и удаленным.',
      prefferedSide: 'bottom',
    },
  },
  {
    element: '.filter-block .tooltip.circle-button',
    popover: {
      className: 'first-step-popover-class',
      title: 'Сброс фильтров',
      description: 'Кликнув на эту кнопку, Вы сбросите все фильтры.',
      prefferedSide: 'bottom',
    },
  },
];

export default {
  getSteps,
};
