import { defineComponent, PropType } from 'vue';
import {
  TableFieldObject,
  TableItem,
} from '@/components/ui/tables/simple/TheTable';
import TheHeader from '@/components/ui/tables/simple/header/TheHeader.vue';
import TheBody from '@/components/ui/tables/simple/body/TheBody.vue';
import TheHierarchyBody
  from '@/components/ui/tables/hierarchy/body/TheBody.vue';

export default defineComponent({
  name: 'TheTableHierarchy',
  components: {
    TheHeader,
    TheBody,
    TheHierarchyBody,
  },
  props: {
    variant: {
      type: String,
      default: 'expand',
    },
    items: {
      type: Array as PropType<TableItem[]>,
      default: () => ([]),
    },
    fields: {
      type: Array as PropType<TableFieldObject[]>,
      default: () => ([]),
    },
    pagination: {
      type: Object,
      default: () => ({
        currentPage: 0,
        perPage: 0,
        totalCount: 0,
        pageCount: 0,
      }),
    },
    sort: {
      type: Object,
      default: () => ({}),
    },
    isAllOpen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isSimple() {
      return this.variant === 'simple';
    },
    isExpand() {
      return this.variant === 'expand';
    },
  },
  emits: ['bodyItemClick', 'changeSortBy', 'changePagination'],
  methods: {
    bodyItemClickHandler(id: string) {
      this.$emit('bodyItemClick', id);
    },
  },
});
