const permissions = Object.freeze({
  ROLE_SUPER_ADMIN: 'ROLE_SUPER_ADMIN',
  ROLE_ADMIN_COMPANY: 'ROLE_ADMIN_COMPANY',
  ROLE_ADMIN_DIVISION: 'ROLE_ADMIN_DIVISION',
  ROLE_VIEWER_MAIN: 'ROLE_VIEWER_MAIN',
  ROLE_EDITOR_MAIN: 'ROLE_EDITOR_MAIN',
  ROLE_VIEWER_EXTRA_YOUR_DEPARTMENT: 'ROLE_VIEWER_EXTRA_YOUR_DEPARTMENT',
  ROLE_VIEWER_EXTRA_OTHER_DEPARTMENT: 'ROLE_VIEWER_EXTRA_OTHER_DEPARTMENT',
  ROLE_VIEWER_EXTRA_WITHOUT_DEPARTMENT: 'ROLE_VIEWER_EXTRA_WITHOUT_DEPARTMENT',
  ROLE_EDITOR_EXTRA_YOUR_DEPARTMENT: 'ROLE_EDITOR_EXTRA_YOUR_DEPARTMENT',
  ROLE_EDITOR_EXTRA_OTHER_DEPARTMENT: 'ROLE_EDITOR_EXTRA_OTHER_DEPARTMENT',
  ROLE_EDITOR_EXTRA_WITHOUT_DEPARTMENT: 'ROLE_EDITOR_EXTRA_WITHOUT_DEPARTMENT',
  ROLE_VIEWER_DICTIONARY_ASSIGN: 'ROLE_VIEWER_DICTIONARY_ASSIGN',
  ROLE_VIEWER_USER_ROLES: 'ROLE_VIEWER_USER_ROLES',
  ROLE_EDITOR_DICTIONARY_USER: 'ROLE_EDITOR_DICTIONARY_USER',
  ROLE_EDITOR_DICTIONARY_ASSIGN: 'ROLE_EDITOR_DICTIONARY_ASSIGN',
  ROLE_EDITOR_DICTIONARY_COUNTRY: 'ROLE_EDITOR_DICTIONARY_COUNTRY',
  ROLE_EDITOR_DICTIONARY_BANK: 'ROLE_EDITOR_DICTIONARY_BANK',
  ROLE_EDITOR_DICTIONARY_BANK_ACCOUNT: 'ROLE_EDITOR_DICTIONARY_BANK_ACCOUNT',
  ROLE_EDITOR_DICTIONARY_COUNTERPARTY_CATEGORY: 'ROLE_EDITOR_DICTIONARY_COUNTERPARTY_CATEGORY',
  ROLE_EDITOR_DICTIONARY_INDUSTRY: 'ROLE_EDITOR_DICTIONARY_INDUSTRY',
  ROLE_EDITOR_DICTIONARY_ACTIVITY_DIRECTION: 'ROLE_EDITOR_DICTIONARY_ACTIVITY_DIRECTION',
  ROLE_EDITOR_DICTIONARY_STRUCTURE: 'ROLE_EDITOR_DICTIONARY_STRUCTURE',
  ROLE_EDITOR_DICTIONARY_POSITION: 'ROLE_EDITOR_DICTIONARY_POSITION',
  ROLE_EDITOR_DICTIONARY_EDUCATION: 'ROLE_EDITOR_DICTIONARY_EDUCATION',
  ROLE_EDITOR_DICTIONARY_INDIVIDUAL: 'ROLE_EDITOR_DICTIONARY_INDIVIDUAL',
  ROLE_EDITOR_DICTIONARY_REGION: 'ROLE_EDITOR_DICTIONARY_REGION',
  ROLE_VIEWER_ENTIRE_LIST: 'ROLE_VIEWER_ENTIRE_LIST',

});

export default permissions;
