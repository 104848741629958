import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d5ba92ac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "filter-block" }
const _hoisted_2 = { class: "w-[200px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_default_input = _resolveComponent("default-input")!
  const _component_button_group = _resolveComponent("button-group")!
  const _component_close_icon = _resolveComponent("close-icon")!
  const _component_icon_button = _resolveComponent("icon-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_default_input, {
      class: "w-full",
      value: _ctx.filter.query ?? '',
      placeholder: _ctx.$t('common.search'),
      "with-debounce": true,
      onOnChange: _ctx.changeQueryHandler
    }, null, 8, ["value", "placeholder", "onOnChange"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_button_group, {
        items: _ctx.statusList,
        "active-group": _ctx.filter.status,
        onGroupSelected: _ctx.changeStatusHandler
      }, null, 8, ["items", "active-group", "onGroupSelected"])
    ]),
    _createVNode(_component_icon_button, {
      class: "circle-button",
      border: "default",
      description: _ctx.$t('counterparty.list.filter.clear'),
      onClick: _ctx.clearFilter
    }, {
      default: _withCtx(() => [
        _createVNode(_component_close_icon)
      ]),
      _: 1
    }, 8, ["description", "onClick"])
  ]))
}