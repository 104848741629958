import { PlausibleInitOptions } from 'plausible-tracker/src/lib/tracker';
import { App } from 'vue';
import Plausible from 'plausible-tracker';

export type PlausibleType = ReturnType<typeof Plausible>;

const VuePlausible = {
  install: (app: App, options: PlausibleInitOptions): void => {
    const plausible: PlausibleType = Plausible(options);

    plausible.enableAutoPageviews();

    app.provide('plausible', plausible);
  },
};

export default VuePlausible;
