
import { classes, Styles } from '@jsonforms/vue-vanilla';
import { defineComponent, PropType } from 'vue';
import IconButton from '@/components/ui/buttons/IconButton/IconButton.vue';
import RemoveColumnIcon from '@/components/ui/icons/RemoveColumnIcon.vue';

const listItem = defineComponent({
  name: 'array-list-element',
  components: {
    RemoveColumnIcon,
    IconButton,
  },
  props: {
    initiallyExpanded: {
      required: false,
      type: Boolean,
      default: false,
    },
    label: {
      required: false,
      type: String,
      default: '',
    },
    moveUpEnabled: {
      required: false,
      type: Boolean,
      default: true,
    },
    moveDownEnabled: {
      required: false,
      type: Boolean,
      default: true,
    },
    onlyText: {
      required: false,
      type: Boolean,
      default: false,
    },
    moveUp: {
      required: false,
      type: Function,
      default: undefined,
    },
    moveDown: {
      required: false,
      type: Function,
      default: undefined,
    },
    delete: {
      required: false,
      type: Function,
      default: undefined,
    },
    deleteEnabled: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    styles: {
      required: true,
      type: Object as PropType<Styles>,
    },
  },
  data() {
    return {
      expanded: this.initiallyExpanded,
    };
  },
  computed: {
    contentClasses(): string {
      return classes`${this.styles.arrayList.itemContent} ${this.expanded
        && this.styles.arrayList.itemExpanded}`;
    },
    toolbarClasses(): string {
      return classes`${this.styles.arrayList.itemToolbar} ${this.expanded
        && this.styles.arrayList.itemExpanded}`;
    },
    showSortButtons(): boolean {
      return this.options.showSortButtons ?? false;
    },
  },
  methods: {
    expandClicked(): void {
      this.expanded = !this.expanded;
    },
    moveUpClicked(event: Event): void {
      event.stopPropagation();
      this.moveUp?.();
    },
    moveDownClicked(event: Event): void {
      event.stopPropagation();
      this.moveDown?.();
    },
    deleteClicked(event: Event): void {
      event.stopPropagation();
      this.delete?.();
    },
  },
});

export default listItem;
