import {
  AxiosCallResponse,
  LoggerInterface,
  ResponseOfRecords,
} from '@/api/base';
import DictionaryBase from '@/api/admin/dictionary/base';

export interface FilterDivisionParams {
  companyId?: string | null,
}

export interface FilterStructureParams {
  companyId?: string | null,
  parentId?: string | null,
}

export interface StructureItem {
  id?: string,
  name: string,
  companyId: string,
  isDepartment: boolean,
  isDivision: boolean,
  parentId?: string,
  fullName?: string,
  code: string,
  items?: StructureItem[],
}

export interface DivisionItem {
  id: string,
  name: string,
  fullName?: string,
  code?: string,
}

export type StructureResponseType = AxiosCallResponse<ResponseOfRecords<StructureItem[]>>;
export type DivisionResponseType = AxiosCallResponse<ResponseOfRecords<DivisionItem[]>>;

export default class StructureService extends DictionaryBase {
  constructor(token: string, logger: LoggerInterface = console) {
    super('structure', token, logger);
  }

  async divisionList(params: FilterDivisionParams = {}): Promise<DivisionResponseType> {
    return this.axiosCall(({ url: '/divisions', params }));
  }
}
