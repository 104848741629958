import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_generator = _resolveComponent("form-generator")!

  return (_openBlock(), _createBlock(_component_form_generator, {
    uischema: _ctx.uischema,
    schema: _ctx.schema,
    information: _ctx.currentData,
    "additional-errors": _ctx.errors,
    "validation-mode": "ValidateAndHide",
    onChange: _ctx.onChange
  }, null, 8, ["uischema", "schema", "information", "additional-errors", "onChange"]))
}