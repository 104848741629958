import i18n from '@/i18n';
import { Module } from 'vuex';
import { AxiosCallResponse, MetaParams, PaginationParams } from '@/api/base';
import { TYPE_LABEL_SUCCESS } from '@/components/ui/notifications/TheAlert/TheAlert.vue';
import PaymentService, {
  GetPaymentParams,
  PaymentDataType,
  PaymentResponseType,
} from '@/api/payment';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import { StateType } from '@/store';

export interface PaymentStateType {
  payments: PaymentDataType[],
  pagination: MetaParams,
}

const initialState = {
  payments: [],
  pagination: {
    currentPage: null,
    pageCount: null,
    perPage: null,
    totalCount: null,
  },
};

const paymentListModule: Module<PaymentStateType, StateType> = {
  namespaced: true,
  state: initialState,
  getters: {
    payments: (state): PaymentDataType[] => state.payments,
    pagination: (state): MetaParams => state.pagination,
    loadPaymentsParams: (state): GetPaymentParams => ({
      page: state.pagination.currentPage,
      perPage: state.pagination.perPage,
    }),
  },
  mutations: {
    setPagination(state, pagination) {
      state.pagination = pagination;
    },
    setPayment(state, payments) {
      state.payments = payments;
    },
  },
  actions: {
    async changePagination({ commit }, pagination: PaginationParams) {
      commit('setPagination', pagination);
    },
    async addPayment({ rootGetters }, props: {
      counterpartyId: string,
      data: JsonData,
    }) {
      if (props.counterpartyId) {
        const paymentApi: PaymentService = rootGetters.$api.payment;

        const response = await paymentApi.create(props.counterpartyId, props.data);

        if (!response.error) {
          await rootGetters
            .$notificationLogger
            .setNotification(
              TYPE_LABEL_SUCCESS,
              i18n.global.t('notification.admin.dictionary.success.create'),
            );
        }
      }
    },
    async editPayment({ rootGetters }, props: {
      counterpartyId: string,
      id: string,
      data: JsonData,
    }) {
      if (props.counterpartyId) {
        const paymentApi: PaymentService = rootGetters.$api.payment;

        const response = await paymentApi.edit(props.counterpartyId, props.id, props.data);

        if (!response.error) {
          await rootGetters
            .$notificationLogger
            .setNotification(
              TYPE_LABEL_SUCCESS,
              i18n.global.t('notification.admin.dictionary.success.update'),
            );
        }
      }
    },
    async blockPayment({ rootGetters }, props: {
      counterpartyId: string,
      id: string,
    }) {
      if (props.counterpartyId) {
        const paymentApi: PaymentService = rootGetters.$api.payment;

        const response = await paymentApi.block(props.counterpartyId, props.id);

        if (!response.error) {
          await rootGetters
            .$notificationLogger
            .setNotification(
              TYPE_LABEL_SUCCESS,
              i18n.global.t('notification.admin.dictionary.success.block'),
            );
        }
      }
    },
    async unblockPayment({ rootGetters }, props: {
      counterpartyId: string,
      id: string,
    }) {
      if (props.counterpartyId) {
        const paymentApi: PaymentService = rootGetters.$api.payment;

        const response = await paymentApi.unblock(props.counterpartyId, props.id);

        if (!response.error) {
          await rootGetters
            .$notificationLogger
            .setNotification(
              TYPE_LABEL_SUCCESS,
              i18n.global.t('notification.admin.dictionary.success.unblock'),
            );
        }
      }
    },
    async loadPayments({ getters, rootGetters, commit }, counterpartyId: string) {
      if (counterpartyId) {
        const paymentApi: PaymentService = rootGetters.$api.payment;
        const loadParams: GetPaymentParams = getters.loadPaymentsParams;
        const response: AxiosCallResponse<PaymentResponseType> = await paymentApi.list(
          counterpartyId,
          loadParams,
        );

        commit('setPayment', response.data?.items ?? initialState.payments);
        commit('setPagination', response.data?._meta ?? initialState.pagination);
      }
    },
  },
};

export default paymentListModule;
