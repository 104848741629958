import {
  AxiosCallResponse,
  BaseService, FilterParams, LoggerInterface, PaginationParams,
  QueryParamsForList,
  ResponseOfRecords, SortParams,
} from '@/api/base';

export interface FilterDictionaryParams extends FilterParams {
  status?: number | string | null,
}

export type QueryDictionaryParamsForList = PaginationParams | FilterDictionaryParams | SortParams;

export type DictionaryListResponseType<T> = AxiosCallResponse<ResponseOfRecords<T[]>>;

export default class DictionaryBase extends BaseService {
  constructor(resource: string, token: string, logger: LoggerInterface = console) {
    super(`admin/dictionary/${resource}`, token, logger);
  }

  async list<T, R = QueryParamsForList>(params: R = <R>{}): Promise<DictionaryListResponseType<T>> {
    return this.axiosCall({ method: 'get', params });
  }

  async create<T, R>(data: T): Promise<AxiosCallResponse<R>> {
    return this.axiosCall({ method: 'post', data });
  }

  async update<T>(id: string, data: T): Promise<AxiosCallResponse<null>> {
    return this.axiosCall({ url: `/${id}`, method: 'patch', data });
  }

  async delete(id: string): Promise<AxiosCallResponse<null>> {
    return this.axiosCall({ url: `/${id}`, method: 'delete' });
  }

  async one<T>(id: string): Promise<AxiosCallResponse<T>> {
    return this.axiosCall({ url: `/${id}`, method: 'get' });
  }

  async restore<T>(id: string): Promise<AxiosCallResponse<T>> {
    return this.axiosCall({ url: `/${id}/restore`, method: 'post' });
  }
}
