import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4d25f914"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "loader-block"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-block" }
const _hoisted_5 = { class: "modal-block__title" }
const _hoisted_6 = { class: "modal-block" }
const _hoisted_7 = { class: "modal-footer" }
const _hoisted_8 = { class: "text-[14px]" }
const _hoisted_9 = { class: "text-[14px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_default_loader = _resolveComponent("default-loader")!
  const _component_form_generator = _resolveComponent("form-generator")!
  const _component_default_button = _resolveComponent("default-button")!

  return (_ctx.isSchemaLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_default_loader)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('jsonforms.generalInformation.mdm.title')), 1)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_form_generator, {
              uischema: _ctx.preparedUischema,
              schema: _ctx.schema,
              information: _ctx.data,
              "additional-errors": _ctx.errors,
              "validation-mode": "ValidateAndHide",
              onChange: _ctx.onChange
            }, null, 8, ["uischema", "schema", "information", "additional-errors", "onChange"])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_default_button, {
            height: "40",
            onClick: _ctx.cancelHandler
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('jsonforms.buttons.cancel')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_default_button, {
            type: "primary",
            height: "40",
            disabled: _ctx.isLoading,
            onClick: _ctx.saveHandler
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('jsonforms.buttons.send')), 1)
            ]),
            _: 1
          }, 8, ["disabled", "onClick"])
        ])
      ]))
}