/* eslint-disable camelcase */
import { Module } from 'vuex';
import { VueKeycloakTokenParsed } from '@dsb-norge/vue-keycloak-js/dist/types';
import UserService, { UserItem, UserResponseType } from '@/api/admin/user';
import { StateType } from '@/store';

export interface UserAttributesType extends VueKeycloakTokenParsed {
  family_name?: string,
  given_name?: string,
  middle_name?: string,
  preferred_username?: string,
  email?: string,
  phone_number?: string,
  division?: string,
  entity?: string,
  subdivision?: string,
  position?: string,
}

export interface UserStateType {
  attributes: UserAttributesType,
  token?: string | null,
  localUserData: UserItem | null,
}

const initialState: UserStateType = {
  attributes: {
    family_name: '',
    given_name: '',
    middle_name: '',
    preferred_username: '',
    email: '',
    phone_number: '',
    division: '',
    entity: '',
    subdivision: '',
    position: '',
    realm_access: {
      roles: [],
    },
    resource_access: {
      account: {
        roles: [],
      },
    },
  },
  token: null,
  localUserData: null,
};

const userModule: Module<UserStateType, StateType> = {
  namespaced: true,
  state: initialState,
  getters: {
    roles: (state): string[] => ([
      ...state.attributes.resource_access
        ?.[process.env.VUE_APP_KEYCLOAK_CLIENT_ID]?.roles ?? [],
      ...state.localUserData?.roles ?? [],
    ]),
    email: (state): string => state.attributes.preferred_username ?? '',
    fullName: (state): string => `${state.attributes.family_name} ${state.attributes.given_name} ${state.attributes.middle_name}`,
    localUserData: (state): UserItem | null => state.localUserData,
  },
  mutations: {
    setUserData(state, user) {
      state.attributes = { ...state.attributes, ...user };
    },
    setUserToken(state, token) {
      state.token = token;
    },
    setLocalUserData(state, localUserData) {
      state.localUserData = localUserData;
    },
  },
  actions: {
    async loadUserData({ commit }, userData: UserAttributesType) {
      commit('setUserData', userData);
    },
    async loadUserToken({ commit }, token: string) {
      commit('setUserToken', token);
    },
    async loadUserLocalData({ rootGetters, state, commit }) {
      const api: UserService = rootGetters.$api.admin.user;

      if (state.attributes.sub) {
        const response: UserResponseType = await api.one(state.attributes.sub);

        commit('setLocalUserData', response.data);
      }
    },
  },
};

export default userModule;
