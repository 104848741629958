import { QueryParamsForList } from '@/api/base';
import store from '@/store';
import IndustryService, { IndustryResponseType } from '@/api/admin/dictionary/industry';

export function useListApi() {
  return {
    async loadItems(params: QueryParamsForList) {
      const api: IndustryService = store.getters.$api.admin.dictionary.industry;

      const response: IndustryResponseType = await api.list(
        params,
      );

      return response.data?.items ?? [];
    },
  };
}

export default {};
