import { ErrorObject } from 'ajv';
import {
  defineComponent,
  PropType,
} from 'vue';
import {
  JsonForms,
  JsonFormsChangeEvent,
} from '@jsonforms/vue';
import {
  JsonFormsI18nState,
  JsonFormsRendererRegistryEntry,
  JsonSchema, UISchemaElement,
} from '@jsonforms/core';
import {
  Styles,
  defaultStyles,
  vanillaRenderers,
} from '@jsonforms/vue-vanilla';
import localize from '@/components/jsonforms/localize';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import customRenderers from '@/components/jsonforms/renderers/renderers';
import { getChangedData } from '@/components/jsonforms/util/util';
import { changeConfirmation } from '@/composables/form/useCheckConfirmation';
import useCheckRules from '@/composables/form/useCheckRules';

export interface CustomStyles extends Styles {
  control: {
    root?: string,
    wrapper?: string,
    wrapperError?: string,
    label?: string,
    description?: string,
    error?: string,
    input?: string,
    textarea?: string,
    select?: string,
    option?: string,
    text?: string,
    labelText?: string,
  },
  financialTable: {
    root?: string;
    legend?: string;
    addButton?: string;
    label?: string;
    itemWrapper?: string;
    noData?: string;
    item?: string;
  },
}

const tailwindStyles: Partial<CustomStyles> = {
  ...defaultStyles,
  control: {
    error: 'subtext error',
    description: 'subtext description',
    wrapper: 'mt-[6px]',
    wrapperError: 'wrapper-error',
    label: 'label mb-[-5px] block',
    labelText: 'label-text',
    textarea: 'textarea-style',
    text: 'text',
  },
  verticalLayout: {
    item: 'vertical-layout-item',
  },
  horizontalLayout: {
    root: 'horizontal-layout-root',
    item: 'horizontal-layout-item',
  },
  group: {
    item: 'group-item',
  },
  arrayList: {
    itemDelete: 'item-delete',
  },
  label: {
    root: 'label-root',
  },
  financialTable: {
    item: 'financial-table-item',
  },
};

const renderers = [
  ...customRenderers,
  ...vanillaRenderers,
];

export default defineComponent({
  name: 'FormGenerator',
  components: {
    JsonForms,
  },
  emits: ['change'],
  provide: {
    styles: tailwindStyles,
  },
  props: {
    information: {
      required: true,
      type: [String, Number, Boolean, Array, Object] as PropType<JsonData>,
    },
    schema: {
      required: true,
      type: [Object, Boolean] as PropType<JsonSchema>,
      default: undefined,
    },
    uischema: {
      required: true,
      type: Object as PropType<UISchemaElement>,
      default: undefined,
    },
  },
  data() {
    return {
      data: this.information,
      oldData: {},
    };
  },
  watch: {
    information() {
      this.data = this.information;
    },
  },
  computed: {
    renderers(): readonly JsonFormsRendererRegistryEntry[] {
      return Object.freeze(renderers);
    },
    i18n(): JsonFormsI18nState {
      return {
        locale: 'ru',
        translateError: this.translateError,
      };
    },
  },
  methods: {
    onChange(event: JsonFormsChangeEvent) {
      const changedData = getChangedData(event.data, this.oldData);

      changeConfirmation(
        Object.keys(changedData).length > 0 && !this.$refs.jsonForms.config?.onlyText,
      );

      this.data = event.data
        ? this.checkSchemaRule(event.data, this.schema, this.uischema)
        : this.data;

      this.$emit('change', event, this.schema, this.uischema);
    },
    translateError(error: ErrorObject) {
      const errors = [error];

      localize.ru(errors);

      return errors[0].message ?? '';
    },
  },
  mounted() {
    this.oldData = this.data;
    changeConfirmation(false);
  },
  unmounted() {
    changeConfirmation(false);
  },
  setup() {
    const { checkSchemaRule } = useCheckRules();

    return {
      checkSchemaRule,
    };
  },
});
