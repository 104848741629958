
import { defineComponent } from 'vue';
import TheBadge from '@/components/ui/badges/TheBadge.vue';
import LockKeyholeIcon from '@/components/ui/icons/LockKeyholeIcon.vue';

export default defineComponent({
  name: 'BlockedBadge',
  components: {
    TheBadge,
    LockKeyholeIcon,
  },
});
