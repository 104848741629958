import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f5d4cbee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item" }
const _hoisted_2 = { class: "item__info" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "item__additional-info" }
const _hoisted_5 = { class: "item__icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_shield_tooltip = _resolveComponent("shield-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", {
        class: "mr-1",
        innerHTML: _ctx.preparedText
      }, null, 8, _hoisted_3),
      _createElementVNode("span", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortedAdditionalInfo, (info, index) => {
          return (_openBlock(), _createElementBlock("span", { key: index }, [
            _createTextVNode(_toDisplayString(info.label) + " ", 1),
            _createElementVNode("span", {
              class: _normalizeClass({ 'font-semibold': info.isFound })
            }, _toDisplayString(_ctx.valueInfo(info)), 3)
          ]))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_ctx.isPriority)
        ? (_openBlock(), _createBlock(_component_shield_tooltip, { key: 0 }))
        : _createCommentVNode("", true)
    ])
  ]))
}