import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "general-menu" }
const _hoisted_2 = { class: "lg:relative" }
const _hoisted_3 = { class: "dictionary-button" }
const _hoisted_4 = { class: "flex lg:hidden" }
const _hoisted_5 = { class: "hidden lg:flex flex-col space-y-[12px] justify-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_list_icon = _resolveComponent("list-icon")!
  const _component_icon_button = _resolveComponent("icon-button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_user_group_icon = _resolveComponent("user-group-icon")!
  const _component_link_icon = _resolveComponent("link-icon")!
  const _component_book_open_icon = _resolveComponent("book-open-icon")!
  const _component_block_arrow = _resolveComponent("block-arrow")!
  const _component_international_icon = _resolveComponent("international-icon")!
  const _component_default_button = _resolveComponent("default-button")!
  const _component_map_icon = _resolveComponent("map-icon")!
  const _component_building_icon = _resolveComponent("building-icon")!
  const _component_factory_icon = _resolveComponent("factory-icon")!
  const _component_view_grid_icon = _resolveComponent("view-grid-icon")!
  const _component_library_icon = _resolveComponent("library-icon")!
  const _component_document_report_icon = _resolveComponent("document-report-icon")!
  const _component_clipboard_list_icon = _resolveComponent("clipboard-list-icon")!
  const _component_chart_pie_icon = _resolveComponent("chart-pie-icon")!
  const _component_presentation_chart_line = _resolveComponent("presentation-chart-line")!
  const _component_trending_up_icon = _resolveComponent("trending-up-icon")!
  const _component_briefcase_icon = _resolveComponent("briefcase-icon")!
  const _component_academic_cap_icon = _resolveComponent("academic-cap-icon")!
  const _component_users_icon = _resolveComponent("users-icon")!
  const _component_credit_card_icon = _resolveComponent("credit-card-icon")!
  const _component_the_custom_notification = _resolveComponent("the-custom-notification")!
  const _component_user_circle_icon = _resolveComponent("user-circle-icon")!
  const _component_question_mark_circle_icon = _resolveComponent("question-mark-circle-icon")!
  const _directive_outside_click = _resolveDirective("outside-click")!

  return (_ctx.isShowSidebar)
    ? (_openBlock(), _createElementBlock("nav", {
        key: 0,
        class: _normalizeClass(["navigation-bar", _ctx.mobileClass])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_router_link, {
            to: "/",
            class: "counterparty-button",
            alt: _ctx.$t('sidebar.title.counterparties'),
            title: _ctx.$t('sidebar.title.counterparties')
          }, {
            default: _withCtx(({isActive}) => [
              _createVNode(_component_icon_button, null, {
                default: _withCtx(() => [
                  _createVNode(_component_list_icon, {
                    color: _ctx.isActiveColor(isActive)
                  }, null, 8, ["color"])
                ]),
                _: 2
              }, 1024)
            ]),
            _: 1
          }, 8, ["alt", "title"]),
          _createVNode(_component_router_link, {
            to: "/admin/users",
            class: "users-button",
            alt: _ctx.$t('sidebar.title.users'),
            title: _ctx.$t('sidebar.title.users')
          }, {
            default: _withCtx(({isActive}) => [
              _createVNode(_component_icon_button, null, {
                default: _withCtx(() => [
                  _createVNode(_component_user_group_icon, {
                    type: "outline",
                    color: _ctx.isActiveColor(isActive)
                  }, null, 8, ["color"])
                ]),
                _: 2
              }, 1024)
            ]),
            _: 1
          }, 8, ["alt", "title"]),
          (_ctx.isShowAssign)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: "/admin/dictionary/pinning",
                class: "assign-button",
                alt: _ctx.$t('sidebar.title.pinning'),
                title: _ctx.$t('sidebar.title.pinning')
              }, {
                default: _withCtx(({isActive}) => [
                  _createVNode(_component_icon_button, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_link_icon, {
                        color: _ctx.isActiveColor(isActive)
                      }, null, 8, ["color"])
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 1
              }, 8, ["alt", "title"]))
            : _createCommentVNode("", true),
          _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_icon_button, { onClick: _ctx.toggleDictionary }, {
                default: _withCtx(() => [
                  _withDirectives(_createVNode(_component_book_open_icon, null, null, 512), [
                    [_vShow, !_ctx.isOpen]
                  ]),
                  _withDirectives(_createVNode(_component_block_arrow, { class: "rotate-180 lg:rotate-[-90deg]" }, null, 512), [
                    [_vShow, _ctx.isOpen]
                  ])
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _createElementVNode("div", {
              class: _normalizeClass([{open: _ctx.isOpen}, "dictionary-menu"]),
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleDictionary && _ctx.toggleDictionary(...args)))
            }, [
              _createVNode(_component_router_link, {
                to: "/admin/dictionary/countries",
                alt: _ctx.$t('sidebar.title.countries')
              }, {
                default: _withCtx(({isActive}) => [
                  _createVNode(_component_default_button, { type: "inline" }, {
                    icon: _withCtx(() => [
                      _createVNode(_component_international_icon, {
                        color: _ctx.isActiveColor(isActive)
                      }, null, 8, ["color"])
                    ]),
                    default: _withCtx(() => [
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('sidebar.title.countries')), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 1
              }, 8, ["alt"]),
              _createVNode(_component_router_link, {
                to: "/admin/dictionary/region",
                alt: _ctx.$t('sidebar.title.region')
              }, {
                default: _withCtx(({isActive}) => [
                  _createVNode(_component_default_button, { type: "inline" }, {
                    icon: _withCtx(() => [
                      _createVNode(_component_map_icon, {
                        type: "outline",
                        color: _ctx.isActiveColor(isActive)
                      }, null, 8, ["color"])
                    ]),
                    default: _withCtx(() => [
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('sidebar.title.region')), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 1
              }, 8, ["alt"]),
              _createVNode(_component_router_link, {
                to: "/admin/dictionary/structure",
                alt: _ctx.$t('sidebar.title.structure')
              }, {
                default: _withCtx(({isActive}) => [
                  _createVNode(_component_default_button, { type: "inline" }, {
                    icon: _withCtx(() => [
                      _createVNode(_component_building_icon, {
                        color: _ctx.isActiveColor(isActive)
                      }, null, 8, ["color"])
                    ]),
                    default: _withCtx(() => [
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('sidebar.title.structure')), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 1
              }, 8, ["alt"]),
              (_ctx.isShowCompany)
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    to: "/admin/dictionary/company",
                    alt: _ctx.$t('sidebar.title.companies')
                  }, {
                    default: _withCtx(({isActive}) => [
                      _createVNode(_component_default_button, { type: "inline" }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_factory_icon, {
                            color: _ctx.isActiveColor(isActive)
                          }, null, 8, ["color"])
                        ]),
                        default: _withCtx(() => [
                          _createTextVNode(" " + _toDisplayString(_ctx.$t('sidebar.title.companies')), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 1
                  }, 8, ["alt"]))
                : _createCommentVNode("", true),
              _createVNode(_component_router_link, {
                to: "/admin/dictionary/category-counterparty",
                alt: _ctx.$t('sidebar.title.categories')
              }, {
                default: _withCtx(({isActive}) => [
                  _createVNode(_component_default_button, { type: "inline" }, {
                    icon: _withCtx(() => [
                      _createVNode(_component_view_grid_icon, {
                        color: _ctx.isActiveColor(isActive)
                      }, null, 8, ["color"])
                    ]),
                    default: _withCtx(() => [
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('sidebar.title.categories')), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 1
              }, 8, ["alt"]),
              _createVNode(_component_router_link, {
                to: "/admin/dictionary/bank",
                alt: _ctx.$t('sidebar.title.banks')
              }, {
                default: _withCtx(({isActive}) => [
                  _createVNode(_component_default_button, { type: "inline" }, {
                    icon: _withCtx(() => [
                      _createVNode(_component_library_icon, {
                        color: _ctx.isActiveColor(isActive)
                      }, null, 8, ["color"])
                    ]),
                    default: _withCtx(() => [
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('sidebar.title.banks')), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 1
              }, 8, ["alt"]),
              _createVNode(_component_router_link, {
                to: "/admin/dictionary/industry",
                alt: _ctx.$t('sidebar.title.industries')
              }, {
                default: _withCtx(({isActive}) => [
                  _createVNode(_component_default_button, { type: "inline" }, {
                    icon: _withCtx(() => [
                      _createVNode(_component_document_report_icon, {
                        color: _ctx.isActiveColor(isActive)
                      }, null, 8, ["color"])
                    ]),
                    default: _withCtx(() => [
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('sidebar.title.industries')), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 1
              }, 8, ["alt"]),
              _createVNode(_component_router_link, {
                to: "/admin/dictionary/psychological-characteristic",
                alt: _ctx.$t('sidebar.title.psychologicalCharacteristics')
              }, {
                default: _withCtx(({isActive}) => [
                  _createVNode(_component_default_button, { type: "inline" }, {
                    icon: _withCtx(() => [
                      _createVNode(_component_clipboard_list_icon, {
                        type: "outline",
                        color: _ctx.isActiveColor(isActive)
                      }, null, 8, ["color"])
                    ]),
                    default: _withCtx(() => [
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('sidebar.title.psychologicalCharacteristics')), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 1
              }, 8, ["alt"]),
              _createVNode(_component_router_link, {
                to: "/admin/dictionary/nomenclature-group",
                alt: _ctx.$t('sidebar.title.nomenclatureGroup')
              }, {
                default: _withCtx(({isActive}) => [
                  _createVNode(_component_default_button, { type: "inline" }, {
                    icon: _withCtx(() => [
                      _createVNode(_component_chart_pie_icon, {
                        type: "outline",
                        color: _ctx.isActiveColor(isActive)
                      }, null, 8, ["color"])
                    ]),
                    default: _withCtx(() => [
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('sidebar.title.nomenclatureGroup')), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 1
              }, 8, ["alt"]),
              (_ctx.isShowTechnologicalSupportType)
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 1,
                    to: "/admin/dictionary/technological-support-type",
                    alt: _ctx.$t('sidebar.title.technologicalSupportType')
                  }, {
                    default: _withCtx(({isActive}) => [
                      _createVNode(_component_default_button, { type: "inline" }, {
                        icon: _withCtx(() => [
                          _createVNode(_component_presentation_chart_line, {
                            color: _ctx.isActiveColor(isActive)
                          }, null, 8, ["color"])
                        ]),
                        default: _withCtx(() => [
                          _createTextVNode(" " + _toDisplayString(_ctx.$t('sidebar.title.technologicalSupportType')), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 1
                  }, 8, ["alt"]))
                : _createCommentVNode("", true),
              _createVNode(_component_router_link, {
                to: "/admin/dictionary/activity-direction",
                alt: _ctx.$t('sidebar.title.activityDirection')
              }, {
                default: _withCtx(({isActive}) => [
                  _createVNode(_component_default_button, { type: "inline" }, {
                    icon: _withCtx(() => [
                      _createVNode(_component_trending_up_icon, {
                        type: "outline",
                        color: _ctx.isActiveColor(isActive)
                      }, null, 8, ["color"])
                    ]),
                    default: _withCtx(() => [
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('sidebar.title.activityDirection')), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 1
              }, 8, ["alt"]),
              _createVNode(_component_router_link, {
                to: "/admin/dictionary/position",
                alt: _ctx.$t('sidebar.title.position')
              }, {
                default: _withCtx(({isActive}) => [
                  _createVNode(_component_default_button, { type: "inline" }, {
                    icon: _withCtx(() => [
                      _createVNode(_component_briefcase_icon, {
                        type: "outline",
                        color: _ctx.isActiveColor(isActive)
                      }, null, 8, ["color"])
                    ]),
                    default: _withCtx(() => [
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('sidebar.title.position')), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 1
              }, 8, ["alt"]),
              _createVNode(_component_router_link, {
                to: "/admin/dictionary/education",
                alt: _ctx.$t('sidebar.title.education')
              }, {
                default: _withCtx(({isActive}) => [
                  _createVNode(_component_default_button, { type: "inline" }, {
                    icon: _withCtx(() => [
                      _createVNode(_component_academic_cap_icon, {
                        type: "outline",
                        color: _ctx.isActiveColor(isActive)
                      }, null, 8, ["color"])
                    ]),
                    default: _withCtx(() => [
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('sidebar.title.education')), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 1
              }, 8, ["alt"]),
              _createVNode(_component_router_link, {
                to: "/admin/dictionary/individuals",
                alt: _ctx.$t('sidebar.title.individual')
              }, {
                default: _withCtx(({isActive}) => [
                  _createVNode(_component_default_button, { type: "inline" }, {
                    icon: _withCtx(() => [
                      _createVNode(_component_users_icon, {
                        type: "outline",
                        color: _ctx.isActiveColor(isActive)
                      }, null, 8, ["color"])
                    ]),
                    default: _withCtx(() => [
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('sidebar.title.individual')), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 1
              }, 8, ["alt"]),
              _createVNode(_component_router_link, {
                to: "/admin/dictionary/nomenclature",
                alt: _ctx.$t('sidebar.title.nomenclature')
              }, {
                default: _withCtx(({isActive}) => [
                  _createVNode(_component_default_button, { type: "inline" }, {
                    icon: _withCtx(() => [
                      _createVNode(_component_credit_card_icon, {
                        type: "outline",
                        color: _ctx.isActiveColor(isActive)
                      }, null, 8, ["color"])
                    ]),
                    default: _withCtx(() => [
                      _createTextVNode(" " + _toDisplayString(_ctx.$t('sidebar.title.nomenclature')), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 1
              }, 8, ["alt"])
            ], 2)
          ])), [
            [_directive_outside_click, _ctx.closeDictionary]
          ]),
          (!this.$sizeWindow.isLg)
            ? (_openBlock(), _createBlock(_component_the_custom_notification, {
                key: 1,
                class: "notification-button"
              }))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_router_link, {
              to: "/profile",
              alt: _ctx.$t('sidebar.title.profile'),
              title: _ctx.$t('sidebar.title.profile')
            }, {
              default: _withCtx(({isActive}) => [
                _createVNode(_component_icon_button, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_user_circle_icon, {
                      type: "outline",
                      color: _ctx.isActiveColor(isActive)
                    }, null, 8, ["color"])
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 1
            }, 8, ["alt", "title"])
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_icon_button, {
            class: "help-button",
            "show-badge": !_ctx.isLearnedPage,
            onClick: _ctx.startTour
          }, {
            default: _withCtx(() => [
              _createVNode(_component_question_mark_circle_icon, { type: "outline" })
            ]),
            _: 1
          }, 8, ["show-badge", "onClick"]),
          _createVNode(_component_router_link, {
            to: "/profile",
            class: "profile-button",
            alt: _ctx.$t('sidebar.title.profile'),
            title: _ctx.$t('sidebar.title.profile')
          }, {
            default: _withCtx(({isActive}) => [
              _createVNode(_component_icon_button, null, {
                default: _withCtx(() => [
                  _createVNode(_component_user_circle_icon, {
                    type: "outline",
                    color: _ctx.isActiveColor(isActive)
                  }, null, 8, ["color"])
                ]),
                _: 2
              }, 1024)
            ]),
            _: 1
          }, 8, ["alt", "title"])
        ])
      ], 2))
    : _createCommentVNode("", true)
}