
import { defineComponent, PropType } from 'vue';
import _ from 'lodash';
import CloseIcon from '@/components/ui/icons/CloseIcon.vue';
import SelectRemoveIcon from '@/components/ui/icons/SelectRemoveIcon.vue';
import SelectArrow from '@/components/ui/icons/SelectArrow.vue';
import SearchIcon from '@/components/ui/icons/SearchIcon.vue';
import CalculatePosition from '@/utils/directives/CalculatePosition';
import OutsideClick from '@/utils/directives/OutsideClick';
import TheItem from '@/components/ui/selects/SelectTree/item/TheItem.vue';
import {
  findElementById,
  clearChildrenElement,
  findToTree,
  fillSearchText,
} from '@/components/jsonforms/helpers/TreeHelper';

export interface DataTypeTree<T = string | number> {
  id: T,
  name: string,
  items?: DataTypeTree<T>[]
}

export default defineComponent({
  name: 'SelectTree',
  emits: ['valueSelect', 'blur'],
  components: {
    SelectRemoveIcon,
    SelectArrow,
    SearchIcon,
    CloseIcon,
    TheItem,
  },
  props: {
    data: {
      type: Array as PropType<DataTypeTree[]>,
      default: () => ([]),
    },
    value: [Number, String, Array],
    clearable: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    calculatePosition: {
      type: [Boolean, Object],
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localValue: this.multiple && !this.value ? [] : this.value,
      localItems: [],
      isOpen: false,
      search: '',
    };
  },
  computed: {
    showPlaceholder() {
      const hasLocalValue = this.multiple && Array.isArray(this.localValue)
        ? !!this.localValue.length
        : !!this.localValue;

      return this.placeholder && !hasLocalValue;
    },
    showClearButton(): boolean {
      return this.clearable
        && this.isOpen
        && !this.disabled
        && !this.required
        && !!this.localValue
        && !this.multiple;
    },
  },
  watch: {
    data() {
      this.localItems = _.cloneDeep(this.data);
    },
    isOpen(value: boolean, oldValue: boolean) {
      this.search = '';

      if (value) {
        const ref = this.$refs.search as HTMLInputElement;

        if (ref) ref.focus();
      }

      if (!value && oldValue) {
        this.$emit('blur', this.localValue);
      }
    },
    value() {
      this.localValue = this.value;
    },
    search(value) {
      if (value !== '') {
        this.isOpen = true;
        this.localItems = findToTree(_.cloneDeep(this.data), value);
        fillSearchText(this.localItems, value);
      } else {
        this.localItems = _.cloneDeep(this.data);
      }
    },
  },
  methods: {
    isSelected(value: string | number) {
      return Array.isArray(this.localValue)
        ? this.localValue.includes(value)
        : this.localValue === value;
    },
    closeDropdown() {
      this.isOpen = false;
    },
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    getNameByValue(value: string | number) {
      const item = findElementById(this.data, value);

      return item?.name ?? '';
    },
    remove(value: string | number) {
      this.localValue = this.multiple && Array.isArray(this.localValue)
        ? this.localValue.filter((localValue: string | number) => localValue !== value)
        : '';
      this.$emit('valueSelect', this.localValue);
    },
    select(value: string | number) {
      if (this.multiple && Array.isArray(this.localValue)) {
        const selectedItem: DataTypeTree | undefined = findElementById(this.data, value);

        if (selectedItem
            && selectedItem.items
            && selectedItem.items.length > 0
            && !this.localValue.includes(value)
        ) {
          this.localValue = clearChildrenElement(selectedItem.items, this.localValue);
        }

        this.localValue = this.localValue.includes(value)
          ? this.localValue.filter((localValue: string | number) => localValue !== value)
          : Array.from(new Set([...this.localValue, value]));
      } else {
        this.localValue = value;
      }

      if (!this.multiple) {
        this.closeDropdown();
      }

      this.$emit('valueSelect', this.localValue);
    },
  },
  directives: {
    OutsideClick,
    CalculatePosition,
  },
  created() {
    this.localItems = _.cloneDeep(this.data);
  },
});
