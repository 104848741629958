import store from '@/store';
import AgentService, { AgentResponseType, AgentSchema } from '@/api/agent';

export function useOneApi() {
  return {
    async loadData(id: string): Promise<AgentSchema | null> {
      const currentCardId = store.getters['counterparty/card/currentCardId'];

      if (!currentCardId) {
        return null;
      }

      const api: AgentService = store.getters.$api.agent;

      const response:AgentResponseType = await api.one(id, currentCardId);

      return response.data ?? null;
    },
  };
}

export default {};
