
import { defineComponent, PropType } from 'vue';
import { JsonSchema } from '@jsonforms/core';
import TheDangerModal from '@/components/ui/modal/TheDangerModal/TheDangerModal.vue';
import StatusBar from '@/components/counterparty/card/blocks/history/status/StatusBar.vue';
import ItemContent from '@/components/counterparty/card/blocks/history/content/ItemContent.vue';
import { HistoryItemType } from '@/api/block/history';
import TheSideModal from '@/components/ui/modal/TheSideModal/TheSideModal.vue';
import DefaultLoader from '@/components/ui/loaders/DefaultLoader.vue';

const initialState = () => ({
  isLoading: false,
  openDangerModal: false,
  currentHistoryItemId: '',
  items: [],
  pagination: {
    currentPage: null,
    pageCount: null,
    perPage: null,
    totalCount: null,
  },
  loadParams: {
    page: 1,
    perPage: 20,
    sortBy: '-createdAt',
  },
});

export default defineComponent({
  name: 'BlocksHistory',
  inject: ['$sizeWindow'],
  emits: ['closeHistory', 'deleteBlockHistory'],
  components: {
    DefaultLoader,
    ItemContent,
    StatusBar,
    TheSideModal,
    TheDangerModal,
  },
  props: {
    loadHistory: Function,
    blockId: {
      type: String,
      default: '',
    },
    itemId: {
      type: [String, Number],
      default: null,
    },
    blockName: {
      type: String,
      default: '',
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    schema: {
      type: Object as PropType<JsonSchema>,
      default: () => ({}),
    },
  },
  data: initialState,
  watch: {
    async isOpen() {
      if (this.isOpen) {
        await this.loadLocalHistory();

        await this.addScrollEvent();

        this.currentHistoryItemId = this.items?.[0]?.id ?? '';
      } else {
        this.setInitialState();
      }
    },
  },
  computed: {
    activeItem(): HistoryItemType | undefined {
      return this.items.find((item: HistoryItemType) => item.id === this.currentHistoryItemId);
    },
    disableNextPage() {
      return this.pagination.pageCount === 0
        || this.pagination.currentPage === this.pagination.pageCount;
    },
    preparedLoadParams() {
      return { ...this.loadParams, itemId: this.itemId };
    },
  },
  methods: {
    setInitialState() {
      Object.assign(this.$data, initialState());
    },
    closeHistory() {
      this.$emit('closeHistory');
    },
    clickCardHandler(id: string) {
      this.currentHistoryItemId = id;
    },
    removeHandler(id: string) {
      this.openDangerModal = true;
      this.currentHistoryItemId = id;
    },
    closeModal() {
      this.openDangerModal = false;
    },
    async addScrollEvent() {
      const element = document.querySelector('.history-cards');

      if (element) {
        element.addEventListener('scroll', async () => {
          if (element.scrollTop + element.clientHeight >= element.scrollHeight) {
            await this.infinityLoadHistory();
          }
        });
      }
    },
    async approvalHandler(next?: () => void) {
      this.$emit('deleteBlockHistory', this.currentHistoryItemId);
      this.openDangerModal = false;

      if (next) {
        next();
      }
    },
    async infinityLoadHistory() {
      if (!this.isLoading && !this.disableNextPage) {
        this.loadParams.page += 1;

        await this.loadLocalHistory();
      }
    },
    async loadLocalHistory() {
      this.isLoading = true;

      const response = await this.loadHistory({
        id: this.blockId,
        params: this.preparedLoadParams,
      });

      const responseItems = response.items ?? [];

      this.items = [...this.items, ...responseItems];
      this.pagination = response._meta;

      this.isLoading = false;
    },
  },
});
