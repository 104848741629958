import { AxiosCallResponse } from '@/api/base';
import store from '@/store';
import CategoryService, { CategoryCounterpartyData } from '@/api/admin/dictionary/category';

export function useOneApi() {
  return {
    async loadData(id: string) {
      const api: CategoryService = store.getters.$api.admin.dictionary.category;

      const response: AxiosCallResponse<CategoryCounterpartyData> = await api.one(id);

      return response.data ?? null;
    },
  };
}

export default {};
