import DictionaryBase, { DictionaryListResponseType } from '@/api/admin/dictionary/base';
import { LoggerInterface } from '@/api/base';

export interface RegionItem {
  id: string,
  name: string,
}

export interface RegionData {
  name: string,
}

export type RegionListResponseType = DictionaryListResponseType<RegionItem>;

export default class RegionService extends DictionaryBase {
  constructor(token: string, logger: LoggerInterface = console) {
    super('region', token, logger);
  }
}
