import RegionService, { RegionListResponseType } from '@/api/admin/dictionary/region';
import { QueryParamsForList } from '@/api/base';
import store from '@/store';

export function useListApi() {
  return {
    async loadItems(params: QueryParamsForList) {
      const api: RegionService = store.getters.$api.admin.dictionary.region;

      const response: RegionListResponseType = await api.list(
        params,
      );

      return response.data?.items ?? [];
    },
  };
}

export default {};
