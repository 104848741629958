<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 15.1998C9.90956 15.1998 11.7409 14.4412 13.0912 13.091C14.4414 11.7407 15.2 9.90936
 15.2 7.9998C15.2 6.09025 14.4414 4.2589 13.0912 2.90864C11.7409 1.55837 9.90956 0.799805
 8 0.799805C6.09044 0.799805 4.25909 1.55837 2.90883 2.90864C1.55857 4.2589 0.799999 6.09025
 0.799999 7.9998C0.799999 9.90936 1.55857 11.7407 2.90883 13.091C4.25909 14.4412 6.09044
 15.1998 8 15.1998ZM5.3 7.0998C5.0613 7.0998 4.83239 7.19463 4.6636 7.36341C4.49482 7.53219
 4.4 7.76111 4.4 7.9998C4.4 8.2385 4.49482 8.46742 4.6636 8.6362C4.83239 8.80498 5.0613
 8.8998 5.3 8.8998H10.7C10.9387 8.8998 11.1676 8.80498 11.3364 8.6362C11.5052 8.46742
 11.6 8.2385 11.6 7.9998C11.6 7.76111 11.5052 7.53219 11.3364 7.36341C11.1676 7.19463
 10.9387 7.0998 10.7 7.0998H5.3Z"
      fill="#64748B"
    />
  </svg>
</template>
