import { Module } from 'vuex';
import { MetaParams, SortParams } from '@/api/base';
import { StateType } from '@/store';
import UserService, {
  UserItem,
  UserListFilterParams,
  UserListQueryParams,
  UserListResponseType,
} from '@/api/admin/user';

export interface UserListStateType {
  items: UserItem[],
  pagination: MetaParams,
  filter: UserListFilterParams,
  sort: SortParams,
}

const getInitialState = (): UserListStateType => ({
  items: [],
  pagination: {
    currentPage: null,
    pageCount: null,
    perPage: null,
    totalCount: null,
  },
  filter: {
    query: null,
    role: null,
    division: null,
    status: null,
  },
  sort: {
    sortBy: null,
  },
});

const initialState = getInitialState();

const userListModule: Module<UserListStateType, StateType> = {
  namespaced: true,
  state: initialState,
  getters: {
    pagination: (state): MetaParams => state.pagination,
    filter: (state): UserListFilterParams => state.filter,
    sort: (state): SortParams => state.sort,
    items: (state): UserItem[] => state.items,
    loadParams: (state): UserListQueryParams => ({
      page: state.pagination.currentPage,
      perPage: state.pagination.perPage,
      query: state.filter.query,
      role: state.filter.role,
      division: state.filter.division,
      company: state.filter.company,
      department: state.filter.department,
      status: state.filter.status,
      sortBy: state.sort.sortBy,
    }),
  },
  mutations: {
    setPagination(state, pagination: MetaParams) {
      state.pagination = pagination;
    },
    setItems(state, items) {
      state.items = items;
    },
    setFilter(state, filter: UserListFilterParams) {
      state.filter = filter;
    },
    setSort(state, sort) {
      state.sort = sort;
    },
    resetState: (state) => {
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    resetState({ commit }) {
      commit('resetState');
    },
    async changePagination({ dispatch, commit }, pagination: MetaParams) {
      commit('setPagination', pagination);

      await dispatch('loadItems');
    },
    async changeFilter({ dispatch, commit, state }, filter: UserListFilterParams) {
      commit('setFilter', filter);
      commit('setPagination', { ...state.pagination, currentPage: 1 });

      await dispatch('loadItems');
    },
    async changeSort({ dispatch, commit }, sort: SortParams) {
      commit('setSort', sort);

      await dispatch('loadItems');
    },
    async loadItems({ getters, rootGetters, commit }, filter: UserListFilterParams | null = null) {
      const localFilterParams = filter ?? getters.loadParams;

      const api: UserService = rootGetters.$api.admin.user;

      const response: UserListResponseType = await api.list(localFilterParams);

      commit('setItems', response.data?.items ?? initialState.items);
      commit('setPagination', response.data?._meta ?? initialState.pagination);
      commit('setFilter', localFilterParams);
    },
  },
};

export default userListModule;
