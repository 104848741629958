import { entry as groupRendererEntry } from './GroupRenderer.vue';
import { entry as layoutRendererEntry } from './LayoutRenderer.vue';

export { default as GroupRenderer } from './GroupRenderer.vue';
export { default as LayoutRenderer } from './LayoutRenderer.vue';

export const layoutRenderers = [
  groupRendererEntry,
  layoutRendererEntry,
];
