import { Module } from 'vuex';
import card, { CounterpartyCardStateType } from '@/store/modules/counterparty/card';
import list, { CounterpartyListStateType } from '@/store/modules/counterparty/list';
import agent, { AgentStateType } from '@/store/modules/counterparty/block/agents';
import { StateType } from '@/store';
import one, { CounterpartyOneStateType } from '@/store/modules/counterparty/one';
import block, { BlockStateType } from '@/store/modules/counterparty/block';

export interface CounterpartyStateType {
  list: CounterpartyListStateType,
  one: CounterpartyOneStateType,
  card: CounterpartyCardStateType,
  agent: AgentStateType,
  block: BlockStateType,
}

const counterpartyModule: Module<CounterpartyStateType, StateType> = {
  namespaced: true,
  modules: {
    list,
    one,
    card,
    agent,
    block,
  },
};

export default counterpartyModule;
