
import { defineComponent } from 'vue';
import { ErrorObject } from 'ajv';
import { JsonFormsChangeEvent } from '@jsonforms/vue';
import { mapActions, mapGetters } from 'vuex';
import DefaultButton from '@/components/ui/buttons/DefaultButton/DefaultButton.vue';
import TheCounterpartyData from '@/components/admin/dictionary/assign/forms/counterpartyData/TheCounterpartyData.vue';
import DefaultLoader from '@/components/ui/loaders/DefaultLoader.vue';
import { PinningItem } from '@/api/admin/pinning';
import { validateData } from '@/utils/helpers/AjvValidator';
import useShowErrors from '@/composables/form/useShowErrors';

export default defineComponent({
  name: 'TheDetach',
  emits: ['detach', 'cancel'],
  components: {
    DefaultLoader, TheCounterpartyData, DefaultButton,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isLoading: true,
      errors: [] as ErrorObject[],
      currentData: {},
      schema: {
        type: 'object',
        properties: {
          detachmentDate: {
            type: 'string',
            format: 'date',
          },
          comment: {
            type: 'string',
          },
          reason: {
            type: ['string', 'null'],
            enum: [
              'Автоматическое открепление',
              'Банкротство',
              'Все фермы закреплены за менеджерами ДЭК',
              'Все фермы закреплены за менеджерами МСД',
              'Выведен из рациона в связи с высокой ценой',
              'Добровольный отказ менеджера от закрепленных за ним клиентов',
              'Долгосрочные контракты ГМО шрота с Содружество, ПШ берут у местных производителей. Не проходная цена',
              'Другое направление деятельности',
              'Закуп через другое юр.лицо',
              'Закупка только через Госзакупку',
              'Запрещение работы СБ',
              'Имеет собственную кормовую базу',
              'Используют ПЖ',
              'Маленькое поголовье',
              'Маленькое поголовье,отсутствие средств',
              'Не выходят на связь',
              'Не используют ЗЖ',
              'Не используют СШ',
              'Не проходим по качеству ОП',
              'Не рентабельно заменять шрот на ЗП',
              'Нет поголовья',
              'Нет разрешения на переработку ОП',
              'Нет связи с клиентом',
              'Неудовлетворительное финансовое состояние',
              'Открепление в свободный блок',
              'Передан в работу по ЗЖ местному дистрибьютору',
              'Планируется передача клиента дистрибьютору',
              'По распоряжению начальника ОП',
              'Прекращение деятельности, связанной с поставкой масел',
              'Прекращение деятельности/ликвидация юр. лица',
              'Прекращение сотрудничества',
              'Расположен в Калининградской области',
              'Реарганизация',
              'Решение комитета',
              'Смена менеджера',
              'Судебные разбирательства по Ком кредиту',
              'Увольнение менеджера',
              'Шрота не используют',
            ],
          },
          reasonComment: {
            type: 'string',
          },
        },
        required: [
          'detachmentDate',
          'reason',
        ],
      },
      uischema: {
        type: 'VerticalLayout',
        elements: [
          {
            type: 'Control',
            label: this.$t('dictionary.pinning.fields.detachmentDate'),
            scope: '#/properties/detachmentDate',
            options: {
              format: 'date',
            },
          },
          {
            type: 'Control',
            label: this.$t('dictionary.pinning.fields.reasonDetachment'),
            scope: '#/properties/reason',
          },
          {
            type: 'Control',
            label: this.$t('dictionary.pinning.fields.reasonComment'),
            scope: '#/properties/reasonComment',
            options: {
              multi: true,
            },
          },
          {
            type: 'Control',
            label: this.$t('dictionary.pinning.fields.comment'),
            scope: '#/properties/comment',
            options: {
              multi: true,
            },
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters({
      counterpartyData: 'admin/dictionary/pinning/one/detail',
    }),
  },
  methods: {
    ...mapActions({
      loadDetailData: 'admin/dictionary/pinning/one/get',
    }),
    cancelHandler() {
      this.$emit('cancel');
    },
    onChange(event: JsonFormsChangeEvent) {
      this.currentData = event.data;
    },
    validateForm(showErrors = false): boolean {
      this.errors = validateData(this.currentData, this.schema, this.uischema);

      if (showErrors) {
        this.showErrors(this.errors);
      }

      return !this.errors || !this.errors.length;
    },
    detachHandler() {
      this.isLoading = true;

      const valid: boolean = this.validateForm(true) ?? false;
      const data: PinningItem | null = this.currentData ?? null;

      if (!valid || !data) {
        this.isLoading = false;

        return false;
      }

      this.$emit('detach', data, () => {
        this.isLoading = false;
      });

      return true;
    },
    async loadData() {
      this.isLoading = true;
      await this.loadDetailData(this.id);
      this.isLoading = false;
    },
  },
  setup() {
    const { showErrors } = useShowErrors();

    return { showErrors };
  },
  async mounted() {
    // TODO: Исправить в будущем
    this.timeoutFunction = setTimeout(this.loadData, 300);
  },
  unmounted() {
    clearTimeout(this.timeoutFunction);
  },
});
