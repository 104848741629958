
import { defineComponent, PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { JsonSchema, UISchemaElement } from '@jsonforms/core';
import TableBlockTemplate
  from '@/components/counterparty/card/blocks/table-block-template/TableBlockTemplate.vue';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import seminarHistory from '@/shared/consts/blocks/seminarHistory';
import TheFilter
  from '@/components/counterparty/card/blocks/relationship/seminarHistory/filter/TheFilter.vue';
import { PaginationParams, SortParams } from '@/api/base';
import {
  FilterSeminarHistory,
} from '@/store/modules/counterparty/block/relationship/seminarHistory/list';

export default defineComponent({
  name: 'TheSeminarHistory',
  components: { TheFilter, TableBlockTemplate },
  props: {
    title: {
      type: String,
      default: '',
    },
    schema: {
      required: true,
      type: Object as PropType<JsonSchema>,
      default: undefined,
    },
    uischema: {
      required: true,
      type: Object as PropType<UISchemaElement>,
      default: undefined,
    },
  },
  data() {
    return {
      fields: [
        {
          key: seminarHistory.BLOCK_FIELD_DATE, label: this.$t('counterparty.tabs.relationship.seminarHistory.date'), format: 'date',
        },
        { key: seminarHistory.BLOCK_FIELD_POINT, label: this.$t('counterparty.tabs.relationship.seminarHistory.point') },
        { key: seminarHistory.BLOCK_FIELD_NAME, label: this.$t('counterparty.tabs.relationship.seminarHistory.name') },
      ],
    };
  },
  computed: {
    ...mapGetters({
      items: 'counterparty/block/seminarHistory/list/items',
      filter: 'counterparty/block/seminarHistory/list/filter',
      sort: 'counterparty/block/seminarHistory/list/sort',
      pagination: 'counterparty/block/seminarHistory/list/pagination',
      currentCardId: 'counterparty/card/currentCardId',
    }),
  },
  methods: {
    ...mapActions({
      loadItems: 'counterparty/block/seminarHistory/list/loadItems',
      changeFilter: 'counterparty/block/seminarHistory/list/changeFilter',
      changeSort: 'counterparty/block/seminarHistory/list/changeSort',
      changePagination: 'counterparty/block/seminarHistory/list/changePagination',
      create: 'counterparty/block/seminarHistory/one/create',
      update: 'counterparty/block/seminarHistory/one/update',
      delete: 'counterparty/block/seminarHistory/one/delete',
    }),
    async createHandler(data: JsonData, next: () => void) {
      await this.create({
        cardId: this.currentCardId,
        data,
      });

      next();
      await this.loadItems(this.currentCardId);
    },
    async updateHandler(id: string, data: JsonData, next: () => void) {
      await this.update({
        cardId: this.currentCardId,
        id,
        data,
      });

      next();
      await this.loadItems(this.currentCardId);
    },
    async deleteHandler(id: string, next: () => void) {
      await this.delete({
        cardId: this.currentCardId,
        id,
      });

      next();
      await this.loadItems(this.currentCardId);
    },
    async changeSortHandler(sort: SortParams) {
      await this.changeSort(sort);

      await this.loadItems(this.currentCardId);
    },
    async changePaginationHandler(pagination: PaginationParams) {
      await this.changePagination(pagination);

      await this.loadItems(this.currentCardId);
    },
    async changeFilterHandler(filter: FilterSeminarHistory) {
      await this.changeFilter(filter);

      await this.loadItems(this.currentCardId);
    },
  },
  async created() {
    await this.loadItems(this.currentCardId);
  },
});
