import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b6e0ffce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text mb-[24px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_role_item = _resolveComponent("role-item")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.block.title), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.block.items, (item) => {
      return (_openBlock(), _createBlock(_component_role_item, {
        key: item.id,
        role: item,
        disabled: _ctx.disabled,
        "user-roles": _ctx.userRoles,
        "roles-attributes": _ctx.rolesAttributes,
        departments: _ctx.departments,
        onUpdated: _ctx.updatedHandler,
        onUpdateRoleAttributes: _ctx.updateRoleAttributesHandler
      }, null, 8, ["role", "disabled", "user-roles", "roles-attributes", "departments", "onUpdated", "onUpdateRoleAttributes"]))
    }), 128))
  ]))
}