import { defineComponent, PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { JsonSchema } from '@jsonforms/core';
import config from '@/config';
import AddIcon from '@/components/ui/icons/AddIcon.vue';
import TheSideModal from '@/components/ui/modal/TheSideModal/TheSideModal.vue';
import TheCreate from '@/components/counterparty/card/blocks/agents/modal/create/TheCreate.vue';
import TheDetail from '@/components/counterparty/card/blocks/agents/modal/detail/TheDetail.vue';
import BlocksHistory from '@/components/counterparty/card/blocks/history/BlocksHistory.vue';
import CustomUISchemaElement from '@/components/jsonforms/interfaces/CustomUISchemaElement';
import TheCard, {
  TYPE_VIEW,
  TYPE_EDIT,
  TYPE_HISTORY,
} from '@/components/counterparty/card/blocks/agents/card/TheCard.vue';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import { AgentSchema } from '@/api/agent';
import DefaultButton from '@/components/ui/buttons/DefaultButton/DefaultButton.vue';

const initialState = () => ({
  isOpen: false,
  isEdit: false,
  isView: false,
  isHistory: false,
  isLoading: false,
  selectedAgent: null,
});

export default defineComponent({
  name: 'TheAgents',
  components: {
    DefaultButton,
    TheCreate,
    TheCard,
    TheDetail,
    AddIcon,
    TheSideModal,
    BlocksHistory,
  },
  props: {
    schema: {
      required: true,
      type: Object as PropType<JsonSchema>,
      default: undefined,
    },
    uischema: {
      required: true,
      type: Object as PropType<CustomUISchemaElement>,
      default: undefined,
    },
    isCanEdit: {
      type: Boolean,
      default: false,
    },
  },
  data: initialState,
  computed: {
    ...mapGetters({
      items: 'counterparty/agent/list/items',
      currentCardId: 'counterparty/card/currentCardId',
    }),
    groupId() {
      return config.COUNTERPARTY_AGENT_BLOCK;
    },
  },
  methods: {
    ...mapActions({
      loadItems: 'counterparty/agent/list/loadItems',
      loadData: 'counterparty/agent/one/loadData',
      create: 'counterparty/agent/one/create',
      update: 'counterparty/agent/one/update',
      block: 'counterparty/agent/one/block',
      unblock: 'counterparty/agent/one/unblock',
      loadHistory: 'history/loadHistory',
    }),
    setInitialState() {
      Object.assign(this.$data, initialState());
    },
    openModal() {
      this.isOpen = true;
    },
    closeModal() {
      this.setInitialState();
    },
    closeHistory() {
      this.setInitialState();
    },
    openCreateModal() {
      this.isEdit = false;
      this.isView = false;
      this.isHistory = false;

      this.openModal();
    },
    cardOpenHandler(agent: AgentSchema, type: string) {
      this.selectedAgent = agent;

      this.isEdit = type === TYPE_EDIT;
      this.isView = type === TYPE_VIEW;
      this.isHistory = type === TYPE_HISTORY;

      if (!this.isHistory) {
        this.openModal();
      }
    },
    changedEditModeHandler(value: boolean) {
      this.isEdit = value;
      this.isView = !value;

      this.openModal();
    },
    async changeUserStatus(value: boolean) {
      if (value) {
        await this.blockAgentHandler(this.selectedAgent.id);
      } else {
        await this.unblockAgentHandler(this.selectedAgent.id);
      }
    },
    async createAgentHandler(data: JsonData, next: () => void) {
      const agent: AgentSchema | null = await this.create({
        cardId: this.currentCardId,
        data,
      });

      await this.loadItems(this.currentCardId);

      next();

      if (agent) {
        this.selectedAgent = agent;
        this.isEdit = true;

        this.openModal();
      }
    },
    async updateAgentHandler(id: string, data: JsonData, next: () => void) {
      await this.update({
        cardId: this.currentCardId,
        id,
        data,
      });

      await this.loadItems(this.currentCardId);

      next();
    },
    async blockAgentHandler(id: string) {
      await this.block({
        cardId: this.currentCardId,
        id,
      });

      this.selectedAgent = await this.loadData({
        cardId: this.currentCardId,
        id,
      });

      await this.loadItems(this.currentCardId);
    },
    async unblockAgentHandler(id: string) {
      await this.unblock({
        cardId: this.currentCardId,
        id,
      });

      this.selectedAgent = await this.loadData({
        cardId: this.currentCardId,
        id,
      });

      await this.loadItems(this.currentCardId);
    },
  },
  async created() {
    this.isLoading = true;

    await this.loadItems(this.currentCardId);

    this.isLoading = false;
  },
});
