import { defineComponent } from 'vue';
import useBlockScroll from '@/composables/modal/useBlockScroll';
import IconButton from '@/components/ui/buttons/IconButton/IconButton.vue';
import DefaultButton
  from '@/components/ui/buttons/DefaultButton/DefaultButton.vue';
import CloseIcon from '@/components/ui/icons/CloseIcon.vue';
import OutsideClick from '@/utils/directives/OutsideClick';

export default defineComponent({
  name: 'TheModal',
  inject: ['$sizeWindow'],
  components: {
    DefaultButton,
    CloseIcon,
    IconButton,
  },
  directives: {
    OutsideClick,
  },
  data() {
    return {
      isShowModal: false,
      isDisabledButton: false,
    };
  },
  created() {
    this.isDisabledButton = this.isDisabled ?? false;
  },
  emits: ['approvalHandler', 'closeModal'],
  props: {
    isOpen: Boolean,
    content: String,
    title: String,
    action: {
      type: Boolean,
      default: true,
    },
    addButtonText: String,
    width: {
      type: String,
      default: '640px',
    },
    isDisabled: Boolean,
  },
  watch: {
    isOpen(newVal) {
      this.toggleScrollBlock(newVal);
      this.toggleListener(newVal);

      this.isShowModal = newVal;
    },
    isDisabled(newVal) {
      this.isDisabledButton = newVal;
    },
  },
  methods: {
    closeModal() {
      this.isShowModal = false;
      this.$emit('closeModal', this.isShowModal);
    },
    outsideClose(event: { target: HTMLDivElement }) {
      if (event.target.classList.contains('modal-background')) {
        this.closeModal();
      }
    },
    toggleListener(value: boolean) {
      if (value) {
        document.addEventListener('keydown', this.clickEscHandler);
      } else {
        document.removeEventListener('keydown', this.clickEscHandler);
      }
    },
    approve() {
      this.isDisabledButton = true;

      this.$emit('approvalHandler', () => {
        this.isDisabledButton = false;
      });
    },
    clickEscHandler(event: KeyboardEvent) {
      switch (event.key) {
        case 'Escape':
          this.closeModal();
          break;
        case 'Enter':
          this.approve();
          break;
        default: break;
      }
    },
  },
  setup() {
    const { toggleClass: toggleScrollBlock } = useBlockScroll();

    return { toggleScrollBlock };
  },
});
