
import { defineComponent, PropType } from 'vue';
import { JsonSchema } from '@jsonforms/core';
import DatepickerPeriod, {
  DatepickerPeriodType,
} from '@/components/ui/datepickers/datepicker-period/DatepickerPeriod.vue';
import SelectDefault from '@/components/ui/selects/SelectDefault/SelectDefault.vue';
import { OneOfType } from '@/api/schema';
import conflictHistory from '@/shared/consts/blocks/conflictHistory';

export default defineComponent({
  name: 'TheFilter',
  inject: ['$sizeWindow'],
  emits: ['filterChanged'],
  components: {
    SelectDefault,
    DatepickerPeriod,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    isCanEdit: {
      type: Boolean,
      default: false,
    },
    schema: {
      type: Object as PropType<JsonSchema>,
      default: () => ({}),
    },
  },
  data() {
    return {
      filter: {
        status: null,
        date: null,
        type: null,
        user: null,
      },
    };
  },
  computed: {
    typeDataList() {
      return this.schema.properties[conflictHistory.BLOCK_FIELD_TYPE]?.enum?.map(
        (item: string) => ({
          id: item,
          name: item,
        }),
      );
    },
    userDataList() {
      return this.schema.properties[conflictHistory.BLOCK_FIELD_USER]?.oneOf?.map(
        (item: OneOfType) => ({
          id: item.const,
          name: item.title,
        }),
      );
    },
    newFilter() {
      return {
        equals: {
          [conflictHistory.BLOCK_FIELD_TYPE]: this.filter.type,
          [conflictHistory.BLOCK_FIELD_USER]: this.filter.user,
        },
        between: {
          [conflictHistory.BLOCK_FIELD_DATE]: this.filter.date,
        },
      };
    },
  },
  methods: {
    changeDateHandler(date: DatepickerPeriodType) {
      this.filter.date = [date.beforeDate, date.afterDate];

      this.$emit('filterChanged', this.newFilter);
    },
    changeTypeHandler(id: string) {
      this.filter.type = id;

      this.$emit('filterChanged', this.newFilter);
    },
    changeUserHandler(id: string) {
      this.filter.user = id;

      this.$emit('filterChanged', this.newFilter);
    },
  },
});
