
import { defineComponent } from 'vue';
import TheTooltip from '@/components/ui/modal/TheTooltip/TheTooltip.vue';
import CircleCheckIcon from '@/components/ui/icons/CircleCheckIcon.vue';

export default defineComponent({
  name: 'CompletedIcon',
  components: {
    TheTooltip,
    CircleCheckIcon,
  },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    color() {
      return this.isActive ? '#22C55E' : undefined;
    },
  },
});
