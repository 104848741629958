
import { defineComponent } from 'vue';
import LockIcon from '@/components/ui/icons/LockIcon.vue';
import TheTooltip from '@/components/ui/modal/TheTooltip/TheTooltip.vue';

export default defineComponent({
  name: 'BlockedIcon',
  components: {
    TheTooltip,
    LockIcon,
  },
});
