import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "table-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_item = _resolveComponent("the-item")!
  const _component_the_table = _resolveComponent("the-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_the_table, {
      items: _ctx.items,
      fields: _ctx.fields,
      pagination: false,
      variant: "simple"
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_the_item, {
          variant: "simple",
          item: _ctx.avgItems,
          fields: _ctx.footerFields
        }, null, 8, ["item", "fields"]),
        _createVNode(_component_the_item, {
          variant: "simple",
          item: _ctx.sumItems,
          fields: _ctx.footerFields
        }, null, 8, ["item", "fields"])
      ]),
      _: 1
    }, 8, ["items", "fields"])
  ]))
}