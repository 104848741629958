import { AxiosCallResponse } from '@/api/base';
import store from '@/store';
import PositionService, { PositionSchema } from '@/api/admin/dictionary/position';

export function useOneApi() {
  return {
    async loadData(id: string) {
      const api: PositionService = store.getters.$api.admin.dictionary.position;

      const response: AxiosCallResponse<PositionSchema> = await api.one(id);

      return response.data ?? null;
    },
  };
}

export default {};
