import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-39f9d6eb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }
const _hoisted_2 = { key: 2 }
const _hoisted_3 = { class: "flex justify-end mt-[24px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_add_button_component = _resolveComponent("add-button-component")!
  const _component_the_item = _resolveComponent("the-item")!
  const _component_the_pagination = _resolveComponent("the-pagination")!
  const _component_the_create = _resolveComponent("the-create")!
  const _component_the_detail = _resolveComponent("the-detail")!
  const _component_the_side_modal = _resolveComponent("the-side-modal")!
  const _component_the_danger_modal = _resolveComponent("the-danger-modal")!
  const _component_block_default = _resolveComponent("block-default")!

  return (_openBlock(), _createBlock(_component_block_default, { text: _ctx.blockLabel }, {
    default: _withCtx(() => [
      (_ctx.isCanEdit)
        ? (_openBlock(), _createBlock(_component_add_button_component, {
            key: 0,
            onClickHandler: _ctx.openCreateForm
          }, null, 8, ["onClickHandler"]))
        : _createCommentVNode("", true),
      (_ctx.noData)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.$t('common.noDataToDisplay')), 1))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
              return (_openBlock(), _createBlock(_component_the_item, {
                key: item.id,
                item: item,
                uischema: _ctx.uischema,
                schema: _ctx.schema,
                onClickItemHandler: _ctx.openInfo
              }, null, 8, ["item", "uischema", "schema", "onClickItemHandler"]))
            }), 128))
          ])),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.pagination?.totalCount > 5)
          ? (_openBlock(), _createBlock(_component_the_pagination, {
              key: 0,
              pagination: _ctx.pagination,
              onPaginationChanged: _ctx.changePaginationHandler
            }, null, 8, ["pagination", "onPaginationChanged"]))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_the_side_modal, {
        "is-show-modal": _ctx.isOpenForm,
        "is-sidebar-with-footer": _ctx.isCreate || _ctx.isUpdate,
        onCloseHandler: _ctx.closeForm
      }, {
        default: _withCtx(() => [
          (_ctx.isCreate)
            ? (_openBlock(), _createBlock(_component_the_create, {
                key: 0,
                schema: _ctx.schema,
                uischema: _ctx.uischema,
                onCreate: _ctx.createHandler,
                onCancel: _ctx.closeForm,
                onChangedFormData: _ctx.changedFormDataHandler
              }, null, 8, ["schema", "uischema", "onCreate", "onCancel", "onChangedFormData"]))
            : (_openBlock(), _createBlock(_component_the_detail, {
                key: 1,
                schema: _ctx.schema,
                uischema: _ctx.uischema,
                data: _ctx.selectedItemData,
                status: _ctx.statusCurrentItem,
                "is-can-edit": _ctx.isCanEdit,
                onUpdated: _ctx.updatedHandler,
                onBlock: _ctx.openBlockConfirmModal,
                onUnblock: _ctx.openUnblockConfirmModal,
                onChangeMode: _ctx.changeModeHandler,
                onChangedFormData: _ctx.changedFormDataHandler
              }, null, 8, ["schema", "uischema", "data", "status", "is-can-edit", "onUpdated", "onBlock", "onUnblock", "onChangeMode", "onChangedFormData"]))
        ]),
        _: 1
      }, 8, ["is-show-modal", "is-sidebar-with-footer", "onCloseHandler"]),
      _createVNode(_component_the_danger_modal, {
        "approve-button-text": _ctx.$t('counterparty.payment.blockPopup.confirmButton'),
        text: _ctx.$t('counterparty.payment.blockPopup.title'),
        "hint-text": _ctx.$t('counterparty.payment.blockPopup.description'),
        "is-open": _ctx.isOpenConfirmBlockModal,
        onApprovalHandler: _ctx.blockHandler,
        onCloseModal: _ctx.confirmCancel
      }, null, 8, ["approve-button-text", "text", "hint-text", "is-open", "onApprovalHandler", "onCloseModal"]),
      _createVNode(_component_the_danger_modal, {
        "approve-button-text": _ctx.$t('counterparty.payment.unblockPopup.confirmButton'),
        text: _ctx.$t('counterparty.payment.unblockPopup.title'),
        "hint-text": _ctx.$t('counterparty.payment.unblockPopup.description'),
        "is-open": _ctx.isOpenConfirmUnblockModal,
        onApprovalHandler: _ctx.unblockHandler,
        onCloseModal: _ctx.confirmCancel
      }, null, 8, ["approve-button-text", "text", "hint-text", "is-open", "onApprovalHandler", "onCloseModal"])
    ]),
    _: 1
  }, 8, ["text"]))
}