import { QueryParamsForList } from '@/api/base';
import store from '@/store';
import CategoryService, { CategoryResponseType } from '@/api/admin/dictionary/category';

export function useListApi() {
  return {
    async loadItems(params: QueryParamsForList) {
      const api: CategoryService = store.getters.$api.admin.dictionary.category;

      const response: CategoryResponseType = await api.list(
        params,
      );

      return response.data?.items ?? [];
    },
  };
}

export default {};
