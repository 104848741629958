import { toDisplayString as _toDisplayString, vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a5ef5f8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "input-label block",
  for: "input"
}
const _hoisted_2 = { class: "relative mt-[2px]" }
const _hoisted_3 = ["placeholder", "type", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("label", _hoisted_1, [
    _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        class: _normalizeClass(["input relative", { error: _ctx.error }]),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dataValue) = $event)),
        placeholder: _ctx.placeholder,
        type: _ctx.type,
        disabled: _ctx.disabled,
        onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.changeFunction && _ctx.changeFunction(...args)))
      }, null, 42, _hoisted_3), [
        [_vModelDynamic, _ctx.dataValue]
      ]),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}