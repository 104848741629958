
import {
  Actions,
  ControlElement, CoreActions, Dispatch,
  JsonFormsRendererRegistryEntry, JsonSchema4, JsonSchema7,
  rankWith,
} from '@jsonforms/core';
import { RendererProps, rendererProps, useJsonFormsArrayControl } from '@jsonforms/vue';
import { useVanillaArrayControl } from '@jsonforms/vue-vanilla';
import { defineComponent, inject } from 'vue';
import SelectDefault, { DataType } from '@/components/ui/selects/SelectDefault/SelectDefault.vue';
import { isMultiEnum } from '@/components/jsonforms/util/testers';
import { controlWrapper } from '@/components/jsonforms/util/renderer';
import ControlWrapper from './ControlWrapper.vue';

const TYPE_LINK = 'link';

const controlRenderer = defineComponent({
  name: 'enum-control-renderer',
  components: {
    ControlWrapper,
    SelectDefault,
  },
  props: {
    ...rendererProps<ControlElement>(),
  },
  setup(props: RendererProps<ControlElement>) {
    const control = useVanillaArrayControl(useJsonFormsArrayControl(props));
    const dispatch = inject<Dispatch<CoreActions>>('dispatch');

    return { ...control, dispatch };
  },
  computed: {
    isTypeLink() {
      return this.appliedOptions.type === TYPE_LINK;
    },
    controlWrapper() {
      return {
        description: this.control.description ?? '',
        errors: this.control.errors ?? '',
        id: this.control.id ?? '',
        label: this.control.label ?? '',
        required: this.control.required ?? '',
        visible: this.control.visible ?? '',
      };
    },
    preparedControlWrapper() {
      return controlWrapper(this);
    },
    data(): DataType[] {
      const hasEnumsSchema = Object.prototype.hasOwnProperty.call(this.control.schema, 'enum');
      const hasOneOfSchema = Object.prototype.hasOwnProperty.call(this.control.schema, 'oneOf');

      if (hasEnumsSchema) return this.prepareEnumData(this.control?.schema?.enum ?? []);

      if (hasOneOfSchema) return this.prepareOneOfData(this.control?.schema?.oneOf ?? []);

      return [];
    },
    isCorrectControlData(): boolean {
      return this.control.data && Array.isArray(this.control.data);
    },
    controlData(): string {
      if (this.isCorrectControlData) {
        const data = this.control.data.map((value: string | number) => this.data.find((item: DataType) => item.id === value)?.name ?? '');

        return data.join(', ');
      }

      return '';
    },
  },
  methods: {
    prepareEnumData(enums: string[]): DataType[] {
      return enums
        .map((item: string) => ({
          id: item,
          name: item,
        }))
        .filter((optionElement: { id: string, name: string }) => !!optionElement.id);
    },
    prepareOneOfData(oneOf: JsonSchema4[] | JsonSchema7[]): DataType[] {
      return oneOf
        .map((item) => ({
          id: item.const ?? '',
          name: item.title ?? '',
        }))
        .filter((optionElement: { id: string, name: string }) => !!optionElement.id);
    },
    changeHandler(newArray: []): void {
      if (this.dispatch) {
        this.dispatch(Actions.update(this.control.path, () => newArray));
      }
    },
    getLink(id: string) {
      return `${this.appliedOptions.baseUrl}/${id}`;
    },
    getLabelById(id: string) {
      const foundItem = this.data.find((item: DataType) => item.id === id);

      return foundItem?.name ?? '';
    },
    isShowComma(index: number) {
      return index !== this.control.data.length - 1;
    },
  },
});

export default controlRenderer;

export const entry: JsonFormsRendererRegistryEntry = {
  renderer: controlRenderer,
  tester: rankWith(5, isMultiEnum),
};
