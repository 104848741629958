import { BoardingSteps } from 'boarding.js';

export const getSteps = (): BoardingSteps => ([
  {
    element: '.tabs-menu-block',
    popover: {
      className: 'first-step-popover-class',
      title: 'Панель навигации по вкладкам',
      description: 'Здесь находятся вкладки.',
      prefferedSide: 'bottom',
    },
  },
  {
    element: '.tabs-menu-item.active',
    popover: {
      className: 'first-step-popover-class',
      title: 'Активная вкладка',
      description: 'Так выглядит активная вкладка.',
      prefferedSide: 'bottom',
    },
  },
]);

export default {
  getSteps,
};
