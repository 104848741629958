
import { defineComponent, PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { JsonSchema, UISchemaElement } from '@jsonforms/core';
import TableBlockTemplate
  from '@/components/counterparty/card/blocks/table-block-template/TableBlockTemplate.vue';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import TheFilter
  from '@/components/counterparty/card/blocks/relationship/salesSupportHistory/filter/TheFilter.vue';
import salesSupportHistory from '@/shared/consts/blocks/salesSupportHistory';
import {
  FilterSalesSupportHistory,
} from '@/store/modules/counterparty/block/relationship/salesSupportHistory/list';
import { OneOfType } from '@/api/schema';
import { PaginationParams, SortParams } from '@/api/base';
import { SalesSupportHistoryItem } from '@/api/block/relationship/salesSupportHistory';
import useAjaxConfiguration from '@/composables/form/useAjaxConfiguration';
import CustomUISchemaElement from '@/components/jsonforms/interfaces/CustomUISchemaElement';
import { DataType } from '@/components/ui/selects/SelectDefault/SelectDefault.vue';

export default defineComponent({
  name: 'TheSalesSupportHistory',
  components: { TheFilter, TableBlockTemplate },
  props: {
    title: {
      type: String,
      default: '',
    },
    schema: {
      required: true,
      type: Object as PropType<JsonSchema>,
      default: undefined,
    },
    uischema: {
      required: true,
      type: Object as PropType<UISchemaElement>,
      default: undefined,
    },
  },
  data() {
    return {
      valueList: [],
      fields: [
        {
          key: salesSupportHistory.BLOCK_FIELD_DATE,
          format: 'date',
          label: this.$t('counterparty.tabs.relationship.salesSupportHistory.date'),
        },
        {
          key: salesSupportHistory.BLOCK_FIELD_TYPE,
          label: this.$t('counterparty.tabs.relationship.salesSupportHistory.type'),
        },
        {
          key: salesSupportHistory.BLOCK_FIELD_SALES_SUPPORT_MANAGER,
          label: this.$t('counterparty.tabs.relationship.salesSupportHistory.user'),
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      items: 'counterparty/block/salesSupportHistory/list/items',
      filter: 'counterparty/block/salesSupportHistory/list/filter',
      sort: 'counterparty/block/salesSupportHistory/list/sort',
      pagination: 'counterparty/block/salesSupportHistory/list/pagination',
      currentCardId: 'counterparty/card/currentCardId',
      supportViewList: 'admin/dictionary/technologicalSupportType/list/items',
    }),
    preparedItems() {
      const items: SalesSupportHistoryItem[] = this.items ?? [];

      return items.map((item: SalesSupportHistoryItem) => ({
        id: item.id,
        [salesSupportHistory.BLOCK_FIELD_DATE]: item[salesSupportHistory.BLOCK_FIELD_DATE],
        [salesSupportHistory.BLOCK_FIELD_TYPE]: this.valueList.find(
          (value: DataType) => value.id === item.id,
        ) ?? this.$t('common.noData'),
        [salesSupportHistory.BLOCK_FIELD_SALES_SUPPORT_MANAGER]:
        this.schema.properties[salesSupportHistory.BLOCK_FIELD_SALES_SUPPORT_MANAGER]?.oneOf.find((
          oneOfItem: OneOfType,
        ) => item[salesSupportHistory.BLOCK_FIELD_SALES_SUPPORT_MANAGER] === oneOfItem.const)
          ?.title,
      }));
    },
  },
  methods: {
    ...mapActions({
      loadItems: 'counterparty/block/salesSupportHistory/list/loadItems',
      loadSupportView: 'admin/dictionary/technologicalSupportType/list/loadItems',
      changeSupportViewFilter: 'admin/dictionary/technologicalSupportType/list/changeFilter',
      changeFilter: 'counterparty/block/salesSupportHistory/list/changeFilter',
      changeSort: 'counterparty/block/salesSupportHistory/list/changeSort',
      changePagination: 'counterparty/block/salesSupportHistory/list/changePagination',
      create: 'counterparty/block/salesSupportHistory/one/create',
      update: 'counterparty/block/salesSupportHistory/one/update',
      delete: 'counterparty/block/salesSupportHistory/one/delete',
    }),
    async createHandler(data: JsonData, next: () => void) {
      await this.create({
        cardId: this.currentCardId,
        data,
      });

      await this.loadAjaxValueAndData();
      next();
    },
    async updateHandler(id: string, data: JsonData, next: () => void) {
      await this.update({
        cardId: this.currentCardId,
        id,
        data,
      });

      await this.loadAjaxValueAndData();
      next();
    },
    async deleteHandler(id: string, next: () => void) {
      await this.delete({
        cardId: this.currentCardId,
        id,
      });

      await this.loadAjaxValueAndData();
      next();
    },
    async changePaginationHandler(pagination: PaginationParams) {
      await this.changePagination(pagination);

      await this.loadAjaxValueAndData();
    },
    async changeSortHandler(sort: SortParams) {
      await this.changeSort(sort);

      await this.loadAjaxValueAndData();
    },
    async filterChangedHandler(filter: FilterSalesSupportHistory) {
      await this.changeFilter(filter);

      await this.loadAjaxValueAndData();
    },
    async loadAjaxValue() {
      this.valueList = [];

      for (const item of this.items) {
        const uiElement = this.uischema.elements.find(
          (element: CustomUISchemaElement) => element.scope?.split('/').pop() === salesSupportHistory.BLOCK_FIELD_TYPE,
        );

        if (uiElement.options.ajaxConfiguration && item[salesSupportHistory.BLOCK_FIELD_TYPE]) {
          const ajaxConfigurator = useAjaxConfiguration(uiElement.options.ajaxConfiguration);

          // eslint-disable-next-line no-await-in-loop
          const name = await ajaxConfigurator.getNameById(
            item[salesSupportHistory.BLOCK_FIELD_TYPE],
          );

          this.valueList.push({
            id: item.id,
            name,
          });
        }
      }
    },
    async loadAjaxValueAndData() {
      await this.loadItems(this.currentCardId);
      await this.loadAjaxValue();
    },
    async ajaxSupportViewHandler(query: string) {
      await this.changeSupportViewFilter({
        query,
      });
      await this.loadSupportView();
    },
  },
  async created() {
    await this.loadAjaxValueAndData();
  },
});
