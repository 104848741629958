import { inject } from 'vue';
import { ErrorObject } from 'ajv';
import { TYPE_LABEL_ERROR } from '@/components/ui/notifications/TheAlert/TheAlert.vue';
import { NotificationLoggerInterface } from '@/utils/helpers/NotificationLogger';

export default function useShowErrors() {
  const $notificationLogger = inject('$notificationLogger') as NotificationLoggerInterface;

  return {
    showErrors: (errors: ErrorObject[]) => {
      if (errors && errors.length) {
        const preparedErrors = errors.map((error: ErrorObject) => {
          const key: string = error?.params?.missingProperty ?? '';
          const label: string = error?.params?.label ?? key;
          const message: string = error.message ?? '';

          return `${label} - ${message}`;
        });

        preparedErrors.forEach((
          text: string,
        ) => $notificationLogger.setNotification(TYPE_LABEL_ERROR, text));
      }
    },
  };
}
