import DictionaryBase, { DictionaryListResponseType } from '@/api/admin/dictionary/base';
import { LoggerInterface } from '@/api/base';

export interface PsychologicalCharacteristicSchema {
  id: string,
  type: string,
  value: string,
  performance: string,
  interpretation: string,
}

export interface PsychologicalCharacteristicData {
  type: string,
  value: string,
  performance: string,
  interpretation: string,
}

export type PsychologicalCharacteristicListResponseType
  = DictionaryListResponseType<PsychologicalCharacteristicSchema>;

export default class PsychologicalCharacteristicService extends DictionaryBase {
  constructor(token: string, logger: LoggerInterface = console) {
    super('psychological-characteristic', token, logger);
  }
}
