import { Module } from 'vuex';
import CounterpartyService, {
  CounterpartyDataType,
  CounterpartyManager,
  CounterpartyManagerResponseType,
  GetOneCounterpartyParams,
} from '@/api/counterparty';
import { AxiosCallResponse } from '@/api/base';
import i18n from '@/i18n';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import { StateType } from '@/store';
import { TYPE_LABEL_SUCCESS } from '@/components/ui/notifications/TheAlert/TheAlert.vue';
import CardService, {
  BlockListType, BlockType, GetBlockParams, GroupType, SyncFieldErrorType,
} from '@/api/card';

export interface CounterpartyCardStateType {
  data: CounterpartyDataType,
  groups: GroupType[],
  blocks: BlockType[],
  currentCounterpartyId?: string | null,
  currentCardId?: string | null,
  currentGroupId?: string | null,
  managers: CounterpartyManager[],
  fieldsErrors: SyncFieldErrorType[],
}

const getInitialState = (): CounterpartyCardStateType => ({
  data: {},
  groups: [],
  blocks: [],
  currentCounterpartyId: null,
  currentCardId: null,
  currentGroupId: null,
  managers: [],
  fieldsErrors: [],
});

const initialState = getInitialState();

const counterpartyCardModule: Module<CounterpartyCardStateType, StateType> = {
  namespaced: true,
  state: initialState,
  getters: {
    data: (state): CounterpartyDataType => state.data,
    groups: (state): GroupType[] => state.groups,
    blocks: (state): BlockType[] => state.blocks,
    loadBlockParams: (state): GetBlockParams => ({
      groupId: state.currentGroupId,
    }),
    currentCounterpartyId: (state): string => state.currentCounterpartyId ?? '',
    currentGroupId: (state): string => state.currentGroupId ?? '',
    currentCardId: (state): string => state.currentCardId ?? '',
    managers: (state): CounterpartyManager[] => state.managers,
    fieldsErrors: (state): SyncFieldErrorType[] => state.fieldsErrors,
  },
  mutations: {
    setCurrentCounterpartyId(state, id) {
      state.currentCounterpartyId = id;
    },
    setCurrentGroupId(state, id) {
      state.currentGroupId = id;
    },
    setCurrentCardId(state, cardId) {
      state.currentCardId = cardId;
    },
    setData(state, data) {
      state.data = { ...data };
    },
    setGroups(state, groups) {
      state.groups = [...groups];
    },
    setBlocks(state, blocks) {
      state.blocks = [...blocks];
    },
    setManagers(state, managers) {
      state.managers = managers;
    },
    setFieldsErrors(state, errors) {
      state.fieldsErrors = errors;
    },
    resetState: (state) => {
      Object.assign(state, getInitialState());
    },
  },
  actions: {
    resetState({ commit }) {
      commit('resetState');
    },
    async changeCurrentCounterpartyId({ commit }, id: string) {
      commit('setCurrentCounterpartyId', id);
    },
    async changeCurrentGroupId({ state, dispatch, commit }, group: string) {
      commit('setCurrentGroupId', group);

      const counterparty = state.currentCounterpartyId;

      await dispatch('schema/loadSchema', { group, counterparty }, { root: true });

      await dispatch('loadBlocks');
    },
    async changeCurrentCardId({ commit }, cardId: string) {
      commit('setCurrentCardId', cardId);
    },
    async loadBlocks({
      rootGetters, commit, state, getters,
    }) {
      const cardId = state.currentCardId;

      if (cardId) {
        const api: CardService = rootGetters.$api.card;
        const params: GetBlockParams = getters.loadBlockParams;

        const blocks: AxiosCallResponse<BlockListType> = await api
          .getBlocks(cardId, params);

        commit('setBlocks', blocks.data?.items ?? initialState.blocks);
      }
    },
    async loadDataAndGroupsByCardIdAndCounterpartyId({ rootGetters, commit }, params: {
      counterpartyId: string,
      cardId: string,
    }) {
      const { counterpartyId, cardId } = params;

      if (counterpartyId && cardId) {
        const cardApi: CardService = rootGetters.$api.card;
        const counterpartyApi: CounterpartyService = rootGetters.$api.counterparty;

        const counterpartyData: AxiosCallResponse<CounterpartyDataType> = await counterpartyApi
          .one(counterpartyId, { cardId });

        if (counterpartyData.error) {
          return;
        }

        const groups: AxiosCallResponse<GroupType[]> = await cardApi
          .getGroups(cardId);

        commit('setData', counterpartyData.data ?? initialState.data);
        commit('setGroups', groups.data ?? initialState.groups);
      }
    },
    async loadDataByCounterpartyId({ rootGetters, commit }, params: {
      id: string,
      counterpartyParams: GetOneCounterpartyParams,
    }) {
      const counterpartyApi: CounterpartyService = rootGetters.$api.counterparty;
      const counterpartyData: AxiosCallResponse<CounterpartyDataType> = await counterpartyApi
        .one(params.id, params.counterpartyParams);

      commit('setData', counterpartyData.data ?? initialState.data);
    },
    async loadKeyDataByCounterpartyId({ rootGetters, commit }, id: string) {
      const counterpartyApi: CounterpartyService = rootGetters.$api.counterparty;
      const counterpartyData: AxiosCallResponse<CounterpartyDataType> = await counterpartyApi
        .getKeyDataOfCounterparty(id);

      commit('setData', counterpartyData.data ?? initialState.data);
    },
    async loadSyncFieldsErrors({ rootGetters, commit }, id: string) {
      const api: CardService = rootGetters.$api.card;

      const response = await api.getSyncFieldErrors(id);

      commit('setFieldsErrors', response.data?.items ?? []);
    },
    async updateCounterparty({
      rootGetters,
      state,
      dispatch,
    }, params: { data: JsonData, block: string }) {
      const counterpartyId = state.currentCounterpartyId;

      if (counterpartyId && params.block && state.currentCardId) {
        const counterpartyApi: CounterpartyService = rootGetters.$api.counterparty;
        const counterpartyData: AxiosCallResponse<null> = await counterpartyApi
          .update(
            counterpartyId,
            params.data,
            { block: params.block, cardId: state.currentCardId },
          );

        if (!counterpartyData.error) {
          await dispatch('loadDataByCounterpartyId', {
            id: counterpartyId,
            counterpartyParams: {
              cardId: state.currentCardId,
            },
          });
          await rootGetters.$notificationLogger
            .setNotification(TYPE_LABEL_SUCCESS, i18n.global.t('notification.counterparty.success.update'));
        }
      }
    },
    async updateMdmData({ rootGetters, state }, data: JsonData) {
      const counterpartyId = state.currentCounterpartyId;

      if (counterpartyId) {
        const counterpartyApi: CounterpartyService = rootGetters.$api.counterparty;
        const counterpartyData: AxiosCallResponse<null> = await counterpartyApi
          .updateMdmData(counterpartyId, data);

        if (!counterpartyData.error) {
          await rootGetters.$notificationLogger
            .setNotification(TYPE_LABEL_SUCCESS, i18n.global.t('notification.counterparty.success.updateMdm'));
        }
      }
    },
    async loadManagers({ rootGetters, commit }, id: string) {
      const api: CounterpartyService = rootGetters.$api.counterparty;

      const managersData: AxiosCallResponse<CounterpartyManagerResponseType> = await api
        .getCounterpartyManagers(id);

      commit('setManagers', managersData.data?.items ?? initialState.managers);
    },
  },
};

export default counterpartyCardModule;
