import { defineComponent } from 'vue';
import {
  EmitDataType,
} from '@/components/ui/menu/ContextMenu/ContextMenu';
import TableItem
  from '@/components/counterparty/card/blocks/news/table/item/TableItem.vue';

export default defineComponent({
  name: 'TableBody',
  components: {
    tableItem: TableItem,
  },
  props: {
    news: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['actionHandler'],
  methods: {
    actionHandler(emitData: EmitDataType) {
      this.$emit('actionHandler', emitData);
    },
  },
});
