import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createBlock as _createBlock, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, toHandlers as _toHandlers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2687db1d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dropdown" }
const _hoisted_2 = {
  key: 0,
  ref: "calculatedBlock",
  class: "dropdown-content w-[300px]"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "icon-container" }
const _hoisted_5 = {
  key: 1,
  class: "flex items-center cursor-pointer text-[12px] md:text-[14px]"
}
const _hoisted_6 = { class: "icon-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_context_menu_icon = _resolveComponent("context-menu-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_outside_click = _resolveDirective("outside-click")!
  const _directive_calculate_position = _resolveDirective("calculate-position")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives((_openBlock(), _createElementBlock("div", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleDropdown && _ctx.toggleDropdown(...args))),
      onMousedown: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isPress = true)),
      onMouseup: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isPress = false)),
      class: _normalizeClass([{ pressed: _ctx.isPress }, "dropdown-btn"]),
      ref: "mainBlock"
    }, [
      _createVNode(_component_context_menu_icon)
    ], 34)), [
      [_directive_outside_click, _ctx.hide]
    ]),
    (_ctx.isShowDropdown)
      ? _withDirectives((_openBlock(), _createElementBlock("ul", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredParams, (item, index) => {
            return (_openBlock(), _createElementBlock("li", _mergeProps({ key: index }, _toHandlers(item.events, true), {
              onClick: ($event: any) => (_ctx.actionHandler(item.emit, _ctx.id))
            }), [
              (!!item.to)
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    to: item.to ?? '#',
                    class: "flex items-center text-[12px] md:text-[14px]"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_4, [
                        (!!item.icon)
                          ? (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon), _normalizeProps(_mergeProps({ key: 0 }, item.iconProps ?? {})), null, 16))
                          : _createCommentVNode("", true)
                      ]),
                      _createTextVNode(" " + _toDisplayString(item.text), 1)
                    ]),
                    _: 2
                  }, 1032, ["to"]))
                : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createElementVNode("span", _hoisted_6, [
                      (!!item.icon)
                        ? (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon), _normalizeProps(_mergeProps({ key: 0 }, item.iconProps ?? {})), null, 16))
                        : _createCommentVNode("", true)
                    ]),
                    _createTextVNode(" " + _toDisplayString(item.text), 1)
                  ]))
            ], 16, _hoisted_3))
          }), 128))
        ])), [
          [_directive_calculate_position]
        ])
      : _createCommentVNode("", true)
  ]))
}