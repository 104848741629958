import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9bd8635e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "relative" }
const _hoisted_2 = ["placeholder", "value", "disabled"]
const _hoisted_3 = {
  key: 1,
  class: "mt-[24px]"
}
const _hoisted_4 = { class: "flex flex-col space-y-[16px]" }
const _hoisted_5 = { class: "inputs-block" }
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = ["placeholder"]
const _hoisted_8 = ["placeholder"]
const _hoisted_9 = { class: "inputs-block" }
const _hoisted_10 = ["placeholder"]
const _hoisted_11 = ["placeholder"]
const _hoisted_12 = ["placeholder"]
const _hoisted_13 = { class: "inputs-block" }
const _hoisted_14 = ["placeholder"]
const _hoisted_15 = ["placeholder"]
const _hoisted_16 = ["placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_view_grid_icon = _resolveComponent("view-grid-icon")!
  const _directive_outside_click = _resolveDirective("outside-click")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.disabled)
      ? (_openBlock(), _createBlock(_component_view_grid_icon, {
          key: 0,
          class: "absolute top-[9px] right-2 cursor-pointer",
          onClick: _ctx.toggleBlock
        }, null, 8, ["onClick"]))
      : _createCommentVNode("", true),
    _createElementVNode("input", {
      type: "text",
      readonly: "",
      class: "input !pr-[36px]",
      placeholder: _ctx.placeholder,
      value: _ctx.isFillText ? this.value.text : _ctx.formattedText,
      disabled: _ctx.disabled,
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openBlock && _ctx.openBlock(...args)))
    }, null, 8, _hoisted_2),
    (_ctx.isOpen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _withDirectives(_createElementVNode("input", {
                type: "text",
                class: "input",
                placeholder: _ctx.$t('address.labels.postalCode'),
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localValue.fields.postalCode) = $event))
              }, null, 8, _hoisted_6), [
                [_vModelText, _ctx.localValue.fields.postalCode]
              ]),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                class: "input",
                placeholder: _ctx.$t('address.labels.country'),
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localValue.fields.country) = $event))
              }, null, 8, _hoisted_7), [
                [_vModelText, _ctx.localValue.fields.country]
              ]),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                class: "input",
                placeholder: _ctx.$t('address.labels.province'),
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.localValue.fields.province) = $event))
              }, null, 8, _hoisted_8), [
                [_vModelText, _ctx.localValue.fields.province]
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _withDirectives(_createElementVNode("input", {
                type: "text",
                class: "input",
                placeholder: _ctx.$t('address.labels.locality'),
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localValue.fields.locality) = $event))
              }, null, 8, _hoisted_10), [
                [_vModelText, _ctx.localValue.fields.locality]
              ]),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                class: "input",
                placeholder: _ctx.$t('address.labels.area'),
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.localValue.fields.area) = $event))
              }, null, 8, _hoisted_11), [
                [_vModelText, _ctx.localValue.fields.area]
              ]),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                class: "input",
                placeholder: _ctx.$t('address.labels.street'),
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.localValue.fields.street) = $event))
              }, null, 8, _hoisted_12), [
                [_vModelText, _ctx.localValue.fields.street]
              ])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _withDirectives(_createElementVNode("input", {
                type: "text",
                class: "input",
                placeholder: _ctx.$t('address.labels.house'),
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.localValue.fields.house) = $event))
              }, null, 8, _hoisted_14), [
                [_vModelText, _ctx.localValue.fields.house]
              ]),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                class: "input",
                placeholder: _ctx.$t('address.labels.housing'),
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.localValue.fields.housing) = $event))
              }, null, 8, _hoisted_15), [
                [_vModelText, _ctx.localValue.fields.housing]
              ]),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                class: "input",
                placeholder: _ctx.$t('address.labels.flat'),
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.localValue.fields.flat) = $event))
              }, null, 8, _hoisted_16), [
                [_vModelText, _ctx.localValue.fields.flat]
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ])), [
    [_directive_outside_click, _ctx.closeBlock]
  ])
}