import { Module } from 'vuex';
import list, { IndustryListStateType } from '@/store/modules/admin/dictionary/industry/list';
import one, { IndustryOneStateType } from '@/store/modules/admin/dictionary/industry/one';
import { StateType } from '@/store';

export interface IndustryStateType {
  list: IndustryListStateType,
  one: IndustryOneStateType,
}

const industryModule: Module<IndustryStateType, StateType> = {
  namespaced: true,
  modules: {
    list,
    one,
  },
};

export default industryModule;
