import { AxiosCallResponse, QueryParamsForList } from '@/api/base';
import store from '@/store';
import CounterpartyService, { CounterpartyResponseType } from '@/api/counterparty';

export function useListApi() {
  return {
    async loadItems(params: QueryParamsForList) {
      const counterpartyApi: CounterpartyService = store.getters.$api.counterparty;

      const response: AxiosCallResponse<CounterpartyResponseType> = await counterpartyApi
        .list(params);

      return response.data?.items ?? [];
    },
  };
}

export default {};
