import _ from 'lodash';
import { Module } from 'vuex';
import { AxiosCallResponse } from '@/api/base';
import i18n from '@/i18n';
import CommunicationService, {
  CommunicationItem,
} from '@/api/block/relationship/communication';
import { StateType } from '@/store';
import {
  TYPE_LABEL_SUCCESS,
} from '@/components/ui/notifications/TheAlert/TheAlert.vue';

export interface CommunicationOneStateType {
  data: CommunicationItem | null,
}

const initialState = {
  data: null,
};

const communicationOneModule: Module<CommunicationOneStateType, StateType> = {
  namespaced: true,
  state: _.cloneDeep(initialState),
  getters: {
    data: (state) => state.data,
  },
  mutations: {
    setData(state, data) {
      state.data = data;
    },
  },
  actions: {
    async create({ rootGetters }, params: {
      cardId: string,
      data: CommunicationItem,
    }): Promise<CommunicationItem | null> {
      const { cardId, data } = params;

      if (cardId) {
        const api: CommunicationService = rootGetters.$api.relationship.communication;

        const response: AxiosCallResponse<CommunicationItem> = await api.create(
          cardId,
          data,
        );

        if (!response.error) {
          await rootGetters
            .$notificationLogger
            .setNotification(
              TYPE_LABEL_SUCCESS,
              i18n.global.t('notification.admin.dictionary.success.create'),
            );

          return response.data ?? null;
        }
      }

      return null;
    },
    async update({ rootGetters }, params: {
      cardId: string,
      id: string,
      data: CommunicationItem,
    }): Promise<CommunicationItem | null> {
      const { cardId, id, data } = params;

      if (cardId) {
        const api: CommunicationService = rootGetters.$api.relationship.communication;

        const response: AxiosCallResponse<null> = await api.update(
          cardId,
          id,
          data,
        );

        if (!response.error) {
          await rootGetters
            .$notificationLogger
            .setNotification(
              TYPE_LABEL_SUCCESS,
              i18n.global.t('notification.admin.dictionary.success.update'),
            );

          return response.data ?? null;
        }
      }

      return null;
    },
    async delete({ rootGetters }, params: {
      cardId: string,
      id: string,
    }): Promise<CommunicationItem | null> {
      const { cardId, id } = params;

      if (cardId) {
        const api: CommunicationService = rootGetters.$api.relationship.communication;

        const response: AxiosCallResponse<null> = await api.delete(cardId, id);

        if (!response.error) {
          await rootGetters
            .$notificationLogger
            .setNotification(
              TYPE_LABEL_SUCCESS,
              i18n.global.t('notification.admin.dictionary.success.delete'),
            );

          return response.data ?? null;
        }
      }

      return null;
    },
  },
};

export default communicationOneModule;
