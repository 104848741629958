import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "loader-block"
}
const _hoisted_3 = {
  key: 1,
  class: "w-full"
}
const _hoisted_4 = { class: "w-full" }
const _hoisted_5 = { class: "header-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_default_loader = _resolveComponent("default-loader")!
  const _component_the_title = _resolveComponent("the-title")!
  const _component_the_filter = _resolveComponent("the-filter")!
  const _component_the_table = _resolveComponent("the-table")!
  const _component_the_create = _resolveComponent("the-create")!
  const _component_the_detail = _resolveComponent("the-detail")!
  const _component_the_side_modal = _resolveComponent("the-side-modal")!
  const _component_the_danger_modal = _resolveComponent("the-danger-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_default_loader)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_the_title, {
                title: _ctx.title,
                "is-can-edit": _ctx.isShowCreateForm,
                onButtonClick: _ctx.openCreateForm
              }, null, 8, ["title", "is-can-edit", "onButtonClick"]),
              _createVNode(_component_the_filter, { onChangeFilter: _ctx.changeFilter }, null, 8, ["onChangeFilter"])
            ])
          ]),
          _createVNode(_component_the_table, {
            items: _ctx.preparedItems,
            fields: _ctx.fields,
            sort: _ctx.sort,
            pagination: _ctx.pagination,
            onBodyItemClick: _ctx.openInfo,
            onChangeSortBy: _ctx.changeSort,
            onChangePagination: _ctx.changePagination
          }, null, 8, ["items", "fields", "sort", "pagination", "onBodyItemClick", "onChangeSortBy", "onChangePagination"]),
          _createVNode(_component_the_side_modal, {
            "is-show-modal": _ctx.isOpenForm,
            "is-sidebar-with-footer": _ctx.isCreate || _ctx.isUpdate,
            onCloseHandler: _ctx.closeForm
          }, {
            default: _withCtx(() => [
              (_ctx.isCreate)
                ? (_openBlock(), _createBlock(_component_the_create, {
                    key: 0,
                    uischema: _ctx.uischema,
                    schema: _ctx.schema,
                    "form-data": _ctx.formData,
                    onCancel: _ctx.closeForm,
                    onCreate: _ctx.createHandler,
                    onUpdateData: _ctx.updateDataHandler
                  }, null, 8, ["uischema", "schema", "form-data", "onCancel", "onCreate", "onUpdateData"]))
                : (_openBlock(), _createBlock(_component_the_detail, {
                    key: 1,
                    schema: _ctx.schema,
                    uischema: _ctx.uischema,
                    data: _ctx.selectedItemData,
                    "is-show-edit-button": _ctx.isShowEditForm,
                    "is-show-remove-button": _ctx.isShowRemoveForm,
                    "is-update": _ctx.isUpdate,
                    "is-deleted": _ctx.isDeletedStatus,
                    onUpdate: _ctx.updateHandler,
                    onUpdateData: _ctx.updateDataHandler,
                    onDelete: _ctx.openConfirmModal,
                    onRestore: _ctx.restoreHandler,
                    onChangeMode: _ctx.changeModeHandler
                  }, null, 8, ["schema", "uischema", "data", "is-show-edit-button", "is-show-remove-button", "is-update", "is-deleted", "onUpdate", "onUpdateData", "onDelete", "onRestore", "onChangeMode"]))
            ]),
            _: 1
          }, 8, ["is-show-modal", "is-sidebar-with-footer", "onCloseHandler"]),
          _createVNode(_component_the_danger_modal, {
            "approve-button-text": _ctx.$t('history.dangerModal.confirmButton'),
            text: _ctx.$t('history.dangerModal.text'),
            "is-open": _ctx.isOpenConfirm,
            onApprovalHandler: _ctx.dangerApprovalHandler,
            onCloseModal: _ctx.confirmCancel
          }, null, 8, ["approve-button-text", "text", "is-open", "onApprovalHandler", "onCloseModal"])
        ]))
  ]))
}