import DictionaryBase, { DictionaryListResponseType } from '@/api/admin/dictionary/base';
import { LoggerInterface } from '@/api/base';

export interface NomenclatureGroupItem {
  id: string,
  name: string,
  certificateName: string,
}

export interface NomenclatureGroupData {
  name: string,
  certificateName: string,
}

export type NomenclatureGroupListResponseType = DictionaryListResponseType<NomenclatureGroupItem>;

export default class NomenclatureGroupService extends DictionaryBase {
  constructor(token: string, logger: LoggerInterface = console) {
    super('nomenclature-group', token, logger);
  }
}
