import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_trash_icon = _resolveComponent("trash-icon")!
  const _component_icon_button = _resolveComponent("icon-button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["top-[10px] right-[10px]", _ctx.isAbsolute ? 'absolute' : ''])
  }, [
    _createVNode(_component_icon_button, { onClick: _ctx.clickHandler }, {
      default: _withCtx(() => [
        _createVNode(_component_trash_icon, { type: "outline" })
      ]),
      _: 1
    }, 8, ["onClick"])
  ], 2))
}