import { Module } from 'vuex';
import region, { RegionStateType } from '@/store/modules/admin/dictionary/region';
import counterpartyType, { CounterpartyTypeStateType } from '@/store/modules/admin/dictionary/counterpartyType';
import nomenclatureGroup, { NomenclatureGroupModuleStateType } from '@/store/modules/admin/dictionary/nomenclatureGroup';
import bankAccount, { BankAccountStateType } from '@/store/modules/admin/dictionary/bankAccount';
import industry, { IndustryStateType } from '@/store/modules/admin/dictionary/industry';
import bank, { BankStateType } from '@/store/modules/admin/dictionary/bank';
import company, { CompanyStateType } from '@/store/modules/admin/dictionary/company';
import country, { CountryStateType } from '@/store/modules/admin/dictionary/country';
import pinning, { PinningStateType } from '@/store/modules/admin/pinning';
import structure, { StructureStateType } from '@/store/modules/admin/dictionary/structure';
import category, { CategoryCounterpartyStateType } from '@/store/modules/admin/dictionary/category';
import activityDirection, { ActivityDirectionModuleStateType } from '@/store/modules/admin/dictionary/activityDirection';
import psychologicalCharacteristic, {
  PsychologicalCharacteristicModuleStateType,
} from '@/store/modules/admin/dictionary/psychologicalCharacteristic';
import { StateType } from '@/store';
import technologicalSupportType, {
  TechnologicalSupportTypeModuleStateType,
} from '@/store/modules/admin/dictionary/technologicalSupportType';
import position, { PositionModuleStateType } from '@/store/modules/admin/dictionary/position';
import education, { EducationStateType } from '@/store/modules/admin/dictionary/education';
import individual, { IndividualStateType } from '@/store/modules/admin/dictionary/individual';
import nomenclature, { NomenclatureModuleStateType } from '@/store/modules/admin/dictionary/nomenclature';

export interface DictionaryStateType {
  country: CountryStateType,
  structure: StructureStateType,
  pinning: PinningStateType,
  company: CompanyStateType,
  category: CategoryCounterpartyStateType,
  bank: BankStateType,
  industry: IndustryStateType,
  bankAccount: BankAccountStateType,
  psychologicalCharacteristic: PsychologicalCharacteristicModuleStateType,
  nomenclatureGroup: NomenclatureGroupModuleStateType,
  counterpartyType: CounterpartyTypeStateType,
  technologicalSupportType: TechnologicalSupportTypeModuleStateType,
  activityDirection: ActivityDirectionModuleStateType,
  position: PositionModuleStateType,
  education: EducationStateType,
  individual: IndividualStateType,
  region: RegionStateType,
  nomenclature: NomenclatureModuleStateType,
}

const dictionaryModule: Module<DictionaryStateType, StateType> = {
  namespaced: true,
  modules: {
    country,
    structure,
    pinning,
    company,
    category,
    bank,
    industry,
    bankAccount,
    psychologicalCharacteristic,
    nomenclatureGroup,
    counterpartyType,
    technologicalSupportType,
    position,
    activityDirection,
    education,
    individual,
    region,
    nomenclature,
  },
};

export default dictionaryModule;
