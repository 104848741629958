import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bdad98a0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_select_default = _resolveComponent("select-default")!
  const _component_switch_horizontal_icon = _resolveComponent("switch-horizontal-icon")!
  const _component_icon_button = _resolveComponent("icon-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["filter-block", _ctx.reverseClass])
    }, [
      _createVNode(_component_select_default, {
        data: _ctx.nomenclatureGroup,
        placeholder: _ctx.$t('counterparty.consumptionRegistry.placeholders.group'),
        value: _ctx.filter?.group,
        class: "w-full md:w-[244px]",
        onValueSelect: _ctx.changeGroup
      }, null, 8, ["data", "placeholder", "value", "onValueSelect"]),
      _createVNode(_component_icon_button, {
        description: _ctx.$t('buttons.switch'),
        class: "switch-button",
        onClick: _ctx.switchDataSlice
      }, {
        default: _withCtx(() => [
          _createVNode(_component_switch_horizontal_icon)
        ]),
        _: 1
      }, 8, ["description", "onClick"]),
      _createVNode(_component_select_default, {
        data: _ctx.yearsList,
        value: _ctx.filter?.year,
        placeholder: _ctx.$t('counterparty.consumptionRegistry.placeholders.year'),
        class: "w-full md:w-[244px]",
        onValueSelect: _ctx.changeYear
      }, null, 8, ["data", "value", "placeholder", "onValueSelect"])
    ], 2)
  ]))
}