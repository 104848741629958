import { Module } from 'vuex';
import { StateType } from '@/store';
import one, {
  RelationshipBreakOneStateType,
} from '@/store/modules/counterparty/block/relationship/relationshipBreak/one';
import list, {
  RelationshipBreakListStateType,
} from '@/store/modules/counterparty/block/relationship/relationshipBreak/list';

export interface RelationshipBreakStateType {
  list: RelationshipBreakListStateType,
  one: RelationshipBreakOneStateType,
}

const relationshipBreakModule: Module<RelationshipBreakStateType, StateType> = {
  namespaced: true,
  modules: {
    list,
    one,
  },
};

export default relationshipBreakModule;
