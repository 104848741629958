import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cede0e82"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sm:relative" }
const _hoisted_2 = ["title"]
const _hoisted_3 = { class: "manager-info-field" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 2,
  class: "card-item-list"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "little-info-list-field" }
const _hoisted_8 = { class: "manager-info-field" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_arrow_down_icon = _resolveComponent("arrow-down-icon")!
  const _component_user_switch_icon = _resolveComponent("user-switch-icon")!
  const _component_icon_button = _resolveComponent("icon-button")!
  const _directive_outside_click = _resolveDirective("outside-click")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.$sizeWindow.isMd)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "card-manager-current",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)))
        }, [
          _createElementVNode("div", null, [
            _createElementVNode("div", {
              title: _ctx.formattedInfo(_ctx.currentItem),
              class: "little-info-field"
            }, _toDisplayString(_ctx.formattedInfo(_ctx.currentItem)), 9, _hoisted_2),
            _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.currentItem.manager?.name
        ?? _ctx.$t(`counterparty.list.filter.form.blocks.${_ctx.config.COUNTERPARTY_FILTER_BLOCK_FREE}`)), 1)
          ]),
          _createVNode(_component_arrow_down_icon, { class: "absolute right-5 sm:relative sm:right-0" })
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_icon_button, { onClick: _ctx.toggle }, {
            default: _withCtx(() => [
              _createVNode(_component_user_switch_icon, {
                type: "outline",
                color: "#6366F1"
              })
            ]),
            _: 1
          }, 8, ["onClick"])
        ])),
    (_ctx.isOpen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (card) => {
            return (_openBlock(), _createElementBlock("div", {
              key: card.id,
              class: _normalizeClass(["card-item", {active: card.id === _ctx.currentItem.id}]),
              onClick: ($event: any) => (_ctx.changeCardHandler(card.id))
            }, [
              _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.formattedInfo(card)), 1),
              _createElementVNode("div", _hoisted_8, _toDisplayString(card.manager?.name
        ?? _ctx.$t(`counterparty.list.filter.form.blocks.${_ctx.config.COUNTERPARTY_FILTER_BLOCK_FREE}`)), 1)
            ], 10, _hoisted_6))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ])), [
    [_directive_outside_click, _ctx.close]
  ])
}