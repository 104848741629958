import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-39bc8f3b"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "tooltip",
  ref: "mainBlock"
}
const _hoisted_2 = {
  key: 0,
  class: "tooltip-content w-[300px]",
  ref: "calculatedBlock"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_info_icon = _resolveComponent("info-icon")!
  const _directive_calculate_position = _resolveDirective("calculate-position")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      onMouseleave: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args))),
      onMouseenter: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.open && _ctx.open(...args)))
    }, [
      _renderSlot(_ctx.$slots, "icon", {}, () => [
        _createVNode(_component_info_icon)
      ], true)
    ], 32),
    (_ctx.showTooltip)
      ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", null, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ])
        ])), [
          [_directive_calculate_position]
        ])
      : _createCommentVNode("", true)
  ], 512))
}