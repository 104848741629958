import { DataType } from '@/components/ui/selects/SelectDefault/SelectDefault.vue';

export default function useSelectDataHelper() {
  const defaultSelectDataList = [{ id: null, name: '' }];

  return {
    prepareData(list: DataType[]) {
      if (list.length > 0) {
        return [
          ...defaultSelectDataList,
          ...list.map((item: DataType) => ({
            id: item.id,
            name: item.name,
          })),
        ];
      }

      return defaultSelectDataList;
    },
  };
}
