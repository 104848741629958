
import { defineComponent, inject, markRaw } from 'vue';
import {
  DispatchRenderer,
  RendererProps,
  rendererProps,
  useJsonFormsArrayControl,
} from '@jsonforms/vue';
import {
  composePaths,
  ControlElement,
  JsonFormsRendererRegistryEntry, JsonFormsSubStates,
  JsonSchema,
  rankWith,
  schemaTypeIs,
  uiTypeIs,
} from '@jsonforms/core';
import { createDefaultValue } from '@/components/jsonforms/util/renderer';
import { useVanillaArrayControl } from '@jsonforms/vue-vanilla';
import TheTable from '@/components/ui/tables/simple/TheTable.vue';
import TheItem from '@/components/ui/tables/simple/body/item/TheItem.vue';
import { TableFieldObject, TableItem } from '@/components/ui/tables/simple/TheTable';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import CustomUISchemaElement from '@/components/jsonforms/interfaces/CustomUISchemaElement';
import _ from 'lodash';
import { useI18n } from 'vue-i18n';

const controlRenderer = defineComponent({
  name: 'table-of-months-renderer',
  components: {
    TheTable,
    TheItem,
  },
  props: {
    ...rendererProps<ControlElement>(),
  },
  data() {
    return {
      items: [],
      fields: [],
      months: [
        this.t('jsonforms.tableOfMonths.months.january'),
        this.t('jsonforms.tableOfMonths.months.february'),
        this.t('jsonforms.tableOfMonths.months.march'),
        this.t('jsonforms.tableOfMonths.months.april'),
        this.t('jsonforms.tableOfMonths.months.may'),
        this.t('jsonforms.tableOfMonths.months.june'),
        this.t('jsonforms.tableOfMonths.months.july'),
        this.t('jsonforms.tableOfMonths.months.august'),
        this.t('jsonforms.tableOfMonths.months.september'),
        this.t('jsonforms.tableOfMonths.months.october'),
        this.t('jsonforms.tableOfMonths.months.november'),
        this.t('jsonforms.tableOfMonths.months.december'),
      ],
    };
  },
  computed: {
    uiSchema(): CustomUISchemaElement {
      if (_.has(this.uischema, 'options.detail')) {
        return this.uischema.options?.detail;
      }

      return this.childUiSchema;
    },
    avgItems() {
      const propertyKeys = Object.keys(this.control.schema.properties);
      const defaultValues: TableItem = {};

      propertyKeys.forEach((key: string) => {
        const sum = this.getSumByColumn(key);
        const linesCount = this.control.data?.length ?? 0;
        const avg = sum / linesCount;

        defaultValues[key] = +avg.toFixed(2);
      });

      return {
        title: this.t('jsonforms.tableOfMonths.monthlyAverage'),
        ...defaultValues,
      };
    },
    sumItems() {
      const propertyKeys = Object.keys(this.control.schema.properties);
      const defaultValues: TableItem = {};

      propertyKeys.forEach((key: string) => {
        defaultValues[key] = this.getSumByColumn(key);
      });

      return {
        title: this.t('jsonforms.tableOfMonths.monthlyAmount'),
        ...defaultValues,
      };
    },
    footerFields(): TableFieldObject[] {
      const fieldObjects = this.uiSchema.elements.map((element: CustomUISchemaElement) => {
        const key: string = element.scope?.split('/').pop() ?? '';

        return { key };
      });

      return [
        { key: 'title' },
        ...fieldObjects,
      ];
    },
  },
  methods: {
    createDefaultValue,
    getSumByColumn(key: string): number {
      return _.reduce(
        this.control.data ?? [],
        (sum: number, item) => sum + (item?.[key] ?? 0),
        0,
      );
    },
    createDefaultSchema(key: string, schema: object): JsonSchema {
      return {
        type: 'object',
        properties: {
          [key]: schema,
        },
      };
    },
    createDefaultControl(key: string, label = ''): CustomUISchemaElement {
      return {
        type: 'Control',
        label,
        scope: `#/properties/${key}`,
      };
    },
    prepareData(): JsonData {
      return this.months.map((month: string, index: number) => {
        const defaultValues = createDefaultValue(this.control.schema) as object;
        const controlData = this.control.data?.[index] ?? {};

        return {
          ...defaultValues,
          ...controlData,
        };
      });
    },
    prepareFields(): TableFieldObject[] {
      const fieldObjects = this.uiSchema.elements.map((element: CustomUISchemaElement) => {
        const key: string = element.scope?.split('/').pop() ?? '';

        return {
          key,
          label: element.label ?? key,
          notSortable: true,
        };
      });

      return [
        { key: 'month', label: this.t('jsonforms.tableOfMonths.month'), notSortable: true },
        ...fieldObjects,
      ];
    },
    prepareItems(): TableItem[] {
      const propertyKeys = Object.keys(this.control.schema.properties);

      return this.months.map((month: string, index: number) => {
        const defaultValues: TableItem = {};

        propertyKeys.forEach((key: string) => {
          const schema: object = this.control.schema.properties[key];

          defaultValues[key] = {
            type: 'component',
            component: markRaw(DispatchRenderer),
            props: {
              schema: this.createDefaultSchema(key, schema),
              uischema: this.createDefaultControl(key),
              path: composePaths(this.control.path, `${index}`),
              enabled: true,
              renderers: this.control.renderers,
              cells: this.control.cells,
            },
            isVisible: true,
          };
        });

        return {
          month,
          ...defaultValues,
        };
      });
    },
  },
  mounted() {
    this.items = this.prepareItems();
    this.fields = this.prepareFields();

    if (this.jsonFormState?.core?.data) {
      this.jsonFormState.core.data[this.control.path] = this.prepareData();
    }
  },
  setup(props: RendererProps<ControlElement>) {
    const control = useVanillaArrayControl(useJsonFormsArrayControl(props));
    const jsonFormState = inject<JsonFormsSubStates>('jsonforms');
    const { t } = useI18n({
      useScope: 'global',
      inheritLocale: true,
    });

    return {
      ...control, jsonFormState, t,
    };
  },
});

export default controlRenderer;

export const tableOfMonthsTester = schemaTypeIs('array')
  && uiTypeIs('TableOfMonths');

export const entry: JsonFormsRendererRegistryEntry = {
  renderer: controlRenderer,
  tester: rankWith(2, tableOfMonthsTester),
};
