
import { defineComponent, PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { JsonSchema, UISchemaElement } from '@jsonforms/core';
import TableBlockTemplate
  from '@/components/counterparty/card/blocks/table-block-template/TableBlockTemplate.vue';
import {
  RelationshipBreakItem,
  FilterRelationshipBreak,
} from '@/api/block/relationship/relationshipBreak';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import TheFilter
  from '@/components/counterparty/card/blocks/relationship/relationshipBreak/filter/TheFilter.vue';
import { PaginationParams, SortParams } from '@/api/base';
import relationshipBreak from '@/shared/consts/blocks/relationshipBreak';

export default defineComponent({
  name: 'TheRelationshipBreak',
  components: { TableBlockTemplate, TheFilter },
  props: {
    title: {
      type: String,
      default: '',
    },
    schema: {
      required: true,
      type: Object as PropType<JsonSchema>,
      default: undefined,
    },
    uischema: {
      required: true,
      type: Object as PropType<UISchemaElement>,
      default: undefined,
    },
  },
  data() {
    return {
      fields: [
        {
          key: relationshipBreak.BLOCK_FIELD_DATE_START,
          format: 'date',
          label: this.$t('counterparty.tabs.relationship.relationshipBreak.dateStart'),
        },
        {
          key: relationshipBreak.BLOCK_FIELD_DATE_END,
          format: 'date',
          label: this.$t('counterparty.tabs.relationship.relationshipBreak.dateEnd'),
        },
        {
          key: relationshipBreak.BLOCK_FIELD_REASON,
          label: this.$t('counterparty.tabs.relationship.relationshipBreak.reason'),
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      items: 'counterparty/block/relationshipBreak/list/items',
      filter: 'counterparty/block/relationshipBreak/list/filter',
      sort: 'counterparty/block/relationshipBreak/list/sort',
      pagination: 'counterparty/block/relationshipBreak/list/pagination',
      currentCardId: 'counterparty/card/currentCardId',
    }),
    preparedItems() {
      const items: RelationshipBreakItem[] = this.items ?? [];

      return items.map((item: RelationshipBreakItem) => ({
        id: item.id,
        [relationshipBreak.BLOCK_FIELD_DATE_START]: item[relationshipBreak.BLOCK_FIELD_DATE_START],
        [relationshipBreak.BLOCK_FIELD_DATE_END]: item[relationshipBreak.BLOCK_FIELD_DATE_END],
        [relationshipBreak.BLOCK_FIELD_REASON]: item[relationshipBreak.BLOCK_FIELD_REASON],
      }));
    },
  },
  methods: {
    ...mapActions({
      loadItems: 'counterparty/block/relationshipBreak/list/loadItems',
      changeFilter: 'counterparty/block/relationshipBreak/list/changeFilter',
      changeSort: 'counterparty/block/relationshipBreak/list/changeSort',
      changePagination: 'counterparty/block/relationshipBreak/list/changePagination',
      create: 'counterparty/block/relationshipBreak/one/create',
      update: 'counterparty/block/relationshipBreak/one/update',
      delete: 'counterparty/block/relationshipBreak/one/delete',
    }),
    async createHandler(data: JsonData, next: () => void) {
      await this.create({
        cardId: this.currentCardId,
        data,
      });

      next();
      await this.loadItems(this.currentCardId);
    },
    async updateHandler(id: string, data: JsonData, next: () => void) {
      await this.update({
        cardId: this.currentCardId,
        id,
        data,
      });

      next();
      await this.loadItems(this.currentCardId);
    },
    async deleteHandler(id: string, next: () => void) {
      await this.delete({
        cardId: this.currentCardId,
        id,
      });

      next();
      await this.loadItems(this.currentCardId);
    },
    async changePaginationHandler(pagination: PaginationParams) {
      await this.changePagination(pagination);

      await this.loadItems(this.currentCardId);
    },
    async changeSortHandler(sort: SortParams) {
      await this.changeSort(sort);

      await this.loadItems(this.currentCardId);
    },
    async filterChangedHandler(filter: FilterRelationshipBreak) {
      await this.changeFilter(filter);

      await this.loadItems(this.currentCardId);
    },
  },
  async created() {
    await this.loadItems(this.currentCardId);
  },
});
