
import { defineComponent, PropType } from 'vue';
import { trim } from 'lodash';
import OutsideClick from '@/utils/directives/OutsideClick';
import ViewGridIcon from '@/components/ui/icons/ViewGridIcon.vue';
import i18n from '@/i18n';

export interface AddressFields {
  [key: string]: string,

  postalCode: string,
  country: string,
  province: string,
  locality: string,
  area: string,
  street: string,
  house: string,
  housing: string,
  flat: string,
}

export interface AddressData {
  text?: string,
  fields: AddressFields
}

export default defineComponent({
  name: 'AddressDefault',
  emits: ['change'],
  components: {
    ViewGridIcon,
  },
  props: {
    value: {
      type: Object as PropType<AddressData>,
      default: () => ({
        text: null,
        fields: {
          postalCode: null,
          country: null,
          province: null,
          locality: null,
          area: null,
          street: null,
          house: null,
          housing: null,
          flat: null,
        },
      }),
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
      isFillText: false,
      localValue: this.value,
    };
  },
  computed: {
    formattedText() {
      const fieldsKeys = Object.keys(this.localValue.fields);

      let countFillData = 0;
      let addressString = '';

      fieldsKeys.forEach((key: string) => {
        if (this.localValue.fields[key]) {
          countFillData += 1;
        }
      });

      fieldsKeys.forEach((key: string) => {
        const translate = i18n.global.te(`address.shortLabels.${key}`)
          ? this.$t(`address.shortLabels.${key}`)
          : false;

        if (this.localValue.fields[key]) {
          addressString += !translate
            ? `${trim(this.localValue.fields[key])}`
            : `${translate}. ${trim(this.localValue.fields[key])}`;

          countFillData -= 1;
        }

        addressString += countFillData !== 0 && this.localValue.fields[key] ? ', ' : '';
      });

      return addressString;
    },
  },
  methods: {
    toggleBlock() {
      this.isOpen = !this.isOpen;
    },
    changeHandler() {
      this.isFillText = false;
      this.$emit('change', this.localValue);
    },
    openBlock() {
      this.isOpen = true;
    },
    closeBlock() {
      this.isOpen = false;
    },
  },
  watch: {
    formattedText(value: string) {
      this.localValue.text = value;
      this.changeHandler();
    },
  },
  directives: {
    OutsideClick,
  },
  created() {
    if (this.value?.text) {
      this.isFillText = true;
    }
  },
});
