import {
  AxiosCallResponse, LoggerInterface, ResponseOfRecords,
} from '@/api/base';
import DictionaryBase from '@/api/admin/dictionary/base';

export interface CategoryCounterpartyData {
  id?: string,
  name: string,
  shortName: string,
}

export type CategoryResponseType = AxiosCallResponse<ResponseOfRecords<CategoryCounterpartyData[]>>;

export default class CategoryService extends DictionaryBase {
  constructor(token: string, logger: LoggerInterface = console) {
    super('counterparty-category', token, logger);
  }
}
