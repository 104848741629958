
import { defineComponent } from 'vue';
import config from '@/config';
import ActiveBadge from '@/components/admin/user/one/modal/content/detail/avatar/badge/ActiveBadge.vue';
import BlockedBadge from '@/components/admin/user/one/modal/content/detail/avatar/badge/BlockedBadge.vue';
import ImageAvatar from '@/components/ui/avatars/ImageAvatar.vue';

export default defineComponent({
  name: 'TheAvatar',
  components: {
    ImageAvatar,
    ActiveBadge,
    BlockedBadge,
  },
  props: {
    src: {
      type: String,
      default: '',
    },
    status: {
      type: Number,
      default: config.STATUS_USER_IS_ACTIVE,
    },
    nameInitial: {
      type: String,
      default: '',
    },
  },

  computed: {
    isActiveStatus() {
      return this.status === config.STATUS_USER_IS_ACTIVE;
    },
    isBlockedStatus() {
      return this.status === config.STATUS_USER_IS_BLOCKED;
    },
  },
});
