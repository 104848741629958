
import { defineComponent, ref } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import DictionaryGenerator from '@/components/admin/dictionary/generator/DictionaryGenerator.vue';
import useCheckPermissions from '@/composables/useCheckPermissions';
import { PaginationParams, SortParams } from '@/api/base';
import { RegionData } from '@/api/admin/dictionary/region';
import { FilterDictionaryParams } from '@/api/admin/dictionary/base';
import CustomUISchemaElement from '@/components/jsonforms/interfaces/CustomUISchemaElement';
import { TableFieldObject } from '@/components/ui/tables/simple/TheTable';

export default defineComponent({
  name: 'TheList',
  components: {
    DictionaryGenerator,
  },
  data() {
    return {
      isLoading: false,
      fields: [
        { key: 'name', label: this.$t('dictionary.region.fields.name') },
        { key: 'action', notSortable: true, width: 52 },
      ] as TableFieldObject[],
      schema: {
        type: 'object',
        properties: {
          name: {
            type: 'string',
          },
        },
        required: [
          'name',
        ],
      },
      uischema: {
        type: 'VerticalLayout',
        elements: [
          {
            type: 'Control',
            label: this.$t('dictionary.region.fields.name'),
            scope: '#/properties/name',
          },
        ],
      } as CustomUISchemaElement,
    };
  },
  computed: {
    ...mapGetters({
      items: 'admin/dictionary/region/list/items',
      filter: 'admin/dictionary/region/list/filter',
      pagination: 'admin/dictionary/region/list/pagination',
      sort: 'admin/dictionary/region/list/sort',
    }),
  },
  methods: {
    ...mapActions({
      loadItems: 'admin/dictionary/region/list/loadItems',
      resetState: 'admin/dictionary/region/list/resetState',
      changeFilter: 'admin/dictionary/region/list/changeFilter',
      changePagination: 'admin/dictionary/region/list/changePagination',
      changeSort: 'admin/dictionary/region/list/changeSort',
      create: 'admin/dictionary/region/one/create',
      update: 'admin/dictionary/region/one/update',
      delete: 'admin/dictionary/region/one/delete',
      restore: 'admin/dictionary/region/one/restore',
    }),
    async createHandler(data: RegionData, next: () => void) {
      await this.create(data);
      await this.loadItems();

      next();
    },
    async updateHandler(data: { id: string, data: RegionData}, next: () => void) {
      await this.update(data);
      await this.loadItems();

      next();
    },
    async deleteHandler(id: string) {
      await this.delete(id);
      await this.loadItems();
    },
    async restoreHandler(id: string) {
      await this.restore(id);

      await this.loadItems();
    },
    async changeFilterHandler(filter: FilterDictionaryParams) {
      await this.changeFilter(filter);
      await this.loadItems();
    },
    async changePaginationHandler(pagination: PaginationParams) {
      await this.changePagination(pagination);

      await this.loadItems();
    },
    async changeSortHandler(sort: SortParams) {
      await this.changeSort(sort);

      await this.loadItems();
    },
  },
  async created() {
    this.isLoading = true;

    await this.loadItems();

    this.isLoading = false;
  },
  unmounted() {
    this.resetState();
  },
  setup() {
    const checkPermissions = useCheckPermissions();
    const isCanEdit = ref(checkPermissions.dictionary.canEditPosition);

    return { isCanEdit };
  },
});
