
import { defineComponent } from 'vue';
import InfoIcon from '@/components/ui/icons/InfoIcon.vue';
import CalculatePosition from '@/utils/directives/CalculatePosition';
import OutsideClick from '@/utils/directives/OutsideClick';
import { marked } from 'marked';
import sanitize from 'sanitize-html';

export default defineComponent({
  name: 'TheToggleTooltip',
  components: { InfoIcon },
  props: {
    text: {
      type: String,
      default: null,
    },
    calculatePosition: {
      type: [Boolean, Object],
      default: true,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    formattedText() {
      return marked(sanitize(this.text));
    },
  },
  methods: {
    toggleTooltip() {
      this.isOpen = !this.isOpen;
    },
    closeTooltip() {
      this.isOpen = false;
    },
  },
  directives: {
    CalculatePosition,
    OutsideClick,
  },
});
