
import { defineComponent, PropType } from 'vue';
import { JsonSchema, UISchemaElement } from '@jsonforms/core';
import _ from 'lodash';
import BlockDefault from '@/components/ui/blocks/BlockDefault/BlockDefault.vue';
import AddButtonComponent
  from '@/components/widgets/add-button-component/AddButtonComponent.vue';
import TheTable from '@/components/ui/tables/simple/TheTable.vue';
import { TableFieldObject, TableItem } from '@/components/ui/tables/simple/TheTable';
import { PaginationParams, SortParams } from '@/api/base';
import TheSideModal from '@/components/ui/modal/TheSideModal/TheSideModal.vue';
import TheCreate
  from '@/components/counterparty/card/blocks/table-block-template/create/TheCreate.vue';
import TheDetail
  from '@/components/counterparty/card/blocks/table-block-template/detail/TheDetail.vue';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import TheDangerModal from '@/components/ui/modal/TheDangerModal/TheDangerModal.vue';

export default defineComponent({
  name: 'TableBlockTemplate',
  emits: [
    'create',
    'update',
    'delete',
    'changeSort',
    'changePagination',
  ],
  components: {
    TheDangerModal,
    TheDetail,
    TheSideModal,
    AddButtonComponent,
    BlockDefault,
    TheTable,
    TheCreate,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    items: {
      type: Array as PropType<TableItem[]>,
      default: () => ([]),
    },
    isCanEdit: {
      type: Boolean,
      default: true,
    },
    preparedItems: {
      type: Array as PropType<TableItem[]>,
      default: () => ([]),
    },
    fields: {
      type: Array as PropType<TableFieldObject[]>,
      default: () => ([]),
    },
    schema: {
      required: true,
      type: Object as PropType<JsonSchema>,
      default: () => ({}),
    },
    uischema: {
      required: true,
      type: Object as PropType<UISchemaElement>,
      default: () => ({}),
    },
    pagination: {
      type: Object,
      default: () => ({
        currentPage: 0,
        perPage: 0,
        totalCount: 0,
        pageCount: 0,
      }),
    },
    sort: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isOpenForm: false,
      isView: false,
      currentItemId: null,
      isOpenDangerModal: false,
    };
  },
  computed: {
    selectedItemData() {
      const obj = this.items.find((
        item: TableItem,
      ) => item.id === this.currentItemId);
      return _.pickBy(obj, (el: string, key: string) => key !== 'id');
    },
    isCreate() {
      return this.currentItemId === null;
    },
  },
  methods: {
    openForm() {
      this.isOpenForm = true;
    },
    closeForm() {
      this.isOpenForm = false;
      this.isView = false;
      this.currentItemId = null;
    },
    dangerCloseHandler() {
      this.isOpenDangerModal = false;
    },
    openDetailForm(id: string) {
      this.isOpenForm = true;
      this.isView = true;
      this.currentItemId = id;
    },
    changeEditModeHandler(value: boolean) {
      this.isView = value;
    },
    changeSortHandler(sort: SortParams) {
      this.$emit('changeSort', sort);
    },
    changePaginationHandler(sort: PaginationParams) {
      this.$emit('changePagination', sort);
    },
    createHandler(data: JsonData, next: () => void) {
      this.$emit('create', data, () => {
        next();

        this.closeForm();
      });
    },
    updateHandler(data: JsonData, next: () => void) {
      if (this.currentItemId) {
        this.$emit('update', this.currentItemId, data, () => {
          next();

          this.closeForm();
        });
      }
    },
    deleteHandler() {
      this.isOpenDangerModal = true;
    },
    dangerApprovalHandler(next: () => void) {
      this.$emit('delete', this.currentItemId, () => {
        this.isOpenDangerModal = false;
        this.isOpenForm = false;
        this.currentItemId = null;

        next();
      });
    },
  },
});
