
import { defineComponent } from 'vue';
import TheAlert from '@/components/ui/notifications/TheAlert/TheAlert.vue';

export default defineComponent({
  name: 'TheManager',
  components: {
    TheAlert,
  },
  props: {
    notifications: {
      type: Array,
      default: () => ([]),
    },
    limit: {
      type: Number,
      default: 5,
    },
  },
  computed: {
    preparedList() {
      return this.notifications.slice(0, this.limit);
    },
  },
  methods: {
    deleteNotification(id: number) {
      this.$emit('deleteHandler', id);
    },
  },
});
