
import { defineComponent } from 'vue';
import TheTooltip from '@/components/ui/modal/TheTooltip/TheTooltip.vue';
import InfoCircleIcon from '@/components/ui/icons/InfoCircleIcon.vue';

export default defineComponent({
  name: 'PlannedIcon',
  components: {
    TheTooltip,
    InfoCircleIcon,
  },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    color() {
      return this.isActive ? '#F59E0B' : undefined;
    },
  },
});
