import { Module } from 'vuex';
import {
  JsonDataOneItem,
  MetaParams, PaginationParams, SortParams,
} from '@/api/base';
import CommunicationService, {
  CommunicationItem,
  CommunicationResponseType,
} from '@/api/block/relationship/communication';
import { StateType } from '@/store';

export type FilterCommunication = JsonDataOneItem | null;

export type QueryParamsForListCommunication = PaginationParams | FilterCommunication | SortParams;

export interface CommunicationListStateType {
  items: CommunicationItem[],
  filter: FilterCommunication,
  sort: SortParams,
  pagination: MetaParams,
}

const initialState = {
  items: [],
  pagination: {
    currentPage: null,
    pageCount: null,
    perPage: 5,
    totalCount: null,
  },
  filter: null,
  sort: {
    sortBy: null,
  },
};

const communicationListModule: Module<CommunicationListStateType, StateType> = {
  namespaced: true,
  state: initialState,
  getters: {
    items: (state) => state.items,
    filter: (state): FilterCommunication => state.filter,
    sort: (state): SortParams => state.sort,
    pagination: (state): MetaParams => state.pagination,
    loadParams: (state): QueryParamsForListCommunication => ({
      page: state.pagination.currentPage,
      perPage: state.pagination.perPage,
      sortBy: state.sort.sortBy,
      ...state.filter,
    }),
  },
  mutations: {
    setItems: (state, items) => {
      state.items = items;
    },
    setFilter: (state, filter) => {
      state.filter = filter;
    },
    setSort: (state, sort) => {
      state.sort = sort;
    },
    setPagination: (state, pagination) => {
      state.pagination = pagination;
    },
  },
  actions: {
    async changePagination({ commit }, pagination: MetaParams) {
      commit('setPagination', pagination);
    },
    async changeFilter({ commit, state }, filter: FilterCommunication) {
      commit('setFilter', filter);
      commit('setPagination', { ...state.pagination, currentPage: 1 });
    },
    async changeSort({ commit }, sort: SortParams) {
      commit('setSort', sort);
    },
    async loadItems({ getters, rootGetters, commit }, cardId: string) {
      const api: CommunicationService = rootGetters.$api.relationship.communication;
      const { loadParams } = getters;

      const response: CommunicationResponseType = await api.list(cardId, loadParams);

      commit('setItems', response.data?.items ?? initialState.items);
      commit('setPagination', response.data?._meta ?? initialState.pagination);
    },
  },
};

export default communicationListModule;
