
import { defineComponent, PropType } from 'vue';
import { JsonSchema } from '@jsonforms/core';
import SelectDefault from '@/components/ui/selects/SelectDefault/SelectDefault.vue';
import seminarHistory from '@/shared/consts/blocks/seminarHistory';
import SearchInput from '@/components/ui/inputs/SearchInput/SearchInput.vue';
import DatepickerPeriod, {
  DatepickerPeriodType,
} from '@/components/ui/datepickers/datepicker-period/DatepickerPeriod.vue';

const getInitialFilter = () => ({
  date: null,
  typeDate: seminarHistory.BLOCK_FIELD_DATE,
  search: null,
});

export default defineComponent({
  name: 'TheFilter',
  inject: ['$sizeWindow'],
  emits: ['filterChanged'],
  components: {
    DatepickerPeriod,
    SearchInput,
    SelectDefault,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    isCanEdit: {
      type: Boolean,
      default: false,
    },
    schema: {
      type: Object as PropType<JsonSchema>,
      default: () => ({}),
    },
  },
  data() {
    return {
      dateData: [
        {
          id: seminarHistory.BLOCK_FIELD_DATE,
          name: this.$t('counterparty.tabs.relationship.seminarHistory.filter.conducting'),
        },
        {
          id: seminarHistory.BLOCK_FIELD_DATE_INVITE,
          name: this.$t('counterparty.tabs.relationship.seminarHistory.filter.invite'),
        },
      ],
      filter: getInitialFilter(),
    };
  },
  computed: {
    newFilter() {
      return {
        contains: {
          [seminarHistory.BLOCK_FIELD_NAME]: this.filter.search,
        },
        between: {
          [this.filter.typeDate]: this.filter.date,
        },
      };
    },
  },
  methods: {
    changeDateHandler(date: DatepickerPeriodType) {
      this.filter.date = [date.beforeDate, date.afterDate];

      this.$emit('filterChanged', this.newFilter);
    },
    changeTypeHandler(id: string) {
      this.filter.typeDate = id;

      this.$emit('filterChanged', this.newFilter);
    },
    changeSearch(query: string) {
      this.filter.search = query;

      this.$emit('filterChanged', this.newFilter);
    },
    clearFilter() {
      this.filter = getInitialFilter();

      this.$emit('filterChanged', this.newFilter);
    },
  },
});
