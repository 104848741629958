import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_json_forms = _resolveComponent("json-forms")!

  return (_openBlock(), _createBlock(_component_json_forms, _mergeProps({ ref: "jsonForms" }, _ctx.$attrs, {
    data: _ctx.data,
    schema: _ctx.schema,
    renderers: _ctx.renderers,
    uischema: _ctx.uischema,
    i18n: _ctx.i18n,
    onChange: _ctx.onChange
  }), null, 16, ["data", "schema", "renderers", "uischema", "i18n", "onChange"]))
}