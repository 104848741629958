import { LoggerInterface } from '@/api/base';
import { NotificationLoggerInterface } from '@/utils/helpers/NotificationLogger';
import { TYPE_LABEL_ERROR } from '@/components/ui/notifications/TheAlert/TheAlert.vue';
import RedirectChecker from '@/utils/helpers/RedirectChecker';
import RedirectManager from '@/utils/helpers/RedirectManager';

export default class ApiErrorLogger implements LoggerInterface {
  constructor(private notificationLogger: NotificationLoggerInterface) {}

  async log(status: number, text: string): Promise<void> {
    if (RedirectChecker.needRedirect(status)) {
      await RedirectManager.redirect(`/${status}`);

      return;
    }

    await this.notificationLogger.setNotification(TYPE_LABEL_ERROR, text);
  }
}
