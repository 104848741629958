import { AxiosCallResponse } from '@/api/base';
import store from '@/store';
import NomenclatureService, { NomenclatureItem } from '@/api/admin/dictionary/nomenclature';

export function useOneApi() {
  return {
    async loadData(id: string) {
      const api: NomenclatureService = store.getters.$api.admin.dictionary.nomenclature;

      const response: AxiosCallResponse<NomenclatureItem> = await api.one(id);

      return response.data ?? null;
    },
  };
}

export default {};
