import { BoardingSteps } from 'boarding.js';

export const getSteps = (): BoardingSteps => [
  {
    element: '.help-button',
    popover: {
      className: 'first-step-popover-class',
      title: 'Помощь',
      description: 'Вы в любой момент можете вызвать подсказки по навигации, кликнув поэтой кнопке. Для вызова подсказок для конкретной страницы нужно кликнуть на эту кнопку находясь на ней.',
      prefferedSide: 'top',
    },
  },
  {
    element: '.profile-button',
    popover: {
      className: 'first-step-popover-class',
      title: 'Профиль',
      description: 'В этом разделе находятся данные идентифицирующие вас как пользователя системы. Для выхода из системы, вам необходимо войти в профиль и нажать кнопку <strong>Выйти</strong>.',
      prefferedSide: 'top',
    },
  },
  {
    element: '.counterparty-button',
    popover: {
      className: 'first-step-popover-class',
      title: 'Список контрагентов',
      description: 'В данный список выводятся доступные Вам контрагенты: закрепленные, свободный блок дивизиона или закрепленные в Вашем подразделении.',
      prefferedSide: 'right',
    },
  },
  {
    element: '.users-button',
    popover: {
      className: 'first-step-popover-class',
      title: 'Список пользователей',
      description: 'В этом разделе находится список и настройки пользователей.',
      prefferedSide: 'right',
    },
  },
  {
    element: '.assign-button',
    popover: {
      className: 'first-step-popover-class',
      title: 'Список закреплений',
      description: 'Раздел со списком согласованных контрагентов.',
      prefferedSide: 'right',
    },
  },
  {
    element: '.dictionary-button',
    popover: {
      className: 'first-step-popover-class',
      title: 'Справочники',
      description: 'В данном разделе находятся справочники системы.',
      prefferedSide: 'right',
    },
  },
  {
    element: '.notification-button',
    popover: {
      className: 'first-step-popover-class',
      title: 'Уведомления',
      description: 'Здесь находятся уведомления и их настройки.',
      prefferedSide: 'right',
    },
  },
];

export default {
  getSteps,
};
