
import { defineComponent, PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { JsonSchema, UISchemaElement } from '@jsonforms/core';
import TableBlockTemplate
  from '@/components/counterparty/card/blocks/table-block-template/TableBlockTemplate.vue';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import conflictHistory from '@/shared/consts/blocks/conflictHistory';
import TheFilter
  from '@/components/counterparty/card/blocks/relationship/conflictHistory/filter/TheFilter.vue';
import {
  FilterConflictHistory,
} from '@/store/modules/counterparty/block/relationship/conflictHistory/list';
import { OneOfType } from '@/api/schema';
import { PaginationParams, SortParams } from '@/api/base';
import { ConflictHistoryItem } from '@/api/block/relationship/conflictHistory';

export default defineComponent({
  name: 'TheConflictHistory',
  components: { TheFilter, TableBlockTemplate },
  props: {
    title: {
      type: String,
      default: '',
    },
    schema: {
      required: true,
      type: Object as PropType<JsonSchema>,
      default: undefined,
    },
    uischema: {
      required: true,
      type: Object as PropType<UISchemaElement>,
      default: undefined,
    },
  },
  data() {
    return {
      fields: [
        {
          key: conflictHistory.BLOCK_FIELD_DATE,
          format: 'date',
          label: this.$t('counterparty.tabs.relationship.conflictHistory.date'),
        },
        { key: conflictHistory.BLOCK_FIELD_TYPE, label: this.$t('counterparty.tabs.relationship.conflictHistory.type') },
        { key: conflictHistory.BLOCK_FIELD_USER, label: this.$t('counterparty.tabs.relationship.conflictHistory.user') },
      ],
    };
  },
  computed: {
    ...mapGetters({
      items: 'counterparty/block/conflictHistory/list/items',
      filter: 'counterparty/block/conflictHistory/list/filter',
      sort: 'counterparty/block/conflictHistory/list/sort',
      pagination: 'counterparty/block/conflictHistory/list/pagination',
      currentCardId: 'counterparty/card/currentCardId',
    }),
    preparedItems() {
      const items: ConflictHistoryItem[] = this.items ?? [];

      return items.map((item: ConflictHistoryItem) => ({
        id: item.id,
        [conflictHistory.BLOCK_FIELD_DATE]: item[conflictHistory.BLOCK_FIELD_DATE],
        [conflictHistory.BLOCK_FIELD_TYPE]: item[conflictHistory.BLOCK_FIELD_TYPE],
        [conflictHistory.BLOCK_FIELD_USER]: this.schema.properties[conflictHistory.BLOCK_FIELD_USER]
          ?.oneOf?.find(
            (oneOfItem: OneOfType) => item[conflictHistory.BLOCK_FIELD_USER] === oneOfItem.const,
          )?.title,
      }));
    },
  },
  methods: {
    ...mapActions({
      loadItems: 'counterparty/block/conflictHistory/list/loadItems',
      changeFilter: 'counterparty/block/conflictHistory/list/changeFilter',
      changeSort: 'counterparty/block/conflictHistory/list/changeSort',
      changePagination: 'counterparty/block/conflictHistory/list/changePagination',
      create: 'counterparty/block/conflictHistory/one/create',
      update: 'counterparty/block/conflictHistory/one/update',
      delete: 'counterparty/block/conflictHistory/one/delete',
    }),
    async createHandler(data: JsonData, next: () => void) {
      await this.create({
        cardId: this.currentCardId,
        data,
      });

      next();
      await this.loadItems(this.currentCardId);
    },
    async updateHandler(id: string, data: JsonData, next: () => void) {
      await this.update({
        cardId: this.currentCardId,
        id,
        data,
      });

      next();
      await this.loadItems(this.currentCardId);
    },
    async deleteHandler(id: string, next: () => void) {
      await this.delete({
        cardId: this.currentCardId,
        id,
      });

      next();
      await this.loadItems(this.currentCardId);
    },
    async changePaginationHandler(pagination: PaginationParams) {
      await this.changePagination(pagination);

      await this.loadItems(this.currentCardId);
    },
    async changeSortHandler(sort: SortParams) {
      await this.changeSort(sort);

      await this.loadItems(this.currentCardId);
    },
    async filterChangedHandler(filter: FilterConflictHistory) {
      await this.changeFilter(filter);

      await this.loadItems(this.currentCardId);
    },
  },
  async created() {
    await this.loadItems(this.currentCardId);
  },
});
