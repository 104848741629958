<template>
  <div @mouseover="openTooltip" @mouseleave="closeTooltip" class="relative">
    <russia-icon v-if="isResident"/>
    <international-icon v-if="!isResident"/>

    <div class="tooltip" v-if="isShowTooltip">
      <p v-if="isResident">Резидент РФ</p>
      <p v-if="!isResident">Нерезидент РФ</p>
    </div>
  </div>
</template>

<script>
import RussiaIcon from '@/components/ui/icons/RussiaIcon.vue';
import InternationalIcon from '@/components/ui/icons/InternationalIcon.vue';

export default {
  name: 'ResidentBlock',
  components: {
    RussiaIcon,
    InternationalIcon,
  },
  data: () => ({
    isShowTooltip: false,
  }),
  props: {
    isResident: Boolean,
  },
  methods: {
    openTooltip() {
      this.isShowTooltip = true;
    },
    closeTooltip() {
      this.isShowTooltip = false;
    },
  },
};
</script>

<style scoped lang="scss">
.tooltip {
  @apply absolute text-[12px] p-[8px] mt-[10px] rounded-sm bg-white shadow-md;
  @apply flex items-center justify-center w-[108px];
}
</style>
