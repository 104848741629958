import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4a9097ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sidebar-content" }
const _hoisted_2 = { class: "sidebar-block" }
const _hoisted_3 = { class: "sidebar-block__title" }
const _hoisted_4 = { class: "sidebar-block" }
const _hoisted_5 = {
  key: 0,
  class: "action-buttons"
}
const _hoisted_6 = {
  key: 0,
  class: "sidebar-footer"
}
const _hoisted_7 = { class: "text-[14px]" }
const _hoisted_8 = { class: "text-[14px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_edit_icon = _resolveComponent("edit-icon")!
  const _component_icon_button = _resolveComponent("icon-button")!
  const _component_trash_icon = _resolveComponent("trash-icon")!
  const _component_the_form = _resolveComponent("the-form")!
  const _component_default_button = _resolveComponent("default-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('dictionary.general.detail')), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        (!_ctx.isEdit && _ctx.isCanEdit)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_icon_button, { onClick: _ctx.changeEditMode }, {
                default: _withCtx(() => [
                  _createVNode(_component_edit_icon)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_icon_button, { onClick: _ctx.deleteHandler }, {
                default: _withCtx(() => [
                  _createVNode(_component_trash_icon, { type: "outline" })
                ]),
                _: 1
              }, 8, ["onClick"])
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_the_form, {
          ref: "form",
          data: _ctx.localData,
          "is-view": !_ctx.isEdit,
          schema: _ctx.schema,
          uischema: _ctx.uischema,
          onUpdateData: _ctx.updateDataHandler
        }, null, 8, ["data", "is-view", "schema", "uischema", "onUpdateData"])
      ])
    ]),
    (_ctx.isEdit)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_default_button, {
            height: "40",
            onClick: _ctx.cancelHandler
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('buttons.cancel')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]),
          _createVNode(_component_default_button, {
            type: "primary",
            height: "40",
            disabled: _ctx.isLoading,
            onClick: _ctx.updateHandler
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('buttons.save')), 1)
            ]),
            _: 1
          }, 8, ["disabled", "onClick"])
        ]))
      : _createCommentVNode("", true)
  ]))
}