import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-83eb5468"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "agents" }
const _hoisted_2 = { class: "agents--header" }
const _hoisted_3 = { class: "agents--cards" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_add_icon = _resolveComponent("add-icon")!
  const _component_default_button = _resolveComponent("default-button")!
  const _component_the_card = _resolveComponent("the-card")!
  const _component_the_create = _resolveComponent("the-create")!
  const _component_the_detail = _resolveComponent("the-detail")!
  const _component_the_side_modal = _resolveComponent("the-side-modal")!
  const _component_blocks_history = _resolveComponent("blocks-history")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.isCanEdit)
        ? (_openBlock(), _createBlock(_component_default_button, {
            key: 0,
            onClick: _ctx.openCreateModal
          }, {
            icon: _withCtx(() => [
              _createVNode(_component_add_icon, {
                width: "12",
                height: "12"
              })
            ]),
            default: _withCtx(() => [
              _createTextVNode(" " + _toDisplayString(_ctx.$t('buttons.add')), 1)
            ]),
            _: 1
          }, 8, ["onClick"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
        return (_openBlock(), _createBlock(_component_the_card, {
          schema: _ctx.schema,
          uischema: _ctx.uischema,
          key: `agent-card-${item.id}`,
          data: item,
          "is-can-edit": _ctx.isCanEdit,
          onOpen: _ctx.cardOpenHandler
        }, null, 8, ["schema", "uischema", "data", "is-can-edit", "onOpen"]))
      }), 128))
    ]),
    _createVNode(_component_the_side_modal, {
      "is-sidebar-with-footer": !_ctx.isView,
      "is-show-modal": _ctx.isOpen,
      onCloseHandler: _ctx.closeModal
    }, {
      default: _withCtx(() => [
        (!_ctx.isEdit && !_ctx.isView)
          ? (_openBlock(), _createBlock(_component_the_create, {
              key: 0,
              schema: _ctx.schema,
              onSubmit: _ctx.createAgentHandler,
              onClose: _ctx.closeModal
            }, null, 8, ["schema", "onSubmit", "onClose"]))
          : _createCommentVNode("", true),
        (_ctx.isEdit || _ctx.isView)
          ? (_openBlock(), _createBlock(_component_the_detail, {
              key: 1,
              data: _ctx.selectedAgent,
              schema: _ctx.schema,
              uischema: _ctx.uischema,
              "is-edit": _ctx.isEdit,
              "is-can-edit": _ctx.isCanEdit,
              onClose: _ctx.closeModal,
              onSubmit: _ctx.updateAgentHandler,
              onChangeUserStatus: _ctx.changeUserStatus,
              onChangedEditMode: _ctx.changedEditModeHandler
            }, null, 8, ["data", "schema", "uischema", "is-edit", "is-can-edit", "onClose", "onSubmit", "onChangeUserStatus", "onChangedEditMode"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["is-sidebar-with-footer", "is-show-modal", "onCloseHandler"]),
    _createVNode(_component_blocks_history, {
      schema: _ctx.schema,
      "is-open": _ctx.isHistory,
      "block-id": _ctx.groupId,
      "item-id": _ctx.selectedAgent?.id ?? null,
      "block-name": _ctx.uischema?.label ?? null,
      "load-history": _ctx.loadHistory,
      onCloseHistory: _ctx.closeHistory
    }, null, 8, ["schema", "is-open", "block-id", "item-id", "block-name", "load-history", "onCloseHistory"])
  ]))
}