import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "symbol-count" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_generator = _resolveComponent("form-generator")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_form_generator, {
      "validation-mode": "ValidateAndHide",
      uischema: _ctx.uischema,
      schema: _ctx.schema,
      information: _ctx.currentData,
      "additional-errors": _ctx.errors,
      readonly: _ctx.isView,
      config: { onlyText: _ctx.isView },
      onChange: _ctx.onChange
    }, null, 8, ["uischema", "schema", "information", "additional-errors", "readonly", "config", "onChange"]),
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.currentSymbolCount) + "/" + _toDisplayString(_ctx.maxSymbol), 1)
  ], 64))
}