import { Module } from 'vuex';
import { AxiosCallResponse } from '@/api/base';
import i18n from '@/i18n';
import { TYPE_LABEL_SUCCESS } from '@/components/ui/notifications/TheAlert/TheAlert.vue';
import { StateType } from '@/store';
import PresenceOfBrandsService, { PresenceOfBrandsSchema } from '@/api/block/strategy/presenceOfBrands';

export interface PresenceOfBrandsOneStateType {
  data: PresenceOfBrandsSchema | null,
}

const initialState = {
  data: null,
};

const presenceOfBrandsOneModule: Module<PresenceOfBrandsOneStateType, StateType> = {
  namespaced: true,
  state: initialState,
  actions: {
    async create({ rootGetters }, params: {
      cardId: string,
      data: PresenceOfBrandsSchema,
    }) {
      if (params.cardId) {
        const api: PresenceOfBrandsService = rootGetters.$api.blocks.presenceOfBrands;

        const response: AxiosCallResponse<PresenceOfBrandsSchema> = await api.create(
          params.cardId,
          params.data,
        );

        if (!response.error) {
          await rootGetters
            .$notificationLogger
            .setNotification(
              TYPE_LABEL_SUCCESS,
              i18n.global.t('notification.admin.dictionary.success.create'),
            );
        }
      }
    },
    async update({ rootGetters }, params: {
      cardId: string,
      id: string,
      data: PresenceOfBrandsSchema,
    }) {
      if (params.cardId) {
        const api: PresenceOfBrandsService = rootGetters.$api.blocks.presenceOfBrands;

        const response: AxiosCallResponse<null> = await api.update(
          params.cardId,
          params.id,
          params.data,
        );

        if (!response.error) {
          await rootGetters
            .$notificationLogger
            .setNotification(
              TYPE_LABEL_SUCCESS,
              i18n.global.t('notification.admin.dictionary.success.update'),
            );
        }
      }
    },
    async delete({ rootGetters }, params: {
      cardId: string,
      id: string,
    }) {
      if (params.cardId) {
        const api: PresenceOfBrandsService = rootGetters.$api.blocks.presenceOfBrands;

        const response: AxiosCallResponse<null> = await api.delete(
          params.cardId,
          params.id,
        );

        if (!response.error) {
          await rootGetters
            .$notificationLogger
            .setNotification(
              TYPE_LABEL_SUCCESS,
              i18n.global.t('notification.admin.dictionary.success.delete'),
            );
        }
      }
    },
  },
};

export default presenceOfBrandsOneModule;
