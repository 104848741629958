<template>
  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 6C6.79565 6 7.55871 5.68393 8.12132 5.12132C8.68393 4.55871 9 3.79565 9 3C9
 2.20435 8.68393 1.44129 8.12132 0.87868C7.55871 0.316071 6.79565 0 6 0C5.20435 0
 4.44129 0.316071 3.87868 0.87868C3.31607 1.44129 3 2.20435 3 3C3 3.79565 3.31607
 4.55871 3.87868 5.12132C4.44129 5.68393 5.20435 6 6 6ZM6 8C7.5913 8 9.11742 8.63214
 10.2426 9.75736C11.3679 10.8826 12 12.4087 12 14H0C0 12.4087 0.632141 10.8826 1.75736
 9.75736C2.88258 8.63214 4.4087 8 6 8ZM14 4C14 3.73478 13.8946 3.48043 13.7071
 3.29289C13.5196 3.10536 13.2652 3 13 3C12.7348 3 12.4804 3.10536 12.2929 3.29289C12.1054
 3.48043 12 3.73478 12 4V5H11C10.7348 5 10.4804 5.10536 10.2929 5.29289C10.1054 5.48043
 10 5.73478 10 6C10 6.26522 10.1054 6.51957 10.2929 6.70711C10.4804 6.89464 10.7348 7
 11 7H12V8C12 8.26522 12.1054 8.51957 12.2929 8.70711C12.4804 8.89464 12.7348 9 13
 9C13.2652 9 13.5196 8.89464 13.7071 8.70711C13.8946 8.51957 14 8.26522 14 8V7H15C15.2652
 7 15.5196 6.89464 15.7071 6.70711C15.8946 6.51957 16 6.26522 16 6C16 5.73478 15.8946
 5.48043 15.7071 5.29289C15.5196 5.10536 15.2652 5 15 5H14V4Z"
      fill="#64748B"
    />
  </svg>
</template>

<script>
export default {
  name: 'AddPersonIcon',
};
</script>
