import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_payment = _resolveComponent("the-payment")!
  const _component_the_agents = _resolveComponent("the-agents")!
  const _component_the_news = _resolveComponent("the-news")!
  const _component_the_production_indicators = _resolveComponent("the-production-indicators")!
  const _component_key_production_assets = _resolveComponent("key-production-assets")!
  const _component_presence_of_brands = _resolveComponent("presence-of-brands")!
  const _component_the_implementation = _resolveComponent("the-implementation")!
  const _component_the_communication = _resolveComponent("the-communication")!
  const _component_the_conflict_history = _resolveComponent("the-conflict-history")!
  const _component_the_sales_support_history = _resolveComponent("the-sales-support-history")!
  const _component_the_seminar_history = _resolveComponent("the-seminar-history")!
  const _component_the_relationship_break = _resolveComponent("the-relationship-break")!
  const _component_the_consumption_registry = _resolveComponent("the-consumption-registry")!
  const _component_the_delivery_registry = _resolveComponent("the-delivery-registry")!
  const _component_assign_history = _resolveComponent("assign-history")!

  return (_ctx.isPaymentAccountBlock)
    ? (_openBlock(), _createBlock(_component_the_payment, {
        key: 0,
        id: _ctx.id,
        "is-can-edit": _ctx.canEdit,
        text: _ctx.label,
        uischema: _ctx.uischema,
        schema: _ctx.schema
      }, null, 8, ["id", "is-can-edit", "text", "uischema", "schema"]))
    : (_ctx.isAgentsBlock)
      ? (_openBlock(), _createBlock(_component_the_agents, {
          key: 1,
          "is-can-edit": _ctx.canEdit,
          uischema: _ctx.uischema,
          schema: _ctx.schema
        }, null, 8, ["is-can-edit", "uischema", "schema"]))
      : (_ctx.isNewsBlock)
        ? (_openBlock(), _createBlock(_component_the_news, {
            key: 2,
            "is-can-edit": _ctx.canEdit
          }, null, 8, ["is-can-edit"]))
        : (_ctx.isProductionIndicators)
          ? (_openBlock(), _createBlock(_component_the_production_indicators, {
              key: 3,
              text: _ctx.label,
              "is-can-edit": _ctx.canEdit,
              schema: _ctx.schema,
              uischema: _ctx.uischema
            }, null, 8, ["text", "is-can-edit", "schema", "uischema"]))
          : (_ctx.isKeyProductionAssets)
            ? (_openBlock(), _createBlock(_component_key_production_assets, {
                key: 4,
                text: _ctx.label,
                "is-can-edit": _ctx.canEdit,
                schema: _ctx.schema,
                uischema: _ctx.uischema
              }, null, 8, ["text", "is-can-edit", "schema", "uischema"]))
            : (_ctx.isPresenceOfBrands)
              ? (_openBlock(), _createBlock(_component_presence_of_brands, {
                  key: 5,
                  text: _ctx.label,
                  "is-can-edit": _ctx.canEdit,
                  schema: _ctx.schema,
                  uischema: _ctx.uischema
                }, null, 8, ["text", "is-can-edit", "schema", "uischema"]))
              : (_ctx.isImplementation)
                ? (_openBlock(), _createBlock(_component_the_implementation, {
                    key: 6,
                    text: _ctx.label,
                    "is-can-edit": _ctx.canEdit,
                    uischema: _ctx.uischema,
                    schema: _ctx.schema
                  }, null, 8, ["text", "is-can-edit", "uischema", "schema"]))
                : (_ctx.isCommunicationBlock)
                  ? (_openBlock(), _createBlock(_component_the_communication, {
                      key: 7,
                      title: _ctx.label,
                      "is-can-edit": _ctx.canEdit,
                      schema: _ctx.schema,
                      uischema: _ctx.uischema
                    }, null, 8, ["title", "is-can-edit", "schema", "uischema"]))
                  : (_ctx.isConflictHistoryBlock)
                    ? (_openBlock(), _createBlock(_component_the_conflict_history, {
                        key: 8,
                        title: _ctx.label,
                        "is-can-edit": _ctx.canEdit,
                        schema: _ctx.schema,
                        uischema: _ctx.uischema
                      }, null, 8, ["title", "is-can-edit", "schema", "uischema"]))
                    : (_ctx.isSalesSupportHistoryBlock)
                      ? (_openBlock(), _createBlock(_component_the_sales_support_history, {
                          key: 9,
                          title: _ctx.label,
                          "is-can-edit": _ctx.canEdit,
                          schema: _ctx.schema,
                          uischema: _ctx.uischema
                        }, null, 8, ["title", "is-can-edit", "schema", "uischema"]))
                      : (_ctx.isSeminarHistoryBlock)
                        ? (_openBlock(), _createBlock(_component_the_seminar_history, {
                            key: 10,
                            title: _ctx.label,
                            "is-can-edit": _ctx.canEdit,
                            schema: _ctx.schema,
                            uischema: _ctx.uischema
                          }, null, 8, ["title", "is-can-edit", "schema", "uischema"]))
                        : (_ctx.isRelationshipBreakBlock)
                          ? (_openBlock(), _createBlock(_component_the_relationship_break, {
                              key: 11,
                              title: _ctx.label,
                              "is-can-edit": _ctx.canEdit,
                              schema: _ctx.schema,
                              uischema: _ctx.uischema
                            }, null, 8, ["title", "is-can-edit", "schema", "uischema"]))
                          : (_ctx.isConsumptionRegistry)
                            ? (_openBlock(), _createBlock(_component_the_consumption_registry, {
                                key: 12,
                                title: _ctx.label,
                                "is-can-edit": _ctx.canEdit,
                                schema: _ctx.schema,
                                uischema: _ctx.uischema
                              }, null, 8, ["title", "is-can-edit", "schema", "uischema"]))
                            : (_ctx.isDeliveryRegistry)
                              ? (_openBlock(), _createBlock(_component_the_delivery_registry, {
                                  key: 13,
                                  title: _ctx.label,
                                  "is-can-edit": _ctx.canEdit,
                                  schema: _ctx.schema,
                                  uischema: _ctx.uischema
                                }, null, 8, ["title", "is-can-edit", "schema", "uischema"]))
                              : (_ctx.isAssignHistoryBlock)
                                ? (_openBlock(), _createBlock(_component_assign_history, {
                                    key: 14,
                                    title: _ctx.label
                                  }, null, 8, ["title"]))
                                : _createCommentVNode("", true)
}