
import { JsonSchema } from '@jsonforms/core';
import { JsonFormsChangeEvent } from '@jsonforms/vue';
import { ErrorObject } from 'ajv';
import { defineComponent, PropType } from 'vue';
import { validateData } from '@/utils/helpers/AjvValidator';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import CustomUISchemaElement from '@/components/jsonforms/interfaces/CustomUISchemaElement';
import FormGenerator from '@/components/jsonforms/generators/FormGenerator.vue';
import useShowErrors from '@/composables/form/useShowErrors';
import { OneOfType } from '@/api/schema';

export interface UserFormData {
  lastName: string,
  firstName: string,
  middleName: string,
  position: string,
  division: string,
  department: string,
  email: string,
  phone: string,
}

export interface UserForm {
  data: JsonData,
  currentData: UserFormData,
  schema: JsonSchema,
  uischema: CustomUISchemaElement,
  errors: JsonData,
  validateForm: (showErrors?: boolean) => boolean,
  showErrors: () => void,
  onChange: () => void,
}

export default defineComponent({
  name: 'TheForm',
  inject: ['$userAccess'],
  components: {
    FormGenerator,
  },
  props: {
    data: {
      type: Object as PropType<JsonData>,
      default: () => ({}),
    },
    companies: {
      type: Array as PropType<OneOfType[]>,
      default: () => ([]),
    },
    divisions: {
      type: Array as PropType<OneOfType[]>,
      default: () => ([]),
    },
    departments: {
      type: Array as PropType<OneOfType[]>,
      default: () => ([]),
    },
  },
  data() {
    return {
      errors: [] as ErrorObject[],
      currentData: this.prepareData(this.data),
    };
  },
  watch: {
    data() {
      this.currentData = this.prepareData(this.data);
    },
  },
  computed: {
    schema(): JsonSchema {
      return {
        type: 'object',
        properties: {
          lastName: {
            type: 'string',
          },
          firstName: {
            type: 'string',
          },
          middleName: {
            type: ['string', 'null'],
            default: null,
          },
          position: {
            type: ['string', 'null'],
          },
          ...this.companySchema,
          division: {
            type: ['string', 'null'],
          },
          department: {
            type: ['string', 'null'],
          },
          phone: {
            type: ['string', 'null'],
            pattern: '^[+]{1}[\\0-9\\s]{3,25}$',
            default: null,
            errorMessage: {
              pattern: 'введите корректный номер телефона',
            },
          },
        },
        required: this.$userAccess.admin.isSuperAdmin
          ? ['lastName', 'firstName', 'company']
          : ['lastName', 'firstName'],
      };
    },
    uischema(): CustomUISchemaElement {
      return {
        type: 'VerticalLayout',
        elements: [
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: this.$t('admin.user.detail.form.lastName'),
                scope: '#/properties/lastName',
              },
              {
                type: 'Control',
                label: this.$t('admin.user.detail.form.firstName'),
                scope: '#/properties/firstName',
              },
            ],
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: this.$t('admin.user.detail.form.middleName'),
                scope: '#/properties/middleName',
              },
              {
                type: 'Control',
                label: this.$t('admin.user.detail.form.position'),
                scope: '#/properties/position',
              },
            ],
          },
          ...this.companyUischema,
          {
            type: 'AjaxControl',
            label: this.$t('admin.user.detail.form.division'),
            scope: '#/properties/division',
            options: {
              search: true,
              readonly: this.$userAccess.admin.isSuperAdmin
                && (!this.currentData || !this.currentData.company),
              ajaxConfiguration: {
                categoryName: 'division',
              },
            },
          },
          {
            type: 'AjaxControl',
            label: this.$t('admin.user.detail.form.direction'),
            scope: '#/properties/department',
            options: {
              search: true,
              readonly: !this.currentData || !this.currentData.division,
              isTree: true,
              ajaxConfiguration: {
                categoryName: 'structure',
                filter: {
                  companyId: this.currentData.company,
                  parentId: this.currentData.division,
                },
              },
            },
          },
          {
            type: 'PhoneControl',
            label: this.$t('admin.user.detail.form.phone'),
            scope: '#/properties/phone',
          },
        ],
      };
    },
    companySchema(): JsonSchema | object {
      return this.$userAccess.admin.isSuperAdmin
        ? {
          company: {
            type: ['string', 'null'],
            nullable: true,
            oneOf: this.companies,
          },
        }
        : {};
    },
    companyUischema() {
      return this.$userAccess.admin.isSuperAdmin
        ? [
          {
            type: 'AjaxControl',
            label: this.$t('admin.user.detail.form.company'),
            scope: '#/properties/company',
            options: {
              search: true,
              ajaxConfiguration: {
                categoryName: 'company',
              },
            },
          },
        ]
        : {};
    },
  },
  methods: {
    onChange(event: JsonFormsChangeEvent) {
      const oldCompanyId = this.currentData.company;
      const oldDivisionId = this.currentData.division;

      this.currentData = event.data;

      if (oldCompanyId !== this.currentData.company) {
        this.currentData.division = null;
        this.currentData.department = null;
      }

      if (oldDivisionId !== this.currentData.division) {
        this.currentData.department = null;
      }
    },
    prepareData(data: JsonData): JsonData {
      return {
        lastName: data.lastName ? String(data.lastName) : undefined,
        firstName: data.firstName ? String(data.firstName) : undefined,
        middleName: data.middleName ? String(data.middleName) : undefined,
        position: data.position ? String(data.position) : undefined,
        division: data.division ? String(data.division) : undefined,
        department: data.department ? String(data.department) : undefined,
        company: data.company ? String(data.company) : undefined,
        email: data.email ? String(data.email) : undefined,
        phone: data.phone ? String(data.phone) : undefined,
      };
    },
    validateForm(showErrors = false): boolean {
      this.errors = validateData(this.currentData, this.schema, this.uischema);

      if (showErrors) {
        this.showErrors(this.errors);
      }

      return !this.errors || !this.errors.length;
    },
  },
  setup() {
    const { showErrors } = useShowErrors();

    return { showErrors };
  },
});
