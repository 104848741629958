
import { defineComponent } from 'vue';
import AddIcon from '@/components/ui/icons/AddIcon.vue';
import IconButton from '@/components/ui/buttons/IconButton/IconButton.vue';
import DefaultButton from '@/components/ui/buttons/DefaultButton/DefaultButton.vue';

export default defineComponent({
  name: 'TheTitle',
  inject: ['$sizeWindow', '$userAccess'],
  emits: ['button-click'],
  components: {
    DefaultButton,
    AddIcon,
    IconButton,
  },
  computed: {
    isShowAddButton() {
      return this.$userAccess.admin.isSuperAdmin || this.$userAccess.dictionary.editor.pinning;
    },
  },
  methods: {
    buttonClickHandler() {
      this.$emit('button-click');
    },
  },
});
