import {
  AxiosCallResponse, BaseService, LoggerInterface, ResponseOfRecords,
} from '@/api/base';
import CustomUISchemaElement from '@/components/jsonforms/interfaces/CustomUISchemaElement';
import { DataType } from '@/components/ui/selects/SelectDefault/SelectDefault.vue';

export interface GroupType {
  id: string,
  name: string,
  shortName: string,
}

export interface BlockType {
  id: string,
  label: string,
  type: string,
  visible?: boolean,
  schema?: CustomUISchemaElement,
}

export interface GetBlockParams {
  groupId?: string | null,
}

export interface SyncFieldErrorType {
  group: DataType,
  block: DataType,
  field: DataType,
  value: string,
  cardId: string,
  item?: DataType,
}

export interface CardStructureType {
  [key: string]: CardStructureType[],
}

export type BlockListType = ResponseOfRecords<BlockType[]>;
export type SyncFieldErrorResponse = ResponseOfRecords<SyncFieldErrorType[]>;

export default class CardService extends BaseService {
  constructor(token: string, logger: LoggerInterface = console) {
    super('card', token, logger, 'v2');
  }

  async getGroups(id: string): Promise<AxiosCallResponse<GroupType[]>> {
    return this.axiosCall({ url: `/${id}/group`, method: 'get' });
  }

  async getBlocks(id: string, params: GetBlockParams): Promise<AxiosCallResponse<BlockListType>> {
    return this.axiosCall({ url: `/${id}/block`, method: 'get', params });
  }

  async getSyncFieldErrors(id: string): Promise<AxiosCallResponse<SyncFieldErrorResponse>> {
    return this.axiosCall({ url: `/${id}/sync-field-error`, method: 'get' });
  }

  async getReportData(id: string): Promise<AxiosCallResponse<CardStructureType[]>> {
    return this.axiosCall({ url: `/${id}/report` });
  }
}
