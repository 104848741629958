import {
  AxiosCallResponse, BaseService, LoggerInterface, QueryParamsForList, ResponseOfRecords,
} from '@/api/base';
import { DataType } from '@/components/ui/selects/SelectDefault/SelectDefault.vue';

export interface BankAccountData {
  id?: string,
  name: string,
  counterparty: DataType,
  account: string,
  bank: DataType,
  settlementsBank: DataType,
  correspondent: string,
  type: string,
  currency: string,
  isGovContract: boolean,
}

export interface BankAccountCreateData {
  name: string,
  counterparty: string,
  account: string,
  bank: string,
  settlementsBank: string,
  correspondent: string,
  type: string,
  currency: string,
  isGovContract: boolean,
}

export interface BankAccountUpdateData {
  name: string,
  counterparty: string,
  account: string,
  bank: string,
  settlementsBank: string,
  correspondent: string,
  type: string,
  currency: string,
  isGovContract: boolean,
}

export type BankAccountResponseType = AxiosCallResponse<ResponseOfRecords<BankAccountData[]>>;

export default class BankAccountService extends BaseService {
  constructor(token: string, logger: LoggerInterface = console) {
    super('admin/dictionary/bank-account', token, logger);
  }

  async list(params: QueryParamsForList): Promise<BankAccountResponseType> {
    return this.axiosCall({ method: 'get', params });
  }

  async create(data: BankAccountCreateData): Promise<AxiosCallResponse<BankAccountData>> {
    return this.axiosCall({ method: 'post', data });
  }

  async update(id: string, data: BankAccountUpdateData): Promise<AxiosCallResponse<null>> {
    return this.axiosCall({ url: `/${id}`, method: 'patch', data });
  }

  async delete(id: string): Promise<AxiosCallResponse<null>> {
    return this.axiosCall({ url: `/${id}`, method: 'delete' });
  }
}
