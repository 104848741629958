
import { defineComponent, PropType } from 'vue';
import { JsonSchema } from '@jsonforms/core';
import DefaultButton from '@/components/ui/buttons/DefaultButton/DefaultButton.vue';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import IconButton from '@/components/ui/buttons/IconButton/IconButton.vue';
import EditIcon from '@/components/ui/icons/EditIcon.vue';
import TrashIcon from '@/components/ui/icons/TrashIcon.vue';
import TheForm, { ListBlockTemplateForm } from '@/components/counterparty/card/blocks/list-block-template/form/TheForm.vue';
import CustomUISchemaElement from '@/components/jsonforms/interfaces/CustomUISchemaElement';

export default defineComponent({
  name: 'TheDetail',
  inject: ['$sizeWindow'],
  emits: ['cancel', 'update', 'delete', 'changeMode'],
  components: {
    TrashIcon,
    EditIcon,
    IconButton,
    DefaultButton,
    TheForm,
  },
  props: {
    data: {
      type: Object as PropType<JsonData>,
    },
    schema: Object as PropType<JsonSchema>,
    uischema: Object as PropType<CustomUISchemaElement>,
    isCanEdit: Boolean,
  },
  data() {
    return {
      isLoading: false,
      isView: true,
      localData: this.data,
    };
  },
  watch: {
    isView(value: boolean) {
      this.$emit('changeMode', !value);
    },
  },
  methods: {
    cancelHandler() {
      this.isView = true;
      this.localData = this.data;
    },
    updateDataHandler(data: JsonData) {
      this.localData = data;
    },
    changeEditMode() {
      this.isView = false;
    },
    updateHandler() {
      this.isLoading = true;

      const formRef: ListBlockTemplateForm = this.$refs.form as ListBlockTemplateForm;
      const valid: boolean = formRef?.validateForm(true) ?? false;
      const data: JsonData | null = formRef?.currentData ?? null;

      if (!valid || !data) {
        this.isLoading = false;

        return false;
      }

      this.$emit('update', data, () => {
        this.isLoading = false;
        this.cancelHandler();
      });

      return true;
    },
    deleteHandler() {
      this.$emit('delete');
    },
  },
});
