
import { defineComponent, PropType } from 'vue';
import { JsonSchema } from '@jsonforms/core';
import relationshipBreak from '@/shared/consts/blocks/relationshipBreak';
import SelectDefault from '@/components/ui/selects/SelectDefault/SelectDefault.vue';
import DatepickerPeriod, {
  DatepickerPeriodType,
} from '@/components/ui/datepickers/datepicker-period/DatepickerPeriod.vue';

export default defineComponent({
  name: 'TheFilter',
  inject: ['$sizeWindow'],
  emits: ['filterChanged'],
  components: {
    DatepickerPeriod,
    SelectDefault,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    isCanEdit: {
      type: Boolean,
      default: false,
    },
    schema: {
      type: Object as PropType<JsonSchema>,
      default: () => ({}),
    },
  },
  data() {
    return {
      dateData: [
        {
          id: relationshipBreak.BLOCK_FIELD_DATE_START,
          name: this.$t('counterparty.tabs.relationship.relationshipBreak.filter.dateStart'),
        },
        {
          id: relationshipBreak.BLOCK_FIELD_DATE_END,
          name: this.$t('counterparty.tabs.relationship.relationshipBreak.filter.dateEnd'),
        },
      ],
      filter: {
        typeDate: relationshipBreak.BLOCK_FIELD_DATE_START,
        date: null,
      },
    };
  },
  computed: {
    newFilter() {
      return {
        between: {
          [this.filter.typeDate]: this.filter.date,
        },
      };
    },
  },
  methods: {
    changeDateHandler(date: DatepickerPeriodType) {
      this.filter.date = [date.beforeDate, date.afterDate];

      this.$emit('filterChanged', this.newFilter);
    },
    changeTypeHandler(id: string) {
      this.filter.typeDate = id;

      this.$emit('filterChanged', this.newFilter);
    },
  },
});
