
import { defineComponent, PropType } from 'vue';
import config from '@/config';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import HomeIcon from '@/components/ui/icons/BuildingIcon.vue';
import FactoryIcon from '@/components/ui/icons/FactoryIcon.vue';
import InternationalIcon from '@/components/ui/icons/InternationalIcon.vue';
import UserItem from '@/components/counterparty/list/sidebar/content/UserItem/UserItem.vue';
import { CounterpartyManager } from '@/api/counterparty';
import DefaultLoader from '@/components/ui/loaders/DefaultLoader.vue';
import IconButton from '@/components/ui/buttons/IconButton/IconButton.vue';
import EditIcon from '@/components/ui/icons/EditIcon.vue';
import fields from '@/shared/consts/counterparty/fields';

export default defineComponent({
  name: 'SidebarContent',
  emits: ['openEditForm'],
  components: {
    EditIcon,
    IconButton,
    HomeIcon,
    FactoryIcon,
    InternationalIcon,
    UserItem,
    DefaultLoader,
  },
  props: {
    counterparty: {
      type: Object as PropType<JsonData>,
      required: true,
    },
    managers: {
      type: Array as PropType<CounterpartyManager[]>,
      default: () => ([]),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isCanEditDataMdm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpenUser: null as number | null,
    };
  },
  computed: {
    counterpartyName() {
      return this.counterparty[fields.NAME_FIELD] ?? '';
    },
    inn() {
      return this.counterparty[fields.INN_FIELD] ?? '';
    },
    kpp() {
      return this.counterparty[fields.KPP_FIELD] ?? '';
    },
    taxNumber() {
      return this.counterparty[fields.TAX_NUMBER_FIELD] ?? '';
    },
    counterpartyRegNumber() {
      return this.counterparty[fields.REG_NUMBER] ?? '';
    },
    counterpartyLegalAddress() {
      return this.counterparty?.[fields.LEGAL_ADDRESS_FIELD]?.[config.ADDRESS_MAPPER_FIELD_TEXT] ?? '';
    },
    counterpartyActualAddress() {
      return this.counterparty?.[fields.ACTUAL_ADDRESS_FIELD]?.[config.ADDRESS_MAPPER_FIELD_TEXT] ?? '';
    },
    counterpartySite() {
      return this.counterparty[config.COUNTERPARTY_SITE_FIELD] ?? '';
    },
  },
  methods: {
    openEditMdmData() {
      this.$emit('openEditForm');
    },
  },
});
