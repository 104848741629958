
import { defineComponent, PropType } from 'vue';
import { DateTime } from 'luxon';
import { month } from '@/components/ui/datepickers/consts';
import MonthItem from '@/components/ui/datepickers/datepicker-period/layouts/month/MonthItem.vue';
import { TYPE_LAYOUT_YEAR, TYPE_LAYOUT_DAY } from '@/components/ui/datepickers/datepicker-period/layouts/DatepickerLayout.vue';

export default defineComponent({
  emit: ['change', 'change-layout'],
  name: 'MonthLayout',
  components: { MonthItem },
  props: {
    date: {
      type: Object as PropType<DateTime | null>,
      default: null,
    },
  },
  data() {
    return {
      nowDate: DateTime.now(),
      currentDate: null,
    };
  },
  computed: {
    monthList() {
      return month;
    },
    year() {
      return this.currentDate.year;
    },
    day() {
      return this.currentDate.day;
    },
  },
  methods: {
    openYearLayout() {
      this.$emit('change-layout', TYPE_LAYOUT_YEAR);
    },
    openDayLayout() {
      this.$emit('change-layout', TYPE_LAYOUT_DAY);
    },
    isSelectedMonth(monthId: number) {
      return this.currentDate.month === monthId + 1;
    },
    isCurrentMonth(monthId: number) {
      return this.nowDate.year === this.currentDate.year && this.nowDate.month === monthId + 1;
    },
    setMonth(monthId: number) {
      this.$emit('change', monthId + 1);
    },
  },
  watch: {
    date(value) {
      this.currentDate = value ?? this.nowDate;
    },
  },
  created() {
    this.currentDate = this.date ?? this.nowDate;
  },
});
