import { AxiosCallResponse } from '@/api/base';
import store from '@/store';
import StructureService, { StructureItem } from '@/api/admin/dictionary/structure';

export function useOneApi() {
  return {
    async loadData(id: string) {
      const api: StructureService = store
        .getters
        .$api
        .admin
        .dictionary
        .structure;

      const response: AxiosCallResponse<StructureItem> = await api.one(id);

      return response.data ?? null;
    },
  };
}

export default {};
