
import { defineComponent } from 'vue';
import TheTooltip from '@/components/ui/modal/TheTooltip/TheTooltip.vue';
import CircleCheckIcon from '@/components/ui/icons/CircleCheckIcon.vue';

export default defineComponent({
  name: 'ActiveStatusIcon',
  components: {
    TheTooltip,
    CircleCheckIcon,
  },
});
