import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3dd20570"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "contact-block" }
const _hoisted_2 = { class: "contact-block-item" }
const _hoisted_3 = { class: "contact-block-item__icon" }
const _hoisted_4 = ["href"]
const _hoisted_5 = {
  key: 0,
  class: "contact-block-item"
}
const _hoisted_6 = { class: "contact-block-item__icon" }
const _hoisted_7 = {
  key: 1,
  class: "contact-block-item"
}
const _hoisted_8 = { class: "contact-block-item__icon" }
const _hoisted_9 = { class: "flex items-center space-x-[8px]" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { key: 2 }
const _hoisted_13 = {
  key: 2,
  class: "contact-block-item"
}
const _hoisted_14 = { class: "contact-block-item__icon" }
const _hoisted_15 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_email_icon = _resolveComponent("email-icon")!
  const _component_briefcase_icon = _resolveComponent("briefcase-icon")!
  const _component_building_icon = _resolveComponent("building-icon")!
  const _component_right_arrow_icon = _resolveComponent("right-arrow-icon")!
  const _component_phone_icon = _resolveComponent("phone-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_email_icon, {
            width: "18",
            height: "18",
            type: "regular",
            color: "#64748B"
          })
        ]),
        _createElementVNode("span", null, [
          _createElementVNode("a", {
            href: `mailto:${_ctx.user.email}`
          }, _toDisplayString(_ctx.user.email ?? ''), 9, _hoisted_4)
        ])
      ]),
      (!!_ctx.user.position)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_briefcase_icon, {
                width: "18",
                height: "18",
                type: "regular"
              })
            ]),
            _createElementVNode("span", null, _toDisplayString(_ctx.user.position), 1)
          ]))
        : _createCommentVNode("", true),
      (!!_ctx.division || _ctx.department)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_building_icon, {
                width: "18",
                height: "18",
                type: "regular",
                color: "#64748B"
              })
            ]),
            _createElementVNode("div", _hoisted_9, [
              (!!_ctx.division)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.division), 1))
                : _createCommentVNode("", true),
              (!!_ctx.division && _ctx.department)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    _createVNode(_component_right_arrow_icon)
                  ]))
                : _createCommentVNode("", true),
              (!!_ctx.department)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.department), 1))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true),
      (!!_ctx.user.phone)
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
            _createElementVNode("div", _hoisted_14, [
              _createVNode(_component_phone_icon, {
                width: "18",
                height: "18",
                type: "regular",
                color: "#64748B"
              })
            ]),
            _createElementVNode("span", null, [
              _createElementVNode("a", {
                href: `tel:${_ctx.user.phone}`
              }, _toDisplayString(_ctx.user.phone ?? ''), 9, _hoisted_15)
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}