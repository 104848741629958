import config from '@/config';

export default {
  common: {
    yes: 'Yes',
    no: 'No',
    noData: '–',
    isDeleted: 'Deleted',
    noDataToDisplay: 'No data to display',
    search: 'Search',
    active: 'Active',
    blocked: 'Blocked',
    notActive: 'Not active',
  },
  buttons: {
    add: 'Add',
    characteristic: 'Characteristic',
    cancel: 'Cancel',
    save: 'Save',
    create: 'Create',
    notSave: 'Dont save',
    edit: 'Edit',
    send: 'Send',
    hide: 'Hide',
    delete: 'Delete',
    show: 'Show all...',
    history: 'History',
    backToView: 'Back to detail',
    apply: 'Apply',
    switch: 'Switch data slice',
    restore: 'Restore',
    logout: 'Logout',
  },
  counterparty: {
    list: {
      all: 'All counterparties',
      goToCard: 'Go to card',
      title: 'Counterparties',
      linkToManuallyForm: 'Manually',
      modal: {
        create: {
          dangerModal: {
            confirmButton: 'Yes, go',
            text: 'When the modal window is closed, the completed data will be lost.',
            hintText: 'Are you sure you want to cancel the creation of the pin?',
          },
        },
      },
      filter: {
        filters: 'Filters',
        clear: 'Clear filters',
        form: {
          industry: 'Industry',
          status: 'Status',
          block: 'Block',
          division: 'Division',
          department: 'Department',
          assignUser: 'Manager',
          province: 'Region',
          city: 'City',
          statuses: {
            [config.COUNTERPARTY_FILTER_STATUS_RESIDENT]: 'Resident',
            [config.COUNTERPARTY_FILTER_STATUS_NOT_RESIDENT]: 'Not resident',
          },
          blocks: {
            [config.COUNTERPARTY_FILTER_BLOCK_WORKING]: 'Working block',
            [config.COUNTERPARTY_FILTER_BLOCK_FREE]: 'Free block',
          },
        },
      },
    },
    card: {
      block: {
        finishEdit: 'Finish editing the block "{block}"',
      },
      syncFieldError: {
        title: 'Data synchronized with errors',
        info: 'Sync errors to check and fix',
        button: 'Synchronization conflicts',
      },
    },
    refresh: {
      dangerModal: {
        confirmButton: 'Yes, go',
        text: 'When the page is refreshed, the completed data will be lost.',
        hintText: 'Are you sure you want to switch?',
      },
    },
    header: {
      dangerModal: {
        confirmButton: 'Yes, go',
        text: 'When you exit this page, the completed data will be lost..',
        hintText: 'Are you sure you want to switch?',
      },
    },
    tabs: {
      dangerModal: {
        confirmButton: 'Yes, go',
        text: 'If you switch to another tab, the completed data will be lost.',
        hintText: 'Are you sure you want to switch?',
      },
      relationship: {
        communication: {
          fields: {
            date: 'Date',
            status: 'Status',
            place: 'Place',
            type: 'Type',
            user: 'User',
            duration: 'Duration',
            description: 'Description',
            content: 'Content',
          },
          statuses: {
            completed: 'Completed',
            planned: 'Planned',
            canceled: 'Canceled',
          },
        },
        conflictHistory: {
          date: 'Claim date',
          type: 'Claim type',
          user: 'OP manager',
        },
        salesSupportHistory: {
          date: 'Departure date',
          type: 'Support type',
          user: 'OP/CCI Manager',
        },
        seminarHistory: {
          date: 'Conducting date',
          name: 'Name',
          point: 'Location',
          filter: {
            invite: 'By invitation date',
            conducting: 'By date conducting',
          },
        },
        relationshipBreak: {
          dateStart: 'Date start',
          dateEnd: 'Date end',
          reason: 'Reason',
          resumptionPlan: 'Resumption plan',
          filter: {
            dateStart: 'By start date',
            dateEnd: 'By end date',
          },
        },
      },
      general: {
        assignHistory: {
          fields: {
            assignmentDate: 'Assignment Date',
            detachmentDate: 'Detachment Date',
            user: 'User',
            department: 'Department',
            reason: 'Reason',
          },
        },
      },
    },
    create: {
      title: 'Add a new counterparty',
      tabs: {
        manually: 'Manually',
        search: 'Search',
      },
      form: {
        search: {
          input: 'Code MDM or INN',
          edit: 'Edit',
          isPriority: 'Priority data',
          placeholder: {
            input: 'Enter code MDM or INN',
          },
        },
      },
      buttons: {
        search: 'Get Data',
        send: 'Create',
        next: 'Next',
        mobile: {
          search: 'Get',
          send: 'Create',
          next: 'Next',
        },
      },
      error: {
        notFound: 'No records found',
        notFoundExpand: 'No records found, use "Manually"',
      },
    },
    pinning: {
      datePin: 'Date pinning',
      dateUnpin: 'Date unpinning',
      manager: 'Manager',
      division: 'Division',
      cause: 'Cause',
      responsible: 'Responsible',
      view: {
        title: 'Detail Info',
      },
      buttons: {
        pinning: 'Assign',
      },
    },
    payment: {
      isBlock: 'Blocked',
      blockPopup: {
        title: 'Block current account?',
        description: 'Are you sure you want to block the selected current account?',
        confirmButton: 'Yes, block',
      },
      unblockPopup: {
        title: 'Unblock current account?',
        description: 'Are you sure you want to unblock the selected current account?',
        confirmButton: 'Yes, unlock',
      },
    },
    consumptionRegistry: {
      info: 'Fill in the filters to load data or switch to edit mode.',
      infoEdit: 'Fill in filters to edit data.',
      placeholders: {
        year: 'Year',
        group: 'Group',
      },
    },
  },
  counterparties: {
    list: 'List of counterparties',
    goToCard: 'Go to card',
    changeData: 'To change the data',
    addRepresentative: 'Add Representative',
  },
  counterpartyLabels: {
    name: 'Counterparty name',
    fullName: 'Counterparty full name',
    type: 'Counterparty type',
    country: 'Country',
    industry: 'Industry',
    marketStatus: 'Market status',
    view: 'View',
    legalAddress: 'Legal address',
    actualAddress: 'The actual address',
    taxNumber: 'Tax number',
    inn: 'INN',
    taxNumberOrInn: 'Tax number (INN)',
    kpp: 'KPP',
    codeMdm: 'Code MDM',
    division: 'Division',
    regNumber: 'Reg. number',
    registrationNumber: 'Registration number',
    contacts: 'Contacts',
    site: 'Site',
    department: 'Department',
    manager: 'Manager',
    managers: 'Managers',
    inHolding: 'Included in the holding',
    headCompany: 'Head company',
    isHeadCompany: 'Is the parent company',
    haventOwnVolume: 'Doesnt have its own volume.',
    ownDevelopedClient: 'Personally developed client',
    singlePurchasingCenter: 'Single purchasing center',
  },
  agent: {
    add: 'Add agent',
    modal: {
      create: {
        title: 'Add agent',
      },
      detail: {
        form: {
          block: 'Block',
        },
      },
    },
    noData: 'No data on agents of influence',
  },
  modals: {
    removeItem: 'Are you sure you want to delete the selected entry?',
    removeConfirm: 'Yes, delete',
    confirmation: {
      dangerModal: {
        confirmButton: 'Yes, go',
        text: 'When you close the form, the completed data will be lost.',
        hintText: 'Are you sure you want to switch?',
      },
    },
  },
  news: {
    newsTitle: 'News',
    addNews: 'Add News',
    editNews: 'Edit News',
    newsList: 'News on page',
    removeItem: 'Are you sure you want to delete the selected news?',
    dateLabel: 'Date of publication in the source',
    shortDescription: 'Short description',
  },
  newsAttributes: {
    publishedAt: 'Published date',
    content: 'Description',
    link: 'Link',
  },
  errors: {
    notFoundNews: 'There is no news',
    link: 'No valid link',
    serverError: {
      text: 'Server Error',
      subtext: 'An unexpected error occurred on the server.',
    },
    accessDenied: {
      text: 'Access is denied',
      subtext: 'You do not have permissions to access this page.',
    },
    notFound: {
      text: 'Page not found',
      subtext: 'The page you are looking for does not exist.',
    },
  },
  billingNumber: {
    button: 'Application for adding an account',
    newRequest: 'Application for adding a new account',
    newRequestShortLabel: 'Adding a new account',
    editRequest: 'Application for editing a current account',
    formLabels: {
      name: 'Current account name',
      number: 'Current account number',
      currency: 'Currency',
      bank: 'Bank',
      bik: 'BIC',
      inn: 'INN',
    },
    tabs: {
      home: 'Ready account',
      alterHome: 'Account',
      request: 'Requests',
      archive: 'Archive',
    },
  },
  address: {
    labels: {
      postalCode: 'Index',
      country: 'Country',
      province: 'Region',
      locality: 'City',
      area: 'Area',
      street: 'Street',
      house: 'House',
      housing: 'Housing',
      flat: 'Apartment',
    },
    shortLabels: {
      province: '',
      locality: '',
      area: '',
      street: '',
      house: '',
      housing: '',
      flat: '',
    },
  },

  notification: {
    counterparty: {
      success: {
        add: 'New counterparty sent for approval',
        search: 'Counterparty created',
        update: 'The block was saved successfully.',
        updateMdm: 'MDM data sent for approval',
      },
      error: {
        add: 'For technical reasons, it was not possible to add a new counterparty. Please try again later.',
        update: 'For technical reasons, it was not possible to save the current data. Please try again later.',
        updateMdm: 'Failed to submit MDM data for approval. Please try again later.',
      },
    },
    agents: {
      success: {
        add: 'A new agent of influence has been added.',
        remove: 'Agent of influence has been removed.',
        create: 'A new agent of influence has been added.',
        update: 'Agent of influence saved.',
        block: 'Agent of influence blocked.',
        unblock: 'Agent of influence unlocked.',
      },
      error: {
        add: 'Failed to save the new agent of influence. Please try again later.',
        create: 'Failed to save the new agent of influence. Please try again later.',
        remove: 'Failed to remove the agent of influence. Please try again later.',
        update: 'Failed to update influence. Please try again later.',
        block: 'Failed to block agent of influence. Please try again later.',
        unblock: 'Failed to unlock agent of influence. Please try again later.',
      },
    },
    news: {
      success: {
        add: 'News added successfully.',
        edit: 'The news has been successfully edited.',
        delete: 'The news has been removed.',
      },
      error: {
        add: 'For technical reasons it was not possible to add news. Please try again later.',
        edit: 'For technical reasons it was not possible to edit the news. Please try again later.',
        delete: 'For technical reasons it was not possible to delete the news. Please try again later.',
      },
    },
    admin: {
      dictionary: {
        success: {
          create: 'Entry created successfully',
          update: 'Entry edited successfully',
          delete: 'Entry deleted successfully',
          restore: 'Entry restore successfully',
          block: 'Entry block successfully',
          unblock: 'Entry unblock successfully',
        },
      },
      user: {
        success: {
          update: 'User successfully edited',
          block: 'User has been blocked',
          unblock: 'User has been unlocked',
        },
        error: {
          create: 'Failed to create user',
          update: 'Failed to update user',
          changeStatus: 'Failed to change status',
        },
      },
      pinning: {
        success: {
          detach: 'Entry successfully unpinned',
          reassign: 'Entry successfully re-pinned',
        },
      },
    },
    forms: {
      requiredError: 'There are not enough values for fields {field} to add this record. The placeholder is the corresponding directory.',
    },
  },
  admin: {
    user: {
      detail: {
        form: {
          block: 'Block',
          email: 'Email',
          firstName: 'First name',
          middleName: 'Middle name',
          position: 'Position',
          lastName: 'Last name',
          company: 'Company',
          division: 'Division',
          direction: 'Direction',
          phone: 'Phone',
          placeholder: {
            email: 'Enter user account email',
          },
        },
        role: {
          title: 'Access rights',
          placeholders: {
            department: 'Select a department',
          },
        },
      },
      create: {
        title: 'New user',
        buttons: {
          verify: 'Get data',
          create: 'Create user',
          mobile: {
            verify: 'Get',
            create: 'Create',
          },
        },
        error: {
          userNotFound: 'User not found',
          userAlreadyExists: 'User already exists',
        },
      },
      list: {
        title: 'User management',
        table: {
          fio: 'Full name',
          email: 'Email',
          division: 'Division',
          role: 'Role',
        },
        search: {
          division: 'Division',
          role: 'Role',
        },
      },
    },
  },
  dictionary: {
    general: {
      detail: 'Detail Info',
      update: 'Update',
      create: 'Create',
    },
    country: {
      title: 'Countries',
      new: 'New Country',
      edit: 'Edit',
      table: {
        code: 'Code',
        shortName: 'Name',
        fullName: 'Full Name',
        alpha2: 'CodeAlpha2',
        alpha3: 'CodeAlpha3',
      },
      placeholders: {
        code: 'Enter code',
        shortName: 'Enter name',
        fullName: 'Enter full name',
        alpha2: 'Enter letter code alpha-2',
        alpha3: 'Enter letter code alpha-3',
      },
    },
    structure: {
      title: 'The structure of the company',
      table: {
        name: 'Name',
        fullName: 'Full name',
        department: 'Sales department',
        division: 'Division',
        code: 'Code',
        assignmentStrategy: 'Assignment strategy',
      },
      view: {
        title: 'Detailed information',
      },
      create: {
        addChild: 'Add child structure',
        title: 'Add structure',
      },
      dangerModal: {
        text: 'Are you sure you want to delete the selected structure?',
        confirmButton: 'Yes, delete',
      },
      assignmentStrategies: {
        oneAssignment: 'One assignment to counterparty in department',
        manyAssignments: 'Many assignment to counterparty in department',
      },
    },
    pinning: {
      title: 'Assign',
      counterpartyTitle: 'Counterparty data',
      fields: {
        counterpartyType: 'Counterparty type',
        counterparty: 'Counterparty',
        user: 'Manager',
        date: 'Date',
        division: 'Division',
        department: 'Department',
        detachmentDate: 'Detachment date',
        result: 'Decision',
        region: 'Region',
        nomenclature: 'Nomenclature',
        nomenclatureGroup: 'Nomenclature Group',
        comment: 'Comment',
        reasonDetachment: 'Detachment reason',
        reasonReassignment: 'Reassign reason',
        reasonComment: 'Reason comment',
      },
      detach: 'Detach entry',
      reassign: 'Reassign entry',
      buttons: {
        detach: 'Detach',
        reassign: 'Reassign',
      },
    },
    company: {
      title: 'Company',
      fields: {
        name: 'Name',
        code: 'Code',
        subdomain: 'Subdomain',
        admins: 'Admins',
        authorizationMethod: 'Method Authorization',
      },
      placeholders: {
        name: 'Enter name',
        code: 'Enter code',
        subdomain: 'Enter subdomain',
        admins: 'Choice admins',
        authorizationMethod: 'Choice authorization method',
      },
    },
    category: {
      title: 'CounterpartyCategory',
      fields: {
        name: 'Name',
        shortName: 'Short name',
      },
      placeholders: {
        name: 'Enter name',
        shortName: 'Enter short name',
      },
    },
    bank: {
      title: 'Banks',
      fields: {
        name: 'Name',
        code: 'Code',
        bic: 'BIK',
        corrAccount: 'Correspondent account',
        city: 'City',
        address: 'Address',
        phone: 'Phone',
      },
    },
    industry: {
      title: 'Industry',
      fields: {
        name: 'Name',
        description: 'Description',
      },
      placeholders: {
        name: 'Enter name',
      },
    },
    psychologicalCharacteristic: {
      title: 'Psychological characteristics',
      fields: {
        type: 'Metaprogram',
        value: 'Value',
        interpretation: 'Interpretation',
      },
      placeholders: {
        type: 'Select metaprogram',
        value: 'Enter value',
        interpretation: 'Enter interpretation',
      },
    },
    nomenclatureGroup: {
      title: 'Nomenclature group',
      fields: {
        name: 'Name',
        certificateName: 'Certificate name',
      },
      placeholders: {
        name: 'Enter name',
        certificateName: 'Enter certificate name',
      },
    },
    nomenclature: {
      title: 'Nomenclature',
      fields: {
        name: 'Name',
        fullName: 'Full name',
        codeMdm: 'Code MDM',
        groupId: 'Nomenclature group',
        measUnit: 'Unit of measurement',
        vatRate: 'VAT rate',
        vendorCode: 'Vendor code',
      },
    },
    bankAccount: {
      title: 'Bank account',
      fields: {
        name: 'Name',
        counterparty: 'Counterparty',
        account: 'Account',
        bank: 'Bank',
        settlementsBank: 'Bank for indirect settlements',
        correspondent: 'Correspondent',
        type: 'Type',
        currency: 'Currency',
        isGovContract: 'Government contract',
      },
    },
    technologicalSupportType: {
      title: 'Technological support types',
      fields: {
        name: 'Name',
      },
      placeholders: {
        name: 'Enter name',
      },
    },
    activityDirection: {
      title: 'Main activity directions',
      fields: {
        name: 'Name',
      },
      placeholders: {
        name: 'Enter name',
      },
    },
    position: {
      title: 'Position',
      fields: {
        name: 'Name',
      },
      placeholders: {
        name: 'Enter name',
      },
    },
    education: {
      title: 'Education',
      fields: {
        name: 'Name',
      },
      placeholders: {
        name: 'Enter name',
      },
    },
    individual: {
      title: 'Individuals',
      fields: {
        name: 'Name',
        counterparty: 'Counterparty',
        inn: 'INN',
        ifts: 'Code of IFTS',
        birthday: 'Birthday',
      },
    },
    region: {
      title: 'Regions',
      fields: {
        name: 'Name',
      },
    },
  },
  history: {
    label: 'History',
    date: 'Date',
    responsible: 'Responsible',
    status: 'Status',
    changes: 'Changes',
    detail: {
      main: 'Date and responsible',
      oldValue: 'Old Value',
      newValue: 'New Value',
    },
    dangerModal: {
      text: 'Are you sure you want to delete the selected blank?',
      confirmButton: 'Yes, delete',
    },
  },
  jsonforms: {
    buttons: {
      editMdmData: 'Edit MDM data',
      cancel: 'Cancel',
      send: 'Send',
      edit: 'Edit',
      history: 'History',
    },
    generalInformation: {
      mdm: {
        title: 'MDM data',
      },
    },
    tableOfMonths: {
      month: 'Month',
      months: {
        january: 'January',
        february: 'February',
        march: 'March',
        april: 'April',
        may: 'May',
        june: 'June',
        july: 'July',
        august: 'August',
        september: 'September',
        october: 'October',
        november: 'November',
        december: 'December',
      },
      monthlyAverage: 'Monthly average',
      monthlyAmount: 'Monthly amount',
    },
  },
  ui: {
    select: {
      search: {
        notFound: 'No records found',
        loading: 'Loading...',
      },
    },
  },
  sidebar: {
    title: {
      counterparties: 'Counterparties',
      users: 'Users',
      pinning: 'Pinning list',
      countries: 'Countries',
      structure: 'Company structure',
      companies: 'Companies',
      categories: 'Counterparty categories',
      banks: 'Banks',
      industries: 'Industries',
      bankAccounts: 'Bank accounts',
      psychologicalCharacteristics: 'Psychological characteristics',
      nomenclatureGroup: 'Nomenclature group',
      technologicalSupportType: 'Technological support types',
      position: 'Positions',
      activityDirection: 'Main activity directions',
      education: 'Education',
      individuals: 'Individuals',
      nomenclature: 'Nomenclature',
      region: 'Regions',
      profile: 'Profile',
    },
  },
  tour: {
    closeButton: 'Close',
    prevButton: 'Prevent',
    nextButton: 'Next',
    doneButton: 'Done',
  },
};
