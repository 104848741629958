import { StateType } from '@/store';
import list, { RegionListStateType } from '@/store/modules/admin/dictionary/region/list';
import one, { RegionOneStateType } from '@/store/modules/admin/dictionary/region/one';
import { Module } from 'vuex';

export interface RegionStateType {
  list: RegionListStateType,
  one: RegionOneStateType,
}

const regionModule: Module<RegionStateType, StateType> = {
  namespaced: true,
  modules: {
    list,
    one,
  },
};

export default regionModule;
