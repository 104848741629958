import { Store } from 'vuex';

export interface NotificationLoggerInterface {
  setNotification(type: string, message: string): Promise<void>
}

export default class NotificationLogger<T> implements NotificationLoggerInterface {
  constructor(private store: Store<T>) {}

  async setNotification(type: string, message: string) {
    await this.store.dispatch('notifications/newNotification', {
      type,
      message,
    }, {
      root: true,
    });
  }
}
