import { AxiosCallResponse } from '@/api/base';
import store from '@/store';
import TechnologicalSupportTypeService, {
  TechnologicalSupportTypeSchema,
} from '@/api/admin/dictionary/typesTechnologicalSupport';

export function useOneApi() {
  return {
    async loadData(id: string) {
      const api: TechnologicalSupportTypeService = store
        .getters
        .$api
        .admin
        .dictionary
        .technologicalSupportType;

      const response: AxiosCallResponse<TechnologicalSupportTypeSchema> = await api.one(id);

      return response.data ?? null;
    },
  };
}

export default {};
