
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LinkIcon',
  props: {
    width: {
      type: String,
      default: '24',
    },
    height: {
      type: String,
      default: '24',
    },
    color: {
      type: String,
      default: '#64748B',
    },
  },
});
