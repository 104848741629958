import { Module } from 'vuex';
import list, {
  PsychologicalCharacteristicListStateType,
} from '@/store/modules/admin/dictionary/psychological-characteristic/list';
import one, {
  PsychologicalCharacteristicOneStateType,
} from '@/store/modules/admin/dictionary/psychological-characteristic/one';
import { StateType } from '@/store';

export interface PsychologicalCharacteristicModuleStateType {
  list: PsychologicalCharacteristicListStateType,
  one: PsychologicalCharacteristicOneStateType,
}

const characteristicModule: Module<PsychologicalCharacteristicModuleStateType, StateType> = {
  namespaced: true,
  modules: {
    list,
    one,
  },
};

export default characteristicModule;
