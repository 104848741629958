
import { defineComponent, PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { OneOfType } from '@/api/schema';
import DefaultLoader from '@/components/ui/loaders/DefaultLoader.vue';
import TheCounterpartyData from '@/components/admin/dictionary/assign/forms/counterpartyData/TheCounterpartyData.vue';
import type from '@/shared/consts/assign/type';

export default defineComponent({
  name: 'TheView',
  inject: ['$sizeWindow'],
  emits: ['cancel', 'create'],
  components: { TheCounterpartyData, DefaultLoader },
  props: {
    id: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'parent',
    },
    departments: {
      type: Array as PropType<OneOfType[]>,
      default: () => [],
    },
    divisions: {
      type: Array as PropType<OneOfType[]>,
      default: () => [],
    },
    counterparties: {
      type: Array as PropType<OneOfType[]>,
      default: () => [],
    },
    counterpartyTypes: {
      type: Array as PropType<OneOfType[]>,
      default: () => [],
    },
    users: {
      type: Array as PropType<OneOfType[]>,
      default: () => [],
    },
  },
  data() {
    return {
      isLoading: true,
      timeoutFunction: null,
    };
  },
  computed: {
    ...mapGetters({
      data: 'admin/dictionary/pinning/one/detail',
    }),
    schema() {
      return {
        type: 'object',
        properties: {
          createdAt: {
            type: ['string', 'null'],
            format: 'date',
          },
          detachmentDate: {
            type: ['string', 'null'],
            format: 'date',
          },
          division: {
            type: ['string', 'null'],
            oneOf: this.divisions,
          },
          department: {
            type: ['string', 'null'],
            oneOf: this.departments,
          },
          user: {
            type: ['string', 'null'],
            oneOf: this.users,
          },
          regionId: {
            type: ['string', 'null'],
          },
          nomenclatureGroupId: {
            type: ['string', 'null'],
          },
          nomenclatureId: {
            type: ['string', 'null'],
          },
          reason: {
            type: 'string',
          },
          reasonComment: {
            type: 'string',
          },
          comment: {
            type: 'string',
          },
        },
        required: [
          'counterpartyType',
          'counterparty',
          'division',
          'department',
          'user',
        ],
      };
    },
    uischema() {
      return {
        type: 'VerticalLayout',
        elements: [
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: this.$t('dictionary.pinning.fields.createdAt'),
                scope: '#/properties/createdAt',
                options: {
                  search: true,
                },
              },
              {
                type: 'Control',
                label: this.$t('dictionary.pinning.fields.division'),
                scope: '#/properties/division',
                options: {
                  search: true,
                },
              },
            ],
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: this.$t('dictionary.pinning.fields.department'),
                scope: '#/properties/department',
                options: {
                  search: true,
                  readonly: !this.data || !this.data.division,
                },
              },
              {
                type: 'Control',
                label: this.$t('dictionary.pinning.fields.user'),
                scope: '#/properties/user',
                options: {
                  search: true,
                  readonly: !this.data || !this.data.division
                      || !this.data.department,
                  ajaxHandler: async (query: string) => {
                    await this.setFilterUser({
                      query,
                      division: this.data.division,
                      department: this.data.department,
                    });
                  },
                },
              },
            ],
          },
          {
            type: 'AjaxControl',
            label: this.$t('dictionary.pinning.fields.region'),
            scope: '#/properties/regionId',
            options: {
              search: true,
              ajaxConfiguration: {
                categoryName: 'region',
              },
            },
          },
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'AjaxControl',
                label: this.$t('dictionary.pinning.fields.nomenclatureGroup'),
                scope: '#/properties/nomenclatureGroupId',
                options: {
                  search: true,
                  ajaxConfiguration: {
                    categoryName: 'nomenclatureGroup',
                  },
                },
              },
              {
                type: 'AjaxControl',
                label: this.$t('dictionary.pinning.fields.nomenclature'),
                scope: '#/properties/nomenclatureId',
                options: {
                  search: true,
                  ajaxConfiguration: {
                    categoryName: 'nomenclature',
                  },
                },
              },
            ],
          },
          ...this.showReasonField,
          {
            type: 'Control',
            label: this.$t('dictionary.pinning.fields.comment'),
            scope: '#/properties/comment',
            options: {
              multi: true,
            },
          },
        ],
      };
    },
    defaultOneOfList() {
      return [{ const: null, title: '' }];
    },
    showReasonField() {
      if (this.data.type === type.ASSIGN_TYPE_DETACHMENT) {
        return [
          {
            type: 'HorizontalLayout',
            elements: [
              {
                type: 'Control',
                label: this.$t('dictionary.pinning.fields.reasonDetachment'),
                scope: '#/properties/reason',
              },
              {
                type: 'Control',
                label: this.$t('dictionary.pinning.fields.detachmentDate'),
                scope: '#/properties/detachmentDate',
              },
            ],
          },
          {
            type: 'Control',
            label: this.$t('dictionary.pinning.fields.reasonComment'),
            scope: '#/properties/reasonComment',
          }];
      }

      if (this.data.type === type.ASSIGN_TYPE_REASSIGNMENT) {
        return [{
          type: 'Control',
          label: this.$t('dictionary.pinning.fields.reasonReassignment'),
          scope: '#/properties/reason',
        }];
      }

      return [];
    },
  },
  methods: {
    ...mapActions({
      loadDetailData: 'admin/dictionary/pinning/one/get',
    }),
    async loadData() {
      this.isLoading = true;
      await this.loadDetailData(this.id);
      this.isLoading = false;
    },
  },
  async mounted() {
    // TODO: Исправить в будущем
    this.timeoutFunction = setTimeout(this.loadData, 300);
  },
  unmounted() {
    clearTimeout(this.timeoutFunction);
  },
});
