import { mergeProps as _mergeProps, createElementVNode as _createElementVNode, withModifiers as _withModifiers, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6c19c7fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "switch-thumb" }
const _hoisted_2 = { class: "ripple" }
const _hoisted_3 = ["id"]
const _hoisted_4 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["switch", _ctx.classes])
  }, [
    _createElementVNode("div", {
      class: "switch-container",
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleCheck && _ctx.toggleCheck(...args)), ["stop"]))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("input", _mergeProps({
            id: _ctx.id,
            type: "checkbox"
          }, { id: _ctx.id, name: _ctx.name, disabled: _ctx.disabled, required: _ctx.required, value: _ctx.value }), null, 16, _hoisted_3)
        ])
      ])
    ]),
    (_ctx.$slots.default)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.id,
          class: "switch-label",
          onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleCheck && _ctx.toggleCheck(...args)), ["prevent"]))
        }, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ], 8, _hoisted_4))
      : _createCommentVNode("", true)
  ], 2))
}