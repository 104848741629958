import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d657e9ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "year-layout" }
const _hoisted_2 = { class: "navigation-menu" }
const _hoisted_3 = { class: "pagination-menu" }
const _hoisted_4 = { class: "year-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_left_arrow = _resolveComponent("left-arrow")!
  const _component_right_arrow = _resolveComponent("right-arrow")!
  const _component_year_item = _resolveComponent("year-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "change-layout__button",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openDayLayout && _ctx.openDayLayout(...args)))
      }, _toDisplayString(_ctx.minYearInPeriod) + " - " + _toDisplayString(_ctx.maxYearInPeriod), 1),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: "prev__button",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.prevYearHandler && _ctx.prevYearHandler(...args)))
        }, [
          _createVNode(_component_left_arrow)
        ]),
        _createElementVNode("div", {
          class: "next__button",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.nextYearHandler && _ctx.nextYearHandler(...args)))
        }, [
          _createVNode(_component_right_arrow)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.yearCount, (year) => {
        return (_openBlock(), _createBlock(_component_year_item, {
          key: year,
          data: _ctx.getYear(year),
          "is-selected": _ctx.isSelectedYear(_ctx.getYear(year)),
          "is-current": _ctx.isCurrentYear(_ctx.getYear(year)),
          onClick: ($event: any) => (_ctx.setYear(_ctx.getYear(year)))
        }, null, 8, ["data", "is-selected", "is-current", "onClick"]))
      }), 128))
    ])
  ]))
}