import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex space-x-[13px] ml-5" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = ["disabled"]
const _hoisted_4 = { class: "text-[14px]" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_table_pagination_over_back = _resolveComponent("table-pagination-over-back")!
  const _component_table_pagination_back = _resolveComponent("table-pagination-back")!
  const _component_table_pagination_next = _resolveComponent("table-pagination-next")!
  const _component_table_pagination_over_next = _resolveComponent("table-pagination-over-next")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.firstPage && _ctx.firstPage(...args))),
      disabled: _ctx.prevButtonsDisabled
    }, [
      _createVNode(_component_table_pagination_over_back, {
        opacity: _ctx.getOpacity(_ctx.prevButtonsDisabled)
      }, null, 8, ["opacity"])
    ], 8, _hoisted_2),
    _createElementVNode("button", {
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.prevPage && _ctx.prevPage(...args))),
      disabled: _ctx.prevButtonsDisabled
    }, [
      _createVNode(_component_table_pagination_back, {
        opacity: _ctx.getOpacity(_ctx.prevButtonsDisabled)
      }, null, 8, ["opacity"])
    ], 8, _hoisted_3),
    _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.startNumber) + " - " + _toDisplayString(_ctx.endNumber) + " из " + _toDisplayString(_ctx.pagination.totalCount), 1),
    _createElementVNode("button", {
      onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.nextPage && _ctx.nextPage(...args))),
      disabled: _ctx.nextButtonsDisabled
    }, [
      _createVNode(_component_table_pagination_next, {
        opacity: _ctx.getOpacity(_ctx.nextButtonsDisabled)
      }, null, 8, ["opacity"])
    ], 8, _hoisted_5),
    _createElementVNode("button", {
      onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.lastPage && _ctx.lastPage(...args))),
      disabled: _ctx.nextButtonsDisabled
    }, [
      _createVNode(_component_table_pagination_over_next, {
        opacity: _ctx.getOpacity(_ctx.nextButtonsDisabled)
      }, null, 8, ["opacity"])
    ], 8, _hoisted_6)
  ]))
}