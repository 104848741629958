import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "loader-block"
}
const _hoisted_3 = {
  key: 1,
  class: "w-full"
}
const _hoisted_4 = { class: "w-full" }
const _hoisted_5 = { class: "header-block" }
const _hoisted_6 = { class: "structure-table" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_default_loader = _resolveComponent("default-loader")!
  const _component_the_title = _resolveComponent("the-title")!
  const _component_the_filter = _resolveComponent("the-filter")!
  const _component_the_table = _resolveComponent("the-table")!
  const _component_the_create = _resolveComponent("the-create")!
  const _component_the_detail = _resolveComponent("the-detail")!
  const _component_the_side_modal = _resolveComponent("the-side-modal")!
  const _component_the_danger_modal = _resolveComponent("the-danger-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_default_loader)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_the_title, { onButtonClick: _ctx.addNewCompany }, null, 8, ["onButtonClick"]),
              _createVNode(_component_the_filter, {
                filter: _ctx.filter,
                "company-id": _ctx.user?.company,
                companies: _ctx.preparedCompanies,
                onQueryChanged: _ctx.searchInTreeHandler,
                onFilterChanged: _ctx.changeFilterHandler,
                onAjaxCompany: _ctx.ajaxCompanyHandler
              }, null, 8, ["filter", "company-id", "companies", "onQueryChanged", "onFilterChanged", "onAjaxCompany"])
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_the_table, {
              items: _ctx.preparedItems,
              fields: _ctx.fields,
              "is-all-open": !!_ctx.queryTree,
              variant: "expand",
              onBodyItemClick: _ctx.viewHandler
            }, null, 8, ["items", "fields", "is-all-open", "onBodyItemClick"]),
            _createVNode(_component_the_side_modal, {
              "is-show-modal": _ctx.isOpenSidebar,
              "is-sidebar-with-footer": _ctx.isCreate || _ctx.isUpdate,
              onCloseHandler: _ctx.closeSidebar
            }, {
              default: _withCtx(() => [
                (_ctx.isCreate && !_ctx.parentId)
                  ? (_openBlock(), _createBlock(_component_the_create, {
                      key: 0,
                      companies: _ctx.companies,
                      onCancel: _ctx.closeSidebar,
                      onCreate: _ctx.createHandler
                    }, null, 8, ["companies", "onCancel", "onCreate"]))
                  : _createCommentVNode("", true),
                (_ctx.isCreate && _ctx.parentId)
                  ? (_openBlock(), _createBlock(_component_the_create, {
                      key: 1,
                      type: "child",
                      companies: _ctx.companies,
                      onCancel: _ctx.closeSidebar,
                      onCreate: _ctx.createChildHandler
                    }, null, 8, ["companies", "onCancel", "onCreate"]))
                  : _createCommentVNode("", true),
                (!_ctx.isCreate)
                  ? (_openBlock(), _createBlock(_component_the_detail, {
                      key: 2,
                      data: _ctx.selectedItemData,
                      "is-show-edit-button": true,
                      "is-show-remove-button": true,
                      "is-update": _ctx.isUpdate,
                      "is-deleted": _ctx.isDeletedStatus,
                      onUpdate: _ctx.updateHandler,
                      onDelete: _ctx.openDangerModal,
                      onAddChildren: _ctx.addChildrenHandler,
                      onChangeMode: _ctx.changeModeHandler
                    }, null, 8, ["data", "is-update", "is-deleted", "onUpdate", "onDelete", "onAddChildren", "onChangeMode"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["is-show-modal", "is-sidebar-with-footer", "onCloseHandler"]),
            _createVNode(_component_the_danger_modal, {
              "approve-button-text": _ctx.$t('dictionary.structure.dangerModal.confirmButton'),
              text: _ctx.$t('dictionary.structure.dangerModal.text'),
              "is-open": _ctx.isOpenDangerModal,
              onApprovalHandler: _ctx.dangerApprovalHandler,
              onCloseModal: _ctx.dangerCloseHandler
            }, null, 8, ["approve-button-text", "text", "is-open", "onApprovalHandler", "onCloseModal"])
          ])
        ]))
  ]))
}