import { AxiosCallResponse } from '@/api/base';
import store from '@/store';
import IndividualService, { IndividualSchema } from '@/api/admin/dictionary/individual';

export function useOneApi() {
  return {
    async loadData(id: string) {
      const individualApi: IndividualService = store.getters.$api.admin.dictionary.individual;

      const response: AxiosCallResponse<IndividualSchema> = await individualApi.one(id);

      return response.data ?? null;
    },
  };
}

export default {};
