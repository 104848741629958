import { defineComponent, PropType } from 'vue';
import { JsonSchema } from '@jsonforms/core';
import TheForm, { AgentForm } from '@/components/counterparty/card/blocks/agents/form/create/TheForm.vue';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import DefaultButton from '@/components/ui/buttons/DefaultButton/DefaultButton.vue';

export default defineComponent({
  name: 'TheCreate',
  emits: ['close', 'submit'],
  components: {
    TheForm,
    DefaultButton,
  },
  props: {
    schema: {
      required: true,
      type: Object as PropType<JsonSchema>,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    closeModal() {
      this.$emit('close');

      this.clearData();
    },
    clearData() {
      const formRef: AgentForm = this.$refs.form as AgentForm;

      formRef?.clearData();
    },
    approvalHandler() {
      this.isLoading = true;

      const formRef: AgentForm = this.$refs.form as AgentForm;
      const valid: boolean = formRef?.validateForm(true) ?? false;
      const data: JsonData | null = formRef?.data ?? null;

      if (!valid || !data) {
        this.isLoading = false;

        return false;
      }

      this.$emit('submit', data, () => {
        this.isLoading = false;

        this.closeModal();
      });

      return true;
    },
  },
});
