
import { defineComponent, PropType } from 'vue';
import { ErrorObject } from 'ajv';
import { JsonFormsChangeEvent } from '@jsonforms/vue';
import { JsonSchema, JsonSchema7 } from '@jsonforms/core';
import { validateData } from '@/utils/helpers/AjvValidator';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import CustomUISchemaElement from '@/components/jsonforms/interfaces/CustomUISchemaElement';
import useShowErrors from '@/composables/form/useShowErrors';

export interface PinningAssignForm {
  data: JsonData,
  currentData: JsonData,
  schema: JsonSchema,
  uischema: CustomUISchemaElement,
  errors: JsonData,
  validateForm: (showErrors?: boolean) => boolean,
  showErrors: () => void,
  onChange: () => void,
}

export default defineComponent({
  name: 'AssignForm',
  emits: ['updateData', 'ajaxUsers'],
  inject: ['$userAccess'],
  props: {
    data: {
      type: Object as PropType<JsonData>,
      default: () => ({}),
    },
    divisions: {
      type: Array as PropType<JsonSchema7[]>,
      default: () => ([]),
    },
    departments: {
      type: Array as PropType<JsonSchema7[]>,
      default: () => ([]),
    },
    users: {
      type: Array as PropType<JsonSchema7[]>,
      default: () => ([]),
    },
  },
  data() {
    return {
      currentData: this.data,
      errors: [] as ErrorObject[],
    };
  },
  watch: {
    data() {
      this.currentData = this.data;
    },
  },
  computed: {
    schema() {
      return {
        type: 'object',
        properties: {
          division: {
            type: ['string', 'null'],
          },
          department: {
            type: ['string', 'null'],
            oneOf: this.departments,
          },
          user: {
            type: ['string', 'null'],
            oneOf: this.users,
          },
          region: {
            type: ['string', 'null'],
          },
          nomenclatureGroup: {
            type: ['string', 'null'],
          },
          nomenclature: {
            type: ['string', 'null'],
          },
          comment: {
            type: ['string'],
          },
        },
        required: this.$userAccess.admin.isSuperAdmin || this.$userAccess.admin.isAdminCompany
          ? ['division']
          : [],
      };
    },
    uischema() {
      return {
        type: 'VerticalLayout',
        elements: [
          {
            type: 'AjaxControl',
            label: this.$t('dictionary.pinning.fields.division'),
            scope: '#/properties/division',
            options: {
              visible: this.$userAccess.admin.isSuperAdmin || this.$userAccess.admin.isAdminCompany,
              search: true,
              ajaxConfiguration: {
                categoryName: 'division',
              },
            },
          },
          {
            type: 'AjaxControl',
            label: this.$t('dictionary.pinning.fields.department'),
            scope: '#/properties/department',
            options: {
              readonly: !this.currentData || !this.currentData.division,
              search: true,
              ajaxConfiguration: {
                categoryName: 'structure',
                filter: {
                  companyId: this.currentData.company,
                  parentId: this.currentData.division,
                },
              },
            },
          },
          {
            type: 'Control',
            label: this.$t('dictionary.pinning.fields.user'),
            scope: '#/properties/user',
            options: {
              readonly: !this.currentData || !this.currentData.division
                || !this.currentData.department,
              search: true,
              ajaxHandler: async (query: string) => {
                this.$emit('ajaxUsers', {
                  query,
                  division: this.currentData.division,
                  department: this.currentData.department,
                });
              },
            },
          },
          {
            type: 'AjaxControl',
            label: this.$t('dictionary.pinning.fields.region'),
            scope: '#/properties/region',
            options: {
              search: true,
              ajaxConfiguration: {
                categoryName: 'region',
              },
            },
          },
          {
            type: 'AjaxControl',
            label: this.$t('dictionary.pinning.fields.nomenclatureGroup'),
            scope: '#/properties/nomenclatureGroup',
            options: {
              search: true,
              ajaxConfiguration: {
                categoryName: 'nomenclatureGroup',
              },
            },
          },
          {
            type: 'AjaxControl',
            label: this.$t('dictionary.pinning.fields.nomenclature'),
            scope: '#/properties/nomenclature',
            options: {
              search: true,
              ajaxConfiguration: {
                categoryName: 'nomenclature',
                filter: {
                  nomenclatureGroup: this.currentData.nomenclature,
                },
              },
            },
          },
          {
            type: 'Control',
            label: this.$t('dictionary.pinning.fields.comment'),
            scope: '#/properties/comment',
            options: {
              multi: true,
            },
          },
        ],
      };
    },
  },
  methods: {
    onChange(event: JsonFormsChangeEvent) {
      this.currentData = event.data;
      this.$emit('updateData', this.currentData);
    },
    validateForm(showErrors = false): boolean {
      this.errors = validateData(this.currentData, this.schema, this.uischema);

      if (showErrors) {
        this.showErrors(this.errors);
      }

      return !this.errors || !this.errors.length;
    },
  },
  setup() {
    const { showErrors } = useShowErrors();

    return { showErrors };
  },
});
