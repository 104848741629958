import { AxiosCallResponse } from '@/api/base';
import store from '@/store';
import PsychologicalCharacteristicService, {
  PsychologicalCharacteristicSchema,
} from '@/api/admin/dictionary/psychologicalCharacteristic';

export function useOneApi() {
  return {
    async loadData(id: string) {
      const api: PsychologicalCharacteristicService = store
        .getters
        .$api
        .admin
        .dictionary
        .psychologicalCharacteristic;

      const response: AxiosCallResponse<PsychologicalCharacteristicSchema> = await api.one(id);

      return response.data ?? {};
    },
  };
}

export default {};
