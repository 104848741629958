import store from '@/store';
import StructureService, {
  FilterStructureParams,
  StructureResponseType,
} from '@/api/admin/dictionary/structure';

export function useListApi() {
  return {
    async loadItems(params: FilterStructureParams) {
      const api: StructureService = store
        .getters
        .$api
        .admin
        .dictionary
        .structure;

      const response: StructureResponseType = await api.list(
        params,
      );

      return response.data?.items ?? [];
    },
  };
}

export default {};
