import { Module } from 'vuex';
import { StateType } from '@/store';
import list, { CountryListStateType } from '@/store/modules/admin/dictionary/country/list';
import one, { CountryOneStateType } from '@/store/modules/admin/dictionary/country/one';

export interface CountryStateType {
  list: CountryListStateType,
  one: CountryOneStateType,
}

const countryModule: Module<CountryStateType, StateType> = {
  namespaced: true,
  modules: {
    list,
    one,
  },
};

export default countryModule;
