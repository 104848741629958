import { Module } from 'vuex';
import { StateType } from '@/store';
import info, { DeliveryRegistryInfoStateType } from '@/store/modules/counterparty/block/registry/deliveryRegistry/info';
import one, { DeliveryRegistryOneStateType } from '@/store/modules/counterparty/block/registry/deliveryRegistry/one';

export interface DeliveryRegistryStateType {
  info: DeliveryRegistryInfoStateType,
  one: DeliveryRegistryOneStateType,
}

const deliveryRegistryModule: Module<DeliveryRegistryStateType, StateType> = {
  namespaced: true,
  modules: {
    info,
    one,
  },
};

export default deliveryRegistryModule;
