
import { defineComponent, PropType } from 'vue';
import { JsonSchema } from '@jsonforms/core';
import { ErrorObject } from 'ajv';
import { JsonFormsChangeEvent } from '@jsonforms/vue';
import CustomUISchemaElement from '@/components/jsonforms/interfaces/CustomUISchemaElement';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import { validateData } from '@/utils/helpers/AjvValidator';
import useShowErrors from '@/composables/form/useShowErrors';

export interface ListBlockTemplateForm {
  data: JsonData,
  currentData: JsonData,
  schema: JsonSchema,
  uischema: CustomUISchemaElement,
  errors: JsonData,
  validateForm: (showErrors?: boolean) => boolean,
  showErrors: () => void,
  onChange: () => void,
}

export default defineComponent({
  name: 'TheForm',
  props: {
    data: {
      type: Object as PropType<JsonData>,
      default: () => ({}),
    },
    schema: Object as PropType<JsonSchema>,
    uischema: Object as PropType<CustomUISchemaElement>,
    isView: Boolean,
  },
  data() {
    return {
      currentData: this.data,
      errors: [] as ErrorObject[],
    };
  },
  watch: {
    data() {
      this.currentData = this.data;
    },
  },
  methods: {
    onChange(event: JsonFormsChangeEvent) {
      this.currentData = event.data;
      this.$emit('updateData', this.currentData);
    },
    validateForm(showErrors = false): boolean {
      this.errors = validateData(this.currentData, this.schema, this.uischema);

      if (showErrors) {
        this.showErrors(this.errors);
      }

      return !this.errors || !this.errors.length;
    },
  },
  setup() {
    const { showErrors } = useShowErrors();

    return { showErrors };
  },
});
