import { QueryParamsForList } from '@/api/base';
import store from '@/store';
import NomenclatureService, { NomenclatureListResponseType } from '@/api/admin/dictionary/nomenclature';

export function useListApi() {
  return {
    async loadItems(params: QueryParamsForList) {
      const api: NomenclatureService = store.getters.$api.admin.dictionary.nomenclature;

      const response: NomenclatureListResponseType = await api.list(
        params,
      );

      return response.data?.items ?? [];
    },
  };
}

export default {};
