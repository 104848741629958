import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b3882b2a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "title-block",
  "aria-label": "user-list-title"
}
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_add_icon = _resolveComponent("add-icon")!
  const _component_default_button = _resolveComponent("default-button")!
  const _component_icon_button = _resolveComponent("icon-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.title), 1),
    (_ctx.isCanEdit)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (!_ctx.$sizeWindow.isSm)
            ? (_openBlock(), _createBlock(_component_default_button, {
                key: 0,
                onClick: _ctx.buttonClickHandler
              }, {
                icon: _withCtx(() => [
                  _createVNode(_component_add_icon, {
                    width: "12",
                    height: "12"
                  })
                ]),
                default: _withCtx(() => [
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('buttons.add')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]))
            : (_openBlock(), _createBlock(_component_icon_button, {
                key: 1,
                onClick: _ctx.buttonClickHandler,
                border: "default"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_add_icon, {
                    width: "16",
                    height: "16"
                  })
                ]),
                _: 1
              }, 8, ["onClick"]))
        ]))
      : _createCommentVNode("", true)
  ]))
}