import {
  AxiosCallResponse,
  BaseService,
  LoggerInterface,
  QueryParamsForList,
  ResponseOfRecords,
} from '@/api/base';
import JsonData from '@/components/jsonforms/interfaces/JsonData';

export type CommunicationItem = {
  id: string,
} & JsonData;

export type CommunicationResponseType = AxiosCallResponse<ResponseOfRecords<CommunicationItem[]>>

export default class CommunicationService extends BaseService {
  constructor(token: string, logger: LoggerInterface = console) {
    super('card', token, logger, 'v2');
  }

  async list(
    cardId: string,
    params: QueryParamsForList,
  ): Promise<CommunicationResponseType> {
    return this.axiosCall({ url: `/${cardId}/communication`, method: 'get', params });
  }

  async create(
    cardId: string,
    data: CommunicationItem,
  ): Promise<AxiosCallResponse<CommunicationItem>> {
    return this.axiosCall({ url: `/${cardId}/communication`, method: 'post', data });
  }

  async update(
    cardId: string,
    id: string,
    data: CommunicationItem,
  ): Promise<AxiosCallResponse<null>> {
    return this.axiosCall({ url: `/${cardId}/communication/${id}`, method: 'patch', data });
  }

  async delete(
    cardId: string,
    id: string,
  ): Promise<AxiosCallResponse<null>> {
    return this.axiosCall({ url: `/${cardId}/communication/${id}`, method: 'delete' });
  }
}
