
import { defineComponent, PropType } from 'vue';
import { useOneApi } from '@/composables/api/agent/one';
import { AgentSchema } from '@/api/agent';
import config from '@/config';

export default defineComponent({
  name: 'UserRenderList',
  props: {
    userList: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  data() {
    return {
      users: [],
    };
  },
  methods: {
    async loadUsers() {
      const agentsPromises: Promise<AgentSchema>[] = [];

      this.userList.forEach((userId: string) => {
        agentsPromises.push(this.agentApi.loadData(userId));
      });

      const agents = await Promise.all(agentsPromises);

      this.users = agents.map((agent: AgentSchema) => ({
        const: agent.id,
        title: agent[config.COUNTERPARTY_AGENT_FULL_NAME_FIELD],
      }));
    },
  },
  watch: {
    async userList() {
      await this.loadUsers();
    },
  },
  async mounted() {
    await this.loadUsers();
  },
  setup() {
    const agentApi = useOneApi();

    return { agentApi };
  },
});
