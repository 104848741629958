import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "loader-block"
}
const _hoisted_3 = {
  key: 1,
  class: "w-full"
}
const _hoisted_4 = { class: "w-full" }
const _hoisted_5 = { class: "header-block" }
const _hoisted_6 = { class: "title-block" }
const _hoisted_7 = { class: "flex items-center gap-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_default_loader = _resolveComponent("default-loader")!
  const _component_DefaultButton = _resolveComponent("DefaultButton")!
  const _component_the_modal = _resolveComponent("the-modal")!
  const _component_the_filter = _resolveComponent("the-filter")!
  const _component_the_table = _resolveComponent("the-table")!
  const _component_sidebar_content = _resolveComponent("sidebar-content")!
  const _component_the_edit_mdm_data = _resolveComponent("the-edit-mdm-data")!
  const _component_the_side_modal = _resolveComponent("the-side-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_default_loader)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("h1", null, _toDisplayString(_ctx.$t("counterparty.list.title")), 1),
                  _createVNode(_component_DefaultButton, {
                    disabled: _ctx.isLoading,
                    onClick: _ctx.downloadReport
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Выгрузить отчет по степени заполнения ")
                    ]),
                    _: 1
                  }, 8, ["disabled", "onClick"])
                ]),
                (_ctx.$userAccess.admin.isSuperAdmin)
                  ? (_openBlock(), _createBlock(_component_the_modal, { key: 0 }))
                  : _createCommentVNode("", true)
              ]),
              _createVNode(_component_the_filter)
            ]),
            _createVNode(_component_the_table, {
              class: "counterparty-table",
              items: _ctx.preparedItems,
              fields: _ctx.fields,
              sort: _ctx.sort,
              pagination: _ctx.pagination,
              onBodyItemClick: _ctx.openShortInfo,
              onChangeSortBy: _ctx.changeSortHandler,
              onChangePagination: _ctx.changePaginationHandler
            }, null, 8, ["items", "fields", "sort", "pagination", "onBodyItemClick", "onChangeSortBy", "onChangePagination"])
          ]),
          _createVNode(_component_the_side_modal, {
            "is-show-modal": _ctx.currentCounterpartyId !== null,
            "is-sidebar-with-footer": !_ctx.isShowShortInfo && !_ctx.isEditMdmLoading,
            onCloseHandler: _ctx.closeShortInfo
          }, {
            default: _withCtx(() => [
              (_ctx.isShowShortInfo)
                ? (_openBlock(), _createBlock(_component_sidebar_content, {
                    key: 0,
                    "is-loading": _ctx.isShortInfoLoading,
                    "is-can-edit-data-mdm": _ctx.isCanEditDataMdm,
                    counterparty: _ctx.counterpartyData,
                    managers: _ctx.managers,
                    onOpenEditForm: _ctx.openEditMdmForm
                  }, null, 8, ["is-loading", "is-can-edit-data-mdm", "counterparty", "managers", "onOpenEditForm"]))
                : (_openBlock(), _createBlock(_component_the_edit_mdm_data, {
                    key: 1,
                    "is-schema-loading": _ctx.isEditMdmLoading,
                    uischema: _ctx.mdmBlockUischema,
                    schema: _ctx.mdmBlockSchema,
                    information: _ctx.counterpartyData,
                    onSave: _ctx.updateMdmHandler,
                    onCancel: _ctx.closeEditModal,
                    onHasErrorsHandler: _ctx.showErrors
                  }, null, 8, ["is-schema-loading", "uischema", "schema", "information", "onSave", "onCancel", "onHasErrorsHandler"]))
            ]),
            _: 1
          }, 8, ["is-show-modal", "is-sidebar-with-footer", "onCloseHandler"])
        ]))
  ]))
}