<template>
  <svg :width="width" :height="height" viewBox="0 0 14 10" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.707 0.292787C13.8945 0.480314 13.9998 0.734622 13.9998 0.999786C13.9998 1.26495
 13.8945 1.51926 13.707 1.70679L5.70704 9.70679C5.51951 9.89426 5.26521 9.99957 5.00004
 9.99957C4.73488 9.99957 4.48057 9.89426 4.29304 9.70679L0.293041 5.70679C0.110883 5.51818
 0.0100885 5.26558 0.0123669 5.00339C0.0146453 4.74119 0.119814 4.49038 0.305222
 4.30497C0.490631 4.11956 0.741443 4.01439 1.00364 4.01211C1.26584 4.00983 1.51844
 4.11063 1.70704 4.29279L5.00004 7.58579L12.293 0.292787C12.4806 0.105316 12.7349 0 13
 0C13.2652 0 13.5195 0.105316 13.707 0.292787Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'SelectedArrow',
  props: {
    width: {
      type: String,
      default: '14',
    },
    height: {
      type: String,
      default: '12',
    },
  },
};
</script>

<style scoped>

</style>
