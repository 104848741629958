import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["width", "height"]
const _hoisted_4 = ["stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isSolid)
      ? (_openBlock(), _createElementBlock("svg", {
          key: 0,
          width: _ctx.width,
          height: _ctx.height,
          viewBox: "0 0 16 16",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg"
        }, [
          _createElementVNode("path", {
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571\n          12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569\n          2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344\n          0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0\n          10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571\n          5.87827 16 8 16ZM11.707 6.707C11.8892 6.5184 11.99 6.2658\n          11.9877 6.0036C11.9854 5.7414 11.8802 5.49059 11.6948\n          5.30518C11.5094 5.11977 11.2586 5.0146 10.9964 5.01233C10.7342\n          5.01005 10.4816 5.11084 10.293 5.293L7 8.586L5.707 7.293C5.5184\n          7.11084 5.2658 7.01005 5.0036 7.01233C4.7414 7.0146 4.49059\n          7.11977 4.30518 7.30518C4.11977 7.49059 4.0146 7.7414 4.01233\n          8.0036C4.01005 8.2658 4.11084 8.5184 4.293 8.707L6.293\n          10.707C6.48053 10.8945 6.73484 10.9998 7 10.9998C7.26516\n          10.9998 7.51947 10.8945 7.707 10.707L11.707 6.707Z",
            fill: _ctx.color
          }, null, 8, _hoisted_2)
        ], 8, _hoisted_1))
      : _createCommentVNode("", true),
    (_ctx.isRegular)
      ? (_openBlock(), _createElementBlock("svg", {
          key: 1,
          width: _ctx.width,
          height: _ctx.height,
          viewBox: "0 0 18 18",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg"
        }, [
          _createElementVNode("path", {
            d: "M6.5 9L8.16667 10.6667L11.5 7.33333M16.5 9C16.5 9.98491\n 16.306 10.9602 15.9291 11.8701C15.5522 12.7801 14.9997\n 13.6069 14.3033 14.3033C13.6069 14.9997 12.7801 15.5522\n 11.8701 15.9291C10.9602 16.306 9.98491 16.5 9 16.5C8.01509\n 16.5 7.03982 16.306 6.12987 15.9291C5.21993 15.5522 4.39314\n 14.9997 3.6967 14.3033C3.00026 13.6069 2.44781 12.7801\n 2.0709 11.8701C1.69399 10.9602 1.5 9.98491 1.5 9C1.5\n 7.01088 2.29018 5.10322 3.6967 3.6967C5.10322 2.29018\n 7.01088 1.5 9 1.5C10.9891 1.5 12.8968 2.29018 14.3033\n 3.6967C15.7098 5.10322 16.5 7.01088 16.5 9Z",
            stroke: _ctx.color,
            "stroke-width": "2",
            "stroke-linecap": "round",
            "stroke-linejoin": "round"
          }, null, 8, _hoisted_4)
        ], 8, _hoisted_3))
      : _createCommentVNode("", true)
  ], 64))
}