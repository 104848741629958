
import {
  ControlElement,
  JsonFormsRendererRegistryEntry,
  rankWith,
  isEnumControl,
} from '@jsonforms/core';
import { RendererProps, rendererProps, useJsonFormsEnumControl } from '@jsonforms/vue';
import { useVanillaControl } from '@jsonforms/vue-vanilla';
import { defineComponent, ref, Ref } from 'vue';
import SelectDefault from '@/components/ui/selects/SelectDefault/SelectDefault.vue';
import { controlWrapper, validationMethods } from '@/components/jsonforms/util/renderer';
import config from '@/config';
import _ from 'lodash';
import ControlWrapper from './ControlWrapper.vue';

const controlRenderer = defineComponent({
  name: 'enum-control-renderer',
  components: {
    ControlWrapper,
    SelectDefault,
  },
  props: {
    ...rendererProps<ControlElement>(),
  },
  computed: {
    data() {
      return this.control.options
        .map((optionElement: { value: string, label: string }) => ({
          id: optionElement.value,
          name: optionElement.label,
        }))
        .filter((optionElement: { id: string, name: string }) => !!optionElement.id);
    },
    preparedControlWrapper() {
      return controlWrapper(this);
    },
  },
  watch: {
    controlWrapper(value, oldValue) {
      if (value.errors !== oldValue.errors) {
        this.errors = value.errors;
      }
    },
  },
  methods: {
    blurHandler(value: string) {
      const localValue: string | null = !value && this.isRequired()
        ? null
        : value;

      this.isFocused = false;

      this.errors = this.getError(this.prepareData(localValue), this.prepareSchema());

      this.handleChange(this.control.path, value);
    },
  },
  setup(props: RendererProps<ControlElement>) {
    const vanillaControl = useVanillaControl(useJsonFormsEnumControl(props));
    const control = vanillaControl.control as unknown as Ref;
    const errors = ref(vanillaControl.controlWrapper?.value?.errors ?? '');
    const debounceTime = config.CONTROL_DEBOUNCE ?? 500;
    const validMethods = validationMethods(control);

    const debounceChangeHandler = _.debounce(async (value: string) => {
      const localValue: string | undefined = !value && validMethods.isRequired()
        ? undefined
        : value;

      vanillaControl.handleChange(control.value.path, localValue);
    }, debounceTime);

    return {
      errors,
      ...vanillaControl,
      ...validMethods,
      debounceChangeHandler,
    };
  },
});

export default controlRenderer;

export const entry: JsonFormsRendererRegistryEntry = {
  renderer: controlRenderer,
  tester: rankWith(2, isEnumControl),
};
