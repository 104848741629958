
import { defineComponent, PropType } from 'vue';
import { RoleAttribute, RoleBlock } from '@/api/admin/role';
import RoleItem, { UpdatedRoleParams } from '@/components/admin/user/one/modal/content/detail/role/RoleItem.vue';
import { DataTypeTree } from '@/components/ui/selects/SelectTree/SelectTree.vue';

export interface UpdatedBlockParams {
  blockId: string,
  roles: UpdatedRoleParams[],
}

export default defineComponent({
  name: 'RoleBlock',
  emits: ['updated', 'updateRoleAttributes'],
  components: {
    RoleItem,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Object as PropType<RoleBlock>,
      default: () => ({}),
    },
    userRoles: {
      type: Array as PropType<string[]>,
      default: () => ([]),
    },
    rolesAttributes: {
      type: Object as PropType<RoleAttribute>,
      default: () => ({}),
    },
    departments: {
      type: Array as PropType<DataTypeTree[]>,
      default: () => ([]),
    },
  },
  methods: {
    updatedHandler(params: UpdatedRoleParams) {
      this.$emit('updated', { blockId: this.block.id, ...params });
    },
    updateRoleAttributesHandler(data: string[], roleId: string) {
      this.$emit('updateRoleAttributes', data, roleId);
    },
  },
});
