import WarningIcon from '@/components/ui/icons/WarningIcon.vue';
import { defineComponent, PropType } from 'vue';
import config from '@/config';
import DefaultButton
  from '@/components/ui/buttons/DefaultButton/DefaultButton.vue';
import InternationalIcon
  from '@/components/ui/icons/InternationalIcon.vue';
import ResidentBlock
  from '@/components/counterparty/card/resident/ResidentBlock.vue';
import IconButton from '@/components/ui/buttons/IconButton/IconButton.vue';
import ListIcon from '@/components/ui/icons/ListIcon.vue';
import TheDangerModal from '@/components/ui/modal/TheDangerModal/TheDangerModal.vue';
import fields from '@/shared/consts/counterparty/fields';
import { CounterpartyCardItem } from '@/api/counterparty';
import CardSwitcher from '@/components/counterparty/card/card-manager/CardSwitcher.vue';
import ClipboardDocumentListIcon from '@/components/ui/icons/ClipboardDocumentListIcon.vue';

export default defineComponent({
  inject: ['$sizeWindow'],
  name: 'CounterpartiesCard',
  emits: ['openFieldError', 'changeCard'],
  components: {
    ClipboardDocumentListIcon,
    CardSwitcher,
    WarningIcon,
    DefaultButton,
    InternationalIcon,
    ResidentBlock,
    IconButton,
    ListIcon,
    TheDangerModal,
  },
  props: {
    information: {
      required: true,
      type: Object,
    },
    needConfirmation: {
      type: Boolean,
      default: false,
    },
    showErrorButton: {
      type: Boolean,
      default: false,
    },
    cards: {
      type: Array as PropType<CounterpartyCardItem[]>,
      default: () => [],
    },
    currentCard: {
      type: Object as PropType<CounterpartyCardItem>,
      default: undefined,
    },
  },
  data() {
    return {
      openDangerModal: false,
    };
  },
  computed: {
    shortName() {
      return this.information
        ? this.information[fields.NAME_FIELD]
        : '';
    },
    inn() {
      return this.information
        ? this.information[fields.INN_FIELD]
        : '';
    },
    taxNumber() {
      return this.information
        ? this.information[fields.TAX_NUMBER_FIELD]
        : '';
    },
    kpp() {
      return this.information
        ? this.information[fields.KPP_FIELD]
        : '';
    },
    country() {
      return this.information
        ? this.information[fields.COUNTRY_FIELD]
        : '';
    },
    isResident() {
      return this.country === config.SIGN_OF_RESIDENT;
    },
  },
  methods: {
    closeModal() {
      this.openDangerModal = false;
    },
    approvalHandler(next?: () => void) {
      this.closeModal();

      this.$router.push({ path: '/' });

      if (next) {
        next();
      }
    },
    clickHandler() {
      if (this.needConfirmation) {
        this.openDangerModal = true;

        return;
      }

      this.approvalHandler();
    },
    openFieldErrorHandler() {
      this.$emit('openFieldError');
    },
    changeCardHandler(cardId: string) {
      this.$emit('changeCard', cardId);
    },
  },
});
