import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4a455ace"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "h-full" }
const _hoisted_2 = {
  key: 0,
  class: "loader-block"
}
const _hoisted_3 = {
  key: 1,
  class: "sidebar-content"
}
const _hoisted_4 = { class: "sidebar-block" }
const _hoisted_5 = { class: "action-buttons" }
const _hoisted_6 = { class: "flex justify-between mt-4" }
const _hoisted_7 = { class: "sidebar-block__title" }
const _hoisted_8 = { class: "sidebar-block" }
const _hoisted_9 = {
  key: 0,
  class: "sidebar-block"
}
const _hoisted_10 = { class: "sidebar-block__text" }
const _hoisted_11 = { class: "sidebar-footer" }
const _hoisted_12 = { class: "text-[14px]" }
const _hoisted_13 = { class: "text-[14px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_default_loader = _resolveComponent("default-loader")!
  const _component_edit_icon = _resolveComponent("edit-icon")!
  const _component_icon_button = _resolveComponent("icon-button")!
  const _component_lock_icon = _resolveComponent("lock-icon")!
  const _component_unlock_icon = _resolveComponent("unlock-icon")!
  const _component_the_avatar = _resolveComponent("the-avatar")!
  const _component_edit_form = _resolveComponent("edit-form")!
  const _component_view_form = _resolveComponent("view-form")!
  const _component_role_group_block = _resolveComponent("role-group-block")!
  const _component_default_button = _resolveComponent("default-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isLoadingUser)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_default_loader)
        ]))
      : _createCommentVNode("", true),
    (!_ctx.isLoadingUser)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              (!_ctx.isEditMode && _ctx.isCanEdit)
                ? (_openBlock(), _createBlock(_component_icon_button, {
                    key: 0,
                    onClick: _ctx.toggleIsEditMode
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_edit_icon)
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : _createCommentVNode("", true),
              (!_ctx.isEditMode && _ctx.isCanEdit)
                ? (_openBlock(), _createBlock(_component_icon_button, {
                    key: 1,
                    onClick: _ctx.changeUserStatus
                  }, {
                    default: _withCtx(() => [
                      (_ctx.isActive)
                        ? (_openBlock(), _createBlock(_component_lock_icon, {
                            key: 0,
                            type: "outline"
                          }))
                        : (_openBlock(), _createBlock(_component_unlock_icon, {
                            key: 1,
                            type: "outline"
                          }))
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : _createCommentVNode("", true)
            ]),
            _createVNode(_component_the_avatar, {
              src: _ctx.user.avatar,
              status: _ctx.user.status,
              nameInitial: _ctx.nameInitial
            }, null, 8, ["src", "status", "nameInitial"]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.fullName), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            (_ctx.isEditMode)
              ? (_openBlock(), _createBlock(_component_edit_form, {
                  key: 0,
                  ref: "form",
                  user: _ctx.user,
                  divisions: _ctx.divisions,
                  departments: _ctx.departments,
                  companies: _ctx.companies
                }, null, 8, ["user", "divisions", "departments", "companies"]))
              : (_openBlock(), _createBlock(_component_view_form, {
                  key: 1,
                  user: _ctx.user,
                  divisions: _ctx.divisions,
                  departments: _ctx.departments,
                  "is-can-edit": _ctx.isCanEdit
                }, null, 8, ["user", "divisions", "departments", "is-can-edit"]))
          ]),
          (_ctx.isVisibleRoles)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('admin.user.detail.role.title')), 1),
                _createVNode(_component_role_group_block, {
                  roles: _ctx.roles,
                  disabled: !_ctx.isEditMode,
                  "user-roles": _ctx.userRoles,
                  "roles-attributes": _ctx.userRolesAttributes,
                  departments: _ctx.preparedDepartments,
                  onUpdated: _ctx.updatedRoleGroupHandler,
                  onUpdateRoleAttributes: _ctx.updatedRoleAttributesHandler
                }, null, 8, ["roles", "disabled", "user-roles", "roles-attributes", "departments", "onUpdated", "onUpdateRoleAttributes"])
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", _hoisted_11, [
      _createVNode(_component_default_button, {
        height: "40",
        onClick: _ctx.toggleIsEditMode
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t('buttons.cancel')), 1)
        ]),
        _: 1
      }, 8, ["onClick"]),
      _createVNode(_component_default_button, {
        type: "primary",
        height: "40",
        disabled: _ctx.isLoading,
        onClick: _ctx.updateHandler
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t('buttons.save')), 1)
        ]),
        _: 1
      }, 8, ["disabled", "onClick"])
    ], 512), [
      [_vShow, _ctx.isEditMode]
    ])
  ]))
}