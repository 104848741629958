
import { defineComponent, PropType } from 'vue';
import LeftArrow from '@/components/ui/icons/LeftArrow.vue';
import RightArrow from '@/components/ui/icons/RightArrow.vue';
import { TYPE_LAYOUT_DAY } from '@/components/ui/datepickers/datepicker-period/layouts/DatepickerLayout.vue';
import { DateTime } from 'luxon';
import YearItem from '@/components/ui/datepickers/datepicker-period/layouts/year/YearItem.vue';

const COUNT_YEAR_LIST = 24;
const MAX_YEAR = 9999;

export default defineComponent({
  emit: ['change', 'change-layout'],
  name: 'YearLayout',
  components: { YearItem, RightArrow, LeftArrow },
  props: {
    date: {
      type: Object as PropType<DateTime | null>,
      default: null,
    },
  },
  data() {
    return {
      nowDate: DateTime.now(),
      currentDate: null,
      rootDate: null,
      maxYearInPeriod: null,
    };
  },
  computed: {
    year() {
      return this.currentDate.year;
    },
    yearCount() {
      return this.maxYearInPeriod > MAX_YEAR
        ? COUNT_YEAR_LIST - (this.maxYearInPeriod - MAX_YEAR)
        : COUNT_YEAR_LIST;
    },
    minYearInPeriod() {
      const min = this.maxYearInPeriod - COUNT_YEAR_LIST;
      return min >= 0 ? min : 0;
    },
  },
  methods: {
    nextYearHandler() {
      if (this.maxYearInPeriod < MAX_YEAR) {
        this.maxYearInPeriod += COUNT_YEAR_LIST;
      }
    },
    prevYearHandler() {
      if (this.maxYearInPeriod > COUNT_YEAR_LIST) {
        this.maxYearInPeriod -= COUNT_YEAR_LIST;
      }
    },
    openDayLayout() {
      this.$emit('change-layout', TYPE_LAYOUT_DAY);
    },
    getYear(year: number) {
      return this.minYearInPeriod + year;
    },
    isSelectedYear(year: number) {
      return this.currentDate.year === year;
    },
    isCurrentYear(year: number) {
      return this.nowDate.year === year;
    },
    setYear(year: number) {
      this.$emit('change', year);
    },
  },
  watch: {
    date(value) {
      this.currentDate = value ?? this.nowDate;
    },
    maxYearInPeriod(value) {
      if (value > MAX_YEAR) {
        this.maxYearInPeriod = MAX_YEAR;
      }
    },
  },
  created() {
    this.currentDate = this.date ?? this.nowDate;
    this.rootDate = this.date ?? this.currentDate;

    while (this.maxYearInPeriod < this.currentDate.year) {
      this.maxYearInPeriod += COUNT_YEAR_LIST;
    }

    this.maxYearInPeriod = this.maxYearInPeriod > MAX_YEAR ? MAX_YEAR : this.maxYearInPeriod;
    this.minYearInPeriod = this.maxYearInPeriod - COUNT_YEAR_LIST;
  },
});
