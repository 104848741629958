import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e4b73b86"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "flex items-center justify-end"
}
const _hoisted_2 = {
  key: 1,
  class: "flex"
}
const _hoisted_3 = {
  key: 0,
  class: "w-full flex justify-end mt-[48px]"
}
const _hoisted_4 = { class: "flex space-x-[12px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_context_menu = _resolveComponent("context-menu")!
  const _component_history_icon = _resolveComponent("history-icon")!
  const _component_icon_button = _resolveComponent("icon-button")!
  const _component_edit_icon = _resolveComponent("edit-icon")!
  const _component_dispatch_renderer = _resolveComponent("dispatch-renderer")!
  const _component_default_button = _resolveComponent("default-button")!
  const _component_blocks_history = _resolveComponent("blocks-history")!
  const _component_block_default = _resolveComponent("block-default")!

  return (_ctx.layout.visible)
    ? (_openBlock(), _createBlock(_component_block_default, {
        key: 0,
        text: _ctx.layout.uischema.label,
        class: _normalizeClass(_ctx.styles.group.root),
        onChanged: _ctx.blockChangedHandler
      }, {
        "header-icon": _withCtx(() => [
          (_ctx.isOpenedBlock)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                (_ctx.$sizeWindow.isSm)
                  ? (_openBlock(), _createBlock(_component_context_menu, {
                      key: 0,
                      "close-on-select": true,
                      params: _ctx.contextMenuParams,
                      onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
                    }, null, 8, ["params"]))
                  : (!_ctx.isEdit)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        (_ctx.isShowEditButton)
                          ? (_openBlock(), _createBlock(_component_icon_button, {
                              key: 0,
                              onClick: _withModifiers(_ctx.openHistory, ["stop"]),
                              class: "mr-[8px]",
                              description: _ctx.$t('jsonforms.buttons.history')
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_history_icon)
                              ]),
                              _: 1
                            }, 8, ["onClick", "description"]))
                          : _createCommentVNode("", true),
                        (_ctx.oldData.canEdit)
                          ? (_openBlock(), _createBlock(_component_icon_button, {
                              key: 1,
                              onClick: _withModifiers(_ctx.toggleEdit, ["stop"]),
                              description: _ctx.$t('jsonforms.buttons.edit')
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_edit_icon)
                              ]),
                              _: 1
                            }, 8, ["onClick", "description"]))
                          : _createCommentVNode("", true)
                      ]))
                    : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.layout.uischema.elements, (element, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: `${_ctx.layout.path}-${index}`,
                class: _normalizeClass(_ctx.styles.group.item)
              }, [
                _createVNode(_component_dispatch_renderer, {
                  schema: _ctx.layout.schema,
                  uischema: element,
                  path: _ctx.layout.path,
                  enabled: _ctx.layout.enabled,
                  renderers: _ctx.layout.renderers,
                  cells: _ctx.layout.cells,
                  onChange: _ctx.onChange
                }, null, 8, ["schema", "uischema", "path", "enabled", "renderers", "cells", "onChange"])
              ], 2))
            }), 128)),
            (_ctx.isEdit)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_default_button, { onClick: _ctx.closeEdit }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('buttons.cancel')), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_default_button, {
                      type: "primary",
                      disabled: !_ctx.needConfirmation || _ctx.isLoading,
                      onClick: _ctx.updateBlockHandler
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('buttons.save')), 1)
                      ]),
                      _: 1
                    }, 8, ["disabled", "onClick"])
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_blocks_history, _mergeProps(_ctx.$attrs, {
              "block-id": _ctx.groupId,
              "block-name": _ctx.layout.uischema.label,
              "is-open": _ctx.isOpenHistory,
              schema: _ctx.layout.schema,
              onCloseHistory: _ctx.closeHistory
            }), null, 16, ["block-id", "block-name", "is-open", "schema", "onCloseHistory"])
          ])
        ]),
        _: 1
      }, 8, ["text", "class", "onChanged"]))
    : _createCommentVNode("", true)
}