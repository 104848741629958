import { Module } from 'vuex';
import list, { CounterpartyTypeListStateType } from '@/store/modules/admin/dictionary/counterparty-type/list';
import { StateType } from '@/store';

export interface CounterpartyTypeStateType {
  list: CounterpartyTypeListStateType,
}

const counterpartyTypeModule: Module<CounterpartyTypeStateType, StateType> = {
  namespaced: true,
  modules: {
    list,
  },
};

export default counterpartyTypeModule;
