
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { UserItem, UserVerifyData } from '@/api/admin/user';
import { UserVerifyError } from '@/store/exception/admin/user/UserVerifyException';
import TheForm, {
  UserFormData,
  UserForm,
} from '@/components/admin/user/one/modal/content/form/TheForm.vue';
import DefaultButton from '@/components/ui/buttons/DefaultButton/DefaultButton.vue';
import SearchInput from '@/components/ui/inputs/SearchInput/SearchInput.vue';
import {
  DivisionItem,
  FilterDivisionParams,
  FilterStructureParams,
} from '@/api/admin/dictionary/structure';
import JsonData from '@/components/jsonforms/interfaces/JsonData';
import TreeHelper from '@/utils/helpers/TreeHelper';
import { CompanyItem } from '@/api/admin/dictionary/company';

export default defineComponent({
  name: 'TheCreate',
  inject: ['$sizeWindow', '$userAccess'],
  emits: ['created', 'cancel'],
  components: {
    SearchInput,
    DefaultButton,
    TheForm,
  },
  data() {
    return {
      email: '',
      isLoading: false,
      emailVerified: false,
      disableVerifyButton: true,
      verifyErrorMessage: '',
      formData: {},
      userData: null as (UserVerifyData | null),
    };
  },
  watch: {
    async formData(data: UserItem, lastData: UserItem) {
      if (data.company && data.company !== lastData.company) {
        await this.loadDivisionWithFilters({
          companyId: data.company,
        });
      }

      if (data.division && data.division !== lastData.division) {
        await this.loadStructureWithFilters({
          parentId: data.division,
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      divisions: 'admin/dictionary/structure/division/items',
      departments: 'admin/dictionary/structure/list/items',
      companies: 'admin/dictionary/company/list/items',
    }),
    hasError() {
      return !!this.verifyErrorMessage;
    },
    preparedCompanies() {
      return [
        ...this.companies.map((company: CompanyItem) => ({
          const: company.id,
          title: company.name,
        })),
      ];
    },
    preparedDivisions() {
      if (this.divisions.length > 0) {
        return [
          ...this.defaultOneOfList,
          ...this.divisions.map((division: DivisionItem) => ({
            const: division.id,
            title: division.name,
          })),
        ];
      }
      return this.defaultOneOfList;
    },
    defaultDepartmentList() {
      return [{
        const: null,
        title: '',
        properties: {
          parent: {
            const: '',
          },
        },
      }];
    },
    defaultOneOfList() {
      return [{
        const: null,
        title: '',
      }];
    },
    preparedDepartments() {
      if (this.departments.length > 0) {
        return TreeHelper.prepareStructure(this.departments);
      }

      return this.defaultDepartmentList;
    },
  },
  methods: {
    ...mapActions({
      verifyUser: 'admin/user/one/verify',
      createUser: 'admin/user/one/create',
      setFilterDivision: 'admin/dictionary/structure/division/changeFilter',
      setFilterStructure: 'admin/dictionary/structure/list/changeFilter',
      loadStructure: 'admin/dictionary/structure/list/loadItems',
    }),
    changeEmailHandler(value: string) {
      this.email = value;
      this.disableVerifyButton = !value;
      this.verifyErrorMessage = '';
    },
    async verifyHandler() {
      if (!this.email) return;

      this.isLoading = true;

      try {
        const user: UserVerifyData = await this.verifyUser(this.email);

        this.emailVerified = true;
        this.userData = user;
        this.verifyErrorMessage = '';
      } catch (err: unknown) {
        const error = err as UserVerifyError;

        this.verifyErrorMessage = error.message;
      }

      this.isLoading = false;
    },
    async createHandler() {
      this.isLoading = true;

      const formRef: UserForm = this.$refs.form as UserForm;
      const valid: boolean = formRef?.validateForm() ?? false;
      const data: UserFormData | null = formRef?.currentData ?? null;

      if (!valid || !data) {
        this.isLoading = false;

        return false;
      }

      const userId: string | null = await this.createUser(data);

      this.$emit('created', userId);

      this.isLoading = false;

      return true;
    },
    async loadDivisionWithFilters(filter: FilterDivisionParams) {
      await this.setFilterDivision(filter);
    },
    async loadStructureWithFilters(filter: FilterStructureParams) {
      await this.setFilterStructure(filter);
      await this.loadStructure();
    },
    updateDataHandler(data: JsonData) {
      this.formData = data;
    },
    cancelHandler() {
      this.$emit('cancel');
    },
  },
  async mounted() {
    if (!this.$userAccess.admin.isSuperAdmin) {
      await this.loadDivisionWithFilters({});
    }
  },
});
