
import { defineComponent, PropType } from 'vue';
import ThePayment from '@/views/counterparty/card/blocks/payment/ThePayment.vue';
import TheAgents from '@/views/counterparty/card/blocks/agents/TheAgents.vue';
import TheNews from '@/components/counterparty/card/blocks/news/TheNews.vue';
import config from '@/config';
import KeyProductionAssets
  from '@/views/counterparty/card/blocks/key-production-assets/KeyProductionAssets.vue';
import TheCommunication from '@/views/counterparty/card/blocks/communication/TheCommunication.vue';
import TheConflictHistory
  from '@/views/counterparty/card/blocks/conflict-history/TheConflictHistory.vue';
import TheSalesSupportHistory
  from '@/views/counterparty/card/blocks/sales-support-history/TheSalesSupportHistory.vue';
import TheSeminarHistory
  from '@/views/counterparty/card/blocks/seminar-history/TheSeminarHistory.vue';
import TheImplementation
  from '@/views/counterparty/card/blocks/implementation/TheImplementation.vue';
import TheProductionIndicators
  from '@/views/counterparty/card/blocks/production-indicatiors/TheProductionIndicators.vue';
import PresenceOfBrands
  from '@/views/counterparty/card/blocks/presence-of-brands/PresenceOfBrands.vue';
import TheConsumptionRegistry
  from '@/views/counterparty/card/blocks/registry/consumption-registry/TheConsumptionRegistry.vue';
import TheDeliveryRegistry
  from '@/views/counterparty/card/blocks/registry/delivery-registry/TheDeliveryRegistry.vue';
import CustomUISchemaElement from '@/components/jsonforms/interfaces/CustomUISchemaElement';
import { JsonSchema } from '@jsonforms/core';
import TheRelationshipBreak
  from '@/views/counterparty/card/blocks/relationship-break/TheRelationshipBreak.vue';
import AssignHistory from '@/views/counterparty/card/blocks/assign-history/AssignHistory.vue';

export default defineComponent({
  name: 'TheBlocks',
  components: {
    AssignHistory,
    TheDeliveryRegistry,
    TheConsumptionRegistry,
    PresenceOfBrands,
    TheProductionIndicators,
    TheSeminarHistory,
    TheSalesSupportHistory,
    TheConflictHistory,
    TheCommunication,
    TheImplementation,
    TheRelationshipBreak,
    KeyProductionAssets,
    ThePayment,
    TheAgents,
    TheNews,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    uischema: {
      required: true,
      type: Object as PropType<CustomUISchemaElement>,
    },
    blockSchemaList: {
      required: true,
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    schema(): JsonSchema {
      return this.blockSchemaList[this.id] ?? {};
    },
    isPaymentAccountBlock() {
      return this.type === config.BLOCK_TYPE_PAYMENT_ACCOUNT;
    },
    isNewsBlock() {
      return this.type === config.BLOCK_TYPE_NEWS_BLOCK;
    },
    isAgentsBlock() {
      return this.type === config.BLOCK_TYPE_AGENTS_ACCOUNT;
    },
    isAssignHistoryBlock() {
      return this.type === config.BLOCK_TYPE_ASSIGN_BLOCK;
    },
    isKeyProductionAssets() {
      return this.type === config.BLOCK_TYPE_KEY_PRODUCTION_ASSETS;
    },
    isImplementation() {
      return this.type === config.BLOCK_TYPE_IMPLEMENTATION_BLOCK;
    },
    isCommunicationBlock() {
      return this.type === config.BLOCK_TYPE_COMMUNICATION_BLOCK;
    },
    isRelationshipBreakBlock() {
      return this.type === config.BLOCK_TYPE_RELATIONSHIP_BREAKS_BLOCK;
    },
    isConflictHistoryBlock() {
      return this.type === config.BLOCK_TYPE_CONFLICT_HISTORY_BLOCK;
    },
    isSalesSupportHistoryBlock() {
      return this.type === config.BLOCK_TYPE_SALES_SUPPORT_HISTORY_BLOCK;
    },
    isSeminarHistoryBlock() {
      return this.type === config.BLOCK_TYPE_SEMINAR_HISTORY_BLOCK;
    },
    isProductionIndicators() {
      return this.type === config.BLOCK_TYPE_PRODUCTION_INDICATORS;
    },
    isPresenceOfBrands() {
      return this.type === config.BLOCK_TYPE_PRESENCE_OF_BRANDS_BLOCK;
    },
    isConsumptionRegistry() {
      return this.type === config.BLOCK_TYPE_CONSUMPTION_REGISTRY;
    },
    isDeliveryRegistry() {
      return this.type === config.BLOCK_TYPE_DELIVERY_REGISTRY;
    },
  },
});
